/**
 * 汎用styleを定義
 */

/**
 * 上下左右中央寄せスタイル
 * 親エレメントに{position: relative}付けてください.
 */
export const verticalCentering = () => {
  return `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`
}

/**
 * 中央寄せスタイル
 * 親エレメントに{position: relative}付けてください.
 */
export const verticalHorizontalCentering = () => {
  return `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
}

/**
 * hover時のアニメーションスタイル
 * @param {string} propertyName プロパティ名
 */
export const linearOnHover = propertyName => {
  return `transition: ${propertyName} 0.1s linear;`
}

/**
 * iconを背景表示するスタイル
 */
export const iconBackground = () => {
  return `
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    vertical-align: middle;
  `
}

/**
 * iconとテキストを並べるスタイル
 */
export const iconBackgroundWithText = () => {
  return `
    ${iconBackground()}
    margin-top: -3px
  `
}
