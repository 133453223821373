import React, { useCallback } from 'react'
import styled from 'styled-components'
import FooterArea from '~/src/components/atoms/Footer/Footer'
import FooterRightArea from '~/src/components/atoms/Footer/FooterRightArea'
import Button from '~/src/components/atoms/Button'
import { useHistory } from 'react-router'
import Const from '~/src/const'
const { Page } = Const

type Props = {
  isValid: boolean
}

const Footer: React.FC<Props> = props => {
  const history = useHistory()
  const onBackCb = useCallback(() => {
    history.push(Page.VARIATION_GROUP.ITEMS)
  }, [history])
  const onConfirmCb = useCallback(() => {
    history.push(Page.VARIATION_GROUP.DOWNLOADED)
  }, [history])
  return (
    <FooterArea>
      <RightArea>
        <StyledButton type="button" normal onClick={onBackCb}>
          登録画面に戻り修正する
        </StyledButton>
        <StyledButton
          type="button"
          primary
          disabled={!props.isValid}
          onClick={onConfirmCb}
        >
          CSVをダウンロードする
        </StyledButton>
      </RightArea>
    </FooterArea>
  )
}

const StyledButton = styled(Button)`
  width: auto;
`

const RightArea = styled(FooterRightArea)`
  button {
    margin-left: 16px;
  }
`

export default Footer
