import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { selectIsEditing } from './selectors'
import { useClearDevices, useFetchDevices } from './hooks'
import { EditModal } from './EditModal/EditModal'
import { List } from './List/List'
import { DeleteModal } from './DeleteModal/DeleteModal'
import { MessageArea } from './MessageArea/MessageArea'

export const RegisteredDevice: React.VFC = () => {
  const isEditing = useSelector(selectIsEditing)
  const clearDevices = useClearDevices()
  const fetchDevices = useFetchDevices()

  useEffect(() => {
    fetchDevices()
    return () => {
      clearDevices()
    }
  }, [clearDevices, fetchDevices])

  const modal = (
    <div>
      <EditModal />
      <DeleteModal />
      <DismissPrompt enabled={isEditing} />
    </div>
  )
  const breadcrumbs = [{ title: '登録端末' }]
  const description = ['ハンディで使用する端末の管理ができます。']

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      modal={modal}
      displayContractedOnly
      displayKM
      displayKP
    >
      <MessageArea />
      <List />
    </PageTemplate>
  )
}
