import React from 'react'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { selectSeatSetDetailModals } from './selectors'
import {
  useCloseDeleteConfirmModal,
  useDeleteSubmit,
  useCloseNotFoundErrorModal
} from './hooks'
import { useSelector } from 'react-redux'

export default () => {
  const { isEditing, isNotFound, isDeleteConfirmModalOpen } = useSelector(
    selectSeatSetDetailModals
  )

  const handleCloseDeleteConfirmModal = useCloseDeleteConfirmModal()
  const handleDeleteSubmit = useDeleteSubmit()
  const handleCloseNotFoundErrorModal = useCloseNotFoundErrorModal()

  return (
    <div>
      <DeleteConfirmModal
        isOpen={isDeleteConfirmModalOpen}
        onCancel={handleCloseDeleteConfirmModal}
        onDelete={handleDeleteSubmit}
        headingMessage="当注文エリアに設定した出し分け設定がクリアされます"
        maxWidth="302px"
      >
        対象テーブルの注文は、注文エリア未設定席に振り分けられます。よろしいですか？
      </DeleteConfirmModal>
      <ErrorModal
        isError={isNotFound}
        error={{
          title: '対象のデータが存在しません',
          message: '注文エリア画面に戻ります'
        }}
        onClose={handleCloseNotFoundErrorModal}
      />
      <DismissPrompt enabled={isEditing} />
    </div>
  )
}
