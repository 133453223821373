import { Record, Set } from 'immutable'

const BatchSelectRecord = new Record({
  targetSeatSet: null,
  selectedPrinterIds: Set(),
  selectedCategoryIds: Set()
})

export default class BatchSelect extends BatchSelectRecord {
  constructor(targetSeatSet) {
    super()
    return this.set('targetSeatSet', targetSeatSet)
  }

  replacePrinterIds(printerIds) {
    return this.set('selectedPrinterIds', Set(printerIds))
  }

  checkCategory(categoryId, isChecked) {
    const set = isChecked
      ? this.selectedCategoryIds.add(categoryId)
      : this.selectedCategoryIds.delete(categoryId)
    return this.set('selectedCategoryIds', set)
  }

  checkAllCategory(categories, isChecked) {
    const set = isChecked ? Set(categories) : Set()
    return this.set('selectedCategoryIds', set)
  }

  isCategoryChecked(categoryId) {
    return this.selectedCategoryIds.has(categoryId)
  }

  isSelected() {
    return (
      this.countSelectedCategories() > 0 || this.selectedPrinterIds.size > 0
    )
  }

  getTargetSeatSetId() {
    return this.targetSeatSet ? this.targetSeatSet.seatSetId : undefined
  }

  getTargetSeatSetName() {
    return this.targetSeatSet ? this.targetSeatSet.name : ''
  }

  getSelectedPrinterIds() {
    return this.selectedPrinterIds.toList()
  }

  getSelectedCategoryIds() {
    return this.selectedCategoryIds.toList()
  }

  countSelectedCategories() {
    return this.selectedCategoryIds.size
  }

  validate() {
    return (
      this.selectedPrinterIds.size > 0 && this.countSelectedCategories() > 0
    )
  }
}
