import styled from 'styled-components'

import TableColumn from '~/src/components/atoms/TableColumn'

export const CheckboxColumn = styled(TableColumn)`
  width: 50px;
`

export const TargetTableColumn = styled(TableColumn)`
  width: auto;
  text-align: left;
`

export const FloorNameColumn = styled(TableColumn)`
  width: auto;
  text-align: left;
`
