import { AppState } from '~/src/redux/reducer'

export const selectEditing = (state: AppState) =>
  state.discountSurchargeSetting.isEditing

export const selectDiscountSurchargeOnVisitEnabled = (state: AppState) =>
  state.discountSurchargeSetting.isDiscountSurchargeOnVisitEnabled

export const selectHandySettingApiState = (state: AppState) =>
  state.api.handySetting
