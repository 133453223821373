import React from 'react'
import styled from 'styled-components'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'

const { Color } = Const

type Props = {
  url: string
  children: React.ReactNode
}

export const ExternalLink: React.VFC<Props> = props => {
  return (
    <InlineLink href={props.url} target="_blank">
      {props.children}
      <LinkIcon color={Color.STRONG_BLUE} />
    </InlineLink>
  )
}

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
