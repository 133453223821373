import React, { useState } from 'react'
import styled from 'styled-components'
import NavigationPrompt from 'react-router-navigation-prompt'
import Modal from '~/src/components/organisms/Modal'
import Const from '~/src/const'
const { Page, Color, Size } = Const

type Props = {
  isEditing: boolean
  isDownloaded: boolean
}

const DismissPrompt: React.FC<Props> = ({ isEditing, isDownloaded }) => {
  const [nextPath, setNextPath] = useState('')
  return (
    <NavigationPrompt
      when={(currentLocation, nextLocation) => {
        const nextPath = nextLocation ? nextLocation.pathname : ''
        setNextPath(nextPath)
        return (
          isEditing &&
          ![
            Page.VARIATION_GROUP.ITEMS,
            Page.VARIATION_GROUP.CONFIRM,
            Page.VARIATION_GROUP.DOWNLOADED
          ].includes(nextPath) &&
          nextPath !== currentLocation.pathname
        )
      }}
    >
      {({ onConfirm, onCancel }) => {
        const isGettingStarted =
          nextPath === Page.VARIATION_GROUP.GETTING_STARTED
        let title, okText, cancelText: string
        let message: React.ReactElement
        let onOK, onClose: any
        if (isGettingStarted) {
          title = 'CSVアップロード画面に戻りますか？'
          message = (
            <>
              ページを移動すると設定内容は破棄され、
              <br />
              現在の続きから編集することはできません。
            </>
          )
          okText = '戻る'
          cancelText = '設定を続ける'
          onOK = onConfirm
          onClose = onCancel
        } else {
          if (isDownloaded) {
            title = '設定を終了しますか？'
            message = (
              <>
                ページを移動すると設定内容は破棄され、
                <br />
                現在の続きから編集することはできません。
              </>
            )
            okText = '終了する'
            cancelText = '設定を続ける'
            onOK = onConfirm
            onClose = onCancel
          } else {
            title = '登録が終了していません。'
            message = (
              <>
                ページを移動すると登録内容は破棄されます。
                <br />
                設定を続けますか？
              </>
            )
            okText = '登録を続ける'
            cancelText = '破棄する'
            onOK = onCancel
            onClose = onConfirm
          }
        }
        return (
          <Modal
            closable
            isOpen
            useWarningOkButton={isGettingStarted || isDownloaded}
            onClose={onClose}
            onOK={onOK}
            title={title}
            cancelText={cancelText}
            okText={okText}
          >
            <StyledContent>{message}</StyledContent>
          </Modal>
        )
      }}
    </NavigationPrompt>
  )
}

const StyledContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  margin-bottom: 8px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 20px;
`
export default DismissPrompt
