import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SortableHandle } from 'react-sortable-hoc'
import { Set } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'
import { SelectedToppingError } from '~/src/redux/modules/Topping/ToppingGroup/types'
import useInteractors from '~/src/hooks/useInteractors'
import TableRow from '~/src/components/atoms/TableRow'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import { HandleColumn, ItemNameColumn, TrashBoxColumn } from '../Common/Column'

const { Color } = Const

type Props = {
  itemId: string
}
const ToppingRowContainer: React.VFC<Props> = React.memo(({ itemId }) => {
  const item = useSelector(
    (state: AppState) => state.toppingGroup.items.get(itemId)!
  )
  const errors = useSelector((state: AppState) =>
    state.toppingGroup.selectedToppingErrors.get(itemId)
  )

  const existsError = computeExistsError(errors)
  const displayName = generateDisplayName(item, errors)
  const interactor = useInteractors().toppingGroupInteractor
  const handleClickTrashBox = useCallback(
    () => interactor.deleteItemFromToppingGroup(itemId),
    [interactor, itemId]
  )

  const props: InnerProps = {
    displayName,
    existsError,
    onClickTrashBox: handleClickTrashBox
  }
  return <ToppingRow {...props} />
})
export { ToppingRowContainer as ToppingRow }

const computeExistsError = (errors?: Set<SelectedToppingError>) =>
  errors ? !errors.isEmpty() : false

const generateDisplayName = (
  item: SimpleItemForTopping,
  errors?: Set<SelectedToppingError>
) => {
  let info = ''
  if (errors?.contains('NO_CATEGORY')) {
    info += '(カテゴリー未設定)'
  }
  if (errors?.contains('HAS_SKU')) {
    info += '(バリエーションあり)'
  }
  if (errors?.contains('TAX_FREE')) {
    info += '(税設定:非課税)'
  }
  if (!item.isDisplayed) {
    info += '(非表示)'
  }
  const nameAndPrice = errors?.contains('HAS_SKU')
    ? item!.itemName
    : // @ts-ignore
      `${item!.itemName}  ¥${item!.price.toCommaSeparatedString()}`
  const nameAndPriceAndInfo = info ? `${nameAndPrice} ${info}` : nameAndPrice
  return computeExistsError(errors)
    ? `[${nameAndPriceAndInfo}]`
    : nameAndPriceAndInfo
}

type InnerProps = {
  displayName: string
  existsError: boolean
  onClickTrashBox: () => void
}
const ToppingRow: React.VFC<InnerProps> = props => {
  const { displayName, existsError, onClickTrashBox } = props
  return (
    <MainContents>
      <DragHandle />
      <ItemNameColumn>
        {existsError && <ExclamationIcon color="red" />}
        {displayName}
      </ItemNameColumn>
      <TrashBoxColumn onClick={onClickTrashBox}>
        <DeleteIcon />
      </TrashBoxColumn>
    </MainContents>
  )
}

const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``

const DragHandle = SortableHandle(() => (
  <HandleColumn>
    <SortIcon />
  </HandleColumn>
))

const MainContents = styled(TableRow)`
  background-color: ${Color.WHITE};
`

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``
