import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { HandySettingApiState } from '~/src/api/handy/settings/types'
import { actions } from './actions'

export const useToggleDiscountSurchargeOnVisitEnabled = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.changeDiscountSurchargeOnVisitEnabled())
  }, [dispatch])
}
export const useInitializeReduxState = (
  handySettingApiState: HandySettingApiState
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(
      actions.initialize({
        isDiscountSurchargeOnVisitEnabled:
          handySettingApiState.response.setting
            .isDiscountSurchargeOnVisitEnabled
      })
    )
    return () => {
      dispatch(actions.clearState())
    }
  }, [dispatch, handySettingApiState])
}
