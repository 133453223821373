import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  selectDefault,
  updateKitchenPositionName,
  setSeatSetUsableByIndex,
  setDeleteKitchenPositionIndex,
  setIsDeleteConfirmModalOpen,
  pushNewKitchenPosition
} from '~/src/redux/modules/KitchenPositionSetting/KitchenPositionManagement'

export const useHandleChangeRadioButton = () => {
  const dispatch = useDispatch()
  return useCallback(
    (e: React.SyntheticEvent) => {
      dispatch(selectDefault(Number((e.target as HTMLInputElement).value)))
    },
    [dispatch]
  )
}

export const useHandleChangeInput = (index: number) => {
  const dispatch = useDispatch()
  return useCallback(
    (e: React.SyntheticEvent) => {
      dispatch(
        updateKitchenPositionName(index, (e.target as HTMLInputElement).value)
      )
    },
    [dispatch, index]
  )
}

export const useHandleChangeSeatSetToggle = (index: number, value: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setSeatSetUsableByIndex(index, value))
  }, [dispatch, index, value])
}

export const useHandleDeleteKitchenPosition = (
  index: number,
  isLast: boolean
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!isLast) {
      dispatch(setDeleteKitchenPositionIndex(index))
      dispatch(setIsDeleteConfirmModalOpen(true))
    }
  }, [dispatch, index, isLast])
}

export const useHandleFocus = (isLast: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (isLast) {
      dispatch(pushNewKitchenPosition())
    }
  }, [dispatch, isLast])
}
