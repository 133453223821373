import { ActionType } from 'typesafe-actions'
import { LoginErrorState } from './types'

// Action types
const HAS_LOGIN_ERROR = 'oes/loginError/HAS_LOGIN_ERROR'

// Actions
export const hasLoginError = (url: string) => {
  return {
    type: HAS_LOGIN_ERROR,
    url
  }
}

// Initial state
const initialState: LoginErrorState = {
  title: null,
  message: null,
  landingPageUrl: null
}

export default (
  state = initialState,
  action: ActionType<typeof hasLoginError>
): LoginErrorState => {
  switch (action.type) {
    case HAS_LOGIN_ERROR: {
      const error = new URLSearchParams(action.url)
      // FIXME: webPageUrlでオープンリダイレクト対策するか？
      return {
        ...state,
        title: error.get('title'),
        message: error.get('message'),
        landingPageUrl: error.get('webPageUrl')
      }
    }
    default:
      return state
  }
}
