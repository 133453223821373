import React, { useState } from 'react'
import styled from 'styled-components'
import { useFetchData, KoruliResponse } from './hooks'

// Koruli参考資料
// 広告枠設置開発マニュアル：https://drive.google.com/drive/folders/1wboA3InFMg3al-uHPe3TOg6sg7Ob-c2I
// 参考実装（JQuery）：https://one-recruit.atlassian.net/wiki/spaces/wmkair/pages/660274618

export const KoruliBanner: React.FC = () => {
  const [data, setData] = useState<KoruliResponse | null>(null)
  useFetchData(setData)

  if (data == null) {
    return <></>
  } else {
    return (
      <div>
        <a
          href={data.link}
          target="_blank"
          rel="noreferrer"
          title="他サービスのご案内"
        >
          <KoruliImg src={data.image} alt="他サービスのご案内" />
        </a>
      </div>
    )
  }
}

const KoruliImg = styled.img`
  width: 170px;
  height: 75px;
`
