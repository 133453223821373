import React from 'react'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Row } from './Row/Row'
import EmptyState from '~/src/components/atoms/EmptyState'
import TableRow from '~/src/components/atoms/TableRow'
import {
  CourseDetailNameColumn,
  CategoryNameColumn,
  PriceColumn,
  ItemCountColumn,
  FreeFlowingColumn,
  IconColumn,
  SpaceColumn
} from './Column/Column'
import Const from '~/src/const/'
import { selectCourses } from '../selectors'

const { Color } = Const

export const InnerList: React.VFC = () => {
  const courses = useSelector(selectCourses)

  return (
    <div>
      <EditableHeader>
        <CourseDetailNameColumn>コース名</CourseDetailNameColumn>
        <CategoryNameColumn>カテゴリー</CategoryNameColumn>
        <PriceColumn>価格</PriceColumn>
        <ItemCountColumn>商品数</ItemCountColumn>
        <FreeFlowingColumn>放題プラン</FreeFlowingColumn>
        <IconColumn>解除</IconColumn>
        <SpaceColumn />
      </EditableHeader>
      {courses.map(course => (
        <Row course={course} key={course.courseId} />
      ))}
      {courses.length === 0 && (
        <EmptyStateWrapper>
          <EmptyState>
            コースが登録されていません。
            <br />
            左下のボタンから登録できます。
          </EmptyState>
        </EmptyStateWrapper>
      )}
    </div>
  )
}

const EditableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  padding: 7px 0;

  position: relative;
`

const EmptyStateWrapper = styled.div`
  padding-top: 28px;
`
