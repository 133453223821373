import Const from '~/src/const'
import { PrinterSelectBoxState, PrinterSelectBoxValues } from './types'
const { UndefinedId } = Const

// ConstantValue
const CLEAR_STATE = 'oes/CLEAR_PRINTER_SELECT_BOXES'
const CHANGE_PRINTER_BY_CATEGORY_SEAT_SET_VALUES =
  'oes/CHANGE_PRINTER_BY_CATEGORY_SEAT_SET_VALUES'
const CHANGE_PRINTER_BY_ITEM_SEAT_SET_VALUES =
  'oes/CHANGE_PRINTER_BY_ITEM_SEAT_SET_VALUES'
const CLEAR_PRINTER_BY_ITEM_SEAT_SET_VALUES_BY_ITEM_ID =
  'oes/CLEAR_PRINTER_BY_ITEM_SEAT_SET_VALUES_BY_ITEM_ID'
const SET_PRINTER_BY_SEAT_SET_VALUES = 'oes/SET_PRINTER_BY_SEAT_SET_VALUES'

// Action
export const clearPrinterSelectBoxState = () => {
  return {
    type: CLEAR_STATE
  }
}

// fix type
export const changeSelectedCategoryValues = (values: any[]) => {
  return {
    type: CHANGE_PRINTER_BY_CATEGORY_SEAT_SET_VALUES,
    values: values
  }
}

export const changeSelectedItemValues = (values: PrinterSelectBoxValues[]) => {
  return {
    type: CHANGE_PRINTER_BY_ITEM_SEAT_SET_VALUES,
    values: values
  }
}

export const clearSelectedItemValuesByItemId = (itemId: string) => {
  return {
    type: CLEAR_PRINTER_BY_ITEM_SEAT_SET_VALUES_BY_ITEM_ID,
    itemId: itemId
  }
}

export const initializeSelectValues = (values: string[]) => {
  return {
    type: SET_PRINTER_BY_SEAT_SET_VALUES,
    values: values
  }
}

const initialState: PrinterSelectBoxState = {
  values: [],
  isInitialized: false
}

// Reducer
// @ts-ignore
export default (state = initialState, action): PrinterSelectBoxState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case SET_PRINTER_BY_SEAT_SET_VALUES:
      return {
        values: action.values || [],
        isInitialized: true
      }
    case CHANGE_PRINTER_BY_CATEGORY_SEAT_SET_VALUES: {
      const targetCategoryId = action.values[0]
        ? action.values[0].categoryId
        : undefined
      const targetSeatSetId = action.values[0]
        ? action.values[0].seatSetId
        : undefined

      // Actionのカテゴリー席群別プリンター以外の、既に入力されているカテゴリー席群別プリンターを取り出す
      const nonActiveValues = state.values.filter(value => {
        return (
          value.categoryId !== targetCategoryId ||
          (value.seatSetId ? value.seatSetId : UndefinedId.SEAT_SET_ID) !==
            targetSeatSetId
        )
      })

      const resultValues = nonActiveValues || []

      // Actionのカテゴリー席群別プリンターを先ほど抽出した配列にpushする. 全ての選択値が消された場合、printerIdが空で渡ってくるので、その時はpushしない
      if (action.values[0].printerId !== '') {
        action.values.forEach(target => resultValues.push(target))
      }

      return {
        ...state,
        values: resultValues
      }
    }
    case CHANGE_PRINTER_BY_ITEM_SEAT_SET_VALUES: {
      const targetItemId = action.values[0]
        ? action.values[0].itemId
        : undefined
      const targetSeatSetId = action.values[0]
        ? action.values[0].seatSetId
        : undefined

      // Actionの商品席群別プリンター以外の、既に入力されている商品席群別プリンターを取り出す
      const nonActiveValues = state.values.filter(value => {
        return (
          value.itemId !== targetItemId ||
          (value.seatSetId ? value.seatSetId : UndefinedId.SEAT_SET_ID) !==
            targetSeatSetId
        )
      })

      const resultValues = nonActiveValues || []

      // Actionの商品席群別プリンターを先ほど抽出した配列にpushする. 全ての選択値が消された場合、printerIdが空で渡ってくるので、その時はpushしない
      if (action.values[0].printerId !== '') {
        action.values.forEach(target => resultValues.push(target))
      }

      return {
        ...state,
        values: resultValues
      }
    }
    case CLEAR_PRINTER_BY_ITEM_SEAT_SET_VALUES_BY_ITEM_ID: {
      // 指定された商品IDに紐付く「商品席群別印刷先出し分け設定」を削除します
      // 具体的な呼び出し元は「トグルボタン」をOFFにした時
      const resultValues = state.values.filter(
        value => value.itemId !== action.itemId
      )

      return {
        ...state,
        values: resultValues
      }
    }
    default:
      return state
  }
}
