// 注文チケットソート順タイプ

export default {
  BY_SELECTED_AT: {
    ID: '0',
    TEXT: '注文入力順'
  },
  BY_NOTICE_SECOND: {
    ID: '1',
    TEXT: '注意時間順'
  }
}
