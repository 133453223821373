import React from 'react'
import styled, { css } from 'styled-components'
import { SortableHandle } from 'react-sortable-hoc'

import Icon from '~/src/components/atoms/Icon'
import TableColumn from '~/src/components/atoms/TableColumn'

export default props => {
  const {
    children,
    isHandleColumnHidden,
    isDeleteColumnHidden,
    onDeleteButtonClick
  } = props

  return (
    <RowWrapper>
      <HandleColumn isHidden={isHandleColumnHidden} />
      <MemoNameColumn name="memo-name">{children}</MemoNameColumn>
      <DeleteColumn
        isHidden={isDeleteColumnHidden}
        onClick={onDeleteButtonClick}
      />
    </RowWrapper>
  )
}

const RowWrapper = styled.div`
  display: table;
  width: 100%;
`

const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``
const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``

const HandleColumn = SortableHandle(({ isHidden }) => (
  <InnerHandleColumn isHidden={isHidden} />
))

const InnerHandleColumn = props => {
  return (
    <HandleColumnStyle name="handle-icon" isHidden={props.isHidden}>
      {!props.isHidden && <SortIcon />}
    </HandleColumnStyle>
  )
}

const HandleColumnStyle = styled(TableColumn)`
  width: 42px;
  ${props =>
    !props.isHidden &&
    css`
      cursor: move;
    `}
`

const MemoNameColumn = styled(TableColumn)`
  width: auto;
  text-align: center;
`

const DeleteColumn = props => {
  const { isHidden, onClick } = props

  return (
    <InnerDeleteColumn
      isHidden={isHidden}
      onClick={() => !isHidden && onClick()}
    >
      {!isHidden && (
        <span name="delete-icon">
          <DeleteIcon />
        </span>
      )}
    </InnerDeleteColumn>
  )
}

const InnerDeleteColumn = styled(TableColumn)`
  width: 50px;
  ${props =>
    !props.isHidden &&
    css`
      cursor: pointer;
    `}
`
