import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Const from '~/src/const'
import { fadeIn, fadeOut } from '~/src/components/particles/Animation'

const { Color } = Const

export default props => {
  const { isVisible, onClick } = props
  const [hasShown, setHasShown] = useState(false)
  useEffect(() => {
    if (isVisible) {
      setHasShown(true)
    }
  }, [isVisible])

  return (
    <Wrapper canAnimate={hasShown} isVisible={isVisible} onClick={onClick} />
  )
}

const Wrapper = styled.div`
  background-color: ${Color.BLACK_ALPHA50};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  ${props =>
    props.canAnimate &&
    css`
      animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.25s forwards
        ease-in-out;
    `}
`
