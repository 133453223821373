import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'

const { Page } = Const

export const selectDeviceCountConfirmPageState = (state: AppState) => {
  const dismissPromptWhiteList = state.Forward.shouldForward
    ? []
    : [Page.DEVICE_COUNT.CONFIRM]
  return {
    isChecked: state.deviceCount.isChecked,
    didUpdateDeviceCount: state.deviceCount.didUpdateDeviceCount,
    dismissPromptWhiteList
  }
}

export const selectDeviceCountConfirmContentState = (state: AppState) => ({
  confirmedDeviceCount: state.deviceCount.confirmedDeviceCount,
  shouldForward: state.Forward.shouldForward,
  planPriceType: state.auth.store?.planPriceType
})
