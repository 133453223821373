import { useCallback } from 'react'
import { actions } from './actions'
import { actions as ItemsApiActions } from '~/src/api/handy/items/actions'
import { useDispatch } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DeleteTargetCourse } from './types'
import { ThunkDispatch } from 'redux-thunk'
import Const from '~/src/const'
import {
  clearLoading,
  setLoading,
  showAndBlurToast,
  closeDeleteConfirmModal,
  openDeleteConfirmModal
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { handleError } from '~/src/redux/modules/ApiError'
import { ItemsApiDispatch } from '~/src/api/handy/items/types'
const { API, Toast } = Const

export const useFetch = () => {
  const dispatch: ItemsApiDispatch = useDispatch()
  return useCallback(() => {
    dispatch(ItemsApiActions.fetchItems())
  }, [dispatch])
}

export const useHandleOpenDeleteModal = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(
    (deleteTargetCourse: DeleteTargetCourse) => {
      dispatch(actions.openDeleteModal(deleteTargetCourse))
      dispatch(openDeleteConfirmModal())
    },
    [dispatch]
  )
}

export const useHandleCloseDeleteConfirmModal = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(closeDeleteConfirmModal())
  }, [dispatch])
}

export const useHandleDeleteCourse = (
  courseId: string,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  return useCallback(() => {
    dispatch(setLoading())
    return apiClient
      .post(API.POST_DELETE_COURSES, { courseId: courseId })
      .then(() => {
        dispatch(showAndBlurToast(Toast.REMOVED_MESSAGE))
        dispatch(ItemsApiActions.fetchItems())
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => {
        dispatch(clearLoading())
        dispatch(closeDeleteConfirmModal())
      })
  }, [dispatch, apiClient, courseId])
}
