import React from 'react'
import styled from 'styled-components'
import Modal from '~/src/components/organisms/Modal'
import Session from '~/src/util/Session'
import Const from '~/src/const'
import { selectIsLogoutModalOpen } from './selectors'
import { useHandleCloseLogoutModal } from './hooks'
import { useSelector } from 'react-redux'

const { Color, Size } = Const

export const LogoutModal: React.VFC = () => {
  const isLogoutModalOpen = useSelector(selectIsLogoutModalOpen)
  const handleCloseLogoutModal = useHandleCloseLogoutModal()
  if (!isLogoutModalOpen) {
    return null
  }
  return (
    <Modal isOpen onOK={Session.logout} onClose={handleCloseLogoutModal}>
      <ModalContent>ログアウトしてよろしいですか？</ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  font-size: ${Size.FONT.BASE}px;
  padding: 16px 0;
  line-height: 20px;
`
