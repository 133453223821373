import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'

export const selectInnerPolicy = createSelector(
  (state: AppState) => state.deviceCount,
  (state: AppState) => state.auth.store,
  ({ isChecked }, store) => ({
    isChecked,
    flatRateCount:
      store?.planPriceType.FLAT_RATE_COUNT === null
        ? '-'
        : store?.planPriceType.FLAT_RATE_COUNT,
    flatRatePrice:
      store?.planPriceType.FLAT_RATE_PRICE === null
        ? '-'
        : store?.planPriceType.FLAT_RATE_PRICE.toLocaleString('ja-JP'),
    appendedPrice: store?.planPriceType.APPENDED_PRICE.toLocaleString('ja-JP')
  })
)
