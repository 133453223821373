/**
 * ボタン - デザインガイドライン
 * https://wiki.misosiru.io/pages/viewpage.action?pageId=59895661
 */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Const from '~/src/const'
const { Size, Color } = Const

const Button = props => {
  const [lastCalledAt, setLastCalledAt] = useState(0)
  const onClick =
    typeof props.onClick === 'function'
      ? e => {
          if (lastCalledAt + 500 < Date.now()) {
            props.onClick(e)
            setLastCalledAt(Date.now())
          }
        } // 連打防止
      : undefined

  return (
    <StyledButton {...props} onClick={onClick}>
      {props.children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 13px 16px 12px;
  border: none;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-radius: ${Size.BORDER.RADIUS.DEFAULT}px;
  line-height: 1;
  ${props => colors(props)}
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.3;
    `}
  &:hover {
    cursor: pointer;
    ${props => hoverColors(props)};
  }
  &:active {
    ${props => activeColors(props)};
  }
  &:focus {
    outline: none;
  }
  font-size: inherit;
`

const getHoverFontColor = (color, defaultColor) => {
  switch (color) {
    case 'red':
      return Color.PALE_RED
    case 'blue':
      return Color.SOFT_BLUE
    default:
      return defaultColor
  }
}
const hoverColors = props => {
  if (props.primary) {
    return `
      background: ${Color.SOFT_BLUE};
      color: ${Color.VERY_SOFT_BLUE};
      box-shadow: inset 0 1px 0 0 ${Color.MODERATE_BLUE},
        inset -1px 0 0 0 ${Color.MODERATE_BLUE},
        inset 1px 0 0 0 ${Color.MODERATE_BLUE},
        inset 0 -2px 0 0 ${Color.MODERATE_BLUE};
    `
  } else if (props.secondary) {
    return `
      background: ${Color.VERY_DARK_GRAYPALE_BLUE};
      color: ${getHoverFontColor(props.variation, Color.LIGHT_GRAYISH_BLUE)};
      box-shadow: inset 0 1px 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset -1px 0 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset 1px 0 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset 0 -2px 0 0 ${Color.LIGHT_GRAYISH_BLUE_2};
    `
  } else if (props.normal) {
    return `
      background: ${Color.VERY_LIGHT_GRAY_3};
      color: ${getHoverFontColor(props.variation, Color.VERY_LIGHT_GRAY_2)};
      box-shadow: inset 0 1px 0 0 ${Color.LIGHT_GRAY},
        inset -1px 0 0 0 ${Color.LIGHT_GRAY},
        inset 1px 0 0 0 ${Color.LIGHT_GRAY},
        inset 0 -2px 0 0 ${Color.LIGHT_GRAY};
    `
  }
}
const getActiveFontColor = (color, defaultColor) => {
  switch (color) {
    case 'red':
      return Color.DARK_MODERATE_RED
    case 'blue':
      return Color.DARK_BLUE
    default:
      return defaultColor
  }
}
const activeColors = props => {
  if (props.primary) {
    return `
      background: ${Color.DARK_BLUE};
      color: ${Color.DARK_MODERATE_BLUE};
      box-shadow: inset 0 1px 0 0 ${Color.VERY_DARK_BLUE},
        inset -1px 0 0 0 ${Color.VERY_DARK_BLUE},
        inset 1px 0 0 0 ${Color.VERY_DARK_BLUE},
        inset 0 -2px 0 0 ${Color.VERY_DARK_BLUE};
    `
  } else if (props.secondary) {
    return `
      background: ${Color.DARK_GRAYISH_BLUE_2};
      color: ${getActiveFontColor(props.variation, Color.DARK_GRAYISH_BLUE)};
      box-shadow: inset 0 1px 0 0 ${Color.DARK_GRAYISH_BLUE_3},
        inset -1px 0 0 0 ${Color.DARK_GRAYISH_BLUE_3},
        inset 1px 0 0 0 ${Color.DARK_GRAYISH_BLUE_3},
        inset 0 -2px 0 0 ${Color.DARK_GRAYISH_BLUE_3};
    `
  } else if (props.normal) {
    return `
      background: ${Color.GRAY_2};
      color: ${getActiveFontColor(props.variation, Color.DARK_GRAY_3)};
      box-shadow: inset 0 1px 0 0 ${Color.DARK_GRAY_2},
        inset -1px 0 0 0 ${Color.DARK_GRAY_2},
        inset 1px 0 0 0 ${Color.DARK_GRAY_2},
        inset 0 -2px 0 0 ${Color.DARK_GRAY_2};
    `
  }
}
const getFontColor = (color, defaultColor) => {
  switch (color) {
    case 'red':
      return Color.SOFT_RED
    case 'blue':
      return Color.VIVID_BLUE
    default:
      return defaultColor
  }
}
const colors = props => {
  if (props.primary) {
    return `
      background: ${Color.VIVID_BLUE};
      color: ${Color.WHITE};
      box-shadow: inset 0 1px 0 0 ${Color.DARK_BLUE},
        inset -1px 0 0 0 ${Color.DARK_BLUE},
        inset 1px 0 0 0 ${Color.DARK_BLUE},
        inset 0 -2px 0 0 ${Color.DARK_BLUE};
    `
  } else if (props.secondary) {
    return `
      background: ${Color.VERY_PALE_BLUE};
      color: ${getFontColor(props.variation, Color.DARK_GRAY)};
      box-shadow: inset 0 1px 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset -1px 0 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset 1px 0 0 0 ${Color.LIGHT_GRAYISH_BLUE_2},
        inset 0 -2px 0 0 ${Color.LIGHT_GRAYISH_BLUE_2};
    `
  } else if (props.normal) {
    return `
      background: ${Color.VERY_LIGHT_GRAY};
      color: ${getFontColor(props.variation, Color.DARK_GRAY)};
      box-shadow: inset 0 1px 0 0 ${Color.LIGHT_GRAY_2},
        inset -1px 0 0 0 ${Color.LIGHT_GRAY_2},
        inset 1px 0 0 0 ${Color.LIGHT_GRAY_2},
        inset 0 -2px 0 0 ${Color.LIGHT_GRAY_2};
    `
  }
}

export default Button
