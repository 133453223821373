import { useCallback } from 'react'
import { actions } from './actions'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { WaitingStatusState } from './types'

export const useInitializeReduxState = (state: WaitingStatusState) => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.initialize(state))
    return () => {
      dispatch(actions.clearState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    state.firstDrinkDelayMinutes,
    state.firstFoodDelayMinutes,
    state.isAlertSecondsEnabled,
    state.isFirstDrinkDelayEnabled,
    state.isFirstFoodDelayEnabled
  ])
}
