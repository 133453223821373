import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import Const from '~/src/const'

const { Size, Color } = Const

export default createGlobalStyle`
  ${normalize()}
  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  body {
    font-family: 'Avenir Next', 'Open Sans', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'MS Pゴシック', 'MSPGothic', sans-serif;
    background: ${Color.WHITE};
    color: ${Color.VERY_DARK_GRAY};
    font-size: ${Size.FONT.LARGE}px;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    user-select: none;
  }
  ul,
  li {
    list-style: none;
  }
  input,
  textarea,
  select,
  button {
    font-family: inherit;
  }
  a {
    color: ${Color.STRONG_BLUE};
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:hover {
      text-decoration: underline;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 1em;
  }

  #root {
    height: 100%;
  }
`
