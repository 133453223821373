import { ActionType, getType } from 'typesafe-actions'
import { DisplayOptionSettingState } from './types'
import { actions } from './actions'
import Const from '~/src/const'

const { OrderTicketSortType } = Const

const initialState: DisplayOptionSettingState = {
  isFirstDrinkDelayEnabled: true,
  firstDrinkDelayMinutes: 5,
  isFirstFoodDelayEnabled: true,
  firstFoodDelayMinutes: 20,
  isAlertSecondsEnabled: true,
  isCookingNoticeEnabled: true,
  orderTicketSortTypeId: OrderTicketSortType.BY_SELECTED_AT.ID
}

export const displayOptionSetting = (
  state: DisplayOptionSettingState = initialState,
  action: ActionType<typeof actions>
): DisplayOptionSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      const setting = action.payload.kmSettingApiResponse.setting
      return {
        ...state,
        isFirstDrinkDelayEnabled: setting.isFirstDrinkDelayEnabled,
        firstDrinkDelayMinutes: setting.firstDrinkDelayMinutes,
        isFirstFoodDelayEnabled: setting.isFirstFoodDelayEnabled,
        firstFoodDelayMinutes: setting.firstFoodDelayMinutes,
        isAlertSecondsEnabled: setting.isAlertSecondsEnabled,
        isCookingNoticeEnabled: setting.isCookingNoticeEnabled,
        orderTicketSortTypeId: setting.orderTicketSortType
      }
    }
    default:
      return state
  }
}
