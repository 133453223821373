import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'

export const selectDeleteModalState = createSelector(
  (state: AppState) => state.registeredDevice.deleteTargetDevice,
  deleteTargetDevice => {
    const isOpen = !!deleteTargetDevice
    return {
      isOpen,
      deviceName: isOpen ? deleteTargetDevice.deviceName : ''
    }
  }
)
