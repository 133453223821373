import React from 'react'
import { useSelector } from 'react-redux'
import CommonFooter from '~/src/components/organisms/Footer'
import { useHandleSubmit } from './hooks'
import { selectIsSubmittable } from './selectors'

export const Footer = () => {
  const isSubmittable = useSelector(selectIsSubmittable)
  const handleSubmit = useHandleSubmit()

  return (
    <CommonFooter
      disableSubmitButton={!isSubmittable}
      noUseOptionButton={true}
      onSubmitButtonClick={handleSubmit}
    />
  )
}
