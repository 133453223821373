import React from 'react'

import Footer from '~/src/components/organisms/Footer'
import Const from '~/src/const/'
const { Page } = Const

export const CourseFooter: React.VFC = () => {
  return (
    <Footer
      noUseSubmitButton={true}
      optionButtonLink={Page.COURSE.CREATE}
      optionButtonText="コースを登録する"
    />
  )
}
