const AnalysisTags: AnalysisTag = {
  FOOD: { id: '1', value: 'フード' },
  ALCOHOL: { id: '2', value: 'アルコールドリンク' },
  NON_ALCOHOL: { id: '3', value: 'ソフトドリンク' },
  APPETIZER: { id: '4', value: 'お通し' },
  COURSE: { id: '5', value: 'コース' },
  HODAI: { id: '6', value: '放題プラン' },
  DELIVERY: { id: '7', value: '出前' },
  TO_GO: { id: '8', value: 'テイクアウト' },
  OTHER: { id: '9', value: 'その他' }
}

type KeyValue = {
  id: string
  value: string
}

export type AnalysisTag = {
  [name: string]: KeyValue
}

export const AnalysisTagOptions = Object.keys(AnalysisTags).map(key => ({
  id: key,
  value: AnalysisTags[key].id,
  label: AnalysisTags[key].value
}))

export default AnalysisTags
