import { AppState } from '~/src/redux/reducer'

export const selectKitchenPositionTargetModal = (state: AppState) => {
  const { isEditing, kitchenPositionByItemEditor } = state.kitchenPositionTarget
  return {
    isEditing:
      isEditing ||
      (!!kitchenPositionByItemEditor && kitchenPositionByItemEditor.isEditing),
    isCategoryModalShowing: !!kitchenPositionByItemEditor,
    isCancelConfirmModalShowing:
      !!kitchenPositionByItemEditor &&
      kitchenPositionByItemEditor.isCancelConfirmModalShowing
  }
}
