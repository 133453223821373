import { AppState } from '~/src/redux/reducer'

export const selectRow = (itemId: string) => (state: AppState) => {
  const {
    kitchenPositionByCategories,
    kitchenPositionByItemEditor,
    selectableOptions
  } = state.kitchenPositionTarget

  // 商品別設定が登録されていない場合は undefined
  const kitchenPositionByItem =
    kitchenPositionByItemEditor?.kitchenPositionByItems.getByItemId(itemId)

  // 商品別設定が登録されていない場合は、カテゴリーの選択状態を表示する
  // カテゴリーの調理場設定はデフォルトが初期値で入っているため、undefinedはない
  const selectedKitchenPositionId = kitchenPositionByItem
    ? kitchenPositionByItem.kitchenPositionId
    : kitchenPositionByCategories.getByCategoryId(
        kitchenPositionByItemEditor?.categoryId
      )!.kitchenPositionId

  // 商品別設定設定から分析タグを抽出
  let selectedAnalysisTag
  if (kitchenPositionByItem) {
    selectedAnalysisTag = kitchenPositionByItem.analysisTag
  }
  // 分析タグ対応で途中から必須選択で追加された
  // そのため商品ごとに調理場が設定されていても分析タグが設定されていないことがある
  // その場合はカテゴリーで設定された分析タグと同じものを設定する
  // カテゴリーの分析タグが設定されていない場合は、モーダルが開けないようになっている
  if (!selectedAnalysisTag) {
    selectedAnalysisTag = kitchenPositionByCategories.getByCategoryId(
      kitchenPositionByItemEditor?.categoryId
    )!.analysisTag
  }

  return {
    hasItemSetting: !!kitchenPositionByItem,
    selectableOptions,
    selectedKitchenPositionId,
    selectedAnalysisTag
  }
}
