import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setShouldForward } from '~/src/redux/modules/Forward'
import { replacePrinterByBatchSelect } from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setShouldForward(true))
    dispatch(replacePrinterByBatchSelect())
  }, [dispatch])
}
