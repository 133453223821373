import React from 'react'
import { HeaderRow } from './Row/HeaderRow'
import { Row } from './Row/Row'
import { useSelector } from 'react-redux'
import { selectCategoryModalList } from './selectors'

export const List: React.VFC = () => {
  const items = useSelector(selectCategoryModalList)

  return (
    <div>
      <HeaderRow />
      {items?.map(item => (
        <Row key={item.itemId} item={item} />
      ))}
    </div>
  )
}
