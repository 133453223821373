import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import CategoryRow from './Category'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import { AppState } from '~/src/redux/reducer'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import useInteractors from '~/src/hooks/useInteractors'

type Props = {
  category?: SimpleCategory
}

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (_: AppState, props: Props) => props,
  (state: VariationGroupState, props: Props) => {
    const categoryId = props.category && props.category.categoryId
    const items =
      categoryId === undefined
        ? state.items.filter(
            item =>
              !state.categories.some(
                category => category.categoryId === item.categoryId
              )
          )
        : state.items.filter(item => item.categoryId === categoryId)
    const isAllSelected =
      items.length !== 0 &&
      items.every(item => state.selectedItemIds.has(item.itemId))
    const isSomeSelected =
      items.length !== 0 &&
      items.some(item => state.selectedItemIds.has(item.itemId))
    return {
      items,
      isSomeSelected,
      isAllSelected
    }
  }
)

const useVariationGroup = (props: Props) => {
  return useSelector((state: AppState) => variationGroupSelector(state, props))
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const CategoryContainer = (props: Props) => {
  const { items, isSomeSelected, isAllSelected } = useVariationGroup(props)

  // レンダリング最適化のための処置
  // categoryに所属する商品は変わらないためメモ化する
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedItems = useMemo(() => items, [props.category?.categoryId])

  return (
    <CategoryRow
      {...props}
      items={memoizedItems}
      isSomeSelected={isSomeSelected}
      isAllSelected={isAllSelected}
      {...useInteractor()}
    />
  )
}

export default CategoryContainer
