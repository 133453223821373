import React, { useCallback } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import TableRow from '~/src/components/atoms/TableRow'
import TableColumn from '~/src/components/atoms/TableColumn'
// @ts-ignore
import seatsImageWithHandy from '~/assets/images/SalesDisplaySetting/seats_with_Handy.png'
// @ts-ignore
import seatsImageWithoutHandy from '~/assets/images/SalesDisplaySetting/seats_without_Handy.png'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import { RadioButton } from '~/src/common/atoms/RadioButton'
import { isHandyKmNotKpKmOnlySelector } from '~/src/redux/selectors/storeSelectors'

const { Size, TaxDisplayType, SalesDisplays, Color } = Const

// SelectBoxに表示す項目

export const DisplaySetting: React.VFC = () => {
  const handySetting = useSelector(
    (state: AppState) => state.setting.handySetting
  )
  const seatsImageUrl = useSelector(isHandyKmNotKpKmOnlySelector)
    ? seatsImageWithHandy
    : seatsImageWithoutHandy
  const interactor = useInteractors().settingInteractor
  const isSalesDisplayed = handySetting.isSalesDisplayed
  const selectedTaxDisplayTypeId = handySetting.taxDisplayTypeId
  const handleOnChangeIsSalesDisplayed = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      interactor.onChangeIsSalesDisplayed(
        event.target.value === SalesDisplays.SHOW.ID
      )
    },
    [interactor]
  )

  const handleOnChangeTaxDisplayType = useCallback(
    e => {
      interactor.onChangeTaxDisplayType(
        e.target.value === TaxDisplayType.EXCLUDED.ID
          ? TaxDisplayType.EXCLUDED.ID
          : TaxDisplayType.INCLUDED.ID
      )
    },
    [interactor]
  )

  return (
    <>
      <SettingTitle>営業状況</SettingTitle>
      <Wrapper>
        <OptionSetting>
          <FormWrapper>
            <StyledBaseRow>
              <SettingArea>
                <Title>集計金額</Title>
                <Description>
                  ハンディ端末上部に表示される売上集計金額の表示状態を選べます。
                </Description>
              </SettingArea>
              <RadioButton
                name="salesDisplaysSelect"
                value={SalesDisplays.SHOW.ID}
                label={SalesDisplays.SHOW.LABEL}
                onChange={handleOnChangeIsSalesDisplayed}
                checked={isSalesDisplayed === SalesDisplays.SHOW.VALUE}
              />
              <RadioButton
                name="salesDisplaysSelect"
                value={SalesDisplays.HIDE.ID}
                label={SalesDisplays.HIDE.LABEL}
                onChange={handleOnChangeIsSalesDisplayed}
                checked={isSalesDisplayed === SalesDisplays.HIDE.VALUE}
              />
            </StyledBaseRow>
            <StyledBaseRow>
              <SettingArea>
                <Title>税表示</Title>
                <Description>
                  ハンディの売上金額の表示方法を選べます。
                </Description>
              </SettingArea>
              <RadioButton
                name="taxDisplayTypeSelect"
                value={TaxDisplayType.INCLUDED.ID}
                label={TaxDisplayType.INCLUDED.TEXT}
                onChange={handleOnChangeTaxDisplayType}
                checked={
                  selectedTaxDisplayTypeId === TaxDisplayType.INCLUDED.ID
                }
              />
              <RadioButton
                name="taxDisplayTypeSelect"
                value={TaxDisplayType.EXCLUDED.ID}
                label={TaxDisplayType.EXCLUDED.TEXT}
                onChange={handleOnChangeTaxDisplayType}
                checked={
                  selectedTaxDisplayTypeId === TaxDisplayType.EXCLUDED.ID
                }
              />
            </StyledBaseRow>
          </FormWrapper>
          <ImageWrapper>
            <ImageStyle src={seatsImageUrl} />
          </ImageWrapper>
        </OptionSetting>
      </Wrapper>
    </>
  )
}

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`

const Wrapper = styled.div`
  padding-bottom: 50px;
`

const OptionSetting = styled.div`
  display: flex;
`

const FormWrapper = styled.div`
  margin-right: 32px;
`

const ImageWrapper = styled.div`
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
  padding: 32px 0;
  display: block;
`

const ImageStyle = styled.img`
  max-width: 256px;
  padding-right: 48px;
`

const StyledBaseRow = styled(TableRow)`
  width: 485px;
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
`

const SettingArea = styled(TableColumn)`
  text-align: left;
  width: 224px;
`

const Title = styled.div`
  font-size: ${Size.FONT.LARGE}px;
`

const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`
