import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { KmSettingApiResponse } from '~/src/api/km/settings/types'
import { actions } from './actions'

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.clearState())
  }, [dispatch])
}

export const useInitialize = (kmSettingApiResponse: KmSettingApiResponse) => {
  const dispatch = useDispatch()
  return useCallback(
    () => dispatch(actions.initialize(kmSettingApiResponse)),
    [kmSettingApiResponse, dispatch]
  )
}
