import Encoding from 'encoding-japanese'

export type CSVData = Array<Array<string | undefined>> // FIXME: location

/**
 * CSVData(string[][])を1つの文字列(string)に変換する
 */
class CSVFileWriter {
  write(data: CSVData): Blob {
    const csvText = data
      .map(row =>
        row
          .map(column => column?.replace(/"/g, '""'))
          .map(column => column || '')
          .map(column => `"${column}"`)
      )
      .map(row => row.join(','))
      .map(row => row + '\r\n')
      .join('')

    const byteArray = Array.from(csvText!).map((s: string) => s.charCodeAt(0))
    const encodedData = Encoding.convert(byteArray, 'SJIS', 'UNICODE')
    const uInt8Data = new Uint8Array(encodedData)
    return new Blob([uInt8Data], {
      type: 'text/csv'
    })
  }
}

export default CSVFileWriter
