/**
 * 共通サイドメニュー フッター
 * 設計書:
 */

import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { selectSideMenuFooterState } from './selectors'
import { KoruliBanner } from './KoruliBanner'

const { Color, Size } = Const

export const Footer: React.VFC = () => {
  const { inquiry } = useSelector(selectSideMenuFooterState)

  return (
    <FooterWrapper>
      <Tel>
        <p>ヘルプデスク</p>
        <label>{inquiry.PHONE}</label>
        <p>{inquiry.BUSINESS_HOURS}</p>
        <p>{inquiry.DESCRIPTION}</p>
      </Tel>
      <KoruliBanner />
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  padding: 20px 10px 10px;
  min-width: ${Size.SIDE_MENU_WIDTH}px;
  &,
  a {
    color: ${Color.DARK_GRAY_4};
    text-decoration: none;
  }
`

const Tel = styled.div`
  margin-bottom: 10px;
  p {
    font-size: ${Size.FONT.XSMALL}px;
    margin: 0;
  }
  label {
    font-size: ${Size.FONT.XLARGE}px;
  }
`
