import React from 'react'

import Variation from './Variation'

import CSVItem from '~/src/redux/models/CSVItem/CSVItem'

import VariationGroup from '~/src/redux/models/CSVItem/VariationGroup'

import { TableRow, NameColumn } from '../Common/TableParts'

type CategoryPops = {
  item: CSVItem
  variationGroup: VariationGroup
}

const ItemRow: React.FC<CategoryPops> = props => {
  const { item } = props

  return (
    <>
      <TableRow>
        <NameColumn item>{item.getNameForDisplay()}</NameColumn>
      </TableRow>
      {props.variationGroup.variations.map(variation => (
        <Variation
          key={variation.variationId}
          item={props.item}
          variation={variation}
        />
      ))}
    </>
  )
}

export default React.memo(ItemRow)
