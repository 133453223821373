import React, { useCallback } from 'react'
import styled from 'styled-components'
import { SortableContainer, SortEnd } from 'react-sortable-hoc'
import { List } from 'immutable'
import Hint from '~/src/components/molecules/Hint'
import VariationRow from './Variation'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import { VariationEditor } from '~/src/redux/models/CSVItem/Variation'
import {
  TableRow,
  HandleColumn,
  NameColumn,
  ToggleColumn,
  TaxTypeColumn,
  PriceColumn,
  TrashColumn
} from './Common/TableParts'
import Const from '~/src/const'
const { Size } = Const

type Props = {
  interactor: VariationGroupInteractor
  variations: List<VariationEditor | undefined>
  count: number
}

const SORTABLE_CONTAINER_ID = 'variation-scrollable-container'

const ModalList: React.FC<Props> = props => {
  const onSortEndCb = useCallback(
    (sortEnd: SortEnd) => {
      props.interactor.sortVariationOnEditor(sortEnd.oldIndex, sortEnd.newIndex)
    },
    [props.interactor]
  )

  return (
    <div>
      <TableRow header>
        <HandleColumn />
        <NameColumn>種別1/種別2</NameColumn>
        <ToggleColumn header>
          税率・価格の
          <br />
          個別設定
          <Hint small>
            オフにすると、バリエーションの税率と価格は商品と同じになります。
          </Hint>
        </ToggleColumn>
        <TaxTypeColumn>
          適用税率
          <Hint small>
            税率を設定できます。
            <br />
            「注文時に選択」は設定できません。
            <br />
            「注文時に選択」の商品はハンディでは10%標準税率が適用されます。
          </Hint>
        </TaxTypeColumn>
        <PriceColumn>価格</PriceColumn>
        <TrashColumn>削除</TrashColumn>
      </TableRow>
      <div id={SORTABLE_CONTAINER_ID}>
        <SortableList
          {...props}
          useDragHandle
          lockToContainerEdges
          lockAxis="y"
          onSortEnd={onSortEndCb}
          getContainer={() => document.getElementById(SORTABLE_CONTAINER_ID)!}
        />
      </div>
      <Count>
        ※バリエーションの上限数は30件です。（現在のバリエーション数：
        {props.count}）
      </Count>
    </div>
  )
}

const SortableList = SortableContainer((props: Props) => {
  const { variations } = props
  return (
    <>
      {variations.map((variation, index) => (
        <VariationRow
          key={index}
          index={index}
          variation={variation}
          disabled={variation === undefined}
        />
      ))}
    </>
  )
})

const Count = styled.p`
  padding: 12px 0;
  margin: 0;
  font-size: ${Size.FONT.BASE}px;
`

export default React.memo(ModalList)
