import React from 'react'
import styled, { css } from 'styled-components'

import { Input } from '~/src/components/atoms/Input'
import { Validation } from '~/src/components/atoms/Validation'
import Const from '~/src/const'

const { Color } = Const

type Props = Omit<
  React.ComponentProps<typeof Input>,
  'type' | 'align' | 'placeholder'
> & {
  error?: string | null
}

export const LastOrderInput: React.VFC<Props> = props => {
  const { error, ...rest } = props
  return (
    <Wrapper>
      <StyleOverriddenInput
        type="number"
        inputMode="numeric"
        align="right"
        placeholder="90"
        {...rest}
      />
      {error && <StyleOverriddenValidation>{error}</StyleOverriddenValidation>}
    </Wrapper>
  )
}

// 放題LO案件でこれまでとdisabled時のスタイルが違うinputが必要になったので、既存のInputのスタイルを上書きしている
// https://www.figma.com/file/ZuEYZRNYGTOn1sIw6d0HRN/branch/5ckstrDDosqjNaInvoKl43/%E3%80%90Master%E3%80%91OES-BO?node-id=754%3A8312&mode=dev
const StyleOverriddenInput = styled(Input)<{
  disabled?: boolean
}>`
  /* <input type="number">にすると矢印ボタンが出てしまうので消している */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  ${props =>
    props.disabled &&
    css`
      color: ${Color.LIGHT_GRAY_4};
      background-color: ${Color.WHITE};
      opacity: 1;
    `}
`

const StyleOverriddenValidation = styled(Validation)`
  bottom: 42px;
  white-space: nowrap;
  padding: 4px 6px;
`

const Wrapper = styled.div`
  position: relative;
  width: 96px;
  height: 42px;
`
