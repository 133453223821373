import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { viewSeatSetSeatSelect } from '~/src/redux/modules/SeatSet/SeatSet'

export const useSelectSeatSetSeat = () => {
  const dispatch = useDispatch()
  return useCallback(
    (params: { seatId: string; checked: boolean }) => {
      dispatch(viewSeatSetSeatSelect(params))
    },
    [dispatch]
  )
}
