import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
const { Color } = Const

type MessageType = 'warning' | 'caution' | 'info'

type Props = {
  type: MessageType
  messageList: (string | ReactElement)[]
}

const Message = (props: Props) => {
  const { type, messageList } = props
  if (!messageList.length) {
    return null
  }
  return (
    <MessageWrapper type={type}>
      {messageList.map((message, index) => (
        // TODO name消したい
        // @ts-ignore
        <MessageItem key={`key-message-${index}`} name="message-list-item">
          {message}
        </MessageItem>
      ))}
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div<{ type: MessageType }>`
  /* stylelint-disable-next-line declaration-colon-newline-after */
  background-color: ${props => {
    switch (props.type) {
      case 'warning':
        return Color.PALE_RED_2
      case 'caution':
        return Color.VERY_PALE_ORANGE
      case 'info':
        return Color.VERY_LIGHT_GRAY_9
    }
  }};
  /* stylelint-disable-next-line declaration-colon-newline-after */
  border: 1px solid
    ${props => {
      switch (props.type) {
        case 'warning':
          return Color.MODERATE_RED
        case 'caution':
          return Color.LIGHT_ORANGE
        case 'info':
          return Color.DARK_GRAY_4
      }
    }};
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
`

const MessageItem = styled.p`
  margin: 0;
  padding-bottom: 14px;
  :last-child {
    padding-bottom: 0;
  }
`

export default Message
