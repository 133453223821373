import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Overlay from '~/src/components/atoms/Overlay'
import Button from '~/src/components/atoms/Button'
import Heading from '~/src/components/atoms/Heading'
import { fadeIn, fadeOut } from '~/src/components/particles/Animation'
import { verticalHorizontalCentering } from '~/src/components/particles/Mixin'
import { getContentPaddingTop, getContentPaddingBottom } from '~/src/util/Modal'
import Const from '~/src/const'

const { Color, Size, ZIndex } = Const
const { Children } = React
const { H4 } = Heading

export default props => {
  const {
    children,
    title,
    onClose,
    onOK,
    isOpen,
    cancelText,
    okText,
    noUseCancelButton,
    noUseOKButton,
    cancelButtonSettingSecondary,
    useSecondaryOkButton,
    useWarningOkButton,
    disableOKButton = false
  } = props

  const [hasShown, setHasShown] = useState(isOpen) // 一度でも表示されたかどうか、一度も表示されていない場合は、css animation を適用しない
  const hasHeader = !!title
  useEffect(() => {
    if (isOpen) {
      setHasShown(true)
    }
  }, [isOpen])

  return (
    <Wrapper isOpen={isOpen} canAnimate={hasShown}>
      <Overlay isVisible={isOpen} />
      <ModalWrapper>
        <Content>
          <InnerContent>
            {hasHeader && (
              <Header>
                <Title>{title}</Title>
              </Header>
            )}
            {Children.toArray(children)}
          </InnerContent>
        </Content>
        <ButtonWrapper>
          {!noUseCancelButton && (
            <Button
              normal={!cancelButtonSettingSecondary}
              secondary={cancelButtonSettingSecondary}
              onClick={onClose}
            >
              {cancelText || 'キャンセル'}
            </Button>
          )}
          {!noUseOKButton && (
            <Button
              disabled={disableOKButton}
              primary={!useWarningOkButton && !useSecondaryOkButton}
              secondary={!useWarningOkButton && !!useSecondaryOkButton}
              normal={useWarningOkButton}
              variation={useWarningOkButton && 'red'}
              onClick={onOK}
            >
              {okText || 'OK'}
            </Button>
          )}
        </ButtonWrapper>
      </ModalWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: ${Size.MODAL.MARGIN_Y}px ${Size.MODAL.MARGIN_X}px;
  visibility: hidden;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  z-index: ${ZIndex.AIR_OES_MODAL};
  ${props =>
    props.canAnimate &&
    css`
      animation: ${props => (props.isOpen ? fadeIn : fadeOut)} 0.25s forwards
        ease-in-out;
    `}
`

const Title = styled(H4)`
  border-radius: 8px 8px 0 0;
  height: inherit;
  text-align: center;
  margin: 0;
`

const Header = styled.div`
  color: ${Color.VERY_DARK_GRAY_3};
  margin: 0 0 24px;
  word-wrap: break-word;
  max-width: 320px;
`

const ModalWrapper = styled.div`
  ${verticalHorizontalCentering()}
  overflow: hidden;
  max-height: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: ${Size.MODAL.CONFIRMATION.MAX_WIDTH}px;
  min-width: ${Size.MODAL.CONFIRMATION.MIN_WIDTH}px;
  width: 100%;
  background-color: ${Color.WHITE};
  display: flex;
  flex-direction: column;
`
const ButtonWrapper = styled.div`
  position: relative;
  padding: 11px 20px 12px;
  background-color: ${Color.VERY_LIGHT_GRAY_4};
  box-shadow: 0 -1px 0 0 ${Color.LIGHT_GRAY_3};
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: flex-end;
  height: ${Size.MODAL.FOOTER_HEIGHT}px;
  min-height: ${Size.MODAL.FOOTER_HEIGHT}px;
  box-sizing: border-box;
  > button {
    max-width: 50%;
    height: 40px;
    &:not(:first-child) {
      margin-left: 18px;
    }
  }
`
const Content = styled.div`
  background-color: ${Color.VERY_LIGHT_GRAY};
  overflow-y: auto;
`
const InnerContent = styled.div`
  display: flex;
  min-width: ${Size.MODAL.CONFIRMATION.INNER.MIN_WIDTH}px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: ${getContentPaddingTop}px 16px ${getContentPaddingBottom}px;
`
