import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearState,
  fetchResouces
} from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useInitializeState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchResouces())
  }, [dispatch])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearState())
  }, [dispatch])
}
