import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'
import { PrinterByItemSeatSet, PrintTargetItem } from './types'
const { UndefinedId } = Const

const UNSET_FLOOR_HEADER_LABEL = '注文エリア未設定'

const selectPrintTargetByItems = (state: AppState) => state.printTargetByItems
const selectPrinterSelectBoxes = (state: AppState) =>
  state.printerSelectBoxes.values
const selectSeatSets = createSelector(
  selectPrintTargetByItems,
  ({ seatSets }) => {
    const result = [
      { seatSetId: UndefinedId.SEAT_SET_ID, name: UNSET_FLOOR_HEADER_LABEL }
    ]
    if (seatSets.length === 0) {
      return result
    }
    const sortedSeatSets = seatSets.sort(
      (a, b) => a.displayOrder - b.displayOrder
    )
    sortedSeatSets.forEach(seatSet => {
      result.push({
        seatSetId: seatSet.seatSetId,
        name: seatSet.name
      })
    })
    return result
  }
)
const selectItems = createSelector(
  selectPrintTargetByItems,
  ({ items, isItemSeatSetSettings }): PrintTargetItem =>
    items
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(item => {
        const isItemSeatSetSettingInItem = isItemSeatSetSettings.filter(
          isItemSeatSetSetting => isItemSeatSetSetting.itemId === item.itemId
        )[0]
        return {
          itemId: item.itemId,
          name: item.itemNameForInput,
          isDisplayed: item.isDisplayed,
          isItemSeatSetSetting: isItemSeatSetSettingInItem
            ? isItemSeatSetSettingInItem.checked
            : false
        }
      })
)
const selectPrinterByItemSeatSets = createSelector(
  selectPrinterSelectBoxes,
  selectPrintTargetByItems,
  (basePrinterByItemSeatSets, { printersForSort }): PrinterByItemSeatSet[] => {
    const printerByItemSeatSetsForSort = basePrinterByItemSeatSets.map(
      basePrinterByItemSeatSet => {
        return {
          ...basePrinterByItemSeatSet,
          createdAt: printersForSort[basePrinterByItemSeatSet.printerId]
        }
      }
    )
    const sortedPrinterByItemSeatSets = printerByItemSeatSetsForSort.sort(
      (a, b) => a.createdAt - b.createdAt
    )

    return sortedPrinterByItemSeatSets.map(sortedPrinterByItemSeatSet => {
      return {
        itemId: sortedPrinterByItemSeatSet.itemId,
        seatSetId:
          sortedPrinterByItemSeatSet.seatSetId || UndefinedId.SEAT_SET_ID,
        printerId:
          sortedPrinterByItemSeatSet.printerId || UndefinedId.PRINTER_ID
      }
    })
  }
)
const selectCategory = createSelector(
  selectPrintTargetByItems,
  ({ category }) => ({
    categoryId: category.categoryId,
    name: category.categoryNameForInput
  })
)

export const selectPrintTargetByItemForm = createSelector(
  (state: AppState) => state.Forward.shouldForward,
  selectPrintTargetByItems,
  selectSeatSets,
  selectItems,
  selectPrinterByItemSeatSets,
  selectCategory,
  (
    shouldForward,
    printTargetByItems,
    seatSets,
    items,
    printerByItemSeatSets,
    category
  ) => ({
    category,
    items,
    seatSets,
    printerByItemSeatSets,
    shouldForward,
    isEditing: printTargetByItems.isEditing,
    isNotFound: printTargetByItems.isNotFound
  })
)
