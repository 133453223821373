import { Record } from 'immutable'

export type Time = {
  minutes: number
  seconds: number
}

interface EstimatedTargetServingTimeProps {
  itemId: string
  noticeSecond?: number
  alertSecond?: number
  confidence: number
}

const EstimatedTargetServingTimeRecord =
  Record<EstimatedTargetServingTimeProps>({
    itemId: '',
    noticeSecond: undefined,
    alertSecond: undefined,
    confidence: -1
  })

class EstimatedTargetServingTime
  extends EstimatedTargetServingTimeRecord
  implements EstimatedTargetServingTimeProps
{
  public static EMPTY: EstimatedTargetServingTime =
    new EstimatedTargetServingTime()

  public displayNoticeTime(): string {
    return this.displayTime(
      EstimatedTargetServingTime.getTime(this.noticeSecond)
    )
  }

  public displayAlertTime(): string {
    return this.displayTime(
      EstimatedTargetServingTime.getTime(this.alertSecond)
    )
  }

  public isHighConfidence(): boolean {
    return this.confidence > 0
  }

  private displayTime = (time?: Time): string => {
    if (time == null) {
      return '-分-秒'
    }

    return `${time.minutes}分${time.seconds}秒`
  }

  public static getTime(second?: number): Time | undefined {
    if (second == null) {
      return
    }

    const minutes = (second / 60) | 0
    if (minutes > 999) {
      return {
        minutes: 999,
        seconds: 59
      }
    }
    const seconds = second % 60

    return {
      minutes,
      seconds
    }
  }
}

export default EstimatedTargetServingTime
