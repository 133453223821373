import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearDeviceCountState,
  fetchResources
} from '~/src/redux/modules/DeviceCount/DeviceCount'
import Const from '~/src/const'
import { useHistory } from 'react-router'

const { Page } = Const

export const useInitialize = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchResources())
  }, [dispatch])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return useCallback(() => {
    const regExp = new RegExp(Page.DEVICE_COUNT.UPDATE)
    if (!regExp.test(history.location.pathname)) {
      dispatch(clearDeviceCountState())
    }
  }, [dispatch, history.location.pathname])
}
