import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { Modal } from '@air-kit/air-kit'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { List } from './List/List'
import { Item as RowItem, SelectedItem } from './List/Row/Row'
import { SearchArea, ALL_SELECT_CATEGORY } from './SearchArea/SearchArea'
import Const from '~/src/const'
const { Size, Color, URL } = Const

type Props = {
  categories: Category[]
  onSubmit: (selectedItem: SelectedItem) => void
  onClose: () => void
}

type Category = {
  id: string
  name: string
  items?: Item[]
}

export type Item = {
  id: string
  name: string
  price: number
  taxType: string
  isDisplayed: boolean // trueの場合は 商品名の後に (非表示) を表示する
  disabled: boolean
}

const searchCategories = (categories: Category[], categoryId: string) => {
  return categories.flatMap(category => {
    if (!category.items) {
      return []
    }

    const searchedCategories: RowItem[] = []

    category.items.forEach(item => {
      if (categoryId === category.id || categoryId === ALL_SELECT_CATEGORY) {
        searchedCategories.push({
          ...item,
          categoryName: category.name
        })
      }
    })

    return searchedCategories
  })
}

const INITIAL_ITEM_ID = 'undefined'
const INITIAL_ITEM_NAME = 'undefined'

export const RegisterItemModal: React.VFC<Props> = props => {
  const categories = props.categories
  const [selectedCategoryId, setSelectedCategoryId] =
    useState(ALL_SELECT_CATEGORY)
  const [selectedItem, setSelectedItem] = useState({
    id: INITIAL_ITEM_ID,
    name: INITIAL_ITEM_NAME
  })
  const isCourse = useLocation().pathname.includes('course')
  const title = isCourse ? 'コース名商品の設定' : '放題プラン名商品の設定'
  const settingTarget = isCourse ? 'コース' : '放題プラン'
  const description = isCourse
    ? 'コースの名前とする商品を設定します。'
    : '放題プランの名前とする商品を設定します。'
  const displayItems = searchCategories(categories, selectedCategoryId)

  const onClickSearch = (categoryId: string) => {
    setSelectedCategoryId(categoryId)
  }

  const onChangeRadio = (selectedItem: SelectedItem) => {
    setSelectedItem({ id: selectedItem.id, name: selectedItem.name })
  }

  const footer = {
    right: (
      <>
        <StyledButton normal name="cancel-button" onClick={props.onClose}>
          キャンセル
        </StyledButton>
        <StyledButton
          primary
          disabled={selectedItem.id === INITIAL_ITEM_ID}
          onClick={() => {
            props.onSubmit(selectedItem)
          }}
        >
          登録する
        </StyledButton>
      </>
    )
  }
  return (
    <Adjuster>
      <StyledModal title={title} footer={footer} onClose={props.onClose}>
        <Description>
          {description}
          商品は
          <InlineLink href={URL.REGI_ITEM} target="_blank">
            Airレジの商品設定ページ
          </InlineLink>
          から追加してください。
          <Detail>
            ※コースまたは放題プラン内容に登録されている商品、バリエーションが設定されている商品、トッピング商品またはトッピング対象商品、カテゴリーが未設定の商品、お通し商品は
            {settingTarget}に登録できません。
          </Detail>
        </Description>
        <SearchArea
          categories={categories.map(category => ({
            id: category.id,
            name: category.name
          }))}
          onClick={onClickSearch}
        />
        <List
          items={displayItems}
          onChange={onChangeRadio}
          selected={selectedItem.id}
        />
      </StyledModal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`

const Description = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.LARGE}px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: justify;
`

const Detail = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.BASE}px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: justify;
`

const StyledButton = styled(Button)`
  width: auto;
`

const StyledModal = styled(Modal)`
  *:not(:first-child) {
    margin-top: 0;
  }
`
