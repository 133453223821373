import React from 'react'
import ValidatableSelectBox from '~/src/components/molecules/ValidatableSelectBox'

import Const from '~/src/const'

const { AnalysisTagOptions } = Const

export default props => {
  const {
    name,
    selectedId,
    validateMode,
    handleSelectChange,
    disabled,
    isFirstChild
  } = props

  return (
    <ValidatableSelectBox
      disabled={disabled}
      name={name}
      placeholder="選択してください"
      options={AnalysisTagOptions}
      selectedId={selectedId}
      handleSelectChange={handleSelectChange}
      error={validateMode}
      errorAlign={isFirstChild ? 'bottom' : 'top'}
    />
  )
}
