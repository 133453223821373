import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { replacePrinterByCategoryAndSeatSet } from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useHandleReplacePrinter = (
  categoryId: string,
  seatSetId: string
) => {
  const dispatch = useDispatch()
  return useCallback(
    (printerIds: string[]) => {
      dispatch(
        replacePrinterByCategoryAndSeatSet(categoryId, seatSetId, printerIds)
      )
    },
    [categoryId, dispatch, seatSetId]
  )
}
