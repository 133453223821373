import { Record, List } from 'immutable'
import Categories from '~/src/redux/models/Category/Categories'
import Const from '~/src/const'
import KitchenPositionByCategoryModel from './KitchenPositionByCategory'
import { KitchenPositionByCategory } from '../../modules/KitchenPositionSetting/types'

const { UndefinedId } = Const

const KitchenPositionByCategoriesRecord = Record({
  kitchenPositionByCategories: List<KitchenPositionByCategoryModel>()
})

export default class KitchenPositionByCategories extends KitchenPositionByCategoriesRecord {
  constructor(
    kitchenPositionByCategories: KitchenPositionByCategory[] = [],
    categories = new Categories(),
    defaultKitchenPositionId: string | null = ''
  ) {
    super()
    const list = categories.categories
      .map(category => {
        return new KitchenPositionByCategoryModel(
          category,
          List(kitchenPositionByCategories), // 扱いやすいようにListにして渡す
          defaultKitchenPositionId,
          category.items.items.toList().map(item => item.itemId)
        )
      })
      .sort((a, b) => a.displayOrder - b.displayOrder)
    return this.merge({ kitchenPositionByCategories: list })
  }

  updateKitchenPositionId(categoryId: string, kitchenPositionId: string) {
    const index = this.kitchenPositionByCategories.findIndex(
      byCategory => byCategory.categoryId === categoryId
    )
    const list = this.kitchenPositionByCategories.update(index, byCategory =>
      // @ts-ignore
      byCategory.setKitchenPositionId(kitchenPositionId)
    )
    return this.set('kitchenPositionByCategories', list)
  }

  updateAnalysisTag(categoryId: string, analysisTag: string) {
    const index = this.kitchenPositionByCategories.findIndex(
      byCategory => byCategory.categoryId === categoryId
    )
    const list = this.kitchenPositionByCategories.update(index, byCategory =>
      // @ts-ignore
      byCategory.setAnalysisTag(analysisTag)
    )
    return this.set('kitchenPositionByCategories', list)
  }

  updateAnalysisTagError(categoryId: string) {
    const index = this.kitchenPositionByCategories.findIndex(
      byCategory => byCategory.categoryId === categoryId
    )
    const list = this.kitchenPositionByCategories.update(index, byCategory =>
      // @ts-ignore
      byCategory.validateAnalysisTagIfNeeded()
    )
    return this.set('kitchenPositionByCategories', list)
  }

  buildRequestObject() {
    return this.kitchenPositionByCategories.map(byCategory => {
      if (UndefinedId.SELECTBOX_ID === byCategory.kitchenPositionId) {
        return {
          categoryId: byCategory.categoryId,
          analysisTag: byCategory.analysisTag
        }
      }

      return {
        categoryId: byCategory.categoryId,
        kitchenPositionId: byCategory.kitchenPositionId,
        analysisTag: byCategory.analysisTag
      }
    })
  }

  hasAutoCompleted() {
    return !!this.kitchenPositionByCategories.find(
      byCategory => byCategory.isAutoCompleted
    )
  }

  getByCategoryId(categoryId?: string) {
    return this.kitchenPositionByCategories.find(
      byCategory => byCategory.categoryId === categoryId
    )
  }

  getInvalidCategoryIds() {
    return this.kitchenPositionByCategories
      .filter(byCategory => !!byCategory.analysisTagError)
      .map(byCategory => byCategory.categoryId)
  }

  validateAnalysisTagsIfNeeded() {
    const list = this.kitchenPositionByCategories.map(byCategory =>
      byCategory.validateAnalysisTagIfNeeded()
    )
    return this.set('kitchenPositionByCategories', list)
  }

  validateAnalysisTagByCategoryForItemSetting(categoryId: string) {
    const index = this.kitchenPositionByCategories.findIndex(
      byCategory => byCategory.categoryId === categoryId
    )
    // 存在しているカテゴリーのindexを取得しているため、強制アンラップする
    const list = this.kitchenPositionByCategories.update(index, byCategory =>
      byCategory!.validateAnalysisTagForItemSetting()
    )
    return this.set('kitchenPositionByCategories', list)
  }

  hasAnalysisTagError() {
    return this.kitchenPositionByCategories.some(
      byCategory => !!byCategory.analysisTagError
    )
  }

  hasAnalysisTagErrorByCategory(categoryId: string) {
    return this.kitchenPositionByCategories
      .filter(byCategory => byCategory.categoryId === categoryId)
      .some(byCategory => !!byCategory.analysisTagError)
  }
}
