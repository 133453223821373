import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { DownArrowIcon } from '~/src/components/atoms/Icon'
import TableRow from '~/src/components/atoms/TableRow'
import Const from '~/src/const'
import { ContentColumn, FunctionColumn } from './Column'
import { useHandleClickItem } from '../../../hooks'

const { Time, Color } = Const

export type Category = {
  categoryId: string
  categoryName: string
  isDisplayed: boolean
  items: Item[]
}

export type Item = {
  itemName: string
  displayItemName?: string
  itemId: string
  categoryId?: string
  otoshiId?: string
  isDeleted: boolean
  disabled: boolean
  hasError: boolean
}

type Props = {
  category: Category
}

export const Row: React.VFC<Props> = props => {
  const { category } = props

  const [isFolded, setIsFolded] = useState(true)
  const [listHeight, setListHeight] = useState(null)
  const menuListlCallback = useCallback(
    node => {
      if (!node) {
        return
      }
      setListHeight(node.clientHeight)
    },
    [setListHeight]
  )
  const onTitleClicked = () => {
    setIsFolded(!isFolded)
  }

  const handleClickItem = useHandleClickItem()

  return (
    <>
      <AccordionTitle onClick={onTitleClicked}>
        <StyledTableRow>
          <ContentColumn>
            {category.categoryName}
            {!category.isDisplayed && ' (非表示)'}
          </ContentColumn>
          <FunctionColumn>
            <ArrowWrapper isFolded={isFolded}>
              <DownArrowIcon />
            </ArrowWrapper>
          </FunctionColumn>
        </StyledTableRow>
      </AccordionTitle>
      <AccordionList isFolded={isFolded} height={listHeight}>
        <div ref={menuListlCallback}>
          {category.items.map((item, i) => {
            return (
              <StyledTableRowItem
                key={`key-${category.categoryId}-${item.itemId}`}
                onClick={handleClickItem(item)}
                disable={item.disabled}
              >
                <ContentColumn>{item.displayItemName}</ContentColumn>
              </StyledTableRowItem>
            )
          })}
        </div>
      </AccordionList>
    </>
  )
}

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
`

const AccordionList = styled.div<{
  isFolded: boolean
  height: number | null
}>`
  max-height: ${props => props.height}px;
  max-width: 100%;
  overflow: hidden;
  transition: all ease ${Time.ACCORDION}ms;
  ${props =>
    props.isFolded &&
    css`
      max-height: 0;
    `}
`

const ArrowWrapper = styled.span<{
  isFolded: boolean
}>`
  svg {
    transition: all ease ${Time.ACCORDION}ms;
    transform: rotate(-180deg);
  }
  ${props =>
    props.isFolded &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  height: 50px;
  font-weight: bold;
`

const StyledTableRowItem = styled(StyledTableRow)<{
  disable: boolean
}>`
  padding-left: 30px;
  font-weight: normal;
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  background: ${props =>
    props.disable ? Color.VERY_LIGHT_GRAY_9 : Color.WHITE};
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  cursor: ${props => (props.disable ? 'auto' : 'pointer')};
`
