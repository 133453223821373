import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import SelectBox from '~/src/components/atoms/SelectBox'
import Button from '~/src/components/atoms/Button'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import ServingTimeModes from '~/src/const/serving-time-mode'
import Const from '~/src/const'

const { Size, Color } = Const

type Props = {
  interactor: TargetServingTimeInteractor
  categories: SimpleCategory[]
  selectedCategoryId?: string
  hasError: boolean
  isKmAvailable: boolean
}

const CategorySelectArea: React.FC<Props> = props => {
  const {
    interactor,
    categories,
    selectedCategoryId,
    hasError,
    isKmAvailable
  } = props

  const categoryOptions = useMemo(
    () =>
      categories.map(category => ({
        id: category.categoryId,
        value: category.categoryId,
        label: category.categoryName
      })),
    [categories]
  )

  const selectedCategoryCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      interactor.changeCategory(e.target.value)
    },
    [interactor]
  )

  const openBatchEditFormModalCb = useCallback(() => {
    interactor.openBatchEditFormModal(
      isKmAvailable ? ServingTimeModes.AUTO.ID : ServingTimeModes.MANUAL.ID
    )
  }, [interactor, isKmAvailable])

  return (
    <>
      {isKmAvailable && <SettingTitle>目標提供時間</SettingTitle>}
      <InputArea>
        <SelectBoxArea>
          <StyledSelectBox
            name="search-by-category-select"
            placeholder="カテゴリーを選択してください"
            options={categoryOptions}
            handleSelectChange={selectedCategoryCb}
            selectedId={selectedCategoryId}
            disabled={hasError}
          />
        </SelectBoxArea>
        <ButtonArea>
          <Button type="button" normal onClick={openBatchEditFormModalCb}>
            一括編集
          </Button>
        </ButtonArea>
      </InputArea>
    </>
  )
}

export default CategorySelectArea

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const InputArea = styled.div`
  padding: 16px 0 12px;
  display: flex;
  justify-content: space-between;
`

const SelectBoxArea = styled.div`
  flex: 1 1 auto;
`

const StyledSelectBox = styled(SelectBox)`
  max-width: 400px;
`

const ButtonArea = styled.div`
  flex: 0 0 120px;
  padding-left: 4px;
`
