import React from 'react'
import styled from 'styled-components'
import HeaderRow from './Row/HeaderRow'
import { Row, Item, SelectedItem } from './Row/Row'
import Const from '~/src/const'
const { Size, Color } = Const

type Props = {
  items: Item[]
  selected: string
  onChange: (selectedItem: SelectedItem) => void
}

export const List: React.VFC<Props> = props => {
  return (
    <Wrapper>
      <HeaderRow />
      {!props.items.length && (
        <NotFound>
          検索結果: 0件
          <br />
          該当する商品情報がありませんでした。
          <br />
          検索条件を変更し、再度検索してください。
        </NotFound>
      )}
      {props.items.map(item => (
        <Row
          key={item.id}
          item={item}
          onChange={props.onChange}
          selected={props.selected}
        />
      ))}
    </Wrapper>
  )
}

// <> にしたかったが、AirKitのスタイルが当たってしまい余計なmarginが発生するため <div> にする
const Wrapper = styled.div``

const NotFound = styled.div`
  padding-top: 36px;
  height: 60px;
  width: 744px;
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.BASE}px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`
