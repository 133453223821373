import { createAction } from 'typesafe-actions'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { HandySettingApiResponse, HandySettingApiDispatch } from './types'
import { clearLoading, setLoading } from '~/src/redux/modules/UI'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'
import { Response } from '../../types'

const { API } = Const

const CLEAR_STATE = 'oes/api/handy/settings/CLEAR_STATE'
const FETCH_SUCCEEDED = 'oes/api/handy/settings/FETCH_SUCCEEDED'
const FETCH_FAILED = 'oes/api/handy/settings/FETCH_FAILED'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  fetchSucceeded: createAction(
    FETCH_SUCCEEDED,
    (response: HandySettingApiResponse) => ({ response })
  )(),
  fetchFailed: createAction(FETCH_FAILED)(),
  fetchHandySetting:
    (apiClient: ApiClientInterface = ApiClient) =>
    (dispatch: HandySettingApiDispatch) => {
      dispatch(setLoading())
      return apiClient
        .get(API.GET_HANDY_SETTING)
        .then((response: Response<HandySettingApiResponse>) => {
          dispatch(actions.fetchSucceeded(response.data.result))
        })
        .catch(error => {
          dispatch(handleError(error))
          dispatch(actions.fetchFailed())
        })
        .finally(() => dispatch(clearLoading()))
    }
}

export const types = {
  CLEAR_STATE,
  FETCH_SUCCEEDED,
  FETCH_FAILED
}
