import React from 'react'
import styled from 'styled-components'
import { datadogLogs } from '@datadog/browser-logs'

import Const from '~/src/const'
import Radio from '~/src/components/atoms/Radio'
import { LastOrderInput } from './LastOrderInput'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { useSelector } from 'react-redux'
import {
  selectLastOrderMinutes,
  selectLastOrderMinutesInputError,
  selectLastOrderSetMode
} from './selectors'
import {
  useUpdateLastOrderIsComposing,
  useUpdateLastOrderMinutes,
  useUpdateLastOrderSetMode
} from './hooks'
import { Datadog } from '~/src/const/datadog'

const { URL } = Const

export const LastOrderSetting: React.VFC = () => {
  const lastOrderSetMode = useSelector(selectLastOrderSetMode)
  const lastOrderMinutes = useSelector(selectLastOrderMinutes)
  const lastOrderMinutesInputError = useSelector(
    selectLastOrderMinutesInputError
  )

  const updateLastOrderMinutes = useUpdateLastOrderMinutes()
  const updateLastOrderSetMode = useUpdateLastOrderSetMode()
  const updateLastOrderIsComposing = useUpdateLastOrderIsComposing()

  return (
    <>
      <LastOrderSettingSelect>
        <Radio
          name="lastOrderSetting"
          value="auto"
          onChange={e =>
            updateLastOrderSetMode(
              (e.target as HTMLInputElement).value as 'manual' | 'auto'
            )
          }
          checked={lastOrderSetMode === 'auto'}
        />
        <AutoLastOrderSetLabel onClick={e => updateLastOrderSetMode('auto')}>
          放題プラン注文から
          <InputWrapper>
            <LastOrderInput
              value={lastOrderMinutes}
              onChange={e => updateLastOrderMinutes(e.target.value)}
              onKeyUp={e =>
                updateLastOrderIsComposing(e.nativeEvent.isComposing)
              }
              // number inputだと"e"が入力できてしまい、ハンドリングが面倒なのでe入力時は値を更新しないようにしている
              onKeyDown={e => {
                if (e.key === 'e' || e.key === '+' || e.key === '-') {
                  e.preventDefault()
                }
              }}
              disabled={lastOrderSetMode === 'manual'}
              error={lastOrderMinutesInputError}
              invalid={typeof lastOrderMinutesInputError !== 'undefined'}
            />
          </InputWrapper>
          分後
        </AutoLastOrderSetLabel>
      </LastOrderSettingSelect>
      <Caption>
        ※モバイルオーダー 店内版をご契約の場合は、
        <InlineLink
          href={URL.SPO_AUTO_STOP_ORDER}
          target="_blank"
          onClick={() =>
            datadogLogs.logger.info(
              Datadog.BROWSER_LOG_MESSAGE.SPO_ORDER_LIMIT.MESSAGE
            )
          }
        >
          注文自動停止機能
        </InlineLink>
        をオンにする必要があります。
      </Caption>
      <LastOrderSettingSelect>
        <Radio
          name="lastOrderSetting"
          value="manual"
          onChange={e =>
            updateLastOrderSetMode(
              (e.target as HTMLInputElement).value as 'manual' | 'auto'
            )
          }
          checked={lastOrderSetMode === 'manual'}
        />
        <ManualLastOrderSetLabel
          onClick={e => updateLastOrderSetMode('manual')}
        >
          注文時に手動で設定する
        </ManualLastOrderSetLabel>
      </LastOrderSettingSelect>
    </>
  )
}

const LastOrderSettingSelect = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
`

const AutoLastOrderSetLabel = styled.label`
  padding-left: 10px;
  display: flex;
  align-items: center;
`

const InputWrapper = styled.span`
  padding-left: 8px;
  padding-right: 8px;
`

const Caption = styled.div`
  padding-left: 48px;
  padding-top: 12px;
  padding-bottom: 16px;
  font-weight: 300;
  font-size: 14px;
  color: #999;
`

const ManualLastOrderSetLabel = styled.div`
  padding-left: 10px;
`
