import React from 'react'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import Const from '~/src/const'
import styled from 'styled-components'
import { LastOrderSetting } from './LastOrderSetting/LastOrderSetting'

const { Color, Weight } = Const

export const Step3: React.VFC = () => {
  return (
    <>
      <StepContainer>
        <StepTitleArea>
          <StepBadge stepNum={3} />
          <StepTitle>放題プランL.O.時間の設定</StepTitle>
        </StepTitleArea>
        <ItemDescription>
          放題プランのL.O.時間を設定します。放題プラン開始後でもハンディからL.O.時間を変更できます。
        </ItemDescription>
        <LastOrderSetting />
      </StepContainer>
    </>
  )
}

const StepContainer = styled.div`
  padding-bottom: 48px;
`

const StepTitleArea = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const StepTitle = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: 18px;
  line-height: 27px;
  padding-left: 8px;
  font-weight: ${Weight.BOLD};
`

const ItemDescription = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`
