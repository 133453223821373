import { List, Map, OrderedMap, Set } from 'immutable'
import { ActionType, getType } from 'typesafe-actions'

import Categories from '~/src/redux/models/Category/Categories'
import Items from '~/src/redux/models/Item/Items'
import { arrayMove } from '~/src/util/ArrayUtils'

import { ToppingGroupState, ItemError, SelectedToppingError } from './types'
import { actions } from './actions'
import {
  HodaiApiResponse,
  CourseApiResponse
} from '~/src/api/handy/items/types'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'

const initialState: ToppingGroupState = {
  categories: OrderedMap<string, SimpleCategoryForTopping>(),
  items: OrderedMap<string, SimpleItemForTopping>(),
  itemErrors: Map<string, Set<ItemError>>(),
  toppingGroupVersion: undefined,
  isToppingGroupNotFound: false,
  toppingGroupName: {
    value: '',
    isError: false
  },
  selectedToppingItemIds: List(),
  selectedToppingErrors: Map<string, Set<SelectedToppingError>>(),
  isEdited: false
}

export const toppingGroup = (
  state: ToppingGroupState = initialState,
  action: ActionType<typeof actions>
): ToppingGroupState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.fetchInitialDataSucceeded): {
      const result = action.payload.items.result
      const toppingGroupId = action.payload.toppingGroupId // 新規作成なら undefined

      const items = new Items(result.items)
      const categories = new Categories(
        result.categories,
        items,
        result.itemCategoryJunctions
      )
      const hodais = result.hodais
      const courses = result.courses
      // 新規作成なら undefined
      const toppingGroup = result.toppingGroups?.find(
        toppingGroup => toppingGroup.toppingGroupId === toppingGroupId
      )

      const toppingGroupName = toppingGroup
        ? toppingGroup.name
        : state.toppingGroupName.value
      const toppingItemIds = toppingGroup?.toppings
        ?.sort((a, b) => a.displayOrder - b.displayOrder)
        .map(topping => topping.itemId)
        .filter(itemId => items.items.has(itemId)) // 裏でレジから削除されている商品は処理対象外とする

      return {
        ...state,
        categories: categories.getSimpleCategoryForToppings(),
        items: items.getSimpleItemForToppings(),
        itemErrors: generateItemErrors(items, hodais, courses),
        toppingGroupVersion: toppingGroup?.version,
        isToppingGroupNotFound: !!toppingGroupId && !toppingGroup,
        toppingGroupName: {
          ...state.toppingGroupName,
          value: toppingGroupName
        },
        selectedToppingItemIds: toppingItemIds ? List(toppingItemIds) : List(),
        selectedToppingErrors: generateSelectedToppingErrors(
          categories,
          items,
          toppingItemIds
        )
      }
    }
    case getType(actions.editToppingGroupName): {
      return {
        ...state,
        isEdited: true,
        toppingGroupName: {
          ...state.toppingGroupName,
          value: action.payload.toppingGroupName
        }
      }
    }
    case getType(actions.setToppingGroupNameErrorStatus): {
      if (action.payload.toBe === state.toppingGroupName.isError) {
        return state
      }
      return {
        ...state,
        toppingGroupName: {
          ...state.toppingGroupName,
          isError: action.payload.toBe
        }
      }
    }
    case getType(actions.addItemToToppingGroup): {
      return {
        ...state,
        isEdited: true,
        selectedToppingItemIds: state.selectedToppingItemIds.push(
          action.payload.itemId
        )
      }
    }
    case getType(actions.addCategoryToToppingGroup): {
      const itemIdsToBeAdded = state.categories
        .get(action.payload.categoryId)!
        .itemIds.filter(itemId => {
          const errors = state.itemErrors.get(itemId)
          return errors ? errors.isEmpty() : true
        })
        .filter(itemId => !state.selectedToppingItemIds.includes(itemId))
      return {
        ...state,
        isEdited: true,
        selectedToppingItemIds:
          state.selectedToppingItemIds.merge(itemIdsToBeAdded)
      }
    }
    case getType(actions.deleteAllFromToppingGroup): {
      return {
        ...state,
        isEdited: true,
        selectedToppingItemIds: initialState.selectedToppingItemIds,
        selectedToppingErrors: initialState.selectedToppingErrors
      }
    }
    case getType(actions.deleteItemFromToppingGroup): {
      return {
        ...state,
        isEdited: true,
        selectedToppingItemIds: state.selectedToppingItemIds.delete(
          state.selectedToppingItemIds.indexOf(action.payload.itemId)
        ),
        selectedToppingErrors: state.selectedToppingErrors.delete(
          action.payload.itemId
        )
      }
    }
    case getType(actions.didReOrderSelectedToppingItemIds): {
      const reorderedToppingItemIds = arrayMove(
        state.selectedToppingItemIds.toArray(),
        action.payload.oldIndex,
        action.payload.newIndex
      ) as string[]
      return {
        ...state,
        isEdited: true,
        selectedToppingItemIds: List.of<string>(...reorderedToppingItemIds)
      }
    }
    case getType(actions.saveToppingGroupSucceeded): {
      return {
        ...state,
        isEdited: false
      }
    }
    default:
      return state
  }
}

const generateItemErrors = (
  items: Items,
  hodais?: HodaiApiResponse[],
  courses?: CourseApiResponse[]
) => {
  let itemErrors = Map<string, Set<ItemError>>()
  items.items.forEach(item => {
    let errors = Set<ItemError>()
    if (hodais?.some(hodai => hodai.itemId === item.itemId)) {
      errors = errors.add('HODAI')
    }
    if (courses?.some(course => course.itemId === item.itemId)) {
      errors = errors.add('COURSE')
    }
    if (item.hasSKU()) {
      errors = errors.add('HAS_SKU')
    }
    if (item.isTaxFree()) {
      errors = errors.add('TAX_FREE')
    }
    if (!errors.isEmpty()) {
      itemErrors = itemErrors.set(item.itemId, errors)
    }
  })
  return itemErrors
}

const generateSelectedToppingErrors = (
  categories: Categories,
  items: Items,
  toppingItemIds?: string[]
) => {
  let selectedToppingErrors = Map<string, Set<SelectedToppingError>>()
  toppingItemIds?.forEach(itemId => {
    let errors = Set<SelectedToppingError>()
    const item = items.items.get(itemId)
    if (!item) {
      return // 削除済みの商品については以降のエラーは発生し得ないので、次の商品へ
    }
    if (!categories.hasItem(itemId)) {
      errors = errors.add('NO_CATEGORY')
    }
    if (item.hasSKU()) {
      errors = errors.add('HAS_SKU')
    }
    if (item.isTaxFree()) {
      errors = errors.add('TAX_FREE')
    }
    if (!errors.isEmpty()) {
      selectedToppingErrors = selectedToppingErrors.set(itemId, errors)
    }
  })
  return selectedToppingErrors
}

// export for testing
export const testables = {
  initialState
}
