import React, { useCallback } from 'react'
import Button from '~/src/components/atoms/Button'
import styled from 'styled-components'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

type FooterProps = {
  interactor: VariationGroupInteractor
  isValid: boolean
}

const Footer: React.FC<FooterProps> = props => {
  const onCancelCb = useCallback(() => {
    props.interactor.cancelVariationGroupEditor()
  }, [props.interactor])
  const onCreateCb = useCallback(() => {
    props.interactor.applyVariationGroupEditor()
  }, [props.interactor])
  return (
    <div>
      <StyledButton normal type="button" onClick={onCancelCb}>
        キャンセル
      </StyledButton>
      <StyledButton
        primary
        type="button"
        disabled={!props.isValid}
        onClick={onCreateCb}
      >
        登録する
      </StyledButton>
    </div>
  )
}

const StyledButton = styled(Button)`
  width: auto;
`

export default React.memo(Footer)
