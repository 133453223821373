import React, { useEffect } from 'react'
import { SeatSetListFooter } from './Footer'
import { InnerList } from './InnerList'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { useSelector } from 'react-redux'
import { useInitialize, useDeinit, useSubmit } from './hooks'
import { selectIsEditing } from './selectors'

export const List = () => {
  const isEditing = useSelector(selectIsEditing)
  const handleInitialize = useInitialize()
  const handleDeinit = useDeinit()
  const handleSubmit = useSubmit()

  useEffect(() => {
    handleInitialize()
    return handleDeinit
  }, [handleDeinit, handleInitialize])

  const footer = (
    <SeatSetListFooter isEditing={isEditing} onSubmit={handleSubmit} />
  )

  const modal = <DismissPrompt enabled={isEditing} />
  const breadcrumbs = [
    {
      title: '注文エリア'
    }
  ]

  const description = [
    '注文エリアは複数のテーブルをまとめたものです。注文エリアごとにキッチンへ注文を振り分けることができます。ドリンカーが1Fと2Fにある場合などにお使いください。'
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={footer}
      modal={modal}
      displayKM
      displayKP
    >
      <InnerList />
    </PageTemplate>
  )
}
