import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const/'

const { Page } = Const

export const selectBatchSelect = (state: AppState) => {
  const resources = state.printTargetByCategories
  const { shouldForward } = state.Forward
  const isReloaded = !state.printTargetByCategories.isFetched
  const isNotFound = state.printTargetByCategories.isSeatSetNotFound

  // 一括選択で選択状態にあるときには、印刷振り分け設定画面への遷移で破棄モーダルを表示する。
  const whiteList = resources.batchSelect.isSelected()
    ? []
    : [Page.PRINTER_TARGET.CATEGORIES]
  // 振り分け画面で編集有り、または一括設定画面で選択有りの場合に破棄確認を有効にする。
  const isDismissPromptEnable =
    resources.isEditing || resources.batchSelect.isSelected()
  return {
    isNotFound,
    whiteList,
    isDismissPromptEnable,
    // 再読み込み時には、エラーモーダルを出すことなく印刷振り分け設定画面へ遷移させる
    shouldForward: shouldForward || isReloaded
  }
}
