import React, { useEffect } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import { InnerTable } from './InnerTable/InnerTable'
import { InnerExample } from './InnerExample/InnerExample'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Message from '~/src/components/atoms/Message'
import { useSelector } from 'react-redux'
import { selectDeviceCountSummary } from './selectors'
import { useClearState, useInitialize } from './hooks'

const { PlanPriceTypes } = Const

export const Summary: React.VFC = () => {
  const planPriceType = useSelector(selectDeviceCountSummary)
  const handleInitialize = useInitialize()
  const handleClearState = useClearState()

  useEffect(() => {
    handleInitialize()
    return handleClearState
  }, [handleInitialize, handleClearState])

  const breadcrumbs = [{ title: '利用台数' }]
  const description = ['使用するハンディ端末の利用台数を設定できます。']

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      displayMangerOnly
      displayContractedOnly
      displayKM
      displayKP
    >
      <SummaryContainer>
        {planPriceType?.ID === PlanPriceTypes.UNSPECIFIED.ID && (
          <MessageContainer>
            <Message
              type="warning"
              messageList={[
                '契約内容を切り替え中のため、一部の情報を表示できません。',
                '適用中の契約内容を確認したい場合は、ヘルプデスクにお問い合わせください。'
              ]}
            />
          </MessageContainer>
        )}
        <InnerTable />
        <InnerExample />
      </SummaryContainer>
    </PageTemplate>
  )
}

const SummaryContainer = styled.div`
  padding: 0;
`

const MessageContainer = styled.div`
  margin-bottom: 24px;
`
