import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { InnerList } from './InnerList/InnerList'
import { CourseFooter } from './Footer'
import PageTemplate from '~/src/components/templates/PageTemplate'
import {
  useFetch,
  useHandleDeleteCourse,
  useHandleCloseDeleteConfirmModal
} from './interactors'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import { selectIsDeleteConfirmModalOpen } from '../../../redux/selectors/uiSelectors'
import { selectDeleteTargetCourse } from './selectors'
import { Messages } from './Messages/Messages'

const { URL, Color } = Const

export const CourseTop: React.VFC = () => {
  const breadcrumbs = [{ title: 'コース' }]
  const description = [
    'コースを設定できます。コース内容に登録された商品は、コース注文時にすべてキッチンに送信されます。'
  ]
  const isDeleteConfirmModalOpen = useSelector(selectIsDeleteConfirmModalOpen)
  const deleteTargetCourse = useSelector(selectDeleteTargetCourse)
  const handleCloseDeleteConfirmModal = useHandleCloseDeleteConfirmModal()
  const handleDeleteCourse = useHandleDeleteCourse(deleteTargetCourse.courseId)

  const handleFetch = useFetch()
  useEffect(() => {
    handleFetch()
  }, [handleFetch])
  // 削除モーダル文言の作成
  const isDeleteTargetHasItem = deleteTargetCourse && !!deleteTargetCourse.name
  const deleteDialogHeader = isDeleteTargetHasItem
    ? `「${deleteTargetCourse.name}」の解除`
    : 'コース設定の解除'
  const deleteDialogMessage = isDeleteTargetHasItem
    ? `「${deleteTargetCourse.name}」のコース設定を解除します。`
    : 'コース設定を解除します。'
  const modal = (
    <DeleteConfirmModal
      isOpen={isDeleteConfirmModalOpen}
      onCancel={handleCloseDeleteConfirmModal}
      onDelete={handleDeleteCourse}
      headingMessage={deleteDialogHeader}
      okText="解除する"
    >
      {deleteDialogMessage}
      <br />
      よろしいですか？
      {isDeleteTargetHasItem && '※商品は削除されません'}
    </DeleteConfirmModal>
  )

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<CourseFooter />}
      modal={modal}
      displayKP
      displayKM
    >
      <Messages />
      <HodaiPlanFAQ>
        ※コースについて、詳しくは
        <InlineLink href={URL.COURSE_FAQ} target="_blank">
          コースの設定
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </HodaiPlanFAQ>
      <InnerList />
    </PageTemplate>
  )
}

const HodaiPlanFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
