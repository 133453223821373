import { List, Map, OrderedMap, Set } from 'immutable'

import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'

/** トッピング商品になれない原因となるエラー種別 */
export type ItemError = 'HODAI' | 'COURSE' | 'HAS_SKU' | 'TAX_FREE'

/** トッピングに選択された商品のエラー種別 */
export type SelectedToppingError = 'NO_CATEGORY' | 'HAS_SKU' | 'TAX_FREE'

export type ToppingGroupState = {
  // Domain data
  categories: OrderedMap<string, SimpleCategoryForTopping> // key: categoryId
  items: OrderedMap<string, SimpleItemForTopping> // key: itemId
  itemErrors: Map<string, Set<ItemError>> // key: itemId
  toppingGroupVersion?: number
  // App state
  isToppingGroupNotFound: boolean
  toppingGroupName: {
    value: string
    isError: boolean
  }
  selectedToppingItemIds: List<string>
  selectedToppingErrors: Map<string, Set<SelectedToppingError>> // key: itemId
  // UI state
  isEdited: boolean
}

export const CLEAR_STATE = 'oes/Topping/ToppingGroup/CLEAR_STATE'
export const FETCH_INITIAL_DATA_SUCCEEDED =
  'oes/Topping/ToppingGroup/FETCH_INITIAL_DATA_SUCCEEDED'
export const EDIT_TOPPING_GROUP_NAME =
  'oes/Topping/ToppingGroup/EDIT_TOPPING_GROUP_NAME'
export const SET_TOPPING_GROUP_NAME_ERROR_STATUS =
  '/oes/Topping/ToppingGroup/SET_TOPPING_GROUP_NAME_ERROR_STATUS'
export const ADD_ITEM_TO_TOPPING_GROUP =
  'oes/Topping/ToppingGroup/ADD_ITEM_TO_TOPPING_GROUP'
export const ADD_CATEGORY_TO_TOPPING_GROUP =
  'oes/Topping/ToppingGroup/ADD_CATEGORY_TO_TOPPING_GROUP'
export const DELETE_ALL_FROM_TOPPING_GROUP =
  'oes/Topping/ToppingGroup/DELETE_ALL_FROM_TOPPING_GROUP'
export const DELETE_ITEM_FROM_TOPPING_GROUP =
  'oes/Topping/ToppingGroup/DELETE_ITEM_FROM_TOPPING_GROUP'
export const DID_REORDER_TOPPING_ITEMS_IDS =
  'oes/Topping/ToppingGroup/DID_REORDER_TOPPING_ITEMS_IDS'
export const SAVE_TOPPING_GROUP_SUCCEEDED =
  '/oes/Topping/ToppingGroup/SAVE_TOPPING_GROUP_SUCCEEDED'
