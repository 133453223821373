import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const/'
import {
  TableRow,
  HandleColumn,
  SeatSetNameColumn,
  ArrowColumn
} from './TableParts'

const { Color } = Const

export const HeaderRow = () => {
  return (
    <section>
      <SeatSetNameHeader>
        <HandleColumn />
        <SeatSetNameColumn>注文エリア名</SeatSetNameColumn>
        <ArrowColumn />
      </SeatSetNameHeader>
    </section>
  )
}

const SeatSetNameHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  font-weight: bold;
`
