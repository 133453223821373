import React from 'react'

import PageTemplate from '~/src/components/templates/PageTemplate'
import Const from '~/src/const'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
const { URL } = Const

export default () => {
  const breadcrumbs = [{ title: 'スタッフ設定' }]
  return (
    <PageTemplate breadcrumbs={breadcrumbs}>
      <Message
        type="info"
        messageList={[
          <>
            この項目は
            <InlineLink href={URL.PLF_STAFF(document.URL)} target="_blank">
              店舗スタッフ設定ページ
            </InlineLink>
            で設定する項目です。リンク先へ移動して設定を行ってください。
          </>
        ]}
      />
    </PageTemplate>
  )
}
