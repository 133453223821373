import React from 'react'
import styled, { css } from 'styled-components'

import Const from '~/src/const'

import Icon from '~/src/components/atoms/Icon'
import { fadeIn } from '~/src/components/particles/Animation'
import { selectToastProps } from './selectors'
import { useSelector } from 'react-redux'
const { Color, ZIndex } = Const

export const Toast: React.VFC = () => {
  const props = useSelector(selectToastProps)
  return (
    <Wrapper isShowToast={props.isShowToast}>
      <ToastWrapper>
        <div>
          <SuccessIcon />
        </div>
        <ToastText>{props.message}</ToastText>
      </ToastWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isShowToast: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.9;
  z-index: ${ZIndex.AIR_OES_TOAST};

  ${props =>
    props.isShowToast
      ? css`
          visibility: visible;
          animation: ${fadeIn} 0.25s ease-out;
        `
      : `
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-out;
  `}
`

const ToastWrapper = styled.div`
  border-radius: 4px;
  color: ${Color.WHITE};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 220px;
  height: 180px;
  text-align: center;
  padding: 30px;

  background-color: ${Color.LIME_GREEN};
`

const SuccessIcon = styled(Icon).attrs({ name: 'success' })``

const ToastText = styled.div`
  padding-top: 18px;
`
