import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import {
  useFetch,
  useHandleCloseDeleteConfirmModal,
  useHandleDeleteHodai
} from './interactors'
import { InnerList } from './InnerList/InnerList'
import { HodaiFooter } from './Footer'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import { selectIsDeleteConfirmModalOpen } from '../../../redux/selectors/uiSelectors'
import { selectDeleteTargetHodai } from './selectors'
import { Messages } from './Messages/Messages'
const { URL, Color } = Const

export const HodaiTop: React.VFC = () => {
  const breadcrumbs = [{ title: '放題プラン' }]
  const description = [
    '食べ放題・飲み放題プランを設定できます。放題プラン内容に登録された商品は、放題プラン適用時に0円で注文できます。'
  ]
  const isDeleteConfirmModalOpen = useSelector(selectIsDeleteConfirmModalOpen)
  const deleteTargetHodai = useSelector(selectDeleteTargetHodai)
  const handleCloseDeleteConfirmModal = useHandleCloseDeleteConfirmModal()
  const handleDeleteHodai = useHandleDeleteHodai(deleteTargetHodai.hodaiId)
  // 削除モーダル文言の作成
  const isDeleteTargetHasItem = deleteTargetHodai && !!deleteTargetHodai.name
  const deleteHeadingMessage = isDeleteTargetHasItem
    ? `「${deleteTargetHodai.name}」の解除`
    : '放題プラン設定の解除'
  const deleteBodyMessage = isDeleteTargetHasItem
    ? `「${deleteTargetHodai.name}」の放題プラン設定を解除します。`
    : '放題プラン設定を解除します。'
  const modal = (
    <DeleteConfirmModal
      isOpen={isDeleteConfirmModalOpen}
      onCancel={handleCloseDeleteConfirmModal}
      onDelete={handleDeleteHodai}
      headingMessage={deleteHeadingMessage}
      okText="解除する"
    >
      {deleteBodyMessage}
      <br />
      よろしいですか？
      {isDeleteTargetHasItem && '※商品としてはなくなりません。'}
    </DeleteConfirmModal>
  )
  const handleFetch = useFetch()
  useEffect(() => {
    handleFetch()
  }, [handleFetch])

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<HodaiFooter />}
      modal={modal}
      displayKP
      displayKM
    >
      <Messages />
      <HodaiPlanFAQ>
        ※放題プランについて、詳しくは
        <InlineLink href={URL.HODAI_FAQ} target="_blank">
          放題プラン(食べ放題／飲み放題)の設定
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </HodaiPlanFAQ>
      <InnerList />
    </PageTemplate>
  )
}

const HodaiPlanFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
