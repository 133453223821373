import React, { useCallback } from 'react'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

type Props = {
  interactor: VariationGroupInteractor
  isShowing: boolean
}

const DisconnectModal: React.FC<Props> = props => {
  const onCancelCb = useCallback(() => {
    props.interactor.clearDisconnectVariationGroup()
  }, [props.interactor])
  const onDisconnectCb = useCallback(() => {
    props.interactor.disconnectVariationGroup()
  }, [props.interactor])
  if (!props.isShowing) {
    return null
  }

  return (
    <DeleteConfirmModal
      isOpen={props.isShowing}
      useWarningOkButton
      onCancel={onCancelCb}
      onDelete={onDisconnectCb}
      headingMessage="バリエーションを解除しますか？"
      okText="解除する"
    >
      解除すると、バリエーション一括設定開始前の状態に戻ります。
    </DeleteConfirmModal>
  )
}

export default DisconnectModal
