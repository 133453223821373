import Const from '~/src/const'
import APIClient from '~/src/util/APIClient'
import { handleError } from './ApiError'
import { setLoading, clearLoading, showAndBlurToast } from './UI'

import OrderItemMemoTemplate from '~/src/redux/models/OrderItemMemoTemplate'
import OrderItemMemoTemplates from '~/src/redux/models/OrderItemMemoTemplates'

const { API, Toast } = Const

const CLEAR_STATE = 'oes/orderItemMemoTemplate/CLEAR_STATE'
const FETCH_SUCCESS = 'oes/orderItemMemoTemplate/FETCH_SUCCESS'
const POST_MERGE_SUCCESS = 'oes/orderItemMemoTemplate/POST_MERGE_SUCCESS'
const UPDATE_MEMO_TEXT = 'oes/orderItemMemoTemplate/UPDATE_MEMO_TEXT'
const MOVE_MEMO = 'oes/orderItemMemoTemplate/MOVE_MEMO'
const PUSH_NEW_MEMO = 'oes/orderItemMemoTemplate/PUSH_NEW_MEMO'
const DELETE_MEMO = 'oes/orderItemMemoTemplate/DELETE_MEMO'
const SET_DELETING_MEMO_INDEX =
  'oes/orderItemMemoTemplate/SET_DELETING_MEMO_INDEX'
const SET_IS_DELETE_CONFIRM_MODAL_OPEN =
  'oes/orderItemMemoTemplate/SET_IS_DELETE_CONFIRM_MODAL_OPEN'

// Actions
export const clearMemoState = () => {
  return {
    type: CLEAR_STATE
  }
}

const fetchSuccess = json => {
  const memosJson = json.result.orderItemMemoTemplates
  const memos = new OrderItemMemoTemplates(memosJson)
  return {
    type: FETCH_SUCCESS,
    memos: memos
  }
}

export const fetchMemo = () => {
  return dispatch => {
    return APIClient.get(API.GET_ORDER_ITEM_MEMO_TEMPLATE)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(fetchSuccess(json)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const postMergeSuccess = json => {
  const memosJson = json.result.orderItemMemoTemplates
  const memos = new OrderItemMemoTemplates(memosJson)
  return {
    type: POST_MERGE_SUCCESS,
    memos: memos
  }
}

export const postMergeMemo = () => {
  return (dispatch, getState) => {
    const json =
      getState().orderItemMemoTemplate.memos.buildRequestJsonForMerge()
    return APIClient.post(API.POST_MERGE_ORDER_ITEM_MEMO_TEMPLATE, json)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(postMergeSuccess(json)))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const postDeleteMemo = index => {
  return (dispatch, getState) => {
    const json = getState()
      .orderItemMemoTemplate.memos.orderItemMemoTemplates.get(index)
      .buildRequestJsonForDelete()
    return APIClient.post(API.POST_DELETE_ORDER_ITEM_MEMO_TEMPLATE, json)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(deleteMemo(index))) // deleteはサクセスハンドラでやることがないので直接deleteMemoを呼ぶ
      .then(() => dispatch(showAndBlurToast(Toast.DELETED_MESSAGE)))
      .then(() => dispatch(fetchMemo()))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const updateMemoText = (index, text) => {
  return {
    type: UPDATE_MEMO_TEXT,
    index,
    text
  }
}

export const moveMemo = (oldIndex, newIndex) => {
  return {
    type: MOVE_MEMO,
    oldIndex,
    newIndex
  }
}

export const pushNewMemo = () => {
  return {
    type: PUSH_NEW_MEMO
  }
}

export const deleteMemo = index => {
  return {
    type: DELETE_MEMO,
    index
  }
}

export const setDeletingMemoIndex = index => {
  return {
    type: SET_DELETING_MEMO_INDEX,
    index
  }
}

export const setIsDeleteConfirmModalOpen = isOpen => {
  return {
    type: SET_IS_DELETE_CONFIRM_MODAL_OPEN,
    isOpen
  }
}

// Initial state
const initialState = {
  memos: new OrderItemMemoTemplates(),
  isEditing: false,
  deletingMemoIndex: undefined,
  isDeleteConfirmModalOpen: false
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case FETCH_SUCCESS:
      return {
        ...state,
        memos: action.memos
      }
    case POST_MERGE_SUCCESS:
      return {
        ...state,
        memos: action.memos,
        isEditing: false
      }
    case UPDATE_MEMO_TEXT:
      return {
        ...state,
        memos: state.memos.setMemoByIndex(action.index, action.text),
        isEditing: true
      }
    case MOVE_MEMO:
      return {
        ...state,
        memos: state.memos.move(action.oldIndex, action.newIndex),
        isEditing: true
      }
    case PUSH_NEW_MEMO:
      return {
        ...state,
        memos: state.memos.push(new OrderItemMemoTemplate())
      }
    case DELETE_MEMO: {
      const memos = state.memos.delete(action.index)
      return {
        ...state,
        memos,
        isEditing:
          memos.orderItemMemoTemplates.size > 1 ? state.isEditing : false
      }
    }
    case SET_DELETING_MEMO_INDEX:
      return {
        ...state,
        deletingMemoIndex: action.index
      }
    case SET_IS_DELETE_CONFIRM_MODAL_OPEN:
      return {
        ...state,
        isDeleteConfirmModalOpen: action.isOpen
      }
    default:
      return state
  }
}
