// サイズ定数
// 複数箇所から参照されるような UI のサイズは定数化してください

export default {
  ACCOUNT_TOP_MAX_WIDTH: 960,
  // adjust max width, virtual scroll area. https://ghe.misosiru.io/AirWaitWeb/air-wait-fe/issues/198
  // OPERATION_MAX_WIDTH: 343,
  COMMON_HEADER_HEIGHT: 36,
  HEADER_HEIGHT: 56,
  MAIN_CONTENT_TITLE_HEIGHT: 57,
  SIDE_MENU_WIDTH: 200,
  SIDE_MENU_MIN_WIDTH: 48,
  FONT: {
    XSMALL: 10,
    SMALL: 12,
    BASE: 14,
    LARGE: 16,
    XLARGE: 18,
    HEADER: {
      H1: 24,
      H2: 20,
      H3: 18,
      H4: 16
    }
  },
  FULL_SCREEN_MODAL: {
    FOOTER_HEIGHT: 72
  },
  MODAL: {
    HEADER_HEIGHT: 44,
    MARGIN_X: 16,
    MARGIN_Y: 32,
    FOOTER_HEIGHT: 63,
    CONTENT: {
      PADDING_Y: 40,
      PADDING_Y_FOR_NO_HEADER: 24,
      PADDING_Y_FOR_SUB_CONTENT: 10
    },
    CONFIRMATION: {
      MAX_WIDTH: 343,
      MIN_WIDTH: 288,
      INNER: {
        MIN_WIDTH: 188
      }
    },
    SUB_CONTENT: {
      HEIGHT: 86
    }
  },
  OPERATION_TOP: {
    HEADER_UPPER_BOX: {
      HEIGHT_MINI: 60,
      HEIGHT_NORMAL: 88
    },
    HEADER_LOWER_BOX: {
      HEIGHT: 56,
      MARGIN_BOTTOM: 16
    }
  },
  WAIT_TYPE_TABS: {
    HEIGHT: 42
  },
  BORDER: {
    RADIUS: {
      DEFAULT: 4,
      LARGE: 6
    }
  },
  RESERVE_LIST: {
    ROW: {
      HEIGHT: 104, // UPPER_ROW_HEIGHT + LOWER_ROW_HEIGHT + PADDING_Y の合計値になる
      UPPER_ROW_HEIGHT: 60,
      LOWER_ROW_HEIGHT: 36,
      PADDING_X: 16,
      PADDING_Y: 8
    }
  },
  MEDIA: {
    SCREEN_SIZE: {
      IPHONE6: 627,
      PORTRAIT_IPHONE6: 375,
      PORTRAIT_IPHONE6_PLUS: 414,
      TABLET_SM_MIN: 768,
      STANDARD_TABLET_MIN: 810,
      PC_MD_MIN: 992,
      PC_LG_MIN: 1200
    },
    XXS_SIZE: {
      XXS_MAX: 626, // IPHONE6 - 1
      PORTRAIT_XXS_MAX_IPHONE6: 374,
      PORTRAIT_XXS_MAX_IPHONE6_PLUS: 413
    }
  }
}
