import React, { useCallback, useEffect } from 'react'
import useInteractors from '~/src/hooks/useInteractors'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import Footer from '~/src/components/organisms/Footer'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { CommonSetting } from './CommonSetting'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { DisplaySetting } from './DisplaySetting'
import { isHandyPlanSelector } from '~/src/redux/selectors/storeSelectors'

export const Setting: React.VFC = () => {
  const { isEdited, commonSetting, handySetting, isSplashShowing } =
    useSelector((state: AppState) => state.setting)
  const isDisplayHandy = useSelector(isHandyPlanSelector)

  const interactor = useInteractors().settingInteractor
  useEffect(() => {
    interactor.clearState()
    interactor.fetchInitialData()
    return interactor.clearState()
  }, [interactor])

  const onSubmitButtonClick = useCallback(() => {
    const handyRequest = {
      setting: {
        taxDisplayType: handySetting.taxDisplayTypeId,
        isSalesDisplayed: handySetting.isSalesDisplayed,
        isOtoshiEnabled: handySetting.isOtoshiEnabled,
        isDiscountSurchargeOnVisitEnabled:
          handySetting.isDiscountSurchargeOnVisitEnabled,
        version: handySetting.version
      }
    }
    const commonRequest = {
      setting: {
        shouldAutoApplySettings: commonSetting.shouldAutoApplySettings,
        version: commonSetting.version
      }
    }
    interactor.postSetting(handyRequest, commonRequest)
  }, [
    interactor,
    handySetting.taxDisplayTypeId,
    handySetting.isSalesDisplayed,
    handySetting.isOtoshiEnabled,
    handySetting.isDiscountSurchargeOnVisitEnabled,
    handySetting.version,
    commonSetting.shouldAutoApplySettings,
    commonSetting.version
  ])

  const footer = (
    <Footer
      noUseOptionButton
      disableSubmitButton={!isEdited}
      onSubmitButtonClick={onSubmitButtonClick}
    />
  )
  return (
    <PageTemplate
      breadcrumbs={[{ title: '設定' }]}
      footer={footer}
      modal={<DismissPrompt enabled={isEdited} />}
      isSplashShowing={isSplashShowing}
    >
      <CommonSetting />
      {isDisplayHandy && <DisplaySetting />}
    </PageTemplate>
  )
}
