import { AppState } from '~/src/redux/reducer'

export const selectPrinterSelect = (state: AppState) => {
  const selectedPrinterIds =
    state.printTargetByCategories.batchSelect.getSelectedPrinterIds()
  const sortedSelectedPrinterIds = state.printTargetByCategories.printers
    .getPrintersByIds(selectedPrinterIds)
    .map(printer => printer.printerId)
    .toArray()

  return {
    printerOptions: state.printTargetByCategories.printerOptions,
    selectedPrinterIds: sortedSelectedPrinterIds
  }
}
