import React from 'react'
import styled from 'styled-components'
import { Input } from '~/src/components/atoms/Input'
import { Validation } from '~/src/components/atoms/Validation'
import Const from '~/src/const/'

const { KeyEvent } = Const

type Props = {
  className?: string
  type: string
  value?: string
  disabled?: boolean
  pattern?: string
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
  id?: string
  name?: string
  max?: number
  min?: number
  index?: number
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
  maxLength?: number
  placeholder?: string
  error?: string | null
  align?: string
  length?: number
  width?: number
}

export const ValidatableInput: React.VFC<Props> = props => {
  const {
    className,
    pattern,
    inputMode,
    id,
    disabled,
    name,
    type,
    value,
    max,
    min,
    index,
    onChange,
    onFocus,
    maxLength,
    placeholder,
    error,
    align,
    width
  } = props

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // enterキーを抑制
    if (e.key === KeyEvent.NAME.ENTER) {
      e.preventDefault()
    }
  }

  return (
    <Wrapper className={className}>
      <Input
        id={id}
        disabled={disabled}
        pattern={pattern}
        inputMode={inputMode}
        name={name}
        index={index}
        max={max}
        min={min}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        align={align}
        invalid={!!error}
        width={width}
      />
      {error && <Validation align={align}>{error}</Validation>}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ display?: string }>`
  position: relative;
`
