import styled from 'styled-components'

import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'

const { Size } = Const

export const ContentColumn = styled(TableColumn)`
  word-wrap: break-word;
  text-align: left;
`

export const FunctionColumn = styled(TableColumn)`
  width: 30%;
  text-align: right;
  padding-left: 0;
`

export const HandleColumn = styled(TableColumn)`
  cursor: move;
  width: 32px;
  padding: 0;
`

export const ItemNameColumn = styled(TableColumn)`
  text-align: left;
  font-size: ${Size.FONT.BASE}px;
  padding: 0 6px;
  word-wrap: break-word;
`

export const TrashBoxColumn = styled(TableColumn)`
  width: 50px;
  font-size: ${Size.FONT.BASE}px;
  padding: 0;
`
