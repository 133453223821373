import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  SortableContainer,
  SortableElement,
  SortEndHandler
} from 'react-sortable-hoc'
import { List } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import { MAX_NUM_TOPPINGS } from '~/src/redux/models/Topping/ToppingGroup'
import EmptyState from '~/src/components/atoms/EmptyState'
import { ExclamationIcon } from '~/src/components/atoms/Icon'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn, FunctionColumn } from '../Common/Column'
import { FunctionLink } from '../Common/Link'
import { ToppingRow } from './ToppingRow'

const { Size, Color } = Const

const SelectedListContainer: React.VFC = () => {
  const selectedItemIds = useSelector(
    (state: AppState) => state.toppingGroup.selectedToppingItemIds
  )

  const interactor = useInteractors().toppingGroupInteractor
  const handleClickDeleteAll = useCallback(
    () => interactor.deleteAllFromToppingGroup(),
    [interactor]
  )
  const handleSortEnd: SortEndHandler = useCallback(
    ({ oldIndex, newIndex }) =>
      interactor.didReOrderSelectedToppingItemIds(oldIndex, newIndex),
    [interactor]
  )

  const props: InnerProps = {
    selectedItemIds,
    onClickDeleteAll: handleClickDeleteAll,
    onSortEnd: handleSortEnd
  }
  return <SelectedList {...props} />
}
export { SelectedListContainer as SelectedList }

type InnerProps = {
  selectedItemIds: List<string>
  onClickDeleteAll: () => void
  onSortEnd: SortEndHandler
}
const SelectedList: React.VFC<InnerProps> = React.memo(props => {
  const { selectedItemIds, onClickDeleteAll, onSortEnd } = props

  return (
    <Wrapper>
      <HeaderArea>
        <HeaderTitle>
          トッピンググループ内容
          <ItemCountArea>
            {selectedItemIds.size > MAX_NUM_TOPPINGS && (
              <ExclamationIcon color="red" />
            )}
            {`計 ${selectedItemIds.size} 点`}
          </ItemCountArea>
        </HeaderTitle>
      </HeaderArea>
      <MainContent>
        <TableHeader>
          <ContentColumn>商品</ContentColumn>
          <FunctionColumn>
            <FunctionLink
              clickHandler={onClickDeleteAll}
              needsToStopPropagation={false}
            >
              すべて解除
            </FunctionLink>
          </FunctionColumn>
        </TableHeader>
        {!selectedItemIds.isEmpty() && (
          <div id="sortable-list-container">
            <SortableList
              itemIds={selectedItemIds}
              // 以下は SortableContainer のプロパティ
              lockAxis="y"
              onSortEnd={onSortEnd}
              useDragHandle
              getContainer={() =>
                document.getElementById('sortable-list-container')!
              }
            />
          </div>
        )}
        {selectedItemIds.isEmpty() && (
          <EmptyState>
            トッピンググループ内容が登録されていません。
            <br />
            左の商品一覧から登録できます。
          </EmptyState>
        )}
      </MainContent>
    </Wrapper>
  )
})

const SortableItem = SortableElement((value: { itemId: string }) => (
  <ToppingRow itemId={value.itemId} />
))

const SortableList = SortableContainer((value: { itemIds: List<string> }) => (
  <>
    {value.itemIds.map((itemId, index) => (
      <SortableItem key={itemId} index={index} itemId={itemId} />
    ))}
  </>
))

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`

const HeaderArea = styled.div`
  height: 30px;
  text-align: left;
  vertical-align: middle;
`

const HeaderTitle = styled.span`
  font-size: ${Size.FONT.BASE}px;
  vertical-align: middle;
  font-weight: bold;
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const TableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`

const ItemCountArea = styled.span`
  font-size: ${Size.FONT.BASE}px;
  font-weight: bold;
  text-align: right;
  height: 30px;
  vertical-align: middle;
  float: right;
  padding-right: 10px;
`
