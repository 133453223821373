import { List } from 'immutable'
import { ItemByToppingGroups } from '~/src/redux/models/Item/ItemByToppingGroups'
import { ToppingGroups } from '~/src/redux/models/Topping/ToppingGroups'

export const CLEAR_STATE = 'oes/Topping/List/CLEAR_STATE'
export const FETCH_INITIAL_DATA_SUCCEEDED =
  'oes/Topping/List/FETCH_INITIAL_DATA_SUCCEEDED'
export const POST_UPDATE_TOPPING_GROUP_SUCCEEDED =
  'oes/Topping/List/POST_UPDATE_TOPPING_GROUP'
export const POST_DELETE_TOPPING_GROUP_SUCCEEDED =
  'oes/Topping/List/POST_DELETE_TOPPING_GROUP'
export const DID_TAP_TRASH_BOX = 'oes/Topping/List/DID_TAP_TRASH_BOX'
export const DID_SORT_TOPPING_GROUP = 'oes/Topping/List/DID_SORT_TOPPING_GROUP'
export const OPEN_MAX_TOPPING_GROUP_MODAL =
  'oes/Topping/List/OPEN_MAX_TOPPING_GROUP_MODAL'
export const CLOSE_MAX_TOPPING_GROUP_MODAL =
  'oes/Topping/List/CLOSE_MAX_TOPPING_GROUP_MODAL'

export type NoticeIconType =
  | 'ITEM_BY_TOPPING_GROUP_WARNING'
  | 'TOPPING_WARNING'
  | 'ITEM_COUNT_CAUTION'
  | 'TARGET_COUNT_CAUTION'

export type ToppingListState = {
  toppingGroups: ToppingGroups
  itemByToppingGroups: ItemByToppingGroups
  toppingGroupIds: List<string>
  toppingGroupIdForDeleteModal: string
  warningMessages: List<string>
  cautionMessages: List<string>
  isEdited: boolean
  openMaxToppingGroupModal: boolean
}
