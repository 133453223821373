import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import Radio from '~/src/components/atoms/Radio'
import { SelectColumn, HodaiNameColumn, HodaiDetailNumColumn } from './Column'
import Const from '~/src/const'
const { Color, UndefinedId } = Const

export type Props = {
  item: Item
  selected: string
  onChange: (selectedItem: SelectedItem) => void
}

export type SelectedItem = {
  id: string
  hodaiId: string
  name: string
}

export type Item = {
  id: string
  name: string
  hodaiId: string
  hodaiNum: number
  isDisplayed: boolean // trueの場合は 商品名の後に (非表示) を表示する
  isDeleted: boolean
  isDisabled: boolean
}

export const Row: React.VFC<Props> = props => {
  let displayHodaiNum: number | string
  if (props.item.hodaiNum === 0 && props.item.id === UndefinedId.ITEM_ID) {
    displayHodaiNum = '-'
  } else if (props.item.hodaiNum === 0) {
    displayHodaiNum = '未設定'
  } else {
    displayHodaiNum = props.item.hodaiNum
  }
  const onChange = () => {
    if (!props.item.isDeleted) {
      props.onChange({
        id: props.item.id,
        hodaiId: props.item.hodaiId,
        name: props.item.name
      })
    }
  }
  return (
    <TableRowWrapper disabled={props.item.isDeleted || props.item.isDisabled}>
      <SelectColumn>
        <RadioWrapper>
          <Radio
            name="selected-hodai-plan"
            value={props.item.name}
            disabled={props.item.isDisabled}
            checked={props.item.id === props.selected}
            onChange={onChange}
          />
        </RadioWrapper>
      </SelectColumn>
      <HodaiNameColumn>
        {props.item.isDeleted ? '[削除済み]' : props.item.name}
      </HodaiNameColumn>
      <HodaiDetailNumColumn>{displayHodaiNum}</HodaiDetailNumColumn>
    </TableRowWrapper>
  )
}

const RadioWrapper = styled.label`
  display: block;
  cursor: pointer;
`
const TableRowWrapper = styled(TableRow)<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    box-shadow: inset 0 -1px 0 0 ${Color.LIGHT_GRAY_4};
    `}
`
