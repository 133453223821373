import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  applyItemSetting,
  cancelItemSettingEditor,
  showCancelConfirmModal
} from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useClose = (isEditing: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (isEditing) {
      dispatch(showCancelConfirmModal())
    } else {
      dispatch(cancelItemSettingEditor())
    }
  }, [dispatch, isEditing])
}

export const useApply = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(applyItemSetting())
  }, [dispatch])
}
