import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'
import { CourseTopState } from './types'

const initialState = {
  deleteTargetCourse: {
    courseId: '',
    name: undefined
  }
}

export const courseTop = (
  state: CourseTopState = initialState,
  action: ActionType<typeof actions>
): CourseTopState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.openDeleteModal): {
      return {
        ...state,
        deleteTargetCourse: {
          courseId: action.payload.courseId,
          name: action.payload.name
        }
      }
    }
    default:
      return state
  }
}
