import React from 'react'
import InnerTable from './InnerTable'
import InnerPolicy from './InnerPolicy'
import Const from '~/src/const'
import Forward from '~/src/components/organisms/Forward'
import Message from '~/src/components/atoms/Message'
import styled from 'styled-components'
import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { selectDeviceCountConfirmContentState } from './selectors'

const { Page, PlanPriceTypes } = Const

const DeviceCountConfirmContent = () => {
  const { confirmedDeviceCount, shouldForward, planPriceType } = useSelector(
    selectDeviceCountConfirmContentState,
    equal
  )

  if (!confirmedDeviceCount) {
    return <Forward when={true} forwardPage={Page.DEVICE_COUNT._INDEX} />
  }

  return (
    <>
      {planPriceType?.ID === PlanPriceTypes.UNSPECIFIED.ID && (
        <MessageContaier>
          <Message
            type="warning"
            messageList={[
              '契約内容を切り替え中のため、一部の情報を表示できません。',
              '適用中の契約内容を確認したい場合は、ヘルプデスクにお問い合わせください。'
            ]}
          />
        </MessageContaier>
      )}
      <InnerTable />
      <InnerPolicy />
      <Forward when={shouldForward} forwardPage={Page.DEVICE_COUNT._INDEX} />
    </>
  )
}

const MessageContaier = styled.div`
  margin-bottom: 24px;
`

export default DeviceCountConfirmContent
