import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { OtoshiSettingState } from './types'
import {
  OtoshiApiRequest,
  OtoshisApiRequest
} from '~/src/api/handy/otoshi/types'
import Const from '~/src/const'
import { HandySettingApiResponse } from '~/src/api/handy/settings/types'
import { actions as handySettingApiActions } from '~/src/api/handy/settings/actions'
import { actions as itemsApiActions } from '~/src/api/handy/items/actions'
import { actions as otoshiSettingActions } from '~/src/pages/Otoshi/actions'
import {
  selectIsOtoshiEnabledFromApi,
  selectSelectedItemsFromApi
} from './selectors'

const { API, Toast } = Const

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(handySettingApiActions.clearState())
    dispatch(itemsApiActions.clearState())
    dispatch(otoshiSettingActions.clearState())
  }, [dispatch])
}

export const useHandleFetchOtoshiSetting = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(itemsApiActions.fetchItems())
    dispatch(handySettingApiActions.fetchHandySetting())
  }, [dispatch])
}

export const useInitializeState = () => {
  const dispatch = useDispatch()
  const isOtoshiEnabled = useSelector(selectIsOtoshiEnabledFromApi)
  const selectedItemsFromApi = useSelector(selectSelectedItemsFromApi)
  return useCallback(() => {
    dispatch(
      otoshiSettingActions.initialize(isOtoshiEnabled, selectedItemsFromApi)
    )
  }, [dispatch, isOtoshiEnabled, selectedItemsFromApi])
}

export const useHandlePostReplaceOtoshi = (
  otoshiSetting: OtoshiSettingState,
  handySetting: HandySettingApiResponse,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const handleFetchOtoshiSetting = useHandleFetchOtoshiSetting()

  return useCallback(() => {
    dispatch(setLoading())
    return Promise.all([
      apiClient.post(
        API.POST_REPLACE_OTOSHI_SETTING,
        convertStateToRequestForOtoshi(otoshiSetting)
      ),
      apiClient.post(
        API.POST_HANDY_SETTING,
        convertStateToRequestForHandySetting(
          handySetting,
          otoshiSetting.isOtoshiEnabled
        )
      )
    ])
      .then(() => {
        dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        handleFetchOtoshiSetting()
        dispatch(otoshiSettingActions.submitSucceeded())
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [
    dispatch,
    apiClient,
    otoshiSetting,
    handySetting,
    handleFetchOtoshiSetting
  ])
}

const convertStateToRequestForOtoshi = (
  state: OtoshiSettingState
): OtoshisApiRequest => {
  const otoshiList: OtoshiApiRequest[] = state.selectedItems.map(item => ({
    // itemIdがない(削除済み商品)がある場合は登録ボタン自体押せないため
    // undefinedが入ることはあり得ない
    itemId: item.itemId!,
    otoshiId: item.otoshiId
  }))
  return {
    otoshis: otoshiList
  }
}

const convertStateToRequestForHandySetting = (
  state: HandySettingApiResponse,
  isOtoshiEnabled: boolean
) => {
  return {
    setting: {
      ...state.setting,
      isOtoshiEnabled
    }
  }
}
