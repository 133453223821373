import { Record } from 'immutable'
import Const from '~/src/const'

const { UndefinedId } = Const

const KitchenPositionByItemRecord = Record({
  itemId: '',
  kitchenPositionId: '',
  analysisTag: ''
})

export default class KitchenPositionByItemModel extends KitchenPositionByItemRecord {
  constructor({ itemId = '', kitchenPositionId = '', analysisTag = '' }) {
    super()

    if (!kitchenPositionId) {
      kitchenPositionId = UndefinedId.SELECTBOX_ID
    }

    return this.merge({
      itemId,
      kitchenPositionId,
      analysisTag
    })
  }

  setKitchenPositionId(kitchenPositionId = UndefinedId.SELECTBOX_ID) {
    return this.set('kitchenPositionId', kitchenPositionId)
  }

  setAnalysisTag(analysisTag = '') {
    return this.set('analysisTag', analysisTag)
  }
}
