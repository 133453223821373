import React from 'react'
import styled from 'styled-components'

import TableRow from '~/src/components/atoms/TableRow'
import Required from '~/src/components/atoms/Required'

import Row from './Row'

import Const from '~/src/const/'

const { Color } = Const

export default props => {
  return (
    <HeaderRow>
      <Row isHandleColumnHidden={true} isDeleteColumnHidden={true}>
        注文メモ名
        <Required name="required" />{' '}
      </Row>
    </HeaderRow>
  )
}

const HeaderRow = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
