import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { updateKitchenPositionByCategory } from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useHandleSelectChange = (categoryId: string) => {
  const dispatch = useDispatch()
  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      e =>
        dispatch(updateKitchenPositionByCategory(categoryId, e.target.value)),
      [dispatch, categoryId]
    )
  return handleSelectChange
}
