import React, { ReactElement } from 'react'
import { Header } from './Header'
import EmptyState from '~/src/components/atoms/EmptyState'
import { ToppingListInteractor } from '~/src/interactors/Topping/ToppingList/ToppingListInteractor'
import {
  FixedArea,
  ContentsBox,
  ScrollArea
} from '~/src/components/atoms/ContentsBox'
import { Row } from './Row'
import { SortableElement, SortableContainer } from 'react-sortable-hoc'
import { List } from 'immutable'

type Props = {
  toppingGroupIds: List<string>
  interactor: ToppingListInteractor
}

export const InnerList: React.VFC<Props> = props => {
  const { toppingGroupIds, interactor } = props

  return (
    <ContentsBox>
      <FixedArea>
        <Header />
      </FixedArea>
      <ScrollArea id="sortable-topping-group-list">
        <SortableComponent
          onSortEnd={(param: { oldIndex: number; newIndex: number }) =>
            interactor.onSort(param)
          }
          useDragHandle={true}
          lockAxis="y"
          getContainer={() =>
            document.getElementById('sortable-topping-group-list')!
          }
        >
          <>
            {toppingGroupIds.map((toppingGroupId: string, index: number) => (
              <SortableItem
                key={toppingGroupId}
                index={index}
                toppingGroupId={toppingGroupId}
              />
            ))}
          </>
        </SortableComponent>
        {toppingGroupIds.size === 0 && (
          <EmptyState>
            トッピンググループが登録されていません。
            <br />
            左下のボタンからトッピンググループを登録できます。
          </EmptyState>
        )}
      </ScrollArea>
    </ContentsBox>
  )
}

const SortableItem = SortableElement((props: { toppingGroupId: string }) => (
  <Row toppingGroupId={props.toppingGroupId} />
))

const SortableComponent = SortableContainer(
  ({ children }: { children: ReactElement }) => children
)
