import { AppState } from '~/src/redux/reducer'

export const selectCategoryList = (state: AppState) => {
  const categories = state.printTargetByCategories.categories
  const batchSelect = state.printTargetByCategories.batchSelect

  const count = batchSelect.countSelectedCategories()
  const isSomeSelected = count > 0 && count < categories.size
  const isAllSelected = count === categories.size

  return {
    categories,
    isAllSelected,
    isSomeSelected
  }
}
