import { createAction } from 'typesafe-actions'

import { GET_STORE_SUCCEEDED, Store, Token } from './types'

const actions = {
  getStoreSucceeded: createAction(
    GET_STORE_SUCCEEDED,
    (store: Store, token: Token) => ({ store, token })
  )()
}

export default actions
