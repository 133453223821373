import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { LinkTableRow } from '~/src/components/atoms/LinkTableRow'
import {
  CourseDetailNameColumn,
  CategoryNameColumn,
  PriceColumn,
  FreeFlowingColumn,
  ItemCountColumn,
  IconColumn,
  SpaceColumn
} from '../Column/Column'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const/'
import { useHandleOpenDeleteModal } from '../../interactors'
import { CourseItem } from '../../types'
import { selectError } from './selectors'

const { Page, TaxType } = Const

type Props = {
  course: CourseItem
}
export const Row: React.VFC<Props> = (props: Props) => {
  const course = props.course
  const handleOpenDeleteModal = useHandleOpenDeleteModal()

  const handleClickTrashbox: React.MouseEventHandler<HTMLInputElement> =
    useCallback(
      e => {
        e.preventDefault()
        handleOpenDeleteModal({
          courseId: course.courseId,
          name: course.itemName
        })
      },
      [course.courseId, course.itemName, handleOpenDeleteModal]
    )
  const { hasEmptyCategoryItem, hasDeletedCourseDetail, hasDeletedCategory } =
    useSelector(selectError(course.itemId))
  const hasDetailError = hasDeletedCourseDetail || hasEmptyCategoryItem

  // コース商品が削除済み、またはSKUを持っている場合はエラー表示
  const hasItemError = course.isDeleted || course.isSku

  return (
    <LinkTableRow to={Page.COURSE.DETAIL(course.courseId)}>
      <CourseDetailNameColumn>
        {(course.isDeleted || course.isSku || course.isCategoryUndefined) && (
          <ExclamationIcon color="red" />
        )}
        {course.itemDisplayName}
      </CourseDetailNameColumn>
      <CategoryNameColumn>
        {hasDeletedCategory && <ExclamationIcon color="red" />}
        {course.categoryDisplayName}
      </CategoryNameColumn>
      <PriceColumn>
        {!hasItemError && !course.isDeleted
          ? // @ts-ignore
            `¥${course.itemPrice.toLocaleString()}\n(${
              TaxType[course.taxType]
            })`
          : '-'}
      </PriceColumn>
      <ItemCountColumn>
        {hasDetailError && !course.isDeleted && <ExclamationIcon color="red" />}
        {course.detailCount === 0 ? (
          <>
            <ExclamationIcon color="orange" /> 未設定
          </>
        ) : (
          course.detailCount
        )}
      </ItemCountColumn>
      <FreeFlowingColumn>
        {course.hasHodaiError && <ExclamationIcon color="red" />}
        {course.hodaiDisplayName}
      </FreeFlowingColumn>
      <IconColumn onClick={handleClickTrashbox}>
        <span>
          <DeleteIcon />
        </span>
      </IconColumn>
      <SpaceColumn>
        <ArrowIcon />
      </SpaceColumn>
    </LinkTableRow>
  )
}

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``
const ArrowIcon = styled(Icon).attrs({ name: 'arrowRight' })``
