import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'
import { containsKM } from '~/src/util/ServiceTypeCheck'

const { OrderTicketSortType } = Const

export const selectDisplayFirstDrinkDelaySetting = (state: AppState) => {
  if (
    !state.targetServingTimeSetting.displayOptionSetting
      .isFirstDrinkDelayEnabled
  ) {
    return undefined
  }

  return `1stドリンク：${state.targetServingTimeSetting.displayOptionSetting.firstDrinkDelayMinutes}分経過`
}

export const selectDisplayFirstFoodDelaySetting = (state: AppState) => {
  if (
    !state.targetServingTimeSetting.displayOptionSetting.isFirstFoodDelayEnabled
  ) {
    return undefined
  }

  return `フード1品目：${state.targetServingTimeSetting.displayOptionSetting.firstFoodDelayMinutes}分経過`
}

export const selectDisplayAlertSecondsEnabled = (state: AppState) => {
  if (
    !state.targetServingTimeSetting.displayOptionSetting.isAlertSecondsEnabled
  ) {
    return undefined
  }

  return '警告時間設定済み商品'
}

export const selectDisplayCookingNoticeEnabled = (state: AppState) => {
  return state.targetServingTimeSetting.displayOptionSetting
    .isCookingNoticeEnabled
    ? '表示する'
    : '表示しない'
}

export const selectDisplayOrderTicketSortType = (state: AppState) => {
  return state.targetServingTimeSetting.displayOptionSetting
    .orderTicketSortTypeId === OrderTicketSortType.BY_NOTICE_SECOND.ID
    ? '注意時間順'
    : '注文入力順'
}

export const selectIsDisplay = (state: AppState) => {
  return containsKM(state.auth.store?.serviceTypes)
}

export const selectKmSettingApiResponse = (state: AppState) =>
  state.api.kmSetting.response
