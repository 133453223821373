// 契約タイプ

export type ContractTypeId = '0' | '1' | '9'

const ContractTypes = {
  0: {
    ID: '0',
    TEXT: '契約なしホワイトリスト',
    INQUIRY: {
      PHONE: '0120-572-419',
      BUSINESS_HOURS: '受付時間9:30-23:00 (年中無休)',
      DESCRIPTION: '※ 3番を選択ください'
    }
  },
  1: {
    ID: '1',
    TEXT: 'RECRUIT',
    INQUIRY: {
      PHONE: '0120-572-419',
      BUSINESS_HOURS: '受付時間9:30-23:00 (年中無休)',
      DESCRIPTION: '※ 3番を選択ください'
    }
  },
  // 契約なしの場合はお試し状態
  9: {
    ID: '9',
    TEXT: '契約なし',
    INQUIRY: {
      PHONE: '',
      BUSINESS_HOURS: '',
      DESCRIPTION: ''
    }
  }
}

export default {
  ...ContractTypes,
  isTrial: (id: ContractTypeId) => ContractTypes[9].ID === id
}
