import PageTemplate from '~/src/components/templates/PageTemplate'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import { ValidationErrorModal } from '~/src/components/organisms/Modal/ValidationErrorModal/ValidationErrorModal'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { List } from './List/List'
import { OrderItemMemoTemplateFooter } from './Footer/Footer'
import {
  useInitializeState,
  useOnSubmit,
  useClearState,
  useOnDeleteConfirmModalDelete,
  useOnDeleteConfirmModalCancel
} from './hooks'
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectOrderItemMemoTemplateParams } from './selectors'

const breadcrumbs = [{ title: '注文メモ' }]
const description = [
  'キッチンへ伝えたい情報を、メモとして商品と一緒に注文送信することができます。「先」「後」などのよく使う内容をご登録ください。'
]

export const OrderItemMemoTemplate = () => {
  const {
    isDeleteConfirmModalOpen,
    deleteConfirmModalTitle,
    deleteConfirmModalMessage,
    validationMessage,
    deletingMemo,
    deletingMemoIndex,
    isEditing
  } = useSelector(selectOrderItemMemoTemplateParams)
  const handleInitializeState = useInitializeState()
  const handleClearState = useClearState()
  const handleDeleteConfirmModalDelete = useOnDeleteConfirmModalDelete(
    deletingMemo?.orderItemMemoTemplateId,
    deletingMemoIndex
  )

  const handleDeleteConfirmModalCancel = useOnDeleteConfirmModalCancel()
  const handleOnSubmit = useOnSubmit()
  const onSubmit = useCallback(() => {
    const hasValidationError = !!validationMessage
    handleOnSubmit(hasValidationError)
  }, [handleOnSubmit, validationMessage])

  useEffect(() => {
    handleInitializeState()
    return () => {
      handleClearState()
    }
  }, [handleInitializeState, handleClearState])

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<OrderItemMemoTemplateFooter onSubmitButtonClick={onSubmit} />}
      modal={
        <div>
          <DeleteConfirmModal
            isOpen={isDeleteConfirmModalOpen}
            onCancel={handleDeleteConfirmModalCancel}
            onDelete={handleDeleteConfirmModalDelete}
            headingMessage={deleteConfirmModalTitle}
          >
            {deleteConfirmModalMessage}
          </DeleteConfirmModal>
          <ValidationErrorModal>{validationMessage}</ValidationErrorModal>
          <DismissPrompt enabled={isEditing} />
        </div>
      }
      displayKM
      displayKP
    >
      <List />
    </PageTemplate>
  )
}
