import React, { useEffect } from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { AvailabilitySetting } from './AvailabilitySetting'
import { OtoshiSetting } from './OtoshiSetting/OtoshiSetting'
import { OtoshiFooter } from './Footer'
import { Messages } from './Messages'
import {
  useHandleFetchOtoshiSetting,
  useInitializeState,
  useClearState
} from './interactors'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'

export const Otoshi: React.VFC = () => {
  const description = [
    'お通し商品を登録することで、来店処理の際に、お通しを注文する画面を必ず表示できるようになります。これによりお通し注文の漏れやミスを防ぐことができます。'
  ]
  const breadcrumbs = [
    {
      title: 'お通し注文'
    }
  ]

  const Footer = <OtoshiFooter />
  const handleFetchOtoshiSetting = useHandleFetchOtoshiSetting()
  const handleInitializeState = useInitializeState()
  const handleClearState = useClearState()
  useEffect(() => {
    handleFetchOtoshiSetting()
  }, [handleFetchOtoshiSetting])
  useEffect(() => {
    handleInitializeState()
  }, [handleInitializeState])
  useEffect(() => {
    return handleClearState
  }, [handleClearState])
  const isEditing = useSelector(
    (state: AppState) => state.otoshiSetting.isEditing
  )
  const modal = <DismissPrompt enabled={isEditing} />

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={Footer}
      modal={modal}
      displayKP
      displayKM
    >
      <Messages />
      <AvailabilitySetting />
      <OtoshiSetting />
    </PageTemplate>
  )
}
