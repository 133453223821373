import React from 'react'
import styled from 'styled-components'

import Icon from '~/src/components/atoms/Icon'
import Const from '~/src/const'

const { Color } = Const

export default props => (
  <CheckboxLabel>
    <input type="checkbox" {...props} />
    <CheckboxInner>
      <CheckboxArrow />
    </CheckboxInner>
  </CheckboxLabel>
)

const CheckboxArrow = styled(Icon).attrs({ name: 'checkboxArrow' })``
const CheckboxInner = styled.span`
  cursor: pointer;
  display: inline-block;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  background-color: ${Color.WHITE};
  border: 1px solid ${Color.DARK_GRAY_2};
  border-radius: 4px;

  > svg {
    visibility: hidden;
    position: absolute;
    left: 4px;
    top: 6px;
  }
`

const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;

  > input {
    display: none;
  }

  > input:checked {
    & + span {
      background-color: ${Color.VIVID_BLUE};
      border: 1px solid ${Color.VIVID_BLUE};

      svg {
        visibility: visible;
      }
    }
  }

  > input:disabled {
    & + span {
      cursor: default;
      opacity: 0.3;
    }
  }
`
