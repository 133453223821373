import React from 'react'
import VariationGroupState from './VariationGroupState'
import useInteractors from '~/src/hooks/useInteractors'

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const VariationGroupStateContiner = () => {
  return <VariationGroupState {...useInteractor()} />
}

export default VariationGroupStateContiner
