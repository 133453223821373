import React, { useEffect } from 'react'
import InnerTable from './InnerTable'
import Const from '~/src/const'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Forward from '~/src/components/organisms/Forward'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import Footer from '~/src/components/organisms/Footer'
import Message from '~/src/components/atoms/Message'
import styled from 'styled-components'
import equal from 'fast-deep-equal'
import { selectDeviceCountModify } from './selectors'
import { useSelector } from 'react-redux'
import {
  useClearState,
  useHandleInitialize,
  useErrorModalClosed,
  useSubmit
} from './hooks'

const { Page, PlanPriceTypes } = Const

export const Modify: React.VFC = () => {
  const {
    isFreePlan,
    selectedDeviceCount,
    shouldForward,
    isModified,
    dismissPromptWhiteList,
    showError,
    registeredDevicesCount,
    planPriceType
  } = useSelector(selectDeviceCountModify, equal)

  const handleInitialize = useHandleInitialize()
  const handleClearState = useClearState()

  useEffect(() => {
    handleInitialize()
    return handleClearState
  }, [handleInitialize, handleClearState])

  const hadleErrorModalClosed = useErrorModalClosed()
  const handleSubmit = useSubmit(
    selectedDeviceCount,
    registeredDevicesCount,
    isFreePlan
  )

  if (!selectedDeviceCount) {
    return <Forward when={true} forwardPage={Page.DEVICE_COUNT._INDEX} />
  }

  const footer = (
    <Footer
      optionButtonLink={Page.DEVICE_COUNT._INDEX}
      submitButtonText="申込内容確認"
      disableSubmitButton={!isModified}
      onSubmitButtonClick={handleSubmit}
    />
  )

  const modal = (
    <div>
      <DismissPrompt enabled={isModified} whiteList={dismissPromptWhiteList} />
      <ErrorModal
        isError={showError}
        error={{
          title: '登録端末台数が利用台数を超えています',
          message:
            '利用台数は登録されている端末台数未満に設定することはできません。超えて設定する場合は「登録端末設定」から端末を解除してから、利用台数を設定してください。'
        }}
        onClose={hadleErrorModalClosed}
      />
    </div>
  )
  const breadcrumbs = [
    { title: '利用台数', page: Page.DEVICE_COUNT._INDEX },
    { title: '利用台数変更' }
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayMangerOnly
      displayContractedOnly
      displayKM
      displayKP
    >
      {planPriceType?.ID === PlanPriceTypes.UNSPECIFIED.ID && (
        <MessageContainer>
          <Message
            type="warning"
            messageList={[
              '契約内容を切り替え中のため、一部の情報を表示できません。',
              '適用中の契約内容を確認したい場合は、ヘルプデスクにお問い合わせください。'
            ]}
          />
        </MessageContainer>
      )}
      <InnerTable />
      <Forward when={shouldForward} forwardPage={Page.DEVICE_COUNT.CONFIRM} />
    </PageTemplate>
  )
}

const MessageContainer = styled.div`
  margin-bottom: 24px;
`
