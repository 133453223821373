import React from 'react'

import Footer from '~/src/components/organisms/Footer'
import Const from '~/src/const/'
const { Page } = Const

type Props = {
  canSubmit: boolean
  saveButtonHandler: () => void
  beforeOptionButtonClick: () => boolean
}

const FooterContainer: React.VFC<Props> = props => (
  <Footer
    noUseSubmitButton={false}
    disableSubmitButton={!props.canSubmit}
    onSubmitButtonClick={props.saveButtonHandler}
    optionButtonLink={Page.TOPPING.TOPPING_GROUP.CREATE}
    optionButtonText="トッピンググループを登録する"
    beforeOptionButtonClick={props.beforeOptionButtonClick}
  />
)
export { FooterContainer as Footer }
