import Const from '~/src/const'

const { Size } = Const

/**
 * モーダル用コンテンツのpadding-topを取得
 * ヘッダを持たない場合に、幅を縮める
 * @param  {number} additionalMarginBottom サブコンテンツ用の追加マージン
 * @return {number} コンテンツ用padding-bottoms
 */
export const getContentPaddingTop = ({ hasHeader }) =>
  hasHeader
    ? Size.MODAL.CONTENT.PADDING_Y
    : Size.MODAL.CONTENT.PADDING_Y_FOR_NO_HEADER

/**
 * モーダル用コンテンツのpadding-bottomを取得。
 * ヘッダを持たない場合に、幅を縮め、
 * サブコンテンツ用の追加マージンがあった場合に、幅を広げる。
 * @param  {number} additionalMarginBottom サブコンテンツ用の追加マージン
 * @return {number} コンテンツ用padding-bottoms
 */
export const getContentPaddingBottom = ({
  hasHeader,
  additionalMarginBottom
}) => {
  if (!hasHeader) return Size.MODAL.CONTENT.PADDING_Y_FOR_NO_HEADER
  return additionalMarginBottom
    ? additionalMarginBottom + Size.MODAL.CONTENT.PADDING_Y_FOR_SUB_CONTENT
    : Size.MODAL.CONTENT.PADDING_Y
}
