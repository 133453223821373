import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchSeatSet,
  updateSeatSet,
  clearSeatSetState
} from '~/src/redux/modules/SeatSet/SeatSet'

export const useInitialize = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearSeatSetState())
    dispatch(fetchSeatSet())
  }, [dispatch])
}

export const useDeinit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearSeatSetState())
  }, [dispatch])
}

export const useSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(updateSeatSet())
  }, [dispatch])
}
