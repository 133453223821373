import React from 'react'
import { useHistory } from 'react-router'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Discription from './Discription'
import CategoryList from './CategoryList'
import DisconnectModal from './DisconnectModal'
import VariationGroupEditor from './VariationGroupEditor'
import VariationGroupModal from './VariationGroupModal'
import Footer from './Footer'
import DismissPrompt from '../Common/DismissPrompt'
import Const from '~/src/const'
const { Page } = Const

type Props = {
  hasFile: boolean
}

const Items: React.FC<Props> = props => {
  const history = useHistory()
  if (!props.hasFile) {
    history.replace(Page.VARIATION_GROUP.GETTING_STARTED)
    return null
  }
  const breadcrumbs = [{ title: 'バリエーションを一括登録する商品の選択' }]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={<Footer />}
      modal={
        <>
          <DisconnectModal />
          <VariationGroupEditor />
          <VariationGroupModal />
          <DismissPrompt />
        </>
      }
      displayKM
    >
      <Discription />
      <CategoryList />
    </PageTemplate>
  )
}

export default React.memo(Items)
