import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import Const from '~/src/const'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import Footer from '~/src/components/organisms/Footer'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { RegisterItemModal } from '~/src/components/organisms/Modal/RegisterItemModal/RegisterItemModal'
import {
  selectInitialCategories,
  selectIsSelectingHodaiPlan,
  selectModalCategories,
  selectIsEditing,
  selectHodaiSettingState,
  selectIsSubmitButtonDisabled,
  selectItemIdByHodaiId,
  selectLastOrderMinutesByHodaiId
} from './selectors'
import {
  useSubmitHodaiSelect,
  useCloseHodaiSelect,
  useClearState,
  useInitialize
} from './hooks'
import {
  useFetch,
  useHandlePostCreateHodai,
  useHandlePostUpdateHodai
} from './interactors'
import { Messages } from './Messages/Messages'
import { selectError } from './Messages/selectors'
import { AppState } from '~/src/redux/reducer'
import { Step3 } from './Step3'
import {
  selectIsLastOrderSettingSubmittable,
  selectLastOrderMinutesInputError
} from './LastOrderSetting/selectors'

const { Page } = Const

export const HodaiSetting: React.VFC = () => {
  const { hodaiId } = useParams<{ hodaiId: string }>()
  const isSelectingHodaiPlan = useSelector(selectIsSelectingHodaiPlan)
  const lastOrderMinutes = useSelector(selectLastOrderMinutesByHodaiId(hodaiId))
  const categories = useSelector(selectModalCategories)
  const itemIdForHodai = useSelector(selectItemIdByHodaiId(hodaiId))
  const isCreate = !hodaiId
  const createBreadcrumbs = [
    { title: '放題プラン', page: Page.HODAI.LIST },
    { title: isCreate ? '放題プラン登録' : '放題プラン編集' }
  ]

  // 画面初期化時のStep2の初期化
  const initialCategories = useSelector((state: AppState) =>
    selectInitialCategories(state, hodaiId)
  )

  const clearState = useClearState()
  const fetch = useFetch()
  const initialize = useInitialize(initialCategories, lastOrderMinutes)

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    return clearState
  }, [clearState])

  const handleSubmitHodaiSelect = useSubmitHodaiSelect()
  const handleCloseHodaiSelect = useCloseHodaiSelect()

  const hodaiSettingState = useSelector(selectHodaiSettingState)
  const lastOrderMinutesInputError = useSelector(
    selectLastOrderMinutesInputError
  )
  const isLastOrderSettingSubmittable = useSelector(
    selectIsLastOrderSettingSubmittable(lastOrderMinutesInputError)
  )
  const handlePostCreateHodai = useHandlePostCreateHodai(hodaiSettingState)
  const handlePostUpdateHodai = useHandlePostUpdateHodai(
    hodaiSettingState,
    hodaiId
  )
  const {
    isDeletedHodaiPlanItemError,
    hasSku,
    hasDeletedHodaiPlanCategoryError
  } = useSelector(selectError(itemIdForHodai))
  const isSubmitButtonDisabled =
    useSelector(selectIsSubmitButtonDisabled(hodaiId)) ||
    isDeletedHodaiPlanItemError ||
    hasSku ||
    hasDeletedHodaiPlanCategoryError ||
    !isLastOrderSettingSubmittable
  const footer = (
    <Footer
      noUseOptionButton
      disableSubmitButton={isSubmitButtonDisabled}
      onSubmitButtonClick={
        hodaiId ? handlePostUpdateHodai : handlePostCreateHodai
      }
    />
  )

  const isEditing = useSelector(selectIsEditing)
  const modal = (
    <div>
      {isSelectingHodaiPlan && (
        <RegisterItemModal
          categories={categories}
          onSubmit={handleSubmitHodaiSelect}
          onClose={handleCloseHodaiSelect}
        />
      )}
      <DismissPrompt enabled={isEditing} />
    </div>
  )

  return (
    <PageTemplate
      breadcrumbs={createBreadcrumbs}
      footer={footer}
      modal={modal}
      displayKP
      displayKM
    >
      <Messages itemId={itemIdForHodai} />
      <Step1
        hasWarning={
          isDeletedHodaiPlanItemError ||
          hasSku ||
          hasDeletedHodaiPlanCategoryError
        }
      />
      <Step2 />
      <Step3 />
    </PageTemplate>
  )
}
