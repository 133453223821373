import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  actions as apiActions,
  fetchKmSetting
} from '~/src/api/km/settings/actions'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import actions from '~/src/redux/modules/TargetServingTime/actions'

export const useHandleInitialize = (
  targetServingTimeInteractor: TargetServingTimeInteractor
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    // fetch前にもclearState()しておく
    targetServingTimeInteractor.clearState()
    targetServingTimeInteractor.fetchInitialData()
    // TODO: old構造からnew構造を使う苦肉の策（ここもnew構造になるときにきれいになる
    dispatch(fetchKmSetting())
  }, [dispatch, targetServingTimeInteractor])
}

export const useHandleDeinit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(apiActions.clearState())
    dispatch(actions.clearState())
  }, [dispatch])
}
