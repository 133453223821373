import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'

const { ContractType } = Const

export const selectSideMenuFooterState = (state: AppState) => {
  const { store } = state.auth
  // 契約情報がない場合はBOが使えないはずだがデフォルトをRにして問い合わせできるようにする
  const contract = store ? ContractType[store.contractType] : ContractType[1]
  return {
    inquiry: contract.INQUIRY
  }
}
