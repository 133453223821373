import { Set, OrderedMap, Map } from 'immutable'
import { ActionType, getType } from 'typesafe-actions'

import Categories from '~/src/redux/models/Category/Categories'
import Items from '~/src/redux/models/Item/Items'
import { ToppingGroup } from '~/src/redux/models/Topping/ToppingGroup'
import { ItemByToppingGroups } from '~/src/redux/models/Item/ItemByToppingGroups'

import { ItemByToppingGroupState, ItemError, SelectedItemError } from './types'
import { actions } from './actions'

import {
  HodaiApiResponse,
  CourseApiResponse,
  OtoshiApiResponse
} from '~/src/api/handy/items/types'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'

const initialState: ItemByToppingGroupState = {
  categories: OrderedMap<string, SimpleCategoryForTopping>(),
  items: OrderedMap<string, SimpleItemForTopping>(),
  itemByToppingGroups: new ItemByToppingGroups(),
  toppingGroup: new ToppingGroup(),
  selectedItemIds: Set<string>(),
  isEdited: false,
  itemErrors: Map<string, Set<ItemError>>(),
  selectedItemErrors: Map<string, Set<SelectedItemError>>(),
  isToppingGroupNotFound: false
}

export const itemByToppingGroup = (
  state: ItemByToppingGroupState = initialState,
  action: ActionType<typeof actions>
): ItemByToppingGroupState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.fetchInitialDataSucceeded): {
      const selectedToppingGroupId = action.payload.toppingGroupId

      const result = action.payload.items.result
      if (
        !result.toppingGroups?.find(
          toppingGroup => toppingGroup.toppingGroupId === selectedToppingGroupId
        )
      ) {
        return {
          ...state,
          isToppingGroupNotFound: true
        }
      }
      const items = new Items(result.items)
      const categories = new Categories(
        result.categories,
        items,
        result.itemCategoryJunctions
      )
      const itemByToppingGroups = new ItemByToppingGroups(
        result.itemByToppingGroups,
        result.items,
        result.categories,
        result.itemCategoryJunctions
      )
      const toppingGroup = new ToppingGroup(
        result.toppingGroups?.find(
          toppingGroup => toppingGroup.toppingGroupId === selectedToppingGroupId
        ),
        result.items,
        result.categories,
        result.itemCategoryJunctions,
        result.itemByToppingGroups
      )
      const selectedItemIds = itemByToppingGroups
        .getItemIdsByToppingGroupId(selectedToppingGroupId)
        .filter(itemId => items.items.has(itemId))
      return {
        ...state,
        categories: categories.getSimpleCategoryForToppings(),
        items: items.getSimpleItemForToppings(),
        itemByToppingGroups: itemByToppingGroups,
        toppingGroup: toppingGroup,
        selectedItemIds: selectedItemIds,
        itemErrors: generateItemErrors(
          items,
          result.hodais,
          result.courses,
          categories,
          result.otoshis
        ),
        selectedItemErrors: generateSelectedItemErrors(
          categories,
          items,
          selectedItemIds
        )
      }
    }
    case getType(actions.addItem): {
      return {
        ...state,
        selectedItemIds: state.selectedItemIds.add(action.payload.itemId),
        isEdited: state.selectedItemErrors.every(
          errorSet => errorSet.size === 0
        )
      }
    }
    case getType(actions.removeItem): {
      const selectedItemErrors = state.selectedItemErrors.delete(
        action.payload.itemId
      )
      return {
        ...state,
        selectedItemIds: state.selectedItemIds.delete(action.payload.itemId),
        isEdited: selectedItemErrors.every(errorSet => errorSet.size === 0),
        selectedItemErrors: selectedItemErrors
      }
    }
    case getType(actions.addAll): {
      const itemIds: Set<string> = action.payload.itemIds.filter(
        itemId => !state.itemErrors.has(itemId)
      )
      return {
        ...state,
        selectedItemIds: state.selectedItemIds.merge(itemIds),
        isEdited: state.selectedItemErrors.every(
          errorSet => errorSet.size === 0
        )
      }
    }
    case getType(actions.removeAll): {
      const toBeRemovedList: Set<string> = action.payload.itemIds
      const selectedItemErrors =
        state.selectedItemErrors.deleteAll(toBeRemovedList)
      return {
        ...state,
        selectedItemIds: state.selectedItemIds.filter(
          itemId => !toBeRemovedList.contains(itemId)
        ),
        isEdited: selectedItemErrors.every(errorSet => errorSet.size === 0),
        selectedItemErrors: selectedItemErrors
      }
    }
    case getType(actions.saveSucceeded): {
      return {
        ...state,
        isEdited: false
      }
    }
    default:
      return state
  }
}

const generateItemErrors = (
  items: Items,
  hodais?: HodaiApiResponse[],
  courses?: CourseApiResponse[],
  categories?: Categories,
  otoshis?: OtoshiApiResponse[]
) => {
  let itemErrors = Map<string, Set<ItemError>>()
  items.items.forEach(item => {
    let errors = Set<ItemError>()
    if (!categories?.hasItem(item.itemId)) {
      errors = errors.add('NO_CATEGORY')
    }
    if (hodais?.some(hodai => hodai.itemId === item.itemId)) {
      errors = errors.add('HODAI')
    }
    if (courses?.some(course => course.itemId === item.itemId)) {
      errors = errors.add('COURSE')
    }
    if (item.isTaxFree()) {
      errors = errors.add('TAX_FREE')
    }
    if (otoshis?.some(otoshi => otoshi.itemId === item.itemId)) {
      errors = errors.add('OTOSHI')
    }
    if (!errors.isEmpty()) {
      itemErrors = itemErrors.set(item.itemId, errors)
    }
  })
  return itemErrors
}

const generateSelectedItemErrors = (
  categories: Categories,
  items: Items,
  selectedItemIds?: Set<string>
) => {
  let selectedItemErrors = Map<string, Set<SelectedItemError>>()
  selectedItemIds?.forEach(itemId => {
    let errors = Set<SelectedItemError>()
    const item = items.items.get(itemId)
    if (!item) {
      return // レジ側で削除されているため処理対象外
    }
    if (!categories.hasItem(itemId)) {
      errors = errors.add('NO_CATEGORY')
    }
    if (item.isTaxFree()) {
      errors = errors.add('TAX_FREE')
    }
    if (!errors.isEmpty()) {
      selectedItemErrors = selectedItemErrors.set(itemId, errors)
    }
  })
  return selectedItemErrors
}
