import { AppState } from '~/src/redux/reducer'
import { containsKM } from '~/src/util/ServiceTypeCheck'

export const selectAvailableMenu = (state: AppState) => {
  const store = state.auth.store
  return containsKM(store?.serviceTypes)
}

export const selectCookingNoticeEnabled = (state: AppState) =>
  state.optionSetting.kmSettings.isCookingNoticeEnabled

export const selectOrderTicketSortTypeId = (state: AppState) =>
  state.optionSetting.kmSettings.orderTicketSortTypeId

export const selectKmSettingApiResponse = (state: AppState) =>
  state.api.kmSetting.response
