import React from 'react'

import PageTemplate from '~/src/components/templates/PageTemplate'
import Const from '~/src/const'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
const { URL } = Const

export const ReferenceNumber: React.VFC = () => {
  const breadcrumbs = [{ title: '整理番号' }]
  const description = [
    'Airレジからキッチンモニターに送信される注文には、整理番号が割り振られます。',
    '整理番号はレシートに印字することもでき、お客様の呼び出しを行う際にお使いいただけます。'
  ]
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      displayKM
      displayKMOnly
      description={description}
    >
      <Message
        type="info"
        messageList={[
          <>
            この項目は
            <InlineLink href={URL.REGI_REFERENCE_NUMBER} target="_blank">
              Airレジのキッチンモニター連携設定ページ
            </InlineLink>
            で設定する項目です。リンク先へ移動して設定を行ってください。
          </>
        ]}
      />
    </PageTemplate>
  )
}
