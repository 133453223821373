import React from 'react'
import {
  TableRow,
  NameColumn,
  PriceColumn,
  VariationColumn,
  TrashColumn
} from './Common/TableParts'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import CategoryRow from './Category'

type Props = {
  categories: SimpleCategory[]
  haveNonCategoryItem: boolean
}

const CategoryList: React.FC<Props> = props => {
  return (
    <div>
      <TableRow header>
        <NameColumn>カテゴリー名/商品名</NameColumn>
        <PriceColumn>価格</PriceColumn>
        <VariationColumn>バリエーション</VariationColumn>
        <TrashColumn>解除</TrashColumn>
      </TableRow>
      {props.categories.map((category: SimpleCategory) => (
        <CategoryRow key={category.categoryId} category={category} />
      ))}
      {props.haveNonCategoryItem && <CategoryRow category={undefined} />}
    </div>
  )
}

export default React.memo(CategoryList)
