import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'
import { ItemsApiState } from './types'

const initialState = {
  response: {
    categories: [],
    courses: [],
    hodais: [],
    itemByToppingGroups: [],
    itemCategoryJunctions: [],
    items: [],
    toppingGroups: [],
    otoshis: []
  },
  fetched: false,
  failed: false
}

export const itemsApi = (
  state: ItemsApiState = initialState,
  action: ActionType<typeof actions>
): ItemsApiState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.fetchSucceeded): {
      return {
        ...initialState,
        response: {
          ...initialState.response,
          ...action.payload.itemsApiResponse
        },
        fetched: true
      }
    }
    case getType(actions.fetchFailed): {
      return {
        ...state,
        failed: true
      }
    }
    default:
      return state
  }
}
