/**
 * エラーコード定数
 */
export default {
  SUCCESS: '0000', // 正常
  VALIDATION_ERROR: '1000', // 入力エラー
  SYSTEM_ERROR: '9999', // システムエラー
  UNAUTHORIZED: '2001', // 未ログイン
  PLF_TOKEN_EXPIRED: '2002', // PLFのアクセストークンが期限切れ
  FORCE_LOGOUT: '2009' // 強制ログアウト
}
