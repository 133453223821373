import { AppState } from '~/src/redux/reducer'
import { ItemApiResponse } from '~/src/api/handy/items/types'
import { Item, CourseItem, CategoryItem, HodaiItem } from './types'
import Const from '~/src/const'

const { ItemType } = Const

type ItemIdMap = {
  [_: string]: Item
}

type CategoryIdMap = {
  [_: string]: CategoryItem
}

export const selectCourses = (state: AppState): CourseItem[] => {
  const response = state.api.items.response
  const courses = response.courses
  if (!courses) {
    return []
  }
  const itemMap = createItemMap(response.items)

  // CategoryItemをcategoryMapByCategoryId[categoryId]で参照できるようにする
  const categoryMapByCategoryId: CategoryIdMap = {}
  response.categories!.forEach(c => {
    categoryMapByCategoryId[c.categoryId] = {
      name: c.categoryName,
      isDisplayed: c.isDisplayed
    }
  })

  // CategoryItemを categoryMapByItemId[itemId]で参照できるようにする
  const categoryMapByItemId: CategoryIdMap = {}
  response.itemCategoryJunctions!.forEach(e => {
    categoryMapByItemId[e.itemId] = categoryMapByCategoryId[e.categoryId]
  })
  return courses
    .sort((a, b) => b.createdAt - a.createdAt)
    .map(course => {
      const item = itemMap[course.itemId]
      const category = categoryMapByItemId[course.itemId]

      const hodaiCourseDetail = course.courseDetails?.find(
        detail => !!detail.hodaiId
      )
      let hodaiDisplayName = '放題プランなし'
      let hasHodaiError = false
      if (hodaiCourseDetail) {
        const hodaiItem = itemMap[hodaiCourseDetail.itemId]
        const hodaiCategory = categoryMapByItemId[hodaiCourseDetail.itemId]
        const hodai = {
          itemName: hodaiItem?.name || undefined,
          isCategoryUndefined: !hodaiCategory,
          isDeleted: !hodaiItem,
          isDisplayed: hodaiItem?.isDisplayed || false,
          isSku: hodaiItem?.isSku || false
        }
        hodaiDisplayName = generateHodaiDisplayName(hodai)
        hasHodaiError = checkHasHodaiError(hodai)
      }
      const courseItem = {
        itemId: course.itemId,
        courseId: course.courseId,
        itemName: item?.name || undefined,
        itemDisplayName: '', // 初期値
        itemPrice: item?.price || 0,
        taxType: item?.taxType || undefined,
        detailCount:
          course.courseDetails?.filter(detail => !detail.hodaiId).length || 0,
        isDeleted: !item,
        isSku: item?.isSku || false,
        isDisplayed: item?.isDisplayed || false,
        categoryDisplayName: '', // 初期値
        hodaiDisplayName: hodaiDisplayName,
        hasHodaiError: hasHodaiError,
        isCategoryUndefined: !category
      }
      courseItem.itemDisplayName = generateCourseDisplayName(courseItem)
      courseItem.categoryDisplayName = generateCategoryDisplayName(
        courseItem,
        category
      )

      return courseItem
    })
}

export const selectDeleteTargetCourse = (state: AppState) =>
  state.courseTop.deleteTargetCourse

const createItemMap = (items?: ItemApiResponse[]) => {
  if (!items) {
    return {}
  }
  return items.reduce((previous: ItemIdMap, current) => {
    previous[current.itemId] = toItem(current)
    return previous
  }, {})
}

const toItem = (item: ItemApiResponse): Item => {
  return {
    id: item.itemId,
    name: item.itemName,
    price: item.price || 0,
    taxType: item.taxType,
    isDisplayed: item.isDisplayed,
    isSku: item.itemType === ItemType.SKU
  }
}

export const generateCourseDisplayName = (course: CourseItem): string => {
  let itemName = '[削除済み]'
  let errorDetailText = ''
  if (!course.isDeleted && course.itemName) {
    if (course.isSku) {
      errorDetailText += '(バリエーションあり)'
    }
    if (course.isCategoryUndefined) {
      errorDetailText += '(カテゴリー未設定)'
    }
    if (errorDetailText) {
      itemName = `[${course.itemName}${errorDetailText}]`
    } else if (course.isDisplayed) {
      itemName = course.itemName
    } else {
      itemName = course.itemName + '(非表示)'
    }
  }
  return itemName
}

export const generateCategoryDisplayName = (
  course: CourseItem,
  category?: CategoryItem
): string => {
  let categoryName = '-'
  if (!course.isDeleted) {
    if (category) {
      if (category.isDisplayed) {
        categoryName = category.name
      } else {
        categoryName = category.name + '(非表示)'
      }
    } else {
      categoryName = '未設定'
    }
  }
  return categoryName
}

const checkHasHodaiError = (hodai: HodaiItem) =>
  hodai.isDeleted || hodai.isSku || hodai.isCategoryUndefined

export const generateHodaiDisplayName = (hodai: HodaiItem): string => {
  if (hodai.isDeleted) return '[削除済み]'
  if (!checkHasHodaiError(hodai) && hodai.itemName)
    return hodai.isDisplayed ? hodai.itemName : hodai.itemName + '(非表示)'

  const hodaiSkuString = hodai.isSku ? '(バリエーションあり)' : null

  const hodaiCategoryString = !hodai.isCategoryUndefined
    ? null
    : '(カテゴリー未設定)'

  const hodaiPostFixString = hodaiSkuString
    ? hodaiCategoryString
      ? ''.concat(hodaiSkuString, hodaiCategoryString)
      : hodaiSkuString
    : hodaiCategoryString || ''

  return '[' + hodai.itemName + hodaiPostFixString + ']'
}
