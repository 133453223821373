import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Toggle from '~/src/components/atoms/Toggle'

const { Size, Color } = Const

export type Props = {
  onToggle: Function
  name?: string
  disabled?: boolean
  checked: boolean
  title: string
  description: string
  hasBorderTop?: boolean
  hasBorderBottom?: boolean
}

export const SettingToggle: React.VFC<Props> = ({
  onToggle,
  name,
  disabled,
  checked,
  title,
  description
}) => (
  <StyledBaseRow>
    <Wrapper>
      <SettingNameArea>
        {title}
        <Description>{description}</Description>
      </SettingNameArea>
      <ToggleAreaStyle>
        <Toggle
          onToggle={onToggle}
          name={name}
          checked={checked}
          disabled={!!disabled}
        />
      </ToggleAreaStyle>
    </Wrapper>
  </StyledBaseRow>
)

const SettingNameArea = styled.div`
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border: none;
  padding: 0 16px;
  position: relative;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  width: 100%;
`

const ToggleAreaStyle = styled.div`
  width: 100px;
  margin: 8px 16px;
  float: right;
  text-align: right;
`
const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`

const StyledBaseRow = styled.div`
  background-color: ${Color.WHITE};
  top: -1px;
  margin-top: -1px;
  padding: 16px 0;
  table-layout: fixed;
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 480px;
  }
  width: 54%;
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
`

const Wrapper = styled.div`
  display: flex;
`
