import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

export const ContentColumn = styled(TableColumn)`
  word-wrap: break-word;
  text-align: left;
`
export const FunctionColumn = styled(TableColumn)`
  width: 30%;
  text-align: right;
  padding-left: 0;
`
