import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SortEnd } from 'react-sortable-hoc'
import { actions } from '../actions'

export const useReleaseAll = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.releaseAll())
  }, [dispatch])
}

export const useReleaseItem = (index: number) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.releaseItem(index))
  }, [dispatch, index])
}

export const useSortItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (sort: SortEnd) => {
      dispatch(
        actions.sortItem({ oldIndex: sort.oldIndex, newIndex: sort.newIndex })
      )
    },
    [dispatch]
  )
}

export const useSelectItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    item => {
      dispatch(actions.selectItem({ item }))
    },
    [dispatch]
  )
}
