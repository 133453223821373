import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '~/src/components/organisms/Footer'
import {
  selectIsEditing,
  selectSelectedItems,
  selectIsOtoshiEnabled,
  selectErrors
} from './selectors'
import { selectHandySetting } from '~/src/redux/selectors/handyApiSelectors'
import { useHandlePostReplaceOtoshi } from './interactors'
import { OtoshiSettingState } from './types'
import { HandySettingApiResponse } from '~/src/api/handy/settings/types'
import { AppState } from '~/src/redux/reducer'

export const OtoshiFooter: React.VFC = () => {
  const selectedItems = useSelector(selectSelectedItems)
  const isEdited = useSelector(selectIsEditing)
  const isOtoshiEnabled = useSelector(
    (state: AppState) => state.otoshiSetting.isOtoshiEnabled
  )
  const otoshiSetting: OtoshiSettingState = {
    isOtoshiEnabled: useSelector(selectIsOtoshiEnabled),
    selectedItems: selectedItems,
    isEditing: isEdited
  }
  const handySetting: HandySettingApiResponse = useSelector(selectHandySetting)
  const errors = useSelector(selectErrors)
  const hasError =
    errors.hasErrorExceededLimit ||
    errors.hasDeletedItem ||
    errors.hasEmptyCategory ||
    errors.hasTaxFree ||
    errors.hasVariation
  return (
    <Footer
      disableSubmitButton={
        !isEdited || hasError || (selectedItems.length === 0 && isOtoshiEnabled)
      }
      noUseOptionButton={true}
      onSubmitButtonClick={useHandlePostReplaceOtoshi(
        otoshiSetting,
        handySetting
      )}
    />
  )
}
