import APIClient from '~/src/util/APIClient'
import Const from '~/src/const'
import { setLoading, clearLoading, showAndBlurToast } from '../UI'
import { handleError } from '~/src/redux/modules/ApiError'
import { openValidationErrorModal } from '~/src/redux/modules/UI'
import {
  KitchenPositionManagementState,
  KitchenPositionSettingFetchApiResponse,
  KitchenPositionSettingPostApiResponse
} from './types'
import KitchenPositionSetting from '~/src/redux/models/KitchenPositionSetting/KitchenPositionSetting'
import { ApiResponse } from '~/src/api/types'

const { API, Toast, Time } = Const
const FETCH_SUCCESS = 'oes/kitchenPosition/FETCH_SUCCESS'
const CLEAR_STATE = 'oes/kitchenPosition/CLEAR_STATE'
const SELECT_DEFAULT = 'oes/kitchenPosition/SELECT_DEFAULT'
const PUSH_NEW_KITCHEN_POSITION =
  'oes/kitchenPosition/PUSH_NEW_KITCHEN_POSITION'
const UPDATE_KITCHEN_POSITION_NAME =
  'oes/kitchenPosition/UPDATE_KITCHEN_POSITION_NAME'
const SET_SEAT_SET_USABLE = 'oes/kitchenPosition/SET_SEAT_SET_USABLE'
const DELETE_KITCHEN_POSITION_FOR_LOCAL =
  'oes/kitchenPosition/DELETE_KITCHEN_POSITION_FOR_LOCAL'
const SET_DELETING_KITCHEN_POSITION_INDEX =
  'oes/kitchenPosition/SET_DELETING_KITCHEN_POSITION_INDEX'
const SET_IS_DELETE_CONFIRM_MODAL_OPEN =
  'oes/kitchenPosition/SET_IS_DELETE_CONFIRM_MODAL_OPEN'
const POST_SUCCESS = 'oes/kitchenPosition/POST_SUCCESS'

// action
export const clearState = () => {
  return {
    type: CLEAR_STATE
  }
}

const fetchSuccess = (
  response: ApiResponse<KitchenPositionSettingFetchApiResponse>
) => {
  return {
    type: FETCH_SUCCESS,
    kitchenPositionSetting: response.result.kitchenPositionList
      ? new KitchenPositionSetting(response.result.kitchenPositionList)
      : new KitchenPositionSetting()
  }
}

export const fetchKitchenPositions = () => {
  // @ts-ignore
  return dispatch => {
    return APIClient.get(API.GET_KITCHEN_POSITIONS)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(fetchSuccess(json)))
      .catch(error => dispatch(handleError(error)))
      .then(() => {
        // 一瞬で消えないように0.5秒入れる
        setTimeout(() => dispatch(clearLoading()), Time.LOADING)
      })
  }
}

const postSuccess = (
  response: ApiResponse<KitchenPositionSettingPostApiResponse>
) => {
  return {
    type: POST_SUCCESS,
    kitchenPositionSetting: new KitchenPositionSetting(
      response.result.kitchenPositionList
    )
  }
}

export const submitKitchenPosition = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    if (getState().kitchenPosition.kitchenPositionSetting.isValid()) {
      return dispatch(postMergeKitchenPosition())
    } else {
      return dispatch(openValidationErrorModal())
    }
  }
}

const postMergeKitchenPosition = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    return APIClient.post(
      API.POST_SAVE_KITCHEN_POSITION,
      getState().kitchenPosition.kitchenPositionSetting.buildRequestJsonForMerge()
    )
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(postSuccess(json)))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const selectDefault = (index: number) => {
  return {
    type: SELECT_DEFAULT,
    index
  }
}

export const pushNewKitchenPosition = () => {
  return {
    type: PUSH_NEW_KITCHEN_POSITION
  }
}

export const updateKitchenPositionName = (index: number, text: string) => {
  return {
    type: UPDATE_KITCHEN_POSITION_NAME,
    index,
    text
  }
}

const postDeleteKitchenPosition = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { deleteTargetIndex, kitchenPositionSetting } =
      getState().kitchenPosition

    return APIClient.post(
      API.POST_DELETE_KITCHEN_POSITION,
      kitchenPositionSetting.buildRequestJsonForDelete(deleteTargetIndex)
    )
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(postSuccess(json)))
      .then(() => dispatch(showAndBlurToast(Toast.DELETED_MESSAGE)))
      .then(() => dispatch(fetchKitchenPositions()))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const deleteKitchenPosition = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { deleteTargetIndex, kitchenPositionSetting } =
      getState().kitchenPosition

    // IDがないもの(サーバにないもの)は通信なしで消す
    if (
      !kitchenPositionSetting.kitchenPositionList.get(deleteTargetIndex)
        .kitchenPositionId
    ) {
      return dispatch(deleteKitchenPositionForLocal())
    } else {
      return dispatch(postDeleteKitchenPosition())
    }
  }
}

export const setSeatSetUsableByIndex = (
  index: number,
  isSeatSetUsable: boolean
) => ({
  type: SET_SEAT_SET_USABLE,
  index,
  isSeatSetUsable
})

const deleteKitchenPositionForLocal = () => ({
  type: DELETE_KITCHEN_POSITION_FOR_LOCAL
})

export const setDeleteKitchenPositionIndex = (index: number) => ({
  type: SET_DELETING_KITCHEN_POSITION_INDEX,
  index
})

export const setIsDeleteConfirmModalOpen = (isOpen: boolean) => ({
  type: SET_IS_DELETE_CONFIRM_MODAL_OPEN,
  isOpen
})

const initialState: KitchenPositionManagementState = {
  kitchenPositionSetting: new KitchenPositionSetting(),
  isEditing: false,
  deleteTargetIndex: null,
  isDeleteConfirmModalOpen: false
}

// Reducer
export default (
  state = initialState,
  // @ts-ignore
  action
): KitchenPositionManagementState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case FETCH_SUCCESS:
      return {
        ...state,
        kitchenPositionSetting: action.kitchenPositionSetting
      }
    case POST_SUCCESS:
      return {
        ...state,
        kitchenPositionSetting: action.kitchenPositionSetting,
        isEditing: false
      }
    case SELECT_DEFAULT:
      return {
        ...state,
        kitchenPositionSetting: state.kitchenPositionSetting.changeIsDefault(
          action.index
        ),
        isEditing: true
      }
    case PUSH_NEW_KITCHEN_POSITION:
      return {
        ...state,
        kitchenPositionSetting:
          state.kitchenPositionSetting.setNewKitchenPosition(),
        isEditing: true
      }
    case UPDATE_KITCHEN_POSITION_NAME:
      return {
        ...state,
        kitchenPositionSetting:
          state.kitchenPositionSetting.setKitchenPositionByIndex(
            action.index,
            action.text
          ),
        isEditing: true
      }
    case SET_SEAT_SET_USABLE: {
      return {
        ...state,
        kitchenPositionSetting:
          state.kitchenPositionSetting.setSeatSetUsableByIndex(
            action.index,
            action.isSeatSetUsable
          ),
        isEditing: true
      }
    }
    case DELETE_KITCHEN_POSITION_FOR_LOCAL: {
      const kitchenPositionSetting =
        state.kitchenPositionSetting.deleteKitchenPosition(
          state.deleteTargetIndex!
        )
      return {
        ...state,
        kitchenPositionSetting: kitchenPositionSetting.setIsDefault(),
        deleteTargetIndex: null
      }
    }
    case SET_DELETING_KITCHEN_POSITION_INDEX:
      return {
        ...state,
        deleteTargetIndex: action.index
      }
    case SET_IS_DELETE_CONFIRM_MODAL_OPEN:
      return {
        ...state,
        isDeleteConfirmModalOpen: action.isOpen
      }
    default:
      return state
  }
}
