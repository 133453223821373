import { Record, List, Map } from 'immutable'
import { PrinterResponse } from '../../modules/PrintTarget/types'
import Printer from './Printer'

type PrinterRecord = {
  printers: List<Printer>
  printersMap: Map<string, Printer>
}

const PrintersRecord = Record<PrinterRecord>({
  printers: List(),
  printersMap: Map<string, Printer>()
})

export default class Printers extends PrintersRecord {
  constructor(printers: PrinterResponse[] = []) {
    super()
    const list = List(printers)
      .map(
        printer =>
          new Printer({
            createdAt: printer.createdAt,
            isDefault: printer.isDefault,
            isRingable: printer.isRingable,
            macAddress: printer.macAddress,
            name: printer.name,
            paperWidthType: printer.paperWidthType,
            printerId: printer.printerId,
            printerModelId: printer.printerModelId,
            version: printer.version
          })
      )
      .sort((a, b) => a.createdAt - b.createdAt)
    const map = Map<string, Printer>().withMutations(map =>
      list.forEach(printer => map.set(printer.printerId, printer))
    )

    return this.set('printers', list).set('printersMap', map)
  }

  getPrinter(printerId: string) {
    return this.printersMap.get(printerId)
  }

  hasPrinter(printerId: string) {
    return this.printersMap.has(printerId)
  }

  getPrintersByIds(printerIds: string[]) {
    return this.printers.filter(printer =>
      printerIds.includes(printer.printerId!)
    )
  }

  push(printer: Printer) {
    const list = this.printers.push(printer)
    const map = this.printersMap.set(printer.printerId, printer)
    return this.set('printers', list).set('printersMap', map)
  }
}
