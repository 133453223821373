import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import {
  HandySettingApiRequest,
  HandySettingApiState,
  HandySettingApiDispatch
} from '~/src/api/handy/settings/types'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { actions as handySettingsAction } from '~/src/api/handy/settings/actions'
import { actions } from './actions'

const { API, Toast } = Const

export const useFetch = () => {
  const dispatch: HandySettingApiDispatch = useDispatch()
  return useCallback(() => {
    dispatch(handySettingsAction.fetchHandySetting())
  }, [dispatch])
}

export const usePostUpdateHandySetting = (
  isDiscountSurchargeOnVisitEnabled: boolean,
  state: HandySettingApiState,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()

  return useCallback(async () => {
    dispatch(setLoading())
    return apiClient
      .post(
        API.POST_HANDY_SETTING,
        convertStateToRequest(isDiscountSurchargeOnVisitEnabled, state)
      )
      .then(() => {
        dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        dispatch(actions.postSucceeded())
        dispatch(handySettingsAction.fetchHandySetting())
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [dispatch, isDiscountSurchargeOnVisitEnabled, state, apiClient])
}

const convertStateToRequest = (
  isDiscountSurchargeOnVisitEnabled: boolean,
  state: HandySettingApiState
): HandySettingApiRequest => ({
  setting: {
    ...state.response.setting,
    isDiscountSurchargeOnVisitEnabled
  }
})
