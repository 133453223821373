import { ENVIRONMENT_TYPE, ENVIRONMENT } from '~/src/const/environment'

// 本番ではlogger経由でのコンソール出力を抑制する
const dummyConsole = {
  ...console,
  log: () => {},
  info: () => {},
  warn: () => {},
  error: () => {},
  debug: () => {}
}

const logger =
  ENVIRONMENT === ENVIRONMENT_TYPE.PRODUCTION ||
  ENVIRONMENT === ENVIRONMENT_TYPE.TEST
    ? dummyConsole
    : console

export default logger
