export const ConsumptionTaxTypeAtOrder = {
  /** 標準税率(10%) */
  STANDARD: '1',

  /** 軽減税率(8%) */
  REDUCED: '2',

  /** 非課税 */
  TAX_FREE: '3',

  /** 注文時に選択 */
  SELECT: '9'
}
