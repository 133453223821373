import { Record } from 'immutable'
import { ItemByToppingGroupApiResponse } from '~/src/api/handy/items/types'
import Item from './Item'

interface ItemByToppingGroupProps {
  itemByToppingGroupId: string
  itemId: string
  storeId: string
  toppingGroupId: string
  isTaxFree: boolean
  isNotSetCategory: boolean
}

const ItemByToppingGroupRecord = Record<ItemByToppingGroupProps>({
  itemByToppingGroupId: '',
  itemId: '',
  storeId: '',
  toppingGroupId: '',
  isTaxFree: false,
  isNotSetCategory: false
})

export class ItemByToppingGroup
  extends ItemByToppingGroupRecord
  implements ItemByToppingGroupProps
{
  constructor(
    itemByToppingGroup: ItemByToppingGroupApiResponse = {
      itemByToppingGroupId: '',
      itemId: '',
      storeId: '',
      toppingGroupId: ''
    },
    item: Item,
    isNotSetCategory: boolean
  ) {
    super(itemByToppingGroup)
    return this.set('isTaxFree', item.isTaxFree()).set(
      'isNotSetCategory',
      isNotSetCategory
    )
  }
}
