import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Icon from '~/src/components/atoms/Icon'
import { AccordionList } from './AccordionList/AccordionList'
import { SelectedItemList } from './SelectedItemList/SelectedItemList'
import { selectCategories, selectSelectedItems } from '../../selectors'

export const SelectItem: React.VFC = () => {
  const categories = useSelector(selectCategories)
  const items = useSelector(selectSelectedItems)
  return (
    <ListTable>
      <ListTableItem>
        <AccordionList
          headerTitle="商品一覧"
          tableHeader="カテゴリー / 商品"
          // @ts-ignore
          categories={categories}
        />
      </ListTableItem>
      <ArrowItem>
        <Arrow />
      </ArrowItem>
      <ListTableItem>
        <SelectedItemList items={items} />
      </ListTableItem>
    </ListTable>
  )
}

const ListTable = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const ListTableItem = styled.div`
  flex-basis: 45%;
`

const ArrowItem = styled.div`
  padding-top: 30px;
  display: flex;
  flex-basis: 10%;
  height: 510px;
  align-items: center;
  justify-content: center;
`

const Arrow = styled(Icon).attrs({ name: 'arrow' })``
