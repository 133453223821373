import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Modal from '~/src/components/organisms/Modal'
import Heading from '~/src/components/atoms/Heading'
import { ErrorInfo } from '~/src/redux/modules/ApiError'

const { Color, Size } = Const
const { H2 } = Heading

type ErrorModalProps = {
  isError: boolean
  error: ErrorInfo
  onClose: () => void
  buttonText?: string
}

const ErrorModal = (props: ErrorModalProps) => {
  const { isError, error, onClose, buttonText } = props

  return (
    <Modal
      closable
      isOpen={isError}
      onOK={() => onClose()}
      okText={buttonText}
      noUseCancelButton
    >
      {error.title && <StyledHeading>{error.title}</StyledHeading>}
      <StyledContent>
        {error.message}
        <br />
        {error.code && `(エラーコード: ${error.code})`}
      </StyledContent>
    </Modal>
  )
}

const StyledHeading = styled(H2)`
  color: ${Color.VERY_DARK_GRAY_3};
  margin: 0 0 24px;
  word-wrap: break-word;
  max-width: 320px;
`

const StyledContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  margin-bottom: 8px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 20px;
  word-wrap: break-word;
  max-width: 320px;
`

export default ErrorModal
