import React, { useEffect } from 'react'
import styled from 'styled-components'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { ValidationErrorModal } from '~/src/components/organisms/Modal/ValidationErrorModal/ValidationErrorModal'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { List } from './List/List'
import { Footer } from './Footer/Footer'
import { DeleteConfirmModal } from './DeleteConfirmModal/DeleteConfirmModal'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'
import { selectIsEditing } from './selectors'
import { useSelector } from 'react-redux'
import { useHandleInitialize, useHandleDeinit } from './hooks'

const { Color, URL } = Const

export const KitchenPosition: React.VFC = () => {
  const isEditing = useSelector(selectIsEditing)
  const handleInitialize = useHandleInitialize()
  const handleDeinit = useHandleDeinit()

  useEffect(() => {
    handleInitialize()
    return handleDeinit
  }, [handleInitialize, handleDeinit])

  const breadcrumbs = [{ title: '調理場情報' }]

  const description = [
    '	キッチンモニターで注文を表示する調理場の名前を設定できます。フードとドリンクで調理場が分かれている場合などにお使いください。'
  ]
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<Footer />}
      modal={
        <div>
          <DeleteConfirmModal />
          <ValidationErrorModal>
            登録できる調理場の上限数は10件です。新しく調理場を登録する場合は、既にある調理場を削除してください。
          </ValidationErrorModal>
          <DismissPrompt enabled={isEditing} />
        </div>
      }
      displayKM
      displayKMOnly
      displayTakeOut
    >
      <KitchenPositionFAQ>
        ※調理場振り分けについて、詳しくは
        <InlineLink href={URL.KITCHEN_POSITION_FAQ} target="_blank">
          調理場管理と調理場振り分け設定（ペーパーレスプラン向け）
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </KitchenPositionFAQ>
      <List />
    </PageTemplate>
  )
}

const KitchenPositionFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
