import { Map } from 'immutable'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import { SimpleCategory } from '~/src/redux/models/Category/Category'

export const CLEAR_STATE = 'oes/TargetServingTime/CLEAR_STATE'
export const FETCH_INITIAL_DATA_SUCCEEDED =
  'oes/TargetServingTime/FETCH_INITIAL_DATA_SUCCEEDED'
export const CHANGE_CATEGORY = 'oes/TargetServingTime/CHANGE_CATEGORY'
export const CHANGE_MODE_TO_AUTO = 'oes/TargetServingTime/CHANGE_MODE_TO_AUTO'
export const CHANGE_MODE_TO_MANUAL =
  'oes/TargetServingTime/CHANGE_MODE_TO_MANUAL'
export const CHANGE_MODE_TO_NON = 'oes/TargetServingTime/CHANGE_MODE_TO_NON'
export const CHANGE_NOTICE_MINUTES =
  'oes/TargetServingTime/CHANGE_NOTICE_MINUTES'
export const CHANGE_ALERT_MINUTES = 'oes/TargetServingTime/CHANGE_ALERT_MINUTES'
export const POST_TARGET_SERVING_TIMES_SUCCEEDED =
  'oes/TargetServingTime/POST_TARGET_SERVING_TIMES_SUCCEEDED'
export const CHANGE_ALL_ITEM_NOTICE_MINUTES =
  'oes/TargetServingTime/CHANGE_ALL_ITEM_NOTICE_MINUTES'
export const CHANGE_ALL_ITEM_ALERT_MINUTES =
  'oes/TargetServingTime/CHANGE_ALL_ITEM_ALERT_MINUTES'
export const ACTIVATE_ALL_ITEM_BATCH_EDIT =
  'oes/TargetServingTime/ACTIVATE_ALL_ITEM_BATCH_EDIT'
export const DEACTIVATE_ALL_ITEM_BATCH_EDIT =
  'oes/TargetServingTime/DEACTIVATE_ALL_ITEM_BATCH_EDIT'
export const CHANGE_ALL_ITEM_MODE = 'oes/TargetServingTime/CHANGE_ALL_ITEM_MODE'
export const CHANGE_CATEGORY_NOTICE_MINUTES =
  'oes/TargetServingTime/CHANGE_CATEGORY_NOTICE_MINUTES'
export const CHANGE_CATEGORY_ALERT_MINUTES =
  'oes/TargetServingTime/CHANGE_CATEGORY_ALERT_MINUTES'
export const ACTIVATE_CATEGORY_BATCH_EDIT =
  'oes/TargetServingTime/ACTIVATE_CATEGORY_BATCH_EDIT'
export const DEACTIVATE_CATEGORY_BATCH_EDIT =
  'oes/TargetServingTime/DEACTIVATE_CATEGORY_BATCH_EDIT'
export const CHANGE_CATEGORY_MODE = 'oes/TargetServingTime/CHANGE_CATEGORY_MODE'
export const APPLY_ALL_ITEM_BATCH_EDIT =
  'oes/TargetServingTime/APPLY_ALL_ITEM_BATCH_EDIT'
export const APPLY_CATEGORIES_BATCH_EDIT =
  'oes/TargetServingTime/APPLY_CATEGORIES_BATCH_EDIT'
export const OPEN_BATCH_EDIT_FORM_MODAL =
  'oes/TargetServingTime/OPEN_BATCH_EDIT_FORM_MODAL'
export const CLOSE_BATCH_EDIT_FORM_MODAL =
  'oes/TargetServingTime/CLOSE_BATCH_EDIT_FORM_MODAL'

export type TargetServingTimeState = {
  isEdited: boolean
  selectedCategoryId?: string
  categories: SimpleCategory[]
  targetServingTimeMap: Map<string, TargetServingTime>
  estimatedTargetServingTimeMap: Map<string, EstimatedTargetServingTime>
  lastCachedAt: string
  batchEditForm: BatchEditForm | undefined
}

export type EditForm = {
  modeId: string
  noticeMinutes: string
  alertMinutes: string
  isChecked: boolean
}

export type CategoriesEditForm = { [categoryId: string]: EditForm }

export type BatchEditSelected = 'all' | 'categories' | 'noselect'

export type BatchEditForm = {
  all: EditForm
  categories: CategoriesEditForm
  which: BatchEditSelected
}
