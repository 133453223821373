import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearSeatSetState,
  fetchSeatSet
} from '~/src/redux/modules/SeatSet/SeatSet'
import { clearUiState } from '~/src/redux/modules/UI'

export const useInitialize = () => {
  const dispatch = useDispatch()
  return useCallback(
    (param: { id: string; isUpdate: boolean }) => {
      dispatch(clearSeatSetState())
      dispatch(fetchSeatSet(param))
    },
    [dispatch]
  )
}

export const useDeinit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearSeatSetState())
    dispatch(clearUiState())
  }, [dispatch])
}
