import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox as AirKitCheckbox } from '@air-kit/air-kit'
import Icon from '~/src/components/atoms/Icon'
import {
  ItemRow,
  CheckboxColumn,
  NameColumn,
  PriceColumn,
  VariationColumn,
  TrashColumn
} from '../../Common/TableParts'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

type Props = {
  item: CSVItem
  isSelected: boolean
  interactor: VariationGroupInteractor
}

const Item: React.FC<Props> = props => {
  const { item, isSelected, interactor } = props

  const onSelectCb = useCallback(() => {
    if (!isSelected) {
      interactor.selectItems([item.itemId])
    } else {
      interactor.deselectItems([item.itemId])
    }
  }, [isSelected, interactor, item.itemId])

  const onOpenModalCb = useCallback(
    (e: React.MouseEvent<MouseEvent>) => {
      e.preventDefault()
      interactor.showVariationGroupModal(item.itemId)
    },
    [interactor, item.itemId]
  )

  const onTrashClickCb = useCallback(() => {
    interactor.setDisconnectVariationGroup(item.itemId)
  }, [interactor, item.itemId])

  return (
    <ItemRow key={item.itemId}>
      <NameLabel>
        <CheckboxColumn>
          <Checkbox isChecked={isSelected} onChange={onSelectCb} />
        </CheckboxColumn>
        <NameColumn>{item.getNameForDisplay()}</NameColumn>
      </NameLabel>
      <PriceColumn>
        {item.hasVariationGroup() ? (
          <VariationAdjuster>
            バリエーション
            <br />
            登録中
          </VariationAdjuster>
        ) : item.hasSKU() ? (
          <VariationAdjuster>
            バリエーション
            <br />
            設定中
          </VariationAdjuster>
        ) : (
          item.getPriceForDisplay()
        )}
      </PriceColumn>
      <VariationColumn>
        {item.hasVariationGroup() ? (
          // @ts-ignore
          <Link onClick={onOpenModalCb}>一括登録中</Link>
        ) : item.hasSKU() ? (
          'レジで設定済み'
        ) : (
          '-'
        )}
      </VariationColumn>
      {!item.variationGroupId ? (
        <TrashColumn />
      ) : (
        <TrashColumn onClick={onTrashClickCb}>
          {!!item.variationGroupId && (
            <TrashAdjuster>
              <Icon name="trash_box" />
            </TrashAdjuster>
          )}
        </TrashColumn>
      )}
    </ItemRow>
  )
}

const Link = styled.a`
  cursor: pointer;
  font-weight: normal;
  padding-right: 6px;
`

const Checkbox = styled(AirKitCheckbox)`
  > span {
    display: none;
  }
`

const NameLabel = styled.label`
  display: table-cell;
  cursor: pointer;
`

const VariationAdjuster = styled.div`
  height: 18px;
  margin-top: -10px;
`

const TrashAdjuster = styled.div`
  height: 18px;
  margin-top: -2px;
`

export default React.memo(Item)
