import { createAction } from 'typesafe-actions'
import { clearLoading, setLoading } from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { ItemsApiDispatch, ItemsApiResponse } from './types'
import { Response } from '../../types'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'

const { API } = Const
const CLEAR_STATE = 'oes/Api/Handy/Items/CLEAR_STATE'
const FETCH_ITEMS_SUCCEEDED = 'oes/Api/Handy/Items/FETCH_ITEMS_SUCCEEDED'
const FETCH_ITEMS_FAILED = 'oes/Api/Handy/Items/FETCH_ITEMS_FAILED'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  fetchSucceeded: createAction(
    FETCH_ITEMS_SUCCEEDED,
    (itemsApiResponse: ItemsApiResponse) => ({ itemsApiResponse })
  )(),
  fetchFailed: createAction(FETCH_ITEMS_FAILED)(),
  fetchItems:
    (apiClient: ApiClientInterface = ApiClient) =>
    (dispatch: ItemsApiDispatch) => {
      dispatch(setLoading())
      return apiClient
        .get(API.GET_ITEMS)
        .then((response: Response<ItemsApiResponse>) => {
          dispatch(actions.fetchSucceeded(response.data.result))
        })
        .catch(error => {
          dispatch(handleError(error))
          dispatch(actions.fetchFailed())
        })
        .finally(() => dispatch(clearLoading()))
    }
}

export const types = {
  CLEAR_STATE,
  FETCH_ITEMS_SUCCEEDED,
  FETCH_ITEMS_FAILED
}
