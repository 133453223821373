import { ActionType, getType } from 'typesafe-actions'
import { VariationGroupState } from './types'
import actions from './actions'
import { VariationGroupEditor } from '~/src/redux/models/CSVItem/VariationGroup'

const initialState: VariationGroupState = {
  items: [],
  categories: [],
  selectedItemIds: new Set(),
  variationGroups: [],
  isDownloaded: false
}

export default (
  state: VariationGroupState = initialState,
  action: ActionType<typeof actions>
): VariationGroupState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.selectItems): {
      const newSet = new Set(state.selectedItemIds)
      action.payload.itemIds.forEach(itemId => newSet.add(itemId))
      return {
        ...state,
        selectedItemIds: newSet
      }
    }
    case getType(actions.deselectItems): {
      const newSet = new Set(state.selectedItemIds)
      action.payload.itemIds.forEach(itemId => newSet.delete(itemId))
      return {
        ...state,
        selectedItemIds: newSet
      }
    }
    case getType(actions.openVariationGroupModal): {
      return {
        ...state,
        showingItem: state.items.find(
          item => item.itemId === action.payload.itemId
        )
      }
    }
    case getType(actions.closeVariationGroupModal): {
      return {
        ...state,
        showingItem: undefined
      }
    }
    case getType(actions.openVariationGroupEditor): {
      return {
        ...state,
        editor: new VariationGroupEditor()
      }
    }
    case getType(actions.applyVariationGroupEditor): {
      const newVariationGroup = state.editor!.toVariationGroup()
      const newItems = state.items.map(item =>
        state.selectedItemIds.has(item.itemId)
          ? item.setVariationGroupId(newVariationGroup.variationGroupId)
          : item
      )
      const newVariationGroups = state.variationGroups
        .concat([newVariationGroup])
        .filter(vg =>
          newItems.some(item => item.variationGroupId === vg.variationGroupId)
        )
      return {
        ...state,
        items: newItems,
        variationGroups: newVariationGroups,
        editor: undefined,
        selectedItemIds: new Set(),
        isDownloaded: false
      }
    }
    case getType(actions.cancelVariationGroupEditor): {
      return {
        ...state,
        editor: undefined
      }
    }
    case getType(actions.addNewVariationOnEditor): {
      return {
        ...state,
        editor: state.editor?.pushNewVariation()
      }
    }
    case getType(actions.updateVariationOnEditor): {
      return {
        ...state,
        editor: state.editor?.updateVariation(action.payload.variation)
      }
    }
    case getType(actions.sortVariationOnEditor): {
      return {
        ...state,
        editor: state.editor?.sortVariation(
          action.payload.from,
          action.payload.to
        )
      }
    }
    case getType(actions.deleteVariationOnEditor): {
      return {
        ...state,
        editor: state.editor?.deleteVariation(action.payload.variationId)
      }
    }
    case getType(actions.setDisconnectVariationGroup): {
      return {
        ...state,
        disconnectTargetItemId: action.payload.itemId
      }
    }
    case getType(actions.clearDisconnectVariationGroup): {
      return {
        ...state,
        disconnectTargetItemId: undefined
      }
    }
    case getType(actions.disconnectVariationGroup): {
      const newItems = state.items.map(item =>
        state.disconnectTargetItemId === item.itemId
          ? item.disconnectVariationGroup()
          : item
      )
      const newVariationGroups = state.variationGroups.filter(vg =>
        newItems.some(item => item.variationGroupId === vg.variationGroupId)
      )
      return {
        ...state,
        items: newItems,
        variationGroups: newVariationGroups,
        disconnectTargetItemId: undefined,
        isDownloaded: false
      }
    }
    case getType(actions.updateVariationRetouch): {
      return {
        ...state,
        items: state.items.map(item =>
          action.payload.itemId === item.itemId
            ? item.updateRetouch(action.payload.retouch)
            : item
        ),
        isDownloaded: false
      }
    }
    case getType(actions.fileLoadSucceeded): {
      return {
        ...state,
        items: action.payload.items,
        file: action.payload.file,
        filename:
          action.payload.file.name.replace(/\.[^/.]+$/, '') + '_handy.csv',
        header: action.payload.header,
        fileErrorMessage: undefined
      }
    }
    case getType(actions.fileLoadFailed): {
      return {
        ...state,
        items: [],
        file: undefined,
        filename: undefined,
        header: undefined,
        fileErrorMessage: action.payload.fileErrorMessage
      }
    }
    case getType(actions.fetchCategoriesSucceeded): {
      return {
        ...state,
        categories: action.payload.categories
      }
    }
    case getType(actions.fileDownloadSucceeded): {
      return {
        ...state,
        isDownloaded: true
      }
    }
    default:
      return state
  }
}
