import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'

const { Page } = Const

export const selectDeviceCountModify = (state: AppState) => {
  const dismissPromptWhiteList = state.Forward.shouldForward
    ? []
    : [Page.DEVICE_COUNT.CONFIRM]

  return {
    isFreePlan: state.auth.store?.isFreePlan,
    selectedDeviceCount: state.deviceCount.selectedDeviceCount,
    shouldForward: state.Forward.shouldForward,
    isModified: state.deviceCount.isModified,
    dismissPromptWhiteList,
    showError: state.deviceCount.showError,
    registeredDevicesCount: state.deviceCount.registeredDevicesCount,
    planPriceType: state.auth.store?.planPriceType
  }
}
