import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { clearError } from '~/src/redux/modules/ApiError'

export const useClearError = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearError())
  }, [dispatch])
}
