import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Row from './Row'
import { CheckboxColumn, TargetTableColumn, FloorNameColumn } from './Columns'
import TableRow from '~/src/components/atoms/TableRow'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { selectSeats } from './selectors'

import Const from '~/src/const/'
const { Color, Size, URL } = Const

export const SeatSetDetailList = () => {
  const seats = useSelector(selectSeats)

  return (
    <div>
      <EditableParagraph>
        テーブルを選択してください。１つ以上のテーブルを選択する必要があります。
        <br />
        ※テーブル情報は
        <InlineLink href={URL.RB_TABLE} target="_blank">
          レストランボードのテーブル情報ページ
        </InlineLink>
        で設定できます。
      </EditableParagraph>
      <section>
        <EditableHeader>
          <CheckboxColumn />
          <TargetTableColumn>対象テーブル</TargetTableColumn>
          <FloorNameColumn>注文エリア</FloorNameColumn>
        </EditableHeader>
        <main>
          {seats.map((seat, index) => (
            <Row seat={seat} key={`seat-${index}`} />
          ))}
        </main>
      </section>
    </div>
  )
}

const EditableParagraph = styled.p`
  padding: 8px 4px;
  line-height: 1.8;
  margin: 0;
  font-size: ${Size.FONT.BASE}px;
`

const EditableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  font-weight: bold;
`
