import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openLogoutModal } from '~/src/redux/modules/UI'

export const useHandleOpenLogoutModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(openLogoutModal())
  }, [dispatch])
}
