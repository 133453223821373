import React from 'react'
import styled from 'styled-components'
import { Checkbox } from '@air-kit/air-kit'
import TableRow from '~/src/components/atoms/TableRow'
import { CheckboxColumn, CategoryColumn, PrintersColumn } from './Column'
import { Row } from './Row/Row'
import Const from '~/src/const/'
import { useSelector } from 'react-redux'
import { selectCategoryList } from './selectors'
import { useCheckAllCategory } from './hooks'
const { Color } = Const

export const CategoryList: React.VFC = () => {
  const { categories, isSomeSelected, isAllSelected } =
    useSelector(selectCategoryList)
  const handleCheckAllCategory = useCheckAllCategory(!isAllSelected)

  return (
    <div>
      <TableHeader>
        <CheckboxColumn>
          <StyledCheckbox
            name="checkbox-category-all"
            isSomeSelected={isSomeSelected}
            isChecked={isAllSelected}
            onChange={handleCheckAllCategory}
          />
        </CheckboxColumn>
        <CategoryColumn>カテゴリー</CategoryColumn>
        <PrintersColumn>現在設定中のプリンター</PrintersColumn>
      </TableHeader>
      {categories.map(category => (
        <Row key={category.categoryId} category={category} />
      ))}
    </div>
  )
}

const TableHeader = styled(TableRow)`
  height: 46px;
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  font-weight: bold;
`

const StyledCheckbox = styled(Checkbox)`
  > span {
    display: none;
  }
`
