import styled from 'styled-components'
import _TableRow from '~/src/components/atoms/TableRow'
import _TableColumn from '~/src/components/atoms/TableColumn'

// 特定の列のみのリンクを効かせるために、行の上下paddingを列で設定させている。
export const TableRow = styled(_TableRow)`
  padding: 0;
`

const BaseTableColumn = styled(_TableColumn)`
  padding-top: 16px;
  padding-bottom: 16px;
`

export const HandleColumn = styled(BaseTableColumn)`
  width: 22px;
`

export const SeatSetNameColumn = styled(BaseTableColumn)`
  width: 100%;
  text-align: left;
`

export const ArrowColumn = styled(BaseTableColumn)`
  width: 40px;
`
