import React from 'react'
import styled from 'styled-components'
import {
  HodaiNameColumn,
  CategoryColumn,
  LastOrderColumn,
  PriceColumn,
  ItemCountColumn,
  TrashColumn,
  SpaceColumn
} from '../Column'
import { LinkTableRow } from '~/src/components/atoms/LinkTableRow'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const/'
import { useHandleOpenDeleteModal } from '../../interactors'
import { HodaiItem } from '../../types'
const { Page, TaxType } = Const

type Props = {
  hodai: HodaiItem
}
export const Row: React.VFC<Props> = (props: Props) => {
  const hodai = props.hodai
  const handleOpenDeleteModal = useHandleOpenDeleteModal()
  const { isVariation, isCategoryDeleted, isItemDeleted, isEmptyHodai } = hodai

  return (
    <LinkTableRow to={Page.HODAI.DETAIL(hodai.hodaiId)}>
      <HodaiNameColumn>
        {(isItemDeleted || isVariation || isCategoryDeleted) && (
          <ExclamationIcon color="red" />
        )}
        {hodai.itemDisplayName}
      </HodaiNameColumn>
      <CategoryColumn>
        {!isItemDeleted && isCategoryDeleted && <ExclamationIcon color="red" />}
        {hodai.categoryDisplayName}
      </CategoryColumn>
      <LastOrderColumn>{hodai.lastOrderMinutes}</LastOrderColumn>
      <PriceColumn>
        {!isItemDeleted && !isVariation
          ? // @ts-ignore
            `¥${hodai.price.toLocaleString()}\n(${TaxType[hodai.taxType]})`
          : '-'}
      </PriceColumn>
      <ItemCountColumn>
        {isEmptyHodai ? (
          <>
            <ExclamationIcon color="orange" /> 未設定
          </>
        ) : (
          hodai.detailCount
        )}
      </ItemCountColumn>
      <TrashColumn
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          e.preventDefault()
          handleOpenDeleteModal({
            hodaiId: hodai.hodaiId,
            name: hodai.itemName
          })
        }}
      >
        <TrashBoxIcon />
      </TrashColumn>
      <SpaceColumn>
        <ArrowIcon />
      </SpaceColumn>
    </LinkTableRow>
  )
}

const TrashBoxIcon = styled(Icon).attrs({ name: 'trash_box' })``
const ArrowIcon = styled(Icon).attrs({ name: 'arrowRight' })``
