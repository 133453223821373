import { AppState } from '~/src/redux/reducer'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import {
  createItemIdSet,
  generateItemNameWithEmptyCategoryAndSku
} from '~/src/api/handy/items/utils'
import { Item } from '~/src/components/organisms/Modal/RegisterItemModal/RegisterItemModal'
import { Item as RowItem } from './HodaiSelectModal/List/Row/Row'
import { checkHodai } from '~/src/util/ErrorChecker'
import { createSelector } from 'reselect'

export const selectIsEditing = (state: AppState) =>
  state.courseSetting.isEditing

export const selectCourseSettingState = (state: AppState) => state.courseSetting

export const selectFetchedItem = (state: AppState) => state.api.items.fetched

export const selectIsSubmitButtonDisabled =
  (courseId?: string) => (state: AppState) => {
    if (!courseId) {
      return !state.courseSetting.selectedCourse?.itemId
    }

    return !state.courseSetting.isEditing
  }

export const selectIsSelectingCourse = (state: AppState) => {
  return state.courseSetting.isSelectingCourse
}

export const selectInitialSelectedItems = createSelector(
  (state: AppState) => state.api.items.response,
  (_: AppState, courseId: string | undefined) => courseId,
  ({ courses, items, itemCategoryJunctions }, courseId) => {
    const course = courses?.find(course => course.courseId === courseId)

    if (!course) {
      return []
    }

    const courseDetails =
      course.courseDetails?.filter(
        courseDetail => courseDetail.courseId === courseId
      ) || []

    const itemMap = items?.reduce((previous: any, current) => {
      previous[current.itemId] = current
      return previous
    }, {})

    const itemCategoryJunctionMap = itemCategoryJunctions || []

    return courseDetails
      .filter(detail => !detail.hodaiId)
      .map(courseDetail => {
        const item = itemMap[courseDetail.itemId]
        return {
          itemId: courseDetail.itemId,
          itemName: item?.itemName,
          isDeleted: !item,
          isSKU: !!item?.skus?.length,
          isDisplayed: item?.isDisplayed || false,
          isCombinedCategory: itemCategoryJunctionMap.some(
            e => e.itemId === courseDetail.itemId
          )
        }
      })
  }
)

export const selectInitialSelectedHodai = createSelector(
  (state: AppState) => state.api.items.response,
  (_: AppState, courseId: string | undefined) => courseId,
  (itemsResponse, courseId) => {
    const courseSelectedHodai = itemsResponse.courses
      ?.find(course => course.courseId === courseId)
      ?.courseDetails?.find(detail => !!detail.hodaiId)
    if (courseSelectedHodai) {
      return {
        itemId: courseSelectedHodai?.itemId,
        hodaiId: courseSelectedHodai?.hodaiId!,
        itemName: generateItemNameWithEmptyCategoryAndSku(
          courseSelectedHodai?.itemId,
          itemsResponse
        )
      }
    }
  }
)

export const selectSelectedCourseName =
  (courseId?: string) => (state: AppState) => {
    if (!courseId) {
      return state.courseSetting.selectedCourse?.name
    }

    const course = state.api.items.response.courses?.find(
      course => course.courseId === courseId
    )

    return generateItemNameWithEmptyCategoryAndSku(
      course?.itemId,
      state.api.items.response
    )
  }

type ItemIdMap = {
  [_: string]: Item
}

type CategoryIdMap = {
  [_: string]: Item[]
}

// state -> modalに渡す形に整形する
export const selectModalCategories = (state: AppState) => {
  const response: ItemsApiResponse = state.api.items.response

  if (
    !response.items ||
    !response.itemCategoryJunctions ||
    !response.categories
  ) {
    // 商品が存在しないまたは商品カテゴリー紐付けが存在しないまたはカテゴリーが存在しない場合は空配列を返却する
    return []
  }

  // ItemApiResponse型からItem型へ変換し、
  // itemMap[itemId]でアクセスできるようにitemをdictionaryにする
  const selectedItemIdSet = new Set(
    state.courseSetting.selectedItemList.map(el => el.itemId)
  )
  const itemMap = createItemMap(response, selectedItemIdSet)

  // categoryに紐づくItem配列を categoryIdMap[categoryId]で参照できるようにする
  const categoryIdMap: CategoryIdMap = {}
  response.itemCategoryJunctions!.forEach(e => {
    if (!categoryIdMap[e.categoryId]) {
      categoryIdMap[e.categoryId] = [itemMap[e.itemId]]
      return
    }

    categoryIdMap[e.categoryId] = [
      ...categoryIdMap[e.categoryId],
      itemMap[e.itemId]
    ]
  })

  return response.categories!.map(category => ({
    id: category.categoryId,
    name: category.categoryName,
    items: categoryIdMap[category.categoryId]
  }))
}

export const selectIsSelectingHodai = (state: AppState) => {
  return state.courseSetting.isSelectingHodai
}

type HodaiInfo = {
  hodaiId: string
  hodaiNum: number
}

export const selectModalHodais = (state: AppState) => {
  const response: ItemsApiResponse = state.api.items.response

  if (!response.hodais || response.hodais!.length === 0) {
    return []
  }
  const {
    itemIdsHaveDeletedHodaiPlanCategoryError,
    itemIdsHaveVariationHodaiPlanError
  } = checkHodai(state.api.items.response)

  // ここには削除済みの放題がある
  const hodaiDetailNumMap = response.hodais!.reduce((prev, hodai) => {
    const hodaiDetailNum: number = hodai.hodaiDetails
      ? hodai.hodaiDetails!.length
      : 0
    prev.set(hodai.itemId, {
      hodaiId: hodai.hodaiId,
      hodaiNum: hodaiDetailNum
    })
    return prev
  }, new Map<string, HodaiInfo>())

  // 削除済みじゃない放題
  const hodaiList: RowItem[] = response
    .items!.filter(item => hodaiDetailNumMap.get(item.itemId))
    .map(item => {
      const isVariation = !!itemIdsHaveVariationHodaiPlanError.includes(
        item.itemId
      )
      const hasNoCategory = !!itemIdsHaveDeletedHodaiPlanCategoryError.includes(
        item.itemId
      )
      const displayName = (() => {
        let extraText = ''
        if (isVariation) {
          extraText += '(バリエーションあり)'
        }
        if (hasNoCategory) {
          extraText += '(カテゴリー未設定)'
        }
        if (extraText === '') {
          return item.itemName
        } else {
          return `[${item.itemName}${extraText}]`
        }
      })()
      return {
        id: item.itemId,
        name: displayName,
        hodaiId: hodaiDetailNumMap.get(item.itemId)!.hodaiId,
        hodaiNum: hodaiDetailNumMap.get(item.itemId)!.hodaiNum,
        isDisplayed: item.isDisplayed,
        isDeleted: false,
        isDisabled: isVariation || hasNoCategory
      }
    })

  const hodaiItemIdSet = new Set(hodaiList.map(hodai => hodai.id))

  // 削除済みな放題
  const deletedHodaiList: RowItem[] = Array.from(hodaiDetailNumMap)
    .filter(entry => !hodaiItemIdSet.has(entry[0]))
    .map(entry => ({
      id: entry[0],
      name: '',
      hodaiId: entry[1].hodaiId,
      hodaiNum: entry[1].hodaiNum,
      isDisplayed: true, // どっちでもいいのでtrue
      isDeleted: true,
      isDisabled: true
    }))

  return hodaiList.concat(deletedHodaiList)
}

export const selectSelectedHodai = (state: AppState) => {
  return state.courseSetting.selectedHodai
}

export const selectItemIdByCourseId =
  (courseId: string) => (state: AppState) => {
    return state.api.items.response.courses?.find(
      course => course.courseId === courseId
    )?.itemId
  }

const createItemMap = (
  response: ItemsApiResponse,
  selectedItemIdSet: Set<string>
) => {
  const itemIdSet = createItemIdSet(response)
  return response.items!.reduce((previous: ItemIdMap, current) => {
    // 商品がSKU、当画面で放題内容に設定されている
    // またはコース親・子、放題親・子、トッピンググループ、トッピング対象の商品は無効化(disabled)する
    const disabled =
      !!current.skus?.length ||
      selectedItemIdSet.has(current.itemId) ||
      itemIdSet.courses.has(current.itemId) ||
      itemIdSet.courseDetails.has(current.itemId) ||
      itemIdSet.hodais.has(current.itemId) ||
      itemIdSet.hodaiDetails.has(current.itemId) ||
      itemIdSet.itemByToppingGroups.has(current.itemId) ||
      itemIdSet.toppingGroups.has(current.itemId) ||
      itemIdSet.otoshis.has(current.itemId)
    previous[current.itemId] = {
      id: current.itemId,
      name: current.itemName,
      price: current.price || 0,
      taxType: current.taxType,
      isDisplayed: current.isDisplayed,
      disabled
    }
    return previous
  }, {})
}
