import React, { useCallback } from 'react'
import { useParams, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import { MAX_NUM_TOPPINGS } from '~/src/redux/models/Topping/ToppingGroup'
import Footer from '~/src/components/organisms/Footer'

const { Page } = Const

const selectCanSubmit = createSelector(
  (state: AppState) => state.toppingGroup.isEdited,
  (state: AppState) => state.toppingGroup.toppingGroupName.isError,
  (state: AppState) => state.toppingGroup.selectedToppingItemIds,
  (state: AppState) => state.toppingGroup.selectedToppingErrors,
  (
    isEdited,
    isToppingGroupNameError,
    selectedToppingItemIds,
    selectedToppingErrors
  ) => {
    return (
      isEdited &&
      !isToppingGroupNameError &&
      selectedToppingItemIds.size <= MAX_NUM_TOPPINGS &&
      selectedToppingErrors.isEmpty()
    )
  }
)

const selectPostFormParams = createSelector(
  (state: AppState) => state.toppingGroup.toppingGroupVersion,
  (state: AppState) => state.toppingGroup.toppingGroupName.value,
  (state: AppState) => state.toppingGroup.selectedToppingItemIds,
  (version, toppingGroupName, selectedToppingItemIds) => ({
    version,
    toppingGroupName,
    itemIds: selectedToppingItemIds
  })
)

const FooterContainer: React.VFC = () => {
  const { toppingGroupId } = useParams<{ toppingGroupId?: string }>() // 新規作成なら undefined
  const canSubmit = useSelector(selectCanSubmit)
  const postFormParams = useSelector(selectPostFormParams)

  const interactor = useInteractors().toppingGroupInteractor
  const history = useHistory()
  const handleClickSubmitButton = useCallback(async () => {
    const params = {
      ...postFormParams,
      toppingGroupId
    }
    await interactor.saveToppingGroup(params)
    history.push(Page.TOPPING.LIST)
  }, [toppingGroupId, interactor, history, postFormParams])

  const props: InnerProps = {
    canSubmit,
    onClickSubmitButton: handleClickSubmitButton
  }
  return <ConfiguredFooter {...props} />
}
export { FooterContainer as Footer }

type InnerProps = {
  canSubmit: boolean
  onClickSubmitButton: () => void
}
const ConfiguredFooter: React.VFC<InnerProps> = React.memo(props => {
  const { canSubmit, onClickSubmitButton } = props

  return (
    <Footer
      noUseOptionButton
      disableSubmitButton={!canSubmit}
      onSubmitButtonClick={onClickSubmitButton}
    />
  )
})
