import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'

import {
  SelectColumn,
  ItemNameColumn,
  CategoryNameColumn,
  PriceColumn
} from './Column'

import Const from '~/src/const/'

const { Color } = Const

export default () => {
  return (
    <HeaderRow>
      <SelectColumn />
      <ItemNameColumn>商品名</ItemNameColumn>
      <CategoryNameColumn>カテゴリー</CategoryNameColumn>
      <PriceColumn>価格</PriceColumn>
    </HeaderRow>
  )
}

const HeaderRow = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
