import { AirCmnHeader } from 'air-common-header'
import Const from '~/src/const'

// TODO: サイカタ用関数を用意する
const scCustomLink = () => {}
const { URL } = Const

/**
 * Air 共通ヘッダーを適切なパラメータで初期化する
 */
export default (store = {}, token = {}, openLogoutModal) => {
  const redirectUri = document.URL

  const commonHeaderObj = {
    productName: 'Airレジ オーダー',
    accountName: token.account || '',
    storeName: store.storeName || '',
    serviceMenuOpen: {
      callBackEvent: () =>
        scCustomLink('external', '', { prop5: 'hdrtop_servicebtn' })
    },
    logoutMenu: {
      callBackEvent: () => {
        scCustomLink('external', '', { prop5: 'hdr_logout' })
        openLogoutModal()
      }
    },
    myAccountMenu: {
      link: URL.PLF_MY_ACCOUNT(redirectUri),
      callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_myacnt' })
    },
    airMarketMenu: {
      link: URL.AIR_MARKET_URL,
      callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_mkt' })
    }
  }

  // アカウントメニュー
  const accountMenuMaps = [
    {
      code: '0001',
      link: URL.PLF_STORE_INFORMATION(redirectUri),
      callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_info' })
    },
    {
      code: '0002',
      link: URL.PLF_SERVICE_LIST,
      callBackEvent: () =>
        scCustomLink('external', '', { prop5: 'hdr_service' })
    },
    {
      code: '0003',
      link: URL.PLF_STAFF(redirectUri),
      callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_stf' })
    }
  ]

  if (token.isManager) {
    accountMenuMaps.push({
      code: '0004',
      link: URL.PLF_CONTRACT_INFO(redirectUri),
      callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_crg' })
    })
    accountMenuMaps.push(
      {
        code: '1000',
        link: URL.PLF_STORE_LIST(redirectUri),
        callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_shps' })
      },
      {
        code: '1001',
        link: URL.PLF_ACCOUNTS(redirectUri),
        callBackEvent: () => scCustomLink('external', '', { prop5: 'hdr_user' })
      }
    )
  }

  // サービスメニュー
  const serviceMenuMaps = (store.serviceUseStateList || []).map(service => ({
    name: service.serviceName,
    link: service.serviceUrl,
    imgPath: service.imageUrl,
    callBackEvent: () =>
      scCustomLink('external', '', {
        prop5: `head_service_${service.serviceCd}`
      })
  }))

  commonHeaderObj.accountMenuMaps = accountMenuMaps
  commonHeaderObj.serviceMenuMaps = serviceMenuMaps
  new AirCmnHeader(commonHeaderObj).render()
}
