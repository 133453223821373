import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { LogoutModal } from '~/src/components/organisms/Modal/LogoutModal/LogoutModal'
import { ApiErrorModal } from '~/src/components/organisms/Modal/ErrorModal/ApiErrorModal/ApiErrorModal'

const MODAL_PORTAL_ROOT = 'modal-portal-root'

export const ModalPortal: React.FC = props => {
  const [root, setRoot] = useState<HTMLElement>()
  useEffect(() => {
    setRoot(document.getElementById(MODAL_PORTAL_ROOT)!)
  }, [])
  return root ? createPortal(props.children, root) : null
}

/**
 * モーダルの転送表示先をまとめたコンテナ
 */
const ModalContainer: React.FC = () => {
  return (
    <div id={MODAL_PORTAL_ROOT}>
      <LogoutModal />
      {/** @ts-ignore */}
      <ApiErrorModal />
    </div>
  )
}

export default ModalContainer
