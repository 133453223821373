import { createAction } from 'typesafe-actions'

const CLEAR_STATE = 'oes/Hodai/HodaiSetting/CLEAR_STATE'
const INITIALIZE = 'oes/Hodai/HodaiSetting/INITIALIZE'
const OPEN_HODAI_SELECT = 'oes/Hodai/HodaiSetting/OPEN_HODAI_SELECT'
const CLOSE_HODAI_SELECT = 'oes/Hodai/HodaiSetting/CLOSE_HODAI_SELECT'
const SUBMIT_HODAI_SELECT = 'oes/Hodai/HodaiSetting/SUBMIT_HODAI_SELECT'
const SELECT_CATEGORY = 'oes/Course/HodaiSetting/SELECT_CATEGORY'
const SELECT_CATEGORY_ITEM = 'oes/Course/HodaiSetting/SELECT_CATEGORY_ITEM'
const RELEASE_CATEGORY_ITEM = 'oes/Course/HodaiSetting/RELEASE_CATEGORY_ITEM'
const RELEASE_CATEGORY = 'oes/Course/HodaiSetting/RELEASE_CATEGORY'
const RELEASE_ALL = 'oes/Course/HodaiSetting/RELEASE_ALL'
const UPDATE_LAST_ORDER_MINUTES =
  'oes/Hodai/HodaiSetting/UPDATE_LAST_ORDER_MINUTES'
const UPDATE_LAST_ORDER_SET_MODE =
  'oes/Hodai/HodaiSetting/UPDATE_LAST_ORDER_SET_MODE'
const UPDATE_LAST_ORDER_IS_COMPOSING =
  'oes/Hodai/HodaiSetting/UPDATE_LAST_ORDER_IS_COMPOSING'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(INITIALIZE, ({ categories, lastOrderMinutes }) => ({
    categories,
    lastOrderMinutes
  }))(),
  openHodaiSelect: createAction(OPEN_HODAI_SELECT)(),
  closeHodaiSelect: createAction(CLOSE_HODAI_SELECT)(),
  submitHodaiSelect: createAction(SUBMIT_HODAI_SELECT, ({ itemId, name }) => ({
    item: {
      itemId,
      name
    }
  }))(),
  selectCategory: createAction(SELECT_CATEGORY, ({ categoryId }) => ({
    categoryId
  }))(),
  selectCategoryItem: createAction(
    SELECT_CATEGORY_ITEM,
    ({ categoryId, itemId }) => ({
      categoryId,
      itemId
    })
  )(),
  releaseCategoryItem: createAction(
    RELEASE_CATEGORY_ITEM,
    ({ categoryId, itemId }) => ({
      categoryId,
      itemId
    })
  )(),
  releaseCategory: createAction(RELEASE_CATEGORY, ({ categoryId }) => ({
    categoryId
  }))(),
  releaseAll: createAction(RELEASE_ALL)(),
  updateLastOrderMinutes: createAction(
    UPDATE_LAST_ORDER_MINUTES,
    (lastOrderMinutes: string) => ({ lastOrderMinutes })
  )(),
  updateLastOrderSetMode: createAction(
    UPDATE_LAST_ORDER_SET_MODE,
    (lastOrderSetMode: 'manual' | 'auto') => ({ lastOrderSetMode })
  )(),
  updateLastOrderIsComposing: createAction(
    UPDATE_LAST_ORDER_IS_COMPOSING,
    (lastOrderIsComposing: boolean) => ({ lastOrderIsComposing })
  )()
}

export const types = {
  CLEAR_STATE,
  INITIALIZE,
  OPEN_HODAI_SELECT,
  CLOSE_HODAI_SELECT,
  SUBMIT_HODAI_SELECT,
  SELECT_CATEGORY,
  SELECT_CATEGORY_ITEM,
  RELEASE_CATEGORY_ITEM,
  RELEASE_CATEGORY,
  RELEASE_ALL,
  UPDATE_LAST_ORDER_MINUTES,
  UPDATE_LAST_ORDER_SET_MODE,
  UPDATE_LAST_ORDER_IS_COMPOSING
}
