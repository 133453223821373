import {
  ItemApiResponse,
  ItemCategoryJunctionApiResponse
} from '~/src/api/handy/items/types'
import { ConsumptionTaxTypeAtOrder } from '~/src/const/consumptionTaxTypeAtOrder'
import itemType from '~/src/const/item-type'
import { AppState } from '~/src/redux/reducer'
import { SelectedItem } from './types'
import { createItemIdSet } from '~/src/api/handy/items/utils'
import { checkOtoshi } from '~/src/util/ErrorChecker'
import { ITEM_COUNT_LIMIT } from './const'
import { createSelector } from 'reselect'

type Category = {
  categoryName: string
  categoryId: string
  isDisplayed: boolean
  items: Item[]
}

type Item = {
  itemName: string
  displayItemName?: string
  itemId: string
  isDeleted: boolean
  disabled: boolean
  hasError: boolean
}

type ItemIdMap = {
  [_: string]: ItemApiResponse
}

type ItemIdMapForIcj = {
  [_: string]: ItemCategoryJunctionApiResponse
}

export const selectCategories = (state: AppState): Category[] => {
  const categories = state.api.items.response.categories
  const itemCategoryJunctions = state.api.items.response.itemCategoryJunctions
  const itemMap: ItemIdMap = state.api.items.response.items?.reduce(
    (previous: any, current) => {
      previous[current.itemId] = current
      return previous
    },
    {}
  )
  const selectedItemIdSet = new Set(
    state.otoshiSetting.selectedItems.map(item => item.itemId)
  )
  const itemIdSetForDisable = createItemIdSet(state.api.items.response)
  return (
    categories?.map(category => {
      return {
        categoryName: category.categoryName,
        categoryId: category.categoryId,
        isDisplayed: category.isDisplayed,
        items:
          itemCategoryJunctions
            ?.filter(
              icj =>
                icj.categoryId === category.categoryId &&
                itemMap[icj.itemId] &&
                !selectedItemIdSet.has(icj.itemId)
            )
            .map(icj => {
              const item = itemMap[icj.itemId]
              const hasVariation = item.itemType === itemType.SKU
              const isTaxFree =
                item.consumptionTaxTypeAtOrder ===
                ConsumptionTaxTypeAtOrder.TAX_FREE
              const hasTopping = itemIdSetForDisable.itemByToppingGroups.has(
                item.itemId
              )
              const disabled =
                hasVariation ||
                isTaxFree ||
                itemIdSetForDisable.courses.has(item.itemId) ||
                itemIdSetForDisable.hodais.has(item.itemId) ||
                hasTopping
              return {
                itemName: item.itemName,
                displayItemName: createDisplayItemNameForItems(
                  item.itemName,
                  item.isDisplayed,
                  item.itemType === itemType.SKU,
                  item.consumptionTaxTypeAtOrder ===
                    ConsumptionTaxTypeAtOrder.TAX_FREE,
                  hasTopping
                ),
                itemId: item.itemId,
                isDeleted: false,
                disabled: disabled,
                hasError: hasVariation || isTaxFree
              }
            }) || []
      }
    }) || []
  )
}

export const selectSelectedItems = (state: AppState): Item[] => {
  return state.otoshiSetting.selectedItems
}

export const selectIsOtoshiEnabled = (state: AppState): boolean => {
  return state.otoshiSetting.isOtoshiEnabled
}

export const selectIsOtoshiEnabledFromApi = createSelector(
  (state: AppState) => state.api.handySetting.response,
  ({ setting }) => setting.isOtoshiEnabled
)

export const selectSelectedItemsFromApi = createSelector(
  (state: AppState) => state.api.items.response,
  ({ items, itemCategoryJunctions, otoshis }): SelectedItem[] => {
    const itemMap: ItemIdMap = items?.reduce((previous: any, current) => {
      previous[current.itemId] = current
      return previous
    }, {})
    const icjMap: ItemIdMapForIcj = itemCategoryJunctions?.reduce(
      (previous: any, current) => {
        previous[current.itemId] = current
        return previous
      },
      {}
    )
    return (
      otoshis?.map(otoshi => {
        const item = itemMap[otoshi.itemId]
        if (!item) {
          return {
            // 削除済みの場合商品名,商品IDは利用しない、undefinedを入れたくないので文字列を入れておく
            itemName: '削除済み',
            displayItemName: '[削除済み]',
            itemId: otoshi.itemId,
            isDeleted: true,
            disabled: false,
            hasError: true
          }
        }
        const hasVariation = item.itemType === itemType.SKU
        const isEmptyCategory = !icjMap[item.itemId]
        const isTaxFree =
          item.consumptionTaxTypeAtOrder === ConsumptionTaxTypeAtOrder.TAX_FREE
        return {
          itemName: item.itemName,
          displayItemName: createDisplayItemNameForOtoshis(
            item.itemName,
            item.isDisplayed,
            isEmptyCategory,
            hasVariation,
            isTaxFree
          ),
          itemId: item.itemId,
          isDeleted: false,
          disabled: false,
          hasError: hasVariation || isEmptyCategory || isTaxFree
        }
      }) || []
    )
  }
)

export const selectIsEditing = (state: AppState) => {
  return state.otoshiSetting.isEditing
}

const createDisplayItemNameForOtoshis = (
  itemName: string,
  isDisplayed: boolean,
  isEmptyCategory: boolean,
  hasVariation: boolean,
  isTaxFree: boolean
) => {
  let extraString = ''
  if (isDisplayed && !isEmptyCategory && !hasVariation && !isTaxFree) {
    return itemName
  }
  if (hasVariation) {
    extraString += '(バリエーションあり)'
  }
  if (isEmptyCategory) {
    extraString += '(カテゴリー未設定)'
  }
  if (isTaxFree) {
    extraString += '(税設定:非課税)'
  }
  if (extraString !== '') {
    return `[${itemName}${extraString}]`
  }
  // 非表示かつカテゴリー未設定等のエラーがある場合はエラー表示が優先される
  if (!isDisplayed) {
    return `${itemName}(非表示)`
  }
}

const createDisplayItemNameForItems = (
  itemName: string,
  isDisplayed: boolean,
  hasVariation: boolean,
  isTaxFree: boolean,
  hasTopping: boolean
) => {
  let extraString = ''
  if (isDisplayed && !hasVariation && !isTaxFree && !hasTopping) {
    return itemName
  }
  if (hasVariation) {
    extraString += '(バリエーションあり)'
  }
  if (isTaxFree) {
    extraString += '(税設定:非課税)'
  }
  if (hasTopping) {
    extraString += '(トッピングあり)'
  }
  if (extraString !== '') {
    return `${itemName}${extraString}`
  }
  if (!isDisplayed) {
    // 非表示かつエラーがある場合はエラー表示が優先される
    return `${itemName}(非表示)`
  }
}

export const selectErrors = (state: AppState) => {
  const checkResult = checkOtoshi(state.api.items.response)
  const selectedItemIdSet = new Set(
    selectSelectedItems(state).map(item => item.itemId)
  )
  return {
    hasErrorExceededLimit: selectedItemIdSet.size > ITEM_COUNT_LIMIT,
    hasDeletedItem: checkResult.itemIdsHaveDeleted.some(itemId =>
      selectedItemIdSet.has(itemId)
    ),
    hasVariation: checkResult.itemIdsHaveVariationItem.some(itemId =>
      selectedItemIdSet.has(itemId)
    ),
    hasEmptyCategory: checkResult.itemIdsHaveEmptyCategory.some(itemId =>
      selectedItemIdSet.has(itemId)
    ),
    hasTaxFree: checkResult.itemIdsHaveTaxFree.some(itemId =>
      selectedItemIdSet.has(itemId)
    )
  }
}
