import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Modal from '~/src/components/organisms/Modal'
import Heading from '~/src/components/atoms/Heading'

const { Color, Size } = Const
const { H2 } = Heading

export default ({ isOpen, onClose, onOK }) => (
  <Modal
    closable
    isOpen={isOpen}
    onClose={onClose} // closeDismissModalWithTransitions
    onOK={onOK} // closeDismissModal
    cancelText="破棄する"
    okText="編集を続ける"
  >
    <StyledHeading>編集内容が保存されていません</StyledHeading>
    <StyledContent>
      このまま移動すると入力内容は破棄されます。
      <br />
      編集を続けますか？
    </StyledContent>
  </Modal>
)

const StyledHeading = styled(H2)`
  color: ${Color.VERY_DARK_GRAY_3};
  margin: 0 0 24px;
`

const StyledContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  margin-bottom: 8px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 20px;
`
