import { AppState } from '~/src/redux/reducer'
import { Category } from '../types'

export const selectSourceCategoryList = (state: AppState): Category[] => {
  return state.hodaiSetting.categories.map(category => ({
    ...category,
    items: category.items.filter(item => !item.isSelected)
  }))
}

export const selectSelectedCategoryList = (state: AppState): Category[] => {
  return state.hodaiSetting.categories
    .map(category => ({
      ...category,
      items: category.items.filter(item => item.isSelected)
    }))
    .filter(category => category.items.length)
}
