import React from 'react'

import Footer from '~/src/components/organisms/Footer'

type Props = {
  isEditing: boolean
  onSubmit: () => void
}

export const PrintTargetByItemFooter = (props: Props) => {
  const { isEditing, onSubmit } = props
  return (
    <Footer
      disableSubmitButton={!isEditing}
      noUseOptionButton={true}
      onSubmitButtonClick={onSubmit}
    />
  )
}
