import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Items from './Items'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import { AppState } from '~/src/redux/reducer'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    hasFile: !!state.file
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const ItemsContainer = () => {
  return <Items {...useVariationGroup()} />
}

export default ItemsContainer
