import { ContractTypeId } from '~/src/const/contract-type'
import { ServiceTypeId } from '~/src/const/service-type'
import { PlanPriceType } from '~/src/const/plan-price-type'

declare global {
  // TODO globalに変数持たせる必要あるか検討
  // eslint-disable-next-line no-unused-vars
  let APP: {
    storeId: string
    storeName: string
    account: string
    plfGrant: string
  }
}

export const GET_STORE_SUCCEEDED = 'oes/Auth/GET_STORE_SUCCEEDED'

export type Store = {
  storeId: string
  storeNo: string
  storeName: string
  serviceUseStateList: object[]
  isFreePlan: boolean
  isGop: boolean
  contractType: ContractTypeId // 初期化に失敗した場合はRに問い合わせできるようにする
  serviceTypes: ServiceTypeId[]
  planPriceType: PlanPriceType
  isKmKpAvailable: boolean
  isKmKmOnlyAvailable: boolean
  isKpKmOnlyAvailable: boolean
}

export type Token = {
  account: string
  plfGrant: string
  isManager: boolean
}

export type AuthState = {
  store?: Store
  token?: Token
}
