import React from 'react'

import PageTemplate from '~/src/components/templates/PageTemplate'
import Const from '~/src/const'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
const { URL } = Const

export default () => {
  const breadcrumbs = [{ title: 'カテゴリー' }]
  const description = [
    'ハンディからは、カテゴリーを設定した商品のみ注文できます。'
  ]
  return (
    <PageTemplate breadcrumbs={breadcrumbs} description={description}>
      <Message
        type="info"
        messageList={[
          <>
            この項目は
            <InlineLink href={URL.REGI_CATEGORY} target="_blank">
              Airレジのカテゴリー設定ページ
            </InlineLink>
            で設定する項目です。リンク先へ移動して設定を行ってください。
          </>
        ]}
      />
    </PageTemplate>
  )
}
