import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import CommonFooter from '~/src/components/organisms/Footer'
import Const from '~/src/const'
const { Page } = Const

type Props = {
  isCsvUploaded: boolean
}

const Footer: FC<Props> = props => {
  const { isCsvUploaded } = props

  const history = useHistory()
  const onSubmitButtonClick = useCallback(() => {
    history.push(Page.VARIATION_GROUP.ITEMS)
  }, [history])
  return (
    <CommonFooter
      noUseOptionButton
      onSubmitButtonClick={onSubmitButtonClick}
      useSubmitButtonAsNormalButton
      submitButtonText="設定を始める"
      disableSubmitButton={!isCsvUploaded}
    />
  )
}

export default Footer
