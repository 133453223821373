import React from 'react'
import { useSelector } from 'react-redux'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import DismissModal from '~/src/components/organisms/Modal/DismissModal'
import { CategoryModal } from './CategoryModal/CategoryModal'
import { useCancelItemSettingEditor, useCloseCancelConfirmModal } from './hooks'
import { selectKitchenPositionTargetModal } from './selectors'

export const Modal = () => {
  const { isCategoryModalShowing, isCancelConfirmModalShowing, isEditing } =
    useSelector(selectKitchenPositionTargetModal)

  const handleCloseCancelConfirmModal = useCloseCancelConfirmModal()
  const handleCancelItemSettingEditor = useCancelItemSettingEditor()

  return (
    <div>
      {isCategoryModalShowing && <CategoryModal />}
      <DismissModal
        isOpen={isCancelConfirmModalShowing}
        onClose={handleCancelItemSettingEditor}
        onOK={handleCloseCancelConfirmModal}
      />
      <DismissPrompt enabled={isEditing} />
    </div>
  )
}
