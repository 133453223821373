import React, { useCallback } from 'react'
import { actions } from './actions'
import { actions as optionSettingActions } from '~/src/pages/TargetServingTime/OptionSetting/actions'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'

export const useHandleSetFirstDrinkDelayMinutes = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.setFirstDrinkDelayMinutes(e.target.value))
      dispatch(optionSettingActions.edited())
    },
    [dispatch]
  )
}

export const useHandleSetFirstFoodDelayMinutes = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.setFirstFoodDelayMinutes(e.target.value))
      dispatch(optionSettingActions.edited())
    },
    [dispatch]
  )
}

export const useHandleToggleFirstDrinkDelay = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.toggleFirstDrinkDelay())
    dispatch(optionSettingActions.edited())
  }, [dispatch])
}

export const useHandleToggleFirstFoodDelay = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.toggleFirstFoodDelay())
    dispatch(optionSettingActions.edited())
  }, [dispatch])
}

export const useToggleAlertSeconds = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.toggleAlertSeconds())
    dispatch(optionSettingActions.edited())
  }, [dispatch])
}
