import Content from './Content'
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'

import useInteractors from '~/src/hooks/useInteractors'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    file: state.file,
    fileErrorMessage: state.fileErrorMessage
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const ContentContiner = () => {
  return <Content {...useInteractor()} {...useVariationGroup()} />
}

export default ContentContiner
