import { Record } from 'immutable'
import UUidGenerator from '~/src/util/UuidGenerator'
import Validate, { CheckType } from '~/src/util/Validate'
import CONSUMPTION_TAX_TYPE, {
  ConsumptionTaxType,
  fromId
} from '~/src/const/consumptionTaxType'

const {
  ALPHA,
  NUMERIC,
  PUNCT,
  BIGALPHA,
  BIGNUMERIC,
  BIGPUNCT,
  KATAKANA,
  HIRAGANA,
  JIS_KANJI
} = CheckType

interface VariationProps {
  variationId: string
  skuName1: string
  skuName2?: string
  inherit: boolean
  consumptionTaxType?: ConsumptionTaxType
  price?: number
}

const VariationRecord = Record<VariationProps>({
  variationId: '',
  skuName1: '',
  skuName2: undefined,
  inherit: true,
  consumptionTaxType: undefined,
  price: undefined
})

class Variation extends VariationRecord implements VariationProps {}

interface VariationEditorProps {
  variationId: string
  skuName1?: string
  skuName2?: string
  inherit: boolean
  consumptionTaxType: ConsumptionTaxType
  price: string
}

const VariationEditorRecord = Record<VariationEditorProps>({
  variationId: '',
  skuName1: undefined,
  skuName2: undefined,
  inherit: true,
  consumptionTaxType: CONSUMPTION_TAX_TYPE.STANDARD,
  price: '0'
})

export class VariationEditor
  extends VariationEditorRecord
  implements VariationEditorProps
{
  constructor() {
    super()
    return this.set('variationId', UUidGenerator())
  }

  setSkuName1(skuName1: string) {
    return this.set('skuName1', skuName1)
  }

  setSkuName2(skuName2: string) {
    return this.set('skuName2', skuName2)
  }

  setInherit(inherit: boolean) {
    return this.set('inherit', inherit)
  }

  setConsumptionTaxType(consumptionTaxTypeId: string) {
    return this.set('consumptionTaxType', fromId(consumptionTaxTypeId)!)
  }

  setPrice(price: string) {
    return this.set('price', price)
  }

  setByName(name: string, value: string) {
    switch (name) {
      case 'skuName1':
        return this.setSkuName1(value)
      case 'skuName2':
        return this.setSkuName2(value)
      case 'price':
        return this.setPrice(value)
    }
    return this
  }

  isValid(): boolean {
    return (
      !this.validateSkuName1() &&
      !this.validateSkuName2() &&
      !this.validatePrice()
    )
  }

  validateSkuName1(): string | undefined {
    return (
      Validate.required(this.skuName1) ||
      Validate.maxLength(this.skuName1, 32) ||
      Validate.invalidChar(this.skuName1, [
        ALPHA,
        NUMERIC,
        PUNCT,
        BIGALPHA,
        BIGNUMERIC,
        BIGPUNCT,
        KATAKANA,
        HIRAGANA,
        JIS_KANJI
      ])
    )
  }

  validateSkuName2(): string | undefined {
    if (this.skuName2 === undefined) {
      return undefined
    }
    return (
      Validate.maxLength(this.skuName2, 32) ||
      Validate.invalidChar(this.skuName2, [
        ALPHA,
        NUMERIC,
        PUNCT,
        BIGALPHA,
        BIGNUMERIC,
        BIGPUNCT,
        KATAKANA,
        HIRAGANA,
        JIS_KANJI
      ])
    )
  }

  validatePrice(): string | undefined {
    if (this.inherit) {
      return undefined
    }
    return (
      Validate.required(this.price) ||
      Validate.number(this.price) ||
      Validate.maxLength(this.price, 7)
    )
  }

  toVariation() {
    if (!this.isValid()) {
      throw new Error('still invalid variation')
    }
    return new Variation({
      variationId: this.variationId,
      skuName1: this.skuName1!,
      skuName2: this.skuName2 ? this.skuName2 : undefined,
      inherit: this.inherit,
      consumptionTaxType: this.inherit ? undefined : this.consumptionTaxType,
      price: this.inherit ? undefined : parseInt(this.price)
    })
  }
}

export default Variation
