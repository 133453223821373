import React from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import VariationGroupEditor from './VariationGroupEditor'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import useInteractors from '~/src/hooks/useInteractors'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    isShowing: !!state.editor,
    selectedItemCount: state.selectedItemIds.size
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const VariationGroupEditorContainer = () => {
  return <VariationGroupEditor {...useVariationGroup()} {...useInteractor()} />
}

export default VariationGroupEditorContainer
