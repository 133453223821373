import { AppState } from '~/src/redux/reducer'

export const selectPrinterByCategoriesSeatSet =
  (categoryId: string, seatSetId: string) => (state: AppState) => {
    const selectedPrinterIds =
      state.printTargetByCategories.selectedPrinterHolder.getSelectedPrinterIds(
        categoryId,
        seatSetId
      )
    const printerOptions = state.printTargetByCategories.printerOptions
    const sortedSelectedPrinterIds = printerOptions
      .filter(printerOption => selectedPrinterIds.includes(printerOption.id))
      .map(printerOption => printerOption.id)
    return {
      printerOptions,
      selectedPrinterIds: sortedSelectedPrinterIds
    }
  }
