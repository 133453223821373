import styled from 'styled-components'
import Const from '~/src/const'

const { Color, Size } = Const

type ColumnProps = {
  height?: string
  width?: string
}

export const ColumnBase = styled.div<ColumnProps>`
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  display: table-cell;
  line-height: 1.2;
  padding: 8px 16px;
  font-size: ${Size.FONT.BASE}px;
  text-align: left;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-all;
  height: ${props => props.height || '70'}px;
  width: ${props => (props.width ? props.width : '230')}px;
`
