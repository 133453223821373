import styled from 'styled-components'
import Row from '~/src/components/atoms/TableRow'
import Column from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'
const { Color, Size, Weight } = Const

const BaseRow = styled(Row)`
  padding: 0;
`

const BaseColumn = styled(Column)`
  font-size: ${Size.FONT.BASE}px;
  padding: 12px 12px;
`

export const TableRow = styled(BaseRow)<{ header: boolean }>`
  ${props =>
    props.header &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
    font-weight: bold;
  `}
`

export const CategoryRow = styled(BaseRow)`
  cursor: pointer;
`

export const ItemRow = styled(BaseRow)`
  margin-left: 16px;
  width: calc(100% - 16px);
`

export const CheckboxColumn = styled(BaseColumn)`
  width: 32px;
  text-align: left;
  padding: 13px 12px 11px;
  padding-right: 0;
`

export const NameColumn = styled(BaseColumn)<{ category?: boolean }>`
  width: auto;
  text-align: left;
  padding-left: 8px;
  ${props => props.category && `font-weight: ${Weight.BOLD}`}
`

export const PriceColumn = styled(BaseColumn)`
  width: 124px;
  text-align: right;
`

export const VariationColumn = styled(BaseColumn)`
  width: 130px;
  text-align: left;
`

export const TrashColumn = styled(BaseColumn)<{ onClick?: any }>`
  width: 52px;
  text-align: center;
  ${props => !!props.onClick && 'cursor: pointer;'}
`
