import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn } from './Column'
import { Row } from './Row'
import { Category } from '../../types'
import Const from '~/src/const'

const { Size, Color } = Const

type Props = {
  categories: Category[]
  headerTitle?: string
  tableHeader: string
}

export const AccordionList: React.VFC<Props> = props => {
  return (
    <Wrapper>
      <HeaderArea>
        <HeaderTitle>{props.headerTitle}</HeaderTitle>
      </HeaderArea>
      <MainContent>
        <TableHeader>
          <ContentColumn>{props.tableHeader}</ContentColumn>
        </TableHeader>
        {props.categories.map(category => (
          <Row
            key={`key-category-${category.categoryId}`}
            category={category}
          />
        ))}
      </MainContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`

const HeaderArea = styled.div`
  height: 30px;
  text-align: left;
  vertical-align: middle;
`

const HeaderTitle = styled.span`
  font-size: ${Size.FONT.LARGE}px;
  vertical-align: middle;
  font-weight: bold;
`

const MainContent = styled.div`
  width: 100%;
  height: 480px;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const TableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
