import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '../Common/DismissPrompt'

import Description from './Description'
import Footer from './Footer'
import { CSVData } from '~/src/util/CSVFileReader'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import VariationGroup from '~/src/redux/models/CSVItem/VariationGroup'
import Const from '~/src/const'

const { Page } = Const

type Props = {
  interactor: VariationGroupInteractor
  hasFile: boolean
  filename: string
  header: CSVData
  items: CSVItem[]
  variationGroups: VariationGroup[]
}

const Downloaded: React.FC<Props> = props => {
  const { interactor, filename, header, items, variationGroups } = props
  const history = useHistory()
  useEffect(() => {
    interactor.downloadFile(filename, header, items, variationGroups)
  }, [filename, header, interactor, items, variationGroups])
  if (!props.hasFile) {
    history.replace(Page.VARIATION_GROUP.GETTING_STARTED)
    return null
  }
  const breadcrumbs = [{ title: '商品一括編集用ファイル(CSV)のダウンロード' }]
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      modal={
        <>
          <DismissPrompt />
        </>
      }
      footer={<Footer />}
      displayKM
    >
      <Description />
    </PageTemplate>
  )
}

export default Downloaded
