import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import { ToppingListInteractor } from '~/src/interactors/Topping/ToppingList/ToppingListInteractor'
import { ToppingGroupInteractor } from '~/src/interactors/Topping/ToppingGroup/ToppingGroupInteractor'
import { ItemByToppingGroupInteractor } from '~/src/interactors/Topping/ItemByToppingGroup/ItemByToppingGroupInteractor'
import AuthInteractor from '~/src/interactors/Auth/AuthInteractor'
import { SettingInteractor } from '~/src/interactors/Setting/SettingInteractor'

const useInteractors = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => ({
      authInteractor: new AuthInteractor(dispatch),
      targetServingTimeInteractor: new TargetServingTimeInteractor(dispatch),
      variationGroupInteractor: new VariationGroupInteractor(dispatch),
      toppingListInteractor: new ToppingListInteractor(dispatch),
      toppingGroupInteractor: new ToppingGroupInteractor(dispatch),
      itemByToppingGroupInteractor: new ItemByToppingGroupInteractor(dispatch),
      settingInteractor: new SettingInteractor(dispatch)
    }),
    [dispatch]
  )
}

export default useInteractors
