import React, { useState } from 'react'
import styled from 'styled-components'
import SelectBox from '~/src/components/atoms/SelectBox'
import Button from '~/src/components/atoms/Button'

import Const from '~/src/const'
const { Color } = Const

type Props = {
  categories: Category[]
  onClick: (categoryId: string) => void
}

type Category = {
  id: string
  name: string
}

export const ALL_SELECT_CATEGORY = 'all_select'
const ALL_CAETGORY_ITEM = {
  id: ALL_SELECT_CATEGORY,
  value: ALL_SELECT_CATEGORY,
  label: 'すべてのカテゴリー商品'
}

export const SearchArea: React.VFC<Props> = props => {
  const [selectedCategoryId, setSelectedCategoryId] =
    useState(ALL_SELECT_CATEGORY)

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategoryId(e.target.value)
  }

  const options = [ALL_CAETGORY_ITEM].concat(
    props.categories.map(category => ({
      id: category.id,
      value: category.id,
      label: category.name
    }))
  )

  const onClick = () => {
    props.onClick(selectedCategoryId)
  }

  return (
    <CatecorySearchArea>
      <SelectBoxArea>
        <StyledSelectBox
          handleSelectChange={handleSelectChange}
          selectedId={selectedCategoryId}
          options={options}
        />
      </SelectBoxArea>
      <ButtonArea>
        <Button type="button" normal onClick={onClick}>
          絞り込む
        </Button>
      </ButtonArea>
    </CatecorySearchArea>
  )
}

const CatecorySearchArea = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Color.LIGHT_GRAY_4};
  border-radius: 6px;
  background-color: ${Color.VERY_LIGHT_GRAY};
  display: flex;
  justify-content: space-between;
`

const SelectBoxArea = styled.div`
  padding: 16px 0 18px 24px;
  flex: 1 1 auto;
`

const StyledSelectBox = styled(SelectBox)`
  max-width: 280px;
`

const ButtonArea = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  letter-spacing: 0;
  line-height: 21px;
  padding: 16px 24px 16px 0;
  flex: 0 0 124px;
  padding-left: 4px;
`
