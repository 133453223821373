import { Record, List, Map, OrderedMap } from 'immutable'
import Category from './Category'
import Items from '~/src/redux/models/Item/Items'
import {
  CategoryApiResponse,
  ItemCategoryJunctionApiResponse
} from '~/src/api/handy/items/types'

interface CategoriesProps {
  categories: List<Category>
  categoriesMap: Map<string, Category>
}

const CategoriesRecord = Record<CategoriesProps>({
  categories: List(),
  categoriesMap: Map<string, Category>()
})

export default class Categories
  extends CategoriesRecord
  implements CategoriesProps
{
  constructor(
    categories: CategoryApiResponse[] = [],
    items: Items = new Items(),
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[] = []
  ) {
    super()
    const list = List(categories)
      .map(category => new Category(category, items, itemCategoryJunctions))
      .sort((a, b) => a.displayOrder - b.displayOrder)

    const map = Map().withMutations(map => {
      list.forEach(category => {
        map.set(category.categoryId, category)
      })
    }) as Map<string, Category>

    return this.set('categories', list).set('categoriesMap', map)
  }

  getCategoryIds() {
    return this.categories.map(category => category.categoryId)
  }

  getCategory(categoryId: string) {
    return this.categoriesMap.get(categoryId)
  }

  hasCategory(categoryId: string) {
    return this.categoriesMap.has(categoryId)
  }

  hasItem(itemId: string) {
    return this.categories.some(category => category.hasItem(itemId))
  }

  // 性能懸念のある画面用の軽量モデル
  getSimpleCategories() {
    return this.categories.map(category => category.getSimpleCategory())
  }

  // 性能懸念のある画面用の軽量モデル
  getSimpleCategoriesWithItems() {
    return this.categories.map(category =>
      category.getSimpleCategoryWithItems()
    )
  }

  // IE 対応用の中量級モデル
  getSimpleCategoryForToppings() {
    return OrderedMap(
      this.categories
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(category => [
          category.categoryId,
          category.getSimpleCategoryForTopping()
        ])
    )
  }
}
