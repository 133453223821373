import styled from 'styled-components'
import Const from '~/src/const'
const { Color } = Const

export default styled.div`
  background-color: ${Color.WHITE};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  display: table;
  top: -1px;
  margin-top: -1px;
  padding: 16px 0;
  table-layout: fixed;
  width: 100%;
`
