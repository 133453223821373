import React, { useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectTargetParams } from './selectors'
import TableRow from '~/src/components/atoms/TableRow'
import {
  CategoryNameColumn,
  KitchenPositionSelectColumn,
  ByItemLinkColumn,
  AnalysisTagSelectColumn
} from './Column'
import Icon from '~/src/components/atoms/Icon'
import AnalysisTagSelect from '../../Common/AnalysisTagSelect/AnalysisTagSelect'
import { KitchenPositionSelect } from '../KitchenPositionSelect/KitchenPositionSelect'
import {
  useFocusFinished,
  useOnChange,
  useOpenItemSettingEditor
} from './hooks'
import KitchenPositionByCategory from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByCategory'

type Props = {
  kitchenPositionByCategory: KitchenPositionByCategory
  isFirstChild: boolean
}

export const Row: React.VFC<Props> = props => {
  const { hasItemSetting, focusTargetCategoryId } = useSelector(
    selectTargetParams(props.kitchenPositionByCategory)
  )
  const focusFinished = useFocusFinished()
  const openItemSettingEditor = useOpenItemSettingEditor(
    props.kitchenPositionByCategory.categoryId
  )
  const onChange = useOnChange(props.kitchenPositionByCategory.categoryId)
  const ref = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (focusTargetCategoryId === props.kitchenPositionByCategory.categoryId) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      focusFinished()
    }
  }, [
    ref,
    focusTargetCategoryId,
    props.kitchenPositionByCategory.categoryId,
    focusFinished
  ])
  return (
    <TableRow ref={ref}>
      <CategoryNameColumn>
        {props.kitchenPositionByCategory.categoryDisplayName}
      </CategoryNameColumn>
      <KitchenPositionSelectColumn>
        <KitchenPositionSelect
          selectedKitchenPositionId={
            props.kitchenPositionByCategory.kitchenPositionId
          }
          isAutoCompleted={props.kitchenPositionByCategory.isAutoCompleted}
          categoryId={props.kitchenPositionByCategory.categoryId}
        />
      </KitchenPositionSelectColumn>
      <AnalysisTagSelectColumn>
        <AnalysisTagSelect
          name="categoryByAnalysisTagSelect"
          selectedId={props.kitchenPositionByCategory.analysisTag}
          validateMode={props.kitchenPositionByCategory.analysisTagError}
          handleSelectChange={onChange}
          isFirstChild={props.isFirstChild}
        />
      </AnalysisTagSelectColumn>
      <ByItemLinkColumn>
        <StyledLink onClick={openItemSettingEditor}>
          商品別設定 {hasItemSetting && <Edited />}
        </StyledLink>
      </ByItemLinkColumn>
    </TableRow>
  )
}

const Edited = styled(Icon).attrs({ name: 'edited' })``

const StyledLink = styled.a`
  cursor: pointer;
`
