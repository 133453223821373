import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchResources,
  replaceResource,
  clearPrinterByCategorySeatSetsState
} from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'
import { clearUiState, openValidationErrorModal } from '~/src/redux/modules/UI'

export const useHandleInitialize = (isFetched: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!isFetched) {
      dispatch(fetchResources())
    }
  }, [dispatch, isFetched])
}

export const useHandleSubmit = (hasError: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (hasError) {
      dispatch(openValidationErrorModal())
      return
    }
    dispatch(replaceResource())
    dispatch(clearUiState())
  }, [dispatch, hasError])
}

export const useHandleClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearPrinterByCategorySeatSetsState())
  }, [dispatch])
}
