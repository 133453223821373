import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const/'
import { selectBatchSelectHeader } from './selectors'
const { Color } = Const

export const BatchSelectHeader: React.VFC = () => {
  const seatSetName = useSelector(selectBatchSelectHeader)

  return <Header>選択した注文エリア： {seatSetName}</Header>
}

const Header = styled.div`
  padding: 16px 0;
  color: ${Color.VERY_DARK_GRAY};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`
