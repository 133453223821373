import Const from '~/src/const'
import { ServiceTypeId } from '../const/service-type'
const { ServiceType } = Const

export const containsHandy = (serviceTypes: ServiceTypeId[] = []): boolean => {
  return serviceTypes.includes(ServiceType.HANDY_APP)
}

export const containsKM = (serviceTypes: ServiceTypeId[] = []): boolean => {
  return serviceTypes.includes(ServiceType.KM_APP)
}

export const containsKP = (serviceTypes: ServiceTypeId[] = []): boolean => {
  return serviceTypes.includes(ServiceType.KITCHEN_PRINTER)
}

export const containsPrepayment = (
  serviceTypes: ServiceTypeId[] = []
): boolean => {
  return serviceTypes.includes(ServiceType.PREPAYMENT)
}

export const containsSPO = (serviceTypes: ServiceTypeId[] = []): boolean => {
  return serviceTypes.includes(ServiceType.SPO)
}

export const containsTakeOut = (
  serviceTypes: ServiceTypeId[] = []
): boolean => {
  return serviceTypes.includes(ServiceType.TAKE_OUT)
}

export const isHandyKm = (serviceTypes: ServiceTypeId[] = []): boolean => {
  return containsHandy(serviceTypes) && containsKM(serviceTypes)
}
