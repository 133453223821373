import { Record, List, Set } from 'immutable'
import {
  CategoryApiResponse,
  ItemApiResponse,
  ItemByToppingGroupApiResponse,
  ItemCategoryJunctionApiResponse
} from '~/src/api/handy/items/types'
import Categories from '../Category/Categories'
import Item from './Item'
import { ItemByToppingGroup } from './ItemByToppingGroup'
import Items from './Items'

interface ItemByToppingGroupsProps {
  itemByToppingGroups: List<ItemByToppingGroup>
}

const ItemByToppingGroupsRecord = Record<ItemByToppingGroupsProps>({
  itemByToppingGroups: List()
})

export class ItemByToppingGroups
  extends ItemByToppingGroupsRecord
  implements ItemByToppingGroupsProps
{
  constructor(
    itemByToppingGroups: ItemByToppingGroupApiResponse[] = [],
    items: ItemApiResponse[] = [],
    categories: CategoryApiResponse[] = [],
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[] = []
  ) {
    super()
    const itemsModel = new Items(items)
    const categoriesModel = new Categories(
      categories,
      itemsModel,
      itemCategoryJunctions
    )
    const list = List(itemByToppingGroups).map(itemByToppingGroup => {
      const itemModel = itemsModel.items.get(itemByToppingGroup.itemId)
      if (itemModel === undefined || itemModel === null) {
        // 商品が消されてる場合は空をセット
        return new ItemByToppingGroup(itemByToppingGroup, new Item(), false)
      }
      return new ItemByToppingGroup(
        itemByToppingGroup,
        itemModel,
        !categoriesModel.hasItem(itemModel.itemId)
      )
    })

    return this.set('itemByToppingGroups', list)
  }

  public hasItemTaxFree() {
    return this.itemByToppingGroups.some(
      itemByToppingGroup => itemByToppingGroup.isTaxFree
    )
  }

  public hasItemNotSetCategory() {
    return this.itemByToppingGroups.some(
      itemByToppingGroup => itemByToppingGroup.isNotSetCategory
    )
  }

  public getItemIdsByToppingGroupId(toppingGroupId: string): Set<string> {
    return this.itemByToppingGroups
      .filter(
        itemByToppingGroup =>
          itemByToppingGroup.toppingGroupId === toppingGroupId
      )
      .map(itemByToppingGroup => itemByToppingGroup.itemId)
      .toSet()
  }
}
