import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { saveKmSetting } from '~/src/api/km/settings/actions'
import {
  KmSettingApiDispatch,
  KmSettingApiRequest
} from '~/src/api/km/settings/types'
import { actions as OptionSettingActions } from '~/src/pages/TargetServingTime/OptionSetting/actions'
import { useHistory } from 'react-router'
import Const from '~/src/const'
const { Page } = Const

export const useSaveButtonClick = (request: KmSettingApiRequest) => {
  const history = useHistory()
  const dispatch: KmSettingApiDispatch = useDispatch()
  return useCallback(async () => {
    await dispatch(saveKmSetting(request))
    dispatch(OptionSettingActions.clearState())
    history.push(Page.TARGET_SERVING_TIME.SETTING)
  }, [dispatch, request, history])
}

export const useOnCancelButtonClick = () => {
  const history = useHistory()
  return useCallback(() => {
    history.push(Page.TARGET_SERVING_TIME.SETTING)
  }, [history])
}
