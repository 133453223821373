import Const from '~/src/const'
import APIClient from '~/src/util/APIClient'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'

import { initializeSelectValues } from './PrinterSelectBox'

import { setShouldForward } from '~/src/redux/modules/Forward'
import { PrintTargetByItemsState } from './types'

const { API, UndefinedId, Toast } = Const

// ConstantValue
const CLEAR_PTBI_STATE = 'oes/ptbi/CLEAR_PTBI_STATE'
const FETCH_MASTERS_SUCCESS = 'oes/ptbi/FETCH_MASTERS_SUCCESS'
const CHANGE_ITEM_SEAT_SET_SETTING = 'oes/ptbi/CHANGE_ITEM_SEAT_SET_SETTING'

const SET_EDITING = 'oes/ptbi/SET_EDITING'

// Action
export const setPrinterByItemSeatSetsEditing = (isEditing: boolean) => {
  return {
    type: SET_EDITING,
    isEditing: isEditing
  }
}

export const clearPrinterByItemSeatSetsState = () => {
  return {
    type: CLEAR_PTBI_STATE
  }
}

export const fetchResources = (categoryId: string) => {
  // @ts-ignore
  return dispatch => {
    return Promise.all([
      APIClient.get(API.GET_ITEMS),
      APIClient.get(API.GET_SEATS),
      APIClient.get(API.GET_PRINTERS)
    ])
      .then(dispatch(setLoading()))
      .then(([itemsResponse, seatsResponse, printersResponse]) => ({
        items: itemsResponse.data,
        seats: seatsResponse.data,
        printers: printersResponse.data
      }))
      .then(responseMap =>
        dispatch(fetchResourcesSuccess(responseMap, categoryId))
      )
      .then(resources =>
        dispatch(initializeSelectValues(resources.printerByItemSeatSets))
      )
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const replacePrinterByItemSeatSets = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const state = getState()
    const { category, itemCategoryJunctions, allPrinterByItemSeatSets } =
      state.printTargetByItems
    // 画面で編集した内容をAPIで利用できるよう整形
    const printerByItemSeatSets = state.printerSelectBoxes.values.map(
      printerByItemSeatSet => {
        return {
          itemId: printerByItemSeatSet.itemId,
          seatSetId:
            printerByItemSeatSet.seatSetId === UndefinedId.SEAT_SET_ID
              ? undefined
              : printerByItemSeatSet.seatSetId,
          printerId:
            printerByItemSeatSet.printerId === UndefinedId.PRINTER_ID
              ? undefined
              : printerByItemSeatSet.printerId
        }
      }
    )

    // 編集対象となっているカテゴリーに紐付く商品IDを特定
    const editTargetItemIds = itemCategoryJunctions
      .filter(
        itemCategoryJunction =>
          itemCategoryJunction.categoryId === category.categoryId
      )
      .map(itemCategoryJunction => itemCategoryJunction.itemId)

    // サーバから返却されたすべての商品席群別印刷先出し分け設定から、編集対象となっているのもを除外する
    const excludeEditedValues = (allPrinterByItemSeatSets || [])
      .filter(
        printerByItemSeatSet =>
          !editTargetItemIds.includes(printerByItemSeatSet.itemId)
      )
      .map(printerByItemSeatSet => {
        return {
          itemId: printerByItemSeatSet.itemId,
          seatSetId: printerByItemSeatSet.seatSetId,
          printerId: printerByItemSeatSet.printerId
        }
      })

    // excludeEditedValuesに画面で編集した内容を追加する
    const postValues = printerByItemSeatSets.concat(excludeEditedValues)

    const json = {
      printerByItemSeatSets: postValues
    }

    return APIClient.post(API.POST_REPLACE_PRINT_TARGET_BY_ITEM, json)
      .then(dispatch(setLoading()))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .then(() => dispatch(setPrinterByItemSeatSetsEditing(false)))
      .then(() => dispatch(setShouldForward(true)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const changeItemSeatSetSetting = (itemId: string, checked: boolean) => {
  return {
    type: CHANGE_ITEM_SEAT_SET_SETTING,
    itemId: itemId,
    checked: checked
  }
}

const fetchResourcesSuccess = (responseMap, categoryId) => {
  const itemResource = responseMap.items.result || []
  const workCategories = (itemResource.categories || []).filter(
    category => category.categoryId === categoryId
  )

  let isNotFound = false
  let category
  if (workCategories.length === 0) {
    isNotFound = true
    // カテゴリーが存在しない場合はcategoryId,categoryNameForInput項目を設定
    // (項目自体がないと、後続処理でundefinedアクセスでJSエラーが発生するため)
    category = {
      categoryId: undefined,
      categoryNameForInput: undefined
    }
  } else {
    category = workCategories[0]
  }

  const itemCategoryJunctions = itemResource.itemCategoryJunctions || []

  // カテゴリーに紐付く商品IDを特定（一時変数：stateには入れない）
  const targetItemIds = itemCategoryJunctions
    .filter(
      itemCategoryJunction => itemCategoryJunction.categoryId === categoryId
    )
    .map(itemCategoryJunction => itemCategoryJunction.itemId)
  // カテゴリーに紐付く商品のみをピック
  const items = (itemResource.items || []).filter(item =>
    targetItemIds.includes(item.itemId)
  )

  const seatResource = responseMap.seats.result || []
  const seatSets = seatResource.seatSets || []

  const printerResource = responseMap.printers.result || []
  const printers = printerResource.printers || []
  // レジ側で削除されている商品は除外する
  const allPrinterByItemSeatSets = (
    printerResource.printerByItemSeatSets || []
  ).filter(printerByItemSeatSet =>
    itemCategoryJunctions.some(
      icj => printerByItemSeatSet.itemId === icj.itemId
    )
  )
  // カテゴリーに紐付く商品席群別印刷出し分け設定をピック
  const printerByItemSeatSets = allPrinterByItemSeatSets.filter(
    printerByItemSeatSet => targetItemIds.includes(printerByItemSeatSet.itemId)
  )

  const printersForSort = []
  printers.forEach(printer => {
    printersForSort[printer.printerId] = printer.createdAt
  })
  const isItemSeatSetSettings = items.map(item => {
    return {
      itemId: item.itemId,
      checked: printerByItemSeatSets.some(
        printerByItemSeatSet => printerByItemSeatSet.itemId === item.itemId
      )
    }
  })

  return {
    type: FETCH_MASTERS_SUCCESS,
    items: items,
    category: category,
    itemCategoryJunctions: itemCategoryJunctions,
    seatSets: seatSets,
    printers: printers,
    allPrinterByItemSeatSets: allPrinterByItemSeatSets,
    printerByItemSeatSets: printerByItemSeatSets,
    printersForSort: printersForSort,
    isItemSeatSetSettings: isItemSeatSetSettings,
    isNotFound: isNotFound
  }
}

// Initial state
const initialState: PrintTargetByItemsState = {
  items: [],
  category: {},
  itemCategoryJunctions: [],
  seatSets: [],
  printers: [],
  printersForSort: [],
  allPrinterByItemSeatSets: [],
  printerByItemSeatSets: [],
  isItemSeatSetSettings: [],
  isEditing: false,
  isNotFound: false
}

// Reducer
// @ts-ignore
export default (state = initialState, action): PrintTargetByItemsState => {
  switch (action.type) {
    case CLEAR_PTBI_STATE:
      return initialState
    case FETCH_MASTERS_SUCCESS:
      return {
        items: action.items,
        category: action.category,
        itemCategoryJunctions: action.itemCategoryJunctions,
        seatSets: action.seatSets,
        printers: action.printers,
        allPrinterByItemSeatSets: action.allPrinterByItemSeatSets,
        printerByItemSeatSets: action.printerByItemSeatSets,
        printersForSort: action.printersForSort,
        isItemSeatSetSettings: action.isItemSeatSetSettings,
        isNotFound: action.isNotFound,
        isEditing: false
      }
    case CHANGE_ITEM_SEAT_SET_SETTING: {
      const resultIsItemSeatSetSettings = state.isItemSeatSetSettings.filter(
        isItemSeatSetSetting => isItemSeatSetSetting.itemId !== action.itemId
      )
      resultIsItemSeatSetSettings.push({
        itemId: action.itemId,
        checked: action.checked
      })
      // toggleがOFFにされたものを除外する
      return {
        ...state,
        isItemSeatSetSettings: resultIsItemSeatSetSettings
      }
    }
    case SET_EDITING:
      return {
        ...state,
        isEditing: action.isEditing
      }
    default:
      return state
  }
}
