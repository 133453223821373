import { AppState } from '~/src/redux/reducer'
import Validate from '~/src/util/Validate'

export const selectLastOrderSetMode = (state: AppState) =>
  state.hodaiSetting.lastOrderSetting.lastOrderSetMode

export const selectLastOrderMinutes = (state: AppState) => {
  const lastOrderMinutes = state.hodaiSetting.lastOrderSetting.lastOrderMinutes
  return typeof lastOrderMinutes === 'undefined'
    ? ''
    : lastOrderMinutes.toString()
}

function validateNumberIsInValidRange(value?: number) {
  if (typeof value === 'undefined') {
    return undefined
  }

  return value > 0 && value < 1000
    ? undefined
    : '半角数字1~999で入力してください'
}

export const selectLastOrderMinutesInputError = (state: AppState) => {
  if (
    state.hodaiSetting.lastOrderSetting.lastOrderSetMode === 'manual' ||
    !state.hodaiSetting.isEditing
  ) {
    return undefined
  }

  if (state.hodaiSetting.lastOrderSetting.lastOrderIsComposing) {
    return '半角数字1~999で入力してください'
  }

  const lastOrderMinutes = state.hodaiSetting.lastOrderSetting.lastOrderMinutes
  return (
    Validate.required(lastOrderMinutes) ||
    validateNumberIsInValidRange(lastOrderMinutes)
  )
}

export const selectIsLastOrderSettingSubmittable =
  (error?: string) => (state: AppState) => {
    // LO手動設定を選択しているときはエラーが出ていてもFormをSubmitしていい
    if (state.hodaiSetting.lastOrderSetting.lastOrderSetMode === 'manual') {
      return true
    }

    if (typeof error === 'string') {
      return false
    }

    // LO自動設定を選択していて、エラーがない、かつ値が一度でも編集されているときはFormをSubmitしていい
    return state.hodaiSetting.isEditing
  }
