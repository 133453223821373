import { createAction } from 'typesafe-actions'
import { DeleteTargetCourse } from './types'

const CLEAR_STATE = 'oes/Course/CourseTop/CLEAR_STATE'
const OPEN_DELETE_MODAL = 'oes/Course/CourseTop/OPEN_DELETE_MODAL'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  openDeleteModal: createAction(
    OPEN_DELETE_MODAL,
    (deleteTargetCourse: DeleteTargetCourse) => deleteTargetCourse
  )()
}

export const types = {
  CLEAR_STATE,
  OPEN_DELETE_MODAL
}
