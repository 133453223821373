import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'

import useInteractors from '~/src/hooks/useInteractors'

import Discription from './Description'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    filename: state.filename!,
    header: state.header!,
    items: state.items,
    variationGroups: state.variationGroups
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const DiscriptionContiner = () => {
  return <Discription {...useInteractor()} {...useVariationGroup()} />
}

export default DiscriptionContiner
