import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import { Link } from 'react-router-dom'
import TableColumn from '~/src/components/atoms/TableColumn'
import TableRow from '~/src/components/atoms/TableRow'
import { useSelector } from 'react-redux'
import { selectInnerTable } from './selectors'

const { Color, Page, Size } = Const

export const InnerTable: React.VFC = () => {
  const {
    currentCount,
    flatRateCount,
    billingTargetCount,
    deviceCountAppended,
    lastUpdatedAt,
    requestedAt,
    billingTargetDate
  } = useSelector(selectInnerTable)

  return (
    <div>
      <SummaryHeaderArea>
        <ContentHeader />
        <ContentCell>台数</ContentCell>
        <ChangeLinkArea />
      </SummaryHeaderArea>
      <TableRow>
        <ContentHeader>現在の利用台数</ContentHeader>
        <ContentCell>
          {currentCount} 台
          <GrayTextArea>最終更新日時：{lastUpdatedAt}</GrayTextArea>
        </ContentCell>
        <ChangeLinkArea>
          <StyledLink to={Page.DEVICE_COUNT.UPDATE}>変更</StyledLink>
        </ChangeLinkArea>
      </TableRow>
      <TableRow>
        <ContentHeader>
          今月の請求対象台数
          <br />
          {requestedAt}
        </ContentHeader>
        <ContentCell>
          {billingTargetCount} 台 (定額分 {flatRateCount} 台 + 追加分{' '}
          {deviceCountAppended} 台)
          <br />
          <GrayTextArea>
            利用端末台数が最大の日：{billingTargetDate}
          </GrayTextArea>
        </ContentCell>
        <ChangeLinkArea />
      </TableRow>
    </div>
  )
}

const BaseColumn = styled(TableColumn)`
  text-align: left;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.TABLET_SM_MIN}px) {
    padding: 0 8px;
  }
`

const SummaryHeaderArea = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  font-weight: bold;
`

const ChangeLinkArea = styled(BaseColumn)`
  width: 70px;
`

const ContentHeader = styled(BaseColumn)`
  font-weight: bold;
`

const ContentCell = styled(BaseColumn)`
  width: 320px;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.TABLET_SM_MIN}px) {
    width: 270px;
  }
`

const GrayTextArea = styled.div`
  color: ${Color.DARK_GRAY_4};
`

const StyledLink = styled(Link)``
