import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducer from './redux/reducer'

export default () => {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
  return createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  )
}
