import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { ValidationErrorModal } from '~/src/components/organisms/Modal/ValidationErrorModal/ValidationErrorModal'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import Forward from '~/src/components/organisms/Forward'

import { PrintTargetByItemHeader } from './Header'
import { List } from './List/List'
import { PrintTargetByItemFooter } from './Footer'
import { selectPrintTargetByItemForm } from './selectors'
import { useSelector } from 'react-redux'
import {
  useCloseNotFoundErrorModal,
  useInitializeState,
  useClearState,
  useSubmit,
  useValidationError
} from './hooks'

import Const from '~/src/const'
import { useParams } from 'react-router'
const { Page, Time } = Const

const VALIDATION_MESSAGE =
  '選択されていない項目があります。すべて選択してください。'

const CATEGORY_NAME_HEIGHT = 50

export const PrintTargetByItem = () => {
  const {
    category,
    items,
    seatSets,
    printerByItemSeatSets,
    shouldForward,
    isEditing,
    isNotFound
  } = useSelector(selectPrintTargetByItemForm)

  const closeNotFoundErrorModal = useCloseNotFoundErrorModal()
  const onSubmit = useSubmit()
  const onValidationError = useValidationError()
  const componentWillUnmount = useClearState()

  const params = useParams<{ categoryId?: string }>()
  const categoryId = params.categoryId
  const componentDidMount = useInitializeState(categoryId)

  useEffect(() => componentDidMount(), [componentDidMount])
  useEffect(() => () => componentWillUnmount(), [componentWillUnmount])

  const formRef = useRef<HTMLFormElement>(null)

  const breadcrumbs = [
    { title: '印刷振り分け', page: Page.PRINTER_TARGET.CATEGORIES },
    { title: '商品別設定' }
  ]

  const doSubmit = useCallback(() => {
    let event
    if (typeof Event === 'function') {
      event = new Event('submit', { bubbles: true, cancelable: true })
    } else {
      event = document.createEvent('Event') // IE対策
      event.initEvent('submit', true, true)
    }
    const current = formRef.current
    if (current !== null) {
      current.dispatchEvent(event)
    }
  }, [])

  const handleSubmit = useCallback(() => {
    const enableItemIds = items
      .filter(item => item.isItemSeatSetSetting)
      .map(item => item.itemId)

    const inputIds: string[] = []
    printerByItemSeatSets.forEach(printerByItemSeatSet => {
      inputIds.push(
        printerByItemSeatSet.itemId + '-' + printerByItemSeatSet.seatSetId
      )
    })

    const masterIds: string[] = []
    items.forEach(item => {
      if (enableItemIds.includes(item.itemId)) {
        seatSets.forEach(seatSet => {
          masterIds.push(item.itemId + '-' + seatSet.seatSetId)
        })
      }
    })

    const errorIds = masterIds.filter(masterId => !inputIds.includes(masterId))

    if (errorIds.length > 0) {
      onValidationError()
      return
    }

    onSubmit()
  }, [printerByItemSeatSets, items, seatSets, onSubmit, onValidationError])

  const footer = (
    <PrintTargetByItemFooter isEditing={isEditing} onSubmit={doSubmit} />
  )

  const modal = (
    <>
      <ValidationErrorModal>{VALIDATION_MESSAGE}</ValidationErrorModal>
      <ErrorModal
        isError={isNotFound}
        error={{
          title: '対象のデータが存在しません',
          message: '印刷振り分け設定画面に戻ります。'
        }}
        onClose={closeNotFoundErrorModal}
      />
      <DismissPrompt enabled={isEditing} />
    </>
  )

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayKP
    >
      <form onSubmit={handleSubmit} ref={formRef}>
        <CategoryName>カテゴリー ： {category.name}</CategoryName>
        <ScrollWrapper>
          <PrintTargetByItemHeader seatSets={seatSets} />
          <List
            items={items}
            seatSets={seatSets}
            printerByItemSeatSets={printerByItemSeatSets}
          />
        </ScrollWrapper>
      </form>
      <Forward
        when={shouldForward}
        forwardPage={Page.PRINTER_TARGET.CATEGORIES}
        after={Time.FOWARD_WAIT}
      />
    </PageTemplate>
  )
}

const ScrollWrapper = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  left: 0;
  overflow: auto;
`

const CategoryName = styled.div`
  height: ${CATEGORY_NAME_HEIGHT}px;
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
`
