import { Map } from 'immutable'
import React, { useCallback } from 'react'
import Footer from '~/src/components/organisms/Footer'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'

type Props = {
  hasError: boolean
  isEdited: boolean
  targetServingTimeInteractor: TargetServingTimeInteractor
  targetServingTimeMap: Map<string, TargetServingTime>
}

const TargetServingTimeFooter: React.FC<Props> = props => {
  const {
    hasError,
    isEdited,
    targetServingTimeInteractor,
    targetServingTimeMap
  } = props

  const onSubmitButtonClickCb = useCallback(() => {
    targetServingTimeInteractor.post(targetServingTimeMap)
  }, [targetServingTimeMap, targetServingTimeInteractor])

  return (
    <Footer
      disableSubmitButton={hasError || !isEdited}
      noUseOptionButton={true}
      onSubmitButtonClick={onSubmitButtonClickCb}
    />
  )
}

export default React.memo(TargetServingTimeFooter)
