import React from 'react'
import { Radio as AirRadio } from '@air-kit/air-kit'

type Props = {
  name: string
  value: string
  disabled?: boolean
  checked?: boolean
  onChange: (e: React.SyntheticEvent) => void
}

const Radio = (props: Props) => {
  const { name, value, disabled, checked, onChange } = props

  return (
    <AirRadio
      name={name}
      label=""
      value={value}
      isDisabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default Radio
