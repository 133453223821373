import { Dispatch } from 'redux'
import { List } from 'immutable'

import Const from '~/src/const'
import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { actions } from '~/src/redux/modules/Topping/ToppingGroup/actions'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import { handleError } from '~/src/redux/modules/ApiError'

const { API, Toast } = Const

export class ToppingGroupInteractor {
  private readonly dispatch: Dispatch
  private readonly apiClient: ApiClientInterface

  constructor(dispatch: Dispatch, apiClient: ApiClientInterface = ApiClient) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  async fetchInitialData(toppingGroupId?: string) {
    this.dispatch(setLoading())
    await this.apiClient
      .get(API.GET_ITEMS)
      .then(getItemsResponse => {
        const itemsResponse: ApiResponse<ItemsApiResponse> =
          getItemsResponse.data
        this.dispatch(
          actions.fetchInitialDataSucceeded(itemsResponse, toppingGroupId)
        )
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  editToppingGroupName(name: string) {
    this.dispatch(actions.editToppingGroupName(name))
  }

  setToppingGroupNameErrorStatus(toBe: boolean) {
    this.dispatch(actions.setToppingGroupNameErrorStatus(toBe))
  }

  addItemToToppingGroup(itemId: string) {
    this.dispatch(actions.addItemToToppingGroup(itemId))
  }

  addCategoryToToppingGroup(categoryId: string) {
    this.dispatch(actions.addCategoryToToppingGroup(categoryId))
  }

  deleteAllFromToppingGroup() {
    this.dispatch(actions.deleteAllFromToppingGroup())
  }

  deleteItemFromToppingGroup(itemId: string) {
    this.dispatch(actions.deleteItemFromToppingGroup(itemId))
  }

  didReOrderSelectedToppingItemIds(oldIndex: number, newIndex: number) {
    this.dispatch(actions.didReOrderSelectedToppingItemIds(oldIndex, newIndex))
  }

  async saveToppingGroup(params: {
    toppingGroupId?: string
    toppingGroupName: string
    itemIds: List<string>
    version?: number
  }) {
    this.dispatch(setLoading())
    const form = {
      toppingGroupId: params.toppingGroupId,
      itemIds: params.itemIds.toArray(),
      toppingGroupName: params.toppingGroupName,
      version: params.version
    }
    await this.apiClient
      .post(API.POST_TOPPING_GROUPS_MERGE, form)
      .then(async () => {
        this.dispatch(actions.saveToppingGroupSucceeded())
        // @ts-ignore
        this.dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        await this.fetchInitialData()
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }
}
