import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import ValidatableSelectBox from '~/src/components/molecules/ValidatableSelectBox'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationRetouch from '~/src/redux/models/CSVItem/VariationRetouch'
import VariationModel from '~/src/redux/models/CSVItem/Variation'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import CONSUMPTION_TAX_TYPE, {
  ConsumptionTaxType
} from '~/src/const/consumptionTaxType'
import {
  VariationRow,
  NameColumn,
  TaxTypeColumn,
  PriceColumn
} from '../../Common/TableParts'

type Props = {
  item: CSVItem
  variation: VariationModel
  interactor: VariationGroupInteractor
}

const Variation: React.FC<Props> = props => {
  const { item, variation, interactor } = props

  const consumptionTaxType: ConsumptionTaxType | undefined =
    item.getConsumptionTaxType(variation)
  const price = item.getPrice(variation)

  const onConsumptionTaxTypeChangeCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const next = new VariationRetouch({ variationId: variation.variationId })
        .setPrice(price)
        .setConsumptionTaxType(e.target.value)
      interactor.updateVariationRetouch(item.itemId, next)
    },
    [variation.variationId, price, interactor, item.itemId]
  )
  const onPriceChangeCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const next = new VariationRetouch({ variationId: variation.variationId })
        .setPrice(e.target.value)
        .setConsumptionTaxType(consumptionTaxType?.ID)
      interactor.updateVariationRetouch(item.itemId, next)
    },
    [variation.variationId, consumptionTaxType, interactor, item.itemId]
  )

  const options = useMemo(
    () =>
      [
        CONSUMPTION_TAX_TYPE.STANDARD,
        CONSUMPTION_TAX_TYPE.REDUCED,
        CONSUMPTION_TAX_TYPE.NON
      ].map(taxType => ({
        id: taxType.ID,
        value: taxType.ID,
        label: taxType.TEXT
      })),
    []
  )

  return (
    <VariationRow>
      <NameColumn>
        <SKUName>{variation.skuName1}</SKUName>
        {variation.skuName2 && <SKUName>{variation.skuName2}</SKUName>}
      </NameColumn>
      <TaxTypeColumn>
        <ValidatableSelectBox
          placeholder="選択"
          options={options}
          selectedId={consumptionTaxType?.ID}
          error={item.validateConsumptionTaxType(variation)}
          handleSelectChange={onConsumptionTaxTypeChangeCb}
        />
      </TaxTypeColumn>
      <PriceColumn>
        <ValidatableInput
          type="text"
          align="right"
          placeholder="0"
          value={price || ''}
          error={item.validatePrice(variation)}
          onChange={onPriceChangeCb}
        />
      </PriceColumn>
    </VariationRow>
  )
}

const SKUName = styled.p`
  margin: 0;
  padding-bottom: 8px;
  :last-child {
    padding-bottom: 0;
  }
`

export default React.memo(Variation)
