import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import {
  CourseApiRequest,
  CourseDetailApiRequest
} from '~/src/api/handy/courses/types'
import { ItemsApiDispatch } from '~/src/api/handy/items/types'
import { actions as itemsApiActions } from '~/src/api/handy/items/actions'
import { useHistory } from 'react-router-dom'
import Const from '~/src/const'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { handleError } from '~/src/redux/modules/ApiError'
import { CourseSettingState, Item } from './types'
import { actions } from './actions'

const { Page, API, Toast, UndefinedId } = Const

export const useFetch = () => {
  const dispatch: ItemsApiDispatch = useDispatch()
  return useCallback(() => {
    dispatch(itemsApiActions.fetchItems())
  }, [dispatch])
}

export const useHandlePostCreateCourse = (
  state: CourseSettingState,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const history = useHistory()
  return useCallback(async () => {
    dispatch(setLoading())
    return apiClient
      .post(API.POST_CREATE_COURSES, convertStateToRequest(state))
      .then(() => {
        dispatch(showAndBlurToast(Toast.CREATED_MESSAGE))
        dispatch(actions.clearState())
        history.push(Page.COURSE.LIST)
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [dispatch, apiClient, state, history])
}

export const useHandlePostUpdateCourse = (
  state: CourseSettingState,
  courseId: string,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const history = useHistory()

  return useCallback(async () => {
    dispatch(setLoading())
    return apiClient
      .post(API.POST_UPDATE_COURSES, convertStateToRequest(state, courseId))
      .then(() => {
        dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        dispatch(actions.clearState())
        history.push(Page.COURSE.LIST)
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [dispatch, apiClient, state, history, courseId])
}

const convertStateToRequest = (
  state: CourseSettingState,
  courseId?: string
): CourseApiRequest => {
  const courseDetails: CourseDetailApiRequest[] = state.selectedItemList.map(
    (item: Item, index: number) => ({
      courseDetailOrder: index + 1,
      courseId: courseId,
      itemId: item.itemId
    })
  )
  if (
    state.selectedHodai &&
    state.selectedHodai.itemId !== UndefinedId.ITEM_ID
  ) {
    courseDetails.push({
      courseId: courseId,
      itemId: state.selectedHodai?.itemId,
      hodaiId: state.selectedHodai?.hodaiId
    })
  }
  return {
    courseId: courseId,
    itemId: state.selectedCourse?.itemId,
    courseDetails: courseDetails
  }
}
