import React, { useState } from 'react'
import { Modal } from '@air-kit/air-kit'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import { Messages } from './Messages/Messages'
import { List } from './List/List'
import { Item as RowItem, SelectedItem } from './List/Row/Row'
import Const from '~/src/const'
import { SelectedHodai } from '../types'
const { Size, Color, UndefinedId } = Const

type Props = {
  displayItems: RowItem[]
  onSubmit: (selectedItem: SelectedItem) => void
  onClose: () => void
  originalSelectedHodai?: SelectedHodai
  hasHodaiError: boolean
}

export const HodaiSelectModal: React.VFC<Props> = props => {
  const [selectedItem, setSelectedItem] = useState({
    id: props.originalSelectedHodai?.itemId || UndefinedId.ITEM_ID,
    hodaiId: props.originalSelectedHodai?.hodaiId || UndefinedId.HODAI_ID,
    name: props.originalSelectedHodai?.itemName || UndefinedId.ITEM_NAME,
    isEditing: false
  })
  const title = '放題プランの設定'
  const displayItems: RowItem[] = props.displayItems

  const onChangeRadio = (selectedItem: SelectedItem) => {
    setSelectedItem({
      id: selectedItem.id,
      hodaiId: selectedItem.hodaiId,
      name: selectedItem.name,
      isEditing: true
    })
  }

  const footer = {
    right: (
      <>
        <StyledButton normal name="cancel-button" onClick={props.onClose}>
          キャンセル
        </StyledButton>
        <StyledButton
          primary
          disabled={!selectedItem.isEditing}
          onClick={() => {
            props.onSubmit(selectedItem)
          }}
        >
          登録する
        </StyledButton>
      </>
    )
  }
  return (
    <Adjuster>
      <StyledModal title={title} footer={footer} onClose={props.onClose}>
        <Messages hasHodaiError={props.hasHodaiError} />
        <Description>
          放題プラン付きコースの場合に設定します。
          <Detail>※放題プランの価格はコース価格に加算されません。</Detail>
        </Description>
        <List
          items={displayItems}
          onChange={onChangeRadio}
          selected={selectedItem.id}
        />
      </StyledModal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`

const Description = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.LARGE}px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: justify;
`

const Detail = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.BASE}px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: justify;
`

const StyledButton = styled(Button)`
  width: auto;
`

const StyledModal = styled(Modal)`
  *:not(:first-child) {
    margin-top: 0;
  }
`
