export type ServiceTypeId =
  | 'HANDY_APP'
  | 'KITCHEN_PRINTER'
  | 'KM_APP'
  | 'SPO'
  | 'PREPAYMENT'
  | 'OPG'
  | 'TAKE_OUT'

/**
 * サービスタイプ
 */
export const ServiceType: {
  HANDY_APP: ServiceTypeId
  KITCHEN_PRINTER: ServiceTypeId
  KM_APP: ServiceTypeId
  SPO: ServiceTypeId
  PREPAYMENT: ServiceTypeId
  OPG: ServiceTypeId
  TAKE_OUT: ServiceTypeId
} = {
  HANDY_APP: 'HANDY_APP', // ハンディアプリ
  KITCHEN_PRINTER: 'KITCHEN_PRINTER', // キッチンプリンター
  KM_APP: 'KM_APP', // キッチンモニターアプリ
  SPO: 'SPO', // MO店内版 (Smart Phone Order)
  PREPAYMENT: 'PREPAYMENT', // 先会計 (Airレジから KM への直接注文)
  OPG: 'OPG', // Order POS Gateway
  TAKE_OUT: 'TAKE_OUT' // MO店外版 (テイクアウト)
}
