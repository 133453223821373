import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import Hint from '~/src/components/molecules/Hint'

import {
  ItemNameColumn,
  KitchenPositionSelectColumn,
  AnalysisTagSelectColumn,
  ItemSettingColumn
} from './Column'

import Const from '~/src/const/'

const { Color } = Const
const BALLOON_WIDTH = 248
const ANALYSIS_TAG_BALLOON_WIDTH = 290

export const HeaderRow: React.VFC = () => {
  return (
    <HeaderRowContainer>
      <ItemNameColumn>商品</ItemNameColumn>
      <ItemSettingColumn>
        商品別設定
        <Hint balloonWidth={BALLOON_WIDTH} position="bottom-left">
          オンにすると、カテゴリーの設定と別の設定ができます。
        </Hint>
      </ItemSettingColumn>
      <KitchenPositionSelectColumn>
        振り分け先の調理場
      </KitchenPositionSelectColumn>
      <AnalysisTagSelectColumn>
        分析タグ
        <Hint balloonWidth={ANALYSIS_TAG_BALLOON_WIDTH} position="bottom-left">
          フードやドリンクなどの種別ごとに商品を分別することができます。
          <br />
          設定すると、フードとドリンクが初めて注文される場合は「新」が表示されます。
        </Hint>
      </AnalysisTagSelectColumn>
    </HeaderRowContainer>
  )
}

const HeaderRowContainer = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
