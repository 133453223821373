import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { TargetServingTimeState } from '~/src/redux/modules/TargetServingTime/types'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import Footer from './Footer'

const targetServingTimeSelector = createSelector(
  (state: AppState) => state.targetServingTimeSetting.targetServingTime,
  (targetServingTimeState: TargetServingTimeState) => {
    const hasError = targetServingTimeState.targetServingTimeMap.some(
      value => !value.isValid()
    )
    return {
      hasError,
      isEdited: targetServingTimeState.isEdited,
      targetServingTimeMap: targetServingTimeState.targetServingTimeMap
    }
  }
)

const useTargetServingTime = () => {
  return useSelector(targetServingTimeSelector)
}

const useInteractor = () => ({
  targetServingTimeInteractor: useInteractors().targetServingTimeInteractor
})

const FooterContainer = () => {
  return <Footer {...useInteractor()} {...useTargetServingTime()} />
}

export default FooterContainer
