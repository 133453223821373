import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'

const { Size } = Const

const baseColumn = styled(TableColumn)`
  font-size: ${Size.FONT.BASE}px;
  text-align: left;
`

export const CategoryNameColumn = styled(baseColumn)`
  width: auto;
`
export const KitchenPositionSelectColumn = styled(baseColumn)`
  width: 200px;
  padding: 0 0 0 16px; /* 親要素で指定されている値を上書きするため padding-leftで指定しない */
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const AnalysisTagSelectColumn = styled(baseColumn)`
  width: 190px;
  padding: 0 0 0 16px; /* 親要素で指定されている値を上書きするため padding-leftで指定しない */
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const ByItemLinkColumn = styled(baseColumn)`
  width: 130px;
  padding: 0 20px;
  text-align: left;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.TABLET_SM_MIN}px) {
    width: 90px;
  }
`
