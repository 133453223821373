import React from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import ModalList from './ModalList'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import useInteractors from '~/src/hooks/useInteractors'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    variations:
      state.editor!.variations.size >= 30
        ? state.editor!.variations
        : state.editor!.variations.push(undefined),
    count: state.editor!.variations.size
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const ModalListContainer = () => {
  return <ModalList {...useVariationGroup()} {...useInteractor()} />
}

export default ModalListContainer
