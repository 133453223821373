import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './actions'
import { Item, SelectedHodai } from './types'

export const useInitialize = (items: Item[], selectedHodai?: SelectedHodai) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.initialize({ items, selectedHodai }))
  }, [dispatch, items, selectedHodai])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.clearState())
  }, [dispatch])
}

export const useSubmitCourseSelect = () => {
  const dispatch = useDispatch()
  return useCallback(
    selectedItem => {
      dispatch(
        actions.submitCourseSelect({
          itemId: selectedItem.id,
          name: selectedItem.name
        })
      )
    },
    [dispatch]
  )
}

export const useCloseCourseSelect = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.closeCourseSelect())
  }, [dispatch])
}

export const useOpenCourseSelect = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.openCourseSelect())
  }, [dispatch])
}

export const useSubmitHodaiSelect = () => {
  const dispatch = useDispatch()
  return useCallback(
    selectedItem => {
      dispatch(
        actions.submitHodaiSelect({
          itemId: selectedItem.id,
          hodaiId: selectedItem.hodaiId,
          itemName: selectedItem.name
        })
      )
    },
    [dispatch]
  )
}

export const useCloseHodaiSelect = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.closeHodaiSelect())
  }, [dispatch])
}
