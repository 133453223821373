import { ENVIRONMENT_TYPE, ENVIRONMENT } from '~/src/const/environment'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'
import { ServiceTypeId } from '../const/service-type'

export const setupDatadog = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      // Nothing to do
      break
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      // RUM setup
      datadogRum.init({
        applicationId: Datadog.APPLICATION_ID.DEVELOPMENT,
        clientToken: Datadog.CLIENT_TOKEN.DEVELOPMENT,
        site: Datadog.SITE,
        service: Datadog.SERVICE_NAME.DEVELOPMENT,
        env: location.hostname,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [
          /^https:\/\/(dev1?\d|stg)\.order\.arg\.x\.recruit\.co\.jp$/
        ],
        // trace観点では不要だが、sourcemap観点で必須ドキュメント上項目となっているため固定値
        version: '1'
      })
      // BrowserLogs setup
      datadogLogs.init({
        clientToken: Datadog.CLIENT_TOKEN_BROWSER_LOGS.DEVELOPMENT,
        site: Datadog.SITE,
        service: Datadog.SERVICE_NAME_BROWSER_LOGS.DEVELOPMENT,
        env: ENVIRONMENT,
        version: '1',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['info', 'warn', 'error'],
        sessionSampleRate: 100
      })
      break
    case ENVIRONMENT_TYPE.PRODUCTION:
      // RUM setup
      datadogRum.init({
        applicationId: Datadog.APPLICATION_ID.PRODUCTION,
        clientToken: Datadog.CLIENT_TOKEN.PRODUCTION,
        site: Datadog.SITE,
        service: Datadog.SERVICE_NAME.PRODUCTION,
        env: location.hostname,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: ['https://order.airregi.jp/'],
        // trace観点では不要だが、sourcemap観点で必須ドキュメント上項目となっているため固定値
        version: '1'
      })
      // BrowserLogs setup
      datadogLogs.init({
        clientToken: Datadog.CLIENT_TOKEN_BROWSER_LOGS.PRODUCTION,
        site: Datadog.SITE,
        service: Datadog.SERVICE_NAME_BROWSER_LOGS.PRODUCTION,
        env: ENVIRONMENT,
        version: '1',
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['info', 'warn', 'error'],
        sessionSampleRate: 100
      })
      break
    default:
      // Nothing to do
      break
  }
}

export const setDatadogUser = (
  storeId: string,
  serviceTypes: ServiceTypeId[]
) => {
  datadogRum.setUser({
    id: storeId,
    plan: serviceTypes
  })
  datadogLogs.setUser({
    id: storeId,
    plan: serviceTypes
  })
}
