import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import CategoryList from './CategoryList'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import { AppState } from '~/src/redux/reducer'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    categories: state.categories,
    haveNonCategoryItem: state.items.some(
      item =>
        !state.categories.some(
          category => category.categoryId === item.categoryId
        )
    )
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const CategoryListContainer = () => {
  return <CategoryList {...useVariationGroup()} />
}

export default CategoryListContainer
