import { ActionType, getType } from 'typesafe-actions'
import { ToppingListState } from './types'
import { ToppingGroups } from '~/src/redux/models/Topping/ToppingGroups'
import { ItemByToppingGroups } from '~/src/redux/models/Item/ItemByToppingGroups'
import actions from './actions'
import { arrayMove } from '~/src/util/ArrayUtils'
import { List } from 'immutable'

const initialState: ToppingListState = {
  toppingGroups: new ToppingGroups([], [], [], [], []),
  itemByToppingGroups: new ItemByToppingGroups([], [], [], []),
  toppingGroupIds: List(),
  toppingGroupIdForDeleteModal: '',
  warningMessages: List(),
  cautionMessages: List(),
  isEdited: false,
  openMaxToppingGroupModal: false
}

export const toppingList = (
  state: ToppingListState = initialState,
  action: ActionType<typeof actions>
): ToppingListState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return {
        ...state,
        toppingGroupIdForDeleteModal: '',
        isEdited: false
      }
    }
    case getType(actions.fetchInitialDataSucceeded): {
      const result = action.payload.items.result
      const itemByToppingGroups = new ItemByToppingGroups(
        result.itemByToppingGroups,
        result.items,
        result.categories,
        result.itemCategoryJunctions
      )
      const toppingGroups = new ToppingGroups(
        result.toppingGroups,
        result.items,
        result.categories,
        result.itemCategoryJunctions,
        result.itemByToppingGroups
      )
      const toppingGroupIds = result.toppingGroups
        ? result.toppingGroups
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(toppingGroup => toppingGroup.toppingGroupId)
        : []
      return {
        ...state,
        toppingGroups: toppingGroups,
        toppingGroupIds: List(toppingGroupIds),
        itemByToppingGroups: itemByToppingGroups,
        // ここでList.of()を使うとなぜかIEでTypeErrorとなるためここだけList()を使っている
        warningMessages: List(
          toppingGroups.createWarningMessages(itemByToppingGroups)
        ),
        cautionMessages: List(toppingGroups.createCautionMassages())
      }
    }
    case getType(actions.didTapTrashBox): {
      return {
        ...state,
        toppingGroupIdForDeleteModal: action.payload.toppingGroupId
      }
    }
    case getType(actions.didSortToppingGroup): {
      return {
        ...state,
        toppingGroupIds: List.of(
          ...arrayMove(
            state.toppingGroupIds.toArray(),
            action.payload.oldIndex,
            action.payload.newIndex
          )
        ),
        isEdited: true
      }
    }
    case getType(actions.postDeleteToppingGroupSucceeded): {
      const toppingGroupIdsDeleted = state.toppingGroupIds.filter(
        toppingGroupId => toppingGroupId !== action.payload.toppingGroupId
      )
      return {
        ...state,
        toppingGroupIds: toppingGroupIdsDeleted,
        isEdited: toppingGroupIdsDeleted.size > 1 ? state.isEdited : false
      }
    }
    case getType(actions.postUpdateToppingGroupSucceeded): {
      return {
        ...state,
        isEdited: false
      }
    }
    case getType(actions.openMaxToppingGroupModal): {
      return {
        ...state,
        openMaxToppingGroupModal: true
      }
    }
    case getType(actions.closeMaxToppingGroupModal): {
      return {
        ...state,
        openMaxToppingGroupModal: false
      }
    }
    default:
      return state
  }
}
