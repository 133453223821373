import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import Validate, { CheckType } from '~/src/util/Validate'
import Required from '~/src/components/atoms/Required'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'

const MAX_NAME_LENGTH = 20

const NameInputContainer: React.VFC = () => {
  const name = useSelector(
    (state: AppState) => state.toppingGroup.toppingGroupName.value
  )
  const isEdited = useSelector((state: AppState) => state.toppingGroup.isEdited)
  const interactor = useInteractors().toppingGroupInteractor

  const handleChange = useCallback(
    (toppingGroupName: string) => {
      interactor.editToppingGroupName(toppingGroupName)
    },
    [interactor]
  )
  const handleErrorStatusChange = useCallback(
    (isError: boolean) => {
      interactor.setToppingGroupNameErrorStatus(isError)
    },
    [interactor]
  )

  const props: InnerProps = {
    name: name,
    isEdited: isEdited,
    onChange: handleChange,
    onErrorStatusChange: handleErrorStatusChange
  }
  return <NameInput {...props} />
}
export { NameInputContainer as NameInput }

type InnerProps = {
  name: string
  isEdited: boolean
  onChange: (name: string) => void
  onErrorStatusChange: (isError: boolean) => void
}
const NameInput: React.VFC<InnerProps> = ({
  name,
  isEdited,
  onChange,
  onErrorStatusChange
}) => {
  const [errorMsg, setErrorMsg] = useState('')

  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  const validationResult = useTextValidation(name)
  useEffect(() => {
    if (!isEdited) {
      return
    } // 画面を触る前はエラーを表示しない
    if (validationResult.isError) {
      setErrorMsg(validationResult.message!)
      onErrorStatusChange(true)
    } else {
      setErrorMsg('')
      onErrorStatusChange(false)
    }
  }, [isEdited, onErrorStatusChange, setErrorMsg, validationResult])

  return (
    <FormGroup>
      <FormLabel>
        トッピンググループ名
        <Required name="required" />
      </FormLabel>
      <FormInput>
        <StyledInput
          type="text"
          value={name}
          align="bottom"
          onChange={changeHandler}
          error={errorMsg}
          placeholder="100円トッピングなど"
        />
      </FormInput>
    </FormGroup>
  )
}

const useTextValidation = (text: string) => {
  const result = useMemo(() => {
    let errorMessage: string | undefined
    if (!errorMessage) errorMessage = Validate.required(text)
    if (!errorMessage) errorMessage = Validate.maxLength(text, MAX_NAME_LENGTH)
    if (!errorMessage)
      errorMessage = Validate.invalidChar(text, [
        CheckType.ALPHA,
        CheckType.NUMERIC,
        CheckType.PUNCT,
        CheckType.BIGALPHA,
        CheckType.BIGNUMERIC,
        CheckType.BIGPUNCT,
        CheckType.KATAKANA,
        CheckType.HIRAGANA,
        CheckType.JIS_KANJI
      ])
    return {
      isError: !!errorMessage,
      message: errorMessage
    }
  }, [text])
  return result
}

const FormGroup = styled.div`
  display: table;
  padding-bottom: 16px;
  width: 100%;
`

const FormLabel = styled.label`
  display: table-cell;
  width: 45%;
  font-weight: bold;
  vertical-align: middle;
`

const FormInput = styled.div`
  display: table-cell;
  width: 45%;
`

const StyledInput = styled(ValidatableInput)`
  position: relative;
  float: right;
  width: 85%;
`
