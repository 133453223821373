import React from 'react'
import Hint from '~/src/components/molecules/Hint'
import {
  TableRow,
  NameColumn,
  TaxTypeColumn,
  PriceColumn
} from './Common/TableParts'
import Item from './Item'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'

type Props = {
  items: CSVItem[]
}

const VariationList: React.FC<Props> = props => {
  const { items } = props

  return (
    <div>
      <TableRow header>
        <NameColumn>商品/バリエーション</NameColumn>
        <TaxTypeColumn>
          適用税率
          <Hint small position="bottom-left">
            税率を設定できます。
            <br />
            「注文時に選択」は設定できません。
            <br />
            「注文時に選択」の商品はハンディでは10%標準税率が適用されます。
          </Hint>
        </TaxTypeColumn>
        <PriceColumn>価格</PriceColumn>
      </TableRow>
      {items.map(item => (
        <Item key={item.itemId} itemId={item.itemId} />
      ))}
    </div>
  )
}

export default React.memo(VariationList)
