import { AppState } from '~/src/redux/reducer'
import Validate from '~/src/util/Validate'
import { FirstOrderDelayMinutes } from './types'

const selectFirstDrinkDelayMinutes = (
  state: AppState
): FirstOrderDelayMinutes => {
  const firstDrinkDelayMinutes =
    state.optionSetting.waitingStatusSetting.firstDrinkDelayMinutes
  return {
    value: firstDrinkDelayMinutes,
    error:
      Validate.required(firstDrinkDelayMinutes) ||
      Validate.number(firstDrinkDelayMinutes)
  }
}

const selectFirstFoodDelayMinutes = (
  state: AppState
): FirstOrderDelayMinutes => {
  const firstFoodDelayMinutes =
    state.optionSetting.waitingStatusSetting.firstFoodDelayMinutes
  return {
    value: firstFoodDelayMinutes,
    error:
      Validate.required(firstFoodDelayMinutes) ||
      Validate.number(firstFoodDelayMinutes)
  }
}

export const selectWaitingStatusSettingForValidate = (state: AppState) => {
  return {
    ...state.optionSetting.waitingStatusSetting,
    firstDrinkDelayMinutes: selectFirstDrinkDelayMinutes(state),
    firstFoodDelayMinutes: selectFirstFoodDelayMinutes(state)
  }
}

export const selectWaitingStatusSetting = (state: AppState) => {
  const kmSetting = state.api.kmSetting.response.setting

  return {
    isFirstDrinkDelayEnabled: kmSetting.isFirstDrinkDelayEnabled,
    isFirstFoodDelayEnabled: kmSetting.isFirstFoodDelayEnabled,
    isAlertSecondsEnabled: kmSetting.isAlertSecondsEnabled,
    firstDrinkDelayMinutes: kmSetting.firstDrinkDelayMinutes.toString(),
    firstFoodDelayMinutes: kmSetting.firstFoodDelayMinutes.toString()
  }
}
