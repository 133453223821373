import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import { InlineLink } from '~/src/components/atoms/InlineLink'
// @ts-ignore
import DeviceCountExample from '~/assets/images/device_count_example.jpg'
import TableColumn from '~/src/components/atoms/TableColumn'
import TableRow from '~/src/components/atoms/TableRow'
import { useSelector } from 'react-redux'
import { selectInnerExample } from './selectors'

const { Size, Color, URL } = Const

export const InnerExample: React.VFC = () => {
  const { flatRateCount, flatRatePrice, appendedPrice } =
    useSelector(selectInnerExample)
  return (
    <ExampleArea>
      <Border>
        <ExampleContent>
          <Title>料金の計算イメージ（サンプル）</Title>
          <ExampleBody>
            <BodyLeftPart>
              <BodyLeftTable>
                <TableRow>
                  <LeftColumn>
                    月額基本料（定額分{flatRateCount}台込）
                  </LeftColumn>
                  <RightColumn>￥{flatRatePrice}（税込）</RightColumn>
                </TableRow>
                <TableRow>
                  <LeftColumn>月額従量（追加1台につき）</LeftColumn>
                  <RightColumn>￥{appendedPrice}（税込）</RightColumn>
                </TableRow>
              </BodyLeftTable>
            </BodyLeftPart>
            <BodyRightPart>
              <ImageStyle src={DeviceCountExample} />
            </BodyRightPart>
          </ExampleBody>
          <Description>
            ※月額従量は毎月1日00:00から末日23:59の期間中で利用端末台数が最大の日の台数をもとに計算されます。
            <br />
            ※翌月の従量請求対象台数を減らす場合は、当月末日(23:59)までに、【現在の利用台数】を減らしてください。
            <br />
            ※詳しい請求額は、
            <InlineLink
              href={URL.PLF_CONTRACT_INFO(document.URL)}
              target="_blank"
            >
              ご利用明細・契約情報
            </InlineLink>
            をご覧ください。
            <br />
          </Description>
        </ExampleContent>
      </Border>
    </ExampleArea>
  )
}

const ExampleArea = styled.div`
  padding: 32px 0;
`

const Border = styled.div`
  border: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-radius: 8px;
`

const ExampleContent = styled.div`
  padding: 16px;
  font-size: ${Size.FONT.BASE}px;
`

const Title = styled.div`
  font-weight: bold;
  padding: 4px 0;
  text-align: left;
`

const ExampleBody = styled.div`
  padding: 16px 0;
  display: table;
  width: 100%;
`

const BodyLeftPart = styled.div`
  display: table-cell;
`

const BodyLeftTable = styled.div`
  width: 100%;
  color: ${Color.DARK_GRAY_4};
`

const BodyRightPart = styled.div`
  display: table-cell;
  width: 50%;
  vertical-align: top;
  padding: 0 16px;
`
const Description = styled.div`
  padding: 4px 0;
  text-align: left;
  color: ${Color.DARK_GRAY_4};
`

const ImageStyle = styled.img`
  width: 100%;
  vertical-align: top;
`

const LeftColumn = styled(TableColumn)`
  width: 180px;
  padding: 0;
  text-align: left;
`
const RightColumn = styled(TableColumn)`
  width: 115px;
  padding: 0;
  text-align: right;
`
