import { ActionType, getType } from 'typesafe-actions'
import { OtoshiSettingState } from './types'
import { actions } from './actions'
import { arrayMove } from 'react-sortable-hoc'

const initialState = {
  isOtoshiEnabled: false,
  selectedItems: [],
  isEditing: false
}

export const otoshiSetting = (
  state: OtoshiSettingState = initialState,
  action: ActionType<typeof actions>
): OtoshiSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      return {
        ...state,
        isOtoshiEnabled: action.payload.isOtoshiEnabled,
        selectedItems: action.payload.selectedItems,
        isEditing: false
      }
    }
    case getType(actions.edited): {
      return {
        ...state,
        isEditing: true
      }
    }
    case getType(actions.submitSucceeded): {
      return {
        ...state,
        isEditing: false
      }
    }
    case getType(actions.toggleOtoshiEnabled): {
      return {
        ...state,
        isOtoshiEnabled: !state.isOtoshiEnabled,
        isEditing: true
      }
    }
    case getType(actions.selectItemName): {
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload.selectedItem],
        isEditing: true
      }
    }
    case getType(actions.removeItem): {
      return {
        ...state,
        selectedItems: [
          ...state.selectedItems.filter(
            item => item.itemId !== action.payload.itemId
          )
        ],
        isEditing: true
      }
    }
    case getType(actions.removeAllItems): {
      return {
        ...state,
        selectedItems: [],
        isEditing: true
      }
    }
    case getType(actions.sortItem): {
      return {
        ...state,
        selectedItems: arrayMove(
          state.selectedItems,
          action.payload.oldIndex,
          action.payload.newIndex
        ),
        isEditing: true
      }
    }
    default:
      return state
  }
}
