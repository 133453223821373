import * as React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'

const { Color, Size } = Const
type Props = {
  name: string
  isDisabled?: boolean
  isDefaultChecked?: boolean
  value: string
  label: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  checked?: boolean
}

const RadioButton = (props: Props) => {
  return (
    <RadioButtonLabel>
      <StyledRadioButton
        type="radio"
        name={props.name}
        value={props.value}
        disabled={props.isDisabled}
        defaultChecked={props.isDefaultChecked}
        onChange={props.onChange}
        checked={props.checked}
      />
      <RadioButtonInner>{props.label}</RadioButtonInner>
    </RadioButtonLabel>
  )
}

RadioButton.displayName = 'RadioButton'

const RadioButtonLabel = styled.label`
  display: inline-block;
  margin-right: 8px;
  min-width: 14px;
  min-height: 44px;
  position: relative;
  user-select: none;
`

const StyledRadioButton = styled.input`
  background-color: ${Color.GRAY};
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: ${Size.FONT.BASE}px;
  height: 14px;
  left: 9px;
  min-height: 0;
  opacity: 0;
  position: absolute;
  top: 13px;
  width: 14px;
  &:checked {
    background: ${Color.VIVID_BLUE};
    border: none;
    color: ${Color.WHITE};
    font-size: ${Size.FONT.BASE}px;
    left: 9px;
    position: absolute;
    top: 13px;
  }
  &:checked + span {
    background: ${Color.VIVID_BLUE};
    border-color: ${Color.VIVID_BLUE};
    color: ${Color.WHITE};
    &::before {
      background: ${Color.VIVID_BLUE};
      border: 2px solid ${Color.WHITE};
    }
  }
  &:disabled + span {
    opacity: 0.3;
    cursor: default;
  }
`

const RadioButtonInner = styled.span`
  background-color: ${Color.WHITE};
  border-radius: 22px;
  color: ${Color.VERY_DARK_GRAY};
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 11px 12px 11px 26px;
  border: 2px solid ${Color.VERY_LIGHT_GRAY_6};
  min-width: 122px;
  &::before {
    background: ${Color.LIGHT_GRAY_5};
    border: 2px solid transparent;
    border-radius: 8px;
    content: '';
    height: 10px;
    left: 9px;
    position: absolute;
    width: 10px;
  }
`

export { RadioButton }
