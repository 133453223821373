import { AppState } from '~/src/redux/reducer'
import {
  containsHandy,
  containsKM,
  containsKP,
  isHandyKm
} from '~/src/util/ServiceTypeCheck'

export const isHandyPlanSelector = (state: AppState) =>
  containsHandy(state.auth.store!.serviceTypes)

export const isKmPlanSelector = (state: AppState) =>
  containsKM(state.auth.store!.serviceTypes)

export const isKpPlanSelector = (state: AppState) =>
  containsKP(state.auth.store!.serviceTypes)

// KP先会計移行中はServiceTypesだけ見るとHANDY_APPとKM_APP両方持っているが、ハンディかつKMの機能は使えないのでisKpKmOnlyAvailableも見ている
export const isHandyKmNotKpKmOnlySelector = (state: AppState) =>
  isHandyKm(state.auth.store!.serviceTypes) &&
  !state.auth.store!.isKpKmOnlyAvailable
