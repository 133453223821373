import React from 'react'
import TableRow from '~/src/components/atoms/TableRow'
import { RowNumberColumn, UnRegisteredColumn } from './Column'

export default props => {
  const { index } = props
  return (
    <TableRow>
      <RowNumberColumn>{index}</RowNumberColumn>
      <UnRegisteredColumn>
        端末が登録されていません。
        <br />
        お使いの端末でハンディアプリにログインしてください。
      </UnRegisteredColumn>
    </TableRow>
  )
}
