import React from 'react'
import { useSelector } from 'react-redux'
import Message from '~/src/components/atoms/Message'
import Const from '~/src/const'
import styled from 'styled-components'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { selectErrors, selectSelectedItems } from './selectors'
import { ITEM_COUNT_LIMIT } from './const'
const { URL } = Const

type Props = {
  itemId?: string
}

export const Messages: React.VFC<Props> = props => {
  const {
    hasErrorExceededLimit,
    hasDeletedItem,
    hasVariation,
    hasEmptyCategory,
    hasTaxFree
  } = useSelector(selectErrors)
  const selectedItems = useSelector(selectSelectedItems)
  const limitExceededCount = selectedItems.length - ITEM_COUNT_LIMIT
  const warningMessageList = []
  if (hasErrorExceededLimit) {
    warningMessageList.push(
      `お通し商品に登録できるのは${ITEM_COUNT_LIMIT}点までです。あと${limitExceededCount}点解除してください。`
    )
  }
  if (hasDeletedItem) {
    warningMessageList.push(
      '削除された商品が含まれています。解除してください。'
    )
  }
  if (hasVariation) {
    warningMessageList.push(
      <>
        バリエーションが設定されている商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (hasTaxFree) {
    warningMessageList.push(
      <>
        税設定が「非課税」の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (hasEmptyCategory) {
    warningMessageList.push(
      <>
        カテゴリーが未設定の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  return (
    (warningMessageList.length !== 0 && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`
