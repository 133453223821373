import { AppState } from '~/src/redux/reducer'
import Validate from '~/src/util/Validate'

export const selectHasError = (state: AppState) => {
  const firstDrinkDelayMinutes =
    state.optionSetting.waitingStatusSetting.firstDrinkDelayMinutes
  const firstFoodDelayMinutes =
    state.optionSetting.waitingStatusSetting.firstFoodDelayMinutes
  const error =
    Validate.required(firstDrinkDelayMinutes) ||
    Validate.number(firstDrinkDelayMinutes) ||
    Validate.required(firstFoodDelayMinutes) ||
    Validate.number(firstFoodDelayMinutes)
  return !!error
}

export const selectIsEditing = (state: AppState) => {
  return state.optionSetting.optionSetting.isEditing
}

export const selectIsSplashShowing = (state: AppState) =>
  !(state.api.kmSetting.fetched || state.api.kmSetting.failed)
