import React from 'react'
import TableRow from '~/src/components/atoms/TableRow'

import Toggle from '~/src/components/atoms/Toggle'

import {
  TitleColumn,
  ToggleColumn
} from '~/src/components/pages/PrintTarget/Column'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'
import { Printer, PrinterByItemSeatSet, PrintTargetItem } from '../types'
import { PrinterSelectBoxes } from './PrinterSelectBoxes/PrinterSelectBoxes'

type Props = {
  item: PrintTargetItem
  seatSets: SeatSet[]
  printers: Printer[]
  printerByItemSeatSets: PrinterByItemSeatSet[]
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Row: React.VFC<Props> = ({
  item,
  seatSets,
  printers,
  printerByItemSeatSets,
  onToggle
}) => {
  return (
    <TableRow>
      <ToggleColumn>
        <Toggle
          onToggle={onToggle}
          name={item.itemId}
          checked={item.isItemSeatSetSetting}
          disabled={false}
        />
      </ToggleColumn>
      <TitleColumn>
        {item.name}
        {!item.isDisplayed && '(非表示)'}
      </TitleColumn>
      <PrinterSelectBoxes
        seatSets={seatSets}
        printers={printers}
        item={item}
        printerByItemSeatSets={printerByItemSeatSets}
      />
    </TableRow>
  )
}
