import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { submitKitchenPosition } from '~/src/redux/modules/KitchenPositionSetting/KitchenPositionManagement'

export const useHandleSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(submitKitchenPosition())
  }, [dispatch])
}
