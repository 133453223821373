import { createAction } from 'typesafe-actions'
import { KmSettingApiResponse } from '~/src/api/km/settings/types'

// Action types
const TOGGLE_COOKING_NOTICE_ENABLED =
  'oes/TargetServingTime/KmSetting/TOGGLE_COOKING_NOTICE_ENABLED'
const SELECT_ORDER_TICKET_SORT_TYPE =
  'oes/TargetServingTime/KmSetting/SELECT_ORDER_TICKET_SORT_TYPE'
const CLEAR_STATE = 'oes/TargetServingTime/KmSetting/CLEAR_STATE'
const INITIALIZE = 'oes/TargetServingTime/KmSetting/INITIALIZE'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(
    INITIALIZE,
    (kmSettingApiResponse: KmSettingApiResponse) => ({ kmSettingApiResponse })
  )(),
  toggleCookingNoticeEnabled: createAction(TOGGLE_COOKING_NOTICE_ENABLED)(),
  selectOrderTicketSortType: createAction(
    SELECT_ORDER_TICKET_SORT_TYPE,
    (selectedOrderTicketSortTypeId: string) => ({
      selectedOrderTicketSortTypeId
    })
  )()
}

export const actionTypes = {
  TOGGLE_COOKING_NOTICE_ENABLED,
  SELECT_ORDER_TICKET_SORT_TYPE,
  CLEAR_STATE,
  INITIALIZE
}
