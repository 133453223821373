import { ActionType, getType } from 'typesafe-actions'
import Const from '~/src/const'
import { KmSettingApiState } from './types'
import { actions } from './actions'

const { OrderTicketSortType } = Const

export const initialState = {
  response: {
    setting: {
      isCookingNoticeEnabled: true,
      orderTicketSortType: OrderTicketSortType.BY_SELECTED_AT.ID,
      isFirstDrinkDelayEnabled: true,
      firstDrinkDelayMinutes: 5,
      isFirstFoodDelayEnabled: true,
      firstFoodDelayMinutes: 20,
      isAlertSecondsEnabled: false,
      version: 0
    }
  },
  fetched: false,
  failed: false
}

// FIXME: 命名規約
export const kmSettingApi = (
  state: KmSettingApiState = initialState,
  action: ActionType<typeof actions>
): KmSettingApiState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return {
        ...initialState
      }
    }
    case getType(actions.fetchSucceeded): {
      return {
        ...state,
        ...action.payload.kmSetting
      }
    }
    case getType(actions.fetchFailed): {
      return {
        ...state,
        failed: true
      }
    }
    case getType(actions.saveSucceeded): {
      return {
        ...state,
        ...action.payload.kmSetting
      }
    }
    default:
      return state
  }
}
