import React, { useCallback } from 'react'
import useInteractors from '~/src/hooks/useInteractors'
import styled from 'styled-components'
import { AppState } from '~/src/redux/reducer'
import { useSelector } from 'react-redux'
import { Checkbox as AirKitCheckbox } from '@air-kit/air-kit'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'
import { Set } from 'immutable'
import {
  SelectedItemError,
  ItemError
} from '~/src/redux/modules/Topping/ItemByToppingGroup/types'
import {
  ItemRow,
  CheckboxColumn,
  NameColumn,
  IconColumn
} from '~/src/components/pages/Topping/ItemByToppingGroup/CategoryList/Common/TableParts'
import { ExclamationIcon } from '~/src/components/atoms/Icon'

type Props = {
  itemId: string
  isSelected: boolean
}

export const Item: React.VFC<Props> = React.memo(({ itemId, isSelected }) => {
  const item = useSelector((state: AppState) =>
    state.itemByToppingGroup.items.get(itemId)
  )!
  const itemErrors = useSelector((state: AppState) =>
    state.itemByToppingGroup.itemErrors.get(itemId)
  )!
  const selectedItemErrors = useSelector((state: AppState) =>
    state.itemByToppingGroup.selectedItemErrors.get(itemId)
  )!

  const interactor = useInteractors().itemByToppingGroupInteractor

  const handleClickCheckBox = useCallback(() => {
    if (!isSelected) {
      interactor.addItem(item.itemId)
    } else {
      interactor.removeItem(item.itemId)
    }
  }, [isSelected, interactor, item.itemId])

  const needsIcon: boolean = !!selectedItemErrors
  const disable: boolean = !!itemErrors && !selectedItemErrors

  return (
    <ItemRow key={itemId}>
      <NameLabel>
        <CheckboxColumn>
          <Checkbox
            isChecked={isSelected}
            onChange={handleClickCheckBox}
            isDisabled={disable}
          />
        </CheckboxColumn>
        {needsIcon && (
          <IconColumn>
            <ExclamationIcon color="red" />
          </IconColumn>
        )}
        <NameColumn disabled={disable} error={needsIcon}>
          {generateDisplayName(item, itemErrors, selectedItemErrors)}
        </NameColumn>
      </NameLabel>
    </ItemRow>
  )
})
const computeExistsError = (errors?: Set<SelectedItemError>) =>
  errors ? !errors.isEmpty() : false

const generateDisplayName = (
  item: SimpleItemForTopping,
  itemErrors?: Set<ItemError>,
  selectedItemErrors?: Set<SelectedItemError>
) => {
  let info = ''
  if (itemErrors?.contains('NO_CATEGORY')) {
    info += '(カテゴリー未設定)'
  }
  if (item.hasSku) {
    info += '(バリエーションあり)'
  }
  if (itemErrors?.contains('TAX_FREE')) {
    info += '(税設定:非課税)'
  }
  if (!item.isDisplayed) {
    info += '(非表示)'
  }
  const nameWithInfo: string = info ? `${item.itemName} ${info}` : item.itemName
  return computeExistsError(selectedItemErrors)
    ? `[${nameWithInfo}]`
    : nameWithInfo
}

const Checkbox = styled(AirKitCheckbox)`
  > span {
    display: none;
  }
`

const NameLabel = styled.label`
  display: table-cell;
  cursor: pointer;
`
