import { Dispatch } from 'redux'
import { Set } from 'immutable'

import Const from '~/src/const'
import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { actions } from '~/src/redux/modules/Topping/ItemByToppingGroup/actions'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'

const { API, Toast } = Const

export class ItemByToppingGroupInteractor {
  private readonly dispatch: Dispatch
  private readonly apiClient: ApiClientInterface

  constructor(dispatch: Dispatch, apiClient: ApiClientInterface = ApiClient) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  async fetchInitialData(toppingGroupId: string) {
    this.dispatch(setLoading())
    await this.apiClient
      .get(API.GET_ITEMS)
      .then(result => {
        const itemsResponse: ApiResponse<ItemsApiResponse> = result.data
        this.dispatch(
          actions.fetchInitialDataSucceeded(itemsResponse, toppingGroupId)
        )
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  addItem(itemId: string) {
    this.dispatch(actions.addItem(itemId))
  }

  removeItem(itemId: string) {
    this.dispatch(actions.removeItem(itemId))
  }

  addAll(itemIds: Set<string>) {
    this.dispatch(actions.addAll(itemIds))
  }

  removeAll(itemIds: Set<string>) {
    this.dispatch(actions.removeAll(itemIds))
  }

  async save(toppingGroupId: string, selectedItemIds: Set<string>) {
    const params = {
      toppingGroupId: toppingGroupId,
      itemIds: selectedItemIds
    }
    this.dispatch(setLoading())
    await this.apiClient
      .post(API.POST_ITEM_BY_TOPPING_GROUPS, params)
      .then(() => {
        this.dispatch(actions.saveSucceeded())
        // @ts-ignore
        this.dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }
}
