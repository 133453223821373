import React from 'react'
import styled from 'styled-components'
import { Checkbox } from '@air-kit/air-kit'
import TableRow from '~/src/components/atoms/TableRow'
import { CheckboxColumn, CategoryColumn, PrintersColumn } from '../Column'
import { useSelector } from 'react-redux'
import { selectRow } from './selectors'
import { useCheckCategory } from './hooks'
import { SimpleCategory } from '~/src/redux/models/Category/Category'

type Props = {
  category: SimpleCategory
}

export const Row: React.VFC<Props> = ({ category }) => {
  const { isChecked, printerNames } = useSelector(
    selectRow(category.categoryId)
  )
  const handleCheckCategory = useCheckCategory(category.categoryId, !isChecked)

  return (
    <StyledTableRow>
      <StyledLabel>
        <CheckboxColumn>
          <StyledCheckbox
            name="checkbox-category"
            isChecked={isChecked}
            onChange={handleCheckCategory}
          />
        </CheckboxColumn>
        <CategoryColumn>{category.categoryName}</CategoryColumn>
        <PrintersColumn>{printerNames}</PrintersColumn>
      </StyledLabel>
    </StyledTableRow>
  )
}

const StyledTableRow = styled(TableRow)`
  padding: 0;
`

const StyledLabel = styled.label`
  display: table;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 16px 0;
`

const StyledCheckbox = styled(Checkbox)`
  > span {
    display: none;
  }
`
