import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Icon from '~/src/components/atoms/Icon'
import { useSelector } from 'react-redux'
import { OnboardingButton } from '~/src/common/organisms/OnboardingButton'
import { SpoUpSellButton } from '~/src/common/organisms/UpSellButton'
import { selectIsShowSpoUpSellBanner } from '~/src/components/organisms/Header/selectors'

const { Color, Size, Weight, ZIndex } = Const

export const OESHeader: React.VFC = () => {
  const isDisplay: boolean = useSelector(selectIsShowSpoUpSellBanner)
  return (
    <Wrapper>
      <Icon name="logo" />
      <RightContainer>
        {isDisplay && <SpoUpSellButton />}
        <OnboardingButton />
      </RightContainer>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  flex: 0 0 ${Size.HEADER_HEIGHT}px;
  padding: 0 0 0 18px;
  width: 100%;
  height: ${Size.HEADER_HEIGHT}px;
  color: ${Color.WHITE};
  background-color: ${Color.VIVID_BLUE};
  font-size: 20px;
  font-weight: ${Weight.BOLD};
  display: flex;
  align-items: center;
  z-index: ${ZIndex.AIR_OES_HEADER};
`

const RightContainer = styled.div`
  display: flex;
  margin-left: auto;
`
