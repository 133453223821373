import React, { useEffect } from 'react'
import AuthInteractor from '~/src/interactors/Auth/AuthInteractor'
import { ApiErrorModal } from '~/src/components/organisms/Modal/ErrorModal/ApiErrorModal/ApiErrorModal'

type Props = {
  hasAuth: boolean
  interactor: AuthInteractor
}
/**
 * 認証情報を確認する。
 * 認証がない場合はサーバに認証情報を問い合わせ、子コンポーネントは表示しない。
 * 認証されている場合は子コンポーネントを表示する。
 */
const AuthFilter: React.FC<Props> = ({ hasAuth, interactor, children }) => {
  useEffect(() => {
    if (!hasAuth) {
      interactor.auth()
    }
  }, [hasAuth, interactor])
  // @ts-ignore
  return hasAuth ? <>{children}</> : <ApiErrorModal />
}

export default React.memo(AuthFilter)
