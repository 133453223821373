import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setShouldForward } from '~/src/redux/modules/Forward'
import {
  clearBatchSelectState,
  clearPrinterByCategorySeatSetsState,
  setBatchSelectSeatSet
} from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useInitialize = (targetSeatSetId: string) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setBatchSelectSeatSet(targetSeatSetId))
  }, [dispatch, targetSeatSetId])
}

export const useDeinit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearBatchSelectState())
  }, [dispatch])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearPrinterByCategorySeatSetsState())
  }, [dispatch])
}

export const useCloseNotFoundErrorModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setShouldForward(true))
  }, [dispatch])
}
