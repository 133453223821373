import React from 'react'
import { AppState } from '~/src/redux/reducer'
import { useSelector } from 'react-redux'
import useInteractors from '~/src/hooks/useInteractors'
import { InnerList } from './InnerList'

const useTopping = () =>
  useSelector((state: AppState) => ({
    toppingGroupIds: state.toppingList.toppingGroupIds
  }))

const useInteractor = () => ({
  interactor: useInteractors().toppingListInteractor
})

const InnerListContiner = () => (
  <InnerList {...useInteractor()} {...useTopping()} />
)

export { InnerListContiner as InnerList }
