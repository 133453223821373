import Const from '~/src/const'
import APIClient from '~/src/util/APIClient'
import Validate, { CheckType } from '~/src/util/Validate'
import { handleError } from '../ApiError'
import {
  setLoading,
  clearLoading,
  showAndBlurToast,
  closeDeleteConfirmModal
} from '../UI'
import { setShouldForward } from '../Forward'
import { arrayMove } from '~/src/util/ArrayUtils'
import { SeatsApiResponse, SeatSetState } from './types'
import { AppState } from '../../reducer'
import { ApiResponse } from '~/src/api/types'
const { API, Toast } = Const
const {
  ALPHA,
  NUMERIC,
  PUNCT,
  BIGALPHA,
  BIGNUMERIC,
  BIGPUNCT,
  KATAKANA,
  HIRAGANA,
  JIS_KANJI
} = CheckType

const CLEAR_SEAT_SET_STATE = 'CLEAR_SEAT_SET_STATE'
const FETCH_SEAT_SET_SUCCESS = 'FETCH_SEAT_SET_SUCCESS'
const VIEW_SEAT_SET_NAME_CHANGE = 'VIEW_SEAT_SET_NAME_CHANGE'
const VIEW_SEAT_SET_SEAT_SELECT = 'VIEW_SEAT_SET_SEAT_SELECT'
const MERGE_SEAT_SET_SUCCESS = 'MERGE_SEAT_SET_SUCCESS'
const DELETE_SEAT_SET_SUCCESS = 'DELETE_SEAT_SET_SUCCESS'
const VIEW_SEAT_SET_SORT = 'VIEW_SEAT_SET_SORT'
const UPDATE_SEAT_SET_SUCCESS = 'UPDATE_SEAT_SET_SUCCESS'
const HANDLE_UNREGISTERED_SEAT_SET_ERROR = 'HANDLE_UNREGISTERED_SEAT_SET_ERROR'

// Actions
export const clearSeatSetState = () => ({
  type: CLEAR_SEAT_SET_STATE
})

export const fetchSeatSet = (param?: { id: string; isUpdate: boolean }) => {
  // @ts-ignore
  return dispatch => {
    return APIClient.get(API.GET_SEATS)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(json => dispatch(fetchSeatSetSuccess(json, param)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const fetchSeatSetSuccess = (
  response: ApiResponse<SeatsApiResponse>,
  param?: { id: string; isUpdate: boolean }
) => {
  let seats = response.result.seats
    ? response.result.seats.map(child => child)
    : []

  let seatSets = response.result.seatSets
    ? response.result.seatSets.map(child => child)
    : []
  let seatSetBySeats = response.result.seatSetBySeats
    ? response.result.seatSetBySeats.map(child => child)
    : []

  seatSets = seatSets.sort((a, b) => a.displayOrder - b.displayOrder)
  seats = seats
    .filter(seat => seat.isDisplayed)
    .sort((a, b) => a.displayOrder - b.displayOrder)

  // 注文エリア詳細設定画面での更新用stateを作成
  let editSeatSet
  if (param && param.isUpdate) {
    const editSeatSetId = param.id

    editSeatSet = {
      seatSet: seatSets.find(item => item.seatSetId === editSeatSetId),
      seatIds: seatSetBySeats
        .filter(item => item.seatSetId === editSeatSetId)
        .map(item => item.seatId)
    }

    if (!editSeatSet.seatSet) {
      // 存在しない（既に削除済み）注文エリアだった場合はエラーモーダル表示
      return handleNotFoundSeatSetError()
    }

    // 編集中のSeatSetに関連するデータはマスタデータから除外する
    seatSets = seatSets.filter(item => item.seatSetId !== editSeatSetId)
    seatSetBySeats = seatSetBySeats.filter(
      item => item.seatSetId !== editSeatSetId
    )
  }

  return {
    type: FETCH_SEAT_SET_SUCCESS,
    seats: seats,
    seatSets: seatSets,
    seatSetBySeats: seatSetBySeats,
    isEditing: false,
    isUpdate: param && param.isUpdate,
    editSeatSet: editSeatSet
  }
}

export const viewSeatSetNameChanged = (param: { newValue: string }) => ({
  type: VIEW_SEAT_SET_NAME_CHANGE,
  value: param.newValue,
  isEditing: true
})

export const viewSeatSetSeatSelect = (param: {
  seatId: string
  checked: boolean
}) => ({
  type: VIEW_SEAT_SET_SEAT_SELECT,
  seatId: param.seatId,
  checked: param.checked,
  isEditing: true
})

export const viewSeatSetSort = (param: {
  oldIndex: number
  newIndex: number
}) => ({
  type: VIEW_SEAT_SET_SORT,
  oldIndex: param.oldIndex,
  newIndex: param.newIndex,
  isEditing: true
})

export const updateSeatSet = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const state: AppState = getState()
    const seatSets = state.seatSet.seatSets.map((item, index) => ({
      seatSetId: item.seatSetId,
      name: item.name,
      displayOrder: index + 1,
      version: item.version
    }))
    const request = { seatSets: seatSets }
    return APIClient.post(API.UPDATE_SEAT_SET, request)
      .then(dispatch(setLoading()))
      .then(response => response.data)
      .then(response => dispatch(updateSeatSetSuccess(response)))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const updateSeatSetSuccess = (response: ApiResponse<SeatsApiResponse>) => {
  const seatSets = response.result.seatSets
    ? response.result.seatSets
        .map(child => child)
        .sort((a, b) => a.displayOrder - b.displayOrder)
    : []
  return {
    type: UPDATE_SEAT_SET_SUCCESS,
    seatSets: seatSets,
    isEditing: false
  }
}

export const postSeatSet = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const json = getState().seatSet.editSeatSet
    return APIClient.post(API.POST_SEAT_SETS_MERGE, json)
      .then(dispatch(setLoading()))
      .then(() => dispatch(mergeSeatSetSuccess()))
      .then(() => dispatch(setShouldForward(true)))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const mergeSeatSetSuccess = () => ({
  type: MERGE_SEAT_SET_SUCCESS
})

export const deleteSeatSet = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const editSeatSet = getState().seatSet.editSeatSet
    const json = {
      seatSet: {
        seatSetId: editSeatSet.seatSet.seatSetId,
        version: editSeatSet.seatSet.version
      }
    }
    return APIClient.post(API.POST_SEAT_SETS_DELETE, json)
      .then(() => dispatch(closeDeleteConfirmModal()))
      .then(() => dispatch(setLoading()))
      .then(() => dispatch(deleteSeatSetSuccess()))
      .then(() => dispatch(setShouldForward(true)))
      .then(() => dispatch(showAndBlurToast(Toast.DELETED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const deleteSeatSetSuccess = () => ({
  type: DELETE_SEAT_SET_SUCCESS
})

const handleNotFoundSeatSetError = () => ({
  type: HANDLE_UNREGISTERED_SEAT_SET_ERROR
})

const initialState: SeatSetState = {
  seats: [],
  seatSets: [],
  seatSetBySeats: [],
  editSeatSet: {
    seatSet: {
      seatSetId: undefined,
      name: '',
      version: undefined,
      nameError: undefined
    },
    seatIds: []
  },
  isEditing: false,
  isUpdate: false,
  isNotFound: false
}

// Reducer
export default (state = initialState, action: any): SeatSetState => {
  switch (action.type) {
    case CLEAR_SEAT_SET_STATE:
      return initialState
    case FETCH_SEAT_SET_SUCCESS:
      return {
        ...state,
        seats: action.seats,
        seatSets: action.seatSets,
        seatSetBySeats: action.seatSetBySeats,
        isEditing: action.isEditing,
        isUpdate: action.isUpdate,
        editSeatSet: action.editSeatSet ? action.editSeatSet : state.editSeatSet
      }
    case VIEW_SEAT_SET_NAME_CHANGE:
      return {
        ...state,
        isEditing: action.isEditing,
        editSeatSet: {
          ...state.editSeatSet,
          seatSet: {
            ...state.editSeatSet.seatSet,
            name: action.value,
            nameError: validateSeatSetName(action.value)
          }
        }
      }
    case VIEW_SEAT_SET_SEAT_SELECT:
      return {
        ...state,
        isEditing: action.isEditing,
        editSeatSet: {
          ...state.editSeatSet,
          seatSet: {
            ...state.editSeatSet.seatSet,
            nameError: validateSeatSetName(state.editSeatSet.seatSet.name)
          },
          seatIds: action.checked
            ? [...state.editSeatSet.seatIds, action.seatId]
            : state.editSeatSet.seatIds.filter(item => item !== action.seatId)
        }
      }
    case VIEW_SEAT_SET_SORT:
      return {
        ...state,
        seatSets: arrayMove(state.seatSets, action.oldIndex, action.newIndex),
        isEditing: action.isEditing
      }
    case UPDATE_SEAT_SET_SUCCESS:
      return {
        ...state,
        seatSets: action.seatSets,
        isEditing: action.isEditing
      }
    case MERGE_SEAT_SET_SUCCESS:
      return {
        ...state,
        isEditing: false
      }
    case DELETE_SEAT_SET_SUCCESS:
      return {
        ...state,
        isEditing: false
      }
    case HANDLE_UNREGISTERED_SEAT_SET_ERROR:
      return {
        ...state,
        isNotFound: true
      }
    default:
      return state
  }
}

const validateSeatSetName = (seatSetName: string) => {
  let seatSetNameError
  if (!seatSetNameError) seatSetNameError = Validate.required(seatSetName)
  if (!seatSetNameError) seatSetNameError = Validate.maxLength(seatSetName, 10)
  if (!seatSetNameError) {
    seatSetNameError = Validate.invalidChar(seatSetName, [
      ALPHA,
      NUMERIC,
      PUNCT,
      BIGALPHA,
      BIGNUMERIC,
      BIGPUNCT,
      KATAKANA,
      HIRAGANA,
      JIS_KANJI
    ])
  }

  return seatSetNameError
}
