import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'
import { HodaiSettingState } from './types'

const initialState: HodaiSettingState = {
  selectedHodai: undefined,
  categories: [],
  lastOrderSetting: {
    lastOrderSetMode: 'manual'
  },
  isSelectingHodaiPlan: false,
  isEditing: false
}

export const hodaiSetting = (
  state: HodaiSettingState = initialState,
  action: ActionType<typeof actions>
): HodaiSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      return {
        ...state,
        categories: action.payload.categories,
        lastOrderSetting: {
          lastOrderMinutes: action.payload.lastOrderMinutes,
          lastOrderSetMode: action.payload.lastOrderMinutes ? 'auto' : 'manual'
        }
      }
    }
    case getType(actions.openHodaiSelect): {
      return {
        ...state,
        isSelectingHodaiPlan: true
      }
    }
    case getType(actions.closeHodaiSelect): {
      return {
        ...state,
        isSelectingHodaiPlan: false
      }
    }
    case getType(actions.submitHodaiSelect): {
      // 放題は初期化時に選択内容をstateに持たせるため、その後の変更はreducer側で行う
      const categories = state.categories.map(category => {
        const items = category.items.map(item => {
          const isTargetItem = item.itemId === action.payload.item.itemId
          // 選択した商品を放題子にできないようにする
          if (isTargetItem) {
            return {
              ...item,
              disabled: true
            }
          }
          // 前回選択していた商品を放題子に選択できるようにする
          const isOldItem = item.itemId === state.selectedHodai?.itemId
          if (isOldItem) {
            return {
              ...item,
              disabled: false
            }
          }
          return item
        })
        return {
          ...category,
          items
        }
      })

      return {
        ...state,
        categories,
        selectedHodai: {
          ...action.payload.item,
          isDeleted: false // 編集画面から削除済み商品が選択されることはないためfalse固定
        },
        isSelectingHodaiPlan: false,
        isEditing: true
      }
    }
    case getType(actions.selectCategory): {
      const categories = state.categories.map(category => {
        if (category.categoryId !== action.payload.categoryId) {
          return category
        }
        const items = category.items.map(item => {
          if (item.disabled) {
            return item
          }
          return {
            ...item,
            isSelected: true
          }
        })
        return {
          ...category,
          items
        }
      })
      return {
        ...state,
        categories,
        isEditing: true
      }
    }
    case getType(actions.selectCategoryItem): {
      const categories = state.categories.map(category => {
        if (category.categoryId !== action.payload.categoryId) {
          return category
        }

        const items = category.items.map(item => {
          if (item.itemId !== action.payload.itemId) {
            return item
          }
          return {
            ...item,
            isSelected: true
          }
        })
        return {
          ...category,
          items
        }
      })
      return {
        ...state,
        categories,
        isEditing: true
      }
    }
    case getType(actions.releaseCategoryItem): {
      const categories = state.categories.map(category => {
        if (category.categoryId !== action.payload.categoryId) {
          return category
        }

        const items = category.items.map(item => {
          if (item.itemId !== action.payload.itemId) {
            return item
          }
          return {
            ...item,
            isSelected: false
          }
        })
        return {
          ...category,
          items
        }
      })
      return {
        ...state,
        categories,
        isEditing: true
      }
    }
    case getType(actions.releaseCategory): {
      const categories = state.categories.map(category => {
        if (category.categoryId !== action.payload.categoryId) {
          return category
        }

        const items = category.items.map(item => {
          return {
            ...item,
            isSelected: false
          }
        })
        return {
          ...category,
          items
        }
      })
      return {
        ...state,
        categories,
        isEditing: true
      }
    }
    case getType(actions.releaseAll): {
      const categories = state.categories.map(category => {
        const items = category.items.map(item => {
          return {
            ...item,
            isSelected: false
          }
        })
        return {
          ...category,
          items
        }
      })
      return {
        ...state,
        categories,
        isEditing: true
      }
    }
    case getType(actions.updateLastOrderMinutes): {
      const input = action.payload.lastOrderMinutes
      if (input === '') {
        return {
          ...state,
          isEditing: true,
          lastOrderSetting: {
            ...state.lastOrderSetting,
            lastOrderMinutes: undefined
          }
        }
      }

      const lastOrderMinutes = Number.parseInt(input, 10)
      if (Number.isNaN(lastOrderMinutes)) {
        return {
          ...state,
          isEditing: true,
          lastOrderSetting: {
            ...state.lastOrderSetting
          }
        }
      }

      return {
        ...state,
        isEditing: true,
        lastOrderSetting: {
          ...state.lastOrderSetting,
          lastOrderMinutes
        }
      }
    }
    case getType(actions.updateLastOrderSetMode): {
      return {
        ...state,
        isEditing: true,
        lastOrderSetting: {
          ...state.lastOrderSetting,
          lastOrderSetMode: action.payload.lastOrderSetMode
        }
      }
    }
    case getType(actions.updateLastOrderIsComposing): {
      return {
        ...state,
        isEditing: true,
        lastOrderSetting: {
          ...state.lastOrderSetting,
          lastOrderIsComposing: action.payload.lastOrderIsComposing
        }
      }
    }
    default:
      return state
  }
}
