import React from 'react'

import ModalFooter from './ModalFooter'
import useInteractors from '~/src/hooks/useInteractors'

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const ModalFooterContainer = () => {
  return <ModalFooter {...useInteractor()} />
}

export default ModalFooterContainer
