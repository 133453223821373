import React from 'react'
import styled from 'styled-components'
import ColorDot from '~/src/components/atoms/ColorDot'
import {
  TableRow,
  ItemNameColumn,
  SettingMethodSelectColumn,
  NoticeSecondColumn,
  AlertSecondColumn
} from '../ItemList/Common/TableParts' // TablePartsをItemListから出したほうが良さそう
import Const from '~/src/const'

const { Color } = Const

const HeaderRow: React.FC = () => {
  return (
    <TableRow header>
      <ItemNameColumn />
      <SettingMethodSelectColumn>設定方法</SettingMethodSelectColumn>
      <NoticeSecondColumn>
        注意時間
        <StyledDot color={Color.LIGHT_ORANGE} />
      </NoticeSecondColumn>
      <AlertSecondColumn>
        警告時間
        <StyledDot color={Color.MODERATE_RED} />
      </AlertSecondColumn>
    </TableRow>
  )
}

// マイナスを使うのは良くないが、この指定がないと中心からずれる
const StyledDot = styled(ColorDot)`
  margin: -2px 5px;
`

export default React.memo(HeaderRow)
