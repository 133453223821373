import React, { useMemo, useEffect, ReactElement } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Const from '~/src/const'

import { ModalPortal } from '~/src/components/organisms/ModalContainer/ModalContainer'
import Breadcrumbs, { Breadcrumb } from '~/src/components/molecules/Breadcrumbs'
import PageDescription from '~/src/components/molecules/PageDescription'
import Splash from '~/src/components/atoms/Splash'
import GrantErrorModal from '~/src/components/organisms/Modal/ErrorModal/GrantErrorModal'
import ServicePlanErrorModal from '~/src/components/organisms/Modal/ErrorModal/ServicePlanErrorModal'
import NoContractErrorModal from '~/src/components/organisms/Modal/ErrorModal/NoContractErrorModal'
import { SpoUpSellErrorModal } from '~/src/components/organisms/Modal/ErrorModal/SpoUpSellErrorModal'

const { Color, Weight } = Const

export type PageTemplateProps = {
  breadcrumbs: Breadcrumb[]
  description?: string[]
  isSplashShowing?: boolean
  initializer?: React.EffectCallback
  footer?: ReactElement
  modal?: ReactElement
  displayKM?: boolean
  displayKP?: boolean
  displayKMOnly?: boolean
  displayTakeOut?: boolean
  displayMangerOnly?: boolean
  displayContractedOnly?: boolean
  displaySpoPromotion?: boolean
}

type Props = {
  breadcrumbs: Breadcrumb[]
  description?: string[]
  isSplashShowing?: boolean
  initializer?: () => void
  footer?: ReactElement
  modal?: ReactElement | false
  displayKM?: boolean
  displayKP?: boolean
  displayKMOnly?: boolean
  displayTakeOut?: boolean
  displayMangerOnly?: boolean
  displayContractedOnly?: boolean
  displaySpoPromotion?: boolean
  isKM: boolean
  isKP: boolean
  isKMOnly: boolean
  isTakeOut: boolean
  isTrial: boolean
  isManager: boolean
  isSpo: boolean
  isHandy: boolean
}

const PageTemplate: React.FC<Props> = props => {
  const {
    children,
    breadcrumbs = [{ title: 'TODO' }],
    description,
    isSplashShowing = false,
    initializer,
    footer,
    modal,
    displayKM,
    displayKP,
    displayKMOnly,
    displayTakeOut,
    displayMangerOnly,
    displayContractedOnly,
    displaySpoPromotion,
    isKM,
    isKP,
    isKMOnly,
    isTakeOut,
    isTrial,
    isManager,
    isSpo,
    isHandy
  } = props

  const pageName: string = useMemo(() => {
    return breadcrumbs[0].title
  }, [breadcrumbs])
  const title = `${pageName} | Airレジ オーダー`

  if (displayContractedOnly && isTrial) {
    return <NoContractErrorModal />
  }
  if (displayMangerOnly && !isManager) {
    return <GrantErrorModal />
  }
  if (displayKM || displayKP || displayKMOnly || displayTakeOut) {
    // 表示プラン指定がある場合はチェック対象
    if (
      !(displayKM && isKM) &&
      !(displayKP && isKP) &&
      !(displayKMOnly && isKMOnly) &&
      !(displayTakeOut && isTakeOut)
    ) {
      return <ServicePlanErrorModal pageName={pageName} />
    }
  }
  // 「お試し店舗またはSPO店舗またはhandy店舗ではない」店舗からアクセスされた時にエラーモーダルを表示する
  if (displaySpoPromotion) {
    if (isTrial || isSpo || !isHandy) {
      return <SpoUpSellErrorModal />
    }
  }
  // 対象プラン外の場合にAPIをリクエストしないようにここで処理をする
  if (initializer) {
    // TODO initializerをPageTemplateに持たせるべきか検討
    // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
    useEffect(initializer!, [])
  }

  return (
    <>
      <Helmet title={title} />
      <PageArea>
        <PageHeader>
          <PageTitle>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </PageTitle>
          {description && <PageDescription list={description} />}
        </PageHeader>
        <Scrollable>
          {!isSplashShowing && children}
          <Splash isOpen={isSplashShowing} />
        </Scrollable>
        {!isSplashShowing && footer}
      </PageArea>
      {modal && <ModalPortal>{modal}</ModalPortal>}
    </>
  )
}

const SIDE_PADDING = 28

const PageArea = styled.div`
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`

const PageHeader = styled.div`
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;
  padding: 12px ${SIDE_PADDING}px 6px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  overflow-wrap: break-word;
`

const PageTitle = styled.div`
  font-size: 24px;
  padding: 6px 0;
  color: ${Color.VERY_DARK_GRAY_2};
  font-weight: ${Weight.BOLD};
`

const Scrollable = styled.div`
  position: relative;
  flex: 1 1 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 24px ${SIDE_PADDING}px 0 ${SIDE_PADDING}px;
`

export default React.memo(PageTemplate)
