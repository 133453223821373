import { createAction } from 'typesafe-actions'
import { WaitingStatusState } from './types'

const CLEAR_STATE = 'oes/TargetServingTime/WaitingStatusSetting/CLEAR_STATE'
const INITIALIZE = 'oes/TargetServingTime/WaitingStatusSetting/INITIALIZE'
const TOGGLE_FIRST_DRINK_DELAY =
  'oes/TargetServingTime/WaitingStatusSetting/TOGGLE_FIRST_DRINK_DELAY'
const SET_FIRST_DRINK_DELAY_MINUTES =
  'oes/TargetServingTime/WaitingStatusSetting/SET_FIRST_DRINK_DELAY_MINUTES'
const TOGGLE_FIRST_FOOD_DELAY =
  'oes/TargetServingTime/WaitingStatusSetting/TOGGLE_FIRST_FOOD_DELAY'
const SET_FIRST_FOOD_DELAY_MINUTES =
  'oes/TargetServingTime/WaitingStatusSetting/SET_FIRST_FOOD_DELAY_MINUTES'
const TOGGLE_ALERT_SECONDS =
  'oes/TargetServingTime/WaitingStatusSetting/TOGGLE_ALERT_SECONDS'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(
    INITIALIZE,
    (waitingStatusState: WaitingStatusState) => ({ waitingStatusState })
  )(),
  toggleAlertSeconds: createAction(TOGGLE_ALERT_SECONDS)(),
  toggleFirstDrinkDelay: createAction(TOGGLE_FIRST_DRINK_DELAY)(),
  toggleFirstFoodDelay: createAction(TOGGLE_FIRST_FOOD_DELAY)(),
  setFirstDrinkDelayMinutes: createAction(
    SET_FIRST_DRINK_DELAY_MINUTES,
    (firstDrinkDelayMinutes: string) => ({ firstDrinkDelayMinutes })
  )(),
  setFirstFoodDelayMinutes: createAction(
    SET_FIRST_FOOD_DELAY_MINUTES,
    (firstFoodDelayMinutes: string) => ({ firstFoodDelayMinutes })
  )()
}

export const types = {
  CLEAR_STATE,
  INITIALIZE,
  TOGGLE_FIRST_DRINK_DELAY,
  SET_FIRST_DRINK_DELAY_MINUTES,
  TOGGLE_FIRST_FOOD_DELAY,
  SET_FIRST_FOOD_DELAY_MINUTES,
  TOGGLE_ALERT_SECONDS
}
