import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from './actions'
import { Category } from './types'

export const useInitialize = (
  categories: Category[],
  lastOrderMinutes?: number
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.initialize({ categories, lastOrderMinutes }))
  }, [categories, lastOrderMinutes, dispatch])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.clearState())
  }, [dispatch])
}

export const useSubmitHodaiSelect = () => {
  const dispatch = useDispatch()
  return useCallback(
    selectedItem => {
      dispatch(
        actions.submitHodaiSelect({
          itemId: selectedItem.id,
          name: selectedItem.name
        })
      )
    },
    [dispatch]
  )
}

export const useCloseHodaiSelect = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.closeHodaiSelect())
  }, [dispatch])
}

export const useOpenHodaiSelect = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.openHodaiSelect())
  }, [dispatch])
}
