import { AppState } from '~/src/redux/reducer'
import { isNullOrUndefinedOrEmpty } from '~/src/util/Objects'

export const selectDeleteConfirmModal = (state: AppState) => {
  const deleteTargetIndex = state.kitchenPosition.deleteTargetIndex

  let deletingKitchenPosition = null
  if (deleteTargetIndex != null) {
    deletingKitchenPosition =
      state.kitchenPosition.kitchenPositionSetting.kitchenPositionList.get(
        deleteTargetIndex
      )
  }

  let deleteConfirmModalTitle = ''
  let deleteConfirmModalMessage = ''
  if (deletingKitchenPosition) {
    deleteConfirmModalTitle = !isNullOrUndefinedOrEmpty(
      deletingKitchenPosition.kitchenPositionName
    )
      ? `「${deletingKitchenPosition.kitchenPositionName}」の削除`
      : '調理場の削除'
    deleteConfirmModalMessage = !isNullOrUndefinedOrEmpty(
      deletingKitchenPosition.kitchenPositionName
    )
      ? `調理場から「${deletingKitchenPosition.kitchenPositionName}」を削除しますがよろしいですか？`
      : '選択された調理場を削除しますがよろしいですか？'
  }

  return {
    isDeleteConfirmModalOpen: state.kitchenPosition.isDeleteConfirmModalOpen,
    deleteTargetIndex,
    deletingKitchenPosition,
    deleteConfirmModalTitle,
    deleteConfirmModalMessage
  }
}
