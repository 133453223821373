import React from 'react'
import styled from 'styled-components'
import Icon from '~/src/components/atoms/Icon'
import Const from '~/src/const'

const { Color, UndefinedId } = Const
const ArrowDropDown = styled(Icon).attrs({ name: 'arrowDropDown' })``

export default props => {
  const {
    name,
    options,
    placeholder,
    disabled,
    handleSelectChange,
    selectedId,
    onFocus,
    invalid,
    className
  } = props

  const isPlaceholderSelected = placeholder && !selectedId
  const selectedValue = selectedId || UndefinedId.SELECTBOX_ID

  return (
    <Wrapper className={className} disabled={disabled}>
      <ArrowDropDown />
      <StyledSelect
        name={name}
        disabled={disabled}
        onChange={e => handleSelectChange(e)}
        isPlaceholderSelected={isPlaceholderSelected}
        value={selectedValue}
        onFocus={onFocus}
        invalid={invalid}
      >
        {placeholder && (
          <option
            disabled
            key={`${name}-option-${UndefinedId.SELECTBOX_ID}`}
            value={UndefinedId.SELECTBOX_ID}
          >
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <option key={`${name}-option-${option.id}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  svg {
    height: 9px;
    position: absolute;
    right: 12px;
    top: 17px;
    width: 14px;
    pointer-events: none;

    path {
      fill: ${props =>
        props.disabled ? Color.LIGHT_GRAY_3 : Color.DARK_GRAY_2};
    }
  }
`

const StyledSelect = styled.select`
  appearance: none;
  background: ${Color.WHITE};
  border: 1px solid
    ${props => (props.invalid ? Color.SOFT_RED : Color.LIGHT_GRAY_2)};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props =>
    props.isPlaceholderSelected ? Color.LIGHT_GRAY_2 : Color.VERY_DARK_GRAY};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  font-size: 14px;
  height: 44px;
  line-height: normal;
  padding: 8px 28px 8px 6px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;

  ::-ms-expand {
    display: none;
  }

  :hover,
  :focus {
    border-color: ${Color.VIVID_BLUE};
    outline: ${Color.VIVID_BLUE};
  }

  :disabled {
    background: ${Color.VERY_LIGHT_GRAY_7};
    border-color: ${Color.VERY_LIGHT_GRAY_4};
    color: ${Color.LIGHT_GRAY_3};
  }

  :disabled::-ms-value,
  :disabled::-ms-expand {
    background: ${Color.VERY_LIGHT_GRAY_7};
    border-color: ${Color.VERY_LIGHT_GRAY_4};
    color: ${Color.LIGHT_GRAY_3};
  }

  > option {
    color: ${Color.VERY_DARK_GRAY};

    :disabled {
      color: ${Color.LIGHT_GRAY_3};
      display: none;
    }
  }
`
