import { Record } from 'immutable'
import Validate from '~/src/util/Validate'
import { ConsumptionTaxType, fromId } from '~/src/const/consumptionTaxType'

interface VariationRetouchProps {
  variationId: string
  consumptionTaxType?: ConsumptionTaxType
  price?: string
}

const VariationRetouchRecord = Record<VariationRetouchProps>({
  variationId: '',
  consumptionTaxType: undefined,
  price: undefined
})

class VariationRetouch
  extends VariationRetouchRecord
  implements VariationRetouchProps
{
  isValid(): boolean {
    return !this.validatesetConsumptionTaxType() && !this.validatePrice()
  }

  setConsumptionTaxType(consumptionTaxTypeId?: string) {
    return this.set('consumptionTaxType', fromId(consumptionTaxTypeId))
  }

  setPrice(price?: string) {
    return this.set('price', price)
  }

  validatesetConsumptionTaxType(): string | undefined {
    if (!this.consumptionTaxType) {
      return '選択必須'
    }
  }

  validatePrice(): string | undefined {
    return !this.price
      ? '入力必須'
      : undefined ||
          Validate.number(this.price) ||
          Validate.maxLength(this.price, 7)
  }
}

export default VariationRetouch
