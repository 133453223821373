import React from 'react'
import styled from 'styled-components'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import TableColumn from '~/src/components/atoms/TableColumn'
import Multiselect from '~/src/components/atoms/Multiselect'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { selectPrinterSelect } from './selectors'
import { useReplacePrinter } from './hooks'

const { Color } = Const

export const PrinterSelect: React.VFC = () => {
  const { printerOptions, selectedPrinterIds } =
    useSelector(selectPrinterSelect)
  const handleReplacePrinter = useReplacePrinter()

  return (
    <PrinterSelectArea>
      <StepTitle>
        <StepBadge stepNum={1} />
        <StepTitleText>プリンター選択</StepTitleText>
      </StepTitle>
      <StyledHr />
      <Description>一括反映したいプリンターを選んでください。</Description>
      <TableRow>
        <TitleColumn>対象プリンター</TitleColumn>
        <SelectBoxColumn>
          <Multiselect
            name="printerIds"
            options={printerOptions}
            values={selectedPrinterIds}
            placeholder="選択してください"
            handleSelectChange={handleReplacePrinter}
          />
        </SelectBoxColumn>
      </TableRow>
    </PrinterSelectArea>
  )
}

const PrinterSelectArea = styled.div`
  padding-bottom: 24px;
`

const Description = styled.div`
  padding-top: 16px;
  padding-bottom: 24px;
`

const TitleColumn = styled(TableColumn)`
  width: 150px;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
`

const SelectBoxColumn = styled(TableColumn)`
  width: auto;
  text-align: left;
`

const StepTitle = styled.div`
  display: flex;
  padding: 12px 0;
`

const StepTitleText = styled.div`
  padding-left: 8px;
  padding-top: 7px;
  font-size: 18px;
  font-weight: 600;
`

const StyledHr = styled.hr`
  margin: 0;
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: none;
`

const TableRow = styled.div`
  background-color: ${Color.WHITE};
  display: table;
  top: -1px;
  margin-top: -1px;
  padding: 16px 0;
  table-layout: fixed;
  width: 100%;
`
