import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import Const from '~/src/const'
import { MenuItem } from '../../types'

const { Color, Size } = Const

type SideMenuItemProps = {
  item: MenuItem
}

export const SideMenuItem: React.VFC<SideMenuItemProps> = (
  props: SideMenuItemProps
) => {
  const { item } = props
  const history = useHistory()
  const match = useRouteMatch({
    path: item.link,
    strict: false,
    sensitive: false
  })
  // 当該リンクが表示中のURL（子ページ含む）のときハイライトする
  const isHighlighted = !!match

  // 当該リンクが表示中のURLでなかった場合はリンクをクリック可能にする
  // 子ページの場合もクリック可能とする
  const isClickable = !match || !match.isExact
  const onClick = () => {
    if (isClickable) {
      history.push(item.link)
    }
  }
  return (
    <Wrapper
      isClickable={isClickable}
      isHighlighted={isHighlighted}
      onClick={onClick}
    >
      {item.title}
    </Wrapper>
  )
}

type WrapperProps = {
  isHighlighted: boolean
  isClickable: boolean
}

const Wrapper = styled.div<WrapperProps>`
  padding: 12px;
  padding-left: 41px;
  min-width: ${Size.SIDE_MENU_WIDTH - 20}px;
  font-size: ${Size.FONT.LARGE}px;
  line-height: 1;
  white-space: normal;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${Color.VERY_DARK_GRAYISH_BULE_6};
  }
  ${props =>
    props.isHighlighted &&
    `
    cursor: default;
    background-color: ${Color.VERY_DARK_GRAYISH_BLUE_7};
    &:hover {
      background-color: ${Color.VERY_DARK_GRAYISH_BLUE_7};
    }
  `}
  ${props =>
    props.isClickable &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${Color.VERY_DARK_GRAYISH_BULE_6};
    }
  `}
`
