import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import PageTemplate, { PageTemplateProps } from './PageTemplate'
import { AppState } from '~/src/redux/reducer'
import { AuthState } from '~/src/redux/modules/Auth/types'
import Const from '~/src/const'
import {
  containsKP,
  containsSPO,
  isHandyKm,
  containsPrepayment,
  containsTakeOut,
  containsHandy
} from '~/src/util/ServiceTypeCheck'
const { ContractType } = Const

const authSelector = createSelector(
  (state: AppState) => state.auth,
  (auth: AuthState) => ({
    isHandy: containsHandy(auth.store!.serviceTypes),
    isKM:
      // KP先会計移行中はServiceTypesだけ見るとHANDY_APPとKM_APP両方持っているが、ハンディかつKMの機能は使えないのでisKpKmOnlyAvailableも見ている
      isHandyKm(auth.store!.serviceTypes) && !auth.store!.isKpKmOnlyAvailable,
    isKP: containsKP(auth.store!.serviceTypes),
    isKMOnly: containsPrepayment(auth.store!.serviceTypes),
    isSpo: containsSPO(auth.store!.serviceTypes),
    isTakeOut: containsTakeOut(auth.store!.serviceTypes),
    isTrial: ContractType.isTrial(auth.store!.contractType),
    isManager: auth.token!.isManager
  })
)

const useAuth = () => {
  return useSelector(authSelector)
}

const PageTemplateContainer: React.FC<PageTemplateProps> = props => {
  return <PageTemplate {...props} {...useAuth()} />
}

export default PageTemplateContainer
