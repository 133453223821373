import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  ContentsBox,
  FixedArea,
  ScrollArea
} from '~/src/components/atoms/ContentsBox'
import SegmentedControl from '~/src/components/atoms/SegmentedControl'
import { HeaderRow } from './Row/HeaderRow'
import { Row } from './Row/Row'
import EmptyState from '~/src/components/atoms/EmptyState'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'
import { useSetIsDisplayedCategoryShowing } from './hooks'
import { selectKitchenPositionParams } from './selectors'
const { URL } = Const

const DISPLAY_CATEGORY = { id: 'display', text: '表示カテゴリー' }
const NON_DISPLAY_CATEGORY = { id: 'non-display', text: '非表示カテゴリー' }

const SEGMENTED_VALUES = [DISPLAY_CATEGORY, NON_DISPLAY_CATEGORY]

export const List: React.VFC = () => {
  const {
    kitchenPositionByCategories,
    isKitchenPositionsEmpty,
    isDisplayedCategoryShowing
  } = useSelector(selectKitchenPositionParams)

  const handleChange = useSetIsDisplayedCategoryShowing()

  if (isKitchenPositionsEmpty) {
    return null
  }

  const CategoryEmptyState = () => {
    const text = isDisplayedCategoryShowing ? '表示設定' : '非表示設定'
    return (
      <EmptyState>
        {text}されているカテゴリーが登録されていません。
        <br />
        <InlineLink href={URL.REGI_CATEGORY} target="_blank">
          Airレジのカテゴリー設定ページ
        </InlineLink>
        から登録できます。
      </EmptyState>
    )
  }

  return (
    <ContentsBox>
      <FixedArea>
        <SegmentedWrapper>
          <SegmentedControl
            values={SEGMENTED_VALUES}
            selectedId={
              isDisplayedCategoryShowing
                ? DISPLAY_CATEGORY.id
                : NON_DISPLAY_CATEGORY.id
            }
            onChange={selectedId =>
              handleChange(selectedId === DISPLAY_CATEGORY.id)
            }
          />
        </SegmentedWrapper>
      </FixedArea>
      <FixedArea>
        <HeaderRow isDisplayedCategoryShowing={isDisplayedCategoryShowing} />
      </FixedArea>
      <ScrollArea>
        {kitchenPositionByCategories.size > 0
          ? kitchenPositionByCategories.map((byCategory, index) => (
              <Row
                key={byCategory.categoryId}
                kitchenPositionByCategory={byCategory}
                isFirstChild={index === 0}
              />
            ))
          : CategoryEmptyState()}
      </ScrollArea>
    </ContentsBox>
  )
}

const SegmentedWrapper = styled.div`
  padding-bottom: 16px;
`
