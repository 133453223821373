export type CSVData = Array<Array<string | undefined>>

type EncodingType = 'sjis'

/**
 * CSV Fileを読み込み、CSVData(string[][])のデータに変換する
 */
class CSVFileReader {
  async read(file: File, encoding: EncodingType): Promise<CSVData> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const result = this.textToCSVData(reader.result as string)
        resolve(result)
      }
      reader.onerror = error => {
        reader.abort()
        reject(error)
      }
      reader.readAsText(file, encoding)
    })
  }

  /**
   * CSV形式のstringを改行とカンマで解読し、CSVData(string[][])に変換する。
   * @param text
   */
  private textToCSVData(text: string): CSVData {
    const rows = text.replace(/\r/g, '').split('\n')
    const data = rows.filter(row => row.length).map(row => this.readRow(row))
    return data
  }

  /**
   * １行のデータを読み取る。
   * 「,」と「","」区切りの両方のデータ、及び項目内の「""」にも対応している
   * @param row
   */
  private readRow(row: string): (string | undefined)[] {
    const data: (string | undefined)[] = []
    let tmpColumn = ''
    let isInDoubleQuote = false

    for (let i = 0; i < row.length; i++) {
      const prev = row[i - 1]
      const letter = row[i]
      const next = row[i + 1]
      if (letter === '"') {
        if (prev === ',' || (prev == null && !isInDoubleQuote)) {
          isInDoubleQuote = true
          continue
        }
        if (
          (next === ',' || next == null) &&
          (tmpColumn === '' || prev !== '"')
        ) {
          isInDoubleQuote = false
          continue
        }
      }
      if (!isInDoubleQuote && letter === ',') {
        data.push(tmpColumn)
        tmpColumn = ''
        continue
      }
      tmpColumn = tmpColumn + letter
    }
    data.push(tmpColumn)
    return data
      .map(column => column?.replace(/""/g, '"'))
      .map(column => (column !== '' ? column : undefined)) // 空文字はundefinedに変える
  }
}

export default CSVFileReader
