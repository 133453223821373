import React from 'react'
import ForwardErrorModal from '~/src/components/organisms/Modal/ErrorModal/Forward'
import Const from '~/src/const'

const { Page } = Const

export const SpoUpSellErrorModal = () => {
  return (
    <ForwardErrorModal
      to={Page.HOME}
      title="表示権限がありません"
      message={`「モバイルオーダー 店内版のご案内」の表示権限がありません。`}
    />
  )
}
