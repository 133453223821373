import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'

export const selectMessageAreaState = createSelector(
  (state: AppState) => state.registeredDevice,
  ({ deviceCount, registeredDevices }) => {
    const maxDeviceCount = deviceCount.currentCount
    const currentCount = registeredDevices.length
    const hasError = maxDeviceCount < currentCount
    let exceededErrorMessage
    if (hasError) {
      exceededErrorMessage =
        maxDeviceCount + 1 < currentCount
          ? `登録端末の内 ${maxDeviceCount + 1}台目〜 ${currentCount}台目`
          : `登録端末の ${currentCount}台目`
    }
    return {
      hasError,
      currentCount,
      exceededErrorMessage
    }
  }
)
