import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'

const { Color } = Const

type Props = {
  stepNum: number
}

export const StepBadge: React.VFC<Props> = (props: Props) => {
  return (
    <StyledBadge>
      <Text>STEP{props.stepNum}</Text>
    </StyledBadge>
  )
}

const StyledBadge = styled.div`
  box-sizing: border-box;
  height: 30px;
  width: 79px;
  border: 1px solid ${Color.VIVID_BLUE};
  border-radius: 16px;
  background-color: ${Color.WHITE};
  padding: 4px 16px;
`

const Text = styled.span`
  height: 22px;
  width: 47px;
  color: ${Color.VIVID_BLUE};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`
