// See https://github.com/sindresorhus/array-move/blob/master/index.js

export const arrayMove = (array: any[], from: number, to: number) => {
  const target = [...array]
  const startIndex = from < 0 ? target.length + from : from

  if (startIndex >= 0 && startIndex < target.length) {
    const endIndex = to < 0 ? target.length + to : to

    const [item] = target.splice(from, 1)
    target.splice(endIndex, 0, item)
  }

  return target
}
