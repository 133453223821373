import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'

import { SelectColumn, HodaiNameColumn, HodaiDetailNumColumn } from './Column'

import Const from '~/src/const/'

const { Color } = Const

export default () => {
  return (
    <HeaderRow>
      <SelectColumn />
      <HodaiNameColumn>放題プラン名</HodaiNameColumn>
      <HodaiDetailNumColumn>商品数</HodaiDetailNumColumn>
    </HeaderRow>
  )
}

const HeaderRow = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
