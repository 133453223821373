import React, {
  useState,
  useMemo,
  useReducer,
  useLayoutEffect,
  useEffect,
  useRef,
  useCallback
} from 'react'
import styled from 'styled-components'
import { DownArrowIcon as DownArrow } from '~/src/components/atoms/Icon'
import { reducer } from './MultiselectReducer'
import Const from '~/src/const'
const { Color } = Const

type Option = {
  id: string
  label: string
  excludeOthers?: boolean
}

type Props = {
  id?: string
  name?: string
  placeholder?: string
  options: Option[]
  values?: string[]
  handleSelectChange: (values: string[]) => void
}

const Multiselect = (props: Props) => {
  const options = useMemo(() => {
    return props.options.map((opt, i) => ({
      ...opt,
      order: i
    }))
  }, [props.options])

  const [state, dispatch] = useReducer(reducer, {
    selected:
      options.filter(opt => props.values?.some(val => val === opt.id)) || []
  })

  const [isInitialized, setIsInitialized] = useState(false)

  useLayoutEffect(() => {
    if (isInitialized) {
      props.handleSelectChange(state.selected.map(option => option.id))
    } else {
      setIsInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selected])

  const selectedIds = state.selected.map(opt => opt.id).join(',')

  // 以下、枠外タップで閉じる機能のためのコード
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const outsideClickListener = useCallback(
    (e: MouseEvent) => {
      // @ts-ignore
      if (!wrapperRef.current?.contains(e.target)) {
        setOpen(false)
      }
    },
    [wrapperRef]
  )
  useEffect(() => {
    if (open && wrapperRef && wrapperRef.current) {
      document.addEventListener('click', outsideClickListener)
    } else {
      document.removeEventListener('click', outsideClickListener)
    }
    return () => {
      document.removeEventListener('click', outsideClickListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, wrapperRef])

  return (
    <Wrapper ref={wrapperRef}>
      <input
        type="hidden"
        id={props.id}
        name={props.name}
        value={selectedIds}
      />
      <Selection open={open} onClick={() => setOpen(!open)}>
        <SelectedZone>
          {Boolean(props.placeholder) && Boolean(!state.selected.length) && (
            <Placeholder>{props.placeholder}</Placeholder>
          )}
          {state.selected.map(selected => (
            <Selected
              key={selected.id}
              option={selected}
              onRemove={() =>
                dispatch({ type: 'REMOVE_SELECTED', option: selected })
              }
            />
          ))}
        </SelectedZone>
        {Boolean(state.selected.length) && (
          <ClearZone onClick={() => dispatch({ type: 'CLEAR_SELECTED' })}>
            ×
          </ClearZone>
        )}
        <ArrowZone open={open}>
          <DownArrow />
        </ArrowZone>
      </Selection>
      {open && (
        <Selector>
          {options
            .filter(option => state.selected.every(sel => sel.id !== option.id))
            .map(option => (
              <Select
                key={option.id}
                onClick={() =>
                  dispatch({ type: 'ADD_SELECTED', option: option })
                }
              >
                {option.label}
              </Select>
            ))}
        </Selector>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: inherit;
`

const Selection = styled.div<{ open: boolean }>`
  padding: 5px 5px 0 5px;
  display: flex;
  align-content: center;
  border: 1px solid ${Color.LIGHT_GRAY_4};
  border-radius: 4px;
  ${props =>
    props.open &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`

const Selector = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: ${Color.WHITE};
  z-index: 1;
`

const Select = styled.div`
  cursor: pointer;
  padding: 10px 10px;
  background-color: ${Color.WHITE};
  :hover {
    background-color: rgba(0, 126, 255, 0.08);
  }
  border: 1px solid ${Color.LIGHT_GRAY_4};
  border-top: none;
  border-bottom: none;
  :last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 1px solid ${Color.LIGHT_GRAY_4};
  }
`

const Placeholder = styled.div`
  color: ${Color.GRAY_3};
  padding: 3px 0;
  padding-left: 5px;
  margin-bottom: 5px;
`

const SelectedZone = styled.span`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
`

const ClearZone = styled.span`
  cursor: pointer;
  color: #555;
  font-weight: 500;
  flex: 0 0 auto;
  padding: 0 5px 5px;
  margin: auto;
`

const ArrowZone = styled.span<{ open: boolean }>`
  cursor: pointer;
  flex: 0 0 auto;
  padding: 0 5px 5px;
  margin: auto;
  svg {
    transition: all ease 300ms;
    ${props =>
      props.open ? 'transform: rotate(-180deg);' : 'transform: rotate(0deg);'}
  }
`

const Selected: React.FC<{ option: Option; onRemove: () => void }> = props => {
  return (
    <SelectedWrapper>
      <SelectedClose onClick={props.onRemove}>×</SelectedClose>
      <SelectedLabel>{props.option.label}</SelectedLabel>
    </SelectedWrapper>
  )
}

const SelectedWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: #007eff;
  background-color: rgba(0, 126, 255, 0.08);
  border: solid 1px rgba(0, 126, 255, 0.24);
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
`

const SelectedClose = styled.span<{ onClick?: any }>`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  padding: 1px 6px 3px;
`

const SelectedLabel = styled.span<{ onClick?: any }>`
  display: inline-block;
  padding: 3px 5px;
  border-left: solid 1px rgba(0, 126, 255, 0.24);
`

export default Multiselect
