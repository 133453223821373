import { AppState } from '~/src/redux/reducer'

const selectKmSetting = (state: AppState) => state.optionSetting.kmSettings

const selectWaitingStatusSetting = (state: AppState) =>
  state.optionSetting.waitingStatusSetting

const selectVersion = (state: AppState) =>
  state.api.kmSetting.response.setting.version

export const selectRequest = (state: AppState) => {
  const kmSetting = selectKmSetting(state)
  const waitingStatusSetting = selectWaitingStatusSetting(state)
  const version = selectVersion(state)
  const request = {
    setting: {
      isCookingNoticeEnabled: kmSetting.isCookingNoticeEnabled,
      orderTicketSortType: kmSetting.orderTicketSortTypeId,
      isFirstDrinkDelayEnabled: waitingStatusSetting.isFirstDrinkDelayEnabled,
      firstDrinkDelayMinutes: parseInt(
        waitingStatusSetting.firstDrinkDelayMinutes
      )
        ? parseInt(waitingStatusSetting.firstDrinkDelayMinutes)
        : 0,
      isFirstFoodDelayEnabled: waitingStatusSetting.isFirstFoodDelayEnabled,
      firstFoodDelayMinutes: parseInt(
        waitingStatusSetting.firstFoodDelayMinutes
      )
        ? parseInt(waitingStatusSetting.firstFoodDelayMinutes)
        : 0,
      isAlertSecondsEnabled: waitingStatusSetting.isAlertSecondsEnabled,
      version: version
    }
  }
  return request
}
