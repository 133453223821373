/**
 * 共通フッター
 */
import React from 'react'

import Footer from '~/src/components/atoms/Footer/Footer'
import FooterLeftArea from '~/src/components/atoms/Footer/FooterLeftArea'
import FooterRightArea from '~/src/components/atoms/Footer/FooterRightArea'
import Button from '~/src/components/atoms/Button'
import styled from 'styled-components'

export default props => {
  const {
    history,
    optionButtonLink,
    submitButtonText,
    optionButtonText,
    cancelButtonText,
    onSubmitButtonClick,
    onOptionButtonClick,
    onCancelButtonClick,
    noUseSubmitButton,
    noUseOptionButton,
    disableSubmitButton,
    disableOptionButton,
    disableCancelButton,
    useSecondaryOptionButton,
    useSecondarySubmitButton,
    useSubmitButtonAsNormalButton,
    beforeOptionButtonClick
  } = props

  const onOptionButtonLinkClick = optionButtonLink
    ? () => {
        if (beforeOptionButtonClick === undefined) {
          history.push(optionButtonLink)
          return
        }
        beforeOptionButtonClick() && history.push(optionButtonLink)
      }
    : undefined

  return (
    <Footer>
      {!noUseSubmitButton && (
        <>
          <FooterRightArea>
            <Button
              type={useSubmitButtonAsNormalButton ? 'button' : 'submit'}
              primary={!useSecondarySubmitButton}
              secondary={!!useSecondarySubmitButton}
              disabled={disableSubmitButton}
              onClick={onSubmitButtonClick}
            >
              {submitButtonText || '保存する'}
            </Button>
          </FooterRightArea>
          {onCancelButtonClick && (
            <FooterRightArea>
              <CancelButtonWrapper>
                <Button
                  type="button"
                  normal={!useSecondaryOptionButton}
                  secondary={!!useSecondaryOptionButton}
                  disabled={disableCancelButton}
                  onClick={onCancelButtonClick}
                >
                  {cancelButtonText || 'キャンセル'}
                </Button>
              </CancelButtonWrapper>
            </FooterRightArea>
          )}
        </>
      )}

      {!noUseOptionButton && (
        <FooterLeftArea>
          <Button
            type="button"
            normal={!useSecondaryOptionButton}
            secondary={!!useSecondaryOptionButton}
            disabled={disableOptionButton}
            onClick={onOptionButtonLinkClick || onOptionButtonClick}
          >
            {optionButtonText || 'キャンセル'}
          </Button>
        </FooterLeftArea>
      )}
    </Footer>
  )
}

const CancelButtonWrapper = styled.div`
  padding-right: 8px;
`
