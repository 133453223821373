// 環境別URL
const URL = {
  DEVELOPMENT: 'https://s9-stg.r-ad.ne.jp/json',
  PRODUCTION: 'https://s9.r-ad.ne.jp/json'
}

// 広告配信枠（本番、開発環境共通）
const AD_DISTRIBUTION_FRAME = '143.crossuse.oes_prod_bo_menubar'

// 広告数
const NUMBER_OF_ADS = '1'

export const Koruli = {
  URL,
  AD_DISTRIBUTION_FRAME,
  NUMBER_OF_ADS
}
