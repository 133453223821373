import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import styled from 'styled-components'
import { List } from 'immutable'

import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import Accordion, { DownArrowIcon } from '~/src/components/atoms/Accordion'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn, FunctionColumn } from '../Common/Column'
import { FunctionLink } from '../Common/Link'
import { ItemRow } from './ItemRow'

type Props = {
  categoryId: string
}

const selectCategoryAndItemIds = createSelector(
  (state: AppState) => state.toppingGroup.categories,
  (state: AppState) => state.toppingGroup.selectedToppingItemIds,
  (_: AppState, categoryId: string) => categoryId,
  (categories, selectedToppingItemIds, categoryId) => {
    const category = categories.get(categoryId)!
    const itemIdsToBeDisplayed = category.itemIds.filter(
      itemId => !selectedToppingItemIds.includes(itemId)
    )
    return { category, itemIdsToBeDisplayed }
  }
)

const CategoryRowContainer: React.VFC<Props> = ({ categoryId }) => {
  const { category, itemIdsToBeDisplayed } = useSelector((state: AppState) =>
    selectCategoryAndItemIds(state, categoryId)
  )

  const interactor = useInteractors().toppingGroupInteractor
  const handleClickSelectAll = useCallback(
    () => interactor.addCategoryToToppingGroup(category.categoryId),
    [category, interactor]
  )
  const displayName = category.isDisplayed
    ? category.categoryName
    : `${category.categoryName} (非表示)`

  const props: InnerProps = {
    displayName,
    itemIdsToBeDisplayed,
    onClickSelectAll: handleClickSelectAll
  }
  return <CategoryRow {...props} />
}
export { CategoryRowContainer as CategoryRow }

type InnerProps = {
  displayName: string
  itemIdsToBeDisplayed: List<string>
  onClickSelectAll: () => void
}
const CategoryRow: React.VFC<InnerProps> = React.memo(props => {
  const { displayName, itemIdsToBeDisplayed, onClickSelectAll } = props

  return (
    <Accordion
      defaultValue={false}
      Parent={props => (
        <StyledTableRow>
          <ContentColumn>{displayName}</ContentColumn>
          <FunctionColumn>
            {props.isOpened && (
              <FunctionLink clickHandler={onClickSelectAll}>
                すべて選択
              </FunctionLink>
            )}
            <DownArrowIcon isOpened={props.isOpened} />
          </FunctionColumn>
        </StyledTableRow>
      )}
    >
      {itemIdsToBeDisplayed.map(itemId => (
        <ItemRow key={itemId} itemId={itemId} />
      ))}
    </Accordion>
  )
})

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  height: 50px;
  font-weight: bold;
`
