import React, { useEffect } from 'react'
import styled from 'styled-components'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { Footer } from './Footer/Footer'
import { List } from './List/List'
import { Modal } from './Modal/Modal'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'
import { useInitializeState, useClearState } from './hooks'
const { Color, URL } = Const

export const Target: React.VFC = () => {
  const handleInitializeState = useInitializeState()
  const handleClearState = useClearState()

  useEffect(() => {
    handleInitializeState()
    return () => {
      handleClearState()
    }
  }, [handleInitializeState, handleClearState])

  const breadcrumbs = [{ title: '調理場振り分け' }]
  const description = [
    '商品ごとに、キッチンモニターのどの調理場に表示するか設定できます。'
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<Footer />}
      modal={<Modal />}
      displayKM
      displayKMOnly
      displayTakeOut
    >
      <KitchenPositionFAQ>
        ※調理場振り分けについて、詳しくは
        <InlineLink href={URL.KITCHEN_POSITION_FAQ} target="_blank">
          調理場管理と調理場振り分け設定（ペーパーレスプラン向け）
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </KitchenPositionFAQ>
      <List />
    </PageTemplate>
  )
}

const KitchenPositionFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
