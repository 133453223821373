import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Icon from '~/src/components/atoms/Icon'
import SelectBox from '~/src/components/atoms/SelectBox'
import TitleColumn from '../../Common/TitleColumn'
import NormalColumn from '../../Common/NormalColumn'
import ArrowColumn from '../../Common/ArrowColumn'
import TableRow from '~/src/components/atoms/TableRow'
import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { selectInnerTable } from './selectors'
import { useSelectChanged } from './hooks'
const { Color } = Const

export const InnerTable = () => {
  const {
    currentCount,
    flatRateCount,
    flatRatePrice,
    selectedDeviceCount,
    isRenderingSelectBox,
    selectOptions,
    appendedPrice
  } = useSelector(selectInnerTable, equal)

  const onSelectChanged = useSelectChanged()

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectChanged(e.target.value)
  }

  return (
    <ModifyTable>
      <ModifyHeaderArea>
        <TitleColumn />
        <NormalColumn>変更前</NormalColumn>
        <ArrowColumn />
        <NormalColumn>変更後</NormalColumn>
      </ModifyHeaderArea>
      <ModifyRowArea>
        <TitleColumn>利用台数</TitleColumn>
        <NormalColumn>{currentCount} 台</NormalColumn>
        <ArrowColumn>
          <Arrow />
        </ArrowColumn>
        <NormalColumn>
          <SelectBoxArea>
            {isRenderingSelectBox && (
              <SelectBox
                name="deviceCountSelect"
                options={selectOptions}
                selectedId={selectedDeviceCount}
                handleSelectChange={handleSelectChange}
              />
            )}
          </SelectBoxArea>
          台
        </NormalColumn>
      </ModifyRowArea>
      <Description>
        ※1店舗あたりハンディ端末{flatRateCount}台まで
        {flatRatePrice}
        円（税込）、1台追加ごとに
        {appendedPrice}
        円（税込）が加算請求されます。
      </Description>
    </ModifyTable>
  )
}

const ModifyTable = styled.div`
  width: 100%;
`

const ModifyHeaderArea = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  font-weight: bold;
`

const ModifyRowArea = styled(TableRow)``

const Arrow = styled(Icon).attrs({ name: 'arrow' })``

const Description = styled.div`
  padding: 32px 0;
  text-align: left;
  color: ${Color.DARK_GRAY_4};
`

const SelectBoxArea = styled.div`
  width: 60%;
  padding: 4px;
  display: inline-block;
`

export default InnerTable
