import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteItemSetting,
  updateItemSetting
} from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useToggle = (
  itemId: string,
  hasItemSetting: boolean,
  kitchenPositionId: string,
  analysisTag: string
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (hasItemSetting) {
      dispatch(deleteItemSetting(itemId))
    } else {
      dispatch(updateItemSetting(itemId, kitchenPositionId, analysisTag))
    }
  }, [analysisTag, dispatch, hasItemSetting, itemId, kitchenPositionId])
}

export const useSelect = (itemId: string) => {
  const dispatch = useDispatch()
  return useCallback(
    (kitchenPositionId: string, analysisTag: string) => {
      dispatch(updateItemSetting(itemId, kitchenPositionId, analysisTag))
    },
    [dispatch, itemId]
  )
}
