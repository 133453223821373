import React from 'react'

import { List } from './List'
import { Detail } from './Detail'

const Update = ({ match }) => {
  const seatSetId = match.params.id
  return <Detail id={seatSetId} isUpdate key={seatSetId} />
}

export default {
  List: List,
  Create: Detail,
  Update: Update
}
