import React from 'react'
import ForwardErrorModal from '~/src/components/organisms/Modal/ErrorModal/Forward'
import Const from '~/src/const'

const { Page } = Const

const NoContractErrorModal = () => {
  return (
    <ForwardErrorModal
      to={Page.HOME}
      title="契約情報がありません"
      message="ハンディ端末管理のご利用には、お申込みが必要です。"
    />
  )
}

export default NoContractErrorModal
