import { Record, Map, OrderedMap } from 'immutable'
import Item from './Item'

interface ItemsProps {
  items: Map<string, Item>
}

const ItemsRecord = Record<ItemsProps>({
  items: Map<string, Item>()
})

export default class Items extends ItemsRecord implements ItemsProps {
  constructor(items: any[] = []) {
    super()
    // @ts-ignore
    const map: Map<string, Item> = Map().withMutations(map => {
      items.forEach(item => {
        map.set(item.itemId, new Item(item))
      })
    })
    return this.set('items', map)
  }

  getItem(itemId: string) {
    return this.items.get(itemId)
  }

  // IE 対応用の軽量モデル
  getSimpleItems() {
    return this.items
      .toList()
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(item => item.getSimpleItem())
  }

  // IE 対応用の中量級モデル
  getSimpleItemForToppings() {
    return OrderedMap(
      this.items
        .toList()
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(item => [item.itemId, item.getSimpleItemForTopping()])
    )
  }
}
