import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'

const DEVICE_COUNT_MIN = 1
const DEVICE_COUNT_MAX = 30

export const selectInnerTable = createSelector(
  (state: AppState) => state.deviceCount,
  (state: AppState) => state.auth.store,
  ({ selectedDeviceCount, isResourceFetched, summary }, store) => {
    const flatRateCount =
      store?.planPriceType.FLAT_RATE_COUNT === null
        ? '-'
        : store?.planPriceType.FLAT_RATE_COUNT

    const flatRatePrice =
      store?.planPriceType.FLAT_RATE_PRICE === null
        ? '-'
        : store?.planPriceType.FLAT_RATE_PRICE.toLocaleString('ja-JP')

    const appendedPrice =
      store?.planPriceType.APPENDED_PRICE.toLocaleString('ja-JP')

    const selectOptions = [
      ...Array(DEVICE_COUNT_MAX - DEVICE_COUNT_MIN + 1)
    ].map((e, i) => ({
      id: DEVICE_COUNT_MIN + i,
      value: DEVICE_COUNT_MIN + i,
      label: DEVICE_COUNT_MIN + i
    }))

    return {
      currentCount: summary.currentCount,
      selectedDeviceCount: selectedDeviceCount,
      selectOptions: selectOptions,
      isRenderingSelectBox: isResourceFetched,
      flatRateCount,
      flatRatePrice,
      appendedPrice
    }
  }
)
