import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import HeaderRow from './Row/HeaderRow'
import UnRegisteredRow from './Row/UnRegisteredRow'
import { Row } from './Row/Row'
import { useSelector } from 'react-redux'
import { selectListState } from './selectors'
const { Size, Color } = Const

export const List = () => {
  const { registeredDevices, unRegisteredRowList } =
    useSelector(selectListState)

  return (
    <Wrapper>
      <HeaderRow />
      {registeredDevices.map((registeredDevice, index) => (
        <Row
          index={index + 1}
          key={registeredDevice.registeredDeviceId}
          registeredDevice={registeredDevice}
        />
      ))}
      {unRegisteredRowList.map(index => (
        <UnRegisteredRow index={index + 1} key={`key-unregistered-${index}`} />
      ))}
      <Description>※表示内容は、端末でログインした時点の情報です</Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: ${Size.FONT.BASE}px;
`
const Description = styled.p`
  color: ${Color.DARK_GRAY_3};
  padding-top: 8px;
`
