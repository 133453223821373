import { AppState } from '~/src/redux/reducer'

export const selectCategoryModal = (state: AppState) => {
  return {
    isEditing:
      state.kitchenPositionTarget.kitchenPositionByItemEditor.isEditing,
    categoryName:
      state.kitchenPositionTarget.kitchenPositionByItemEditor.categoryName
  }
}
