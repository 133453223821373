type State = {
  selected: SelectedOption[]
}

type SelectedOption = {
  id: string
  label: string
  order: number
  excludeOthers?: boolean
}

type addSelected = {
  type: 'ADD_SELECTED'
  option: SelectedOption
}

type removeSelected = {
  type: 'REMOVE_SELECTED'
  option: SelectedOption
}

type clearSelected = {
  type: 'CLEAR_SELECTED'
}

type Actions = addSelected | removeSelected | clearSelected

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'ADD_SELECTED':
      if (action.option.excludeOthers) {
        return {
          selected: [action.option]
        }
      } else {
        return {
          selected: state.selected
            .concat([action.option])
            .filter(opt => !opt.excludeOthers)
            .filter((opt, i, self) => self.indexOf(opt) === i)
            .sort((a, b) => a.order - b.order)
        }
      }
    case 'REMOVE_SELECTED':
      return {
        selected: state.selected.filter(opt => opt.id !== action.option.id)
      }
    case 'CLEAR_SELECTED':
      return {
        selected: []
      }
    default:
      throw Error('Invalid action type.')
  }
}
