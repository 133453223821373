import { AppState } from '~/src/redux/reducer'
import Const from '~/src/const'
const { Page } = Const

export const selectPrintTargetCategories = (state: AppState) => {
  const resources = state.printTargetByCategories
  const whiteList = resources.seatSets.map(seatSet =>
    Page.PRINTER_TARGET.BATCH_SELECT(seatSet.seatSetId)
  )
  return {
    selectedPrinterHolder: resources.selectedPrinterHolder,
    isFetched: resources.isFetched,
    isEditing: resources.isEditing,
    whiteList
  }
}
