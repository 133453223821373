import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import { SortableHandle } from 'react-sortable-hoc'
import { HandleColumn, TrashBoxColumn, ItemNameColumn } from '../Column/Column'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'
import { useHandleClickTrashButton } from '../../../../hooks'

const { Color } = Const

type Props = {
  item: any
}

export const Row: React.VFC<Props> = props => {
  const { isDeleted, itemId, displayItemName, hasError } = props.item
  const handleClickTrashButton = useHandleClickTrashButton()
  return (
    <MainContents isDeleted={isDeleted}>
      <DraggableColumn />
      <ItemNameColumn>
        <ItemNameContainer>
          {hasError && <ExclamationIcon color="red" />}
          {displayItemName}
        </ItemNameContainer>
      </ItemNameColumn>
      <TrashBoxColumn onClick={handleClickTrashButton(itemId)}>
        <DeleteIcon />
      </TrashBoxColumn>
    </MainContents>
  )
}

const MainContents = styled(TableRow)<{
  isDeleted: boolean
}>`
  background-color: ${props =>
    props.isDeleted ? Color.VERY_LIGHT_GRAY_9 : Color.WHITE};
`

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``

const DraggableColumn = SortableHandle(() => {
  return (
    <HandleColumn>
      <SortIcon />
    </HandleColumn>
  )
})

const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``

const ItemNameContainer = styled.div`
  display: flex;
`
