import React from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import VariationGroupModal from './VariationGroupModal'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import useInteractors from '~/src/hooks/useInteractors'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    isShowing: !!state.showingItem,
    item: state.showingItem!
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const VariationGroupModalContainer = () => {
  return <VariationGroupModal {...useVariationGroup()} {...useInteractor()} />
}

export default VariationGroupModalContainer
