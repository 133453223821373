import { createAction } from 'typesafe-actions'

const INITIALIZE = 'oes/DiscountSurchargeSetting/INITIALIZE'
const CLEAR_STATE = 'oes/DiscountSurchargeSetting/CLEAR_STATE'
const CHANGE_DISCOUNT_SURCHARGE_ON_VISIT_ENABLED =
  'oes/DiscountSurchargeSetting/CHANGE_DISCOUNT_SURCHARGE_ON_VISIT_ENABLED'
const POST_SUCCEEDED = 'oes/DiscountSurchargeSetting/POST_SUCCEEDED'

export const actions = {
  initialize: createAction(
    INITIALIZE,
    ({ isDiscountSurchargeOnVisitEnabled }) => ({
      isDiscountSurchargeOnVisitEnabled
    })
  )(),
  clearState: createAction(CLEAR_STATE)(),
  changeDiscountSurchargeOnVisitEnabled: createAction(
    CHANGE_DISCOUNT_SURCHARGE_ON_VISIT_ENABLED
  )(),
  postSucceeded: createAction(POST_SUCCEEDED)()
}
