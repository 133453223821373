import React from 'react'

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { TargetServingTimeState } from '~/src/redux/modules/TargetServingTime/types'
import useInteractors from '~/src/hooks/useInteractors'
import { AppState } from '~/src/redux/reducer'
import { AuthState } from '~/src/redux/modules/Auth/types'
import { containsKM } from '~/src/util/ServiceTypeCheck'

import CategorySelectArea from './CategorySelectArea'

const authState = (state: AppState) => state.auth
const targetServingTimeState = (state: AppState) =>
  state.targetServingTimeSetting.targetServingTime

const targetServingTimeSelector = createSelector(
  targetServingTimeState,
  authState,
  (state: TargetServingTimeState, authState: AuthState) => {
    const hasError = state.targetServingTimeMap.some(value => !value.isValid())
    const isKmAvailable = containsKM(authState!.store!.serviceTypes)

    return {
      categories: state.categories,
      selectedCategoryId: state.selectedCategoryId,
      hasError,
      isKmAvailable
    }
  }
)

const useInteractor = () => ({
  interactor: useInteractors().targetServingTimeInteractor
})

const CategorySelectAreaContainer = () => {
  return (
    <CategorySelectArea
      {...useInteractor()}
      {...useSelector(targetServingTimeSelector)}
    />
  )
}

export default CategorySelectAreaContainer
