import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import equal from 'fast-deep-equal'
import Footer from '~/src/components/organisms/Footer'
import Const from '~/src/const'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DeviceCountConfirmContent from './ConfirmContent'
import { selectDeviceCountConfirmPageState } from './selectors'
import { useClearState, useSubmit } from './hooks'

const { Page } = Const

export const Confirm: React.VFC = () => {
  const { isChecked, dismissPromptWhiteList, didUpdateDeviceCount } =
    useSelector(selectDeviceCountConfirmPageState, equal)

  const handleSubmit = useSubmit()

  const handleClearState = useClearState()
  useEffect(() => {
    return handleClearState
  }, [handleClearState])

  const footer = (
    <Footer
      optionButtonLink={Page.DEVICE_COUNT._INDEX}
      submitButtonText="申込内容確定"
      disableSubmitButton={!isChecked}
      onSubmitButtonClick={handleSubmit}
    />
  )

  const modal = (
    // 当該画面は前画面からの編集状態を引き継ぎ、当該画面でsubmitするまでは編集状態として
    // 扱うため、完了時の遷移時だけ破棄モーダルを出さないようにする.
    // 本当はisModifiedでやりたかったが、変更される条件が複雑で副作用が怖いのでdidUpdateDeviceCountで行う.
    <DismissPrompt
      enabled={!didUpdateDeviceCount}
      whiteList={dismissPromptWhiteList}
    />
  )

  const breadcrumbs = [
    { title: '利用台数', page: Page.DEVICE_COUNT._INDEX },
    { title: '利用台数変更', page: Page.DEVICE_COUNT.UPDATE },
    { title: '申込内容確認' }
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayMangerOnly
      displayContractedOnly
      displayKM
      displayKP
    >
      <DeviceCountConfirmContent />
    </PageTemplate>
  )
}
