import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'
const { Color, Size } = Const

const baseColumn = styled(TableColumn)`
  text-align: left;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    padding: 0 8px;
  }
`

export const RowNumberColumn = styled(baseColumn)`
  width: 50px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    width: 35px;
  }
  text-align: right;
  word-wrap: break-word;
`
export const DeviceNameColumn = styled(baseColumn)`
  width: auto;
  min-width: 80px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const DeviceNameEditColumn = styled(baseColumn)`
  width: 30px;
  padding: 0;
  text-align: center;
`
export const DeviceIdColumn = styled(baseColumn)`
  width: 190px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    width: 170px;
  }
  word-wrap: break-word;
`
export const DeviceModelColumn = styled(baseColumn)`
  width: 170px;
  word-wrap: break-word;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    width: 90px;
  }

  > span {
    @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
      display: none;
    }
  }
`
export const AppVersionColumn = styled(baseColumn)`
  width: 90px;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    width: 60px;
  }
  word-wrap: break-word;
`
export const DeleteColumn = styled(baseColumn)`
  width: 60px;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_MD_MIN}px) {
    width: 40px;
  }
  text-align: center;
`
export const UnRegisteredColumn = styled(baseColumn)`
  color: ${Color.DARK_GRAY_3};
`
