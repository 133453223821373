import { createAction } from 'typesafe-actions'

const CLEAR_STATE = 'oes/TargetServingTime/OptionSetting/CLEAR_STATE'
const EDITED = 'oes/TargetServingTime/OptionSetting/EDITED'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  edited: createAction(EDITED)()
}

export const types = {
  CLEAR_STATE,
  EDITED
}
