type ShouldAutoApplySetting = {
  ID: string
  LABEL: string
  VALUE: boolean
}

const ShouldAutoApplySettings: {
  [key: string]: ShouldAutoApplySetting
} = {
  AUTO: {
    ID: '1',
    LABEL: '自動更新',
    VALUE: true
  },
  MANUAL: {
    ID: '2',
    LABEL: '手動更新',
    VALUE: false
  }
}

export default ShouldAutoApplySettings
