import React from 'react'
import styled from 'styled-components'
import { List } from 'immutable'
import Hint from '~/src/components/molecules/Hint'
import Header from './Header'
import ItemRow from './ItemRow'
import Const from '~/src/const'
import { SimpleItem } from '~/src/redux/models/Item/Item'
import EmptyState from '~/src/components/atoms/EmptyState'

const { Color, Size } = Const
const BALLOON_WIDTH = 316

type Props = {
  itemList: List<SimpleItem>
  isCategorySelected: boolean
  lastCachedAt: string
  isAutomaticSettingAvailable: boolean
}

const ItemList: React.FC<Props> = props => {
  const {
    itemList,
    isCategorySelected,
    lastCachedAt,
    isAutomaticSettingAvailable
  } = props

  return (
    <>
      {isAutomaticSettingAvailable && (
        <LastUpdated>
          自動設定の最終更新日時
          <Hint position="top-left" balloonWidth={BALLOON_WIDTH}>
            自動設定に設定されている商品の目標提供時間は毎日更新されます。
          </Hint>
          ：{lastCachedAt}
        </LastUpdated>
      )}
      <Header />
      {isCategorySelected &&
        itemList.map((item: SimpleItem) => (
          <ItemRow key={item.itemId} item={item} />
        ))}
      {!isCategorySelected && (
        <EmptyState>
          商品情報を一覧で表示します。
          <br />
          カテゴリーを選択してください。
        </EmptyState>
      )}
    </>
  )
}

const LastUpdated = styled.div`
  text-align: right;
  padding: 12px 0;
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
`

export default ItemList
