import { AppState } from '~/src/redux/reducer'

export const selectRow = (categoryId: string) => (state: AppState) => {
  const { batchSelect, printers, selectedPrinterHolder } =
    state.printTargetByCategories

  const isChecked = batchSelect.isCategoryChecked(categoryId)

  const selectedPrinterIds = selectedPrinterHolder.getSelectedPrinterIds(
    categoryId,
    batchSelect.getTargetSeatSetId()
  )
  const selectedPrinters = printers.getPrintersByIds(selectedPrinterIds)
  const printerNames = selectedPrinters.size
    ? selectedPrinters.map(p => p.name).join('、')
    : '-'
  return {
    isChecked,
    printerNames
  }
}
