import H1 from './h1'
import H2 from './h2'
import H3 from './h3'
import H4 from './h4'

export default {
  H1,
  H2,
  H3,
  H4
}
