import { Dispatch } from 'redux'
import actions from '~/src/redux/modules/Auth/actions'
import { Store, Token } from '~/src/redux/modules/Auth/types'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { ApiResponse } from '~/src/api/types'
import { StoresApiResponse } from '~/src/api/handy/stores/types'
import { handleError } from '~/src/redux/modules/ApiError'
import { setLoading, clearLoading, closeSplash } from '~/src/redux/modules/UI'
import Const from '~/src/const'
import { getPlanPriceType } from '~/src/const/plan-price-type'
import { initializeOnboarding } from '~/src/util/Onboarding/OnboardingClient'

const { API, Time } = Const

class AuthInteractor {
  private dispatch: Dispatch
  private apiClient: ApiClientInterface

  public constructor(
    dispatch: Dispatch,
    apiClient: ApiClientInterface = ApiClient
  ) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  public async auth() {
    try {
      this.dispatch(setLoading())
      const response = await this.apiClient.get(API.GET_STORES)
      const storeResponse: ApiResponse<StoresApiResponse> = response.data
      const { store, token } = storeResponse.result

      APP.storeId = store.storeId
      APP.storeName = store.storeName
      APP.account = token.account
      APP.plfGrant = token.plfGrant

      await initializeOnboarding(store.storeId, store.storeName, this.dispatch)

      const newStore = {
        ...store,
        planPriceType: getPlanPriceType(store.planPriceType)
      }
      this.dispatch(
        actions.getStoreSucceeded(newStore as Store, token as Token)
      )
    } catch (e) {
      this.dispatch(handleError(e))
    }

    // 一瞬で消えないように0.5秒入れる
    setTimeout(() => this.dispatch(clearLoading()), Time.LOADING)
    setTimeout(() => this.dispatch(closeSplash()), Time.SPLASH)
  }
}

export default AuthInteractor
