import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  TableRow,
  ItemNameColumn,
  SettingMethodSelectColumn,
  NoticeSecondColumn,
  AlertSecondColumn,
  AutomaticSettingConfidenceColumn
} from '../Common/TableParts'
import SelectBox from '~/src/components/atoms/SelectBox'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import ServingTimeModes, {
  servingTimeModeOptions
} from '~/src/const/serving-time-mode'
import Const from '~/src/const'
const { Color, Size } = Const

type Props = {
  interactor: TargetServingTimeInteractor
  itemId: string
  itemName: string
  targetServingTime: TargetServingTime
  estimatedTargetServingTime: EstimatedTargetServingTime
  isAutomaticSettingAvailable: boolean
}

const ItemRow: React.FC<Props> = props => {
  const {
    interactor,
    itemId,
    itemName,
    targetServingTime,
    estimatedTargetServingTime,
    isAutomaticSettingAvailable
  } = props
  const mode = targetServingTime?.mode
    ? targetServingTime.mode
    : ServingTimeModes.NON.ID

  const selectedModeCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      interactor.changeMode(itemId, e.target.value)
    },
    [itemId, interactor]
  )

  const changeNoticeMinutesCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      interactor.changeNoticeMinutes(itemId, e.target.value)
    },
    [itemId, interactor]
  )

  const changeAlertMinutesCb = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      interactor.changeAlertMinutes(itemId, e.target.value)
    },
    [itemId, interactor]
  )

  const options = servingTimeModeOptions(isAutomaticSettingAvailable)

  return (
    <TableRow>
      <ItemNameColumn>{itemName}</ItemNameColumn>
      <SettingMethodSelectColumn>
        <SelectBox
          name="setting-method-select"
          options={options}
          selectedId={mode}
          handleSelectChange={selectedModeCb}
        />
        {mode !== ServingTimeModes.AUTO.ID &&
          estimatedTargetServingTime.isHighConfidence() &&
          isAutomaticSettingAvailable && (
            <RecommendSettings>※自動設定がおすすめです</RecommendSettings>
          )}
      </SettingMethodSelectColumn>
      {mode === ServingTimeModes.MANUAL.ID && (
        <>
          <NoticeSecondColumn>
            <Input>
              <ValidatableInput
                id={`notice-${itemId}`}
                type="text"
                align="right"
                length={3}
                value={targetServingTime.noticeMinutes || ''}
                onChange={changeNoticeMinutesCb}
                error={targetServingTime.validateNoticeMinutes()}
              />
              <Text>分</Text>
            </Input>
          </NoticeSecondColumn>
          <AlertSecondColumn>
            <Input>
              <ValidatableInput
                id={`alert-${itemId}`}
                type="text"
                align="right"
                length={3}
                value={targetServingTime.alertMinutes || ''}
                onChange={changeAlertMinutesCb}
                error={targetServingTime.validateAlertMinutes()}
              />
              <Text>分</Text>
            </Input>
          </AlertSecondColumn>
        </>
      )}
      {mode === ServingTimeModes.AUTO.ID && (
        <>
          <NoticeSecondColumn>
            {estimatedTargetServingTime.displayNoticeTime()}
          </NoticeSecondColumn>
          <AlertSecondColumn>
            {estimatedTargetServingTime.displayAlertTime()}
          </AlertSecondColumn>
        </>
      )}
      {mode === ServingTimeModes.NON.ID && (
        <>
          <NoticeSecondColumn>-分-秒</NoticeSecondColumn>
          <AlertSecondColumn>-分-秒</AlertSecondColumn>
        </>
      )}
      {isAutomaticSettingAvailable && (
        <AutomaticSettingConfidenceColumn>
          {mode === ServingTimeModes.AUTO.ID && (
            <ConfidenceLabel
              isHigh={estimatedTargetServingTime.isHighConfidence()}
            />
          )}
          {mode !== ServingTimeModes.AUTO.ID && '-'}
        </AutomaticSettingConfidenceColumn>
      )}
    </TableRow>
  )
}

const Input = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Text = styled.div`
  flex: 0 0 20px;
  padding-left: 4px;
`

const ConfidenceLabel = styled.span<{ isHigh: boolean }>`
  ::after {
    content: '${props => (props.isHigh ? '高' : '低')}';
  }
  background: ${props => (props.isHigh ? Color.LIME_GREEN : Color.DARK_GRAY_4)};
  border-radius: 2px;
  color: ${Color.WHITE};
  font-weight: normal;
  font-size: ${Size.FONT.BASE}px;
  margin-left: 6px;
  padding: 2px 20px;
  white-space: nowrap;
  vertical-align: middle;
`

const RecommendSettings = styled.span`
  font-size: ${Size.FONT.XSMALL}px;
  color: ${Color.VERY_DARK_GRAY};
`

export default React.memo(ItemRow)
