import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Row } from './Row'
import { selectListState } from './selectors'
import { PrintTargetItem, PrinterByItemSeatSet } from '../types'
import { useToggle } from './hooks'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'

type Props = {
  items: PrintTargetItem[]
  seatSets: SeatSet[]
  printerByItemSeatSets: PrinterByItemSeatSet[]
}

export const List: React.VFC<Props> = ({
  items,
  seatSets,
  printerByItemSeatSets
}) => {
  const printers = useSelector(selectListState)
  const handleToggle = useToggle()

  return (
    <Main>
      {items.map(item => (
        <Row
          key={item.itemId}
          seatSets={seatSets}
          printers={printers}
          item={item}
          printerByItemSeatSets={printerByItemSeatSets}
          onToggle={handleToggle}
        />
      ))}
    </Main>
  )
}

const Main = styled.main`
  padding-bottom: 200px;
`
