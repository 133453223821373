import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { List } from 'immutable'
import ItemList from './ItemList'
import { TargetServingTimeState } from '~/src/redux/modules/TargetServingTime/types'
import { AppState } from '~/src/redux/reducer'
import { AuthState } from '~/src/redux/modules/Auth/types'
import { containsKM } from '~/src/util/ServiceTypeCheck'

const authState = (state: AppState) => state.auth
const targetServingTimeState = (state: AppState) =>
  state.targetServingTimeSetting.targetServingTime

const targetServingTimeSelector = createSelector(
  targetServingTimeState,
  authState,
  (state: TargetServingTimeState, authState: AuthState) => {
    return {
      itemList:
        state.categories.find(c => c.categoryId === state.selectedCategoryId)
          ?.items || List(),
      isCategorySelected: !!state.selectedCategoryId,
      lastCachedAt: state.lastCachedAt,
      isAutomaticSettingAvailable: containsKM(authState!.store!.serviceTypes)
    }
  }
)

const ItemListContainer = () => {
  return <ItemList {...useSelector(targetServingTimeSelector)} />
}

export default ItemListContainer
