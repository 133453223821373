import VariationGroupState from './VariationGroupState'
import GettingStarted from './GettingStarted'
import Items from './Items'
import Confirm from './Confirm'
import Downloaded from './Downloaded'

export default {
  VariationGroupState: VariationGroupState,
  GettingStarted: GettingStarted,
  Items: Items,
  Confirm: Confirm,
  Downloded: Downloaded
}
