import { createSelector } from 'reselect'
import { RegisteredDevice } from '~/src/redux/modules/RegisteredDevice/types'
import { AppState } from '~/src/redux/reducer'
import Validate, { CheckType } from '~/src/util/Validate'

export const selectEditModalState = createSelector(
  (state: AppState) => state.registeredDevice,
  ({ editTargetDevice, isEditing }) => {
    const isOpen = !!editTargetDevice
    // @ts-ignore
    const error = editTargetDevice && validate(editTargetDevice)
    const disableSaveButton = !!error || !isEditing
    const deviceName = editTargetDevice ? editTargetDevice.deviceName : ''
    return {
      isOpen,
      deviceName,
      disableSaveButton,
      error
    }
  }
)

// TODO:適切な場所へ移動
const {
  ALPHA,
  NUMERIC,
  PUNCT,
  BIGALPHA,
  BIGNUMERIC,
  BIGPUNCT,
  KATAKANA,
  HIRAGANA,
  JIS_KANJI
} = CheckType

const validate = (registeredDevice: RegisteredDevice) => {
  let error = Validate.notBlank(registeredDevice.deviceName)
  if (!error) error = Validate.maxLength(registeredDevice.deviceName, 32)
  if (!error)
    error = Validate.invalidChar(registeredDevice.deviceName, [
      ALPHA,
      NUMERIC,
      PUNCT,
      BIGALPHA,
      BIGNUMERIC,
      BIGPUNCT,
      KATAKANA,
      HIRAGANA,
      JIS_KANJI
    ])
  return error
}
