import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
const { Color, Size } = Const

type Props = {
  count: number
}

const Discription: React.FC<Props> = props => {
  return (
    <Wrapper>
      <Text>
        ※この画面では税率と金額のみ編集できます。バリエーションの変更は登録画面に戻って行ってください。
      </Text>
      <Text>
        ※Airレジの商品設定ですでにバリエーションが設定されている商品および適用税率に「注文時に選択」が設定されている商品に、商品と同じ税率・価格を適用することができないため、この画面で税率と価格を入力してください。
      </Text>
      <Text>
        ※バリエーション一括設定を行った商品のみこの画面に表示しています。ダウンロードするCSVには全
        {props.count}件の商品が含まれています。
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
  padding-bottom: 16px;
`

const Text = styled.p`
  line-height: 20px;
  margin: 0;
  padding-bottom: 8px;

  :last-child {
    padding-bottom: 0;
  }
`

export default Discription
