import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { changePolicyChecked } from '~/src/redux/modules/DeviceCount/DeviceCount'

export const useChange = (isChecked: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(changePolicyChecked(isChecked))
  }, [dispatch, isChecked])
}
