import axios from 'axios'
import Const from '~/src/const'

const { URL } = Const

export const client = axios.create({
  baseURL: URL.HANDY_URL,
  withCredentials: true
})

export type ApiClientInterface = {
  get: (path: string) => Promise<any>
  post: (path: string, data: any) => Promise<any>
}

const ApiClient: ApiClientInterface = {
  get: path => {
    return client.get(path)
  },

  post: (path, data) => {
    return client.post(path, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

export default ApiClient
