import styled from 'styled-components'
import Row from '~/src/components/atoms/TableRow'
import Column from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'
const { Color, Size } = Const

const BaseColumn = styled(Column)`
  font-size: ${Size.FONT.BASE}px;
  padding: 0 12px;
`

export const TableRow = styled(Row)<{ header?: boolean }>`
  padding: 12px 0;
  ${props =>
    props.header &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
    font-weight: bold;
    padding: 6px 0;
  `}
`

export const HandleColumn = styled(BaseColumn)`
  width: 26px;
  padding-right: 0;
`

export const NameColumn = styled(BaseColumn)`
  width: auto;
  text-align: left;
`

export const ToggleColumn = styled(BaseColumn)<{ header?: boolean }>`
  width: 110px;
  text-align: ${props => (props.header ? 'left' : 'center')};
`

export const TaxTypeColumn = styled(BaseColumn)`
  width: 130px;
  text-align: left;
`

export const PriceColumn = styled(BaseColumn)`
  width: 120px;
  text-align: right;
`

export const TrashColumn = styled(BaseColumn)`
  width: 52px;
  text-align: center;
`
