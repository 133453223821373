import { Record, List } from 'immutable'
import KitchenPositionModel from '~/src/redux/models/KitchenPositionSetting/KitchenPosition'
import { KitchenPosition } from '../../modules/KitchenPositionSetting/types'

const KitchenPositionSettingRecord = Record({
  kitchenPositionList: List<KitchenPositionModel>()
})

export default class KitchenPositionSetting extends KitchenPositionSettingRecord {
  constructor(kitchenPositionList: KitchenPosition[] = []) {
    super()

    if (kitchenPositionList) {
      const sortedKitchenPositionList = kitchenPositionList
        .map(kitchenPosition => new KitchenPositionModel(kitchenPosition))
        .sort((a, b) => a.displayOrder - b.displayOrder)

      return this.set('kitchenPositionList', List(sortedKitchenPositionList))
    }
  }

  setKitchenPositionByIndex(index: number, text: string) {
    const kitchenPosition = this.kitchenPositionList.get(index)
    if (!kitchenPosition) {
      return this
    }
    const kitchenPositions = this.kitchenPositionList.set(
      index,
      kitchenPosition.setKitchenPositionName(text)
    )
    return this.set('kitchenPositionList', kitchenPositions)
  }

  setNewKitchenPosition() {
    return this.set(
      'kitchenPositionList',
      this.kitchenPositionList.push(
        new KitchenPositionModel().set(
          'isDefault',
          !this.kitchenPositionList.some(
            kitchenPosition => kitchenPosition.isDefault
          )
        )
      )
    )
  }

  setIsDefault() {
    if (
      this.kitchenPositionList.some(
        kitchenPosition => kitchenPosition.isDefault
      )
    ) {
      return this
    }

    // デフォルト設定されている調理場が存在しない場合、一番目の調理場をデフォルトに設定するため、0を指定する
    return this.changeIsDefault(0)
  }

  deleteKitchenPosition(index: number) {
    return this.set(
      'kitchenPositionList',
      this.kitchenPositionList.delete(index)
    )
  }

  buildRequestJsonForDelete(index: number) {
    const kitchenPosition = this.kitchenPositionList.get(index)
    return {
      kitchenPositionId: kitchenPosition?.kitchenPositionId,
      version: kitchenPosition?.version
    }
  }

  isValid() {
    return this.kitchenPositionList.size <= 10
  }

  buildRequestJsonForMerge() {
    const kitchenPositions = this.kitchenPositionList.map(
      (kitchenPosition: KitchenPositionModel, index: number) => {
        return kitchenPosition.buildRequestJsonForMerge(index + 1)
      }
    )
    return {
      kitchenPositionList: kitchenPositions.toJS()
    }
  }

  hasError() {
    return !this.kitchenPositionList
      .map(kitchenPosition => !kitchenPosition.validate())
      .reduce((hasNoError = true, value) => hasNoError && value)
  }

  changeIsDefault(index: number) {
    const kitchenPositions = this.kitchenPositionList.map(
      (kitchenPosition, i: number) => {
        if (Number(index) === i) {
          return kitchenPosition.set('isDefault', true)
        }
        return kitchenPosition.set('isDefault', false)
      }
    )

    return this.set('kitchenPositionList', kitchenPositions)
  }

  setSeatSetUsableByIndex(index: number, isSeatSetUsable: boolean) {
    const kitchenPosition = this.kitchenPositionList.get(index)
    if (!kitchenPosition) {
      return this
    }
    const kitchenPositions = this.kitchenPositionList.set(
      index,
      kitchenPosition.setSeatSetUsable(isSeatSetUsable)
    )

    return this.set('kitchenPositionList', kitchenPositions)
  }

  getDefaultKitchenPositionId() {
    return this.kitchenPositionList.isEmpty()
      ? null
      : // デフォルトプリンターは必ず存在するため強制アンラップする
        this.kitchenPositionList.find(
          kitchenPosition => kitchenPosition.isDefault
        )!.kitchenPositionId
  }
}
