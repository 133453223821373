import React, { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import initCommonHeader from '~/src/util/AirCommonHeader'
import { selectAirCommonHeader } from './selectors'
import { useHandleOpenLogoutModal } from './hooks'

/**
 * Air 共通ヘッダ
 */
export const AirCommonHeader = () => {
  const { store, token } = useSelector(selectAirCommonHeader)
  const handleOpenLogoutModal = useHandleOpenLogoutModal()
  useLayoutEffect(() => {
    initCommonHeader(store, token, handleOpenLogoutModal)
  }, [handleOpenLogoutModal, store, token])
  return <Header id="air-common-header" />
}

const Header = styled.div`
  flex: 0 0 auto;
`
