import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
// @ts-ignore
import LoadingGif from '~/assets/images/loading_spinner.gif'
import Const from '~/src/const'
import { selectIsLoading } from './selectors'

const { ZIndex } = Const

export const Loading: React.VFC = () => {
  const isLoading = useSelector(selectIsLoading)
  return isLoading ? (
    <LoadingWrapper>
      <LoadingImg src={LoadingGif} />
    </LoadingWrapper>
  ) : null
}

const LoadingWrapper = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${ZIndex.AIR_OES_LOADING};
`

const LoadingImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
  height: 80px;
`
