import styled from 'styled-components'
import Const from '~/src/const'

const { Weight, Color, Size } = Const

export default styled.h3`
  font-size: ${Size.FONT.H3}px;
  font-weight: ${Weight.BOLD};
  color: ${Color.VERY_DARK_GRAY};
`
