import { SettingState } from './types'
import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'

const initialState: SettingState = {
  isEdited: false,
  handySetting: {
    isSalesDisplayed: true,
    taxDisplayTypeId: '1',
    isOtoshiEnabled: false,
    isDiscountSurchargeOnVisitEnabled: false,
    version: 1
  },
  commonSetting: {
    shouldAutoApplySettings: true,
    version: 1
  },
  isSplashShowing: true
}

export const setting = (
  state: SettingState = initialState,
  action: ActionType<typeof actions>
): SettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.fetchHandyInitialDataSucceeded): {
      return {
        ...state,
        handySetting: {
          isSalesDisplayed:
            action.payload.setting.result.setting.isSalesDisplayed,
          taxDisplayTypeId:
            action.payload.setting.result.setting.taxDisplayType,
          isOtoshiEnabled:
            action.payload.setting.result.setting.isOtoshiEnabled,
          isDiscountSurchargeOnVisitEnabled:
            action.payload.setting.result.setting
              .isDiscountSurchargeOnVisitEnabled,
          version: action.payload.setting.result.setting.version
        }
      }
    }
    case getType(actions.fetchCommonInitialDataSucceeded): {
      return {
        ...state,
        commonSetting: {
          shouldAutoApplySettings:
            action.payload.setting.result.setting.shouldAutoApplySettings,
          version: action.payload.setting.result.setting.version
        }
      }
    }
    case getType(actions.fetchInitialDataFinished): {
      return {
        ...state,
        isSplashShowing: false
      }
    }
    case getType(actions.updateHandySettingSuccess): {
      return {
        ...state,
        handySetting: {
          isSalesDisplayed:
            action.payload.setting.result.setting.isSalesDisplayed,
          taxDisplayTypeId:
            action.payload.setting.result.setting.taxDisplayType,
          isOtoshiEnabled:
            action.payload.setting.result.setting.isOtoshiEnabled,
          isDiscountSurchargeOnVisitEnabled:
            action.payload.setting.result.setting
              .isDiscountSurchargeOnVisitEnabled,
          version: action.payload.setting.result.setting.version
        },
        isEdited: false
      }
    }
    case getType(actions.updateCommonSettingSuccess): {
      return {
        ...state,
        commonSetting: {
          shouldAutoApplySettings:
            action.payload.setting.result.setting.shouldAutoApplySettings,
          version: action.payload.setting.result.setting.version
        },
        isEdited: false
      }
    }
    case getType(actions.changeIsSalesDisplayed): {
      return {
        ...state,
        handySetting: {
          ...state.handySetting,
          isSalesDisplayed: action.payload.isSalesDisplayed
        },
        isEdited: true
      }
    }
    case getType(actions.changeTaxDisplayType): {
      return {
        ...state,
        handySetting: {
          ...state.handySetting,
          taxDisplayTypeId: action.payload.taxDisplayTypeId
        },
        isEdited: true
      }
    }
    case getType(actions.changeShouldAutoApplySettings): {
      return {
        ...state,
        commonSetting: {
          ...state.commonSetting,
          shouldAutoApplySettings: action.payload.shouldAutoApplySettings
        },
        isEdited: true
      }
    }
    default:
      return {
        ...state
      }
  }
}

// export for testing
export const testables = {
  initialState
}
