import { Record, List } from 'immutable'
import OrderItemMemoTemplate from './OrderItemMemoTemplate'

const OrderItemMemoTemplatesRecord = new Record({
  orderItemMemoTemplates: List()
})

export default class OrderItemMemoTemplates extends OrderItemMemoTemplatesRecord {
  constructor(memos = []) {
    super(memos)
    const sortedMemos = memos.sort((a, b) => a.displayOrder - b.displayOrder)
    const list = sortedMemos.map(memo => new OrderItemMemoTemplate(memo))
    list.push(new OrderItemMemoTemplate()) // 最終行がフォーカスされた時に新規メモを追加するためのダミー行を追加する
    return this.set('orderItemMemoTemplates', List(list))
  }

  setMemoByIndex(index, memoText) {
    const memo = this.orderItemMemoTemplates.get(index)
    const newMemo = memo.setMemo(memoText)
    const memos = this.orderItemMemoTemplates.set(index, newMemo)
    return this.set('orderItemMemoTemplates', memos)
  }

  move(oldIndex, newIndex) {
    const tmp = this.orderItemMemoTemplates.get(oldIndex)
    const memos = this.orderItemMemoTemplates.delete(oldIndex)
    return this.set('orderItemMemoTemplates', memos.insert(newIndex, tmp))
  }

  push(memo) {
    const memos = this.orderItemMemoTemplates.push(memo)
    return this.set('orderItemMemoTemplates', memos)
  }

  delete(index) {
    const memos = this.orderItemMemoTemplates.delete(index)
    return this.set('orderItemMemoTemplates', memos)
  }

  // バリデーションし、エラーがあればメッセージを返す
  validate() {
    if (this.orderItemMemoTemplates.size - 1 > 10) {
      return '登録できる注文メモの上限数は10件です。新しく注文メモを登録する場合は、既にある注文メモを削除してください。'
    }
    return undefined
  }

  // 一つでもバリデーションエラーを含む項目(memo)があればtrueを返す
  hasError() {
    return !this.orderItemMemoTemplates
      .delete(this.orderItemMemoTemplates.size - 1) // 最終行の空メモを抜く
      .map(memo => !memo.validate())
      .reduce((hasNoError = true, value) => hasNoError && value)
  }

  buildRequestJsonForMerge() {
    const memos = this.orderItemMemoTemplates
      .delete(this.orderItemMemoTemplates.size - 1) // 最終行の空メモを抜く
      .map((memo, index) => {
        return memo.buildRequestJsonForMerge(index + 1)
      })
    return {
      orderItemMemoTemplates: memos.toJS()
    }
  }
}
