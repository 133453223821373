import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '~/src/components/atoms/Icon'
import Toggle from '~/src/components/atoms/Toggle'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import {
  TableRow,
  HandleColumn,
  NameColumn,
  ToggleColumn,
  TaxTypeColumn,
  PriceColumn,
  TrashColumn
} from '../Common/TableParts'
import { VariationEditor } from '~/src/redux/models/CSVItem/Variation'
import CONSUMPTION_TAX_TYPE from '~/src/const/consumptionTaxType'
import SelectBox from '~/src/components/atoms/SelectBox'

type Props = {
  interactor: VariationGroupInteractor
  variation?: VariationEditor // undefinedのとき行追加の為のDummyと見なす
}

const VariationRow: React.FC<Props> = props => {
  const { interactor, variation } = props
  const isDummy = variation === undefined
  const options = useMemo(
    () =>
      [
        CONSUMPTION_TAX_TYPE.STANDARD,
        CONSUMPTION_TAX_TYPE.REDUCED,
        CONSUMPTION_TAX_TYPE.NON
      ].map(taxType => ({
        id: taxType.ID,
        value: taxType.ID,
        label: taxType.TEXT
      })),
    []
  )

  const addNewVariation = useCallback(() => {
    if (isDummy) {
      interactor.addNewVariationOnEditor()
    }
  }, [interactor, isDummy])
  const onTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const next = variation!.setByName(e.target.name, e.target.value)
      interactor.updateVariationOnEditor(next)
    },
    [interactor, variation]
  )
  const onToggleChange = useCallback(() => {
    const next = variation!.setInherit(!variation?.inherit)
    interactor.updateVariationOnEditor(next)
  }, [interactor, variation])
  const onTaxTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const next = variation!.setConsumptionTaxType(e.target.value)
      interactor.updateVariationOnEditor(next)
    },
    [interactor, variation]
  )
  const onTrashClick = useCallback(() => {
    interactor.deleteVariationOnEditor(variation!.variationId)
  }, [interactor, variation])

  return (
    <TableRow>
      {!isDummy ? <SortableHandleColumn /> : <HandleColumn />}
      <StyledNameColumn>
        <ValidatableInput
          name="skuName1"
          type="text"
          placeholder="種別1"
          onFocus={addNewVariation}
          onChange={onTextChange}
          value={variation?.skuName1 || ''}
          error={variation?.validateSkuName1()}
        />
        <ValidatableInput
          name="skuName2"
          type="text"
          placeholder="種別2"
          onFocus={addNewVariation}
          onChange={onTextChange}
          value={variation?.skuName2 || ''}
          error={variation?.validateSkuName2()}
        />
      </StyledNameColumn>
      <ToggleColumn>
        <Toggle
          name="inherit"
          disabled={isDummy}
          checked={!isDummy && !variation?.inherit}
          onToggle={onToggleChange}
        />
      </ToggleColumn>
      <TaxTypeColumn>
        <SelectBox
          disabled={isDummy || variation!.inherit}
          selectedId={variation?.consumptionTaxType.ID}
          handleSelectChange={onTaxTypeChange}
          options={options}
        />
      </TaxTypeColumn>
      <PriceColumn>
        <ValidatableInput
          name="price"
          type="text"
          placeholder="0"
          align="right"
          disabled={isDummy || variation!.inherit}
          value={variation?.price !== undefined ? variation?.price : '0'}
          onChange={onTextChange}
          error={variation?.validatePrice()}
        />
      </PriceColumn>
      <TrashColumn onClick={onTrashClick}>
        {!isDummy && <Icon name="trash_box" />}
      </TrashColumn>
    </TableRow>
  )
}

const MemoizedVariationRow = React.memo(VariationRow)

const StyledNameColumn = styled(NameColumn)`
  > :not(:first-child) {
    margin-top: 10px;
  }
`

const SortableHandleColumn = SortableHandle(() => (
  <HandleColumn>
    <Icon name="sort_handle" />
  </HandleColumn>
))
const SortableRow = SortableElement((props: Props) => (
  <MemoizedVariationRow {...props} />
))

export default React.memo(SortableRow)
