import React from 'react'
import styled from 'styled-components'
import { Header } from './Header'
import { Row } from './Row/Row'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { ExclamationIcon } from '~/src/components/atoms/Icon'
import EmptyState from '~/src/components/atoms/EmptyState'
import Const from '~/src/const'
import { ITEM_COUNT_LIMIT } from '../../../const'
import { useHandleSortItem } from '../../../hooks'

const { Size } = Const

type Props = {
  items: any[]
}

export const SelectedItemList: React.VFC<Props> = props => {
  const handleSortItem = useHandleSortItem()
  return (
    <Wrapper>
      <HeaderArea>
        <HeaderTitle>お通し商品</HeaderTitle>
        <ItemCountArea>
          {(props.items || []).length > ITEM_COUNT_LIMIT && (
            <ExclamationIcon color="red" />
          )}
          計{(props.items || []).length}点
        </ItemCountArea>
      </HeaderArea>
      <MainContent id="course-detail-selected-list-main">
        <Header />
        <SortableComponent
          selectedItems={props.items}
          onSortEnd={handleSortItem}
          useDragHandle={true} // for SortableContainer
          lockAxis="y" // for SortableContainer
          getContainer={() =>
            document.getElementById(
              'course-detail-selected-list-main'
            ) as HTMLElement
          }
        />
        {(props.items || []).length === 0 && (
          <EmptyState>
            お通し商品が選択されていません。
            <br />
            左の商品一覧から登録できます。
          </EmptyState>
        )}
      </MainContent>
    </Wrapper>
  )
}

type SortableComponentProps = {
  selectedItems: any[]
}

type SortableItemProps = {
  item: any
  deleteIndex: number // indexはSortableComponentで予約されていて、使えないため冗長的な名前にする
}

const SortableComponent = SortableContainer((props: SortableComponentProps) => {
  return (
    <div>
      {props.selectedItems.map((selectedItem, i) => {
        return (
          <SortableItem key={i} index={i} item={selectedItem} deleteIndex={i} />
        )
      })}
    </div>
  )
})

const SortableItem = SortableElement((props: SortableItemProps) => {
  return <Row item={props.item} />
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`

const MainContent = styled.div`
  width: 100%;
  height: 480px;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const HeaderArea = styled.div`
  width: 100%;
  height: 30px;
  border: none;
  text-align: left;
`

const HeaderTitle = styled.span`
  font-size: ${Size.FONT.LARGE}px;
  vertical-align: middle;
  font-weight: bold;
`

const ItemCountArea = styled.div`
  font-size: ${Size.FONT.LARGE}px;
  padding-top: 3px;
  padding-right: 10px;
  font-weight: bold;
  vertical-align: middle;
  width: 100px;
  height: 30px;
  float: right;
  display: inline-block;
  border: none;
  text-align: right;
`
