import React, { useCallback } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
const { Color, Size } = Const

type Props = {
  values: Array<SegmentedValue>
  selectedId: string
  onChange: (id: string) => void
}

type SegmentedValue = {
  id: string
  text: string
}

const SegmentedControl = (props: Props) => {
  return (
    <Wrapper>
      {props.values.map((value: SegmentedValue) => (
        <Segment
          key={value.id}
          value={value}
          selected={props.selectedId === value.id}
          onChange={props.onChange}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  > div {
    border: solid 1px ${Color.VIVID_BLUE};
    border-right: none;
  }
  > :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  > :last-child {
    border-right: solid 1px ${Color.VIVID_BLUE};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

type SegmentProps = {
  value: SegmentedValue
  selected?: boolean
  onChange: (id: string) => void
}

const Segment = (props: SegmentProps) => {
  const handleClick = useCallback(() => {
    props.onChange(props.value.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onChange, props.value])

  return (
    <SegmentItem
      selected={props.selected}
      onClick={!props.selected ? handleClick : undefined}
    >
      {props.value.text}
    </SegmentItem>
  )
}

const SegmentItem = styled.div<{ selected?: boolean }>`
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  color: ${props => (props.selected ? Color.WHITE : Color.VIVID_BLUE)};
  /* stylelint-disable-next-line declaration-colon-newline-after */
  background-color: ${props =>
    props.selected ? Color.VIVID_BLUE : Color.WHITE};
  min-width: 130px;
  height: 40px;
  text-align: center;
  padding: 0 12px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 40px;
`

export default SegmentedControl
