import React, { useEffect } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import TableColumn from '~/src/components/atoms/TableColumn'
import TableRow from '~/src/components/atoms/TableRow'
import CheckBox from '~/src/components/atoms/Checkbox'
// @ts-ignore
import punBoardImage from '~/assets/images/TargetServingTime/pun_board.png'
import Hint from '~/src/components/molecules/Hint'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import {
  selectWaitingStatusSettingForValidate,
  selectWaitingStatusSetting
} from './selectors'
import { isHandyKmNotKpKmOnlySelector } from '~/src/redux/selectors/storeSelectors'
import { useSelector } from 'react-redux'
import { useInitializeReduxState } from './hooks'
import {
  useHandleSetFirstDrinkDelayMinutes,
  useHandleSetFirstFoodDelayMinutes,
  useHandleToggleFirstDrinkDelay,
  useHandleToggleFirstFoodDelay,
  useToggleAlertSeconds
} from './interactors'

const { Size, Color } = Const
const FIRST_DRINK_SETTING_WIDTH = 180
const FIRST_FOOD_SETTING_WIDTH = 227
const INPUT_MAX_LENGHT = 3
const INPUT_WIDTH = 64

type Props = {}
export const WaitingStatusSetting: React.VFC<Props> = () => {
  const isAvailableMenu = useSelector(isHandyKmNotKpKmOnlySelector)
  const handleSetFirstDrinkDelayMinutes = useHandleSetFirstDrinkDelayMinutes()
  const handleSetFirstFoodDelayMinutes = useHandleSetFirstFoodDelayMinutes()
  const handleToggleFirstDrinkDelay = useHandleToggleFirstDrinkDelay()
  const handleToggleFirstFoodDelay = useHandleToggleFirstFoodDelay()
  const handleToggleAlertSeconds = useToggleAlertSeconds()
  const {
    isFirstDrinkDelayEnabled,
    isFirstFoodDelayEnabled,
    firstDrinkDelayMinutes,
    firstFoodDelayMinutes,
    isAlertSecondsEnabled
  } = useSelector(selectWaitingStatusSettingForValidate)
  const waitingStatusState = useSelector(selectWaitingStatusSetting)
  const initializeReduxState = useInitializeReduxState(waitingStatusState)
  useEffect(() => {
    initializeReduxState()
  }, [initializeReduxState])
  if (!isAvailableMenu) {
    return <></>
  }
  return (
    <Wrapper>
      <FormWrapper>
        <SettingTitle>ハンディ</SettingTitle>
        <StyledBaseRow>
          <SettingNameArea>
            お客様をお待たせしたと判定する条件
            <Description>
              下記の設定で未調理の場合に、お待たせ状況の顔マークを赤くします。
            </Description>
            <FirstDrinkSettingRow>
              <CheckboxColumn>
                <CheckBox
                  name="option-setting-first-drink-checkbox"
                  checked={isFirstDrinkDelayEnabled}
                  disabled={false}
                  onChange={handleToggleFirstDrinkDelay}
                />
              </CheckboxColumn>
              <SettingNameColumn>
                1stドリンク
                <Hint
                  position="top-right"
                  balloonWidth={FIRST_DRINK_SETTING_WIDTH}
                >
                  お客様が最初に注文したドリンクです。
                </Hint>
              </SettingNameColumn>
              <SettingContentColumn>
                注文から
                <StyledValidatableInput
                  id="first-drink-delay-minutes-input"
                  disabled={!isFirstDrinkDelayEnabled}
                  type="text"
                  align="right"
                  maxLength={INPUT_MAX_LENGHT}
                  value={firstDrinkDelayMinutes.value}
                  onChange={handleSetFirstDrinkDelayMinutes}
                  error={firstDrinkDelayMinutes.error}
                  width={INPUT_WIDTH}
                />
                分経過
              </SettingContentColumn>
            </FirstDrinkSettingRow>
            <FirstFoodSettingRow>
              <CheckboxColumn>
                <CheckBox
                  name="option-setting-first-food-checkbox"
                  checked={isFirstFoodDelayEnabled}
                  disabled={false}
                  onChange={handleToggleFirstFoodDelay}
                />
              </CheckboxColumn>
              <SettingNameColumn>
                フード1品目
                <Hint
                  position="bottom-right"
                  balloonWidth={FIRST_FOOD_SETTING_WIDTH}
                >
                  お客様が注文した、分析タグが「フード」の商品の中で、最初に調理済みにされた商品です。
                </Hint>
              </SettingNameColumn>
              <SettingContentColumn>
                注文から
                <StyledValidatableInput
                  id="first-food-delay-minutes-input"
                  disabled={!isFirstFoodDelayEnabled}
                  type="text"
                  align="right"
                  maxLength={INPUT_MAX_LENGHT}
                  value={firstFoodDelayMinutes.value}
                  onChange={handleSetFirstFoodDelayMinutes}
                  error={firstFoodDelayMinutes.error}
                  width={INPUT_WIDTH}
                />
                分経過
              </SettingContentColumn>
            </FirstFoodSettingRow>
            <AlertMinutesSettingRow>
              <CheckboxColumn>
                <CheckBox
                  name="option-setting-alert-seconds-checkbox"
                  checked={isAlertSecondsEnabled}
                  disabled={false}
                  onChange={handleToggleAlertSeconds}
                />
              </CheckboxColumn>
              <SettingNameColumn>警告時間設定済み商品</SettingNameColumn>
              <SettingContentColumn>注文から設定時間経過</SettingContentColumn>
            </AlertMinutesSettingRow>
          </SettingNameArea>
          <ImageWrapper>
            <ImageStyle src={punBoardImage} />
          </ImageWrapper>
        </StyledBaseRow>
      </FormWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding-bottom: 50px;
  display: flex;
`

const FormWrapper = styled.div`
  margin-right: 32px;
`

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`
const SettingNameArea = styled(TableColumn)`
  text-align: left;
  width: 510px;
`
const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`

const StyledBaseRow = styled(TableRow)`
  width: 100%;
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
`

const CheckboxColumn = styled(TableColumn)`
  text-align: left;
  padding: 0;
  padding: 0 16px;
`

const SettingNameColumn = styled(TableColumn)`
  text-align: left;
  padding: 0;
  width: 192px;
`

const SettingContentColumn = styled(TableColumn)`
  text-align: left;
  padding: 0;
  width: 212px;
`
const FirstDrinkSettingRow = styled.div`
  max-width: 100%;
  height: 42px;
  margin: 12px 0;
  display: flex;
  align-items: center;
`

const FirstFoodSettingRow = styled.div`
  max-width: 100%;
  height: 42px;
  margin: 12px 0;
  display: flex;
  align-items: center;
`

const AlertMinutesSettingRow = styled.div`
  max-width: 100%;
  height: 42px;
  margin: 12px 0;
  display: flex;
  align-items: center;
`

const ImageWrapper = styled.div`
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
  padding: 32px 0;
  display: block;
`

const ImageStyle = styled.img`
  max-width: 256px;
  padding-right: 48px;
`

const StyledValidatableInput = styled(ValidatableInput)`
  display: inline-block;
  margin: 0 4px;
`
