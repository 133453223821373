import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import Accordion, { DownArrowIcon } from '~/src/components/atoms/Accordion'
import {
  CategoryRow as TableRow,
  NameColumn,
  ArrowColumn,
  FunctionColumn,
  Separater,
  StyledFunctionLink
} from '~/src/components/pages/Topping/ItemByToppingGroup/CategoryList/Common/TableParts'
import { Item } from './ItemRow/ItemRow'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import { OrderedMap, Set } from 'immutable'

type CategoryProps = {
  category?: SimpleCategoryForTopping
}

export const CategoryRow: React.VFC<CategoryProps> = React.memo(
  ({ category }) => {
    const selectedItemIds = useSelector(
      (state: AppState) => state.itemByToppingGroup.selectedItemIds
    ).toArray()
    const categories: OrderedMap<string, SimpleCategoryForTopping> =
      useSelector((state: AppState) => state.itemByToppingGroup.categories)

    // 選択状態のカテゴリー未設定商品を計算する
    const [itemIdsNotInCategories, setItemIdsNotInCategories] = useState<
      string[]
    >([])
    useEffect(() => {
      const itemIdsInCategories = categories
        .valueSeq()
        .toArray()
        .flatMap(category => category.itemIds.toArray())
      if (itemIdsNotInCategories.length === 0) {
        // 解除後も画面上に表示し続けるために、一度セットしたら更新しない
        const toBe = selectedItemIds.filter(
          selectedItemId => !itemIdsInCategories.includes(selectedItemId)
        )
        if (JSON.stringify(toBe) !== JSON.stringify(itemIdsNotInCategories)) {
          // 差分がある場合のみ更新（無限ループ回避）
          setItemIdsNotInCategories(toBe)
        }
      }
    }, [
      categories,
      selectedItemIds,
      itemIdsNotInCategories,
      setItemIdsNotInCategories
    ])

    const interactor = useInteractors().itemByToppingGroupInteractor

    const handleClickSelectAll = useCallback(() => {
      // カテゴリー未設定の場合は追加させない
      if (!category) {
        return
      }
      interactor.addAll(category!.itemIds.toSet())
    }, [category, interactor])

    const handleClickRemoveAll = useCallback(() => {
      const itemIds = category
        ? category.itemIds.toSet()
        : Set(itemIdsNotInCategories)
      interactor.removeAll(itemIds)
    }, [category, interactor, itemIdsNotInCategories])

    const haveSelected: boolean = category
      ? selectedItemIds.some(itemId => category.itemIds.contains(itemId))
      : true // カテゴリー未設定が存在する場合は必ず商品が存在している
    return (
      <Accordion
        defaultValue={haveSelected}
        Parent={props => (
          <TableRow>
            <NameColumn category>{getDisplayName(category)}</NameColumn>
            {props.isOpened && (
              <FunctionColumn>
                <StyledFunctionLink
                  needsToStopPropagation={true}
                  clickHandler={handleClickSelectAll}
                >
                  すべて選択
                </StyledFunctionLink>
                <Separater>/</Separater>
                <StyledFunctionLink
                  needsToStopPropagation={true}
                  clickHandler={handleClickRemoveAll}
                >
                  すべて解除
                </StyledFunctionLink>
              </FunctionColumn>
            )}
            <ArrowColumn>
              <DownArrowIcon isOpened={props.isOpened} />
            </ArrowColumn>
          </TableRow>
        )}
      >
        {!category &&
          itemIdsNotInCategories.map(itemId => (
            <Item
              key={itemId}
              itemId={itemId}
              isSelected={selectedItemIds.includes(itemId)}
            />
          ))}
        {category &&
          category.itemIds.map(itemId => (
            <Item
              key={itemId}
              itemId={itemId}
              isSelected={selectedItemIds.includes(itemId)}
            />
          ))}
      </Accordion>
    )
  }
)

const getDisplayName = (category?: SimpleCategoryForTopping) => {
  return category
    ? category.isDisplayed
      ? category.categoryName
      : `${category.categoryName} (非表示)`
    : '未設定'
}
