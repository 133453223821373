import { Record } from 'immutable'
import Validate, { CheckType } from '~/src/util/Validate'
const {
  ALPHA,
  NUMERIC,
  PUNCT,
  BIGALPHA,
  BIGNUMERIC,
  BIGPUNCT,
  KATAKANA,
  HIRAGANA,
  JIS_KANJI
} = CheckType

const OrderItemMemoTemplateRecord = new Record({
  orderItemMemoTemplateId: null,
  memo: null,
  isDisplayed: true,
  version: 0
})

export default class OrderItemMemoTemplate extends OrderItemMemoTemplateRecord {
  getMemo() {
    return this.memo || ''
  }

  setMemo(memo) {
    return this.set('memo', memo)
  }

  // バリデーションし、エラーがあればメッセージを返す
  validate() {
    let error = Validate.required(this.memo)
    if (!error) error = Validate.maxLength(this.memo, 10)
    if (!error)
      error = Validate.invalidChar(this.memo, [
        ALPHA,
        NUMERIC,
        PUNCT,
        BIGALPHA,
        BIGNUMERIC,
        BIGPUNCT,
        KATAKANA,
        HIRAGANA,
        JIS_KANJI
      ])
    return error
  }

  buildRequestJsonForMerge(displayOrder) {
    if (this.orderItemMemoTemplateId === null) {
      // 新規ならidとversionは不要
      return {
        orderItemMemo: this.memo,
        displayOrder: displayOrder
      }
    }
    return {
      orderItemMemoTemplateId: this.orderItemMemoTemplateId,
      orderItemMemo: this.memo,
      displayOrder: displayOrder,
      version: this.version
    }
  }

  buildRequestJsonForDelete() {
    return {
      orderItemMemoTemplateId: this.orderItemMemoTemplateId,
      version: this.version
    }
  }
}
