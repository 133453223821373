import React from 'react'
import {
  TableRow,
  NameColumn,
  TaxTypeColumn,
  PriceColumn
} from '../Common/TableParts'
import Variation from '~/src/redux/models/CSVItem/Variation'

type Props = {
  variation: Variation
}

const VariationRow: React.FC<Props> = props => {
  const { variation } = props

  return (
    <TableRow>
      <NameColumn>
        <div>{variation.skuName1}</div>
        {variation.skuName2 && <div>{variation.skuName2}</div>}
      </NameColumn>
      {!variation.inherit && (
        <>
          <TaxTypeColumn>{variation.consumptionTaxType!.TEXT}</TaxTypeColumn>
          <PriceColumn>{`¥${variation.price!.toLocaleString()}`}</PriceColumn>
        </>
      )}
    </TableRow>
  )
}

export default React.memo(VariationRow)
