import { Record, List } from 'immutable'
import Sku from './Sku'
import Const from '~/src/const'
const { ItemType, TaxType, ConsumptionTaxTypeAtOrder } = Const

// IE 対応用の軽量モデル
export type SimpleItem = {
  itemId: string
  itemName: string
}

// IE 対応用の中量級モデル
export type SimpleItemForTopping = {
  itemId: string
  itemName: string
  price?: number
  isDisplayed: boolean
  hasSku: boolean
}

interface ItemProps {
  consumptionTaxTypeAtOrder?: string
  cost?: number
  courseType?: string
  deletedAt?: number
  displayOrder: number
  isDisplayed: boolean
  isGivenPoint: boolean
  itemId: string
  itemName: string
  itemNameForInput: string
  itemNameForReceipt: string
  itemType: string
  price?: number
  skus: List<Sku>
  taxType: string
}

const ItemRecord = Record<ItemProps>({
  consumptionTaxTypeAtOrder: undefined,
  cost: undefined,
  courseType: undefined,
  deletedAt: undefined,
  displayOrder: 0,
  isDisplayed: true,
  isGivenPoint: false,
  itemId: '',
  itemName: '',
  itemNameForInput: '',
  itemNameForReceipt: '',
  itemType: '',
  price: 0,
  skus: List(),
  taxType: ''
})

export default class Item extends ItemRecord implements ItemProps {
  constructor(item: any = {}) {
    super(item)
    const skus = List(item.skus || [])
      .map((sku: any) => new Sku(sku))
      .sort((a, b) => a.displayOrder - b.displayOrder)

    // convert null to false
    this.set('isDisplayed', !!item.isDisplayed)
    this.set('isGivenPoint', !!item.isGivenPoint)
    return this.set('skus', List(skus))
  }

  // 非表示設定を考慮した表示用の商品名を返す
  // ex. 商品名 (非表示)
  getDisplayItemName() {
    return this.itemName + (this.isDisplayed ? '' : ' (非表示)')
  }

  // 税区分を考慮した表示用の金額を返す。
  // ex. ¥1,080(内税)
  getDisplayItemPrice() {
    // @ts-ignore
    return `¥${this.price.toCommaSeparatedString()}(${TaxType[this.taxType]})`
  }

  hasSKU() {
    return this.itemType === ItemType.SKU
  }

  isTaxFree() {
    return this.consumptionTaxTypeAtOrder === ConsumptionTaxTypeAtOrder.TAX_FREE
  }

  // IE 対応用の軽量モデル
  getSimpleItem(): SimpleItem {
    return {
      itemId: this.itemId,
      itemName: this.getDisplayItemName()
    }
  }

  // IE 対応用の中量級モデル
  getSimpleItemForTopping(): SimpleItemForTopping {
    return {
      itemId: this.itemId,
      itemName: this.itemName,
      price: this.price,
      isDisplayed: this.isDisplayed,
      hasSku: this.hasSKU()
    }
  }
}
