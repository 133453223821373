import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Const from '~/src/const'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import Footer from '~/src/components/organisms/Footer'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { RegisterItemModal } from '~/src/components/organisms/Modal/RegisterItemModal/RegisterItemModal'
import {
  selectIsSelectingCourse,
  selectModalCategories,
  selectIsSelectingHodai,
  selectModalHodais,
  selectInitialSelectedItems,
  selectCourseSettingState,
  selectIsSubmitButtonDisabled,
  selectIsEditing,
  selectItemIdByCourseId,
  selectInitialSelectedHodai,
  selectSelectedHodai
} from './selectors'
import {
  useSubmitCourseSelect,
  useCloseCourseSelect,
  useSubmitHodaiSelect,
  useCloseHodaiSelect,
  useInitialize,
  useClearState
} from './hooks'
import {
  useFetch,
  useHandlePostCreateCourse,
  useHandlePostUpdateCourse
} from './interactors'
import { HodaiSelectModal } from './HodaiSelectModal/HodaiSelectModal'
import { Item } from './HodaiSelectModal/List/Row/Row'
import { Messages } from './Messages/Messages'
import { selectError } from './Messages/selectors'
import { AppState } from '~/src/redux/reducer'

const { Page } = Const

export const CourseSetting: React.VFC = () => {
  const { courseId } = useParams<{ courseId: string }>()
  const isCreate = !courseId
  const createBreadcrumbs = [
    { title: 'コース', page: Page.COURSE.LIST },
    { title: isCreate ? 'コース登録' : 'コース編集' }
  ]

  // 画面初期化時のStep2の初期化
  const initialSelectedItems = useSelector((state: AppState) =>
    selectInitialSelectedItems(state, courseId)
  )
  const initialSelectedHodai = useSelector((state: AppState) =>
    selectInitialSelectedHodai(state, courseId)
  )
  const selectedHodai = useSelector(selectSelectedHodai)

  const handleFetch = useFetch()
  const initialize = useInitialize(initialSelectedItems, initialSelectedHodai)
  const clearState = useClearState()

  useEffect(() => {
    handleFetch()
  }, [handleFetch])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    return clearState
  }, [clearState])

  const handleSubmitCourseSelect = useSubmitCourseSelect()
  const handleCloseCourseSelect = useCloseCourseSelect()

  const isSelectCourse = useSelector(selectIsSelectingCourse)
  const categories = useSelector(selectModalCategories)
  const isSelectHodai = useSelector(selectIsSelectingHodai)
  const itemIdForCourse = useSelector(selectItemIdByCourseId(courseId))

  const hodais: Item[] = useSelector(selectModalHodais)
  const handleHodaiSubmit = useSubmitHodaiSelect()
  const handleCloseHodaiSelect = useCloseHodaiSelect()

  const courseSettingState = useSelector(selectCourseSettingState)
  const handlePostUpdateCourse = useHandlePostUpdateCourse(
    courseSettingState,
    courseId
  )
  const handlePostCreateCourse = useHandlePostCreateCourse(courseSettingState)
  const {
    isDeletedCourse,
    hasDeletedCourseDetail,
    hasHodaiError,
    hasEmptyCategoryItem,
    hasSku,
    hasDeletedCategory,
    exceededItemCount
  } = useSelector(selectError(itemIdForCourse))
  // コース内容の上限数は保存ボタン活性／非活性の判定で使う
  const isSubmitButtonDisabled =
    useSelector(selectIsSubmitButtonDisabled(courseId)) ||
    exceededItemCount > 0 ||
    hasDeletedCourseDetail ||
    hasHodaiError ||
    hasEmptyCategoryItem ||
    isDeletedCourse ||
    hasSku ||
    hasDeletedCategory
  const footer = (
    <Footer
      noUseOptionButton
      disableSubmitButton={isSubmitButtonDisabled}
      onSubmitButtonClick={
        courseId ? handlePostUpdateCourse : handlePostCreateCourse
      }
    />
  )

  const isEditing = useSelector(selectIsEditing)
  const modal = (
    <div>
      {isSelectCourse && (
        <RegisterItemModal
          categories={categories}
          onSubmit={handleSubmitCourseSelect}
          onClose={handleCloseCourseSelect}
        />
      )}
      {isSelectHodai && (
        <HodaiSelectModal
          displayItems={hodais}
          onSubmit={handleHodaiSubmit}
          onClose={handleCloseHodaiSelect}
          originalSelectedHodai={selectedHodai}
          hasHodaiError={hasHodaiError}
        />
      )}
      <DismissPrompt enabled={isEditing} />
    </div>
  )

  return (
    <PageTemplate
      breadcrumbs={createBreadcrumbs}
      footer={footer}
      modal={modal}
      displayKP
      displayKM
    >
      <Messages itemId={itemIdForCourse} />
      <Step1 hasWarning={isDeletedCourse || hasSku || hasDeletedCategory} />
      <Step2 />
      <Step3 hasWarning={hasHodaiError} />
    </PageTemplate>
  )
}
