import KitchenPositionSetting from '~/src/redux/models/KitchenPositionSetting/KitchenPositionSetting'
import KitchenPositionByCategories from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByCategories'
import KitchenPositionByItems from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByItems'
import KitchenPositionByItemEditor from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByItemEditor'
import Categories from '~/src/redux/models/Category/Categories'
import Items from '~/src/redux/models/Item/Items'
import Const from '~/src/const'
import { setLoading, clearLoading, showAndBlurToast } from '../UI'
import { handleError } from '~/src/redux/modules/ApiError'
import APIClient from '~/src/util/APIClient'
import { Category, KitchenPositionTargetFetchResponse } from './types'
const { API, Toast, DisplayText, UndefinedId, Time } = Const

const parentPath = 'oes/kitchenPositionTarget/'

const CLEAR_STATE = parentPath + 'CLEAR_STATE'
const FETCH_RESOURCE_SUCCESS = parentPath + 'FETCH_SUCCESS'
const CHANGE_KITCHEN_POSITION = parentPath + 'CHANGE_KITCHEN_POSITION'
const CHANGE_ANALYSIS_TAG = parentPath + 'CHANGE_ANALYSIS_TAG'
const CHANGE_IS_EDITING = parentPath + 'CHANGE_IS_EDITING'
const OPEN_ITEM_SETTING_EDITOR = parentPath + 'OPEN_ITEM_SETTING_EDITOR'
const APPLY_ITEM_SETTING = parentPath + 'APPLY_ITEM_SETTING'
const CANCEL_ITEM_SETTING_EDITOR = parentPath + 'CANCEL_ITEM_SETTING_EDITOR'
const SHOW_CANCEL_CONFIRM_MODAL = parentPath + 'SHOW_CANCEL_CONFIRM_MODAL'
const CLOSE_CANCEL_CONFIRM_MODAL = parentPath + 'CLOSE_CANCEL_CONFIRM_MODAL'
const UPDATE_ITEM_SETTING = parentPath + 'UPDATE_ITEM_SETTING'
const VALIDATE_KITCHEN_POSITION_BY_CATEGORIES =
  parentPath + 'VALIDATE_KITCHEN_POSITION_BY_CATEGORIES'
const VALIDATE_KITCHEN_POSITION_BY_CATEGORIES_WITH_FOCUS =
  parentPath + 'VALIDATE_KITCHEN_POSITION_BY_CATEGORIES_WITH_FOCUS'
const FOCUS_FINISHED = parentPath + 'FOCUS_FINISHED'
const DELETE_ITEM_SETTING = parentPath + 'DELETE_ITEM_SETTING'
const SET_SHOW_DISPLAYED_CATEGORY = parentPath + 'SET_SHOW_DISPLAYED_CATEGORY'

export const postIfValid = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { kitchenPositionByCategories } = getState().kitchenPositionTarget

    const validatedKitchenPositionByCategories =
      kitchenPositionByCategories.validateAnalysisTagsIfNeeded()
    if (validatedKitchenPositionByCategories.hasAnalysisTagError()) {
      dispatch(
        validateKitchenPositionByCategoriesWithFocus(
          validatedKitchenPositionByCategories
        )
      )
      return
    }
    return dispatch(post())
  }
}

export const post = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { kitchenPositionByCategories, kitchenPositionByItems } =
      getState().kitchenPositionTarget

    const request = {
      kitchenPositionByCategoryList:
        kitchenPositionByCategories.buildRequestObject(),
      kitchenPositionByItemList: kitchenPositionByItems.buildRequestObject()
    }

    return (
      APIClient.post(API.POST_SAVE_KITCHEN_POSITION_TARGETS, request)
        // fetchしてから保存ボタンを非活性にするとちょっと遅いからここでやる
        .then(dispatch(setLoading()))
        .then(() => dispatch(changeIsEditing(false)))
        // レジの商品も取り直すためレスポンスは使わずfetchの処理に乗せる
        .then(() => dispatch(fetchResouces()))
        .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
        .catch(error => dispatch(handleError(error)))
        .then(() => dispatch(clearLoading()))
    )
  }
}

export const fetchResouces = () => {
  // @ts-ignore
  return dispatch => {
    return Promise.all([
      APIClient.get(API.GET_ITEMS),
      APIClient.get(API.GET_KITCHEN_POSITIONS)
    ])
      .then(dispatch(setLoading()))
      .then(([itemsResponse, kitchenPositionsResponse]) => ({
        items: itemsResponse.data,
        kitchenPositions: kitchenPositionsResponse.data
      }))
      .then(responseMap => dispatch(fetchResourcesSuccess(responseMap)))
      .catch(error => dispatch(handleError(error)))
      .then(() => {
        // 一瞬で消えないように0.5秒入れる
        setTimeout(() => dispatch(clearLoading()), Time.LOADING)
      })
  }
}

// test用にexport
export const validateKitchenPositionByCategories = (
  kitchenPositionByCategories: KitchenPositionByCategories
) => ({
  type: VALIDATE_KITCHEN_POSITION_BY_CATEGORIES,
  kitchenPositionByCategories
})
export const validateKitchenPositionByCategoriesWithFocus = (
  kitchenPositionByCategories: KitchenPositionByCategories
) => ({
  type: VALIDATE_KITCHEN_POSITION_BY_CATEGORIES_WITH_FOCUS,
  kitchenPositionByCategories
})

export const focusFinished = () => ({
  type: FOCUS_FINISHED
})

export const updateKitchenPositionByCategory = (
  categoryId: string,
  kitchenPositionId: string
) => ({
  type: CHANGE_KITCHEN_POSITION,
  categoryId,
  kitchenPositionId
})

const changeIsEditing = (isEditing: boolean) => ({
  type: CHANGE_IS_EDITING,
  isEditing
})

export const updateAnalysisTagByCategory = (
  categoryId: string,
  analysisTag: string
) => ({
  type: CHANGE_ANALYSIS_TAG,
  categoryId,
  analysisTag
})

const fetchResourcesSuccess = (
  responseMap: KitchenPositionTargetFetchResponse
) => {
  const kitchenPositionSetting = new KitchenPositionSetting(
    responseMap.kitchenPositions.result.kitchenPositionList
  )

  const items = new Items(responseMap.items.result.items)

  const categories = new Categories(
    responseMap.items.result.categories,
    items,
    responseMap.items.result.itemCategoryJunctions
  )
  const kitchenPositionByCategories = new KitchenPositionByCategories(
    responseMap.kitchenPositions.result.kitchenPositionByCategoryList,
    categories,
    kitchenPositionSetting.getDefaultKitchenPositionId()
  )

  const kitchenPositionByItems = new KitchenPositionByItems(
    responseMap.kitchenPositions.result.kitchenPositionByItemList,
    (responseMap.items.result.itemCategoryJunctions || []).map(
      icj => icj.itemId
    )
  )

  // IE対応のために描画用オブジェクトを作成
  const selectableOptions = kitchenPositionSetting.kitchenPositionList
    .map(kitchenPosition => ({
      id: kitchenPosition.kitchenPositionId,
      value: kitchenPosition.kitchenPositionId,
      label: kitchenPosition.kitchenPositionName
    }))
    .push({
      id: UndefinedId.SELECTBOX_ID,
      value: UndefinedId.SELECTBOX_ID,
      label: DisplayText.NONE
    })

  return {
    type: FETCH_RESOURCE_SUCCESS,
    categories: categories.getSimpleCategoriesWithItems(),
    kitchenPositionByCategories: kitchenPositionByCategories,
    kitchenPositionByItems: kitchenPositionByItems,
    selectableOptions: selectableOptions,
    isKitchenPositionsEmpty:
      kitchenPositionSetting.kitchenPositionList.isEmpty()
  }
}

export const clearState = () => ({
  type: CLEAR_STATE
})

export const openItemSettingEditor = (categoryId: string) => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { kitchenPositionByCategories } = getState().kitchenPositionTarget

    const validatedKitchenPositionByCategories =
      kitchenPositionByCategories.validateAnalysisTagByCategoryForItemSetting(
        categoryId
      )
    if (
      validatedKitchenPositionByCategories.hasAnalysisTagErrorByCategory(
        categoryId
      )
    ) {
      // 対象カテゴリーにエラーがある場合は商品別調理場設定モーダルは表示しない
      dispatch({
        type: VALIDATE_KITCHEN_POSITION_BY_CATEGORIES,
        kitchenPositionByCategories: validatedKitchenPositionByCategories
      })

      return
    }

    return dispatch({
      type: OPEN_ITEM_SETTING_EDITOR,
      categoryId
    })
  }
}

export const applyItemSetting = () => ({
  type: APPLY_ITEM_SETTING
})

export const cancelItemSettingEditor = () => ({
  type: CANCEL_ITEM_SETTING_EDITOR
})

export const showCancelConfirmModal = () => ({
  type: SHOW_CANCEL_CONFIRM_MODAL
})

export const closeCancelConfirmModal = () => ({
  type: CLOSE_CANCEL_CONFIRM_MODAL
})

export const updateItemSetting = (
  itemId: string,
  kitchenPositionId: string,
  analysisTag: string
) => ({
  type: UPDATE_ITEM_SETTING,
  itemId,
  kitchenPositionId,
  analysisTag
})

export const deleteItemSetting = (itemId: string) => ({
  type: DELETE_ITEM_SETTING,
  itemId
})

export const setisDisplayedCategoryShowing = (
  isDisplayedCategoryShowing: boolean
) => ({
  type: SET_SHOW_DISPLAYED_CATEGORY,
  isDisplayedCategoryShowing
})

type KitchenPositionTargetState = {
  categories: Category[]
  kitchenPositionByCategories: KitchenPositionByCategories
  kitchenPositionByItems: KitchenPositionByItems
  selectableOptions: []
  isEditing: boolean
  kitchenPositionByItemEditor?: KitchenPositionByItemEditor | null
  isKitchenPositionsEmpty: boolean
  focusTargetCategoryId: string | null
  isDisplayedCategoryShowing: boolean
}

const initialState: KitchenPositionTargetState = {
  categories: [],
  kitchenPositionByCategories: new KitchenPositionByCategories(),
  kitchenPositionByItems: new KitchenPositionByItems(),
  selectableOptions: [],
  isEditing: false,
  kitchenPositionByItemEditor: null,
  isKitchenPositionsEmpty: false,
  focusTargetCategoryId: null,
  isDisplayedCategoryShowing: true
}

// Reducer
export default (
  state = initialState,
  action: any
): KitchenPositionTargetState => {
  switch (action.type) {
    case CLEAR_STATE:
      return initialState
    case FETCH_RESOURCE_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        kitchenPositionByCategories: action.kitchenPositionByCategories,
        kitchenPositionByItems: action.kitchenPositionByItems,
        selectableOptions: action.selectableOptions,
        isEditing:
          !action.isKitchenPositionsEmpty &&
          action.kitchenPositionByCategories.hasAutoCompleted(),
        isKitchenPositionsEmpty: action.isKitchenPositionsEmpty
      }
    case CHANGE_KITCHEN_POSITION:
      return {
        ...state,
        kitchenPositionByCategories:
          state.kitchenPositionByCategories.updateKitchenPositionId(
            action.categoryId,
            action.kitchenPositionId
          ),
        isEditing: true
      }
    case CHANGE_ANALYSIS_TAG: {
      const kitchenPositionByCategories =
        state.kitchenPositionByCategories.updateAnalysisTag(
          action.categoryId,
          action.analysisTag
        )
      return {
        ...state,
        kitchenPositionByCategories:
          kitchenPositionByCategories.updateAnalysisTagError(action.categoryId),
        isEditing: true
      }
    }
    case CHANGE_IS_EDITING:
      return {
        ...state,
        isEditing: action.isEditing
      }
    case OPEN_ITEM_SETTING_EDITOR: {
      const targetCategory = state.categories.find(
        category => category.categoryId === action.categoryId
      )
      const targetItemIds = targetCategory?.items.map(item => item.itemId) || []
      const filteredKitchenPositionByItems =
        state.kitchenPositionByItems.filterByItemIds(targetItemIds)
      const kitchenPositionByItemEditor = new KitchenPositionByItemEditor(
        targetCategory,
        filteredKitchenPositionByItems
      )
      return {
        ...state,
        kitchenPositionByItemEditor
      }
    }
    case APPLY_ITEM_SETTING:
      return {
        ...state,
        kitchenPositionByItems:
          state.kitchenPositionByItems.applyKitchenPositionByItemEditor(
            state.kitchenPositionByItemEditor
          ),
        kitchenPositionByItemEditor: null,
        isEditing: true
      }
    case CANCEL_ITEM_SETTING_EDITOR:
      return {
        ...state,
        kitchenPositionByItemEditor: null
      }
    case SHOW_CANCEL_CONFIRM_MODAL:
      return {
        ...state,
        kitchenPositionByItemEditor:
          state.kitchenPositionByItemEditor?.showCancelConfirmModal()
      }
    case CLOSE_CANCEL_CONFIRM_MODAL:
      return {
        ...state,
        kitchenPositionByItemEditor:
          state.kitchenPositionByItemEditor?.closeCancelConfirmModal()
      }
    case UPDATE_ITEM_SETTING:
      return {
        ...state,
        kitchenPositionByItemEditor:
          state.kitchenPositionByItemEditor?.addItemSetting(
            action.itemId,
            action.kitchenPositionId,
            action.analysisTag
          )
      }
    case VALIDATE_KITCHEN_POSITION_BY_CATEGORIES: {
      return {
        ...state,
        kitchenPositionByCategories: action.kitchenPositionByCategories
      }
    }
    case VALIDATE_KITCHEN_POSITION_BY_CATEGORIES_WITH_FOCUS: {
      const focusTargetCategoryId = action.kitchenPositionByCategories
        .getInvalidCategoryIds()
        .first()
      return {
        ...state,
        kitchenPositionByCategories: action.kitchenPositionByCategories,
        isDisplayedCategoryShowing:
          focusTargetCategoryId !== undefined
            ? true
            : state.isDisplayedCategoryShowing,
        focusTargetCategoryId
      }
    }
    case FOCUS_FINISHED:
      return {
        ...state,
        focusTargetCategoryId: null
      }
    case DELETE_ITEM_SETTING:
      return {
        ...state,
        kitchenPositionByItemEditor:
          state.kitchenPositionByItemEditor?.deleteItemSetting(action.itemId)
      }
    case SET_SHOW_DISPLAYED_CATEGORY:
      return {
        ...state,
        isDisplayedCategoryShowing: action.isDisplayedCategoryShowing
      }
    default:
      return state
  }
}
