import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { viewSeatSetNameChanged } from '~/src/redux/modules/SeatSet/SeatSet'

export const useChangeSeatSetName = () => {
  const dispatch = useDispatch()
  return useCallback(
    (param: { newValue: string }) => {
      dispatch(viewSeatSetNameChanged(param))
    },
    [dispatch]
  )
}
