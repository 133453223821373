import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import AuthFilter from './AuthFilter'
import useInteractors from '~/src/hooks/useInteractors'
import { AppState } from '~/src/redux/reducer'
import { AuthState } from '~/src/redux/modules/Auth/types'

const authSelector = createSelector(
  (state: AppState) => state.auth,
  (state: AuthState) => ({
    hasAuth: !!state.store
  })
)

const useAuth = () => {
  return useSelector(authSelector)
}
const useInteractor = () => ({
  interactor: useInteractors().authInteractor
})

const AuthFilterContainer: React.FC = props => {
  return <AuthFilter {...props} {...useInteractor()} {...useAuth()} />
}

export default AuthFilterContainer
