import styled from 'styled-components'
import Row from '~/src/components/atoms/TableRow'
import Column from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'
const { Color, Size, Weight } = Const

const BaseRow = styled(Row)`
  padding: 12px 0;
`

const BaseColumn = styled(Column)`
  font-size: ${Size.FONT.BASE}px;
  padding: 0 12px;
`

export const TableRow = styled(BaseRow)<{ header?: boolean }>`
  ${props =>
    !props.header &&
    `
    min-height: 68px;
  `}
  ${props =>
    props.header &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
    font-weight: bold;
  `}
`

export const VariationRow = styled(BaseRow)`
  margin-left: 16px;
  width: calc(100% - 16px);
`

export const NameColumn = styled(BaseColumn)<{ item?: boolean }>`
  width: auto;
  text-align: left;
  ${props => props.item && `font-weight: ${Weight.BOLD}`}
`

export const PriceColumn = styled(BaseColumn)`
  width: 120px;
  text-align: right;
`

export const TaxTypeColumn = styled(BaseColumn)`
  width: 130px;
  text-align: left;
`
