import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const/'
import { ColumnBase } from '../Column'

const { Color } = Const

export const CategoryHeader = () => (
  <StyledCategoryColumn>カテゴリー</StyledCategoryColumn>
)

const StyledCategoryColumn = styled(ColumnBase)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  height: 50px;
`
