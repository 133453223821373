import { Record } from 'immutable'
import KitchenPositionByItems from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByItems'
import { Category } from '../../modules/KitchenPositionTarget/types'
import Item from '../Item/Item'

type EditorRecord = {
  categoryId: string
  categoryName: string
  items: Item[]
  kitchenPositionByItems: KitchenPositionByItems
  isEditing: boolean
  isCancelConfirmModalShowing: boolean
}

const KitchenPositionByItemEditorRecord = Record<EditorRecord>({
  categoryId: '',
  categoryName: '',
  items: [],
  kitchenPositionByItems: new KitchenPositionByItems(),
  isEditing: false,
  isCancelConfirmModalShowing: false
})

export default class KitchenPositionByItemEditor extends KitchenPositionByItemEditorRecord {
  constructor(
    category: Category = {
      categoryId: '',
      categoryName: '',
      items: []
    },
    kitchenPositionByItems = new KitchenPositionByItems()
  ) {
    super()
    return this.merge({
      categoryId: category.categoryId,
      categoryName: category.categoryName,
      items: category.items,
      kitchenPositionByItems: kitchenPositionByItems
    })
  }

  addItemSetting(
    itemId: string,
    kitchenPositionId: string,
    analysisTag: string
  ) {
    return this.merge({
      kitchenPositionByItems:
        this.kitchenPositionByItems.updateKitchenPositionIdAndAnalysisTag(
          itemId,
          kitchenPositionId,
          analysisTag
        ),
      isEditing: true
    })
  }

  deleteItemSetting(itemId: string) {
    return this.merge({
      kitchenPositionByItems:
        this.kitchenPositionByItems.deleteByItemId(itemId),
      isEditing: true
    })
  }

  showCancelConfirmModal() {
    return this.set('isCancelConfirmModalShowing', true)
  }

  closeCancelConfirmModal() {
    return this.set('isCancelConfirmModalShowing', false)
  }
}
