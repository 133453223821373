import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Item from './Item'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'

type Props = {
  itemId: string
}

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (_: AppState, props: Props) => props,
  (state: VariationGroupState, props: Props) => {
    const item = state.items.find(item => item.itemId === props.itemId)!
    return {
      item,
      variationGroup: state.variationGroups.find(
        vg => item.variationGroupId === vg.variationGroupId
      )!
    }
  }
)

const useVariationGroup = (props: Props) => {
  return useSelector((state: AppState) => variationGroupSelector(state, props))
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const ItemContainer: React.FC<Props> = props => {
  return <Item {...useVariationGroup(props)} {...useInteractor()} />
}

export default ItemContainer
