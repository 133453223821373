import { DiscountSurchargeSettingState } from './types'
import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'

const initialState: DiscountSurchargeSettingState = {
  isDiscountSurchargeOnVisitEnabled: false,
  isEditing: false
}

export const discountSurchargeSetting = (
  state: DiscountSurchargeSettingState = initialState,
  action: ActionType<typeof actions>
): DiscountSurchargeSettingState => {
  switch (action.type) {
    case getType(actions.initialize): {
      return {
        ...state,
        isDiscountSurchargeOnVisitEnabled:
          action.payload.isDiscountSurchargeOnVisitEnabled
      }
    }
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.changeDiscountSurchargeOnVisitEnabled): {
      return {
        ...state,
        isDiscountSurchargeOnVisitEnabled:
          !state.isDiscountSurchargeOnVisitEnabled,
        isEditing: true
      }
    }
    case getType(actions.postSucceeded): {
      return {
        ...state,
        isEditing: false
      }
    }
    default: {
      return state
    }
  }
}
