import React, { useState, useCallback } from 'react'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import Forward from '~/src/components/organisms/Forward'

const ForwardErrorModal = props => {
  const [isSetForwardTo, setIsSetForwardTo] = useState(false)
  const setForwardToHome = useCallback(() => {
    setIsSetForwardTo(true)
  }, [])

  if (isSetForwardTo) {
    return <Forward when forwardPage={props.to} />
  }

  return (
    <ErrorModal
      isError
      error={{
        title: props.title,
        message: props.message
      }}
      onClose={setForwardToHome}
    />
  )
}

export default ForwardErrorModal
