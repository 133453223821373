import React from 'react'
import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const/'

const { Size } = Const

type Props = {
  children: React.ReactNode
  clickHandler?: (e: any) => void
}

export const ToppingGroupNameColumn: React.VFC<Props> = props => (
  <ToppingGroupNameColumnStyled>{props.children}</ToppingGroupNameColumnStyled>
)

export const ToppingItemCountColumn: React.VFC<Props> = props => (
  <ToppingItemCountColumnStyled>{props.children}</ToppingItemCountColumnStyled>
)

export const TargetItemCountColumn: React.VFC<Props> = props => (
  <TargetItemCountColumnStyled>{props.children}</TargetItemCountColumnStyled>
)

export const IconColumn: React.VFC<Props> = props => (
  <IconColumnStyled onClick={props.clickHandler}>
    {props.children}
  </IconColumnStyled>
)

export const HandleColumn: React.VFC<Props> = props => (
  <HandleColumnStyle>{props.children}</HandleColumnStyle>
)

const ToppingCommonColumn = styled(TableColumn)`
  text-align: left;
`

const ToppingGroupNameColumnStyled = styled(ToppingCommonColumn)`
  width: 250px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 100px;
  }
`

const ToppingItemCountColumnStyled = styled(ToppingCommonColumn)`
  width: 250px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 144px;
  }
`

const TargetItemCountColumnStyled = styled(ToppingCommonColumn)`
  width: 250px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 144px;
  }
`

const IconColumnStyled = styled(ToppingCommonColumn)`
  width: 250px;
  height: 36px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 70px;
  }
`

const HandleColumnStyle = styled(TableColumn)`
  cursor: move;
  width: 32px;
  padding: 0;
`
