import {
  ItemsApiResponse,
  CourseApiResponse,
  HodaiApiResponse,
  ItemByToppingGroupApiResponse,
  CourseDetailApiResponse,
  HodaiDetailApiResponse,
  ToppingGroupApiResponse,
  OtoshiApiResponse
} from '~/src/api/handy/items/types'

const createCourseItemIdSet = (courses?: CourseApiResponse[]) => {
  if (!courses) {
    return new Set<string>()
  }

  return courses.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

const createCourseDetailItemIdSet = (
  courseDetails?: CourseDetailApiResponse[]
) => {
  if (!courseDetails) {
    return new Set<string>()
  }

  return courseDetails.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

const createHodaiItemIdSet = (hodais?: HodaiApiResponse[]) => {
  if (!hodais) {
    return new Set<string>()
  }

  return hodais.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

const createHodaiDetailItemIdSet = (
  hodaiDetails?: HodaiDetailApiResponse[]
) => {
  if (!hodaiDetails) {
    return new Set<string>()
  }

  return hodaiDetails.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

const createItemByToppingGroupItemIdSet = (
  itemByToppingGroups?: ItemByToppingGroupApiResponse[]
) => {
  if (!itemByToppingGroups) {
    return new Set<string>()
  }

  return itemByToppingGroups.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

const createToppingGroupItemIdSet = (
  toppingGroups?: ToppingGroupApiResponse[]
) => {
  if (!toppingGroups) {
    return new Set<string>()
  }

  return toppingGroups.reduce(
    (previous: Set<string>, current) => {
      current.toppings?.forEach(topping => previous.add(topping.itemId))
      return previous
    },

    new Set<string>()
  )
}

const createOtoshiItemIdSet = (otoshis?: OtoshiApiResponse[]) => {
  if (!otoshis) {
    return new Set<string>()
  }

  return otoshis.reduce(
    (previous: Set<string>, current) => previous.add(current.itemId),
    new Set<string>()
  )
}

export const generateItemNameWithEmptyCategoryAndSku = (
  itemId: string | undefined, // itemId?: string にしたかったが省略可能と判断されるため | undefined にする
  itemsApiResponse: ItemsApiResponse
) => {
  const item = itemsApiResponse.items?.find(item => item.itemId === itemId)
  if (!item) {
    return '[削除済み]'
  }
  const variation = item.skus?.length ? '(バリエーションあり)' : undefined
  const emptyCategory = itemsApiResponse.itemCategoryJunctions?.some(
    el => el.itemId === item.itemId
  )
    ? undefined
    : '(カテゴリー未設定)'
  let itemName = item.itemName + (item.isDisplayed ? '' : ' (非表示)')

  if (variation) {
    itemName = itemName + variation
  }

  if (emptyCategory) {
    itemName = itemName + emptyCategory
  }

  if (variation || emptyCategory) {
    itemName = '[' + itemName + ']'
  }

  return itemName
}

export const createItemIdSet = (itemsApiResponse: ItemsApiResponse) => ({
  courses: createCourseItemIdSet(itemsApiResponse.courses),
  courseDetails: createCourseDetailItemIdSet(
    itemsApiResponse.courses?.flatMap(el => el.courseDetails || [])
  ),
  hodais: createHodaiItemIdSet(itemsApiResponse.hodais),
  hodaiDetails: createHodaiDetailItemIdSet(
    itemsApiResponse.hodais?.flatMap(el => el.hodaiDetails || [])
  ),
  itemByToppingGroups: createItemByToppingGroupItemIdSet(
    itemsApiResponse.itemByToppingGroups
  ),
  toppingGroups: createToppingGroupItemIdSet(itemsApiResponse.toppingGroups),
  otoshis: createOtoshiItemIdSet(itemsApiResponse.otoshis)
})
