import { Dispatch } from 'redux'
import actions from '~/src/redux/modules/VariationGroup/actions'
import Categories from '~/src/redux/models/Category/Categories'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import { VariationEditor } from '~/src/redux/models/CSVItem/Variation'
import VariationGroup from '~/src/redux/models/CSVItem/VariationGroup'
import VariationRetouch from '~/src/redux/models/CSVItem/VariationRetouch'
import CSVItemReader from '~/src/redux/models/CSVItem/CSVItemReader'
import CSVFileReader, { CSVData } from '~/src/util/CSVFileReader'
import CSVItemWriter from '~/src/redux/models/CSVItem/CSVItemWriter'
import CSVFileWriter from '~/src/util/CSVFileWriter'
import CSVFileDownloader from '~/src/util/CSVFileDownloader'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import { setLoading, clearLoading } from '~/src/redux/modules/UI'

import { handleError } from '~/src/redux/modules/ApiError'

import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
const { API, DisplayText } = Const

class VariationGroupInteractor {
  private dispatch: Dispatch
  private apiClient: ApiClientInterface

  public constructor(
    dispatch: Dispatch,
    apiClient: ApiClientInterface = ApiClient
  ) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  selectItems(itemIds: string[]) {
    this.dispatch(actions.selectItems(itemIds))
  }

  deselectItems(itemIds: string[]) {
    this.dispatch(actions.deselectItems(itemIds))
  }

  showVariationGroupModal(itemId: string) {
    this.dispatch(actions.openVariationGroupModal(itemId))
  }

  closeVariationGroupModal() {
    this.dispatch(actions.closeVariationGroupModal())
  }

  openVariationGroupEditor() {
    this.dispatch(actions.openVariationGroupEditor())
  }

  applyVariationGroupEditor() {
    this.dispatch(actions.applyVariationGroupEditor())
  }

  cancelVariationGroupEditor() {
    this.dispatch(actions.cancelVariationGroupEditor())
  }

  addNewVariationOnEditor() {
    this.dispatch(actions.addNewVariationOnEditor())
  }

  updateVariationOnEditor(variation: VariationEditor) {
    this.dispatch(actions.updateVariationOnEditor(variation))
  }

  sortVariationOnEditor(from: number, to: number) {
    this.dispatch(actions.sortVariationOnEditor(from, to))
  }

  deleteVariationOnEditor(variationId: string) {
    this.dispatch(actions.deleteVariationOnEditor(variationId))
  }

  setDisconnectVariationGroup(itemId: string) {
    this.dispatch(actions.setDisconnectVariationGroup(itemId))
  }

  clearDisconnectVariationGroup() {
    this.dispatch(actions.clearDisconnectVariationGroup())
  }

  disconnectVariationGroup() {
    this.dispatch(actions.disconnectVariationGroup())
  }

  updateVariationRetouch(itemId: string, retouch: VariationRetouch) {
    this.dispatch(actions.updateVariationRetouch(itemId, retouch))
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  validateFile(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!/\.csv$/.test(file.name)) {
        reject(DisplayText.CSV_UPLOAD_ERROR)
      }
      const reader = new CSVFileReader()
      reader
        .read(file, 'sjis')
        .then(data => {
          const itemReader = new CSVItemReader()
          const hasError = itemReader.hasError(data)
          if (!hasError) {
            resolve()
          } else {
            reject(DisplayText.CSV_UPLOAD_ERROR)
          }
        })
        .catch(() => reject(DisplayText.CSV_UPLOAD_ERROR))
    })
  }

  loadFile(file: File) {
    const csvFileReader = new CSVFileReader()
    csvFileReader
      .read(file, 'sjis')
      .then(data => {
        const csvItemReader = new CSVItemReader()
        const { results, header } = csvItemReader.read(data)
        this.dispatch(actions.fileLoadSucceeded(file, results, header))
      })
      .catch(e => console.error(e)) // TODO: error handling
  }

  rejectFile(fileErrorMessage: string) {
    this.dispatch(actions.fileLoadFailed(fileErrorMessage))
  }

  fetchCategories() {
    this.dispatch(setLoading())
    this.apiClient
      .get(API.GET_ITEMS)
      .then(response => {
        const itemsResponse: ApiResponse<ItemsApiResponse> = response.data
        const categories: Categories = new Categories(
          // @ts-ignore
          itemsResponse.result.categories
        )
        this.dispatch(
          actions.fetchCategoriesSucceeded(
            categories.getSimpleCategories().toArray()
          )
        )
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  async downloadFile(
    filename: string,
    header: CSVData,
    items: CSVItem[],
    variationGroups: VariationGroup[]
  ) {
    const results = items.map(item => item.applyVariationGroup(variationGroups))
    const csvItemWriter = new CSVItemWriter()
    const csvFileWriter = new CSVFileWriter()
    const data = csvItemWriter.write(results)
    const blob = csvFileWriter.write(header.concat(data))
    const downloader = new CSVFileDownloader()
    await downloader
      .execute(filename, blob)
      .then(() => {
        this.dispatch(actions.fileDownloadSucceeded())
      })
      .catch(e => {
        console.error(e) // TODO: error handling
        throw new Error(e)
      })
  }
}
export default VariationGroupInteractor
