import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { ValidationErrorModal } from '~/src/components/organisms/Modal/ValidationErrorModal/ValidationErrorModal'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import PageTemplate from '~/src/components/templates/PageTemplate'

import { Table } from './Table/Table'
import { Footer } from './Footer'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'
import {
  useHandleInitialize,
  useHandleSubmit,
  useHandleClearState
} from './hooks'
import { selectPrintTargetCategories } from './selectors'
import { useSelector } from 'react-redux'

const { Page, Color, URL } = Const

const VALIDATION_MESSAGE =
  '選択されていない項目があります。すべて選択してください。'

export const Categories: React.VFC = () => {
  const { isEditing, whiteList, selectedPrinterHolder, isFetched } =
    useSelector(selectPrintTargetCategories)

  const history = useHistory()
  const handleInitialize = useHandleInitialize(isFetched)
  const handleClearState = useHandleClearState()
  const handleSubmit = useHandleSubmit(!!selectedPrinterHolder.validate())
  useEffect(() => {
    handleInitialize()
    return () => {
      // 次画面が一括選択画面以外であれば、stateを初期化する。
      const regExp = new RegExp(Page.PRINTER_TARGET.BATCH_SELECT(''))
      if (!regExp.test(history.location.pathname)) {
        handleClearState()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const footer = <Footer isEditing={isEditing} onSubmit={handleSubmit} />

  const modal = (
    <div>
      <DismissPrompt enabled={isEditing} whiteList={whiteList} />
      <ValidationErrorModal>{VALIDATION_MESSAGE}</ValidationErrorModal>
    </div>
  )
  const breadcrumbs = [{ title: '印刷振り分け' }]
  const description = [
    '商品ごとに、どのキッチンプリンターに送信するか設定できます。'
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={footer}
      modal={modal}
      displayKP
    >
      <KitchenPrinterFAQ>
        ※印刷振り分けについて、詳しくは
        <InlineLink href={URL.KITCHEN_PRINTER_FAQ} target="_blank">
          キッチンプリンターの振り分け設定（プリンタープラン向け）
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </KitchenPrinterFAQ>
      <Wrapper>
        <Table />
      </Wrapper>
    </PageTemplate>
  )
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  div:focus {
    outline: none;
  }
`

const KitchenPrinterFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
