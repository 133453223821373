import React from 'react'
import { useSelector } from 'react-redux'
import ConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import {
  useHandleOpenDeleteConfirmModal,
  useHandleCloseDeleteConfirmModal
} from './hooks'
import { selectDeleteConfirmModal } from './selectors'

export const DeleteConfirmModal = () => {
  const {
    isDeleteConfirmModalOpen,
    deleteConfirmModalTitle,
    deleteConfirmModalMessage
  } = useSelector(selectDeleteConfirmModal)

  const handleOpenDeleteConfirmModal = useHandleOpenDeleteConfirmModal()
  const handleCloseDeleteConfirmModal = useHandleCloseDeleteConfirmModal()

  return (
    <ConfirmModal
      isOpen={isDeleteConfirmModalOpen}
      onCancel={handleCloseDeleteConfirmModal}
      onDelete={handleOpenDeleteConfirmModal}
      headingMessage={deleteConfirmModalTitle}
    >
      {deleteConfirmModalMessage}
    </ConfirmModal>
  )
}
