import React from 'react'
import ForwardErrorModal from '~/src/components/organisms/Modal/ErrorModal/Forward'
import Const from '~/src/const'

const { Page } = Const

type Props = {
  pageName: string
}

const ServicePlanErrorModal = (props: Props) => {
  return (
    <ForwardErrorModal
      to={Page.HOME}
      title="契約情報がありません"
      message={`${props.pageName}のご利用には、専用プランのお申込みが必要です。`}
    />
  )
}

export default ServicePlanErrorModal
