import styled, { css } from 'styled-components'

import Const from '~/src/const'
const { Color, Size } = Const

export const Validation = styled.div<{ align?: string }>`
  position: absolute;
  ${props => (props.align === 'bottom' ? 'top: 36px;' : 'bottom: 50px;')};
  ${props =>
    props.align === 'right' &&
    css`
      right: 0;
    `}
  padding: 1px 6px;
  text-align: left;
  line-height: 1.4;
  font-size: ${Size.FONT.BASE}px;
  background: ${Color.VERY_PALE_RED};
  color: ${Color.SOFT_RED};
  border: 1px solid ${Color.SOFT_RED};
  border-radius: 4px;
  -webkit-font-smoothing: auto;
  ::after {
    outline: 0;
    border: ${Color.VIVID_BLUE} solid 1px;
    content: '';
    position: absolute;
    background: ${Color.VERY_PALE_RED};
    border-color: ${Color.SOFT_RED};
    border-style: solid;
    border-width: ${props =>
      props.align === 'bottom' ? '1px 0 0 1px' : '0 1px 1px 0'};
    width: 6px;
    height: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: ${props => (props.align === 'bottom' ? '18px' : '-4px')};
    ${props =>
      props.align !== 'right' &&
      css`
        left: 20px;
      `}
    ${props =>
      props.align === 'right' &&
      css`
        right: 20px;
      `}
  }
`
