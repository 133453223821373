import { datadogLogs } from '@datadog/browser-logs'
import React from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Const from '~/src/const'
import SpoUpSellImage from '~/assets/images/SpoUpSell/spo_up_sell_promotion.png'
import SpoUpSellImageBg from '~/assets/images/SpoUpSell/spo_up_sell_promotion_bg.png'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { Datadog } from '~/src/const/datadog'

const { URL, Color } = Const

/**
 * SPOアップセル画面
 */
export const SpoUpSell: React.FC = () => {
  // RSV に遷移する処理
  const transitionToRsv = () => {
    datadogLogs.logger.info(Datadog.BROWSER_LOG_MESSAGE.RSV.MESSAGE, {
      event: Datadog.BROWSER_LOG_MESSAGE.RSV.EVENT
    })
    window.open(URL.AIR_RESERVE_URL, '_blank')
  }

  const breadcrumbs = [
    {
      title: 'モバイルオーダー 店内版のご案内'
    }
  ]

  return (
    <PageTemplate breadcrumbs={breadcrumbs} displaySpoPromotion>
      <PageContainer>
        <ImageContainer>
          <BackgroundImage />
          <ImageStyle
            src={SpoUpSellImage}
            alt="モバイルオーダー 店内版のご案内"
          />
        </ImageContainer>
        <ButtonArea>
          <StyledButton type="button" primary onClick={transitionToRsv}>
            導入相談予約する
            <RsvExternalLinkIcon />
          </StyledButton>
        </ButtonArea>
      </PageContainer>
    </PageTemplate>
  )
}

const PageContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
`

const ImageContainer = styled.div``

const ImageStyle = styled.img`
  width: 619px;
  height: 528px;
`

const BackgroundImage = styled.div`
  background-image: url(${SpoUpSellImageBg});
  background-repeat: repeat-x;
  height: 250px;
  position: absolute; /* paddingの影響を受けないようにする */
  top: 124px;
  left: 0;
  right: 0;
  z-index: -1;
`

const ButtonArea = styled.div`
  display: inline-block;
`
const RsvExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 8px;
`
const StyledButton = styled(Button)`
  &:hover ${RsvExternalLinkIcon} {
    fill: ${Color.VERY_SOFT_BLUE};
  }
  width: 296px;
  height: 42px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 14px;
`
