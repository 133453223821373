import { AppState } from '~/src/redux/reducer'

export const selectInnerExample = (state: AppState) => {
  const planPriceType = state.auth.store?.planPriceType
  return {
    flatRateCount:
      planPriceType?.FLAT_RATE_COUNT === null
        ? '-'
        : planPriceType?.FLAT_RATE_COUNT,
    flatRatePrice:
      planPriceType?.FLAT_RATE_PRICE === null
        ? '-'
        : planPriceType?.FLAT_RATE_PRICE.toLocaleString('ja-JP'),
    appendedPrice: planPriceType?.APPENDED_PRICE.toLocaleString('ja-JP')
  }
}
