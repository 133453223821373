import { AppState } from '~/src/redux/reducer'
import { HodaiApiResponse, ItemApiResponse } from '~/src/api/handy/items/types'
import { Item, CategoryItem, HodaiItem, DeleteTargetHodai } from './types'
import { checkHodai, HodaiError } from '~/src/util/ErrorChecker'

type ItemIdMap = {
  [_: string]: Item
}

type CategoryIdMap = {
  [_: string]: CategoryItem
}

export const selectHodais = (state: AppState): HodaiItem[] => {
  const response = state.api.items.response
  const hodais = response.hodais
  if (!hodais) {
    return []
  }
  const itemMap: ItemIdMap = createItemMap(response.items)
  const hodaiTopErrors: HodaiError = checkHodai(state.api.items.response)

  // CategoryItemをcategoryMapByCategoryId[categoryId]で参照できるようにする
  const categoryMapByCategoryId: CategoryIdMap = {}
  response.categories!.forEach(c => {
    categoryMapByCategoryId[c.categoryId] = {
      name: c.categoryName,
      isDisplayed: c.isDisplayed
    }
  })

  // CategoryItemを categoryMapByItemId[itemId]で参照できるようにする
  const categoryMapByItemId: CategoryIdMap = {}
  response.itemCategoryJunctions!.forEach(e => {
    categoryMapByItemId[e.itemId] = categoryMapByCategoryId[e.categoryId]
  })

  return hodais
    .sort((a, b) => b.createdAt - a.createdAt)
    .map(hodai => {
      const item = itemMap[hodai.itemId]
      const category = categoryMapByItemId[hodai.itemId]

      const detailCount =
        hodai.hodaiDetails?.filter(
          detail =>
            itemMap[detail.itemId] !== undefined && // レジで消された商品はカウントしない
            categoryMapByItemId[detail.itemId] // カテゴリ未設定の商品はカウントしない
        ).length || 0
      const hodaiItem: HodaiItem = {
        hodaiId: hodai.hodaiId,
        itemName: item?.name,
        itemDisplayName: '', // 初期値
        categoryDisplayName: '', // 初期値
        lastOrderMinutes: '', // 初期値
        price: item?.price || 0,
        taxType: item?.taxType || undefined,
        detailCount: detailCount,
        isDisplayed: item?.isDisplayed || false,
        isVariation: hodaiTopErrors.itemIdsHaveVariationHodaiPlanError.includes(
          hodai.itemId
        ),
        isCategoryDeleted:
          hodaiTopErrors.itemIdsHaveDeletedHodaiPlanCategoryError.includes(
            hodai.itemId
          ),
        isItemDeleted:
          hodaiTopErrors.itemIdsHaveDeletedHodaiPlanItemError.includes(
            hodai.itemId
          ),
        isEmptyHodai: hodaiTopErrors.itemIdsHaveItemNotHodaiPlanError.includes(
          hodai.itemId
        )
      }
      hodaiItem.itemDisplayName = generateHodaiDisplayName(hodaiItem)
      hodaiItem.categoryDisplayName = generateCategoryDisplayName(
        hodaiItem,
        category
      )
      hodaiItem.lastOrderMinutes = generateLastOrderMinutes(
        hodaiItem.isItemDeleted,
        hodai
      )
      return hodaiItem
    })
}

export const selectDeleteTargetHodai = (state: AppState): DeleteTargetHodai =>
  state.hodaiTop.deleteTargetHodai

const checkHasHodaiError = (hodai: HodaiItem) =>
  hodai.isItemDeleted || hodai.isVariation || hodai.isCategoryDeleted

export const generateHodaiDisplayName = (hodai: HodaiItem): string => {
  if (hodai.isItemDeleted) {
    return '[削除済み]'
  }
  if (!checkHasHodaiError(hodai) && hodai.itemName) {
    return hodai.isDisplayed ? hodai.itemName : hodai.itemName + '(非表示)'
  }

  const hodaiSkuString = hodai.isVariation ? '(バリエーションあり)' : null

  const hodaiCategoryString = !hodai.isCategoryDeleted
    ? null
    : '(カテゴリー未設定)'

  const hodaiPostFixString = hodaiSkuString
    ? hodaiCategoryString
      ? ''.concat(hodaiSkuString, hodaiCategoryString)
      : hodaiSkuString
    : hodaiCategoryString || ''

  return '[' + hodai.itemName + hodaiPostFixString + ']'
}

export const generateCategoryDisplayName = (
  hodaiItem: HodaiItem,
  categoryItem: CategoryItem
) => {
  if (hodaiItem.isItemDeleted) {
    return '-'
  }
  if (hodaiItem.isCategoryDeleted) {
    return '未設定'
  }
  return categoryItem.isDisplayed
    ? categoryItem.name
    : categoryItem.name + '(非表示)'
}

const createItemMap = (items?: ItemApiResponse[]) => {
  if (!items) {
    return {}
  }
  return items.reduce((previous: ItemIdMap, current) => {
    previous[current.itemId] = toItem(current)
    return previous
  }, {})
}

const toItem = (item: ItemApiResponse): Item => {
  return {
    id: item.itemId,
    name: item.itemName,
    price: item.price || 0,
    taxType: item.taxType,
    isDisplayed: item.isDisplayed
  }
}

export const generateLastOrderMinutes = (
  isItemDeleted: boolean,
  hodai: HodaiApiResponse
): string => {
  if (isItemDeleted) {
    return '-'
  }
  if (typeof hodai.lastOrderMinutes === 'undefined') {
    return '注文時設定'
  }
  return `${hodai.lastOrderMinutes}分`
}
