/**
 * グローバル APP オブジェクトのモック
 */

module.exports = {
  storeId: '{storeId}',
  storeName: '{storeName}',
  account: '{account}',
  plfGrant: '{plfGrant}',
  plfGoodsInfoFlg: false
}
