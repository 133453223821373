import { Record } from 'immutable'
import Validate from '~/src/util/Validate'
import { TargetServingTimeApiRequest } from '~/src/api/km/targetServingTimes/types'
import ServingTimeModes from '~/src/const/serving-time-mode'

const BAD_RELATION_ERROR = '注意時間より長くしてください'

const DEFAULT_NOTICE_MINUTES = '10'
const DEFAULT_ALERT_MINUTES = '20'

const MINUTES_MAX_LENGTH = 3

interface TargetServingTimeProps {
  itemId: string
  noticeMinutes?: string
  alertMinutes?: string
  estimatedNoticeSecond?: number
  estimatedAlertSecond?: number
  confidence: number
  mode: string
}

const TargetServingTimeRecord = Record<TargetServingTimeProps>({
  itemId: '',
  noticeMinutes: undefined,
  alertMinutes: undefined,
  estimatedNoticeSecond: undefined,
  estimatedAlertSecond: undefined,
  confidence: -1,
  mode: ServingTimeModes.NON.ID
})

class TargetServingTime
  extends TargetServingTimeRecord
  implements TargetServingTimeProps
{
  public setModeToManual(): TargetServingTime {
    return this.set('noticeMinutes', DEFAULT_NOTICE_MINUTES)
      .set('alertMinutes', DEFAULT_ALERT_MINUTES)
      .set('mode', ServingTimeModes.MANUAL.ID)
  }

  public setModeToAuto(): TargetServingTime {
    return this.set('noticeMinutes', undefined)
      .set('alertMinutes', undefined)
      .set('mode', ServingTimeModes.AUTO.ID)
  }

  public setModeToNon(): TargetServingTime {
    return this.set('noticeMinutes', undefined)
      .set('alertMinutes', undefined)
      .set('mode', ServingTimeModes.NON.ID)
  }

  public validateNoticeMinutes(): string | undefined {
    // 手動以外はvalidationしない
    if (this.mode !== ServingTimeModes.MANUAL.ID) {
      return
    }

    const error =
      Validate.required(this.noticeMinutes) ||
      Validate.number(this.noticeMinutes)
    if (error) {
      return error
    }
  }

  public setNoticeMinutes(noticeMinutes: string): TargetServingTime {
    // ３文字までしか入力させない
    if (noticeMinutes != null && noticeMinutes.length > MINUTES_MAX_LENGTH) {
      return this
    }

    return this.set('noticeMinutes', noticeMinutes)
  }

  public validateAlertMinutes(): string | undefined {
    // 手動以外はvalidationしない
    if (this.mode !== ServingTimeModes.MANUAL.ID) {
      return
    }

    const error =
      Validate.required(this.alertMinutes) || Validate.number(this.alertMinutes)
    if (error) {
      return error
    }

    if (!this.noticeMinutes || !this.alertMinutes) {
      return
    }

    if (parseInt(this.noticeMinutes) > parseInt(this.alertMinutes)) {
      return BAD_RELATION_ERROR
    }
  }

  public isValid(): boolean {
    return !this.validateNoticeMinutes() && !this.validateAlertMinutes()
  }

  public setAlertMinutes(alertMinutes: string): TargetServingTime {
    // ３文字までしか入力させない
    if (alertMinutes != null && alertMinutes.length > MINUTES_MAX_LENGTH) {
      return this
    }

    return this.set('alertMinutes', alertMinutes)
  }

  static validateTime(modeId: string, time: string): string | undefined {
    // 手動以外はvalidationしない
    if (modeId !== ServingTimeModes.MANUAL.ID) {
      return
    }
    const error = Validate.required(time) || Validate.number(time)
    if (error) {
      return error
    }
  }

  static validateAlertTime(
    modeId: string,
    noticeTime: string,
    alertTime: string
  ): string | undefined {
    const error = TargetServingTime.validateTime(modeId, alertTime)
    if (error) {
      return error
    }
    const parsedNoticeSeconds = parseInt(noticeTime)
    const parsedAlertSeconds = parseInt(alertTime)
    if (parsedNoticeSeconds > parsedAlertSeconds) {
      return BAD_RELATION_ERROR
    }
  }

  public toRequest(): TargetServingTimeApiRequest {
    const noticeSecond: number | undefined =
      this.noticeMinutes != null ? parseInt(this.noticeMinutes) * 60 : undefined
    const alertSecond: number | undefined =
      this.alertMinutes != null ? parseInt(this.alertMinutes) * 60 : undefined
    return {
      itemId: this.itemId,
      noticeSecond: noticeSecond,
      alertSecond: alertSecond,
      mode: this.mode
    }
  }
}

export default TargetServingTime
