import Const from '~/src/const/'
import { AppState } from '~/src/redux/reducer'
import { Printer } from '../types'
const { UndefinedId, DisplayText } = Const

export const selectListState = (state: AppState): Printer[] => {
  const printers = state.printTargetByItems.printers
    .sort((a, b) => a.createdAt - b.createdAt)
    .map(printer => ({
      printerId: printer.printerId,
      name: printer.name
    }))

  printers.push({
    printerId: UndefinedId.PRINTER_ID,
    name: DisplayText.UNPRINT_LABEL
  })
  return printers
}
