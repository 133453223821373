// Forward 系モジュール
// API処理完了後等に別画面へ遷移させたいときに使用する

// Action Types
const SET_SHOULD_FORWARD = 'oes/forward/SET_SHOULD_FORWARD'

// Actions

/**
 * 画面遷移設定をセットする
 */
export const setShouldForward = shouldForward => ({
  type: SET_SHOULD_FORWARD,
  shouldForward: shouldForward
})

// Initial state
const initialState = {
  shouldForward: false
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOULD_FORWARD: {
      return {
        shouldForward: action.shouldForward
      }
    }
    default:
      return state
  }
}
