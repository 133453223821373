import React, { useEffect } from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { Setting } from './setting'
import Footer from '~/src/components/organisms/Footer'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import {
  selectEditing,
  selectDiscountSurchargeOnVisitEnabled,
  selectHandySettingApiState
} from './selectors'
import { useSelector } from 'react-redux'
import { usePostUpdateHandySetting, useFetch } from './interactors'
import { useInitializeReduxState } from './hooks'

export const DiscountSurchargeSetting: React.VFC = () => {
  const breadcrumbs = [{ title: '割引・割増' }]
  const isEditing = useSelector(selectEditing)
  const isDiscountSurchargeOnVisitEnabled = useSelector(
    selectDiscountSurchargeOnVisitEnabled
  )
  const handySettingApiState = useSelector(selectHandySettingApiState)
  const handlePostUpdateHandySetting = usePostUpdateHandySetting(
    isDiscountSurchargeOnVisitEnabled,
    handySettingApiState
  )
  const footer = (
    <Footer
      noUseOptionButton
      disableSubmitButton={!isEditing}
      onSubmitButtonClick={handlePostUpdateHandySetting}
    />
  )
  const modal = <DismissPrompt enabled={isEditing} />
  const handleInitializeReduxState =
    useInitializeReduxState(handySettingApiState)
  useEffect(handleInitializeReduxState, [handleInitializeReduxState])
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={[
        '来店処理の際に、割引・割増条件を設定する画面を必ず表示できるようになります。これにより割引・割増設定の漏れやミスを防ぐことができます。'
      ]}
      footer={footer}
      modal={modal}
      initializer={useFetch()}
      displayKP
      displayKM
    >
      <Setting
        isDiscountisDiscountSurchargeOnVisitEnabled={
          isDiscountSurchargeOnVisitEnabled
        }
      />
    </PageTemplate>
  )
}
