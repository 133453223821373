import React from 'react'
import styled from 'styled-components'
import Checkbox from '~/src/components/atoms/Checkbox'
import {
  CheckboxColumn,
  TargetTableColumn,
  FloorNameColumn
} from './../Columns'
import TableRow from '~/src/components/atoms/TableRow'
import { selectSeatSetDetailListRow } from './selectors'
import { useSelectSeatSetSeat } from './hooks'
import { Seat } from '~/src/redux/modules/SeatSet/types'
import { useSelector } from 'react-redux'

export default ({ seat }: { seat: Seat }) => {
  const { isChecked, isDisabled, seatSet } = useSelector(
    selectSeatSetDetailListRow(seat.seatId)
  )

  const handleSelectSeatSetSeat = useSelectSeatSetSeat()

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectSeatSetSeat({ seatId: seat.seatId, checked: e.target.checked })
  }

  return (
    <EditableRowLabel disabled={isDisabled}>
      <TableRow>
        <CheckboxColumn>
          <Checkbox
            name="seat-checkbox"
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleCheckboxClick}
          />
        </CheckboxColumn>
        <TargetTableColumn disabled={isDisabled}>
          {seat.seatName}
        </TargetTableColumn>
        <FloorNameColumn disabled={isDisabled}>
          {seatSet && seatSet.name}
        </FloorNameColumn>
      </TableRow>
    </EditableRowLabel>
  )
}

const EditableRowLabel = styled.label`
  ${(props: { disabled: boolean }) => !props.disabled && `cursor: pointer;`}
`
