import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ENVIRONMENT_TYPE, ENVIRONMENT } from '~/src/const/environment'
import { showOnboardingButton } from '~/src/redux/modules/UI'

// 参考: https://help.onboarding-app.io/b893017a7d654cb5bd0d694bdabe524f
export const initializeOnboarding = async (
  storeId: string,
  storeName: string,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  const ONB = ONB || {}
  ONB.ignition_url =
    'https://api.onboarding-app.io/v1/onboarding-init?aid=124&pid=140'
  if (ENVIRONMENT !== ENVIRONMENT_TYPE.PRODUCTION) {
    ONB.ignition_url += '&display_mode=preview'
  }

  /**
   * Onboardingのready関数のコールバック関数
   * 詳細：https://help.onboarding-app.io/b893017a7d654cb5bd0d694bdabe524f#b3acc197e3b9415a87b1fc69db08073c
   * @param firstDisplayed 初めてのOnboarding起動かどうか
   */
  // @ts-ignore
  window.onBoardingReady = (firstDisplayed: boolean) => {
    dispatch(showOnboardingButton())
  }

  // Custom Area Start=====================
  ONB._queryparam = {
    user_id: storeId,
    user_name: storeName,
    user_group_id: '',
    user_group_name: ''
  }
  ONB.black_list = []
  ONB._custom_functions = {
    ready: 'onBoardingReady'
  }

  // Custom Area End======================
  ONB.embed = function () {
    for (ONB.item in ONB._queryparam) {
      ONB.ignition_url +=
        '&' + ONB.item + '=' + encodeURIComponent(ONB._queryparam[ONB.item])
    }
    for (ONB.d = 0; ONB.d < ONB.black_list.length; ONB.d++) {
      if (location.href.indexOf(ONB.black_list[ONB.d]) !== -1) {
        return
      }
    }
    if (Object.keys(ONB._custom_functions).length > 0) {
      ONB.ignition_url +=
        '&custom_functions=' +
        encodeURIComponent(JSON.stringify(ONB._custom_functions))
    }
    ONB.b = document.createElement('script')
    ONB.c = document.getElementsByTagName('head')[0]
    ONB.b.src = ONB.ignition_url
    ONB.b.id = 'stands_onbd_point'
    ONB.b.charset = 'utf-8'
    ONB.b.async = 'async'
    ONB.c.appendChild(ONB.b)
  }
  ONB.embed()
}

export const showOnboarding = () => {
  STANDSMotion.showIntro()
}
