import { Dispatch, SetStateAction, useEffect } from 'react'
import { getKoruliUrl } from '~/src/util/Koruli'
import axios from 'axios'

export type KoruliResponse = {
  link: string
  image: string
}

export const useFetchData = (
  setData: Dispatch<SetStateAction<KoruliResponse | null>>
) => {
  useEffect(() => {
    const fetchDataAndSetData = async () => {
      const result = await getData()
      setData(result)
    }
    fetchDataAndSetData()
  }, [setData])
}

const getData = async (): Promise<KoruliResponse | null> => {
  try {
    const koruliUrl = getKoruliUrl()
    const response = await axios.get(koruliUrl)
    const data = response.data

    if (data.length === 1) {
      const item = data[0]
      const link = `https:${item.link_url}`
      const image = `https:${item.image_url}`
      return { link, image }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}
