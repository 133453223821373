import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import { Row } from './Row/Row'
import { ContentColumn, FunctionColumn } from './Column/Column'
import { FunctionLink } from '~/src/components/atoms/FunctionLink'
import EmptyState from '~/src/components/atoms/EmptyState'
import Const from '~/src/const'
import { useReleaseAll } from '../hooks'
import { Category } from '../../types'
const { Size, Color } = Const

type Props = {
  categories: Category[]
}

export const SelectedItemList: React.VFC<Props> = props => {
  const itemCount = props.categories.flatMap(category => category.items).length
  const handleUseReleaseAll = useReleaseAll()
  return (
    <Wrapper>
      <HeaderArea>
        <HeaderTitle>放題プラン内容</HeaderTitle>
        <ItemCountArea>{`計 ${itemCount} 点`}</ItemCountArea>
      </HeaderArea>
      <MainContent>
        <TableHeader>
          <ContentColumn>商品</ContentColumn>
          <FunctionColumn>
            <FunctionLink onClick={handleUseReleaseAll}>
              すべて解除
            </FunctionLink>
          </FunctionColumn>
        </TableHeader>
        {props.categories.map(category => (
          <Row
            key={`key-category-${category.categoryId}`}
            category={category}
          />
        ))}
        {props.categories.length === 0 && (
          <EmptyState>
            放題プラン内容が登録されていません。
            <br />
            左の商品一覧から登録できます。
          </EmptyState>
        )}
      </MainContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`

const HeaderArea = styled.div`
  height: 30px;
  vertical-align: middle;
`

const HeaderTitle = styled.span`
  font-size: ${Size.FONT.LARGE}px;
  vertical-align: middle;
  font-weight: bold;
`

const MainContent = styled.div`
  width: 100%;
  height: 480px;
  border: 1px solid ${Color.LIGHT_GRAY_4};
  border-radius: 6px;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const TableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`

const ItemCountArea = styled.span`
  font-size: ${Size.FONT.LARGE}px;
  font-weight: bold;
  text-align: right;
  height: 30px;
  vertical-align: middle;
  float: right;
  padding-right: 10px;
`
