import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'

import Footer from './Footer'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    isCsvUploaded: !!state.file
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const FooterContiner = () => {
  return <Footer {...useVariationGroup()} />
}

export default FooterContiner
