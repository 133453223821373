export type ConsumptionTaxType = {
  ID: string
  CSV_ID: string
  TEXT: string
}

export const fromCsvId = (
  csvId: '標準税率' | '軽減税率' | '注文時に選択' | '非課税'
): ConsumptionTaxType => {
  switch (csvId) {
    case '標準税率':
      return STANDARD
    case '軽減税率':
      return REDUCED
    case '非課税':
      return NON
    case '注文時に選択':
      return CHOICE
  }
}

export const fromId = (id?: string): ConsumptionTaxType | undefined => {
  switch (id) {
    case '02':
      return STANDARD
    case '03':
      return REDUCED
    case '04':
      return NON
    case '05':
      return CHOICE
  }
}

const STANDARD: ConsumptionTaxType = {
  ID: '02',
  CSV_ID: '標準税率',
  TEXT: '10%標準'
}

const REDUCED: ConsumptionTaxType = {
  ID: '03',
  CSV_ID: '軽減税率',
  TEXT: '8%軽減'
}

const NON: ConsumptionTaxType = {
  ID: '04',
  CSV_ID: '非課税',
  TEXT: '非課税'
}

const CHOICE: ConsumptionTaxType = {
  ID: '05',
  CSV_ID: '注文時に選択',
  TEXT: '注文時に選択'
}

const CONSUMPTION_TAX_TYPE = {
  STANDARD,
  REDUCED,
  NON,
  CHOICE
}

export default CONSUMPTION_TAX_TYPE
