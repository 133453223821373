import { ActionType, getType } from 'typesafe-actions'
import { WaitingStatusState } from './types'
import { actions } from './actions'

const initialState = {
  isAlertSecondsEnabled: false,
  isFirstDrinkDelayEnabled: true,
  isFirstFoodDelayEnabled: true,
  firstDrinkDelayMinutes: '5',
  firstFoodDelayMinutes: '20'
}

export const waitingStatusSetting = (
  state: WaitingStatusState = initialState,
  action: ActionType<typeof actions>
): WaitingStatusState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      const state = action.payload.waitingStatusState
      return {
        ...state,
        isAlertSecondsEnabled: state.isAlertSecondsEnabled,
        isFirstDrinkDelayEnabled: state.isFirstDrinkDelayEnabled,
        isFirstFoodDelayEnabled: state.isFirstFoodDelayEnabled,
        firstDrinkDelayMinutes: state.firstDrinkDelayMinutes,
        firstFoodDelayMinutes: state.firstFoodDelayMinutes
      }
    }
    case getType(actions.toggleAlertSeconds): {
      return {
        ...state,
        isAlertSecondsEnabled: !state.isAlertSecondsEnabled
      }
    }

    case getType(actions.toggleFirstDrinkDelay): {
      return {
        ...state,
        isFirstDrinkDelayEnabled: !state.isFirstDrinkDelayEnabled
      }
    }

    case getType(actions.toggleFirstFoodDelay): {
      return {
        ...state,
        isFirstFoodDelayEnabled: !state.isFirstFoodDelayEnabled
      }
    }

    case getType(actions.setFirstDrinkDelayMinutes): {
      return {
        ...state,
        firstDrinkDelayMinutes: action.payload.firstDrinkDelayMinutes
      }
    }

    case getType(actions.setFirstFoodDelayMinutes): {
      return {
        ...state,
        firstFoodDelayMinutes: action.payload.firstFoodDelayMinutes
      }
    }

    default:
      return state
  }
}
