import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Checkbox from '~/src/components/atoms/Checkbox'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'
import equal from 'fast-deep-equal'
import { useChange } from './hooks'
import { selectInnerPolicy } from './selectors'

const { Color, URL } = Const

export const InnerPolicy = () => {
  const { isChecked, flatRateCount, flatRatePrice, appendedPrice } =
    useSelector(selectInnerPolicy, equal)

  const handleCheckboxClick = useChange(!isChecked)

  return (
    <ConfirmPolicy>
      <Description>
        ※1店舗あたりハンディ端末{flatRateCount}台まで
        {flatRatePrice}
        円（税込）、
        <FontRed>
          1台追加ごとに
          {appendedPrice}
          円（税込）
        </FontRed>
        が加算請求されます。 <br />
        ※月額従量は毎月1日00:00から末日23:59の期間中で利用端末台数が最大の日の台数をもとに計算されます。
      </Description>
      <Border>
        <PolicyArea>
          <PolicyList>金額は、税込みです。</PolicyList>
          <PolicyList>
            お申込み後のキャンセルは、いかなる理由においてもお受けいたしかねます。
          </PolicyList>
          <PolicyList>
            法規・公序良俗・弊社掲載基準に反する場合など、弊社にて不適当と判断させていただいた場合(暴力団、暴力団員及び暴力的行為を行うものに該当する場合を含みます)には、サービスのご利用をお断りする場合がございます。
          </PolicyList>
          <PolicyList>
            当社が、サービスの一部または全部を変更または廃止した場合、お申込みいただいた内容をお受けできかねる場合がございます。
          </PolicyList>
        </PolicyArea>
        <PolicyLinkArea>
          <InlineLink href={URL.ORDER_KIYAKU} target="_blank">
            Airレジ オーダー 利用約款
          </InlineLink>
        </PolicyLinkArea>
        <PolicyLinkArea>
          <InlineLink href={URL.REGI_COMMON_TERMS} target="_blank">
            Airサービス共通利用約款
          </InlineLink>
        </PolicyLinkArea>
      </Border>
      <CheckArea>
        <CheckBoxArea>
          <Checkbox checked={isChecked} onChange={handleCheckboxClick} />
        </CheckBoxArea>
        <CheckTextArea>
          上記利用約款および申込みに関する注意事項に同意の上申込みます。
        </CheckTextArea>
      </CheckArea>
    </ConfirmPolicy>
  )
}

const ConfirmPolicy = styled.div`
  padding: 16px 0;
`

const Description = styled.div`
  padding-bottom: 24px;
  text-align: left;
  color: ${Color.DARK_GRAY_4};
  line-height: 1.5;
`

const FontRed = styled.div`
  display: inline-block;
  color: ${Color.MODERATE_RED};
`

const Border = styled.div`
  border: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-radius: 8px;
`

const PolicyArea = styled.div`
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  padding: 16px;
  line-height: 1.5;
`

const PolicyLinkArea = styled.div`
  padding: 16px;
  display: inline-block;
`

const CheckArea = styled.label`
  display: table;
  text-align: center;
  padding: 8px;
  margin: auto;
`

const CheckBoxArea = styled.div`
  display: table-cell;
  width: 50px;
  padding: 8px;
`

const CheckTextArea = styled.div`
  display: table-cell;
  padding: 8px;
`

const PolicyList = styled.li`
  list-style: none;
  ::before {
    content: '・';
  }
`

export default InnerPolicy
