import React from 'react'
import styled from 'styled-components'

import { HelpIcon } from '~/src/components/atoms/Icon'
import Balloon, { Position } from '~/src/components/atoms/Balloon'

type Props = {
  className?: string
  balloonWidth?: number
  small?: boolean
  link?: string
  position?: Position
}

const Hint: React.FC<Props> = props => {
  const { className, small, balloonWidth, link, position } = props
  return (
    <Wrapper className={className}>
      <HelpIcon small={small?.toString()} />
      <Balloon balloonWidth={balloonWidth} link={link} position={position}>
        {props.children}
      </Balloon>
    </Wrapper>
  )
}

const Wrapper = styled.span`
  display: inline-block;
  position: relative;
  padding: 0;
  padding-left: 6px;
  height: 20px;
  vertical-align: middle;
  :hover {
    span {
      display: inline-block;
    }
  }
`

export default Hint
