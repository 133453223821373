import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import { ToppingListState } from '~/src/redux/modules/Topping/ToppingList/types'
import useInteractors from '~/src/hooks/useInteractors'
import { ToppingList } from './ToppingList'

const ToppingSelector = createSelector(
  (state: AppState) => state.toppingList,
  (state: AppState) => state.UI,
  (toppingState: ToppingListState, uiState: any) => {
    // 削除モーダル用
    const toppingGroupForDelete =
      toppingState.toppingGroups.toppingGroupsMap.get(
        toppingState.toppingGroupIdForDeleteModal
      )

    const toppingGroupIdAndVersions = toppingState.toppingGroupIds.map(
      toppingGroupId => ({
        toppingGroupId: toppingGroupId,
        version:
          toppingState.toppingGroups.toppingGroupsMap.get(toppingGroupId)!
            .version
      })
    )

    return {
      isDeleteConfirmModalOpen: uiState.isDeleteConfirmModalOpen,
      toppingGroupIdForDeleteModal: toppingState.toppingGroupIdForDeleteModal,
      deleteDialogHeaderMessage: toppingGroupForDelete
        ? toppingGroupForDelete.createDeleteDialogHeaderMessage()
        : 'トッピンググループ設定の解除',
      deleteDialogMessage: toppingGroupForDelete
        ? toppingGroupForDelete.createDeleteDialogMessage()
        : '',
      warningMessages: toppingState.warningMessages,
      cautionMessages: toppingState.cautionMessages,
      toppingGroupIdAndVersions: toppingGroupIdAndVersions,
      isEditing: toppingState.isEdited,
      openMaxToppingGroupModal: toppingState.openMaxToppingGroupModal,
      toppingGroupsCount: toppingState.toppingGroupIds.size
    }
  }
)

const useTopping = () => useSelector(ToppingSelector)

const useInteractor = () => ({
  interactor: useInteractors().toppingListInteractor
})

const ToppingListContainer = () => (
  <ToppingList {...useInteractor()} {...useTopping()} />
)

export { ToppingListContainer as ToppingList }
