import moment from 'moment'
import { createSelector } from 'reselect'
import { format } from '~/src/const/formatter'
import { AppState } from '~/src/redux/reducer'

export const selectInnerTable = createSelector(
  (state: AppState) => state.deviceCount,
  (state: AppState) => state.auth.store,
  ({ summary, confirmedDeviceCount }, store) => {
    const newDeviceCountMax =
      confirmedDeviceCount && confirmedDeviceCount > summary.billingTargetCount
        ? confirmedDeviceCount
        : summary.billingTargetCount

    const billingTargetCount = summary.billingTargetCount
    const flatRateCount =
      store?.planPriceType.FLAT_RATE_COUNT === null
        ? '-'
        : store?.planPriceType.FLAT_RATE_COUNT
    const deviceCountAppended = !store?.planPriceType.FLAT_RATE_COUNT
      ? '-'
      : Math.max(billingTargetCount - store.planPriceType.FLAT_RATE_COUNT, 0)
    const newDeviceCountAppended = !store?.planPriceType.FLAT_RATE_COUNT
      ? '-'
      : Math.max(newDeviceCountMax - store.planPriceType.FLAT_RATE_COUNT, 0)
    return {
      currentCount: summary.currentCount,
      newDeviceCountMax: newDeviceCountMax,
      billingTargetDate: moment(summary.billingTargetDate).format(
        format.YYYY_MM_DD
      ),
      requestAt: moment(summary.requestedAt).format(format.YYYY_MM_JP),
      confirmedDeviceCount: confirmedDeviceCount,
      billingTargetCount,
      flatRateCount,
      deviceCountAppended,
      newDeviceCountAppended
    }
  }
)
