import { Record } from 'immutable'
import {
  CategoryApiResponse,
  ItemApiResponse,
  ItemCategoryJunctionApiResponse,
  ToppingApiResponse
} from '~/src/api/handy/items/types'
import Categories from '../Category/Categories'
import Items from '../Item/Items'

interface ToppingProps {
  toppingId: string
  toppingGroupId: string
  displayOrder: number
  itemId: string
  hasSku: boolean
  isTaxFree: boolean
  isNotSetCategory: boolean
}

const ToppingRecord = Record<ToppingProps>({
  toppingId: '',
  toppingGroupId: '',
  displayOrder: -1,
  itemId: '',
  hasSku: false,
  isTaxFree: false,
  isNotSetCategory: false
})

export class Topping extends ToppingRecord implements ToppingProps {
  constructor(
    topping: ToppingApiResponse,
    items: ItemApiResponse[],
    categories: CategoryApiResponse[],
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[]
  ) {
    super(topping)
    const itemsModel = new Items(items)
    const categoriesModel = new Categories(
      categories,
      itemsModel,
      itemCategoryJunctions
    )

    const item = itemsModel.items.get(this.itemId)
    if (item === undefined || item === null) {
      return
    }
    const categorizedItemIds = categoriesModel.categories
      .valueSeq()
      .toList()
      .flatMap(category =>
        category.items.items.valueSeq().map(item => item.itemId)
      )
    const isNotSetCategory = !categorizedItemIds.contains(item.itemId)
    return this.set('hasSku', item.hasSKU())
      .set('isTaxFree', item.isTaxFree())
      .set('isNotSetCategory', isNotSetCategory)
  }
}
