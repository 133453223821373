import { List, Record, Set } from 'immutable'
import {
  CategoryApiResponse,
  ItemApiResponse,
  ItemByToppingGroupApiResponse,
  ItemCategoryJunctionApiResponse,
  ToppingApiResponse,
  ToppingGroupApiResponse
} from '~/src/api/handy/items/types'
import { NoticeIconType } from '~/src/redux/modules/Topping/ToppingList/types'
import { ItemByToppingGroup } from '../Item/ItemByToppingGroup'
import { Toppings } from './Toppings'

export const MAX_NUM_TOPPINGS = 30

interface ToppingGroupProps {
  displayOrder: number
  name: string
  storeId: string
  toppingGroupId: string
  toppings: ToppingApiResponse[]
  toppingsModel: Toppings
  toppingCount: number
  targetItemCount: number
  version: number
}

const ToppingGroupRecord = Record<ToppingGroupProps>({
  displayOrder: -1,
  name: '',
  storeId: '',
  toppingGroupId: '',
  toppings: [],
  toppingsModel: new Toppings(),
  toppingCount: 0,
  targetItemCount: 0,
  version: 0
})

export class ToppingGroup
  extends ToppingGroupRecord
  implements ToppingGroupProps
{
  static readonly MAX_AVAILABLE_COUNT = 50

  constructor(
    toppingGroup: ToppingGroupApiResponse = {
      displayOrder: -1,
      name: '',
      storeId: '',
      toppingGroupId: '',
      toppings: [],
      version: 0
    },
    items: ItemApiResponse[] = [],
    categories: CategoryApiResponse[] = [],
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[] = [],
    itemByToppingGroups: ItemByToppingGroupApiResponse[] = []
  ) {
    super(toppingGroup)

    // トッピンググループが含むトッピングがレジBOで消されている場合があるので、商品マスタに含まれているものだけ数える
    const masterItemIds = items.map(item => item.itemId)
    const toppingCount = toppingGroup.toppings
      ? toppingGroup.toppings.filter(topping =>
          masterItemIds.includes(topping.itemId)
        ).length
      : 0
    const targetItemCount = itemByToppingGroups
      ? itemByToppingGroups
          .filter(
            itemByToppingGroup =>
              itemByToppingGroup.toppingGroupId === toppingGroup.toppingGroupId
          )
          .filter(itemByToppingGroup =>
            masterItemIds.includes(itemByToppingGroup.itemId)
          ).length
      : 0
    return this.set(
      'toppingsModel',
      new Toppings(
        toppingGroup.toppings,
        items,
        categories,
        itemCategoryJunctions
      )
    )
      .set('toppingCount', toppingCount)
      .set('targetItemCount', targetItemCount)
  }

  public createDeleteDialogMessage() {
    return `「${this.name}」のトッピンググループ設定を解除します。\nよろしいですか？\n※商品は削除されません`
  }

  public createDeleteDialogHeaderMessage() {
    return `「${this.name}」の解除`
  }

  public hasItemHasSku() {
    return this.toppingsModel.toppingModels.some(topping => topping.hasSku)
  }

  public hasItemTaxFree() {
    return this.toppingsModel.toppingModels.some(topping => topping.isTaxFree)
  }

  public hasItemNotSetCategory() {
    return this.toppingsModel.toppingModels.some(
      topping => topping.isNotSetCategory
    )
  }

  public hasItem() {
    return this.toppingCount !== 0
  }

  public hasTargetItemTopping() {
    return this.targetItemCount !== 0
  }

  public createNoticeIconTypeSet(
    itemByToppingGroups: List<ItemByToppingGroup>
  ) {
    let noticeIconTypeSet: Set<NoticeIconType> = Set()
    if (
      itemByToppingGroups.size > 0 &&
      (itemByToppingGroups.some(
        itemByToppingGroup => itemByToppingGroup.isTaxFree
      ) ||
        itemByToppingGroups.some(
          itemByToppingGroups => itemByToppingGroups.isNotSetCategory
        ))
    ) {
      noticeIconTypeSet = noticeIconTypeSet.add('ITEM_BY_TOPPING_GROUP_WARNING')
    }
    if (
      this.hasItemHasSku() ||
      this.hasItemTaxFree() ||
      this.hasItemNotSetCategory()
    ) {
      noticeIconTypeSet = noticeIconTypeSet.add('TOPPING_WARNING')
    }
    if (!this.hasTargetItemTopping()) {
      noticeIconTypeSet = noticeIconTypeSet.add('TARGET_COUNT_CAUTION')
    }
    if (!this.hasItem()) {
      noticeIconTypeSet = noticeIconTypeSet.add('ITEM_COUNT_CAUTION')
    }
    return noticeIconTypeSet
  }
}
