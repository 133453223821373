import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import useInteractors from '~/src/hooks/useInteractors'
import { AppState } from '~/src/redux/reducer'
import { Row } from './Row'
import {
  NoticeIconType,
  ToppingListState
} from '~/src/redux/modules/Topping/ToppingList/types'
import { Set } from 'immutable'

type Props = {
  toppingGroupId: string
}
const ToppingSelector = createSelector(
  (state: AppState) => state.toppingList,
  (_: AppState, props: Props) => props.toppingGroupId,
  (toppingState: ToppingListState, toppingGroupId: string) => {
    const toppingGroup =
      toppingState.toppingGroups.toppingGroupsMap.get(toppingGroupId)!
    const itemByToppingGroups =
      toppingState.itemByToppingGroups.itemByToppingGroups.filter(
        itemByToppingGroup =>
          itemByToppingGroup.toppingGroupId === toppingGroupId
      )
    const noticeIconTypeSet: Set<NoticeIconType> =
      toppingGroup.createNoticeIconTypeSet(itemByToppingGroups)
    return {
      toppingGroupId: toppingGroupId,
      name: toppingGroup.name,
      toppingCount: toppingGroup.toppingCount,
      targetItemCount: toppingGroup.targetItemCount,
      noticeIconTypeSet: noticeIconTypeSet,
      hasTargetItem: toppingGroup.hasTargetItemTopping(),
      hasTopping: toppingGroup.hasItem()
    }
  }
)

const useTopping = (props: Props) => ({
  ...useSelector((state: AppState) => ToppingSelector(state, props))
})

const useInteractor = () => ({
  interactor: useInteractors().toppingListInteractor
})

const RowContiner = (props: Props) => (
  <Row {...useInteractor()} {...useTopping(props)} />
)

export { RowContiner as Row }
