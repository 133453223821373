import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import Icon from '~/src/components/atoms/Icon'

import Const from '~/src/const'
const { Color } = Const

export type RejectFile = {
  file: File
  cause: string
}

type Props = {
  fileType: 'text/csv'
  filenameExample: string
  selectedFile?: File
  validator?: (file: File) => Promise<void>
  onFileAccepted: (file: File) => void
  onFileRejected: (files: RejectFile) => void
}

const FileUploader: React.FC<Props> = props => {
  const { fileType, selectedFile, validator, onFileAccepted, onFileRejected } =
    props

  const fileInputRef = useRef<HTMLInputElement>(null)
  const onFileInputClickCb = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    fileInputRef.current!.click()
  }, [])
  const onDragOverCb = useCallback((e: React.DragEvent) => {
    e.preventDefault()
  }, [])
  const onDropCb = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.persist()
    e.stopPropagation()
    const file = e.dataTransfer.files.item(0)!
    validateFile(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onFileChangeCb = useCallback(() => {
    const files = fileInputRef.current!.files
    validateFile(files!.item(0)!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateFile = useCallback((file: File) => {
    if (validator) {
      validator(file)
        .then(() => onFileAccepted(file))
        .catch(error => onFileRejected({ file: file, cause: error }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DropZone onDragOver={onDragOverCb} onDrop={onDropCb}>
      <>
        <IconContainer>
          {!selectedFile ? <FoldedDocument /> : <CircledCheck />}
        </IconContainer>
      </>
      <Desciption>
        {!selectedFile && (
          <>
            {props.filenameExample}を<br />
            ここにドラッグアンドドロップしてアップロード
            <br />
            もしくは<a onClick={onFileInputClickCb}>ファイルを選択する</a>
          </>
        )}
        {selectedFile && (
          <>
            {selectedFile.name}
            <br />
            をアップロード完了
            <br />
            <a onClick={onFileInputClickCb}>ファイルを変更する</a>
          </>
        )}
      </Desciption>
      <input
        type="file"
        hidden
        accept={fileType}
        ref={fileInputRef}
        onChange={onFileChangeCb}
      />
    </DropZone>
  )
}

const IconContainer = styled.div`
  display: inline-block;
  padding: 12px;
`

const CircledCheck = styled(Icon).attrs({ name: 'circled_check' })``
const FoldedDocument = styled(Icon).attrs({ name: 'folded_document' })``

const DropZone = styled.div`
  padding: 20px;
  border: dashed 2px ${Color.DARK_GRAY};
  border-radius: 4px;
  text-align: center;
  min-height: 200px;
`

const Desciption = styled.div`
  display: inline-block;
  width: 100%;
  color: ${Color.DARK_GRAY};
`

export default FileUploader
