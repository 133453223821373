import React, { useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Footer from '~/src/components/organisms/Footer'
import styled from 'styled-components'
import { ListArea } from '~/src/components/pages/Topping/ItemByToppingGroup/List/ListArea'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import { ErrorMessages } from './ErrorMessages'

import Const from '~/src/const'
const { Page, Color, Weight } = Const

export const ItemByToppingGroup: React.VFC = () => {
  const { toppingGroupId } = useParams<{ toppingGroupId: string }>()
  const isEdited = useSelector(
    (state: AppState) => state.itemByToppingGroup.isEdited
  )
  const isNotFound = useSelector(
    (state: AppState) => state.itemByToppingGroup.isToppingGroupNotFound
  )
  const history = useHistory()
  const interactor = useInteractors().itemByToppingGroupInteractor

  const handleCloseNotFoundModal = useCallback(() => {
    history.push(Page.TOPPING.LIST)
  }, [history])

  const modal = (
    <>
      <DismissPrompt enabled={isEdited} />
      <ErrorModal
        isError={isNotFound}
        error={{
          title: '対象のデータが存在しません',
          message: 'トッピング画面に戻ります。'
        }}
        onClose={handleCloseNotFoundModal}
      />
    </>
  )

  const postParams = useSelector(postParamSelector)
  const toppingGroupName = useSelector(
    (state: AppState) => state.itemByToppingGroup.toppingGroup.name
  )

  useEffect(() => {
    interactor.clearState()
    interactor.fetchInitialData(toppingGroupId)
    return () => {
      interactor.clearState()
    }
  }, [interactor, toppingGroupId])

  const handleClickSubmitButton = useCallback(async () => {
    await interactor.save(postParams.toppingGroupId, postParams.selectedItemIds)
    history.push(Page.TOPPING.LIST)
  }, [interactor, postParams, history])

  const breadcrumbs = [
    { title: 'トッピング', page: Page.TOPPING.LIST },
    { title: '対象商品の選択' }
  ]

  const footer = (
    <Footer
      noUseOptionButton
      disableSubmitButton={!isEdited}
      onSubmitButtonClick={handleClickSubmitButton}
    />
  )

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayKM
      displayKP
    >
      <ErrorMessages />
      <Addition>
        <MainText>
          トッピンググループの割り当て先にしたい対象商品を選んでください。
        </MainText>
        <SubText>
          ※コースおよび放題プランとして登録されている商品、税設定が「非課税」で設定されている商品、お通し商品は選択できません。
        </SubText>
      </Addition>
      <ToppingGroupName>
        トッピンググループ： {toppingGroupName}
      </ToppingGroupName>
      <ListArea />
    </PageTemplate>
  )
}

const postParamSelector = createSelector(
  (state: AppState) => state.itemByToppingGroup.toppingGroup.toppingGroupId,
  (state: AppState) => state.itemByToppingGroup.selectedItemIds,
  (toppingGroupId, selectedItemIds) => ({
    toppingGroupId,
    selectedItemIds
  })
)

const ToppingGroupName = styled.div`
  font-weight: ${Weight.BOLD};
  color: ${Color.VERY_DARK_GRAY};
  font-size: 16px;
  padding-bottom: 16px;
`

const Addition = styled.div`
  padding-bottom: 24px;
`

const MainText = styled.div`
  font-size: 16px;
  line-height: 22px;
`

const SubText = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: 14px;
  line-height: 20px;
`
