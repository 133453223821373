import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import Router from './Router'
import Extension from '~/src/extension' // eslint-disable-line no-unused-vars
import GlobalStyles from '~/src/util/GlobalStyles'
import commonHeaderObject from './__mocks__/app'
import { setupDatadog } from '~/src/util/Datadog'

setupDatadog()

window.APP = commonHeaderObject

const store = configureStore()

render(
  <Provider store={store}>
    <GlobalStyles />
    <Router />
  </Provider>,
  document.getElementById('root')
)
