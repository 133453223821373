import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '~/src/pages/Otoshi/actions'
import { SortEnd } from 'react-sortable-hoc'
import { SelectedItem } from './types'

export const useHandleToggleOtoshiEnabled = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.toggleOtoshiEnabled())
    dispatch(actions.edited())
  }, [dispatch])
}

export const useHandleClickItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (item: SelectedItem) => () => {
      if (item.disabled) {
        return
      }
      dispatch(actions.selectItemName(item))
    },
    [dispatch]
  )
}

export const useHandleClickTrashButton = () => {
  const dispatch = useDispatch()
  return useCallback(
    (itemId: string) => () => {
      dispatch(actions.removeItem(itemId))
    },
    [dispatch]
  )
}

export const useHandleClickAllRelease = () => {
  const dispatch = useDispatch()
  return useCallback(
    () => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault()
      dispatch(actions.removeAllItems())
    },
    [dispatch]
  )
}

export const useHandleSortItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (sort: SortEnd) => {
      dispatch(actions.sortItem(sort.oldIndex, sort.newIndex))
    },
    [dispatch]
  )
}
