import styled from 'styled-components'
import Row from '~/src/components/atoms/TableRow'
import Column from '~/src/components/atoms/TableColumn'
// TODO: 共通化
import { FunctionLink } from '~/src/components/pages/Topping/ToppingGroup/Common/Link'
import Const from '~/src/const'
const { Color, Size, Weight } = Const

const BaseRow = styled(Row)`
  padding: 0;
`

const BaseColumn = styled(Column)`
  font-size: ${Size.FONT.BASE}px;
  padding: 12px 12px;
`

export const TableRow = styled(BaseRow)<{ header: boolean }>`
  ${props =>
    props.header &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
    font-weight: bold;
  `}
`

export const CategoryRow = styled(BaseRow)`
  height: 60px;
  cursor: pointer;
`

export const ItemRow = styled(BaseRow)`
  height: 60px;
  width: 100%;
`

export const CheckboxColumn = styled(BaseColumn)`
  width: 32px;
  text-align: left;
  padding: 18px 24px 11px;
  padding-right: 0;
`

export const IconColumn = styled(BaseColumn)`
  width: 16px;
  padding: 16px 8px 11px;
  padding-right: 0;
`

export const NameColumn = styled(BaseColumn)<{
  category?: boolean
  error?: boolean
  disabled?: boolean
}>`
  width: auto;
  text-align: left;
  padding: 18px 24px 11px;
  ${props => props.category && `font-weight: ${Weight.BOLD}`}
  ${props =>
    props.disabled &&
    `
  pointer-events: none;
  color: ${Color.LIGHT_GRAY_4};
  `}
  ${props =>
    props.error &&
    `
  padding: 16px 6px 11px;
  `}
`

export const FunctionColumn = styled(BaseColumn)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 18px 24px 11px;
  margin-left: auto;
`

export const Separater = styled(BaseColumn)`
  padding: 2px;
`

export const ArrowColumn = styled(BaseColumn)<{ onClick?: any }>`
  width: 52px;
  text-align: center;
  ${props => !!props.onClick && 'cursor: pointer;'}
`

export const StyledFunctionLink = styled(FunctionLink)`
  padding: 0;
`
