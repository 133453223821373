import { createAction } from 'typesafe-actions'
import { SelectedItem } from './types'

const CLEAR_STATE = 'oes/OtoshiSetting/CLEAR_STATE'
const INITIALIZE = 'oes/OtoshiSetting/INITIALIZE'
const EDITED = 'oes/OtoshiSetting/EDITED'
const SUBMIT_SUCCEEDED = 'oes/OtoshiSetting/SUBMIT_SUCCEEDED'
const TOGGLE_OTOSHI_ENABLED = 'oes/OtoshiSetting/TOGGLE_OTOSHI_ENABLED'
const SELECT_ITEM_NAME = 'oes/OtoshiSetting/SELECT_ITEM_NAME'
const REMOVE_ITEM = 'oes/OtoshiSetting/REMOVE_ITEM'
const REMOVE_ALL_ITEMS = 'oes/OtoshiSetting/REMOVE_ALL_ITEMS'
const SORT_ITEM = 'oes/OtoshiSetting/SORT_ITEM'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(
    INITIALIZE,
    (isOtoshiEnabled: boolean, selectedItems: SelectedItem[]) => ({
      isOtoshiEnabled,
      selectedItems
    })
  )(),
  edited: createAction(EDITED)(),
  submitSucceeded: createAction(SUBMIT_SUCCEEDED)(),
  toggleOtoshiEnabled: createAction(TOGGLE_OTOSHI_ENABLED)(),
  selectItemName: createAction(
    SELECT_ITEM_NAME,
    (selectedItem: SelectedItem) => ({
      selectedItem
    })
  )(),
  removeItem: createAction(REMOVE_ITEM, (itemId: string) => ({ itemId }))(),
  removeAllItems: createAction(REMOVE_ALL_ITEMS)(),
  sortItem: createAction(SORT_ITEM, (oldIndex: number, newIndex: number) => ({
    oldIndex,
    newIndex
  }))()
}

export const types = {
  CLEAR_STATE,
  INITIALIZE,
  EDITED,
  SUBMIT_SUCCEEDED,
  TOGGLE_OTOSHI_ENABLED,
  SELECT_ITEM_NAME,
  REMOVE_ITEM,
  REMOVE_ALL_ITEMS,
  SORT_ITEM
}
