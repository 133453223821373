import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { RadioButton } from '~/src/common/atoms/RadioButton'
import TableRow from '~/src/components/atoms/TableRow'
import TableColumn from '~/src/components/atoms/TableColumn'
import { SettingToggle } from '~/src/components/molecules/SettingToggle'
// @ts-ignore
import cookingNoticeImage from '~/assets/images/TargetServingTime/cooking_notice.png'
import {
  useHandleToggleCookingNoticeEnabled,
  useHandleSelectOrderTicketSortType
} from './interactors'
import {
  selectAvailableMenu,
  selectCookingNoticeEnabled,
  selectOrderTicketSortTypeId
} from './selectors'
import { useInitializeReduxState } from './hooks'

const { Size, OrderTicketSortType } = Const

export const KmSetting: React.VFC = () => {
  const isAvailableMenu = useSelector(selectAvailableMenu)
  const isCookingNoticeEnabled = useSelector(selectCookingNoticeEnabled)
  const selectedOrderTicketSortTypeId = useSelector(selectOrderTicketSortTypeId)
  const handleToggleCookingNoticeEnabled = useHandleToggleCookingNoticeEnabled()
  const handleSelectOrderTicketSortType = useHandleSelectOrderTicketSortType()

  const initializeReduxState = useInitializeReduxState()
  useEffect(initializeReduxState, [initializeReduxState])

  if (!isAvailableMenu) {
    return <></>
  }
  return (
    <Wrapper>
      <FormWrapper>
        <SettingTitle>キッチンモニター</SettingTitle>
        <SettingToggle
          onToggle={handleToggleCookingNoticeEnabled}
          checked={isCookingNoticeEnabled}
          title="着手アラート表示"
          description="注意時間を過ぎると、注文商品に着手アラートが表示されます。"
        />
        <StyledBaseRow>
          <OrderTicketSortTypeNameArea>
            注文内の商品並び順
          </OrderTicketSortTypeNameArea>
          <RadioButton
            name="orderTicketSortTypeSelect"
            value={OrderTicketSortType.BY_NOTICE_SECOND.ID}
            label={OrderTicketSortType.BY_NOTICE_SECOND.TEXT}
            onChange={handleSelectOrderTicketSortType}
            checked={
              selectedOrderTicketSortTypeId ===
              OrderTicketSortType.BY_NOTICE_SECOND.ID
            }
          />
          <RadioButton
            name="orderTicketSortTypeSelect"
            value={OrderTicketSortType.BY_SELECTED_AT.ID}
            label={OrderTicketSortType.BY_SELECTED_AT.TEXT}
            onChange={handleSelectOrderTicketSortType}
            checked={
              selectedOrderTicketSortTypeId ===
              OrderTicketSortType.BY_SELECTED_AT.ID
            }
          />
        </StyledBaseRow>
      </FormWrapper>
      <ImageWrapper>
        <ImageStyle src={cookingNoticeImage} />
      </ImageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 50px;
  display: flex;
`

const FormWrapper = styled.div`
  margin-right: 32px;
`

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`

const ImageWrapper = styled.div`
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
  padding: 32px 0;
  display: block;
`

const ImageStyle = styled.img`
  max-width: 256px;
  padding-right: 48px;
`

const StyledBaseRow = styled(TableRow)`
  width: 480px;
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
  display: inline-flex;
  align-items: center;
`

const OrderTicketSortTypeNameArea = styled(TableColumn)`
  text-align: left;
  width: 200px;
`
