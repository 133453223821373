import styled, { css } from 'styled-components'

import Const from '~/src/const'

const { Color, Size } = Const

const styles = {
  required: css`
    content: '必須';
  `,
  either_required: css`
    content: 'いずれか必須';
  `
}

export default styled.span<{ name: 'required' | 'either_required' }>`
  ::after {
    ${props => styles[props.name]}
  }
  background: ${Color.SOFT_RED};
  border-radius: 2px;
  color: ${Color.WHITE};
  font-weight: normal;
  font-size: ${Size.FONT.XSMALL}px;
  margin-left: 6px;
  padding: 2px 4px;
  white-space: nowrap;
  vertical-align: middle;
`
