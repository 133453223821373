import React, { useEffect } from 'react'
import styled from 'styled-components'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import Forward from '~/src/components/organisms/Forward'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import { BatchSelectHeader } from './Header/Header'
import { PrinterSelect } from './PrinterSelect/PrinterSelect'
import { CategorySelect } from './CategorySelect/CategorySelect'
import { BatchSelectFooter } from './Footer/Footer'
import Const from '~/src/const/'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { selectBatchSelect } from './selectors'
import {
  useClearState,
  useCloseNotFoundErrorModal,
  useDeinit,
  useInitialize
} from './hooks'
const { Page } = Const

export const BatchSelect: React.VFC = () => {
  const { whiteList, isDismissPromptEnable, isNotFound, shouldForward } =
    useSelector(selectBatchSelect)
  const handleCloseNotFoundErrorModal = useCloseNotFoundErrorModal()
  const params = useParams<{ seatSetId: string }>()
  const seatSetId = params.seatSetId

  const handleInitialize = useInitialize(seatSetId)
  const handleDeinit = useDeinit()
  const handleClearState = useClearState()
  const history = useHistory()
  useEffect(() => {
    handleInitialize()
    return () => {
      handleDeinit()
      // 次画面が印刷振り分け画面以外であれば、stateを初期化する。
      const regExp = new RegExp(Page.PRINTER_TARGET.CATEGORIES)
      if (!regExp.test(history.location.pathname)) {
        handleClearState()
      }
    }
  }, [handleClearState, handleDeinit, handleInitialize, history])

  const breadcrumbs = [
    { title: '印刷振り分け', page: Page.PRINTER_TARGET.CATEGORIES },
    { title: 'プリンター一括選択' }
  ]

  const footer = <BatchSelectFooter />

  const modal = (
    <div>
      <DismissPrompt whiteList={whiteList} enabled={isDismissPromptEnable} />
      <ErrorModal
        isError={isNotFound}
        error={{
          title: '対象のデータが存在しません',
          message: '印刷振り分け設定画面に戻ります。'
        }}
        onClose={handleCloseNotFoundErrorModal}
      />
    </div>
  )
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayKP
    >
      <Addition>
        カテゴリーに対して、選択したプリンターを印刷振り分け画面に一括反映できます。
      </Addition>
      <BatchSelectHeader />
      <PrinterSelect />
      <CategorySelect />
      <Forward
        when={shouldForward}
        forwardPage={Page.PRINTER_TARGET.CATEGORIES}
        after={0}
      />
    </PageTemplate>
  )
}

const Addition = styled.div`
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 22px;
`
