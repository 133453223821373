export type PlanPriceTypeId = '0' | '1' | '2'

/**
 * 利用料金タイプ
 */
export type PlanPriceType = {
  ID: PlanPriceTypeId
  FLAT_RATE_COUNT: number | null
  FLAT_RATE_PRICE: number | null
  APPENDED_PRICE: number
}

const UNSPECIFIED: PlanPriceType = {
  ID: '0',
  FLAT_RATE_COUNT: null,
  FLAT_RATE_PRICE: null,
  APPENDED_PRICE: 1650
}

const BASE: PlanPriceType = {
  ID: '1',
  FLAT_RATE_COUNT: 5,
  FLAT_RATE_PRICE: 13200,
  APPENDED_PRICE: 1650
}

const WITH_SPO: PlanPriceType = {
  ID: '2',
  FLAT_RATE_COUNT: 1,
  FLAT_RATE_PRICE: 17600,
  APPENDED_PRICE: 1650
}

const PlanPriceTypes = {
  UNSPECIFIED: UNSPECIFIED,
  BASE: BASE,
  WITH_SPO: WITH_SPO
}
export const getPlanPriceType = (planPriceTypeId: string): PlanPriceType => {
  const key = Object.keys(PlanPriceTypes).find(
    // @ts-ignore
    key => PlanPriceTypes[key].ID === planPriceTypeId
  )
  // @ts-ignore
  return PlanPriceTypes[key]
}

export default PlanPriceTypes
