import styled from 'styled-components'

export const ContentsBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FixedArea = styled.div`
  flex: 0 0 auto;
`

export const ScrollArea = styled.div`
  flex: 1 1 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`
