import React, { useEffect } from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { SeatSetDetailList } from './List'
import NameInput from './NameInput'
import { SeatSetDetailFooter } from './Footer'
import Modals from './Modals'
import Forward from '~/src/components/organisms/Forward'
import Const from '~/src/const'
import { selectShouldForward } from './selector'
import { useSelector } from 'react-redux'
import { useInitialize, useDeinit } from './hooks'

const { Page, Time } = Const

export const Detail = ({ id, isUpdate }: { id: string; isUpdate: boolean }) => {
  const shouldForward = useSelector(selectShouldForward)
  const handleInitialize = useInitialize()
  const handleDeinit = useDeinit()

  useEffect(() => {
    handleInitialize({ id, isUpdate })
    return handleDeinit
  }, [handleDeinit, handleInitialize, id, isUpdate])

  const breadcrumbs = [
    { title: '注文エリア一覧', page: Page.SEAT_SET.LIST },
    { title: isUpdate ? '注文エリア設定' : '注文エリア追加' }
  ]

  const footer = <SeatSetDetailFooter />

  const modal = <Modals />

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={footer}
      modal={modal}
      displayKM
      displayKP
    >
      <NameInput />
      <SeatSetDetailList />
      <Forward
        when={shouldForward}
        forwardPage={Page.SEAT_SET.LIST}
        after={Time.FOWARD_WAIT}
      />
    </PageTemplate>
  )
}
