import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchKitchenPositions,
  clearState
} from '~/src/redux/modules/KitchenPositionSetting/KitchenPositionManagement'
import { clearUiState } from '~/src/redux/modules/UI'

export const useHandleInitialize = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchKitchenPositions())
  }, [dispatch])
}

export const useHandleDeinit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearUiState())
    dispatch(clearState())
  }, [dispatch])
}
