import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { DownArrowIcon } from '~/src/components/atoms/Icon'
import TableRow from '~/src/components/atoms/TableRow'
import Const from '~/src/const'
import { ContentColumn, FunctionColumn } from './Column'
import { FunctionLink } from '~/src/components/atoms/FunctionLink'
import { Category, Item } from '../../types'
import { useSelectCategory, useSelectCategoryItem } from '../hooks'

const { Time, Color } = Const

type Props = {
  category: Category
}

export const Row: React.VFC<Props> = props => {
  const { category } = props

  const [isFolded, setIsFolded] = useState(true)
  const [listHeight, setListHeight] = useState(null)
  const menuListlCallback = useCallback(
    node => {
      if (!node) {
        return
      }
      setListHeight(node.clientHeight)
    },
    [setListHeight]
  )
  const onTitleClicked = () => {
    setIsFolded(!isFolded)
  }

  const handleSelectCategory = useSelectCategory()
  const handleSelectCategoryItem = useSelectCategoryItem()
  const handleClickAllSelect = (categoryId: string) => {
    return () => {
      handleSelectCategory(categoryId)
    }
  }

  const handleClickItem = (categoryId: string, item: Item) => {
    if (!item.disabled) {
      return () => handleSelectCategoryItem(categoryId, item.itemId)
    }
  }

  return (
    <>
      <AccordionTitle onClick={onTitleClicked}>
        <StyledTableRow>
          <ContentColumn>
            {category.name}
            {!category.isDisplayed && ' (非表示)'}
          </ContentColumn>
          <FunctionColumn>
            {!isFolded && (
              <FunctionLink
                className="show-on-expanded"
                onClick={handleClickAllSelect(category.categoryId)}
              >
                すべて選択
              </FunctionLink>
            )}
            <ArrowWrapper isFolded={isFolded}>
              <DownArrowIcon />
            </ArrowWrapper>
          </FunctionColumn>
        </StyledTableRow>
      </AccordionTitle>
      <AccordionList isFolded={isFolded} height={listHeight}>
        <div ref={menuListlCallback}>
          {category.items.map((item, i) => {
            return (
              <StyledTableRowItem
                key={`key-${category.categoryId}-${item.itemId}`}
                onClick={handleClickItem(category.categoryId, item)}
                disable={item.disabled}
              >
                <ContentColumn>
                  {item.itemName}
                  {!item.isDisplayed && ' (非表示)'}
                </ContentColumn>
              </StyledTableRowItem>
            )
          })}
        </div>
      </AccordionList>
    </>
  )
}

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
`

const AccordionList = styled.div<{
  isFolded: boolean
  height: number | null
}>`
  max-height: ${props => props.height}px;
  max-width: 100%;
  overflow: hidden;
  transition: all ease ${Time.ACCORDION}ms;
  ${props =>
    props.isFolded &&
    css`
      max-height: 0;
    `}
`

const ArrowWrapper = styled.span<{
  isFolded: boolean
}>`
  svg {
    transition: all ease ${Time.ACCORDION}ms;
    transform: rotate(-180deg);
  }
  ${props =>
    props.isFolded &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
  padding-left:6px;
`

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  height: 50px;
  font-weight: bold;
`

const StyledTableRowItem = styled(StyledTableRow)<{
  disable: boolean
}>`
  padding-left: 30px;
  font-weight: normal;
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  background: ${props =>
    props.disable ? Color.VERY_LIGHT_GRAY_9 : Color.WHITE};
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  cursor: ${props => (props.disable ? 'auto' : 'pointer')};
`
