import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import { SelectItem } from './SelectItem/SelectItem'

const { Size, Color } = Const

export const OtoshiSetting: React.VFC = () => {
  return (
    <Wrapper>
      <FormWrapper>
        <SettingTitle>
          お通し商品設定
          <IconBackGround>
            <Required>必須</Required>
          </IconBackGround>
        </SettingTitle>
        <StyledBaseRow>
          <SettingNameArea>
            お通し商品の内容を設定します。商品一覧より選び、表示したい順に並べてください。
            <Description>
              ※バリエーションが設定されている商品、コースおよび放題プランとして登録されている商品、トッピングの対象商品、税設定が非課税で設定されている商品は、お通し商品に登録できません。
            </Description>
          </SettingNameArea>
        </StyledBaseRow>
        <SelectItem />
      </FormWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 50px;
  display: flex;
`

const FormWrapper = styled.div`
  margin-right: 32px;
  width: 100%;
`

const SettingTitle = styled.div`
  display: flex;
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`
const StyledBaseRow = styled.div`
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
  background-color: ${Color.WHITE};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  display: table;
  top: -1px;
  margin-top: -1px;
  padding: 16px 0;
  table-layout: fixed;
  width: 100%;
`

const SettingNameArea = styled.div`
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border: none;
  display: table-cell;
  line-height: 1.2;
  position: relative;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-all;
  text-align: left;
  width: 510px;
`

const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`

const Required = styled.span`
  color: ${Color.WHITE};
  font-size: 10px;
  letter-spacing: 0;
  line-height: 18px;
`

const IconBackGround = styled.div`
  margin-left: 8px;
  height: 18px;
  width: 28px;
  border-radius: 2px;
  background-color: ${Color.SOFT_RED};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
