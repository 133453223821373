import { AppState } from '~/src/redux/reducer'

export const selectKitchenPositionSettingList = (state: AppState) => {
  const kitchenPositionList =
    state.kitchenPosition.kitchenPositionSetting.kitchenPositionList
  return {
    // Dummy用の１要素を追加する(ここでnullを設定してもnullを取得する実装になっていないためts-ignoerする)
    // @ts-ignore
    kitchenPositionList: kitchenPositionList.push(null),
    listSize: kitchenPositionList.size
  }
}
