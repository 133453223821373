import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Modal from '~/src/components/organisms/Modal'
import Heading from '~/src/components/atoms/Heading'

const { Color, Size } = Const
const { H2 } = Heading

export default props => {
  const {
    isOpen,
    onCancel,
    onDelete,
    headingMessage,
    okText,
    useWarningOkButton,
    children,
    maxWidth
  } = props

  const okButtonText = okText || '削除する'

  return (
    <Modal
      closable
      isOpen={isOpen}
      onClose={onCancel}
      onOK={onDelete}
      cancelText="キャンセル"
      okText={okButtonText}
      useWarningOkButton={useWarningOkButton}
    >
      {headingMessage && (
        <StyledHeading maxWidth={maxWidth}>{headingMessage}</StyledHeading>
      )}
      {children && (
        <StyledContent maxWidth={maxWidth}>{children}</StyledContent>
      )}
    </Modal>
  )
}

const StyledHeading = styled(H2)`
  color: ${Color.VERY_DARK_GRAY_3};
  text-align: center;
  margin: 0 0 24px;
  word-wrap: break-word;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '320px')};
`

const StyledContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  margin-bottom: 8px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 20px;
  word-wrap: break-word;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '320px')};
`
