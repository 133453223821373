import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ColumnBase } from '../Column'
import Icon from '~/src/components/atoms/Icon'
import { selectHasItemPrinterSetting } from './selectors'
import Const from '~/src/const/'
import { useSelector } from 'react-redux'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
const { Size, Page } = Const

export const CategoryColumn = ({
  height,
  category
}: {
  height: number
  category: SimpleCategory
}) => {
  const hasItemPrinterSetting = useSelector(
    selectHasItemPrinterSetting(category.categoryId)
  )
  const printTargetByItemsURI = Page.PRINTER_TARGET.ITEMS(category.categoryId)

  return (
    <ColumnBase height={height.toString()}>
      {category.categoryName} <br />
      <StyledLink to={printTargetByItemsURI}>商品別設定</StyledLink>
      {hasItemPrinterSetting && <CheckIcon />}
    </ColumnBase>
  )
}

const StyledLink = styled(Link)`
  font-size: ${Size.FONT.SMALL}px;
`

const CheckIcon = styled(Icon).attrs({ name: 'check' })``
