import React from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line import/no-named-default
import { default as CommonFooter } from '~/src/components/organisms/Footer'
import { useHandleSubmitButtonClick } from './hooks'
import { selectIsEditing } from './selectors'

export const Footer: React.VFC = () => {
  const isEditing = useSelector(selectIsEditing)
  const handleSubmitButtonClick = useHandleSubmitButtonClick()
  return (
    <CommonFooter
      // @ts-ignore
      disableSubmitButton={!isEditing}
      noUseOptionButton={true}
      onSubmitButtonClick={handleSubmitButtonClick}
    />
  )
}
