import { Record, List } from 'immutable'
import Const from '~/src/const'
import { KitchenPositionByItem } from '../../modules/KitchenPositionSetting/types'
import KitchenPositionByItemModel from './KitchenPositionByItem'
import KitchenPositionByItemEditor from './KitchenPositionByItemEditor'

const { UndefinedId } = Const

const KitchenPositionByItemsRecord = Record({
  kitchenPositionByItems: List<KitchenPositionByItemModel>()
})

export default class KitchenPositionByItems extends KitchenPositionByItemsRecord {
  constructor(
    kitchenPositionByItems: KitchenPositionByItem[] = [],
    itemIds: string[] = []
  ) {
    super()

    const list = List(kitchenPositionByItems).map(
      byItem => new KitchenPositionByItemModel(byItem)
    )
    return this.merge({ kitchenPositionByItems: list }).filterByItemIds(itemIds)
  }

  hasItemId(itemId: string) {
    return this.kitchenPositionByItems.some(byItem => byItem.itemId === itemId)
  }

  hasItemIds(itemIds: string[]) {
    return this.kitchenPositionByItems.some(byItem =>
      itemIds.includes(byItem.itemId)
    )
  }

  filterByItemIds(itemIds: string[]) {
    return this.set(
      'kitchenPositionByItems',
      this.kitchenPositionByItems.filter(byItem =>
        itemIds.includes(byItem.itemId)
      )
    )
  }

  applyKitchenPositionByItemEditor(
    editor?: KitchenPositionByItemEditor | null
  ) {
    // 対象のカテゴリーに含まれる商品の設定を全て除外したのち、Editorの設定を適用する。
    if (!editor) return this
    const itemIds = editor.items.map(item => item.itemId)
    const list = this.kitchenPositionByItems
      .filter(byItem => !itemIds.includes(byItem.itemId))
      .concat(editor.kitchenPositionByItems.kitchenPositionByItems)
    return this.set('kitchenPositionByItems', list)
  }

  getByItemId(itemId: string) {
    return this.kitchenPositionByItems.find(ByItem => ByItem.itemId === itemId)
  }

  updateKitchenPositionIdAndAnalysisTag(
    itemId: string,
    kitchenPositionId: string,
    analysisTag: string
  ) {
    const index = this.kitchenPositionByItems.findIndex(
      byItem => byItem.itemId === itemId
    )

    // 既に設定済みであれば更新、未設定であれば新規追加。
    const list =
      index >= 0
        ? this.kitchenPositionByItems.update(index, byItem =>
            // @ts-ignore
            byItem.setKitchenPositionId(kitchenPositionId)
          )
        : this.kitchenPositionByItems.push(
            new KitchenPositionByItemModel({ itemId, kitchenPositionId })
          )

    return this.set(
      'kitchenPositionByItems',
      // @ts-ignore
      list.update(index, byItem => byItem.setAnalysisTag(analysisTag))
    )
  }

  deleteByItemId(itemId: string) {
    return this.set(
      'kitchenPositionByItems',
      this.kitchenPositionByItems.filter(byItem => byItem.itemId !== itemId)
    )
  }

  buildRequestObject() {
    // 設定がない場合はnullを返し、jsonに含まれないようにする。
    if (!this.kitchenPositionByItems.size) {
      return null
    }

    return this.kitchenPositionByItems.map(byItem => {
      if (UndefinedId.SELECTBOX_ID === byItem.kitchenPositionId) {
        return {
          itemId: byItem.itemId,
          analysisTag: byItem.analysisTag
        }
      }

      return {
        itemId: byItem.itemId,
        kitchenPositionId: byItem.kitchenPositionId,
        analysisTag: byItem.analysisTag
      }
    })
  }
}
