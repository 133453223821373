import { Store } from '~/src/redux/modules/Auth/types'
import { AppState } from '~/src/redux/reducer'
import { containsSPO, containsHandy } from '~/src/util/ServiceTypeCheck'
import Const from '~/src/const'
const { ContractType } = Const

export const selectIsShowSpoUpSellBanner = (state: AppState): boolean => {
  const store: Store | undefined = state.auth.store
  const isSpoStore: boolean = store ? containsSPO(store.serviceTypes) : false
  const isHandyStore: boolean = store
    ? containsHandy(store.serviceTypes)
    : false
  const isTrial = store ? ContractType.isTrial(store.contractType) : false
  // 「契約店舗(notハンディKMお試し)でSPO店舗ではないかつhandy店舗」の場合のみSPOアップセルボタンを出す
  // 契約店舗で絞っている理由：アップセルとして営業に取り次いでいるので、未契約店舗には出さない
  // handy店舗で絞っている理由：ハンディアプリを契約してないとSPOを契約できないので、プロモーションして営業に取り継いでも案内する契約がないため
  return !isTrial && !isSpoStore && isHandyStore
}
