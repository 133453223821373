import { createAction } from 'typesafe-actions'
import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import {
  CLEAR_STATE,
  FETCH_INITIAL_DATA_SUCCEEDED,
  DID_TAP_TRASH_BOX,
  DID_SORT_TOPPING_GROUP,
  POST_DELETE_TOPPING_GROUP_SUCCEEDED,
  POST_UPDATE_TOPPING_GROUP_SUCCEEDED,
  OPEN_MAX_TOPPING_GROUP_MODAL,
  CLOSE_MAX_TOPPING_GROUP_MODAL
} from './types'

export default {
  clearState: createAction(CLEAR_STATE)(),
  fetchInitialDataSucceeded: createAction(
    FETCH_INITIAL_DATA_SUCCEEDED,
    (items: ApiResponse<ItemsApiResponse>) => ({ items })
  )(),
  postUpdateToppingGroupSucceeded: createAction(
    POST_UPDATE_TOPPING_GROUP_SUCCEEDED
  )(),
  postDeleteToppingGroupSucceeded: createAction(
    POST_DELETE_TOPPING_GROUP_SUCCEEDED,
    (toppingGroupId: string) => ({ toppingGroupId })
  )(),
  didTapTrashBox: createAction(DID_TAP_TRASH_BOX, (toppingGroupId: string) => ({
    toppingGroupId
  }))(),
  didSortToppingGroup: createAction(
    DID_SORT_TOPPING_GROUP,
    (oldIndex: number, newIndex: number) => ({ oldIndex, newIndex })
  )(),
  openMaxToppingGroupModal: createAction(OPEN_MAX_TOPPING_GROUP_MODAL)(),
  closeMaxToppingGroupModal: createAction(CLOSE_MAX_TOPPING_GROUP_MODAL)()
}
