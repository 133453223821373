import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import { handleError } from '~/src/redux/modules/ApiError'
import APIClient from '~/src/util/APIClient'
import Const from '~/src/const'
import { setShouldForward } from '../Forward'
import { DeviceCountSummary, ResponseMap } from '../RegisteredDevice/types'

const { API, Toast, Time } = Const

const CHANGE_SELECTED_DEVICE_COUNT =
  'oes/DeviceCount/CHANGE_SELECTED_DEVICE_COUNT'
const FETCH_RESOURCES_SUCCESS = 'oes/DeviceCount/FETCH_RESOURCES_SUCCESS'
const UPDATE_DEVICE_COUNT_SUCCESS =
  'oes/DeviceCount/UPDATE_DEVICE_COUNT_SUCCESS'
const UPDATE_DEVICE_COUNT_READY = 'oes/DeviceCount/UPDATE_DEVICE_COUNT_READY'
const UPDATE_DEVICE_COUNT_VALIDATE_ERROR =
  'oes/DeviceCount/UPDATE_DEVICE_COUNT_VALIDATE_ERROR'
const CLOSE_VALIDATE_ERROR_MODAL = 'oes/DeviceCount/CLOSE_VALIDATE_ERROR_MODAL'
const CHANGE_POLICY_CHECKED = 'oes/DeviceCount/CHANGE_POLICY_CHECKED'
const START_FETCH_RESOURCE = 'oes/DeviceCount/START_FETCH_RESOURCE'
const CLEAR_DEVICE_COUNT_STATE = 'oes/DeviceCount/CLEAR_DEVICE_COUNT_STATE'

// Actions
export const fetchResources = () => {
  // @ts-ignore
  return dispatch => {
    return Promise.all([
      APIClient.get(API.GET_DEVICE_COUNT),
      APIClient.get(API.GET_REGISTERED_DEVICES)
    ])
      .then(dispatch(setLoading()))
      .then(dispatch(startFetchResources()))
      .then(([deviceCountResponse, registeredDevicesResponse]) => {
        return {
          deviceCountApiResponse: deviceCountResponse.data,
          registeredDeviceApiResponse: registeredDevicesResponse.data
        }
      })
      .then((responseMap: ResponseMap) =>
        dispatch(fetchResourcesSuccess(responseMap))
      )
      .catch(error => dispatch(handleError(error)))
      .then(() => {
        // 一瞬で消えないように0.5秒入れる
        setTimeout(() => dispatch(clearLoading()), Time.LOADING)
      })
  }
}

const startFetchResources = () => {
  return {
    type: START_FETCH_RESOURCE
  }
}

const fetchResourcesSuccess = (responseMap: ResponseMap) => {
  const summary = responseMap.deviceCountApiResponse.result.deviceCountSummary

  return {
    type: FETCH_RESOURCES_SUCCESS,
    summary: summary,
    registeredDevicesCount:
      responseMap.registeredDeviceApiResponse.result.registeredDevices?.length
  }
}

export const updateDeviceCount = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const state = getState().deviceCount
    const count = state.confirmedDeviceCount
    const json = {
      count: count,
      lastRequestedAt: state.summary.requestedAt
    }
    return APIClient.post(API.POST_UPDATE_DEVICE_COUNT, json)
      .then(dispatch(setLoading()))
      .then(() => dispatch(showAndBlurToast(Toast.CONFIRMED_MESSAGE)))
      .then(() => dispatch(updateDeviceCountSuccess()))
      .then(() => dispatch(fetchResources()))
      .then(() => dispatch(setShouldForward(true)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const updateDeviceCountSuccess = () => {
  return {
    type: UPDATE_DEVICE_COUNT_SUCCESS
  }
}

export const clearDeviceCountState = () => {
  return {
    type: CLEAR_DEVICE_COUNT_STATE
  }
}

export const changePolicyChecked = (isChecked: boolean) => {
  return {
    type: CHANGE_POLICY_CHECKED,
    isChecked: isChecked
  }
}

export const changeSelectedDeivceCount = (count: number) => {
  return {
    type: CHANGE_SELECTED_DEVICE_COUNT,
    count: count
  }
}

export const closeErrorModal = () => {
  return {
    type: CLOSE_VALIDATE_ERROR_MODAL
  }
}

export const updateDeviceReady = () => {
  return {
    type: UPDATE_DEVICE_COUNT_READY
  }
}

export const updateDeviceError = () => {
  return {
    type: UPDATE_DEVICE_COUNT_VALIDATE_ERROR
  }
}

type DeviceCountState = {
  summary: DeviceCountSummary
  // drop down boxに選択された数字
  selectedDeviceCount: number
  // 最後に変更画面で確認ボタン押した時、selectedDeviceCountの数字
  confirmedDeviceCount: number
  // すでに登録している端末の数
  registeredDevicesCount: number
  // 変更画面の変更前後の台数が一致であるか
  isModified: boolean
  // エラーモーダルを表示するか
  showError: boolean
  // 申込内容確認画面の規約同意check boxにチェック入れてるか
  isChecked: boolean
  isResourceFetched: boolean
  // 端末台数更新が完了したか.
  didUpdateDeviceCount: boolean
  hasError: boolean
}

export const initialState: DeviceCountState = {
  summary: {
    currentCount: 0,
    flatRateCount: 0,
    billingTargetCount: 0,
    lastUpdatedAt: 0,
    billingTargetDate: 0,
    requestedAt: 0
  },
  selectedDeviceCount: 0,
  confirmedDeviceCount: 0,
  registeredDevicesCount: 0,
  isModified: false,
  showError: false,
  isChecked: false,
  isResourceFetched: false,
  didUpdateDeviceCount: false,
  hasError: false
}

// Reducer
// @ts-ignore
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        summary: action.summary,
        selectedDeviceCount: state.confirmedDeviceCount
          ? state.confirmedDeviceCount
          : action.summary.currentCount,
        registeredDevicesCount: action.registeredDevicesCount,
        isModified: state.confirmedDeviceCount
          ? state.summary.currentCount !== state.confirmedDeviceCount
          : false,
        showError: false,
        isChecked: false,
        isResourceFetched: true
      }
    case CHANGE_SELECTED_DEVICE_COUNT:
      return {
        ...state,
        selectedDeviceCount: Number(action.count),
        isModified: state.summary.currentCount !== Number(action.count),
        hasError: action.count < state.summary.currentCount
      }
    case UPDATE_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        didUpdateDeviceCount: true
      }
    case UPDATE_DEVICE_COUNT_READY:
      return {
        ...state,
        isChecked: false,
        confirmedDeviceCount: state.selectedDeviceCount
      }
    case UPDATE_DEVICE_COUNT_VALIDATE_ERROR:
      return {
        ...state,
        showError: true
      }
    case CLOSE_VALIDATE_ERROR_MODAL:
      return {
        ...state,
        showError: false
      }
    case CHANGE_POLICY_CHECKED:
      return {
        ...state,
        isChecked: action.isChecked
      }
    case START_FETCH_RESOURCE:
      return {
        ...state,
        isResourceFetched: false
      }
    case CLEAR_DEVICE_COUNT_STATE:
      return initialState
    default:
      return state
  }
}
