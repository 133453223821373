import {
  CategoryApiResponse,
  CourseApiResponse,
  CourseDetailApiResponse,
  HodaiApiResponse,
  ItemApiResponse,
  ItemCategoryJunctionApiResponse,
  ItemsApiResponse
} from '~/src/api/handy/items/types'
import Const from '~/src/const'
import { ConsumptionTaxTypeAtOrder } from '../const/consumptionTaxTypeAtOrder'
const { ItemType } = Const

type ItemMap = {
  [_: string]: ItemApiResponse
}

interface ObjectHasItemId {
  itemId: string
}

export type HodaiError = {
  itemIdsHaveVariationHodaiPlanError: string[]
  itemIdsHaveDeletedHodaiPlanItemError: string[]
  itemIdsHaveDeletedHodaiPlanCategoryError: string[]
  itemIdsHaveItemNotHodaiPlanError: string[]
}

export type CourseError = {
  itemIdsHaveDeletedCourseDetail: string[]
  itemIdsHaveEmptyCategoryItem: string[]
  itemIdsHaveHodaiError: string[]
  itemIdsHaveSkuCourse: string[]
  itemIdsHaveDeletedCourse: string[]
  itemIdsHaveEmptyCourse: string[]
  itemIdsHaveEmptyCategory: string[]
}

export const checkHodai = (response: ItemsApiResponse): HodaiError => {
  const itemMap = createItemMap(response.items)
  const itemIdSetForCategories = createItemIdSetForCategories(
    response.categories,
    response.itemCategoryJunctions
  )
  return {
    itemIdsHaveDeletedHodaiPlanCategoryError: calculateItemIdsHaveEmptyCategory(
      itemMap,
      itemIdSetForCategories,
      response.hodais
    ),
    itemIdsHaveItemNotHodaiPlanError: calculateItemIdsHaveItemNotHodai(
      itemMap,
      itemIdSetForCategories,
      response.hodais
    ),
    itemIdsHaveDeletedHodaiPlanItemError: calculateItemIdsHaveDeletedItem(
      itemMap,
      response.hodais
    ),
    itemIdsHaveVariationHodaiPlanError: calculateItemIdsHaveSku(
      itemMap,
      response.hodais
    )
  }
}

export const checkCourse = (response: ItemsApiResponse): CourseError => {
  const itemMap = createItemMap(response.items)
  const itemIdSetForCategories = createItemIdSetForCategories(
    response.categories,
    response.itemCategoryJunctions
  )
  return {
    itemIdsHaveDeletedCourseDetail: calculateItemIdsHaveDeletedCourseDetail(
      itemMap,
      response.courses
    ),
    itemIdsHaveEmptyCategoryItem: calculateItemIdsHaveEmptyCategoryItem(
      itemMap,
      itemIdSetForCategories,
      response.courses
    ),
    itemIdsHaveHodaiError: calculateItemIdsHaveHodaiError(
      itemMap,
      itemIdSetForCategories,
      response.courses
    ),
    itemIdsHaveEmptyCourse: calculateItemIdsHaveEmptyCourse(response.courses),
    itemIdsHaveSkuCourse: calculateItemIdsHaveSku(itemMap, response.courses),
    itemIdsHaveDeletedCourse: calculateItemIdsHaveDeletedItem(
      itemMap,
      response.courses
    ),
    itemIdsHaveEmptyCategory: calculateItemIdsHaveEmptyCategory(
      itemMap,
      itemIdSetForCategories,
      response.courses
    )
  }
}

export const checkOtoshi = (response: ItemsApiResponse) => {
  const itemMap = createItemMap(response.items)
  const itemIdSetForCategories = createItemIdSetForCategories(
    response.categories,
    response.itemCategoryJunctions
  )
  return {
    itemIdsHaveDeleted: calculateItemIdsHaveDeletedItem(
      itemMap,
      response.otoshis
    ),
    itemIdsHaveEmptyCategory: calculateItemIdsHaveEmptyCategory(
      itemMap,
      itemIdSetForCategories,
      response.otoshis
    ),
    itemIdsHaveVariationItem: calculateItemIdsHaveSku(
      itemMap,
      response.otoshis
    ),
    itemIdsHaveTaxFree: calculateItemIdsHaveTaxFree(itemMap, response.otoshis),
    itemIdsHaveSelectableTax: calculateItemIdsHaveSelectableTax(
      itemMap,
      response.otoshis
    )
  }
}

// 共通メソッド
const calculateItemIdsHaveDeletedItem = (
  itemMap: ItemMap,
  objects?: ObjectHasItemId[]
): string[] => {
  return (
    objects
      ?.filter(object => !itemMap[object.itemId])
      .map(object => object.itemId) || []
  )
}

// 共通メソッド
const calculateItemIdsHaveSku = (
  itemMap: ItemMap,
  objects?: ObjectHasItemId[]
): string[] => {
  return (
    objects
      ?.filter(
        object =>
          itemMap[object.itemId] &&
          itemMap[object.itemId].itemType === ItemType.SKU
      )
      .map(object => object.itemId) || []
  )
}

// 共通メソッド
const calculateItemIdsHaveTaxFree = (
  itemMap: ItemMap,
  objects?: ObjectHasItemId[]
): string[] => {
  return (
    objects
      ?.filter(
        object =>
          itemMap[object.itemId] &&
          itemMap[object.itemId].consumptionTaxTypeAtOrder ===
            ConsumptionTaxTypeAtOrder.TAX_FREE
      )
      .map(object => object.itemId) || []
  )
}

// 共通メソッド
const calculateItemIdsHaveSelectableTax = (
  itemMap: ItemMap,
  objects?: ObjectHasItemId[]
): string[] => {
  return (
    objects
      ?.filter(
        object =>
          itemMap[object.itemId] &&
          itemMap[object.itemId].consumptionTaxTypeAtOrder ===
            ConsumptionTaxTypeAtOrder.SELECT
      )
      .map(object => object.itemId) || []
  )
}

// 共通メソッド
const calculateItemIdsHaveEmptyCategory = (
  itemMap: ItemMap,
  itemIdSetForCategories: Set<string>,
  objects?: ObjectHasItemId[]
): string[] => {
  return (
    objects
      ?.filter(object => itemMap[object.itemId]) // 該当商品が削除されている場合はカテゴリー未設定のチェックをしない
      ?.filter(object => !itemIdSetForCategories.has(object.itemId))
      .map(object => object.itemId) || []
  )
}

const calculateItemIdsHaveItemNotHodai = (
  itemMap: ItemMap,
  itemIdSetForCategories: Set<string>,
  hodais?: HodaiApiResponse[]
): string[] => {
  return (
    hodais
      ?.filter(
        hodai =>
          !hodai.hodaiDetails ||
          hodai.hodaiDetails.filter(
            detail =>
              itemMap[detail.itemId] &&
              itemIdSetForCategories.has(detail.itemId)
          ).length === 0 // レジで消された&カテゴリ未設定の放題子はカウントしない
      )
      .map(hodai => hodai.itemId) || []
  )
}

const calculateItemIdsHaveDeletedCourseDetail = (
  itemMap: ItemMap,
  courses?: CourseApiResponse[]
): string[] => {
  return (
    courses?.flatMap(
      course =>
        course.courseDetails
          ?.filter(detail => !itemMap[detail.itemId])
          ?.filter(detail => detail.hodaiId === undefined)
          .map(detail => detail.itemId) || []
    ) || []
  )
}

const calculateItemIdsHaveEmptyCategoryItem = (
  itemMap: ItemMap,
  itemIdSetForCategories: Set<string>,
  courses?: CourseApiResponse[]
): string[] => {
  return (
    courses?.flatMap(
      course =>
        course.courseDetails
          ?.filter(
            detail =>
              !detail.hodaiId &&
              !itemIdSetForCategories.has(detail.itemId) &&
              !!itemMap[detail.itemId]
          )
          .map(detail => detail.itemId) || []
    ) || []
  )
}

const calculateItemIdsHaveHodaiError = (
  itemMap: ItemMap,
  itemIdSetForCategories: Set<string>,
  courses?: CourseApiResponse[]
): string[] => {
  return (
    courses?.flatMap(
      course =>
        course.courseDetails
          ?.filter(detail =>
            errorCheckForHodaiOnCourse(itemMap, itemIdSetForCategories, detail)
          )
          .map(detail => detail.itemId) || []
    ) || []
  )
}

const errorCheckForHodaiOnCourse = (
  itemMap: ItemMap,
  itemIdSetForCategories: Set<string>,
  detail: CourseDetailApiResponse
) =>
  // 放題商品の時だけチェック
  !!detail.hodaiId &&
  // 削除されてないか
  (!itemMap[detail.itemId] ||
    // SKU商品が設定されてないか
    // skusが0かundefinedでfalse
    !!itemMap[detail.itemId].skus?.length ||
    // カテゴリーに紐づいているか
    !itemIdSetForCategories.has(detail.itemId))

const calculateItemIdsHaveEmptyCourse = (
  courses?: CourseApiResponse[]
): string[] => {
  return (
    courses
      ?.filter(
        course => !course.courseDetails || course.courseDetails?.length === 0
      )
      .map(course => course.itemId) || []
  )
}

const createItemMap = (items?: ItemApiResponse[]) => {
  return (
    items?.reduce((previous: any, current) => {
      previous[current.itemId] = current
      return previous
    }, {}) || {}
  )
}

const createItemIdSetForCategories = (
  categories?: CategoryApiResponse[],
  itemCategoryJunctions?: ItemCategoryJunctionApiResponse[]
): Set<string> => {
  const categoryIdSet = createCategoryIdSet(categories)
  if (!categoryIdSet.size) {
    return new Set()
  }
  return new Set(
    itemCategoryJunctions
      ?.filter(icj => categoryIdSet.has(icj.categoryId))
      .map(icj => icj.itemId) || []
  )
}

const createCategoryIdSet = (categories?: CategoryApiResponse[]) => {
  return new Set(categories?.map(category => category.categoryId) || [])
}
