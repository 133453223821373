import React from 'react'
import { useSelector } from 'react-redux'
import { SettingToggle } from './SettingToggle'
// @ts-ignore
import image from '~/assets/images/Otoshi/otoshi_order.png'
import styled from 'styled-components'
import Const from '~/src/const'
import { useHandleToggleOtoshiEnabled } from './hooks'
import { selectIsOtoshiEnabled } from './selectors'

const { Size, Color } = Const

export const AvailabilitySetting: React.VFC = () => {
  const handleToggleOtoshiEnabled = useHandleToggleOtoshiEnabled()
  const isOtoshiEnabled = useSelector(selectIsOtoshiEnabled)
  return (
    <Wrapper>
      <FormWrapper>
        <SettingTitle>利用設定</SettingTitle>
        <SettingToggleWrapper>
          <ImageWrapper>
            <ImageStyle src={image} />
          </ImageWrapper>
          <SettingToggle
            onToggle={handleToggleOtoshiEnabled}
            checked={isOtoshiEnabled}
            title="お通し注文"
            description="来店処理、人数変更の際にお通し注文画面が表示されます。"
          />
        </SettingToggleWrapper>
      </FormWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  padding-bottom: 136px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    padding-bottom: 48px;
  }
`

const FormWrapper = styled.div`
  margin-right: 32px;
  clear: both;
`

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`

const ImageWrapper = styled.div`
  float: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
  display: block;
`

const ImageStyle = styled.img`
  padding-top: 10px;
  max-width: 256px;
  height: 163px;
  width: 236px;
`

const SettingToggleWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`
