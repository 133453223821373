import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { actions } from './actions'
import { actions as kmSettingApiActions } from '~/src/api/km/settings/actions'

export const useClearState = () => {
  const dispatch: Dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.clearState())
    dispatch(kmSettingApiActions.clearState())
  }, [dispatch])
}
