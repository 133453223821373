import React from 'react'
import Variation from './Variation'
import useInteractors from '~/src/hooks/useInteractors'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationModel from '~/src/redux/models/CSVItem/Variation'

type Props = {
  item: CSVItem
  variation: VariationModel
}

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const VariationContainer: React.FC<Props> = props => {
  return <Variation {...props} {...useInteractor()} />
}

export default VariationContainer
