import moment from 'moment'
import { Map } from 'immutable'
import { Dispatch } from 'redux'
import actions from '~/src/redux/modules/TargetServingTime/actions'
import Categories from '~/src/redux/models/Category/Categories'
import Items from '~/src/redux/models/Item/Items'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'

import { handleError } from '~/src/redux/modules/ApiError'

import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import {
  TargetServingTimesApiRequest,
  TargetServingTimesApiResponse
} from '~/src/api/km/targetServingTimes/types'
import { EstimatedTargetServingTimesApiResponse } from '~/src/api/km/targetServingTimes/estimated/types'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import { SimpleItem } from '~/src/redux/models/Item/Item'
import ServingTimeModes from '~/src/const/serving-time-mode'
import { BatchEditSelected } from '~/src/redux/modules/TargetServingTime/types'
const { API, Toast } = Const

const LAST_CACHED_AT_FORMAT = 'YYYY/MM/DD HH:mm'

class TargetServingTimeInteractor {
  private dispatch: Dispatch
  private apiClient: ApiClientInterface

  public constructor(
    dispatch: Dispatch,
    apiClient: ApiClientInterface = ApiClient
  ) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  public async fetchInitialData(): Promise<void> {
    this.dispatch(setLoading())
    await Promise.all([
      this.apiClient.get(API.GET_ITEMS),
      this.apiClient.get(API.GET_TARGET_SERVING_TIME),
      this.apiClient.get(API.GET_ESTIMATED_TARGET_SERVING_TIME)
    ])
      .then(
        ([
          getItemsRes,
          getTaretServingTimeRes,
          getEstimatedTargetServingTimeRes
        ]) => {
          const itemResponse: Categories = this.fetchItemsSucceeded(getItemsRes)
          const targetServingTimeResponse: Map<string, TargetServingTime> =
            this.featchTargetServingTimesSucceeded(
              getTaretServingTimeRes,
              getItemsRes
            )
          const estimatedTargetServingTimeResponse =
            this.featchEstimatedTargetServingTimesSucceeded(
              getEstimatedTargetServingTimeRes
            )
          this.dispatch(
            actions.fetchInitialDateSucceeded(
              itemResponse.getSimpleCategoriesWithItems().toArray(),
              targetServingTimeResponse,
              estimatedTargetServingTimeResponse,
              moment(getTaretServingTimeRes.data.result.lastCachedAt).format(
                LAST_CACHED_AT_FORMAT
              )
            )
          )
        }
      )
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  private fetchItemsSucceeded(response: any): Categories {
    const itemsResponse: ApiResponse<ItemsApiResponse> = response.data
    // @ts-ignore
    const items: Items = new Items(itemsResponse.result.items)
    const categories: Categories = new Categories(
      // @ts-ignore
      itemsResponse.result.categories,
      items,
      itemsResponse.result.itemCategoryJunctions
    )

    return categories
  }

  private featchTargetServingTimesSucceeded(
    targetServingResponse: any,
    itemResponse: any
  ): Map<string, TargetServingTime> {
    const targetServingTimesResponse: ApiResponse<TargetServingTimesApiResponse> =
      targetServingResponse.data
    const itemsResponse: ApiResponse<ItemsApiResponse> = itemResponse.data
    // @ts-ignore
    const items: Items = new Items(itemsResponse.result.items)

    // 処理性能を考慮し、Mapで保持する
    let targetServingTimeMap: Map<string, TargetServingTime> = Map<
      string,
      TargetServingTime
    >()
    const categoriedItemIds =
      itemsResponse.result.itemCategoryJunctions?.map(
        junction => junction.itemId
      ) || []
    if (targetServingTimesResponse.result.targetServingTimes) {
      targetServingTimesResponse.result.targetServingTimes.forEach(value => {
        if (categoriedItemIds.includes(value.itemId)) {
          const targetServingTime = new TargetServingTime({
            itemId: value.itemId,
            noticeMinutes:
              value.noticeSecond != null
                ? (value.noticeSecond / 60).toString()
                : undefined,
            alertMinutes:
              value.alertSecond != null
                ? (value.alertSecond / 60).toString()
                : undefined,
            confidence: value.confidence,
            mode: value.mode
          })
          targetServingTimeMap = targetServingTimeMap.set(
            value.itemId,
            targetServingTime
          )
        }
      })
    }

    // 商品に紐づく目標配膳時間が存在しない場合は作成する
    // ただし、カテゴリー未設定の商品は除く
    items.getSimpleItems().forEach((item: SimpleItem) => {
      let targetServingTime: TargetServingTime | undefined =
        targetServingTimeMap.get(item.itemId)
      if (targetServingTime || !categoriedItemIds.includes(item.itemId)) {
        return
      }

      targetServingTime = new TargetServingTime({
        itemId: item.itemId,
        mode: ServingTimeModes.NON.ID
      })
      targetServingTimeMap = targetServingTimeMap.set(
        item.itemId,
        targetServingTime
      )
    })

    return targetServingTimeMap
  }

  private featchEstimatedTargetServingTimesSucceeded(
    response: any
  ): Map<string, EstimatedTargetServingTime> {
    const estimatedTargetServingTimesResponse: ApiResponse<EstimatedTargetServingTimesApiResponse> =
      response.data

    // 処理性能を考慮し、Mapで保持する
    return (
      estimatedTargetServingTimesResponse.result.estimatedTargetServingTimes?.reduce(
        (map, value) => {
          const estimatedTargetServingTime = new EstimatedTargetServingTime({
            itemId: value.itemId,
            noticeSecond: value.noticeSecond,
            alertSecond: value.alertSecond,
            confidence: value.confidence
          })

          return map.set(value.itemId, estimatedTargetServingTime)
        },
        Map<string, EstimatedTargetServingTime>()
      ) || Map<string, EstimatedTargetServingTime>()
    )
  }

  changeCategory(categoryId: string) {
    this.dispatch(actions.changeCategory(categoryId))
  }

  changeMode(itemId: string, mode: string) {
    switch (mode) {
      case ServingTimeModes.AUTO.ID: {
        this.dispatch(actions.changeModeToAuto(itemId))
        return
      }
      case ServingTimeModes.MANUAL.ID: {
        this.dispatch(actions.changeModeToManual(itemId))
        return
      }
      case ServingTimeModes.NON.ID: {
        this.dispatch(actions.changeModeToNon(itemId))
        return
      }
      default:
        this.dispatch(actions.changeModeToNon(itemId))
    }
  }

  public async post(
    targetServingTimeMap: Map<string, TargetServingTime>
  ): Promise<void> {
    this.dispatch(setLoading())
    await this.apiClient
      .post(
        API.POST_TARGET_SERVING_TIME,
        this.buildPostRequestForTargetServingTime(targetServingTimeMap)
      )
      .then(async () => {
        this.dispatch(actions.postTargetServingTimesSucceeded())
        await this.fetchInitialData()
        // @ts-ignore
        this.dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  private buildPostRequestForTargetServingTime(
    targetServingTimeMap: Map<string, TargetServingTime>
  ): TargetServingTimesApiRequest {
    return {
      targetServingTimes: targetServingTimeMap
        .toList()
        .map((t: TargetServingTime) => t.toRequest())
        .toArray()
    }
  }

  changeNoticeMinutes(itemId: string, noticeMinutes: string) {
    this.dispatch(actions.changeNoticeMinutes(itemId, noticeMinutes))
  }

  changeAlertMinutes(itemId: string, alertMinutes: string) {
    this.dispatch(actions.changeAlertMinutes(itemId, alertMinutes))
  }

  activateAllItemBatchEdit(initialServingTimeModeId: string) {
    this.dispatch(actions.activateAllItemBatchEdit(initialServingTimeModeId))
  }

  deactivateAllItemBatchEdit(initialServingTimeModeId: string) {
    this.dispatch(actions.deactivateAllItemBatchEdit(initialServingTimeModeId))
  }

  changeAllItemMode(modeId: string) {
    this.dispatch(actions.changeAllItemMode(modeId))
  }

  changeAllItemNoticeMinutes(noticeMinutes: string) {
    this.dispatch(actions.changeAllItemNoticeMinutes(noticeMinutes))
  }

  changeAllItemAlertMinutes(alertMinutes: string) {
    this.dispatch(actions.changeAllItemAlertMinutes(alertMinutes))
  }

  activateCategoryBatchEdit(
    categoryId: string,
    initialServingTimeModeId: string
  ) {
    this.dispatch(
      actions.activateCategoryBatchEdit(categoryId, initialServingTimeModeId)
    )
  }

  deactivateCategoryBatchEdit(
    categoryId: string,
    initialServingTimeModeId: string
  ) {
    this.dispatch(
      actions.deactivateCategoryBatchEdit(categoryId, initialServingTimeModeId)
    )
  }

  changeCategoryMode(categoryId: string, modeId: string) {
    this.dispatch(actions.changeCategoryMode(categoryId, modeId))
  }

  changeCategoryNoticeMinutes(categoryId: string, noticeMinutes: string) {
    this.dispatch(
      actions.changeCategoryNoticeMinutes(categoryId, noticeMinutes)
    )
  }

  changeCategoryAlertMinutes(categoryId: string, alertMinutes: string) {
    this.dispatch(actions.changeCategoryAlertMinutes(categoryId, alertMinutes))
  }

  openBatchEditFormModal(initialServingTimeModeId: string) {
    this.dispatch(actions.openBatchEditFormModal(initialServingTimeModeId))
  }

  closeBatchEditFormModal() {
    this.dispatch(actions.closeBatchEditFormModal())
  }

  applyBatchEdit(which: BatchEditSelected) {
    switch (which) {
      case 'all':
        this.dispatch(actions.applyAllItemBatchEdit())
        break
      case 'categories':
        this.dispatch(actions.applyCategoriesBatchEdit())
        break
    }
  }
}

export default TargetServingTimeInteractor
