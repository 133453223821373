// z-index 定数

export default {
  AIR_COMMON_HEADER: 10,
  AIR_OES_HEADER: 1,
  AIR_OES_SIDE_MENU: 2,
  AIR_OES_BALLOON: 10,
  AIR_OES_MODAL: 11,
  AIR_OES_MODAL_CONTAINER: 12,
  // TODO: スプラッシュは単独で完結させたいため、最前面(TOASTより前面)に来るべき。
  AIR_OES_SPLASH: 1002,
  AIR_OES_LOADING: 1003,
  AIR_OES_TOAST: 1004
}
