import { createAction } from 'typesafe-actions'
import { VariationEditor } from '~/src/redux/models/CSVItem/Variation'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationRetouch from '~/src/redux/models/CSVItem/VariationRetouch'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import {
  CLEAR_STATE,
  SELECT_ITEMS,
  DESELECT_ITEMS,
  OPEN_VARIATION_GROUP_MODAL,
  CLOSE_VARIATION_GROUP_MODAL,
  OPEN_VARIATION_GROUP_EDITOR,
  APPLY_VARIATION_GROUP_EDITOR,
  CANCEL_VARIATION_GROUP_EDITOR,
  ADD_NEW_VARIATION_ON_EDITOR,
  UPDATE_VARIATION_ON_EDITOR,
  SORT_VARIATION_ON_EDITOR,
  DELETE_VARIATION_ON_EDITOR,
  SET_DISCONNECT_VARIATION_GROUP,
  CLEAR_DISCONNECT_VARIATION_GROUP,
  DISCONNECT_VARIATION_GROUP,
  UPDATE_VARIATION_RETOUCH,
  FETCH_CATEGORIES_SUCCEEDED,
  FILE_LOAD_SUCCEEDED,
  FILE_LOAD_FAILED,
  FILE_DOWNLOAD_SUCCEEDED
} from './types'
import { CSVData } from '~/src/util/CSVFileReader'

export default {
  clearState: createAction(CLEAR_STATE)(),
  selectItems: createAction(SELECT_ITEMS, (itemIds: string[]) => ({
    itemIds
  }))(),
  deselectItems: createAction(DESELECT_ITEMS, (itemIds: string[]) => ({
    itemIds
  }))(),
  openVariationGroupModal: createAction(
    OPEN_VARIATION_GROUP_MODAL,
    (itemId: string) => ({ itemId })
  )(),
  closeVariationGroupModal: createAction(CLOSE_VARIATION_GROUP_MODAL)(),
  openVariationGroupEditor: createAction(OPEN_VARIATION_GROUP_EDITOR)(),
  applyVariationGroupEditor: createAction(APPLY_VARIATION_GROUP_EDITOR)(),
  cancelVariationGroupEditor: createAction(CANCEL_VARIATION_GROUP_EDITOR)(),
  addNewVariationOnEditor: createAction(ADD_NEW_VARIATION_ON_EDITOR)(),
  updateVariationOnEditor: createAction(
    UPDATE_VARIATION_ON_EDITOR,
    (variation: VariationEditor) => ({ variation })
  )(),
  sortVariationOnEditor: createAction(
    SORT_VARIATION_ON_EDITOR,
    (from: number, to: number) => ({ from, to })
  )(),
  deleteVariationOnEditor: createAction(
    DELETE_VARIATION_ON_EDITOR,
    (variationId: string) => ({ variationId })
  )(),
  setDisconnectVariationGroup: createAction(
    SET_DISCONNECT_VARIATION_GROUP,
    (itemId: string) => ({ itemId })
  )(),
  clearDisconnectVariationGroup: createAction(
    CLEAR_DISCONNECT_VARIATION_GROUP
  )(),
  disconnectVariationGroup: createAction(DISCONNECT_VARIATION_GROUP)(),
  updateVariationRetouch: createAction(
    UPDATE_VARIATION_RETOUCH,
    (itemId: string, retouch: VariationRetouch) => ({ itemId, retouch })
  )(),
  fetchCategoriesSucceeded: createAction(
    FETCH_CATEGORIES_SUCCEEDED,
    (categories: SimpleCategory[]) => ({ categories })
  )(),
  fileLoadSucceeded: createAction(
    FILE_LOAD_SUCCEEDED,
    (file: File, items: CSVItem[], header: CSVData) => ({ file, items, header })
  )(),
  fileLoadFailed: createAction(
    FILE_LOAD_FAILED,
    (fileErrorMessage: string) => ({ fileErrorMessage })
  )(),
  fileDownloadSucceeded: createAction(FILE_DOWNLOAD_SUCCEEDED)()
}
