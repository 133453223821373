import KitchenPosition from '~/src/redux/models/KitchenPositionSetting/KitchenPosition'
import { AppState } from '~/src/redux/reducer'
import { isNullOrUndefinedOrEmpty } from '~/src/util/Objects'

export const selectKitchenPositionSettingListRow =
  (kitchenPosition: KitchenPosition) => (state: AppState) => ({
    isSeatSetUsable: kitchenPosition ? kitchenPosition.isSeatSetUsable : false,
    isDefault: kitchenPosition ? kitchenPosition.isDefault : false,
    kitchenPositionName:
      !kitchenPosition ||
      isNullOrUndefinedOrEmpty(kitchenPosition.kitchenPositionName)
        ? ''
        : kitchenPosition.kitchenPositionName,
    validateName: kitchenPosition ? kitchenPosition.validateName() : null
  })
