import { AppState } from '~/src/redux/reducer'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import {
  createItemIdSet,
  generateItemNameWithEmptyCategoryAndSku
} from '~/src/api/handy/items/utils'
import { Item } from '~/src/components/organisms/Modal/RegisterItemModal/RegisterItemModal'
import { Category } from './types'
import { createSelector } from 'reselect'

export const selectIsEditing = (state: AppState) => state.hodaiSetting.isEditing

export const selectHodaiSettingState = (state: AppState) => state.hodaiSetting

export const selectIsSubmitButtonDisabled =
  (hodaiId?: string) => (state: AppState) => {
    if (!hodaiId) {
      return !state.hodaiSetting.selectedHodai?.itemId
    }

    return !state.hodaiSetting.isEditing
  }

export const selectIsSelectingHodaiPlan = (state: AppState) => {
  return state.hodaiSetting.isSelectingHodaiPlan
}

export const selectSelectedHodaiName =
  (hodaiId?: string) => (state: AppState) => {
    if (!hodaiId) {
      return state.hodaiSetting.selectedHodai?.name
    }

    const hodai = state.api.items.response.hodais?.find(
      hodai => hodai.hodaiId === hodaiId
    )

    return generateItemNameWithEmptyCategoryAndSku(
      hodai?.itemId,
      state.api.items.response
    )
  }

type ItemIdMap = {
  [_: string]: Item
}

type CategoryIdMap = {
  [_: string]: Item[]
}

// state -> modalに渡す形に整形する
export const selectModalCategories = (state: AppState) => {
  const response = state.api.items.response

  if (
    !response.items ||
    !response.itemCategoryJunctions ||
    !response.categories
  ) {
    // 商品が存在しないまたは商品カテゴリー紐付けが存在しないまたはカテゴリーが存在しない場合は空配列を返却する
    return []
  }

  // 放題子に設定された商品をdisableにするために商品IDを抽出
  const selectedItemIdSet = new Set(
    state.hodaiSetting.categories.flatMap(category =>
      category.items.filter(el => el.isSelected).map(el => el.itemId)
    )
  )
  const itemMap = createItemMap(response, selectedItemIdSet)

  // categoryに紐づくItem配列を categoryIdMap[categoryId]で参照できるようにする
  const categoryIdMap: CategoryIdMap = {}
  response.itemCategoryJunctions!.forEach(e => {
    if (!categoryIdMap[e.categoryId]) {
      categoryIdMap[e.categoryId] = [itemMap[e.itemId]]
      return
    }

    categoryIdMap[e.categoryId] = [
      ...categoryIdMap[e.categoryId],
      itemMap[e.itemId]
    ]
  })

  return response.categories!.map(category => ({
    id: category.categoryId,
    name: category.categoryName,
    items: categoryIdMap[category.categoryId]
  }))
}

export const selectLastOrderMinutesByHodaiId =
  (hodaiId: string) => (state: AppState) => {
    return state.api.items.response.hodais?.find(
      hodai => hodai.hodaiId === hodaiId
    )?.lastOrderMinutes
  }

export const selectItemIdByHodaiId = (hodaiId: string) => (state: AppState) => {
  return state.api.items.response.hodais?.find(
    hodai => hodai.hodaiId === hodaiId
  )?.itemId
}

const createItemMap = (
  response: ItemsApiResponse,
  selectedItemIdSet: Set<string>
) => {
  const itemIdSet = createItemIdSet(response)
  return response.items!.reduce((previous: ItemIdMap, current) => {
    // 商品がSKU、当画面で放題内容に設定されている
    // またはコース親・子、放題親・子、トッピンググループ、トッピング対象、お通し商品は無効化(disabled)する
    const disabled =
      !!current.skus?.length ||
      selectedItemIdSet.has(current.itemId) ||
      itemIdSet.courses.has(current.itemId) ||
      itemIdSet.courseDetails.has(current.itemId) ||
      itemIdSet.hodais.has(current.itemId) ||
      itemIdSet.hodaiDetails.has(current.itemId) ||
      itemIdSet.itemByToppingGroups.has(current.itemId) ||
      itemIdSet.toppingGroups.has(current.itemId) ||
      itemIdSet.otoshis.has(current.itemId)
    previous[current.itemId] = {
      id: current.itemId,
      name: current.itemName,
      price: current.price || 0,
      taxType: current.taxType,
      isDisplayed: current.isDisplayed,
      disabled
    }
    return previous
  }, {})
}

export const selectInitialCategories = createSelector(
  (state: AppState) => state.hodaiSetting.selectedHodai,
  (state: AppState) => state.api.items.response,
  (_: AppState, hodaiId: string) => hodaiId,
  (selectedHodai, itemsState, hodaiId): Category[] => {
    const itemMap = itemsState.items?.reduce((previous: any, current) => {
      previous[current.itemId] = current
      return previous
    }, {})

    // Step1で選択されている、またはコース親・放題プランに設定されている商品はグレーアウト
    const selectedHodaiItemId = selectedHodai?.itemId
    const coursesItemIds = new Set(
      itemsState.courses?.map(course => course.itemId)
    )
    const hodaisItemIds = new Set(itemsState.hodais?.map(hodai => hodai.itemId))

    // 対象の放題内容に含まれている商品ID
    const hodaiDetailsItemIds =
      new Set(
        itemsState.hodais
          ?.filter(hodai => hodai.hodaiId === hodaiId)
          .flatMap(hodai => hodai.hodaiDetails?.map(detail => detail.itemId))
      ) || []

    return (
      itemsState.categories?.map(category => {
        const items =
          itemsState.itemCategoryJunctions
            ?.filter(e => e.categoryId === category.categoryId)
            .map(e => {
              const item = itemMap[e.itemId]
              const disabled =
                selectedHodaiItemId === item.itemId ||
                coursesItemIds.has(item.itemId) ||
                hodaisItemIds.has(item.itemId)
              return {
                itemId: item.itemId,
                itemName: item.itemName,
                disabled,
                isDeleted: false, // 左のリスト出す商品はレジマスタから表示しているため未削除
                isDisplayed: item.isDisplayed,
                isCombinedCategory: true, // categoryと紐づいてるためtrue
                isSelected: hodaiDetailsItemIds.has(item.itemId),
                displayOrder: item.displayOrder
              }
            }) || []
        return {
          categoryId: category.categoryId,
          name: category.categoryName,
          isDisplayed: category.isDisplayed,
          displayOrder: category.displayOrder,
          items
        }
      }) || []
    )
  }
)
