import { AppState } from '~/src/redux/reducer'
import {
  ItemTagIcon,
  SelfOrderIcon,
  KitchenIcon,
  VariousSettingsIcon,
  HandyIcon,
  HowToIcon,
  MemoIcon,
  DishupIcon,
  OnlinePaymentIcon,
  TakeOutIcon,
  BusinessSupportIcon
} from '~/src/components/atoms/SideMenuIcon'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'
import { Store, Token } from '~/src/redux/modules/Auth/types'
import { Menu, MenuCondition } from './types'
import {
  containsKP,
  containsSPO,
  isHandyKm,
  containsPrepayment,
  containsTakeOut,
  containsHandy
} from '~/src/util/ServiceTypeCheck'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'

const { Page, URL, ContractType } = Const

export const selectMenuList = (state: AppState) => {
  const { store, token } = state.auth
  return {
    menuList: createMenuList(store, token)
  }
}

const createMenuList = (store: Store | undefined, token: Token | undefined) => {
  const isHandyStore = store ? containsHandy(store.serviceTypes) : false
  // KP先会計移行中はServiceTypesだけ見るとHANDY_APPとKM_APP両方持っているが、ハンディかつKMの機能は使えないのでisKpKmOnlyAvailableも見ている
  const isKMStore = store
    ? isHandyKm(store.serviceTypes) && !store.isKpKmOnlyAvailable
    : false
  const isKPStore = store ? containsKP(store.serviceTypes) : false
  const isKMOnlyStore = store ? containsPrepayment(store.serviceTypes) : false
  const isSpoStore = store ? containsSPO(store.serviceTypes) : false
  const isTakeOutStore = store ? containsTakeOut(store.serviceTypes) : false
  const isGopStore = store ? store.isGop : false
  const isManager = token ? token.isManager : false
  // 初期化に失敗した場合はRに問い合わせできるようにする
  const isContracted = !ContractType.isTrial(store ? store.contractType : '1')

  return menuMaster
    .filter(menuGroup =>
      isDisplay(
        isHandyStore,
        isKMStore,
        isKPStore,
        isKMOnlyStore,
        isSpoStore,
        isTakeOutStore,
        isGopStore,
        isManager,
        isContracted,
        menuGroup.conditions
      )
    )
    .map(menuGroup => {
      return menuGroup.items
        ? {
            ...menuGroup,
            items: menuGroup.items.filter(link =>
              isDisplay(
                isHandyStore,
                isKMStore,
                isKPStore,
                isKMOnlyStore,
                isSpoStore,
                isTakeOutStore,
                isGopStore,
                isManager,
                isContracted,
                link.conditions
              )
            )
          }
        : menuGroup
    })
}

const isDisplay = (
  isHandyStore: boolean,
  isKMStore: boolean,
  isKPStore: boolean,
  isKMOnlyStore: boolean,
  isSpoStore: boolean,
  isTakeOutStore: boolean,
  isGopStore: boolean,
  isManager: boolean,
  isContracted: boolean,
  conditions?: MenuCondition[]
) => {
  if (!conditions) {
    return true
  }
  return conditions.some(condition => {
    if (condition.managerOnly && !isManager) {
      return false
    }
    if (condition.spoStoreOnly && !isSpoStore) {
      return false
    }
    // 「契約店舗(notハンディKMお試し)でSPO店舗ではないかつhandy店舗」の場合のみSPOアップセルサイドメニューを出す
    // 契約店舗で絞っている理由：アップセルとして営業に取り次いでいるので、未契約店舗には出さない
    // handy店舗で絞っている理由：ハンディアプリを契約してないとSPOを契約できないので、プロモーションして営業に取り継いでも案内する契約がないため
    if (condition.displaySpoPromotion && (!isContracted || isSpoStore || !isHandyStore)) {
      return false
    }
    if (condition.tablePaymentStoreOnly) {
      // SPO店舗・TakeOut店舗はSPO決済を利用するのでテーブル決済店舗とは両立しない
      return isGopStore && !isSpoStore && !isTakeOutStore
    }
    if (condition.contractedOnly && !isContracted) {
      return false
    }
    if (condition.displayKmStore && !isKMStore) {
      return false
    }
    if (condition.displayKpStore && !isKPStore) {
      return false
    }
    if (condition.displayKmOnlyStore && !isKMOnlyStore) {
      return false
    }
    if (condition.displayTakeOutStore && !isTakeOutStore) {
      return false
    }
    return true
  })
}

// メニューはここに追加
// conditionsが無い場合は問答無用で表示される
// conditionsが記載されている場合は配列のうち、少なくともひとつが全て満たされるなら表示される
const menuMaster: Menu[] = [
  {
    menuTitle: 'メニュー',
    menuIcon: ItemTagIcon,
    items: [
      {
        title: '商品',
        link: Page.ITEM
      },
      {
        title: 'カテゴリー',
        link: Page.CATEGORY
      },
      {
        title: '放題プラン',
        link: Page.HODAI.LIST,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: 'コース',
        link: Page.COURSE.LIST,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: 'トッピング',
        link: Page.TOPPING.LIST,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: 'バリエーション',
        link: Page.VARIATION_GROUP.GETTING_STARTED,
        conditions: [{ displayKmStore: true }]
      }
    ]
  },
  {
    menuTitle: '注文',
    menuIcon: MemoIcon,
    conditions: [
      { displayKmStore: true },
      { displayKpStore: true },
      { displayKmOnlyStore: true }
    ],
    items: [
      {
        title: '注文メモ',
        link: Page.ORDER_ITEM_MEMO_TEMPLATE,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: '整理番号',
        link: Page.REFERENCE_NUMBER,
        conditions: [{ displayKmStore: true }, { displayKmOnlyStore: true }]
      },
      {
        title: 'お通し注文',
        link: Page.OTOSHI,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: '割引・割増',
        link: Page.DISCOUNT_SURCHARGE_SETTING,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      }
    ]
  },
  {
    menuTitle: '調理場',
    menuIcon: KitchenIcon,
    items: [
      {
        title: '注文エリア',
        link: Page.SEAT_SET.LIST,
        conditions: [{ displayKmStore: true }, { displayKpStore: true }]
      },
      {
        title: '調理場情報',
        link: Page.KITCHEN_POSITION,
        conditions: [
          { displayKmStore: true },
          { displayKmOnlyStore: true },
          { displayTakeOutStore: true }
        ]
      },
      {
        title: '調理場振り分け',
        link: Page.KITCHEN_POSITION_TARGET,
        conditions: [
          { displayKmStore: true },
          { displayKmOnlyStore: true },
          { displayTakeOutStore: true }
        ]
      },
      {
        title: '印刷振り分け',
        link: Page.PRINTER_TARGET.CATEGORIES,
        conditions: [{ displayKpStore: true }]
      }
    ]
  },
  {
    menuTitle: '目標提供時間',
    menuIcon: DishupIcon,
    menuLink: Page.TARGET_SERVING_TIME.SETTING,
    conditions: [
      { displayKmStore: true },
      { displayKpStore: true },
      { displayKmOnlyStore: true }
    ]
  },
  {
    menuTitle: '端末',
    menuIcon: HandyIcon,
    conditions: [
      { contractedOnly: true, displayKmStore: true },
      { contractedOnly: true, displayKpStore: true }
    ],
    items: [
      {
        title: '利用台数',
        link: Page.DEVICE_COUNT._INDEX,
        conditions: [{ managerOnly: true }]
      },
      {
        title: '登録端末',
        link: Page.REGISTERED_DEVICE
      }
    ]
  },
  {
    menuTitle: '設定',
    menuIcon: VariousSettingsIcon,
    menuLink: Page.SETTING
  },
  {
    menuTitle: 'モバイルオーダー',
    menuIcon: SelfOrderIcon,
    menuLink: URL.SPO_CATEGORY,
    showOtherTab: true,
    conditions: [{ spoStoreOnly: true }],
    rightIcon: ExternalLinkIcon
  },
  {
    menuTitle: 'モバイルオーダー',
    menuIcon: SelfOrderIcon,
    conditions: [{ displaySpoPromotion: true }],
    menuLink: Page.SPO_UP_SELL,
    onClick: () => {
      datadogLogs.logger.info(
        Datadog.BROWSER_LOG_MESSAGE.SPO_UP_SELL_FROM_SIDE_MENU.MESSAGE,
        { event: Datadog.BROWSER_LOG_MESSAGE.SPO_UP_SELL_FROM_SIDE_MENU.EVENT }
      )
    }
  },
  {
    menuTitle: 'モバイルオーダー店外版',
    menuIcon: TakeOutIcon,
    menuLink: URL.TAKEOUT_SITE,
    showOtherTab: true,
    conditions: [{ displayTakeOutStore: true }],
    rightIcon: ExternalLinkIcon,
    isWordWrap: true
  },
  {
    menuTitle: 'オンライン決済',
    menuIcon: OnlinePaymentIcon,
    menuLink: URL.SPO_URL,
    showOtherTab: true,
    conditions: [{ tablePaymentStoreOnly: true }],
    rightIcon: ExternalLinkIcon
  },
  {
    menuTitle: '経営サポート',
    menuIcon: BusinessSupportIcon,
    menuLink: Page.BUSINESS_SUPPORT,
    showOtherTab: false
  },
  {
    menuTitle: 'ヘルプ',
    menuIcon: HowToIcon,
    menuLink: URL.ORDER_FAQ,
    showOtherTab: true,
    rightIcon: ExternalLinkIcon
  }
]
