import React from 'react'
import { Map, Set } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import { MAX_NUM_TOPPINGS } from '~/src/redux/models/Topping/ToppingGroup'
import { SelectedToppingError } from '~/src/redux/modules/Topping/ToppingGroup/types'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { VerticalSpacer } from './Common/Spacer'
import { useSelector } from 'react-redux'

const { URL } = Const

const ErrorMessagesContainer: React.VFC = React.memo(() => {
  const selectedToppings = useSelector(
    (state: AppState) => state.toppingGroup.selectedToppingItemIds
  )
  const selectedToppingErrors = useSelector(
    (state: AppState) => state.toppingGroup.selectedToppingErrors
  )

  const warningMessages: (string | React.ReactElement)[] = []
  if (selectedToppings.size > MAX_NUM_TOPPINGS) {
    warningMessages.push(
      `トッピンググループに登録できるのは${MAX_NUM_TOPPINGS}点までです。あと${
        selectedToppings.size - MAX_NUM_TOPPINGS
      }点解除してください。`
    )
  }
  if (containsError(selectedToppingErrors, 'HAS_SKU')) {
    warningMessages.push(
      <>
        バリエーションが設定された商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (containsError(selectedToppingErrors, 'TAX_FREE')) {
    warningMessages.push(
      <>
        税設定が「非課税」の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (containsError(selectedToppingErrors, 'NO_CATEGORY')) {
    warningMessages.push(
      <>
        カテゴリーが未設定の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  return <ErrorMessages warningMessages={warningMessages} />
})
export { ErrorMessagesContainer as ErrorMessages }

const containsError = (
  errors: Map<string, Set<SelectedToppingError>>,
  target: SelectedToppingError
) => {
  return errors.some(err => !!err.get(target))
}

type InnerProps = {
  warningMessages: (string | React.ReactElement)[]
}
const ErrorMessages: React.VFC<InnerProps> = React.memo(
  ({ warningMessages }) => {
    if (warningMessages.length < 1) {
      return <></>
    }
    return (
      <>
        <Message type="warning" messageList={warningMessages} />
        <VerticalSpacer length={32} />
      </>
    )
  }
)
