import { combineReducers } from 'redux'
import { actions } from './actions'
import { waitingStatusSetting } from './WaitingStatus/reducers'
import { kmSettings } from '~/src/pages/TargetServingTime/OptionSetting/Km/reducers'
import { OptionSettingState } from '~/src/pages/TargetServingTime/OptionSetting/types'
import { ActionType, getType } from 'typesafe-actions'
const initialState = {
  isEditing: false
}

export const optionSetting = (
  state: OptionSettingState = initialState,
  action: ActionType<typeof actions>
): OptionSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.edited): {
      return {
        ...state,
        isEditing: true
      }
    }
    default:
      return state
  }
}

export const optionSettingParent = combineReducers({
  waitingStatusSetting,
  kmSettings,
  optionSetting
})
