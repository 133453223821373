import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { List } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn } from '../Common/Column'
import { CategoryRow } from './CategoryRow'

const { Size, Color } = Const

const ItemListContainer: React.VFC = () => {
  const categories = useSelector(
    (state: AppState) => state.toppingGroup.categories
  )
  return <ItemList categories={categories.valueSeq().toList()} />
}
export { ItemListContainer as ItemList }

type InnerProps = {
  categories: List<SimpleCategoryForTopping>
}
const ItemList: React.VFC<InnerProps> = React.memo(({ categories }) => {
  return (
    <Wrapper>
      <HeaderArea>
        <HeaderTitle>商品一覧</HeaderTitle>
      </HeaderArea>
      <MainContent>
        <TableHeader>
          <ContentColumn>カテゴリー / 商品</ContentColumn>
        </TableHeader>
        {categories.map(category => (
          <CategoryRow
            key={category.categoryId}
            categoryId={category.categoryId}
          />
        ))}
      </MainContent>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;
`

const HeaderArea = styled.div`
  height: 30px;
  text-align: left;
  vertical-align: middle;
`

const HeaderTitle = styled.span`
  font-size: ${Size.FONT.BASE}px;
  vertical-align: middle;
  font-weight: bold;
`

const MainContent = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`

const TableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
