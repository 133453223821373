import React, {
  useState,
  useRef,
  useLayoutEffect,
  ReactElement,
  useCallback
} from 'react'
import styled from 'styled-components'
import { DownArrowIcon as DownArrow } from '~/src/components/atoms/Icon'

type ParentProps = {
  isOpened: boolean
}

type Props = {
  defaultValue?: boolean
  Parent: (props: ParentProps) => ReactElement
  animationMilliseconds?: number
}

const DefaultMilliseconds = 300

const Accordion: React.FC<Props> = props => {
  const {
    Parent,
    children,
    defaultValue = true,
    animationMilliseconds = DefaultMilliseconds
  } = props
  const [isOpened, setIsOpened] = useState<boolean>(defaultValue)
  const [childrenHeight, setChildrenHeight] = useState<number | undefined>(
    undefined
  )
  const chldrenHeightRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    if (!chldrenHeightRef.current) return
    setChildrenHeight(chldrenHeightRef.current.clientHeight)
  }, [])
  const onClick = useCallback(() => {
    setIsOpened(!isOpened)
  }, [isOpened])

  return (
    <>
      <div onClick={onClick}>
        <Parent isOpened={isOpened} />
      </div>
      <ChildrenWrapper
        isOpened={isOpened}
        childrenHeight={childrenHeight}
        animationMilliseconds={animationMilliseconds}
      >
        <div ref={chldrenHeightRef}>{children}</div>
      </ChildrenWrapper>
    </>
  )
}

/**
 * 閉じる機構
 * `max-height` の値を内部要素の高さから0へ変えて開く閉じるを実現し、
 * `overflow: hidden` で内部要素を表示崩れしないようにしている。
 */
const ChildrenWrapper = styled.div<{
  childrenHeight?: number
  isOpened: boolean
  animationMilliseconds: number
}>`
  overflow: hidden;
  transition: all ease ${props => props.animationMilliseconds}ms;
  max-height: ${props => props.childrenHeight}px;
  ${props => !props.isOpened && 'max-height: 0;'}
`

const IconArea = styled.span<{
  isOpened: boolean
  animationMilliseconds?: number
}>`
  svg {
    /* stylelint-disable-next-line declaration-colon-newline-after */
    transition: all ease
      ${props => props.animationMilliseconds || DefaultMilliseconds}ms;
    ${props =>
      props.isOpened
        ? 'transform: rotate(-180deg);'
        : 'transform: rotate(0deg);'}
  }
`

export const DownArrowIcon = (props: {
  isOpened: boolean
  animationMilliseconds?: number
}) => (
  <IconArea {...props}>
    <DownArrow />
  </IconArea>
)

export default Accordion
