import React from 'react'
import { useSelector } from 'react-redux'

import Footer from '~/src/components/organisms/Footer'
import { selectSeatSetDetail } from './selector'
import { useOpenDeleteConfirmModal, usePostSeatSet } from './hooks'

export const SeatSetDetailFooter = () => {
  const { isUpdate, isEditing, hasError } = useSelector(selectSeatSetDetail)

  const handleOpenDeleteConfirmModal = useOpenDeleteConfirmModal()
  const handleOnSubmit = usePostSeatSet()

  return (
    <Footer
      disableSubmitButton={!isEditing || hasError}
      noUseOptionButton={!isUpdate}
      optionButtonText="注文エリアを削除する"
      onOptionButtonClick={handleOpenDeleteConfirmModal}
      onSubmitButtonClick={handleOnSubmit}
    />
  )
}
