import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import Radio from '~/src/components/atoms/Radio'
import {
  SelectColumn,
  ItemNameColumn,
  CategoryNameColumn,
  PriceColumn
} from './Column'
import Const from '~/src/const'
const { TaxType, Color } = Const

export type Props = {
  item: Item
  selected: string
  onChange: (selectedItem: SelectedItem) => void
}

export type SelectedItem = {
  id: string
  name: string
}

export type Item = {
  id: string
  name: string
  categoryName: string
  price: number
  taxType: string
  isDisplayed: boolean // trueの場合は 商品名の後に (非表示) を表示する
  disabled: boolean
}

export const Row: React.VFC<Props> = props => {
  const onChange = () => {
    if (!props.item.disabled) {
      props.onChange({ id: props.item.id, name: props.item.name })
    }
  }
  const displayPrice =
    '¥' +
    props.item.price.toLocaleString() +
    '(' +
    TaxType[props.item.taxType as keyof typeof TaxType] +
    ')'
  return (
    <TableRowWrapper disabled={props.item.disabled}>
      <SelectColumn>
        <RadioWrapper>
          <Radio
            name="selected-hodai-plan"
            value={props.item.name}
            disabled={props.item.disabled}
            checked={props.item.id === props.selected}
            onChange={onChange}
          />
        </RadioWrapper>
      </SelectColumn>
      <ItemNameColumn>{props.item.name}</ItemNameColumn>
      <CategoryNameColumn>{props.item.categoryName}</CategoryNameColumn>
      <PriceColumn>{displayPrice}</PriceColumn>
    </TableRowWrapper>
  )
}

const RadioWrapper = styled.label`
  display: block;
  cursor: pointer;
`
const TableRowWrapper = styled(TableRow)<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    box-shadow: inset 0 -1px 0 0 ${Color.LIGHT_GRAY_4};
    `}
`
