import { AppState } from '~/src/redux/reducer'

export const selectOrderItemMemoTemplateParams = (state: AppState) => {
  const deletingMemoIndex = state.orderItemMemoTemplate.deletingMemoIndex
  const deletingMemo =
    state.orderItemMemoTemplate.memos.orderItemMemoTemplates.get(
      deletingMemoIndex
    )
  let deleteConfirmModalTitle = ''
  let deleteConfirmModalMessage = ''
  if (deletingMemo) {
    deleteConfirmModalTitle =
      deletingMemo.getMemo() !== ''
        ? `「${deletingMemo.getMemo()}」の削除`
        : '注文メモの削除'
    deleteConfirmModalMessage =
      deletingMemo.getMemo() !== ''
        ? `注文メモ「${deletingMemo.getMemo()}」を削除しますがよろしいですか？`
        : '選択された注文メモを削除しますがよろしいですか？'
  }
  const validationMessage = state.orderItemMemoTemplate.memos.validate()
  return {
    isDeleteConfirmModalOpen:
      state.orderItemMemoTemplate.isDeleteConfirmModalOpen,
    deletingMemoIndex,
    deletingMemo,
    deleteConfirmModalTitle,
    deleteConfirmModalMessage,
    validationMessage,
    isEditing: state.orderItemMemoTemplate.isEditing
  }
}
