import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Multiselect from '~/src/components/atoms/Multiselect'
import { ColumnBase } from '../Column'
import { selectPrinterByCategoriesSeatSet } from './selectors'
import { useHandleReplacePrinter } from './hooks'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'

type SeatSetColumnProps = {
  height: number
  width: number
  category: SimpleCategory
  seatSet: SeatSet
}

export const SeatSetColumn = (props: SeatSetColumnProps) => {
  const { height, width, category, seatSet } = props

  const { printerOptions, selectedPrinterIds } = useSelector(
    selectPrinterByCategoriesSeatSet(category.categoryId, seatSet.seatSetId)
  )

  const handleSelectChange = useHandleReplacePrinter(
    category.categoryId,
    seatSet.seatSetId
  )

  const id = `${category.categoryId}-${seatSet.seatSetId}`
  return (
    <SeatSetColumnWrapper height={height.toString()} width={width.toString()}>
      <Multiselect
        id={id}
        name={id}
        placeholder="選択してください"
        options={printerOptions}
        values={selectedPrinterIds}
        handleSelectChange={values => handleSelectChange(values)}
      />
    </SeatSetColumnWrapper>
  )
}

export const SeatSetColumnWrapper = styled(ColumnBase)`
  padding-left: 28px;
`
