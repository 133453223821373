import React from 'react'
import styled from 'styled-components'
import Icon from '~/src/components/atoms/Icon'
import Const from '~/src/const'
import TitleColumn from '../../Common/TitleColumn'
import NormalColumn from '../../Common/NormalColumn'
import ArrowColumn from '../../Common/ArrowColumn'
import TableRow from '~/src/components/atoms/TableRow'
import equal from 'fast-deep-equal'
import { useSelector } from 'react-redux'
import { selectInnerTable } from './selectors'

const { Color } = Const

export const InnerTable = () => {
  const {
    currentCount,
    billingTargetCount,
    flatRateCount,
    confirmedDeviceCount,
    deviceCountAppended,
    newDeviceCountAppended,
    billingTargetDate,
    requestAt,
    newDeviceCountMax
  } = useSelector(selectInnerTable, equal)

  return (
    <div>
      <ConfirmHeaderArea>
        <TitleColumn />
        <NormalColumn>
          <TableTitleTextArea>変更前</TableTitleTextArea>
        </NormalColumn>
        <ArrowColumn />
        <NormalColumn>
          <TableTitleTextArea>変更後</TableTitleTextArea>
        </NormalColumn>
      </ConfirmHeaderArea>
      <TableRow>
        <TitleColumn>利用台数</TitleColumn>
        <NormalColumn>{currentCount} 台</NormalColumn>
        <ArrowColumn>
          <Arrow />
        </ArrowColumn>
        <NormalColumn>{confirmedDeviceCount} 台</NormalColumn>
      </TableRow>
      <TableRow>
        <TitleColumn>
          今月の請求対象台数
          <br />
          {requestAt}
        </TitleColumn>
        <NormalColumn>
          {billingTargetCount} 台（定額分 {flatRateCount} 台 + 追加分{' '}
          {deviceCountAppended} 台）
          <br />
          <Description>利用端末台数が最大の日：{billingTargetDate}</Description>
        </NormalColumn>
        <ArrowColumn>
          <Arrow />
        </ArrowColumn>
        <NormalColumn>
          {newDeviceCountMax} 台（定額分 {flatRateCount} 台 + 追加分{' '}
          {newDeviceCountAppended} 台）
          <br />
        </NormalColumn>
      </TableRow>
    </div>
  )
}

const ConfirmHeaderArea = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
`

const Arrow = styled(Icon).attrs({ name: 'arrow' })``

const Description = styled.div`
  padding: 4px 0;
  text-align: left;
  color: ${Color.DARK_GRAY_4};
`

const TableTitleTextArea = styled.div`
  display: inline-block;
  font-weight: bold;
`

export default InnerTable
