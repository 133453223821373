import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
`

export const fadeOut = keyframes`
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
`

export const showHorizontal = keyframes`
  from {
    visibility: hidden;
    transform: translateX(100%);
  }
  to {
    visibility: visible;
    transform: translateX(0);
  }
`
export const hideHorizontal = keyframes`
  from {
    visibility: visible;
    transform: translateX(0);
  }
  to {
    visibility: hidden;
    transform: translateX(100%);
  }
`

export const showVertical = keyframes`
  from {
    visibility: hidden;
    transform: translateY(100%);
  }
  to {
    visibility: visible;
    transform: translateY(0);
  }
`
export const hideVertical = keyframes`
  from {
    visibility: visible;
    transform: translateY(0);
  }
  to {
    visibility: hidden;
    transform: translateY(100%);
  }
`
