import React, { useEffect } from 'react'

import { InnerList } from './InnerList'
import { Footer } from './Footer'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import PageTemplate from '~/src/components/templates/PageTemplate'
import styled from 'styled-components'
import { ToppingListInteractor } from '~/src/interactors/Topping/ToppingList/ToppingListInteractor'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import Message from '~/src/components/atoms/Message'
import { List } from 'immutable'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'

const { URL, Color } = Const

type Props = {
  isDeleteConfirmModalOpen: boolean
  interactor: ToppingListInteractor
  toppingGroupIdForDeleteModal: string
  deleteDialogHeaderMessage: string
  deleteDialogMessage: string
  warningMessages: List<string>
  cautionMessages: List<string>
  toppingGroupIdAndVersions: List<{ toppingGroupId: string; version: number }>
  isEditing: boolean
  openMaxToppingGroupModal: boolean
  toppingGroupsCount: number
}

export const ToppingList: React.VFC<Props> = (props: Props) => {
  const {
    isDeleteConfirmModalOpen,
    interactor,
    toppingGroupIdForDeleteModal,
    deleteDialogHeaderMessage,
    deleteDialogMessage,
    warningMessages,
    cautionMessages,
    toppingGroupIdAndVersions,
    isEditing,
    openMaxToppingGroupModal,
    toppingGroupsCount
  } = props

  useEffect(() => {
    interactor.clearState()
    interactor.fetchInitialData()

    return interactor.clearState
  }, [interactor])

  const modal = (
    <>
      <ModalWithLineBreak>
        <DeleteConfirmModal
          isOpen={isDeleteConfirmModalOpen}
          onCancel={() => interactor.closeDeleteConfirmModalDispatcher()}
          onDelete={() => interactor.onDelete(toppingGroupIdForDeleteModal)}
          headingMessage={deleteDialogHeaderMessage}
          okText="解除する"
        >
          {deleteDialogMessage && <>{deleteDialogMessage}</>}
        </DeleteConfirmModal>
        <DismissPrompt enabled={isEditing} />
      </ModalWithLineBreak>
      <ErrorModal
        isError={openMaxToppingGroupModal}
        error={{
          title: 'トッピンググループを登録できません',
          message:
            '登録できるトッピンググループの上限数は50件です。新しく登録する場合は、既にあるトッピンググループを削除してください。'
        }}
        onClose={() => interactor.closeMaxToppingGroupModal()}
      />
    </>
  )
  return (
    <PageTemplate
      breadcrumbs={[{ title: 'トッピング' }]}
      description={['商品に追加するトッピングを設定できます。']}
      footer={
        <Footer
          canSubmit={isEditing}
          saveButtonHandler={() =>
            interactor.updateOrder(toppingGroupIdAndVersions)
          }
          beforeOptionButtonClick={() =>
            interactor.beforeOptionButtonClick(toppingGroupsCount)
          }
        />
      }
      modal={modal}
      displayKM
      displayKP
    >
      {(warningMessages.size !== 0 || cautionMessages.size !== 0) && (
        <Wrapper>
          <Message type="warning" messageList={warningMessages.toArray()} />
          <Message type="caution" messageList={cautionMessages.toArray()} />
        </Wrapper>
      )}

      <ToppingFAQ>
        ※トッピングについて、詳しくは
        <InlineLink href={URL.TOPPING_FAQ} target="_blank">
          トッピングの設定
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </ToppingFAQ>
      <InnerList />
    </PageTemplate>
  )
}

const ModalWithLineBreak = styled.div`
  white-space: pre-line;
`

const ToppingFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`

const Wrapper = styled.div`
  padding-bottom: 16px;
`
