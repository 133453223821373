import React from 'react'
import { Modal } from '@air-kit/air-kit'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import { List } from './List/List'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { selectCategoryModal } from './selectors'
import { useApply, useClose } from './hooks'
const { Size } = Const

export const CategoryModal = () => {
  const { isEditing, categoryName } = useSelector(selectCategoryModal)

  const handleClose = useClose(isEditing)
  const handleApply = useApply()

  const footer = {
    right: (
      <div>
        <StyledButton normal name="cancel-button" onClick={handleClose}>
          キャンセル
        </StyledButton>
        <StyledButton primary disabled={!isEditing} onClick={handleApply}>
          反映する
        </StyledButton>
      </div>
    )
  }
  return (
    <Adjuster>
      <Modal title="商品別振り分け設定" footer={footer} onClose={handleClose}>
        <CategoryName>カテゴリー： {categoryName}</CategoryName>
        <List />
      </Modal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`
const StyledButton = styled(Button)`
  width: auto;
`

const CategoryName = styled.div`
  font-weight: bold;
`
