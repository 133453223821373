import { Set, OrderedMap, Map } from 'immutable'
import { ToppingGroup } from '~/src/redux/models/Topping/ToppingGroup'
import { ItemByToppingGroups } from '~/src/redux/models/Item/ItemByToppingGroups'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'

export type ItemError =
  | 'HODAI'
  | 'COURSE'
  | 'TAX_FREE'
  | 'NO_CATEGORY'
  | 'OTOSHI'

export type SelectedItemError = 'TAX_FREE' | 'NO_CATEGORY'

export type ItemByToppingGroupState = {
  categories: OrderedMap<string, SimpleCategoryForTopping>
  items: OrderedMap<string, SimpleItemForTopping>
  itemByToppingGroups: ItemByToppingGroups
  toppingGroup: ToppingGroup
  selectedItemIds: Set<string>
  isEdited: boolean
  itemErrors: Map<string, Set<ItemError>>
  selectedItemErrors: Map<string, Set<SelectedItemError>>
  isToppingGroupNotFound: boolean
}

export const CLEAR_STATE = 'oes/Topping/ItemByToppingGroup/CLEAR_STATE'
export const FETCH_INITIAL_DATA_SUCCEEDED =
  'oes/Topping/ItemByToppingGroup/FETCH_INITIAL_DATA_SUCCEEDED'
export const ADD_ITEM = 'oes/Topping/ItemByToppingGroup/ADD_ITEM'
export const REMOVE_ITEM = 'oes/Topping/ItemByToppingGroup/REMOVE_ITEM'
export const ADD_ALL = 'oes/Topping/ItemByToppingGroup/ADD_ALL'
export const REMOVE_ALL = 'oes/Topping/ItemByToppingGroup/REMOVE_ALL'
export const SAVE_SUCCEEDED = 'oes/Topping/ItemByToppingGroup/SAVE_SUCCEEDED'
