import Const from '~/src/const'

const { URL } = Const

export const login = () => {
  location.assign(URL.HANDY_LOGIN(document.URL))
}

export const logout = () => {
  location.assign(URL.HANDY_LOGOUT)
}

export default {
  login,
  logout
}
