export type ServeingTimeModeId = '1' | '2' | '3'

export type ServingTimeMode = {
  ID: ServeingTimeModeId
  VALUE: string
}

/**
 * SelectBoxに合わせた型
 * SelectBoxがts化されたタイミングで除却
 */
type ServingTimeModeOption = {
  id: string
  value: ServeingTimeModeId
  label: string
}

const MANUAL: ServingTimeMode = {
  ID: '1',
  VALUE: '手動設定'
}

const AUTO: ServingTimeMode = {
  ID: '2',
  VALUE: '自動設定'
}

export const NON: ServingTimeMode = {
  ID: '3',
  VALUE: '設定しない'
}

const ServingTimeModes = {
  MANUAL: MANUAL,
  AUTO: AUTO,
  NON: NON
}

const options: ServingTimeModeOption[] = [
  {
    id: 'AUTO',
    value: ServingTimeModes.AUTO.ID,
    label: ServingTimeModes.AUTO.VALUE
  },
  {
    id: 'MANUAL',
    value: ServingTimeModes.MANUAL.ID,
    label: ServingTimeModes.MANUAL.VALUE
  },
  {
    id: 'NON',
    value: ServingTimeModes.NON.ID,
    label: ServingTimeModes.NON.VALUE
  }
]

const optionsForKp: ServingTimeModeOption[] = [
  {
    id: 'MANUAL',
    value: ServingTimeModes.MANUAL.ID,
    label: ServingTimeModes.MANUAL.VALUE
  },
  {
    id: 'NON',
    value: ServingTimeModes.NON.ID,
    label: ServingTimeModes.NON.VALUE
  }
]

export const servingTimeModeOptions = (
  isAutomaticSettingAvailable: boolean
): ServingTimeModeOption[] => {
  return isAutomaticSettingAvailable ? options : optionsForKp
}

export default ServingTimeModes
