import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Hint from '~/src/components/molecules/Hint'
import ColorDot from '~/src/components/atoms/ColorDot'
import {
  TableRow,
  ItemNameColumn,
  SettingMethodSelectColumn,
  NoticeSecondColumn,
  AlertSecondColumn,
  AutomaticSettingConfidenceColumn
} from '../Common/TableParts'
import Const from '~/src/const'
import { isKpPlanSelector } from '~/src/redux/selectors/storeSelectors'

const SETTING_METHOD_BALLOON_WIDTH = 262
const NOTICE_SECOND_BALLOON_WIDTH = 276
const ALERT_SECOND_BALLOON_WIDTH = 262
const CONFIDENCE_BALLOON_WIDTH = 322
const { Color } = Const

type Props = {
  isAutomaticSettingAvailable: boolean
}

const HeaderRow: React.FC<Props> = props => {
  const { isAutomaticSettingAvailable } = props
  const isKpPlan = useSelector(isKpPlanSelector)
  const hintTextForNoticeSecond = isKpPlan ? (
    <>
      着手しないと提供遅れになりそうな注意喚起の時間を設定します。
      <br />
      注意時間を過ぎた注文商品には黄色の目印が表示されます。
    </>
  ) : (
    <>
      着手しないと提供遅れになりそうな注意喚起の時間を設定します。
      <br />
      注意時間を過ぎた注文商品にはハンディで黄色の目印、キッチンモニターで着手アラートが表示されます。
    </>
  )
  const hintTextForAlertSecond = isKpPlan ? (
    <>
      提供遅れが確実な時間を設定します。
      <br />
      警告時間を過ぎた注文商品には赤色の目印が表示されます。
    </>
  ) : (
    <>
      提供遅れが確実な時間を設定します。
      <br />
      警告時間を過ぎた注文商品にはハンディで赤色の目印が表示されます。
      <br />
      キッチンモニターには表示されません。
    </>
  )
  return (
    <TableRow header>
      <ItemNameColumn>商品名</ItemNameColumn>
      <SettingMethodSelectColumn>
        設定方法
        <Hint
          position="bottom-right"
          balloonWidth={SETTING_METHOD_BALLOON_WIDTH}
        >
          目標提供時間の設定方法を選べます。
        </Hint>
      </SettingMethodSelectColumn>
      <NoticeSecondColumn>
        注意時間
        <StyledDot color={Color.LIGHT_ORANGE} />
        <Hint position="bottom-left" balloonWidth={NOTICE_SECOND_BALLOON_WIDTH}>
          {hintTextForNoticeSecond}
        </Hint>
      </NoticeSecondColumn>
      <AlertSecondColumn>
        警告時間
        <StyledDot color={Color.MODERATE_RED} />
        <Hint position="bottom-left" balloonWidth={ALERT_SECOND_BALLOON_WIDTH}>
          {hintTextForAlertSecond}
        </Hint>
      </AlertSecondColumn>
      {isAutomaticSettingAvailable && (
        <AutomaticSettingConfidenceColumn>
          自動設定精度
          <Hint position="top-left" balloonWidth={CONFIDENCE_BALLOON_WIDTH}>
            自動設定により設定された時間の精度を表します。商品ごとの注文回数が多くなればなるほど精度も高まります。
          </Hint>
        </AutomaticSettingConfidenceColumn>
      )}
    </TableRow>
  )
}

// マイナスを使うのは良くないが、この指定がないと中心からずれる
const StyledDot = styled(ColorDot)`
  margin: -2px 5px;
`

export default React.memo(HeaderRow)
