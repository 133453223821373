import { ActionType, getType } from 'typesafe-actions'
import { HandySettingApiState } from './types'
import { actions } from './actions'

export const initialState = {
  response: {
    setting: {
      isOtoshiEnabled: false,
      isSalesDisplayed: true,
      isDiscountSurchargeOnVisitEnabled: false,
      taxDisplayType: '1',
      version: 0
    }
  },
  fetched: false,
  failed: false
}

export const handySettingApi = (
  state: HandySettingApiState = initialState,
  action: ActionType<typeof actions>
): HandySettingApiState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return {
        ...initialState
      }
    }
    case getType(actions.fetchSucceeded): {
      return {
        ...state,
        response: action.payload.response,
        fetched: true,
        failed: false
      }
    }
    case getType(actions.fetchFailed): {
      return {
        ...state,
        failed: true
      }
    }
    default:
      return state
  }
}
