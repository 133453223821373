import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { actions } from '../actions'

export const useUpdateLastOrderMinutes = () => {
  const dispatch = useDispatch()
  return useCallback(
    (lastOrderMinutes: string) =>
      dispatch(actions.updateLastOrderMinutes(lastOrderMinutes)),
    [dispatch]
  )
}

export const useUpdateLastOrderSetMode = () => {
  const dispatch = useDispatch()
  return useCallback(
    (lastOrderSetMode: 'manual' | 'auto') =>
      dispatch(actions.updateLastOrderSetMode(lastOrderSetMode)),
    [dispatch]
  )
}

export const useUpdateLastOrderIsComposing = () => {
  const dispatch = useDispatch()
  return useCallback(
    (lastOrderIsComposing: boolean) =>
      dispatch(actions.updateLastOrderIsComposing(lastOrderIsComposing)),
    [dispatch]
  )
}
