import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import TableColumn from '~/src/components/atoms/TableColumn'
import Toggle from '~/src/components/atoms/Toggle'
import TableRow from '~/src/components/atoms/TableRow'

const { Size, Color } = Const

export type Props = {
  onToggle: Function
  name?: string
  disabled?: boolean
  checked: boolean
  title: string
  description: string
  hasBorderTop?: boolean
}

export const SettingToggle: React.VFC<Props> = ({
  onToggle,
  name,
  disabled,
  checked,
  title,
  description,
  hasBorderTop
}) => (
  <StyledBaseRow
    hasBorderTop={hasBorderTop === undefined ? true : hasBorderTop}
  >
    <SettingNameArea>
      {title}
      <Description>{description}</Description>
    </SettingNameArea>
    <ToggleAreaStyle>
      <Toggle
        onToggle={onToggle}
        name={name}
        checked={checked}
        disabled={!!disabled}
      />
    </ToggleAreaStyle>
  </StyledBaseRow>
)

const SettingNameArea = styled(TableColumn)`
  text-align: left;
  width: 100%;
`
const ToggleAreaStyle = styled.div`
  width: 100px;
  margin: 8px 16px;
  float: right;
  text-align: right;
`
const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`

const StyledBaseRow = styled(TableRow)<{ hasBorderTop: boolean }>`
  border-top: ${props => {
    return props.hasBorderTop === true
      ? '1px solid ' + Color.VERY_LIGHT_GRAY_6
      : 'none'
  }};
  width: 480px;
  max-width: 100%;
  font-size: ${Size.FONT.LARGE}px;
`
