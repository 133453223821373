import APIClient from '~/src/util/APIClient'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import Items from '~/src/redux/models/Item/Items'
import Categories from '~/src/redux/models/Category/Categories'
import Printer from '~/src/redux/models/Printer/Printer'
import Printers from '~/src/redux/models/Printer/Printers'
import SelectedPrinterHolder, {
  UndefinedId
} from '~/src/redux/models/PrintTarget/SelectedPrinterHolder'
import BatchSelect from '~/src/redux/models/PrintTarget/BatchSelect'
import { FetchResponses, PrintTargetByCategoryState } from './types'

const { API, DisplayText, Toast } = Const

// Action Type ConstantValue
const FETCH_PRINT_TARGET_BY_CATEGORY_SUCCESS =
  'oes/PrintTargetByCategory/FETCH_PRINT_TARGET_BY_CATEGORY_SUCCESS'
const CLEAR_PRINT_TARGET_BY_CATEGORY_STATE =
  'oes/PrintTargetByCategory/CLEAR_PRINT_TARGET_BY_CATEGORY_STATE'
const REPLACE_PRINTER_BY_CATEGORY_AND_SEAT_SET =
  'oes/PrintTargetByCategory/REPLACE_PRINTER_BY_CATEGORY_AND_SEAT_SET'
const REPLACE_PRINT_TARGET_BY_CATEGORY_SUCCESS =
  'oes/PrintTargetByCategory/REPLACE_PRINT_TARGET_BY_CATEGORY_SUCCESS'
const SET_BATCH_SELECT_SEAT_SET =
  'oes/PrintTargetByCategory/SET_BATCH_SELECT_SEAT_SET'
const CLEAR_BATCH_SELECT_STATE =
  'oes/PrintTargetByCategory/CLEAR_BATCH_SELECT_STATE'
const REPLACE_BATCH_SELECT_PRINTERS =
  'oes/PrintTargetByCategory/REPLACE_BATCH_SELECT_PRINTERS'
const CHECK_BATCH_SELECT_CATEGORY =
  'oes/PrintTargetByCategory/CHECK_BATCH_SELECT_CATEGORY'
const CHECK_BATCH_SELECT_ALL_CATEGORY =
  'oes/PrintTargetByCategory/CHECK_BATCH_SELECT_ALL_CATEGORY'
const REPLACE_PRINTER_BY_BATCH_SELECT =
  'oes/PrintTargetByCategory/REPLACE_PRINTER_BY_BATCH_SELECT'

// Action
export const fetchResources = () => {
  // @ts-ignore
  return dispatch => {
    return Promise.all([
      APIClient.get(API.GET_ITEMS),
      APIClient.get(API.GET_SEATS),
      APIClient.get(API.GET_PRINTERS)
    ])
      .then(dispatch(setLoading()))
      .then(([itemsResponse, seatsResponse, printersResponse]) => {
        return {
          items: itemsResponse.data,
          seats: seatsResponse.data,
          printers: printersResponse.data
        }
      })
      .then(responseMap => dispatch(fetchResourceSuccess(responseMap)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const replacePrinterByCategoryAndSeatSet = (
  categoryId: string,
  seatSetId: string,
  printerIds: string[]
) => {
  return {
    type: REPLACE_PRINTER_BY_CATEGORY_AND_SEAT_SET,
    categoryId: categoryId,
    seatSetId: seatSetId,
    printerIds: printerIds
  }
}

export const clearPrinterByCategorySeatSetsState = () => {
  return {
    type: CLEAR_PRINT_TARGET_BY_CATEGORY_STATE
  }
}

export const replaceResource = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const { printTargetByCategories } = getState()
    const body =
      printTargetByCategories.selectedPrinterHolder.buildRequestJsonForReplace()
    return APIClient.post(API.POST_REPLACE_PRINT_TARGET_BY_CATEGORY, body)
      .then(dispatch(setLoading()))
      .then(() => dispatch(replaceResourceSuccess()))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const replaceResourceSuccess = () => {
  return {
    type: REPLACE_PRINT_TARGET_BY_CATEGORY_SUCCESS
  }
}

export const replacePrinterByBatchSelect = () => {
  return {
    type: REPLACE_PRINTER_BY_BATCH_SELECT
  }
}

const fetchResourceSuccess = (responseMap: FetchResponses) => {
  return {
    type: FETCH_PRINT_TARGET_BY_CATEGORY_SUCCESS,
    response: responseMap
  }
}

export const setBatchSelectSeatSet = (seatSetId: string) => {
  return {
    type: SET_BATCH_SELECT_SEAT_SET,
    seatSetId: seatSetId
  }
}

export const replaceBatchSelectPrinters = (printerIds: string[]) => {
  return {
    type: REPLACE_BATCH_SELECT_PRINTERS,
    printerIds: printerIds
  }
}

export const checkBatchSelectCategory = (
  categoryId: string,
  isChecked: boolean
) => {
  return {
    type: CHECK_BATCH_SELECT_CATEGORY,
    categoryId: categoryId,
    isChecked: isChecked
  }
}

export const checkBatchSelectAllCategory = (isChecked: boolean) => {
  return {
    type: CHECK_BATCH_SELECT_ALL_CATEGORY,
    isChecked: isChecked
  }
}

export const clearBatchSelectState = () => {
  return {
    type: CLEAR_BATCH_SELECT_STATE
  }
}

// Initial state
const initialState: PrintTargetByCategoryState = {
  categories: new Categories().getSimpleCategories(),
  seatSets: [],
  printers: new Printers(),
  printerOptions: [],
  selectedPrinterHolder: new SelectedPrinterHolder(),
  batchSelect: new BatchSelect(),
  isEditing: false,
  isFetched: false,
  isSeatSetNotFound: false
}

// Reducer
// @ts-ignore
export default (state = initialState, action): PrintTargetByCategoryState => {
  switch (action.type) {
    case FETCH_PRINT_TARGET_BY_CATEGORY_SUCCESS: {
      const fetchResponse = convertFetchResponses(action.response)
      return {
        ...initialState,
        isFetched: true,
        categories: fetchResponse.categories,
        seatSets: fetchResponse.seatSets,
        printers: fetchResponse.printers,
        printerOptions: fetchResponse.printerOptions,
        selectedPrinterHolder: fetchResponse.selectedPrinterHolder
      }
    }
    case REPLACE_PRINTER_BY_CATEGORY_AND_SEAT_SET:
      return {
        ...state,
        selectedPrinterHolder:
          state.selectedPrinterHolder.replacePrinterByCategoryAndSeatSet(
            action.categoryId,
            action.seatSetId,
            action.printerIds
          ),
        isEditing: true
      }
    case CLEAR_PRINT_TARGET_BY_CATEGORY_STATE:
      return initialState
    case CLEAR_BATCH_SELECT_STATE:
      return {
        ...state,
        batchSelect: initialState.batchSelect
      }
    case REPLACE_BATCH_SELECT_PRINTERS:
      return {
        ...state,
        batchSelect: state.batchSelect.replacePrinterIds(action.printerIds)
      }
    case REPLACE_PRINTER_BY_BATCH_SELECT:
      return {
        ...state,
        selectedPrinterHolder:
          state.selectedPrinterHolder.replacePrinterByCategoriesAndSeatSet(
            state.batchSelect.getSelectedCategoryIds(),
            state.batchSelect.targetSeatSet.seatSetId,
            state.batchSelect.getSelectedPrinterIds()
          ),
        batchSelect: initialState.batchSelect,
        isEditing: true
      }
    case REPLACE_PRINT_TARGET_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        isEditing: false
      }
    case CHECK_BATCH_SELECT_CATEGORY:
      return {
        ...state,
        batchSelect: state.batchSelect.checkCategory(
          action.categoryId,
          action.isChecked
        )
      }
    case CHECK_BATCH_SELECT_ALL_CATEGORY:
      return {
        ...state,
        batchSelect: state.batchSelect.checkAllCategory(
          state.categories.map(category => category.categoryId),
          action.isChecked
        )
      }
    case SET_BATCH_SELECT_SEAT_SET: {
      const targetSeatSet = state.seatSets.find(
        seatSet => seatSet.seatSetId === action.seatSetId
      )
      return {
        ...state,
        batchSelect: new BatchSelect(targetSeatSet),
        isSeatSetNotFound: !targetSeatSet
      }
    }
    default:
      return state
  }
}

const convertFetchResponses = (responseMap: FetchResponses) => {
  const itemResource = responseMap.items.result || {}
  const items = new Items(itemResource.items)
  const categories = new Categories(
    itemResource.categories,
    items,
    itemResource.itemCategoryJunctions
  )

  const seatResource = responseMap.seats.result || {}
  const seatSets = [
    ...(seatResource.seatSets || []),
    {
      seatSetId: UndefinedId.SEAT_SET_ID,
      name: '注文エリア未設定',
      version: 0,
      displayOrder: 0
    }
  ].sort((a, b) => a.displayOrder - b.displayOrder)

  const printerResource = responseMap.printers.result || {}
  const printers = new Printers(printerResource.printers).push(
    new Printer({
      printerId: UndefinedId.PRINTER_ID,
      name: DisplayText.UNPRINT_LABEL
    })
  )

  // 本来ならComponent側でやるべき処理だが、パフォーマンス改善のためにマスタとしてStateにいれる
  const printerOptions = printers.printers
    .map(printer => ({
      id: printer.printerId,
      label: printer.name,
      excludeOthers: printer.printerId === UndefinedId.PRINTER_ID
    }))
    .toArray()

  const printerByCategorySeatSets =
    printerResource.printerByCategorySeatSets || []
  const printerByItemSeatSets = printerResource.printerByItemSeatSets || []
  const seatSetIds = seatSets.map(seatSet => seatSet.seatSetId)
  const printerIds = printers.printers
    .map(printer => printer.printerId)
    .toList()
  const selectedPrinterHolder = new SelectedPrinterHolder(
    categories,
    seatSetIds,
    printerIds,
    printerByCategorySeatSets,
    printerByItemSeatSets
  )
  return {
    categories: categories.getSimpleCategories(),
    seatSets: seatSets,
    printers: printers,
    printerOptions: printerOptions,
    selectedPrinterHolder: selectedPrinterHolder
  }
}
