import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

export const TitleColumn = styled(TableColumn)`
  width: 230px;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
`
export const ToggleColumn = styled(TableColumn)`
  width: 76px;
  font-size: 14px;
  margin-right: 24px;
  text-align: left;
`

export const SeatSetColumn = styled(TableColumn)`
  width: 315px;
  font-size: 14px;
  text-align: left;
  padding-left: 28px;
`
