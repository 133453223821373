import React from 'react'
import { useSelector } from 'react-redux'
import Footer from '~/src/components/organisms/Footer'
import { selectIsSubmittable } from './selectors'
type FooterProps = {
  onSubmitButtonClick: () => void
}
export const OrderItemMemoTemplateFooter = ({
  onSubmitButtonClick
}: FooterProps) => {
  const { isSubmittable } = useSelector(selectIsSubmittable)
  return (
    <Footer
      disableSubmitButton={!isSubmittable}
      noUseOptionButton={true}
      onSubmitButtonClick={onSubmitButtonClick}
    />
  )
}
