class CSVFileDownloader {
  async execute(name: string, data: Blob) {
    return new Promise<void>(resolve => {
      // @ts-ignore
      if (window.navigator && window.navigator.msSaveBlob) {
        // @ts-ignore
        window.navigator.msSaveBlob(data, name)
        resolve()
      } else {
        const downloadURL = URL.createObjectURL(data)
        const virtualLink = document.createElement('a')
        virtualLink.download = name!
        virtualLink.href = downloadURL
        virtualLink.click()
        resolve()
        setTimeout(() => {
          virtualLink.remove()
          URL.revokeObjectURL(downloadURL)
        }, 60000) // 60s 即座にURLを無効化するとユーザーの操作タイミングでは失敗してしまうため60sと長めに設定している。
      }
    })
  }
}

export default CSVFileDownloader
