import React from 'react'
import styled from 'styled-components'

import TableRow from '~/src/components/atoms/TableRow'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import OrderItemMemoTemplate from '~/src/redux/models/OrderItemMemoTemplate'
import Row from '../Row'
import { useDeleteButtonClick, useFocus, useInputChange } from './hooks'

const MEMO_NAME_PLACEHOLDER = 'お急ぎなど'

type MemoRowProps = {
  index: number
  memo: OrderItemMemoTemplate
  isLast: boolean
}

export const MemoRow = ({ index, memo, isLast }: MemoRowProps) => {
  const onInputChange = useInputChange()
  const onFocus = useFocus()
  const onDeleteButtonClick = useDeleteButtonClick()
  return (
    <MemoRowContainer>
      <Row
        isHandleColumnHidden={isLast}
        isDeleteColumnHidden={isLast}
        onDeleteButtonClick={() => {
          onDeleteButtonClick(index)
        }}
      >
        <ValidatableInput
          id={memo.orderItemMemoTemplateId}
          index={index}
          type="text"
          value={memo.getMemo()}
          placeholder={MEMO_NAME_PLACEHOLDER}
          onChange={e => onInputChange(index, e.target.value)}
          onFocus={() => onFocus(isLast)}
          error={isLast ? undefined : memo.validate()}
        />
      </Row>
    </MemoRowContainer>
  )
}

const MemoRowContainer = styled(TableRow)`
  padding: 15px 12px;
`
