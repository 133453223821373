export default {
  TITLE: 'エラー',
  NETWORK_ERROR:
    '通信エラーです。インターネット回線を確認し、再度実行してください。',
  UNDEFINED: '不明なエラーです。時間をおいて再度お試しください。',
  SERVICE_UNAVAILABLE: {
    TITLE: '現在システムメンテナンス中です',
    MESSAGE:
      'ただいま、システムメンテナンス中です。時間をおいて、再度お試しください。'
  }
}
