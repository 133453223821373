// Action types
export const FETCH_HANDY_INITIAL_DATA_SUCCEEDED =
  'oes/HandySetting/FETCH_SUCCESS'
export const FETCH_COMMON_INITIAL_DATA_SUCCEEDED =
  'oes/CommonSetting/FETCH_SUCCESS'
export const FETCH_INITIAL_DATA_FINISHED = 'oes/Setting/FETCH_FINISHED'
export const CHANGE_IS_SALES_DISPLAYED =
  'oes/HandySetting/CHANGE_IS_SALES_DISPLAYED'
export const CHANGE_TAX_DISPLAY_TYPE =
  'ors/HandySetting/CHANGE_TAX_DISPLAY_TYPE'
export const CHANGE_SHOULD_AUTO_APPLY_SETTINGS =
  'ors/CommonSetting/CHANGE_SHOULD_AUTO_APPLY_SETTINGS'
export const UPDATE_HANDY_SETTING_SUCCESS =
  'oes/HandySetting/UPDATE_SETTING_SUCCESS'
export const UPDATE_COMMON_SETTING_SUCCESS =
  'oes/CommonSetting/UPDATE_SETTING_SUCCESS'
export const CLEAR_STATE = 'oes/HandySetting/CLEAR_STATE'

export type HandySetting = {
  isSalesDisplayed: boolean
  taxDisplayTypeId: string
  isOtoshiEnabled: boolean
  isDiscountSurchargeOnVisitEnabled: boolean
  version: number
}

export type CommonSetting = {
  shouldAutoApplySettings: boolean
  version: number
}

export type SettingState = {
  isEdited: boolean
  handySetting: HandySetting
  commonSetting: CommonSetting
  isSplashShowing: boolean
}
