import React from 'react'
import styled from 'styled-components'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import { SelectItem } from '../HodaiSetting/SelectItem/SelectItem'
import Const from '~/src/const'

const { Color, Weight } = Const

export const Step2: React.VFC = () => (
  <Wrapper>
    <StepTitleArea>
      <StepBadge stepNum={2} />
      <StepTitle>放題プラン内容の設定</StepTitle>
    </StepTitleArea>
    <DescriptionArea>
      <Description>
        放題プランの内容を設定します。商品一覧より選んでください。
      </Description>
    </DescriptionArea>
    <SelectItem />
  </Wrapper>
)

const Wrapper = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
`

export const DescriptionArea = styled.div`
  padding-bottom: 24px;
`

export const Description = styled.div`
  padding: 16px 0 4px 0;
  line-height: 22px;
`

export const StepTitleArea = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

export const StepTitle = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: 18px;
  line-height: 27px;
  padding-left: 8px;
  font-weight: ${Weight.BOLD};
`
