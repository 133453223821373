import { combineReducers } from 'redux'
import auth from './modules/Auth/reducer'
import orderItemMemoTemplate from './modules/OrderItemMemoTemplate'
import printTargetByCategories from './modules/PrintTarget/PrintTargetByCategory'
import printTargetByItems from './modules/PrintTarget/PrintTargetByItem'
import printerSelectBoxes from './modules/PrintTarget/PrinterSelectBox'
import seatSet from './modules/SeatSet/SeatSet'
import apiError from './modules/ApiError'
import Forward from './modules/Forward'
import UI from './modules/UI'
import LoginError from './modules/LoginError/LoginError'
import registeredDevice from './modules/RegisteredDevice/RegisteredDevice'
import deviceCount from './modules/DeviceCount/DeviceCount'
import kitchenPosition from './modules/KitchenPositionSetting/KitchenPositionManagement'
import kitchenPositionTarget from './modules/KitchenPositionTarget/KitchenPositionTarget'
import variationGroup from './modules/VariationGroup/reducer'
import { setting } from '~/src/pages/Setting/reducer'
import { toppingList } from './modules/Topping/ToppingList/reducer'
import { toppingGroup } from './modules/Topping/ToppingGroup/reducer'
import { itemByToppingGroup } from './modules/Topping/ItemByToppingGroup/reducer'
import { optionSettingParent as optionSetting } from '~/src/pages/TargetServingTime/OptionSetting/reducers'
import { kmSettingApi } from '~/src/api/km/settings/reducers'
import { targetServingTimeSetting } from '~/src/pages/TargetServingTime/reducers'
import { hodaiTop } from '~/src/pages/Hodai/HodaiTop/reducers'
import { hodaiSetting } from '~/src/pages/Hodai/HodaiSetting/reducers'
import { courseTop } from '../pages/Course/CourseTop/reducers'
import { courseSetting } from '../pages/Course/CourseSetting/reducers'
import { itemsApi } from '../api/handy/items/reducers'
import { otoshiSetting } from '../pages/Otoshi/reducers'
import { handySettingApi } from '~/src/api/handy/settings/reducers'
import { discountSurchargeSetting } from '~/src/pages/DiscountSurchargeSetting/reducers'

const api = combineReducers({
  kmSetting: kmSettingApi,
  items: itemsApi,
  handySetting: handySettingApi
})

// @ts-ignore
const rootReducer = combineReducers({
  auth,
  orderItemMemoTemplate,
  printerSelectBoxes,
  printTargetByCategories,
  printTargetByItems,
  seatSet,
  apiError,
  Forward,
  UI,
  LoginError,
  registeredDevice,
  deviceCount,
  kitchenPosition,
  kitchenPositionTarget,
  variationGroup,
  targetServingTimeSetting,
  toppingList,
  toppingGroup,
  itemByToppingGroup,
  optionSetting,
  api,
  setting,
  hodaiTop,
  hodaiSetting,
  courseTop,
  courseSetting,
  otoshiSetting,
  discountSurchargeSetting
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
