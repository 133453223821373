import { Dispatch } from 'redux'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import { Response } from '~/src/api/types'
import {
  HandySettingApiRequest,
  HandySettingApiResponse
} from '~/src/api/handy/settings/types'
import {
  CommonSettingApiResponse,
  CommonSettingApiRequest
} from '~/src/api/common/settings/types'
import { handleError } from '~/src/redux/modules/ApiError'
import { actions } from '~/src/pages/Setting/actions'

const { API, Toast } = Const

class SettingInteractor {
  private dispatch: Dispatch
  private apiClient: ApiClientInterface

  constructor(dispatch: Dispatch, apiClient: ApiClientInterface = ApiClient) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  async fetchInitialData(): Promise<void> {
    this.dispatch(setLoading())
    await Promise.all([
      this.apiClient.get(API.GET_HANDY_SETTING),
      this.apiClient.get(API.GET_COMMON_SETTING)
    ])
      .then(
        async ([handyRes, commonRes]: [
          Response<HandySettingApiResponse>,
          Response<CommonSettingApiResponse>
        ]) => {
          this.dispatch(actions.fetchHandyInitialDataSucceeded(handyRes.data))
          this.dispatch(actions.fetchCommonInitialDataSucceeded(commonRes.data))
        }
      )
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => {
        this.dispatch(actions.fetchInitialDataFinished())
        this.dispatch(clearLoading())
      })
  }

  async postSetting(
    handyRequest: HandySettingApiRequest,
    commonRequest: CommonSettingApiRequest
  ): Promise<void> {
    this.dispatch(setLoading())
    await Promise.all([
      this.apiClient.post(API.POST_HANDY_SETTING, handyRequest),
      this.apiClient.post(API.POST_COMMON_SETTING, commonRequest)
    ])
      .then(
        async ([handyRes, commonRes]: [
          Response<HandySettingApiResponse>,
          Response<CommonSettingApiResponse>
        ]) => {
          this.dispatch(actions.updateHandySettingSuccess(handyRes.data))
          this.dispatch(actions.updateCommonSettingSuccess(commonRes.data))
          // @ts-ignore
          this.dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
          await this.fetchInitialData()
        }
      )
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  onChangeIsSalesDisplayed(isSalesDisplayed: boolean) {
    this.dispatch(actions.changeIsSalesDisplayed(isSalesDisplayed))
  }

  onChangeTaxDisplayType(taxDisplayTypeId: string) {
    this.dispatch(actions.changeTaxDisplayType(taxDisplayTypeId))
  }

  onChangeShouldApplySetting(shouldAutoApplySettings: boolean): void {
    this.dispatch(
      actions.changeShouldAutoApplySettings(shouldAutoApplySettings)
    )
  }
}

export { SettingInteractor }
