import React from 'react'
import styled from 'styled-components'

import Const from '~/src/const'

const { ZIndex, Color, Time } = Const

type Props = {
  isOpen: boolean
}

const Splash = (props: Props) => {
  const { isOpen } = props
  return <SplashComponent isOpen={isOpen} />
}

const SplashComponent = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${ZIndex.AIR_OES_SPLASH};
  background-color: ${Color.WHITE};

  ${props =>
    props.isOpen
      ? `
    visibility: visible;
  `
      : `
    visibility: hidden;
    opacity: 0;
    transition: all ${Time.SPLASH}ms ease-out;
  `}
`

export default Splash
