import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const'
const { Size } = Const

const baseColumn = styled(TableColumn)`
  text-align: left;
  padding: 0 5px;
`

export const KitchenPositionNameColumn = styled(baseColumn)`
  width: auto;
`

export const RadioButtonColumn = styled(baseColumn)`
  width: 110px;
  font-size: ${Size.FONT.BASE}px;
  vertical-align: middle;
  text-align: center;
`
export const KitchenPositionColumn = styled(baseColumn)`
  word-wrap: break-word;
`
export const SeatSetUsableColumn = styled(baseColumn)`
  width: 140px;
  text-align: center;
`

export const DeleteColumn = styled(baseColumn)`
  width: 60px;
  text-align: center;
  ${({ pointer }: { pointer: boolean }) =>
    pointer &&
    `
    cursor: pointer
  `}
`
