import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const/'

const { Size } = Const

export const HodaiNameColumn = styled(TableColumn)`
  width: 30%;
  word-wrap: break-word;
  text-align: left;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 130px;
  }
`
export const CategoryColumn = styled(TableColumn)`
  width: 26%;
  word-wrap: break-word;
  text-align: left;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
`
export const LastOrderColumn = styled(TableColumn)`
  width: 112px;
  text-align: right;
`
export const PriceColumn = styled(TableColumn)`
  width: 160px;
  white-space: pre-wrap;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 80px;
  }
`
export const ItemCountColumn = styled(TableColumn)`
  width: 110px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 80px;
  }
`
export const TrashColumn = styled(TableColumn)`
  width: 70px;
  text-align: center;
`
export const SpaceColumn = styled(TableColumn)`
  width: 39px;
  padding: 0 10px 0 0;
  text-align: center;
`
