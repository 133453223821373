import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { deleteSeatSet } from '~/src/redux/modules/SeatSet/SeatSet'
import { closeDeleteConfirmModal } from '~/src/redux/modules/UI'
import { setShouldForward } from '~/src/redux/modules/Forward'

export const useCloseDeleteConfirmModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(closeDeleteConfirmModal())
  }, [dispatch])
}

export const useDeleteSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(deleteSeatSet())
  }, [dispatch])
}

export const useCloseNotFoundErrorModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setShouldForward(true))
  }, [dispatch])
}
