import { Record, Map, Set, List } from 'immutable'
import Categories from '~/src/redux/models/Category/Categories'

const SelectedPrinterHolderRecord = new Record({
  selectedPrinterMap: Map(),
  itemSettingByCategorySet: Set()
})

export const UndefinedId = {
  SEAT_SET_ID: 'SELECTED_PRINTER_HOLDER.UNDEFINED.SEAT_SET_ID',
  PRINTER_ID: 'SELECTED_PRINTER_HOLDER.UNDEFINED.PRINTER_ID'
}

const VALIDATION_MESSAGE =
  '選択されていない項目があります。すべて選択してください。'
const KEY_SEPARATOR = '-'

export default class SelectedPrinterHolder extends SelectedPrinterHolderRecord {
  constructor(
    categories = new Categories(),
    originSeatSetIds = new List(),
    originPrinterIds = new List(),
    printerByCategorySeatSets = [],
    printerByItemSeatSets = []
  ) {
    super()
    const categoryIds = categories.getCategoryIds()

    const emptyMap = Map().withMutations(map => {
      categoryIds.forEach(categoryId => {
        originSeatSetIds.forEach(seatSetId => {
          const key = this.getKey(categoryId, seatSetId)
          map.set(key, [])
        })
      })
    })

    const selectedPrinterMap = emptyMap.withMutations(map => {
      printerByCategorySeatSets
        .filter(v => categories.hasCategory(v.categoryId))
        .forEach(v => {
          const seatSetId = v.seatSetId ? v.seatSetId : UndefinedId.SEAT_SET_ID
          const printerId = v.printerId ? v.printerId : UndefinedId.PRINTER_ID
          if (
            originSeatSetIds.indexOf(seatSetId) < 0 ||
            originPrinterIds.indexOf(printerId) < 0
          ) {
            return
          }
          const key = this.getKey(v.categoryId, seatSetId)
          const printerIds = map.get(key)
          map.set(key, printerIds.concat([printerId]))
        })
    })
    // カテゴリー毎の所属商品の印刷出し分け設定の有無を調べる
    const printerByItemSeatSetsSet = Set(
      printerByItemSeatSets.map(item => item.itemId)
    )
    const itemSettingByCategorySet = Set().withMutations(set => {
      categories.categories.forEach(category => {
        const hasItemSetting = category.items.items.some(item => {
          return printerByItemSeatSetsSet.includes(item.itemId)
        })
        if (hasItemSetting) {
          set.add(category.categoryId)
        }
      })
    })
    return this.set('selectedPrinterMap', selectedPrinterMap).set(
      'itemSettingByCategorySet',
      itemSettingByCategorySet
    )
  }

  replacePrinterByCategoryAndSeatSet(categoryId, seatSetId, printerIds) {
    const result = this.selectedPrinterMap.withMutations(map => {
      const key = this.getKey(categoryId, seatSetId)
      map.set(key, printerIds)
    })
    return this.set('selectedPrinterMap', result)
  }

  replacePrinterByCategoriesAndSeatSet(categoryIds, seatSetId, printerIds) {
    const result = this.selectedPrinterMap.withMutations(map => {
      categoryIds.forEach(categoryId => {
        const key = this.getKey(categoryId, seatSetId)
        map.set(key, printerIds)
      })
    })
    return this.set('selectedPrinterMap', result)
  }

  getSelectedPrinterIds(categoryId, seatSetId) {
    const key = this.getKey(categoryId, seatSetId)
    return this.selectedPrinterMap.get(key) || []
  }

  hasItemSettingByCategory(categoryId) {
    return !!this.itemSettingByCategorySet.get(categoryId)
  }

  list() {
    const result = []
    this.selectedPrinterMap.forEach((printerIds, key) => {
      const { categoryId, seatSetId } = this.getCategoryIdAndSeatSetId(key)
      printerIds.forEach(printerId => {
        result.push({
          categoryId: categoryId,
          seatSetId: seatSetId,
          printerId: printerId
        })
      })
    })
    return result
  }

  buildRequestJsonForReplace() {
    const printerByCategorySeatSets = this.list().map(
      printerByCategorySeatSet => {
        return {
          categoryId: printerByCategorySeatSet.categoryId,
          seatSetId:
            printerByCategorySeatSet.seatSetId === UndefinedId.SEAT_SET_ID
              ? undefined
              : printerByCategorySeatSet.seatSetId,
          printerId:
            printerByCategorySeatSet.printerId === UndefinedId.PRINTER_ID
              ? undefined
              : printerByCategorySeatSet.printerId
        }
      }
    )
    return {
      printerByCategorySeatSets: printerByCategorySeatSets
    }
  }

  getKey(categoryId, seatSetId) {
    return `${categoryId}${KEY_SEPARATOR}${seatSetId}`
  }

  getCategoryIdAndSeatSetId(key) {
    const array = key.split(KEY_SEPARATOR)
    return {
      categoryId: array[0],
      seatSetId: array[1]
    }
  }

  validate() {
    // IsFullyInputted?
    let notInputtedCounter = 0
    this.selectedPrinterMap.forEach((printerIds, key) => {
      if (printerIds.length < 1) {
        notInputtedCounter++
      }
    })
    if (notInputtedCounter === 0) {
      return undefined
    }
    return VALIDATION_MESSAGE
  }
}
