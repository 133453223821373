import { createAction } from 'typesafe-actions'
import {
  KmSettingApiDispatch,
  KmSettingApiRequest,
  KmSettingApiResponse
} from './types'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'

const { API, Toast } = Const

const CLEAR_STATE = 'oes/api/km/settings/CLEAR_STATE'
const FETCH_SUCCEEDED = 'oes/api/km/settings/FETCH_SUCCEEDED'
const FETCH_FAILED = 'oes/api/km/settings/FETCH_FAILED'
const SAVE_SUCCEEDED = 'oes/api/km/settings/SAVE_SUCCEEDED'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  fetchSucceeded: createAction(
    FETCH_SUCCEEDED,
    (response: KmSettingApiResponse) => ({
      kmSetting: { response: response, fetched: true }
    })
  )(),
  fetchFailed: createAction(FETCH_FAILED)(),
  saveSucceeded: createAction(
    SAVE_SUCCEEDED,
    (response: KmSettingApiResponse) => ({
      kmSetting: { response: response, fetched: true }
    })
  )()
}

export const fetchKmSetting =
  (apiClient: ApiClientInterface = ApiClient) =>
  (dispatch: KmSettingApiDispatch) => {
    dispatch(setLoading())
    apiClient
      .get(API.GET_KM_SETTING)
      .then(response => {
        dispatch(actions.fetchSucceeded(response.data.result))
      })
      .catch(error => {
        dispatch(handleError(error))
        dispatch(actions.fetchFailed())
      })
      .finally(() => dispatch(clearLoading()))
  }

export const saveKmSetting =
  (request: KmSettingApiRequest, apiClient: ApiClientInterface = ApiClient) =>
  async (dispatch: KmSettingApiDispatch) => {
    dispatch(setLoading())
    await apiClient
      .post(API.POST_KM_SETTING, request)
      .then(response => {
        dispatch(actions.saveSucceeded(response.data.result))
        dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }

export const types = {
  CLEAR_STATE,
  FETCH_SUCCEEDED,
  SAVE_SUCCEEDED
}
