import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SortableHandle } from 'react-sortable-hoc'
import TableRow from '~/src/components/atoms/TableRow'
import {
  ToppingGroupNameColumn,
  ToppingItemCountColumn,
  TargetItemCountColumn,
  IconColumn,
  HandleColumn
} from '../Column/Column'
import { ToppingListInteractor } from '~/src/interactors/Topping/ToppingList/ToppingListInteractor'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'
import { NoticeIconType } from '~/src/redux/modules/Topping/ToppingList/types'
import { Set } from 'immutable'

const { Page, Size } = Const

type Props = {
  toppingGroupId: string
  name: string
  toppingCount: number
  targetItemCount: number
  noticeIconTypeSet: Set<NoticeIconType>
  interactor: ToppingListInteractor
  hasTargetItem: boolean
  hasTopping: boolean
}

export const Row: React.VFC<Props> = props => {
  const {
    toppingGroupId,
    name,
    toppingCount,
    targetItemCount,
    noticeIconTypeSet,
    hasTargetItem,
    hasTopping,
    interactor
  } = props
  const clickTrashboxHandler = (e: any) => {
    e.preventDefault()
    interactor.clickTrashBox(toppingGroupId)
  }
  return (
    <Main>
      <DraggableColumn />
      <ToppingGroupNameColumn>{name}</ToppingGroupNameColumn>
      <ToppingItemCountColumn>
        <StyledLink to={Page.TOPPING.TOPPING_GROUP.EDIT(toppingGroupId)}>
          {noticeIconTypeSet.has('ITEM_COUNT_CAUTION') && (
            <ExclamationIcon color="orange" />
          )}
          {noticeIconTypeSet.has('TOPPING_WARNING') && (
            <ExclamationIcon color="red" />
          )}
          {hasTopping ? toppingCount : '未設定'}
        </StyledLink>
      </ToppingItemCountColumn>
      <TargetItemCountColumn>
        <StyledLink to={Page.TOPPING.ITEM_BY_TOPPING_GROUP(toppingGroupId)}>
          {noticeIconTypeSet.has('ITEM_BY_TOPPING_GROUP_WARNING') && (
            <ExclamationIcon color="red" />
          )}
          {noticeIconTypeSet.has('TARGET_COUNT_CAUTION') && (
            <ExclamationIcon color="orange" />
          )}
          {hasTargetItem ? targetItemCount : '未設定'}
        </StyledLink>
      </TargetItemCountColumn>
      <IconColumn clickHandler={clickTrashboxHandler}>
        <span>
          <DeleteIcon />
        </span>
      </IconColumn>
    </Main>
  )
}

const DraggableColumn = SortableHandle(() => (
  <HandleColumn>
    <SortIcon />
  </HandleColumn>
))

const StyledLink = styled(Link)`
  display: block;
  padding: 10px 0;
  font-size: ${Size.FONT.LARGE}px;
  vertical-align: middle;
`
const Main = styled(TableRow)`
  padding-right: 4px;
`
const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``
const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``
