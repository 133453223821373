import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  changeItemSeatSetSetting,
  setPrinterByItemSeatSetsEditing
} from '~/src/redux/modules/PrintTarget/PrintTargetByItem'
import { clearSelectedItemValuesByItemId } from '~/src/redux/modules/PrintTarget/PrinterSelectBox'

export const useToggle = (): React.ChangeEventHandler<HTMLInputElement> => {
  const dispatch = useDispatch()
  return useCallback(
    e => {
      const itemId = e.target.name
      const checked = e.target.checked
      if (!checked) {
        dispatch(clearSelectedItemValuesByItemId(itemId))
      }
      dispatch(setPrinterByItemSeatSetsEditing(true))
      dispatch(changeItemSeatSetSetting(itemId, checked))
    },
    [dispatch]
  )
}
