import * as React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Icon from '~/src/components/atoms/Icon'
import { useSelector } from 'react-redux'
import { selectIsLoadingOnboarding } from './selectors'
import { useOnClick } from './hooks'

const { Color, Size, Weight } = Const

// デザインはMateのBOを参考にしている
// https://ghe.misosiru.io/AirLab/airmate_client/blob/8fa6510e1f0496c574adeec4e58be56f73c1873a/src/components/common/Header.tsx#L517-L525
export const OnboardingButton: React.VFC = () => {
  const isLoading = useSelector(selectIsLoadingOnboarding)
  const onClick = useOnClick(isLoading)

  return (
    <GuideButton onClick={onClick} isLoading={isLoading}>
      <Icon name="tutorialIcon" />
      {isLoading ? '読み込み中･･･' : '利用ガイド'}
    </GuideButton>
  )
}

const GuideButton = styled.button<{ isLoading: boolean }>`
  padding: 0 16px;
  border: none;
  border-left: solid 1px #1982aa;
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.5) inset;
  display: flex;
  height: ${Size.HEADER_HEIGHT}px;
  color: ${Color.WHITE};
  background-color: ${Color.VIVID_BLUE};
  font-size: 15px;
  font-weight: ${Weight.BOLD};
  align-items: center;
  ${props => !props.isLoading && 'cursor: pointer;'}
  &:hover {
    background-color: #4ebfea;
  }
`
