import { Dispatch } from 'redux'
import actions from '~/src/redux/modules/Topping/ToppingList/actions'
import {
  openDeleteConfirmModal,
  closeDeleteConfirmModal,
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import Const from '~/src/const'
import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'
import { handleError } from '~/src/redux/modules/ApiError'
import { List } from 'immutable'
import { ToppingGroup } from '~/src/redux/models/Topping/ToppingGroup'

const { API, Toast } = Const

class ToppingListInteractor {
  private dispatch: Dispatch
  private apiClient: ApiClientInterface

  public constructor(
    dispatch: Dispatch,
    apiClient: ApiClientInterface = ApiClient
  ) {
    this.dispatch = dispatch
    this.apiClient = apiClient
  }

  clearState() {
    this.dispatch(actions.clearState())
  }

  async fetchInitialData() {
    this.dispatch(setLoading())
    await this.apiClient
      .get(API.GET_ITEMS)
      .then(getItemsResponse => {
        const itemsResponse: ApiResponse<ItemsApiResponse> =
          getItemsResponse.data
        this.dispatch(actions.fetchInitialDataSucceeded(itemsResponse))
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  async updateOrder(
    toppingGroupAndVersions: List<{ toppingGroupId: string; version: number }>
  ) {
    this.dispatch(setLoading())
    await this.apiClient
      .post(API.POST_UPDATE_TOPPING_LIST, {
        toppingGroups: toppingGroupAndVersions.toArray()
      })
      .then(async () => {
        this.dispatch(actions.postUpdateToppingGroupSucceeded())
        // @ts-ignore
        this.dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        await this.fetchInitialData()
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  async onDelete(toppingGroupId: string) {
    this.dispatch(closeDeleteConfirmModal())
    this.dispatch(setLoading())
    await this.apiClient
      .post(API.POST_DELETE_TOPPING_LIST, { toppingGroupId: toppingGroupId })
      .then(async () => {
        this.dispatch(actions.postDeleteToppingGroupSucceeded(toppingGroupId))
        // @ts-ignore
        this.dispatch(showAndBlurToast(Toast.REMOVED_MESSAGE))
        await this.fetchInitialData()
      })
      .catch(error => this.dispatch(handleError(error)))
      .finally(() => this.dispatch(clearLoading()))
  }

  public clickTrashBox(toppingGroupId: string) {
    this.dispatch(openDeleteConfirmModal())
    this.dispatch(actions.didTapTrashBox(toppingGroupId))
  }

  public onSort(params: { oldIndex: number; newIndex: number }) {
    this.dispatch(actions.didSortToppingGroup(params.oldIndex, params.newIndex))
  }

  public closeDeleteConfirmModalDispatcher() {
    this.dispatch(closeDeleteConfirmModal())
  }

  public beforeOptionButtonClick(toppingGroupsCount: number) {
    if (toppingGroupsCount >= ToppingGroup.MAX_AVAILABLE_COUNT) {
      this.dispatch(actions.openMaxToppingGroupModal())
      return false
    }
    return true
  }

  public closeMaxToppingGroupModal() {
    this.dispatch(actions.closeMaxToppingGroupModal())
  }
}

export { ToppingListInteractor }
