import React from 'react'
import styled from 'styled-components'

import Hint from '~/src/components/molecules/Hint'
import TableRow from '~/src/components/atoms/TableRow'

import {
  RowNumberColumn,
  DeviceNameColumn,
  DeviceNameEditColumn,
  DeviceIdColumn,
  DeviceModelColumn,
  AppVersionColumn,
  DeleteColumn
} from './Column'

import Const from '~/src/const/'

const { Color } = Const

export default () => {
  return (
    <HeaderRow>
      <RowNumberColumn />
      <DeviceIdColumn>
        端末識別番号
        <Hint balloonWidth={275}>
          {
            '端末識別番号は、ハンディアプリのホーム画面>設定>端末識別番号でご確認ください'
          }
        </Hint>
      </DeviceIdColumn>
      <DeviceNameColumn>端末名</DeviceNameColumn>
      <DeviceNameEditColumn />
      <DeviceModelColumn>端末情報</DeviceModelColumn>
      <AppVersionColumn>アプリバージョン</AppVersionColumn>
      <DeleteColumn>解除</DeleteColumn>
    </HeaderRow>
  )
}

const HeaderRow = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
