import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Const from '~/src/const/'
import Required from '~/src/components/atoms/Required'
import { SeatSetColumnWrapper } from '../SeatSetColumn/SeatSetColumn'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'

const { Color, Page } = Const

type SeatSetHeaderProps = {
  seatSet: SeatSet
  width: number
}

export const SeatSetHeader: React.VFC<SeatSetHeaderProps> = ({
  seatSet,
  width
}) => (
  <StyledSeatSetColumn width={width.toString()}>
    {seatSet.name} <Required name="required" />
    <StyledLink to={Page.PRINTER_TARGET.BATCH_SELECT(seatSet.seatSetId)}>
      一括選択
    </StyledLink>
  </StyledSeatSetColumn>
)

const StyledSeatSetColumn = styled(SeatSetColumnWrapper)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  height: 50px;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  float: right;
`
