export type DataType = 'string' | 'number'

export type ColumnType = {
  id:
    | 'itemId'
    | 'skuId'
    | 'categoryId'
    | 'itemName'
    | 'skuName1'
    | 'skuName2'
    | 'taxType'
    | 'consumptionTaxType'
    | 'price'
    | 'isDisplayed'
  order: number
  type: DataType
  required: boolean
  regExp?: RegExp
}

const CSVItemStructure: ColumnType[] = [
  {
    id: 'itemId',
    order: 0,
    type: 'string',
    required: true,
    regExp: /^\d+$/
  },
  {
    id: 'skuId',
    order: 1,
    type: 'string',
    required: false,
    regExp: /^\d+$/
  },
  {
    id: 'categoryId',
    order: 3,
    type: 'string',
    required: false,
    regExp: /^\d+$/
  },
  {
    id: 'itemName',
    order: 4,
    type: 'string',
    required: true
  },
  {
    id: 'skuName1',
    order: 7,
    type: 'string',
    required: false
  },
  {
    id: 'skuName2',
    order: 8,
    type: 'string',
    required: false
  },
  {
    id: 'taxType',
    order: 9,
    type: 'string',
    required: true,
    regExp: /^(内税|外税|非課税)$/
  },
  {
    id: 'consumptionTaxType',
    order: 10,
    type: 'string',
    required: true,
    regExp: /^(標準税率|軽減税率|注文時に選択|非課税)$/
  },
  {
    id: 'price',
    order: 14,
    type: 'number',
    required: true
  },
  {
    id: 'isDisplayed',
    order: 21,
    type: 'string',
    required: true,
    regExp: /^(表示|非表示)$/
  }
]

export default CSVItemStructure
