import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchKmSetting } from '~/src/api/km/settings/actions'
import { KmSettingApiDispatch } from '~/src/api/km/settings/types'

export const useFetch = () => {
  const dispatch: KmSettingApiDispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchKmSetting())
  }, [dispatch])
}
