import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import DismissModal from '~/src/components/organisms/Modal/DismissModal'

/**
 * whiteList: 指定したパスに対しては破棄モーダルを表示しない
 */
export default ({ enabled, whiteList = [] }) => (
  <NavigationPrompt
    when={(currentLocation, nextLocation) => {
      const nextPath = nextLocation ? nextLocation.pathname : ''
      return (
        enabled &&
        !whiteList.includes(nextPath) &&
        nextPath !== currentLocation.pathname
      )
    }}
  >
    {({ onConfirm, onCancel }) => (
      <DismissModal isOpen={true} onClose={onConfirm} onOK={onCancel} />
    )}
  </NavigationPrompt>
)
