import { createAction } from 'typesafe-actions'
import { DeleteTargetHodai } from './types'

const CLEAR_STATE = 'oes/Hodai/HodaiTop/CLEAR_STATE'
const OPEN_DELETE_MODAL = 'oes/Hodai/HodaiTop/OPEN_DELETE_MODAL'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  openDeleteModal: createAction(
    OPEN_DELETE_MODAL,
    (deleteTargetHodai: DeleteTargetHodai) => deleteTargetHodai
  )()
}

export const types = {
  CLEAR_STATE,
  OPEN_DELETE_MODAL
}
