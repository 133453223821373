import React from 'react'
import styled from 'styled-components'
import HeaderRow from './Row/HeaderRow'
import { Row, Item, SelectedItem } from './Row/Row'
import Const from '~/src/const'

const { UndefinedId } = Const

type Props = {
  items: Item[]
  selected: string
  onChange: (selectedItem: SelectedItem) => void
}

export const List: React.VFC<Props> = props => {
  const items = [
    {
      id: UndefinedId.ITEM_ID,
      name: '放題プランなし',
      hodaiId: UndefinedId.HODAI_ID,
      hodaiNum: 0,
      isDisplayed: false,
      isDeleted: false,
      isDisabled: false
    }
  ].concat(props.items)
  return (
    <Wrapper>
      <HeaderRow />
      {items.map(item => (
        <Row
          key={item.id}
          item={item}
          onChange={props.onChange}
          selected={props.selected}
        />
      ))}
    </Wrapper>
  )
}

// <> にしたかったが、AirKitのスタイルが当たってしまい余計なmarginが発生するため <div> にする
const Wrapper = styled.div``
