import React from 'react'

import useInteractors from '~/src/hooks/useInteractors'

import GettingStarted from './GettingStarted'

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const GettingStartedContiner = () => {
  return <GettingStarted {...useInteractor()} />
}

export default GettingStartedContiner
