import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { moveMemo } from '~/src/redux/modules/OrderItemMemoTemplate'

export const useSort = () => {
  const dispatch = useDispatch()
  return useCallback(
    (oldIndex: number, newIndex: number) => {
      dispatch(moveMemo(oldIndex, newIndex))
    },
    [dispatch]
  )
}
