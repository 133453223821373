import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearResources,
  fetchResources
} from '~/src/redux/modules/RegisteredDevice/RegisteredDevice'

export const useClearDevices = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearResources())
  }, [dispatch])
}

export const useFetchDevices = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchResources())
  }, [dispatch])
}
