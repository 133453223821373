import React from 'react'
import DeleteConfirmModal from '~/src/components/organisms/Modal/DeleteConfirmModal'
import { useSelector } from 'react-redux'
import { selectDeleteModalState } from './selectors'
import { useDeleteDevice, useClearDeleteTargetDevice } from './hooks'

export const DeleteModal = () => {
  const { isOpen, deviceName } = useSelector(selectDeleteModalState)
  const deleteDevice = useDeleteDevice()
  const clearDeleteTargetDevice = useClearDeleteTargetDevice()

  return (
    <DeleteConfirmModal
      isOpen={isOpen}
      headingMessage={`「${deviceName}」を解除します`}
      okText="OK"
      onDelete={deleteDevice}
      onCancel={clearDeleteTargetDevice}
    >
      再度登録するには、使用する端末のハンディアプリからログインしてください。
    </DeleteConfirmModal>
  )
}
