import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openDeleteConfirmModal } from '~/src/redux/modules/UI'
import { postSeatSet } from '~/src/redux/modules/SeatSet/SeatSet'

export const useOpenDeleteConfirmModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(openDeleteConfirmModal())
  }, [dispatch])
}

export const usePostSeatSet = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(postSeatSet())
  }, [dispatch])
}
