import { List } from 'immutable'
import { CSVData } from '~/src/util/CSVFileReader'
import CSVItem from './CSVItem'
import CSVSku from './CSVSku'
import CSVItemStructure, { ColumnType } from './CSVItemStructure'
import * as ConsumptionTaxType from '~/src/const/consumptionTaxType'

import Const from '~/src/const'
const { DisplayText } = Const

export type RowData = {
  itemId: string
  skuId?: string
  categoryId?: string
  itemName: string
  skuName1?: string
  skuName2?: string
  price: number
  taxType: '内税' | '外税' | '非課税'
  consumptionTaxType: '標準税率' | '軽減税率' | '注文時に選択' | '非課税'
  isDisplayed: string
  skus: RowData[]
  displayOrder: number
  rowData: string[]
}

/**
 * CSVData(string[][]) を CSVItem, CSVSkuのモデルに変換する
 */
class CSVItemReader {
  private static CSV_FILE_VERSION: string = 'v1000'

  private static HEADER_COUNT: number = 2

  private static COLUMN_COUNT: number = 26

  /**
   * 受け取った商品一括編集CSVデータの検査を行う
   * 検査項目は下記の通り。
   * ・ファイルバージョン
   * ・項目数
   * ・各カラムの検査
   * @param data
   */
  hasError(data: CSVData): boolean {
    if (data[0][0] !== CSVItemReader.CSV_FILE_VERSION) {
      return true
    }
    const { csvItems } = this.trimHeaderRow(data, CSVItemReader.HEADER_COUNT)

    let columnErrors: string[] = []

    csvItems.forEach(row => {
      if (row.length !== CSVItemReader.COLUMN_COUNT) {
        return true
      }

      CSVItemStructure.forEach(columnType => {
        const errors = this.validateColumn(row[columnType.order], columnType)
        columnErrors = columnErrors.concat(errors)
      })
    })

    return columnErrors.length > 0
  }

  /**
   * 項目タイプに応じて、下記について項目の検査を行う
   * ・必須・非必須
   * ・型チェック(string, number)
   * ・正規表現によるデータ形式
   * @param column
   * @param columnType
   */
  validateColumn(column: string | undefined, columnType: ColumnType): string[] {
    const errors: string[] = []
    if (columnType.required && !column) {
      errors.push(`必須項目がありません ${columnType.id}`)
    }
    if (!column) {
      return errors
    }
    if (columnType.type === 'number') {
      const num = parseInt(column)
      if (isNaN(num)) {
        errors.push(`数値ではありません ${columnType.id}`)
      }
    }
    if (columnType.regExp && !columnType.regExp.test(column)) {
      errors.push(`形式が不正です ${columnType.id}`)
    }
    return errors
  }

  /**
   * CSVData(string[][]) を CSVItem, CSVSkuのモデルに変換する
   * @param data
   */
  read(data: CSVData): { results: CSVItem[]; header: CSVData } {
    if (this.hasError(data)) {
      throw new Error(DisplayText.CSV_UPLOAD_ERROR)
    }
    // TODO: itemIdなしの新規商品追加パターンに対応していない
    const { csvItems, header } = this.trimHeaderRow(
      data,
      CSVItemReader.HEADER_COUNT
    )
    const itemMaps: any = {}
    csvItems.forEach((row, index) => {
      const tmpData: any = {}
      tmpData.displayOrder = index
      CSVItemStructure.forEach(columnType => {
        const columnData = this.loadColumn(row[columnType.order], columnType)
        if (columnData !== undefined) {
          tmpData[columnType.id] = columnData
        }
      })
      tmpData.rowData = row
      const rowData: RowData = tmpData as RowData
      const isSKU = rowData.skuId !== undefined
      if (isSKU) {
        const addedItem = itemMaps[rowData.itemId!]
        if (addedItem === undefined) {
          const tmpItem = {
            ...rowData,
            skus: [rowData]
          }
          itemMaps[rowData.itemId!] = tmpItem
        } else {
          addedItem.skus.push(rowData)
        }
      } else {
        itemMaps[rowData.itemId!] = rowData
      }
    })
    const results = Object.keys(itemMaps)
      .map((key: string) => itemMaps[key])
      .map((item: RowData) => this.convertRowDataToItem(item))
    return {
      results,
      header
    }
  }

  trimHeaderRow(
    data: CSVData,
    headerCount: number
  ): { header: CSVData; csvItems: CSVData } {
    return {
      header: data.slice(0, headerCount),
      csvItems: data.slice(headerCount)
    }
  }

  /**
   * 与えられた columnType.type に応じて、string または number に変換する
   * @param column
   * @param columnType
   */
  loadColumn(
    column: string | undefined,
    columnType: ColumnType
  ): string | number | undefined {
    if (!column || columnType.type === 'string') {
      return column
    }
    return parseInt(column)
  }

  convertRowDataToItem(rowData: RowData): CSVItem {
    const skus = List(rowData.skus || [])
      .map(sku => this.convertRowDataToSku(sku!))
      .toList()
    const categoryId = rowData.categoryId
      ? rowData.categoryId.length === 4
        ? rowData.categoryId
        : ('0000' + rowData.categoryId).slice(-4) // CSVによっては0埋めが外されたcategoryIdが来うる
      : undefined
    return new CSVItem({
      itemId: rowData.itemId,
      itemName: rowData.itemName,
      categoryId: categoryId,
      price: skus.isEmpty() ? rowData.price : undefined,
      taxType: rowData.taxType,
      consumptionTaxType: skus.isEmpty()
        ? ConsumptionTaxType.fromCsvId(rowData.consumptionTaxType)
        : undefined,
      isDisplayed: rowData.isDisplayed === '表示',
      skus: skus,
      displayOrder: rowData.displayOrder,
      rowData: rowData.rowData
    })
  }

  convertRowDataToSku(rowData: RowData): CSVSku {
    return new CSVSku({
      skuId: rowData.skuId!,
      skuName1: rowData.skuName1!,
      skuName2: rowData.skuName2,
      price: rowData.price,
      taxType: rowData.taxType,
      consumptionTaxType: ConsumptionTaxType.fromCsvId(
        rowData.consumptionTaxType
      ),
      isDisplayed: rowData.isDisplayed === '表示',
      rowData: rowData.rowData
    })
  }
}

export default CSVItemReader
