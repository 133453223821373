import styled from 'styled-components'
import Row from '~/src/components/atoms/TableRow'
import Column from '~/src/components/atoms/TableColumn'

import Const from '~/src/const'
const { Color, Size } = Const

const BaseRow = styled(Row)`
  padding: 12px 0;
`

export const TableRow = styled(BaseRow)<{ header?: boolean }>`
  ${props =>
    props.header &&
    `
    background-color: ${Color.VERY_LIGHT_GRAY_9};
    border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
    font-weight: bold;
  `}
`

const BaseColumn = styled(Column)`
  font-size: ${Size.FONT.BASE}px;
  padding: 0 12px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    padding: 0 6px;
  }
  text-align: left;
`

export const ItemNameColumn = styled(BaseColumn)`
  width: auto;
  text-align: left;
  word-wrap: break-word;
`

export const SettingMethodSelectColumn = styled(BaseColumn)`
  width: 148px;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 120px;
  }
`

export const NoticeSecondColumn = styled(BaseColumn)`
  width: 140px;
  text-align: right;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 120px;
  }
`

export const AlertSecondColumn = styled(BaseColumn)`
  width: 140px;
  text-align: right;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 120px;
  }
`

export const AutomaticSettingConfidenceColumn = styled(BaseColumn)`
  width: 134px;

  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 70px;
  }
`

export const CheckBoxColumn = styled(BaseColumn)`
  width: 50px;
`
