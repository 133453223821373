import React from 'react'
import Message from '~/src/components/atoms/Message'
import { selectError } from './selectors'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { InlineLink } from '~/src/components/atoms/InlineLink'

const { Page, URL } = Const

type Props = {
  itemId?: string
}

export const ITEM_COUNT_LIMIT = 25

export const Messages: React.VFC<Props> = props => {
  const {
    isDeletedCourse,
    hasDeletedCourseDetail,
    hasHodaiError,
    hasEmptyCategoryItem,
    hasSku,
    hasDeletedCategory,
    emptyCourseDetails,
    exceededItemCount
  } = useSelector(selectError(props.itemId))
  const warningMessageList = []
  if (exceededItemCount !== 0) {
    warningMessageList.push(
      'コースに登録できるのは' +
        ITEM_COUNT_LIMIT +
        '点までです。あと' +
        exceededItemCount +
        '点解除してください。'
    )
  }
  if (isDeletedCourse) {
    warningMessageList.push(
      '削除済みのコースがあります。削除済みのコースは編集できません。 解除してください。'
    )
  }
  if (hasSku) {
    warningMessageList.push(
      <>
        バリエーションが設定されているコースがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (hasDeletedCategory) {
    warningMessageList.push(
      <>
        カテゴリーが未設定のコースがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (hasDeletedCourseDetail) {
    warningMessageList.push(
      'コース内容に削除された商品が含まれています。解除してください。'
    )
  }
  if (hasEmptyCategoryItem) {
    warningMessageList.push(
      'コース内容にカテゴリーが未設定の商品が含まれています。解除してください。'
    )
  }
  if (hasHodaiError) {
    warningMessageList.push(
      <>
        コース内容に設定エラーがある放題プランが含まれています。
        <StyledLink to={Page.HODAI.LIST}>放題プランページ</StyledLink>
        で内容を確認してください。または選択する放題プランを変更してください。
      </>
    )
  }

  const cautionMessageList = []
  if (emptyCourseDetails) {
    cautionMessageList.push('コース内容が未設定のコースがあります。')
  }

  return (
    ((warningMessageList.length !== 0 || cautionMessageList.length !== 0) && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
        <Message type="caution" messageList={cautionMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const StyledLink = styled(Link)`
  vertical-align: middle;
`

const Wrapper = styled.div`
  padding-bottom: 16px;
`
