import styled from 'styled-components'
import Const from '~/src/const'

const { Color, Weight } = Const

export const Attention = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: 14px;
  line-height: 20px;
`

export const DescriptionArea = styled.div`
  padding-bottom: 24px;
`

export const Description = styled.div`
  padding: 16px 0 4px 0;
  line-height: 22px;
`

export const StepTitleArea = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

export const StepTitle = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: 18px;
  line-height: 27px;
  padding-left: 8px;
  font-weight: ${Weight.BOLD};
`
