import { AppState } from '~/src/redux/reducer'

export const selectSeatSetDetailListRow =
  (seatId: string) => (state: AppState) => {
    const { editSeatSet, seatSets, seatSetBySeats } = state.seatSet

    const isChecked = editSeatSet.seatIds.includes(seatId)

    // 他の注文エリアでテーブルが登録されていないかチェックする
    const seatSetBySeat = seatSetBySeats.find(item => item.seatId === seatId)
    const seatSet = seatSetBySeat
      ? seatSets.find(item => item.seatSetId === seatSetBySeat.seatSetId)
      : undefined
    const isDisabled = Boolean(seatSet)

    return {
      isChecked: isChecked,
      isDisabled: isDisabled,
      seatSet: seatSet,
      seatSets: state.seatSet.seatSets,
      seatSetBySeats: state.seatSet.seatSetBySeats
    }
  }
