import React from 'react'
import Message from '~/src/components/atoms/Message'
import Const from '~/src/const'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { Page } = Const

type Props = {
  hasHodaiError: boolean
}

export const Messages: React.VFC<Props> = props => {
  const warningMessageList = []
  if (props.hasHodaiError) {
    warningMessageList.push(
      <>
        設定エラーがある放題プランが含まれています。
        <StyledLink to={Page.HODAI.LIST}>放題プランページ</StyledLink>
        で内容を確認してください。または選択する放題プランを変更してください。
      </>
    )
  }

  return (
    (warningMessageList.length !== 0 && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const StyledLink = styled(Link)`
  vertical-align: middle;
`

const Wrapper = styled.div`
  padding-bottom: 16px;
`
