import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { HeaderRow } from './Row/HeaderRow'
import { Row } from './Row/Row'
import { selectKitchenPositionSettingList } from './selectors'

const { Size } = Const

export const List: React.VFC = () => {
  const { kitchenPositionList, listSize } = useSelector(
    selectKitchenPositionSettingList
  )

  return (
    <Wrapper>
      <HeaderRow />
      {kitchenPositionList.map((kitchenPosition, index) => (
        <Row
          isLast={kitchenPositionList.size - 1 === index}
          index={index}
          key={index}
          kitchenPosition={kitchenPosition}
        />
      ))}
      <CountText>
        ※調理場の上限数は10件です。(現在の調理場数：{listSize})
      </CountText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-size: ${Size.FONT.BASE}px;
`
const CountText = styled.p`
  margin: 0;
  padding: 16px 0;
`
