import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { hasLoginError } from '~/src/redux/modules/LoginError/LoginError'

export const useLoginError = () => {
  const dispatch = useDispatch()
  return useCallback(
    (url: string) => {
      dispatch(hasLoginError(url))
    },
    [dispatch]
  )
}
