import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import ToggleButton from '~/src/components/atoms/Toggle'
import { ExternalLink } from '~/src/components/atoms/ExternalLink'
import { useToggleDiscountSurchargeOnVisitEnabled } from './hooks'

// @ts-ignore
import discountSurchargeModal from '~/assets/images/DiscountSurchargeSetting/discount_surcharge_modal.png'

type Props = { isDiscountisDiscountSurchargeOnVisitEnabled: boolean }

export const Setting: React.VFC<Props> = props => {
  const handleToggleDiscountSurchargeOnVisitEnabled =
    useToggleDiscountSurchargeOnVisitEnabled()

  return (
    <>
      <CautionaryStatement>
        ※割引・割増の自動表示について、詳しくは
        <ExternalLink url={Const.URL.DISCOUNT_SURCHARGE_FAQ}>
          来店時に割引・割増を表示させる設定方法
        </ExternalLink>
        をご覧ください。
      </CautionaryStatement>
      <SubTitle>利用設定</SubTitle>
      <Wrapper>
        <div>
          <Detail>
            <div>
              割引・割増
              <Text>来店処理の際に割引・割増設定画面が表示されます。</Text>
            </div>
            <Toggle>
              <ToggleButton
                onToggle={handleToggleDiscountSurchargeOnVisitEnabled}
                checked={props.isDiscountisDiscountSurchargeOnVisitEnabled}
                name="discount-surcharge"
                disabled={false}
              />
            </Toggle>
          </Detail>
        </div>
        <ImageWrapper>
          <Image src={discountSurchargeModal} />
        </ImageWrapper>
      </Wrapper>
    </>
  )
}

const { Color, Size } = Const

const SubTitle = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  padding-bottom: 14px;
`

const Wrapper = styled.div`
  display: flex;
  padding-top: 14px;
`

const CautionaryStatement = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
  padding-bottom: 24px;
`
const Detail = styled.div`
  display: flex;
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.LARGE}px;
  padding-left: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const Text = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.SMALL}px;
  padding-top: 6px;
`

const Toggle = styled.div`
  margin-left: auto;
`

const ImageWrapper = styled.div`
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
  padding-left: 52px;
  display: block;
`

const Image = styled.img`
  max-width: 256px;
`
