import { Record, List } from 'immutable'
import Items from '~/src/redux/models/Item/Items'
import { SimpleItem } from '~/src/redux/models/Item/Item'

// IE 対応用の軽量モデル
export type SimpleCategory = {
  categoryId: string
  categoryName: string
  items: List<SimpleItem>
}

// IE 対応用の中量級モデル
export type SimpleCategoryForTopping = {
  categoryId: string
  categoryName: string
  isDisplayed: boolean
  itemIds: List<string>
}

interface CategoryProps {
  categoryId: string
  categoryName: string
  categoryNameForInput: string
  colorCode: string
  deletedAt?: number
  displayOrder: number
  isDisplayed: boolean
  items: Items
}

const CategoryRecord = Record<CategoryProps>({
  categoryId: '',
  categoryName: '',
  categoryNameForInput: '',
  colorCode: '',
  deletedAt: undefined,
  displayOrder: 0,
  isDisplayed: true,
  items: new Items()
})

export default class Category extends CategoryRecord implements CategoryProps {
  constructor(
    category: any = {},
    items = new Items(),
    itemCategoryJunctions: any[] = []
  ) {
    super(category)
    // TODO: itemCategoryJunctionsのmodelを作り、map等を用いて計算コストを下げる。
    const itemListModel = new Items(
      List(itemCategoryJunctions)
        .filter(icj => this.categoryId === icj.categoryId)
        .map(icj => items.getItem(icj.itemId))
        .sort((a, b) => a!.displayOrder - b!.displayOrder)
        .toArray()
    )

    return this.set('isDisplayed', !!category.isDisplayed).set(
      'items',
      itemListModel
    )
  }

  // 非表示設定を考慮した表示用のカテゴリー名を返す
  // ex. カテゴリー名 (非表示)
  getDisplayCategoryName() {
    return this.categoryName + (this.isDisplayed ? '' : ' (非表示)')
  }

  hasItem(itemId: string) {
    return !!this.items.items.get(itemId)
  }

  // 性能懸念のある画面用の軽量モデル
  getSimpleCategory(): SimpleCategory {
    return {
      categoryId: this.categoryId,
      categoryName: this.getDisplayCategoryName(),
      items: List()
    }
  }

  // 性能懸念のある画面用の軽量モデル
  getSimpleCategoryWithItems(): SimpleCategory {
    return {
      categoryId: this.categoryId,
      categoryName: this.getDisplayCategoryName(),
      items: this.items.getSimpleItems()
    }
  }

  // IE 対応用の中量級モデル
  getSimpleCategoryForTopping(): SimpleCategoryForTopping {
    return {
      categoryId: this.categoryId,
      categoryName: this.categoryName,
      isDisplayed: this.isDisplayed,
      itemIds: this.items.items
        .valueSeq()
        .toList()
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map(item => item.itemId)
    }
  }
}
