import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox as AirKitCheckbox } from '@air-kit/air-kit'
import Accordion, { DownArrowIcon } from '~/src/components/atoms/Accordion'
import {
  CategoryRow as TableRow,
  CheckboxColumn,
  NameColumn,
  TrashColumn
} from './../Common/TableParts'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import ItemRow from './ItemRow'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'

type CategoryPops = {
  category?: SimpleCategory
  isSomeSelected: boolean
  isAllSelected: boolean
  items: CSVItem[]
  interactor: VariationGroupInteractor
}

const CategoryRow: React.FC<CategoryPops> = props => {
  const { category, isSomeSelected, isAllSelected, items, interactor } = props

  const onSelectCb = useCallback(() => {
    if (!isAllSelected) {
      interactor.selectItems(items.map(item => item.itemId))
    } else {
      interactor.deselectItems(items.map(item => item.itemId))
    }
  }, [isAllSelected, interactor, items])

  return (
    <Accordion
      defaultValue={false}
      Parent={props => (
        <TableRow>
          <CheckboxColumn>
            <Checkbox
              isSomeSelected={isSomeSelected && !isAllSelected}
              isChecked={isAllSelected}
              onChange={onSelectCb}
            />
          </CheckboxColumn>
          <NameColumn category>
            {category ? category.categoryName : '未設定'}
          </NameColumn>
          <TrashColumn>
            <DownArrowIcon isOpened={props.isOpened} />
          </TrashColumn>
        </TableRow>
      )}
    >
      {items.map(item => (
        <ItemRow key={item.itemId} itemId={item.itemId} />
      ))}
    </Accordion>
  )
}

const Checkbox = styled(AirKitCheckbox)`
  > span {
    display: none;
  }
`

export default React.memo(CategoryRow)
