import React, { FC, useCallback } from 'react'
import Const from '~/src/const'
import styled from 'styled-components'

import Heading from '~/src/components/atoms/Heading'
// @ts-ignore
import RegiItemUploadDoneScreen from '~/assets/images/regi_item_upload_screen_done.png'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationGroup from '~/src/redux/models/CSVItem/VariationGroup'
import { CSVData } from '~/src/util/CSVFileWriter'
import { InlineLink } from '~/src/components/atoms/InlineLink'

import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

const { Size, Color, URL } = Const
const { H3 } = Heading

type Props = {
  interactor: VariationGroupInteractor
  filename: string
  header: CSVData
  items: CSVItem[]
  variationGroups: VariationGroup[]
}

const Discription: FC<Props> = props => {
  const { interactor, filename, header, items, variationGroups } = props

  const onReDownloadClick = useCallback(() => {
    interactor.downloadFile(filename, header, items, variationGroups)
  }, [filename, header, interactor, items, variationGroups])

  return (
    <Wrapper>
      <Section>
        <StyledH3>商品一括編集用ファイル(CSV)をダウンロードしました</StyledH3>
        <DescriptionContainer>
          <FileDetail>
            <FileDetailKey>ファイル名</FileDetailKey>
            {props.filename && (
              <FileDetailValue>{props.filename}</FileDetailValue>
            )}
          </FileDetail>
          <FileDetail>
            <FileDetailKey>商品数</FileDetailKey>
            {props.items && (
              <FileDetailValue>{`${props.items.length}件`}</FileDetailValue>
            )}
          </FileDetail>
          <DescriptionElement>
            ダウンロードができない場合、
            <Redownload onClick={onReDownloadClick}>こちら</Redownload>
            をクリックして再度ダウンロードしてください。
          </DescriptionElement>
        </DescriptionContainer>
      </Section>
      <Section>
        <StyledH3>
          バリエーション一括設定はまだ終了していません。Airレジにアップロードしてください。
        </StyledH3>
        <DescriptionContainer>
          <DescriptionElement>
            <InlineLink href={URL.REGI_BULK_ITEM} target="_blank">
              Airレジのファイルで一括編集ページ
            </InlineLink>
            で新しい商品一括編集用ファイル(CSV)をアップロードし、完了です。
            バリエーション一括設定を終了する場合は、サイドメニューをクリックなど、ページを移動してください。
          </DescriptionElement>
          <ImageContainer>
            <ImageStyle src={RegiItemUploadDoneScreen} />
          </ImageContainer>
        </DescriptionContainer>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Section = styled.div`
  padding-bottom: 16px;
`

const FileDetail = styled.div`
  display: flex;
  padding-bottom: 8px;
`

const FileDetailKey = styled.div`
  flex: 0 0 120px;
`

const FileDetailValue = styled.div``

const DescriptionElement = styled.div`
  font-size: ${Size.FONT.LARGE}px;
  line-height: 22px;
  color: ${Color.DARK_GRAY};
  padding-bottom: 8px;
`

const DescriptionContainer = styled.div``

const Redownload = styled.a`
  cursor: pointer;
`

const StyledH3 = styled(H3)`
  font-size: ${Size.FONT.HEADER.H3}px;
  padding-bottom: 16px;
`

const ImageContainer = styled.div``

const ImageStyle = styled.img`
  width: 100%;
  border: 1px solid ${Color.LIGHT_GRAY_4};
`

export default Discription
