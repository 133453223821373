import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const/'
import {
  NoHandleColumn,
  ItemNameColumn,
  TrashBoxColumn
} from '../Column/Column'
import TableRow from '~/src/components/atoms/TableRow'

const { Color } = Const

type Props = {
  onClickAllRelease: () => void
}

export const Header: React.VFC<Props> = props => {
  const onClickAllRelease = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    props.onClickAllRelease()
  }

  return (
    <EditableHeader>
      <NoHandleColumn />
      <ItemNameColumn>商品</ItemNameColumn>
      <StyledRightColumn>
        <AllReleaseLink onClick={e => onClickAllRelease(e)}>
          すべて解除
        </AllReleaseLink>
      </StyledRightColumn>
    </EditableHeader>
  )
}

const AllReleaseLink = styled.a`
  cursor: pointer;
`

const EditableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`

const StyledRightColumn = styled(TrashBoxColumn)`
  width: 100px;
  padding-right: 16px;
`
