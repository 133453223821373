import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Hint from '~/src/components/molecules/Hint'

export type Breadcrumb = {
  title: string
  page?: string
  hint?: string
}

type Props = {
  breadcrumbs: Breadcrumb[]
}

const Breadcrumbs = (props: Props) => (
  <BreadcrumbsArea>
    {props.breadcrumbs.map((item, i) => (
      <BreadcrumbsItem key={`breadcrumb-${i}`}>
        {item.page ? <Link to={item.page}>{item.title}</Link> : item.title}
        {item.hint && (
          <StyledHint
            position={i === 0 ? 'bottom-right' : 'bottom-left'}
            balloonWidth={320}
          >
            {item.hint}
          </StyledHint>
        )}
      </BreadcrumbsItem>
    ))}
  </BreadcrumbsArea>
)

const BreadcrumbsArea = styled.ul`
  margin: 0;
  padding-left: 0;
  li::after {
    content: '>';
    padding: 0 1em 0;
  }
  li:last-child::after {
    content: '';
    padding: 0;
  }
`

const BreadcrumbsItem = styled.li`
  display: inline;
  list-style: none;
`

const StyledHint = styled(Hint)`
  vertical-align: top;
`

export default Breadcrumbs
