import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { checkBatchSelectAllCategory } from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useCheckAllCategory = (isChecked: boolean) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(checkBatchSelectAllCategory(isChecked))
  }, [dispatch, isChecked])
}
