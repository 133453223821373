export default {
  MODAL: {
    SUB_CONTENT_ANIMATION: 2000
  },
  FREE_WORD_UPDATE_DEBOUNCE_DURATION: 3000,
  FOWARD_WAIT: 1500,
  SPLASH: 300,
  SIDE_MENU: 300,
  ACCORDION: 300,
  LOADING: 300
}
