import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationGroup, {
  VariationGroupEditor
} from '~/src/redux/models/CSVItem/VariationGroup'
import { CSVData } from '~/src/util/CSVFileReader'
import { SimpleCategory } from '../../models/Category/Category'

export const CLEAR_STATE = 'oes/VariationGroup/CLEAR_STATE'
export const SELECT_ITEMS = 'oes/VariationGroup/SELECT_ITEMS'
export const DESELECT_ITEMS = 'oes/VariationGroup/DESELECT_ITEMS'
export const OPEN_VARIATION_GROUP_MODAL =
  'oes/VariationGroup/OPEN_VARIATION_GROUP_MODAL'
export const CLOSE_VARIATION_GROUP_MODAL =
  'oes/VariationGroup/CLOSE_VARIATION_GROUP_MODAL'
export const OPEN_VARIATION_GROUP_EDITOR =
  'oes/VariationGroup/OPEN_VARIATION_GROUP_EDITOR'
export const APPLY_VARIATION_GROUP_EDITOR =
  'oes/VariationGroup/APPLY_VARIATION_GROUP_EDITOR'
export const CANCEL_VARIATION_GROUP_EDITOR =
  'oes/VariationGroup/CANCEL_VARIATION_GROUP_EDITOR'
export const ADD_NEW_VARIATION_ON_EDITOR =
  'oes/VariationGroup/ADD_NEW_VARIATION_ON_EDITOR'
export const UPDATE_VARIATION_ON_EDITOR =
  'oes/VariationGroup/UPDATE_VARIATION_ON_EDITOR'
export const SORT_VARIATION_ON_EDITOR =
  'oes/VariationGroup/SORT_VARIATION_ON_EDITOR'
export const DELETE_VARIATION_ON_EDITOR =
  'oes/VariationGroup/DELETE_VARIATION_ON_EDITOR'
export const SET_DISCONNECT_VARIATION_GROUP =
  'oes/VariationGroup/SET_DISCONNECT_VARIATION_GROUP'
export const CLEAR_DISCONNECT_VARIATION_GROUP =
  'oes/VariationGroup/CLEAR_DISCONNECT_VARIATION_GROUP'
export const DISCONNECT_VARIATION_GROUP =
  'oes/VariationGroup/DISCONNECT_VARIATION_GROUP'
export const UPDATE_VARIATION_RETOUCH =
  'oes/VariationGroup/UPDATE_VARIATION_RETOUCH'
export const FETCH_CATEGORIES_SUCCEEDED =
  'oes/VariationGroup/FETCH_CATEGORIES_SUCCEEDED'
export const FILE_LOAD_SUCCEEDED = 'oes/VariationGroup/FILE_LOAD_SUCCEEDED'
export const FILE_LOAD_FAILED = 'oes/VariationGroup/FILE_LOAD_FAILED'
export const FILE_DOWNLOAD_SUCCEEDED =
  'oes/VariationGroup/FILE_DOWNLOAD_SUCCEEDED'

export type VariationGroupState = {
  isDownloaded: boolean
  items: CSVItem[]
  categories: SimpleCategory[]
  selectedItemIds: Set<string>
  disconnectTargetItemId?: string
  variationGroups: VariationGroup[]
  editor?: VariationGroupEditor
  showingItem?: CSVItem
  header?: CSVData
  file?: File
  filename?: string
  fileErrorMessage?: string
}
