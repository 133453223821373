// UI 系モジュール
// 複数コンポーネントから操作する必要がある UI に関するデータの処理

// Action Types
const CLEAR_UI_STATE = 'oes/ui/CLEAR_UI_STATE'

const OPEN_LOGOUT_MODAL = 'oes/ui/OPEN_LOGOUT_MODAL'
const CLOSE_LOGOUT_MODAL = 'oes/ui/CLOSE_LOGOUT_MODAL'
const OPEN_DELETE_CONFIRM_MODAL = 'oes/ui/OPEN_DELETE_CONFIRM_MODAL'
const CLOSE_DELETE_CONFIRM_MODAL = 'oes/ui/CLOSE_DELETE_CONFIRM_MODAL'

const OPEN_VALIDATION_MODAL = 'oes/ui/OPEN_VALIDATION_MODAL'
const CLOSE_VALIDATION_MODAL = 'oes/ui/CLOSE_VALIDATION_MODAL'

const SET_LOADING = 'oes/ui/SET_LOADING'
const CLEAR_LOADING = 'oes/ui/CLEAR_LOADING'

const OPEN_RELOAD_CONFIRM = 'oes/ui/OPEN_RELOAD_CONFIRM'

const SHOW_TOAST = 'oes/ui/SHOW_TOAST'
const CLEAN_TOAST = 'oes/ui/CLEAN_TOAST'

const OPEN_SPLASH = 'oes/ui/OPEN_SPLASH'
const CLOSE_SPLASH = 'oes/ui/CLOSE_SPLASH'

const SHOW_ONBOARDING_BUTTON = 'oes/ui/SHOW_ONBOARDING_BUTTON'

// Actions

/**
 * Steteを初期値に変更する
 */
export const clearUiState = () => ({ type: CLEAR_UI_STATE })

/**
 * ログアウトモーダルを開く
 */
export const openLogoutModal = () => ({ type: OPEN_LOGOUT_MODAL })

/**
 * ログアウトモーダルを閉じる
 */
export const closeLogoutModal = () => ({ type: CLOSE_LOGOUT_MODAL })

/**
 * 削除確認モーダルを開く
 */
export const openDeleteConfirmModal = () => ({
  type: OPEN_DELETE_CONFIRM_MODAL
})

/**
 * 削除確認モーダルを閉じる
 */
export const closeDeleteConfirmModal = () => ({
  type: CLOSE_DELETE_CONFIRM_MODAL
})

/*
 * バリデーションエラーモーダルを開く
 */
export const openValidationErrorModal = () => ({ type: OPEN_VALIDATION_MODAL })

/**
 * バリデーションエラーモーダルを閉じる
 */
export const closeValidationErrorModal = () => ({
  type: CLOSE_VALIDATION_MODAL
})

/**
 * ローディングインジケーターを表示する
 */
export const setLoading = () => ({ type: SET_LOADING })

/**
 * ローディングインジケーターを消す
 */
export const clearLoading = () => ({ type: CLEAR_LOADING })

/**
 * リロード用確認モーダルを開く
 */
export const openReloadConfirmModal = () => ({ type: OPEN_RELOAD_CONFIRM })

/**
 * successトーストを描画する
 */
const showToast = message => ({
  type: SHOW_TOAST,
  message: message
})

/**
 * successトーストの描画を止める
 */
const clearToast = () => ({ type: CLEAN_TOAST })

/**
 * スプラッシュ画面を描画する
 */
export const openSplash = () => ({ type: OPEN_SPLASH })

/**
 * スプラッシュ画面の描画を止める
 */
export const closeSplash = () => ({ type: CLOSE_SPLASH })

/**
 * オンボーディングのボタンを表示する
 */
export const showOnboardingButton = () => ({ type: SHOW_ONBOARDING_BUTTON })

// Initial state
const initialState = {
  isReloadConfirmModalOpen: false,
  isLogoutModalOpen: false,
  isDeleteConfirmModalOpen: false,
  loader: {
    isLoading: false
  },
  toast: {
    isShowToast: false,
    message: undefined
  },
  splash: {
    isOpen: true
  },
  validationErrorModal: {
    isOpen: false
  },
  isLoadingOnboarding: true
}

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_UI_STATE: {
      return {
        ...initialState,
        splash: {
          isOpen: false
        },
        isLoadingOnboarding: false
      }
    }
    case OPEN_LOGOUT_MODAL: {
      return { ...state, isLogoutModalOpen: true }
    }
    case CLOSE_LOGOUT_MODAL: {
      return { ...state, isLogoutModalOpen: false }
    }
    case OPEN_DELETE_CONFIRM_MODAL: {
      return { ...state, isDeleteConfirmModalOpen: true }
    }
    case CLOSE_DELETE_CONFIRM_MODAL: {
      return { ...state, isDeleteConfirmModalOpen: false }
    }
    case OPEN_VALIDATION_MODAL: {
      return {
        ...state,
        validationErrorModal: { ...state.validationErrorModal, isOpen: true }
      }
    }
    case CLOSE_VALIDATION_MODAL: {
      return {
        ...state,
        validationErrorModal: { ...state.validationErrorModal, isOpen: false }
      }
    }
    case SET_LOADING: {
      return { ...state, loader: { ...state.loader, isLoading: true } }
    }
    case CLEAR_LOADING: {
      return { ...state, loader: { ...state.loader, isLoading: false } }
    }
    case OPEN_RELOAD_CONFIRM: {
      return { ...state, isReloadConfirmModalOpen: true }
    }
    case SHOW_TOAST: {
      return {
        ...state,
        toast: { ...state.loader, isShowToast: true, message: action.message }
      }
    }
    case CLEAN_TOAST: {
      return {
        ...state,
        toast: { ...state.loader, isShowToast: false, message: undefined }
      }
    }
    case OPEN_SPLASH: {
      return { ...state, splash: { ...state.splash, isOpen: true } }
    }
    case CLOSE_SPLASH: {
      return { ...state, splash: { ...state.splash, isOpen: false } }
    }
    case SHOW_ONBOARDING_BUTTON: {
      return { ...state, isLoadingOnboarding: false }
    }
    default:
      return state
  }
}

/**
 * トーストを出して閉じる公開用のfunction
 **/
export const showAndBlurToast = message => dispatch => {
  dispatch(showToast(message))
  setTimeout(() => dispatch(clearToast()), 1500)
}
