import React from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import Discrption from './Discrption'
import { AppState } from '~/src/redux/reducer'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    count: state.items.length
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const DiscrptionContainer = () => {
  return <Discrption {...useVariationGroup()} />
}

export default DiscrptionContainer
