import { AppState } from '~/src/redux/reducer'
import { checkCourse, CourseError } from '~/src/util/ErrorChecker'

export const selectError = (itemId: string) => (state: AppState) => {
  const courseErrors: CourseError = checkCourse(state.api.items.response)
  const itemIdsByCourseDetails = state.api.items.response.courses
    ?.find(course => course.itemId === itemId)
    ?.courseDetails?.map(detail => detail.itemId)
  const hodaiItemIdSet = new Set(
    state.api.items.response.hodais?.map(hodai => hodai.itemId)
  )
  return {
    hasDeletedCourseDetail: courseErrors.itemIdsHaveDeletedCourseDetail
      .filter(e => !hodaiItemIdSet.has(e))
      .some(e => itemIdsByCourseDetails?.some(element => e === element)),
    hasEmptyCategoryItem: courseErrors.itemIdsHaveEmptyCategoryItem.some(e =>
      itemIdsByCourseDetails?.some(element => e === element)
    ),
    hasDeletedCategory: courseErrors.itemIdsHaveEmptyCategory.some(
      e => e === itemId
    )
  }
}
