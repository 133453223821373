import React from 'react'
import styled from 'styled-components'

import HeaderRow from './Row/HeaderRow'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { MemoRow } from './Row/MemoRow'
import OrderItemMemoTemplate from '~/src/redux/models/OrderItemMemoTemplate'
import { useSort } from './hooks'
import { useSelector } from 'react-redux'
import { selectMemos } from './selectors'
import OrderItemMemoTemplates from '~/src/redux/models/OrderItemMemoTemplates'

export const List = () => {
  const onSort = useSort()
  const { memos } = useSelector(selectMemos)
  const memoCount = memos.size - 1

  const id = 'order-item-memo-template-scrollable'
  const element = document.getElementById(id)
  return (
    <div id={id}>
      <HeaderRow />
      <SortableRows
        memos={memos}
        onSortEnd={params => onSort(params.oldIndex, params.newIndex)}
        useDragHandle={true}
        lockAxis="y"
        getContainer={element ? () => element : undefined}
      />
      <MemoCountText>
        ※注文メモの上限数は10件です。(現在の注文メモ数：{memoCount})
      </MemoCountText>
    </div>
  )
}

type SortableRowProps = {
  _index: number
  memo: OrderItemMemoTemplate
  isLast: boolean
}

const SortableRow = SortableElement(
  ({ _index, memo, isLast }: SortableRowProps) => {
    return <MemoRow index={_index} memo={memo} isLast={isLast} />
  }
)

type SortableRowsProps = {
  memos: OrderItemMemoTemplates
}

const SortableRows = SortableContainer(({ memos }: SortableRowsProps) => {
  return (
    <div>
      {memos?.map((memo, index: number) => {
        const isLast = index === memos.size - 1
        return (
          <SortableRow
            key={index}
            index={index}
            _index={index} // indexは予約されている
            memo={memo}
            isLast={isLast}
            disabled={isLast} // ソート不可にする
          />
        )
      })}
    </div>
  )
})
const MemoCountText = styled.p`
  margin: 0;
  padding: 16px 0;
`
