import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Const from '~/src/const'
import useInteractors from '~/src/hooks/useInteractors'
import Icon from '~/src/components/atoms/Icon'
import PageTemplate from '~/src/components/templates/PageTemplate'
import { Modal } from './Modal'
import { Footer } from './Footer'
import { ErrorMessages } from './ErrorMessages'
import { NameInput } from './NameInput'
import { VerticalSpacer } from './Common/Spacer'
import { ItemList } from './ItemList/ItemList'
import { SelectedList } from './SelectedList/SelectedList'

const { Page, Color } = Const

export const ToppingGroup: React.VFC = () => {
  const { toppingGroupId } = useParams<{ toppingGroupId?: string }>() // 新規作成なら undefined

  const interactor = useInteractors().toppingGroupInteractor
  useEffect(() => {
    interactor.clearState()
    interactor.fetchInitialData(toppingGroupId)
    return () => {
      interactor.clearState()
    }
  }, [interactor, toppingGroupId])

  const breadcrumbs = [
    { title: 'トッピング', page: Page.TOPPING.LIST },
    { title: 'トッピンググループ設定' }
  ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      modal={<Modal />}
      footer={<Footer />}
      displayKM
      displayKP
    >
      <ErrorMessages />
      <Addition>
        <MainText>
          トッピングしたい商品をトッピンググループとしてまとめます。商品一覧より選び、表示したい順番に並ベてください。
        </MainText>
        <SubText>
          ※バリエーションが設定されている商品、コースおよび放題プランとして登録されている商品、税設定が「非課税」で設定されている商品は、トッピンググループに登録できません。
        </SubText>
      </Addition>
      <NameInput />
      <VerticalSpacer length={48} />
      <ListTable>
        <ListTableItem>
          <ItemList />
        </ListTableItem>
        <ArrowItem>
          <Arrow />
        </ArrowItem>
        <ListTableItem>
          <SelectedList />
        </ListTableItem>
      </ListTable>
    </PageTemplate>
  )
}

const ListTable = styled.div`
  display: flex;
  height: calc(100% - 140px);
`

const ListTableItem = styled.div`
  width: 45%;
`

const ArrowItem = styled.div`
  padding-top: 30px;
  display: flex;
  width: 10%;
  height: 100%;
  align-items: center;
  justify-content: center;
`
const Addition = styled.div`
  padding-bottom: 24px;
`

const MainText = styled.div`
  font-size: 16px;
  line-height: 22px;
`

const SubText = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: 14px;
  line-height: 20px;
`

const Arrow = styled(Icon).attrs({ name: 'arrow' })``
