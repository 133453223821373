import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from '@air-kit/air-kit'
import Const from '~/src/const'
import ModalList from './ModalList'
import ModalFooter from './ModalFooter'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
const { Size } = Const

type Props = {
  interactor: VariationGroupInteractor
  isShowing: boolean
  selectedItemCount: number
}

const VariationGroupEditor: React.FC<Props> = props => {
  const onCancelCb = useCallback(() => {
    props.interactor.cancelVariationGroupEditor()
  }, [props.interactor])
  if (!props.isShowing) {
    return null
  }
  const footer = {
    right: <ModalFooter />
  }
  return (
    <Adjuster>
      <Modal
        isScrollable
        title={`${props.selectedItemCount}件の商品にバリエーションを一括登録する`}
        footer={footer}
        onClose={onCancelCb}
      >
        <ModalList />
      </Modal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`

export default React.memo(VariationGroupEditor)
