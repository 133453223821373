import APIClient from '~/src/util/APIClient'
import Const from '~/src/const'
import { handleError } from '~/src/redux/modules/ApiError'
import {
  setLoading,
  clearLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import { RegisteredDevices, RegisteredDevice, ResponseMap } from './types'

const { API, Toast } = Const

// Action Type ConstantValue
const FETCH_REGISTERED_DEVICE_SUCCESS =
  'oes/RegisteredDevice/FETCH_REGISTERED_DEVICE_SUCCESS'
const CLEAR_RESOURCES = 'oes/RegisteredDevice/CLEAR_RESOURCES'
const SET_EDIT_TARGET_DEVICE = 'oes/RegisteredDevice/SET_EDIT_TARGET_DEVICE'
const CHANGE_DEVICE_NAME = 'oes/RegisteredDevice/CHANGE_DEVICE_NAME'
const EDIT_DEVICE_NAME_SUCCESS = 'oes/RegisteredDevice/EDIT_DEVICE_NAME_SUCCESS'
const CLEAR_EDIT_TARGET_DEVICE = 'oes/RegisteredDevice/CLEAR_EDIT_TARGET_DEVICE'
const SET_DELETE_TARGET_DEVICE = 'oes/RegisteredDevice/SET_DELETE_TARGET_DEVICE'
const DELETE_DEVICE_SUCCESS = 'oes/RegisteredDevice/DELETE_DEVICE_SUCCESS'
const CLEAR_DELETE_TARGET_DEVICE =
  'oes/RegisteredDevice/CLEAR_DELETE_TARGET_DEVICE'

// Action
export const fetchResources = () => {
  // @ts-ignore
  return dispatch => {
    return Promise.all([
      APIClient.get(API.GET_REGISTERED_DEVICES),
      APIClient.get(API.GET_DEVICE_COUNT)
    ])
      .then(dispatch(setLoading()))
      .then(([registeredDevicesResponse, deviceCountResponse]) => {
        return {
          registeredDeviceApiResponse: registeredDevicesResponse.data,
          deviceCountApiResponse: deviceCountResponse.data
        }
      })
      .then((responseMap: ResponseMap) =>
        dispatch(fetchResourceSuccess(responseMap))
      )
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const fetchResourceSuccess = (responseMap: ResponseMap) => {
  return {
    type: FETCH_REGISTERED_DEVICE_SUCCESS,
    response: responseMap
  }
}

export const clearResources = () => ({
  type: CLEAR_RESOURCES
})

export const setEditTargetDevice = (registeredDevice: RegisteredDevice) => ({
  type: SET_EDIT_TARGET_DEVICE,
  editTargetDevice: registeredDevice
})

export const changeDeviceName = (deviceName: string) => ({
  type: CHANGE_DEVICE_NAME,
  deviceName: deviceName
})

export const postEditDeviceName = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const registeredDevice = getState().registeredDevice.editTargetDevice
    const json = {
      registeredDeviceId: registeredDevice.registeredDeviceId,
      deviceName: registeredDevice.deviceName
    }
    return APIClient.post(API.POST_REGISTERED_DEVICES_UPDATE, json)
      .then(dispatch(setLoading()))
      .then(json => dispatch(editDeviceNameSuccess()))
      .then(() => dispatch(showAndBlurToast(Toast.SAVED_MESSAGE)))
      .then(() => dispatch(fetchResources()))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

export const clearEditTargetDevice = () => ({
  type: CLEAR_EDIT_TARGET_DEVICE
})

const editDeviceNameSuccess = () => ({
  type: EDIT_DEVICE_NAME_SUCCESS
})

export const setDeleteTargetDevice = (registeredDevice: RegisteredDevice) => ({
  type: SET_DELETE_TARGET_DEVICE,
  deleteTargetDevice: registeredDevice
})

export const postDeleteDevice = () => {
  // @ts-ignore
  return (dispatch, getState) => {
    const registeredDevice = getState().registeredDevice.deleteTargetDevice
    const json = { registeredDeviceId: registeredDevice.registeredDeviceId }
    return APIClient.post(API.POST_REGISTERED_DEVICES_DELETE, json)
      .then(dispatch(setLoading()))
      .then(json => dispatch(deleteDeviceSuccess()))
      .then(() => dispatch(showAndBlurToast(Toast.REMOVED_MESSAGE)))
      .then(() => dispatch(fetchResources()))
      .catch(error => dispatch(handleError(error)))
      .then(() => dispatch(clearLoading()))
  }
}

const deleteDeviceSuccess = () => ({
  type: DELETE_DEVICE_SUCCESS
})

export const clearDeleteTargetDevice = () => ({
  type: CLEAR_DELETE_TARGET_DEVICE
})

// Initial state
const initialState: RegisteredDevices = {
  registeredDevices: [],
  deviceCount: {
    currentCount: 0,
    flatRateCount: 0,
    billingTargetCount: 0,
    lastUpdatedAt: 0,
    billingTargetDate: 0,
    requestedAt: 0
  },
  isEditing: false,
  editTargetDevice: null,
  deleteTargetDevice: null
}

// Reducer
// @ts-ignore
export default (state = initialState, action: any): RegisteredDevices => {
  switch (action.type) {
    case FETCH_REGISTERED_DEVICE_SUCCESS: {
      const fetchResponse = convertFetchResponses(action.response)
      return {
        ...state,
        registeredDevices: fetchResponse.registeredDevices || [],
        deviceCount: fetchResponse.deviceCount
      }
    }
    case SET_EDIT_TARGET_DEVICE:
      return {
        ...state,
        editTargetDevice: action.editTargetDevice
      }
    case CHANGE_DEVICE_NAME:
      return {
        ...state,
        editTargetDevice: {
          ...state.editTargetDevice!, // 端末名変更時は必ず編集対象は存在しているため強制アンラップ
          deviceName: action.deviceName
        },
        isEditing: true
      }
    case EDIT_DEVICE_NAME_SUCCESS:
      return {
        ...state,
        registeredDevices: state.registeredDevices.map(device =>
          device.registeredDeviceId ===
          state.editTargetDevice?.registeredDeviceId
            ? state.editTargetDevice
            : device
        ),
        editTargetDevice: null,
        isEditing: false
      }
    case CLEAR_EDIT_TARGET_DEVICE:
      return {
        ...state,
        editTargetDevice: null,
        isEditing: false
      }
    case SET_DELETE_TARGET_DEVICE:
      return {
        ...state,
        deleteTargetDevice: action.deleteTargetDevice
      }
    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        registeredDevices: state.registeredDevices.filter(
          device =>
            device.registeredDeviceId !==
            state.deleteTargetDevice?.registeredDeviceId
        ),
        deleteTargetDevice: null
      }
    case CLEAR_DELETE_TARGET_DEVICE:
      return {
        ...state,
        deleteTargetDevice: null
      }
    case CLEAR_RESOURCES:
      return initialState
    default:
      return state
  }
}

const convertFetchResponses = (responseMap: ResponseMap) => {
  const registeredDevicesResource =
    responseMap.registeredDeviceApiResponse.result || {}
  const registeredDevices = registeredDevicesResource.registeredDevices?.sort(
    (a, b) => a.createdAt - b.createdAt
  )
  const deviceCountSummaryResource =
    responseMap.deviceCountApiResponse.result || {}
  const deviceCount = deviceCountSummaryResource.deviceCountSummary
  return {
    registeredDevices,
    deviceCount
  }
}
