import { Set } from 'immutable'
import { createAction } from 'typesafe-actions'

import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'

import * as types from './types'

export const actions = {
  clearState: createAction(types.CLEAR_STATE)(),
  fetchInitialDataSucceeded: createAction(
    types.FETCH_INITIAL_DATA_SUCCEEDED,
    (items: ApiResponse<ItemsApiResponse>, toppingGroupId: string) => ({
      items,
      toppingGroupId
    })
  )(),
  addItem: createAction(types.ADD_ITEM, (itemId: string) => ({ itemId }))(),
  removeItem: createAction(types.REMOVE_ITEM, (itemId: string) => ({
    itemId
  }))(),
  addAll: createAction(types.ADD_ALL, (itemIds: Set<string>) => ({
    itemIds
  }))(),
  removeAll: createAction(types.REMOVE_ALL, (itemIds: Set<string>) => ({
    itemIds
  }))(),
  saveSucceeded: createAction(types.SAVE_SUCCEEDED)()
}
