import { ActionType, getType } from 'typesafe-actions'
import { actions } from './actions'
import { HodaiTopState } from './types'

const initialState: HodaiTopState = {
  deleteTargetHodai: {
    hodaiId: '',
    name: undefined
  }
}

export const hodaiTop = (
  state: HodaiTopState = initialState,
  action: ActionType<typeof actions>
): HodaiTopState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.openDeleteModal): {
      return {
        ...state,
        deleteTargetHodai: {
          hodaiId: action.payload.hodaiId,
          name: action.payload.name
        }
      }
    }
    default:
      return state
  }
}
