import React from 'react'
import {
  TableRow,
  NameColumn,
  TaxTypeColumn,
  PriceColumn
} from './Common/TableParts'
import VariationRow from './Variation'
import VariationGroup from '~/src/redux/models/CSVItem/VariationGroup'

type Props = {
  group: VariationGroup
}

const ModalList: React.FC<Props> = props => {
  return (
    <div>
      <TableRow header>
        <NameColumn>種別1/種別2</NameColumn>
        <TaxTypeColumn>適用税率</TaxTypeColumn>
        <PriceColumn>価格</PriceColumn>
      </TableRow>
      {props.group.variations.map(variation => (
        <VariationRow key={variation.variationId} variation={variation} />
      ))}
    </div>
  )
}

export default React.memo(ModalList)
