import React from 'react'
import Footer from '~/src/components/organisms/Footer'
import { useSaveButtonClick, useOnCancelButtonClick } from './interactors'
import { useSelector } from 'react-redux'
import { selectRequest } from './selectors'
type Props = {
  isEdited: boolean
}
export const OptionSettingFooter: React.VFC<Props> = props => {
  const request = useSelector(selectRequest)
  return (
    <Footer
      disableSubmitButton={props.isEdited}
      noUseOptionButton={true}
      onSubmitButtonClick={useSaveButtonClick(request)}
      onCancelButtonClick={useOnCancelButtonClick()}
    />
  )
}
