import { CSVData } from '~/src/util/CSVFileReader'
import CSVItem from './CSVItem'
import CSVSku from './CSVSku'
import CSVItemStructure from './CSVItemStructure'
import CONSUMPTION_TAX_TYPE, {
  ConsumptionTaxType
} from '~/src/const/consumptionTaxType'

/**
 * CSVItem, CSVSku のモデルを CSVData(string[][]) に変換する
 */
class CSVItemWriter {
  /**
   * 商品の配列を複数行からなるCSVDataに変換する
   * @param items CSVItem[]
   * @returns CSVData
   */
  write(items: CSVItem[]): CSVData {
    const result = items
      .sort((a, b) => a.compareTo(b))
      .map(item => this.convertToRowData(item))
      .reduce((sum, val) => sum.concat(val), [])
    return result
  }

  /**
   * CSVItemモデルをCSVDataに変換する
   * SKU商品の場合は複数行となるため、通常商品の場合も複数行の配列として返却する
   * @param item
   */
  convertToRowData(item: CSVItem): CSVData {
    if (item.hasSKU()) {
      return item.skus.map(sku => this.mergeToRowData(sku)).toArray()
    } else {
      const rowData = this.mergeToRowData(item)
      return [rowData]
    }
  }

  mergeToRowData(model: CSVItem | CSVSku) {
    const rowData = model.rowData.concat()
    CSVItemStructure.forEach(columnType => {
      let data: any = model.get(columnType.id, undefined)
      switch (columnType.id) {
        case 'consumptionTaxType':
          data = (data as ConsumptionTaxType).CSV_ID
          break
        case 'isDisplayed':
          data = data ? '表示' : '非表示'
          break
      }
      if (columnType.type === 'number') {
        data = String(data)
      }
      if (data !== undefined) {
        rowData[columnType.order] = data
      }
    })
    // 「注文時に選択」以外の場合は「税率別価格」と「価格2」を消す。
    if (model.consumptionTaxType?.ID !== CONSUMPTION_TAX_TYPE.CHOICE.ID) {
      rowData[11] = ''
      rowData[15] = ''
    }
    return rowData
  }
}

export default CSVItemWriter
