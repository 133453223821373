import { createAction } from 'typesafe-actions'
import { KmSettingApiResponse } from '~/src/api/km/settings/types'

const CLEAR_STATE = 'oes/TargetServingTime/DisplayOptionSetting/CLEAR_STATE'
const INITIALIZE = 'oes/TargetServingTime/DisplayOptionSetting/INITIALIZE'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(
    INITIALIZE,
    (kmSettingApiResponse: KmSettingApiResponse) => ({ kmSettingApiResponse })
  )()
}

export const actionTypes = {
  CLEAR_STATE,
  INITIALIZE
}
