import React from 'react'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import Const from '~/src/const'
import { ExclamationIcon } from '~/src/components/atoms/Icon'

const { Size, Color } = Const

type Props = {
  onClick: () => void
  selectedItemName?: string
  shouldShowButton: boolean
  hasWarning: boolean
}

export const SelectItemArea: React.VFC<Props> = props => {
  const selectedItemName = props.selectedItemName
    ? { name: props.selectedItemName, enabled: true }
    : { name: '選択されていません', enabled: false }

  return (
    <Wrapper>
      {!selectedItemName.enabled && (
        <DisabledText>
          {props.hasWarning && <ExclamationIcon color="red" />}
          {selectedItemName.name}
        </DisabledText>
      )}
      {selectedItemName.enabled && (
        <EnabledText>
          {props.hasWarning && <ExclamationIcon color="red" />}
          {selectedItemName.name}
        </EnabledText>
      )}

      {props.shouldShowButton && (
        <SelectButtonArea>
          <Button type="button" normal onClick={props.onClick}>
            選択する
          </Button>
        </SelectButtonArea>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Color.LIGHT_GRAY_4};
  border-radius: 6px;
  background-color: ${Color.VERY_LIGHT_GRAY};
  display: flex;
  justify-content: space-between;
`

const EnabledText = styled.div`
  width: 144px;
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.LARGE}px;
  letter-spacing: 0;
  padding: 20px;
  flex: 1 1 auto;
`

const DisabledText = styled.div`
  width: 144px;
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.LARGE}px;
  letter-spacing: 0;
  padding: 20px;
  flex: 1 1 auto;
`

const SelectButtonArea = styled.div`
  height: 64px;
  width: 88px;
  padding: 12px 24px 12px 0;
  flex: 0 0 124px;
`
