import React from 'react'
import { useSelector } from 'react-redux'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'
import { useClearError } from './hooks'
import { selectApiError } from './selectors'

export const ApiErrorModal: React.VFC = () => {
  const { isError, error, onClick } = useSelector(selectApiError)
  const clearErrorState = useClearError()

  if (!isError) {
    return null
  }

  const clearError = () => {
    clearErrorState()

    if (onClick) {
      onClick()
    }
  }

  return <ErrorModal isError error={error} onClose={clearError} />
}
