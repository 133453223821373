import React from 'react'
import styled from 'styled-components'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import EmptyState from '~/src/components/atoms/EmptyState'
import { Row } from './Row'
import { HeaderRow } from './HeaderRow'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'
import { useSelector } from 'react-redux'
import { selectSeatSets } from './selectors'
import { useSort } from './hooks'

const SortableItem = SortableElement(
  ({ seatSet, index }: { seatSet: SeatSet; index: number }) => (
    <Row seatSet={seatSet} index={index} />
  )
)

const SortableComponent = SortableContainer(
  ({ seatSets }: { seatSets: SeatSet[] }) => {
    return (
      <div>
        {seatSets.map((seatSet, index) => (
          <SortableItem
            key={`seatSets-${index}`}
            index={index}
            seatSet={seatSet}
          />
        ))}
      </div>
    )
  }
)

export const InnerList = () => {
  const handleSort = useSort()
  const seatSets = useSelector(selectSeatSets)
  return (
    <section id="seat-set-scrollable">
      <HeaderRow />
      <SortableComponent
        seatSets={seatSets}
        onSortEnd={handleSort}
        useDragHandle={true}
        lockAxis="y"
        // 上で定義しているため強制アンラップする
        getContainer={() => document.getElementById('seat-set-scrollable')!}
      />
      {seatSets.length === 0 && (
        <EmptyState>
          注文エリアが登録されていません。
          <br />
          左下のボタンから注文エリアを登録できます。
        </EmptyState>
      )}
      {seatSets.length !== 0 && (
        <CountText>
          ※注文エリア数の上限数は10件です。(現在の注文エリア数：
          {seatSets.length})
        </CountText>
      )}
    </section>
  )
}

const CountText = styled.p`
  margin: 0;
  padding: 16px 0;
`
