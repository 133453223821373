import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Const from '~/src/const'
import Modal from '~/src/components/organisms/Modal'
import Heading from '~/src/components/atoms/Heading'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import { selectEditModalState } from './selectors'
import {
  useClearEditTargetDevice,
  useEditDeviceName,
  useChangeDeviceName
} from './hooks'

const { Color } = Const
const { H2 } = Heading

export const EditModal = () => {
  const { isOpen, deviceName, disableSaveButton, error } =
    useSelector(selectEditModalState)
  const clearEditTargetDevice = useClearEditTargetDevice()
  const editDeviceName = useEditDeviceName()
  const changeDeviceName = useChangeDeviceName()

  return (
    <Modal
      closable
      isOpen={isOpen}
      onClose={clearEditTargetDevice}
      onOK={editDeviceName}
      cancelText="キャンセル"
      okText="保存"
      disableOKButton={disableSaveButton}
    >
      <StyledHeading>端末名を変更</StyledHeading>
      <StyledContent>
        <ValidatableInput
          type="text"
          value={deviceName}
          onChange={e => changeDeviceName(e.target.value)}
          error={error}
        />
      </StyledContent>
    </Modal>
  )
}

const StyledHeading = styled(H2)`
  color: ${Color.VERY_DARK_GRAY_3};
  margin: 0 0 24px;
  word-wrap: break-word;
  max-width: 320px;
`

const StyledContent = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 8px;
  line-height: 20px;
  width: 100%;
`
