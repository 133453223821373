import { Record, Map, List } from 'immutable'
import {
  CategoryApiResponse,
  ItemApiResponse,
  ItemByToppingGroupApiResponse,
  ItemCategoryJunctionApiResponse,
  ToppingGroupApiResponse
} from '~/src/api/handy/items/types'
import { ItemByToppingGroups } from '../Item/ItemByToppingGroups'
import { ToppingGroup } from './ToppingGroup'

interface ToppingGroupsProps {
  toppingGroups: List<ToppingGroup>
  toppingGroupsMap: Map<string, ToppingGroup>
}

const ToppingGroupsRecord = Record<ToppingGroupsProps>({
  toppingGroups: List(),
  toppingGroupsMap: Map<string, ToppingGroup>()
})

export class ToppingGroups
  extends ToppingGroupsRecord
  implements ToppingGroupsProps
{
  constructor(
    toppingGroups: ToppingGroupApiResponse[] = [],
    items: ItemApiResponse[] = [],
    categories: CategoryApiResponse[] = [],
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[] = [],
    itemByToppingGroups: ItemByToppingGroupApiResponse[] = []
  ) {
    super()
    const list = List(toppingGroups).map(
      toppingGroup =>
        new ToppingGroup(
          toppingGroup,
          items,
          categories,
          itemCategoryJunctions,
          itemByToppingGroups
        )
    )
    const map: Map<string, ToppingGroup> = Map().withMutations(map => {
      list.forEach(toppingGroup => {
        map.set(toppingGroup.toppingGroupId, toppingGroup)
      })
    }) as Map<string, ToppingGroup>
    return this.set('toppingGroupsMap', map).set('toppingGroups', list)
  }

  public hasItemHasSku() {
    return this.toppingGroups.some(toppingGroup => toppingGroup.hasItemHasSku())
  }

  public hasItemTaxFree() {
    return this.toppingGroups.some(toppingGroup =>
      toppingGroup.hasItemTaxFree()
    )
  }

  public hasItemNotSetCategory() {
    return this.toppingGroups.some(toppingGroup =>
      toppingGroup.hasItemNotSetCategory()
    )
  }

  public hasToppingGroupNotSetTopping() {
    return this.toppingGroups.some(toppingGroup => !toppingGroup.hasItem())
  }

  public hasToppingGroupNotSetTargetItem() {
    return this.toppingGroups.some(
      toppingGroup => !toppingGroup.hasTargetItemTopping()
    )
  }

  public createWarningMessages(itemByToppingGroups: ItemByToppingGroups) {
    const warningMessages: string[] = []
    if (this.hasItemHasSku()) {
      warningMessages.push(
        'トッピング商品にバリエーションが設定された商品が含まれています。トッピンググループ内容を確認してください。'
      )
    }
    if (this.hasItemTaxFree()) {
      warningMessages.push(
        'トッピング商品に税設定が「非課税」の商品が含まれています。トッピンググループ内容を確認してください。'
      )
    }
    if (this.hasItemNotSetCategory()) {
      warningMessages.push(
        'トッピング商品にカテゴリーが未設定の商品が含まれています。トッピンググループ内容を確認してください。'
      )
    }
    if (itemByToppingGroups.hasItemTaxFree()) {
      warningMessages.push(
        '対象商品に税設定が「非課税」の商品が含まれています。トッピンググループ内容を確認してください。'
      )
    }
    if (itemByToppingGroups.hasItemNotSetCategory()) {
      warningMessages.push(
        '対象商品にカテゴリーが未設定の商品が含まれています。トッピンググループ内容を確認してください。'
      )
    }
    return warningMessages
  }

  public createCautionMassages() {
    const cautionMessages: string[] = []

    if (this.hasToppingGroupNotSetTopping()) {
      cautionMessages.push(
        'トッピング商品が未設定のトッピンググループがあります。'
      )
    }
    if (this.hasToppingGroupNotSetTargetItem()) {
      cautionMessages.push('対象商品が未設定のトッピンググループがあります。')
    }
    return cautionMessages
  }
}
