import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const/'
import {
  ToppingGroupNameColumn,
  ToppingItemCountColumn,
  TargetItemCountColumn,
  IconColumn
} from './Column/Column'
import TableRow from '~/src/components/atoms/TableRow'

const { Color } = Const

export const Header = () => (
  <EditableHeader>
    <ToppingGroupNameColumn>
      <>トッピング</>
      <>グループ名</>
    </ToppingGroupNameColumn>
    <ToppingItemCountColumn>
      トッピング商品数
      <br />
      <ToppingItemCountExample>(もち、チーズなど)</ToppingItemCountExample>
    </ToppingItemCountColumn>
    <TargetItemCountColumn>
      <TargetItemCountColumnBreak>対象商品数</TargetItemCountColumnBreak>
      <br />
      <TargetItemCountExample>(豚玉、海鮮玉など)</TargetItemCountExample>
    </TargetItemCountColumn>
    <IconColumn>解除</IconColumn>
  </EditableHeader>
)

const EditableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  height: 48px;
  padding: 10 0;
  position: relative;
`

const TargetItemCountColumnBreak = styled.span`
  /** テキストを左側に寄せる */
  padding-right: 40px;
`

const TargetItemCountExample = styled.span`
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
`

const ToppingItemCountExample = styled.span`
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  /** テキストを左側に寄せる */
  padding-right: 10px;
`
