import React from 'react'
import { useSelector } from 'react-redux'
import Header from './Header'
import { isKmPlanSelector } from '~/src/redux/selectors/storeSelectors'

const useCreateProps = () => ({
  isAutomaticSettingAvailable: useSelector(isKmPlanSelector)
})

const HeaderContainer = () => {
  return <Header {...useCreateProps()} />
}

export default HeaderContainer
