import React from 'react'
import Message from '~/src/components/atoms/Message'
import { selectError } from './selectors'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Const from '~/src/const'

const { URL } = Const
type Props = {
  itemId?: string
}

export const Messages: React.VFC<Props> = props => {
  const {
    isDeletedHodaiPlanItemError,
    isEmptyHodai,
    hasSku,
    hasDeletedHodaiPlanCategoryError
  } = useSelector(selectError(props.itemId))

  const warningMessageList = []
  const cautionMessageList = []
  if (isDeletedHodaiPlanItemError) {
    warningMessageList.push(
      '削除済みの放題プランがあります。削除済みの放題プランは編集できません。解除してください。'
    )
  }

  if (hasSku) {
    warningMessageList.push(
      <>
        バリエーションが設定されている放題プランがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (hasDeletedHodaiPlanCategoryError) {
    warningMessageList.push(
      <>
        カテゴリーが未設定の放題プランがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  if (isEmptyHodai) {
    cautionMessageList.push('商品が未設定の放題プランがあります。')
  }

  return (
    ((warningMessageList.length !== 0 || cautionMessageList.length !== 0) && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
        <Message type="caution" messageList={cautionMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`
