import React, { useEffect } from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import { OptionSettingFooter as Footer } from './Footer/Footer'
import { WaitingStatusSetting } from './WaitingStatus/index'
import { KmSetting } from './Km/index'
import {
  selectHasError,
  selectIsEditing,
  selectIsSplashShowing
} from './selectors'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { useFetch } from './interactors'
import { useClearState } from './hooks'
const { Page } = Const

export const OptionSetting: React.VFC = () => {
  const hasError = useSelector(selectHasError)
  const isEditing = useSelector(selectIsEditing)
  const isSplashShowing = useSelector(selectIsSplashShowing)
  const breadcrumbs = [
    {
      title: '目標提供時間設定',
      page: Page.TARGET_SERVING_TIME.SETTING
    },
    {
      title: 'オプション設定'
    }
  ]
  const clearState = useClearState()
  useEffect(() => {
    return clearState
  }, [clearState])
  const handleFetch = useFetch()
  useEffect(() => {
    handleFetch()
  }, [handleFetch])
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      footer={<Footer isEdited={hasError || !isEditing} />}
      modal={<DismissPrompt enabled={isEditing} />}
      displayKM
      displayKMOnly
      isSplashShowing={isSplashShowing}
    >
      <WaitingStatusSetting />
      <KmSetting />
    </PageTemplate>
  )
}
