import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  focusFinished,
  openItemSettingEditor,
  updateAnalysisTagByCategory
} from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useOpenItemSettingEditor = (categoryId: String) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(openItemSettingEditor(categoryId))
  }, [categoryId, dispatch])
}
export const useOnChange = (categoryId: String) => {
  const dispatch = useDispatch()
  return useCallback(
    (e: React.SyntheticEvent) => {
      dispatch(
        updateAnalysisTagByCategory(
          categoryId,
          (e.target as HTMLInputElement).value
        )
      )
    },
    [categoryId, dispatch]
  )
}

export const useFocusFinished = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(focusFinished())
  }, [dispatch])
}
