import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setisDisplayedCategoryShowing } from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useSetIsDisplayedCategoryShowing = () => {
  const dispatch = useDispatch()
  return useCallback(
    (isDisplayedCategoryShowing: boolean) => {
      dispatch(setisDisplayedCategoryShowing(isDisplayedCategoryShowing))
    },
    [dispatch]
  )
}
