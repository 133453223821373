import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import styled from 'styled-components'
import useInteractors from '~/src/hooks/useInteractors'
import Const from '~/src/const'
import { RadioButton } from '~/src/common/atoms/RadioButton'
import TableColumn from '../../components/atoms/TableColumn'
import TableRow from '~/src/components/atoms/TableRow'

const { Size, Color, ShouldAutoApplySettings } = Const

export const CommonSetting: React.VFC = () => {
  const interactor = useInteractors().settingInteractor
  const shouldAutoApplySettings = useSelector(
    (state: AppState) => state.setting.commonSetting.shouldAutoApplySettings
  )
  const handleChangeShouldApplySetting = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      interactor.onChangeShouldApplySetting(
        event.target.value === ShouldAutoApplySettings.AUTO.ID
      )
    },
    [interactor]
  )

  return (
    <>
      <SettingTitle>設定情報</SettingTitle>
      <Wrapper>
        <OptionSetting>
          <FormWrapper>
            <StyledBaseRow>
              <CommonSettingArea>
                <Title>更新方法</Title>
                <Description>
                  自動更新にすると、各端末に設定内容が自動で反映されます。
                </Description>
              </CommonSettingArea>
              <RadioButton
                name="shouldAutoApplySettingsSelect"
                value={ShouldAutoApplySettings.AUTO.ID}
                label={ShouldAutoApplySettings.AUTO.LABEL}
                onChange={handleChangeShouldApplySetting}
                checked={
                  shouldAutoApplySettings === ShouldAutoApplySettings.AUTO.VALUE
                }
              />
              <RadioButton
                name="shouldAutoApplySettingsSelect"
                value={ShouldAutoApplySettings.MANUAL.ID}
                label={ShouldAutoApplySettings.MANUAL.LABEL}
                onChange={handleChangeShouldApplySetting}
                checked={
                  shouldAutoApplySettings ===
                  ShouldAutoApplySettings.MANUAL.VALUE
                }
              />
            </StyledBaseRow>
          </FormWrapper>
        </OptionSetting>
      </Wrapper>
    </>
  )
}

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`

const Wrapper = styled.div`
  padding-bottom: 50px;
`

const FormWrapper = styled.div`
  margin-right: 32px;
`

const OptionSetting = styled.div`
  display: flex;
`

const StyledBaseRow = styled(TableRow)`
  width: 480px;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
`

const CommonSettingArea = styled(TableColumn)`
  text-align: left;
  width: 223px;
`

const Title = styled.div`
  font-size: ${Size.FONT.LARGE}px;
`

const Description = styled.div`
  font-size: ${Size.FONT.SMALL}px;
  color: ${Color.DARK_GRAY_3};
  padding-top: 6px;
`
