import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import { TableRow, NameColumn } from './Common/TableParts'
import { SimpleCategoryForTopping } from '~/src/redux/models/Category/Category'
import { CategoryRow } from './Category/Category'

export const CategoryList: React.VFC = () => {
  const categories = useSelector(
    (state: AppState) => state.itemByToppingGroup.categories
  )
  const selectedItemIds = useSelector(
    (state: AppState) => state.itemByToppingGroup.selectedItemIds
  )

  // 選択状態のカテゴリー未設定商品があるかチェックする
  const [haveNonCategoryItem, setHaveNonCategoryItem] = useState(false)
  useEffect(() => {
    const itemIdsInCategories = categories
      .valueSeq()
      .toList()
      .flatMap(category => category.itemIds)
    if (!haveNonCategoryItem) {
      // 解除後も画面上に表示し続けるために、一度セットしたら更新しない
      const toBe = selectedItemIds.some(
        selectedItemId => !itemIdsInCategories.contains(selectedItemId)
      )
      if (toBe !== haveNonCategoryItem) {
        // 差分がある場合のみ更新（無限ループ回避）
        setHaveNonCategoryItem(toBe)
      }
    }
  }, [categories, selectedItemIds, haveNonCategoryItem, setHaveNonCategoryItem])

  return (
    <div>
      <TableRow header>
        <NameColumn>カテゴリー/商品</NameColumn>
      </TableRow>
      {haveNonCategoryItem && <CategoryRow category={undefined} />}
      {categories
        .valueSeq()
        .toList()
        .map((category: SimpleCategoryForTopping) => (
          <CategoryRow key={category.categoryId} category={category} />
        ))}
    </div>
  )
}
