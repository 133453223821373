import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Const from '~/src/const'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import Required from '~/src/components/atoms/Required'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import { SelectItemArea } from '~/src/components/organisms/SelectItemArea/SelectItemArea'
import { selectSelectedCourseName } from './selectors'
import { useParams } from 'react-router'
import { useOpenCourseSelect } from './hooks'

const { URL, Color, Weight } = Const

type Props = {
  hasWarning: boolean
}

export const Step1: React.VFC<Props> = props => {
  const handleOpenCourseSelect = useOpenCourseSelect()
  const { courseId } = useParams<{ courseId: string }>()
  const selectedCourseName = useSelector(selectSelectedCourseName(courseId))

  return (
    <>
      <StepTitleArea>
        <StepBadge stepNum={1} />
        <StepTitle>コース名商品の設定</StepTitle>
        <RequiredWrapper>
          <Required name="required" />
        </RequiredWrapper>
      </StepTitleArea>
      <ItemDescription>
        コースの名前とする商品を設定します。商品は
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        から追加してください。
      </ItemDescription>
      <SelectItemArea
        onClick={handleOpenCourseSelect}
        selectedItemName={selectedCourseName}
        shouldShowButton={!courseId}
        hasWarning={props.hasWarning}
      />
    </>
  )
}

const ItemDescription = styled.div`
  padding-top: 16px;
  padding-bottom: 24px;
`

const StepTitleArea = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const StepTitle = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: 18px;
  line-height: 27px;
  padding-left: 8px;
  font-weight: ${Weight.BOLD};
`

const RequiredWrapper = styled.div`
  height: 22px;
  display: block;
`
