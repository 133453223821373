import { Record } from 'immutable'
import Validate, { CheckType } from '~/src/util/Validate'
import { KitchenPosition } from '../../modules/KitchenPositionSetting/types'

const {
  ALPHA,
  NUMERIC,
  PUNCT,
  BIGALPHA,
  BIGNUMERIC,
  BIGPUNCT,
  KATAKANA,
  HIRAGANA,
  JIS_KANJI
} = CheckType

const KitchenPositionRecord = Record<KitchenPosition>({
  kitchenPositionId: null,
  kitchenPositionName: '',
  isSeatSetUsable: false,
  isDefault: false,
  displayOrder: 0,
  version: 0
})

export default class KitchenPositionModel extends KitchenPositionRecord {
  setKitchenPositionName(name: string) {
    return this.set('kitchenPositionName', name)
  }

  setSeatSetUsable(isSeatSetUsable: boolean) {
    return this.set('isSeatSetUsable', isSeatSetUsable)
  }

  validateName() {
    let error = Validate.required(this.kitchenPositionName)
    if (!error) error = Validate.maxLength(this.kitchenPositionName, 10)
    if (!error) {
      error = Validate.invalidChar(this.kitchenPositionName, [
        ALPHA,
        NUMERIC,
        PUNCT,
        BIGALPHA,
        BIGNUMERIC,
        BIGPUNCT,
        KATAKANA,
        HIRAGANA,
        JIS_KANJI
      ])
    }
    return error
  }

  validate() {
    return this.validateName()
  }

  buildRequestJsonForMerge(index: number) {
    if (this.kitchenPositionId === null) {
      // 新規ならidは不要
      return {
        kitchenPositionName: this.kitchenPositionName,
        isSeatSetUsable: this.isSeatSetUsable,
        isDefault: this.isDefault,
        displayOrder: index,
        version: 1
      }
    }
    return {
      kitchenPositionId: this.kitchenPositionId,
      kitchenPositionName: this.kitchenPositionName,
      isSeatSetUsable: this.isSeatSetUsable,
      isDefault: this.isDefault,
      displayOrder: index,
      version: this.version
    }
  }
}
