import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Const from '~/src/const'
const { Color } = Const

export const LinkTableRow = styled(Link)`
  transition: background-color 0.2s;
  color: ${Color.VERY_DARK_GRAY};
  &:hover {
    background-color: ${Color.VERY_PALE_BLUE_2};
    text-decoration: none;
  }
  background-color: ${Color.WHITE};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  display: table;
  top: -1px;
  margin-top: -1px;
  padding: 16px 0;
  table-layout: fixed;
  width: 100%;
`
