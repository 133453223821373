import React from 'react'
import { useSelector } from 'react-redux'

import Footer from '~/src/components/organisms/Footer'
import { useSubmit } from './hooks'
import { selectBatchSelectFooter } from './selectors'

export const BatchSelectFooter: React.VFC = () => {
  const isSubmittable = useSelector(selectBatchSelectFooter)
  const handleSubmit = useSubmit()

  return (
    <Footer
      disableSubmitButton={!isSubmittable}
      submitButtonText="反映する"
      noUseOptionButton={true}
      onSubmitButtonClick={handleSubmit}
    />
  )
}
