import { Map } from 'immutable'
import { ActionType, getType } from 'typesafe-actions'
import { TargetServingTimeState, CategoriesEditForm, EditForm } from './types'
import actions from './actions'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import ServingTimeModes from '~/src/const/serving-time-mode'
import { SimpleItem } from '../../models/Item/Item'

const initialState: TargetServingTimeState = {
  isEdited: false,
  selectedCategoryId: undefined,
  categories: [],
  targetServingTimeMap: Map<string, TargetServingTime>(),
  estimatedTargetServingTimeMap: Map<string, EstimatedTargetServingTime>(),
  lastCachedAt: '',
  batchEditForm: undefined
}

const editFormInitialState: EditForm = {
  modeId: ServingTimeModes.AUTO.ID,
  noticeMinutes: '',
  alertMinutes: '',
  isChecked: false
}

const editFormManualInitialState: EditForm = {
  modeId: ServingTimeModes.MANUAL.ID,
  noticeMinutes: '10',
  alertMinutes: '20',
  isChecked: false
}

export default (
  state: TargetServingTimeState = initialState,
  action: ActionType<typeof actions>
): TargetServingTimeState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.fetchInitialDateSucceeded): {
      return {
        ...state,
        categories: action.payload.categories,
        targetServingTimeMap: action.payload.targetServingTimeMap,
        estimatedTargetServingTimeMap:
          action.payload.estimatedTargetServingTimeMap,
        lastCachedAt: action.payload.lastCachedAt
      }
    }
    case getType(actions.changeCategory): {
      return {
        ...state,
        selectedCategoryId: action.payload.categoryId
      }
    }
    case getType(actions.changeModeToManual): {
      const target: TargetServingTime = state.targetServingTimeMap.get(
        action.payload.itemId
      )!

      return {
        ...state,
        isEdited: true,
        targetServingTimeMap: state.targetServingTimeMap.set(
          action.payload.itemId,
          target.setModeToManual()
        )
      }
    }
    case getType(actions.changeModeToAuto): {
      const target: TargetServingTime = state.targetServingTimeMap.get(
        action.payload.itemId
      )!

      return {
        ...state,
        isEdited: true,
        targetServingTimeMap: state.targetServingTimeMap.set(
          action.payload.itemId,
          target.setModeToAuto()
        )
      }
    }
    case getType(actions.changeModeToNon): {
      const target: TargetServingTime | undefined =
        state.targetServingTimeMap.get(action.payload.itemId)!

      return {
        ...state,
        isEdited: true,
        targetServingTimeMap: state.targetServingTimeMap.set(
          action.payload.itemId,
          target.setModeToNon()
        )
      }
    }
    case getType(actions.changeNoticeMinutes): {
      const target: TargetServingTime | undefined =
        state.targetServingTimeMap.get(action.payload.itemId)!

      return {
        ...state,
        isEdited: true,
        targetServingTimeMap: state.targetServingTimeMap.set(
          action.payload.itemId,
          target.setNoticeMinutes(action.payload.noticeMinutes)
        )
      }
    }
    case getType(actions.changeAlertMinutes): {
      const target: TargetServingTime | undefined =
        state.targetServingTimeMap.get(action.payload.itemId)!

      return {
        ...state,
        isEdited: true,
        targetServingTimeMap: state.targetServingTimeMap.set(
          action.payload.itemId,
          target.setAlertMinutes(action.payload.alertMinutes)
        )
      }
    }
    case getType(actions.postTargetServingTimesSucceeded): {
      return {
        ...state,
        isEdited: false
      }
    }
    case getType(actions.changeAllItemNoticeMinutes): {
      if (state.batchEditForm === undefined) {
        return state
      }
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          all: {
            ...state.batchEditForm.all,
            noticeMinutes: action.payload.noticeMinutes
          }
        }
      }
    }
    case getType(actions.changeAllItemAlertMinutes): {
      if (state.batchEditForm === undefined) {
        return state
      }
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          all: {
            ...state.batchEditForm.all,
            alertMinutes: action.payload.alertMinutes
          }
        }
      }
    }
    case getType(actions.changeAllItemMode): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.modeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          all: {
            ...editFormState,
            isChecked: true,
            modeId: action.payload.modeId
          }
        }
      }
    }
    case getType(actions.activateAllItemBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.initialServingTimeModeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          all: {
            ...editFormState,
            isChecked: true
          },
          which: 'all'
        }
      }
    }
    case getType(actions.deactivateAllItemBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.initialServingTimeModeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          all: editFormState,
          which: 'noselect'
        }
      }
    }
    case getType(actions.changeCategoryNoticeMinutes): {
      if (state.batchEditForm === undefined) {
        return state
      }
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          categories: {
            ...state.batchEditForm.categories,
            [action.payload.categoryId]: {
              ...state.batchEditForm.categories[action.payload.categoryId],
              noticeMinutes: action.payload.noticeMinutes
            }
          }
        }
      }
    }
    case getType(actions.changeCategoryAlertMinutes): {
      if (state.batchEditForm === undefined) {
        return state
      }
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          categories: {
            ...state.batchEditForm.categories,
            [action.payload.categoryId]: {
              ...state.batchEditForm.categories[action.payload.categoryId],
              alertMinutes: action.payload.alertMinutes
            }
          }
        }
      }
    }
    case getType(actions.changeCategoryMode): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.modeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          categories: {
            ...state.batchEditForm.categories,
            [action.payload.categoryId]: {
              ...editFormState,
              isChecked: true,
              modeId: action.payload.modeId
            }
          }
        }
      }
    }
    case getType(actions.activateCategoryBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.initialServingTimeModeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          categories: {
            ...state.batchEditForm.categories,
            [action.payload.categoryId]: {
              ...state.batchEditForm.categories[action.payload.categoryId],
              ...editFormState,
              isChecked: true
            }
          },
          which: 'categories'
        }
      }
    }
    case getType(actions.deactivateCategoryBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const editFormState =
        action.payload.initialServingTimeModeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      const nextCategoriesState = {
        ...state.batchEditForm.categories,
        [action.payload.categoryId]: editFormState
      }
      const isEditingSomeCategories = Object.values(nextCategoriesState).some(
        category => category.isChecked
      )
      return {
        ...state,
        batchEditForm: {
          ...state.batchEditForm,
          categories: nextCategoriesState,
          which: isEditingSomeCategories ? 'categories' : 'noselect'
        }
      }
    }
    case getType(actions.openBatchEditFormModal): {
      const categories: CategoriesEditForm = {}
      const initialEditModal =
        action.payload.initialServingTimeModeId === ServingTimeModes.MANUAL.ID
          ? editFormManualInitialState
          : editFormInitialState
      state.categories.forEach(category => {
        categories[category.categoryId] = initialEditModal
      })
      return {
        ...state,
        batchEditForm: {
          all: initialEditModal,
          categories,
          which: 'noselect'
        }
      }
    }
    case getType(actions.closeBatchEditFormModal): {
      return {
        ...state,
        batchEditForm: undefined
      }
    }
    case getType(actions.applyAllItemBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const form = state.batchEditForm.all
      let nextTargetServingTimeMap = Map<string, TargetServingTime>()
      const itemIds = state.categories
        .map(category => {
          const items = category.items.toArray() as SimpleItem[]
          return items.map(item => item.itemId)
        })
        .flat()
      if (itemIds.length === 0) {
        return {
          ...state,
          batchEditForm: undefined
        }
      }
      itemIds.forEach((itemId: string) => {
        const targetServingTime = new TargetServingTime({
          itemId,
          mode: form.modeId,
          noticeMinutes: form.noticeMinutes,
          alertMinutes: form.alertMinutes
        })
        nextTargetServingTimeMap = nextTargetServingTimeMap.set(
          itemId,
          targetServingTime
        )
      })
      return {
        ...state,
        targetServingTimeMap: nextTargetServingTimeMap,
        isEdited: true,
        batchEditForm: undefined
      }
    }
    case getType(actions.applyCategoriesBatchEdit): {
      if (state.batchEditForm === undefined) {
        return state
      }
      const checkedForms = Object.entries(
        state.batchEditForm.categories
      ).filter(form => form[1].isChecked)
      let nextTargetServingTimeMap = state.targetServingTimeMap
      checkedForms.forEach(checked => {
        const categoryId = checked[0]
        const form = checked[1]
        const itemIds: string[] =
          state.categories
            .find(c => c.categoryId === categoryId)
            ?.items.toArray()
            .map(item => item.itemId) || []
        itemIds.forEach(itemId => {
          const targetServingTime = new TargetServingTime({
            itemId,
            mode: form.modeId,
            noticeMinutes: form.noticeMinutes,
            alertMinutes: form.alertMinutes
          })
          nextTargetServingTimeMap = nextTargetServingTimeMap.set(
            itemId,
            targetServingTime
          )
        })
      })
      return {
        ...state,
        targetServingTimeMap: nextTargetServingTimeMap,
        isEdited: true,
        batchEditForm: undefined
      }
    }
    default:
      return state
  }
}
