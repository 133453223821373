import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'

const { Size, Weight, Color, ZIndex } = Const

const DEFAULT_WIDTH = 200
const TRIANGLE_HEIGHT = 6
const TRIANGLE_OFFSET = 12
const BACKGROUND_COLOR = 'rgba(41, 54, 59, 0.9)'

export type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

type Props = {
  position?: Position
  balloonWidth?: number
  link?: string
}

const Balloon: React.FC<Props> = props => {
  const {
    children,
    link,
    balloonWidth = DEFAULT_WIDTH,
    position = 'bottom-right'
  } = props
  const isTop = /top/.test(position)
  const isLeft = /left/.test(position)
  return (
    <Wrapper isTop={isTop} isLeft={isLeft} balloonWidth={balloonWidth}>
      {children}
      {link && (
        <StyledLink target="_blank" href={link}>
          詳しくはこちら <ExternalLinkIcon />
        </StyledLink>
      )}
    </Wrapper>
  )
}

const StyledLink = styled(InlineLink)`
  color: ${Color.WHITE};
  text-decoration: underline;
`

const Wrapper = styled.span<{
  isTop: boolean
  isLeft: boolean
  balloonWidth: number
}>`
  display: none;
  width: ${props => props.balloonWidth}px;
  position: absolute;
  z-index: ${ZIndex.AIR_OES_BALLOON};
  padding: 8px 12px;
  color: ${Color.WHITE};
  font-size: ${Size.FONT.BASE}px;
  line-height: 16px;
  font-weight: ${Weight.NORMAL};
  text-align: left;
  white-space: normal;
  background: ${BACKGROUND_COLOR};
  border-radius: 4px;
  :hover {
    display: inline-block;
  }
  ::before {
    content: '';
    position: absolute;
    z-index: ${ZIndex.AIR_OES_BALLOON - 1};
    width: 0;
    height: 0;
    border-style: solid;
  }
  ${props => makeStyle(props.isTop, props.isLeft)}
`

const makeStyle = (isTop: boolean, isLeft: boolean) => {
  return `
    ${isTop ? 'bottom' : 'top'}: 26px;
    ${isLeft ? 'right' : 'left'}: -6px;
    ::before {
      ${isTop ? 'bottom' : 'top'}: -${TRIANGLE_HEIGHT}px;
      ${isLeft ? 'right' : 'left'}: ${TRIANGLE_OFFSET}px;
      border-width: ${
        isTop
          ? `${TRIANGLE_HEIGHT}px ${TRIANGLE_HEIGHT}px 0;`
          : `0 ${TRIANGLE_HEIGHT}px ${TRIANGLE_HEIGHT}px;`
      }
      border-color: ${
        isTop
          ? `${BACKGROUND_COLOR} transparent transparent`
          : `transparent transparent ${BACKGROUND_COLOR}`
      }
    }
  `
}

export default Balloon
