import React from 'react'
import styled from 'styled-components'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import Required from '~/src/components/atoms/Required'
import { useSelector } from 'react-redux'
import { useChangeSeatSetName } from './hooks'
import { selectSeatSetDetailNameInput } from './selectors'

export default () => {
  const handleChangeSeatSetName = useChangeSeatSetName()
  const { seatSetName, error } = useSelector(selectSeatSetDetailNameInput)

  return (
    <FormGroup>
      <FormLabel>
        注文エリア名
        <Required name="required" />
      </FormLabel>
      <FormInput>
        <StyledInput
          type="text"
          name="seatset-name-input"
          value={seatSetName}
          align="bottom"
          error={error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeSeatSetName({ newValue: e.target.value })
          }
          placeholder="１階、２階など"
        />
      </FormInput>
    </FormGroup>
  )
}

const FormGroup = styled.div`
  display: table;
  padding-bottom: 16px;
  width: 100%;
`

const FormLabel = styled.label`
  display: table-cell;
  width: 162px;
  font-weight: bold;
  vertical-align: middle;
  padding: 0 16px;
`

const FormInput = styled.div`
  display: table-cell;
  width: auto;
  padding: 0 16px;
`

const StyledInput = styled(ValidatableInput)`
  position: relative;
  float: right;
  width: 100%;
  max-width: 400px;
`
