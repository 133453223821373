import { AppState } from '~/src/redux/reducer'

export const selectKitchenPositionParams = (state: AppState) => {
  const {
    kitchenPositionByCategories,
    isKitchenPositionsEmpty,
    isDisplayedCategoryShowing
  } = state.kitchenPositionTarget

  return {
    kitchenPositionByCategories:
      kitchenPositionByCategories.kitchenPositionByCategories.filter(
        category => category.isDisplayed === isDisplayedCategoryShowing
      ),
    isKitchenPositionsEmpty,
    isDisplayedCategoryShowing
  }
}
