import { Record, List } from 'immutable'
import Const from '~/src/const'
import Category from '~/src/redux/models/Category/Category'
import Validate from '~/src/util/Validate'
import { KitchenPositionByCategory } from '../../modules/KitchenPositionSetting/types'

const { UndefinedId } = Const

type KitchenPositionRecord = {
  categoryId: string
  categoryDisplayName: string
  isDisplayed: boolean
  kitchenPositionId: string
  analysisTag: string
  displayOrder: number
  isAutoCompleted: boolean
  analysisTagError?: string | null
  itemIds?: List<string>
}

const KitchenPositionByCategoryRecord = Record<KitchenPositionRecord>({
  categoryId: '',
  categoryDisplayName: '',
  isDisplayed: true,
  kitchenPositionId: '',
  analysisTag: '',
  displayOrder: 0,
  isAutoCompleted: false,
  analysisTagError: null, // 意図的に保存ボタン押下時にエラーを出力したいためメンバにしている
  itemIds: undefined
})

export default class KitchenPositionByCategoryModel extends KitchenPositionByCategoryRecord {
  constructor(
    category = new Category(),
    kitchenPositionByCategories = List<KitchenPositionByCategory>(),
    defaultKitchenPositionId: string | null = '',
    itemIds: List<string>
  ) {
    super()

    const isAutoCompleted = !kitchenPositionByCategories.some(
      byCategory => byCategory.categoryId === category.categoryId
    )

    const kitchenPositionId = isAutoCompleted
      ? defaultKitchenPositionId
      : kitchenPositionByCategories
          .filter(byCategory => byCategory.categoryId === category.categoryId)
          .map(byCategory => byCategory.kitchenPositionId)
          .first()

    const analysisTag = kitchenPositionByCategories
      .filter(byCategory => byCategory.categoryId === category.categoryId)
      .map(byCategory => byCategory.analysisTag)
      .first()

    return this.merge({
      categoryId: category.categoryId,
      categoryDisplayName: category.getDisplayCategoryName(),
      isDisplayed: category.isDisplayed,
      displayOrder: category.displayOrder,
      kitchenPositionId: !kitchenPositionId
        ? UndefinedId.SELECTBOX_ID
        : kitchenPositionId,
      analysisTag: analysisTag,
      isAutoCompleted: isAutoCompleted,
      analysisTagError: null,
      itemIds: itemIds
    })
  }

  setKitchenPositionId(kitchenPositionId: string) {
    // CL操作によって変更された場合、デフォルト設定状態ではなくす
    // 描画維持以外にデフォルトの状態になるOR戻すことはないので、ここで処理する
    return this.set('kitchenPositionId', kitchenPositionId).set(
      'isAutoCompleted',
      false
    )
  }

  setAnalysisTag(analysisTag: string) {
    return this.set('analysisTag', analysisTag)
  }

  validateAnalysisTagForItemSetting() {
    return this.set(
      'analysisTagError',
      Validate.required(this.analysisTag) && '商品別設定のために必須です'
    )
  }

  validateAnalysisTagIfNeeded() {
    if (!this.isDisplayed) {
      return this.set('analysisTagError', null)
    }
    return this.set('analysisTagError', Validate.required(this.analysisTag))
  }
}
