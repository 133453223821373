import React, { useState, useRef, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { useHistory, useLocation, matchPath } from 'react-router-dom'
import Const from '~/src/const'
import { DownArrowIcon } from '~/src/components/atoms/SideMenuIcon'
import { SideMenuItem } from './SideMenuItem/SideMenuItem'
import { Menu } from '../types'
const { Color, Size, Time } = Const

type SideMenuGroupProps = {
  menuGroup: Menu
  isMenuFolded: boolean
  onClick?: () => void
}
export const SideMenuGroup: React.VFC<SideMenuGroupProps> = (
  props: SideMenuGroupProps
) => {
  const { menuGroup, isMenuFolded } = props
  const [isListFolded, setIsListFolded] = useState(true)
  const [menuHeight, setMenuHeight] = useState<number | null>(null)
  const menuGroupRef = useRef<HTMLDivElement>(null)
  const listHeightRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const location = useLocation()
  const match =
    menuGroup.menuLink &&
    matchPath(location.pathname, {
      path: menuGroup.menuLink,
      strict: false,
      sensitive: false
    })

  // サイドメニューが閉じた場合、表示崩れ防止のためリストも閉じる
  const isMenuListFolded = isMenuFolded || isListFolded
  // 当該リンクが表示中のURL、またはリストが閉じられている場合にリスト内にURLを含む場合はハイライトする
  const isLinkContained =
    menuGroup.items &&
    !!menuGroup.items.filter(
      item =>
        !!matchPath(location.pathname, {
          path: item.link,
          strict: false,
          sensitive: false
        })
    ).length
  const isHighlighted = !!match || (isMenuListFolded && isLinkContained)

  useLayoutEffect(() => {
    if (!listHeightRef.current) return
    if (isLinkContained) {
      setIsListFolded(false)
    }
    setMenuHeight(listHeightRef.current.clientHeight)
  }, [isLinkContained, location]) // ルートにアクセスされリダイレクトされた時もメニューの開閉を判定するためにlocationを追加

  const isClickable = !match
  const onClick = () => {
    if (menuGroup.menuLink && isClickable) {
      props.onClick?.()
      menuGroup.showOtherTab
        ? window.open(menuGroup.menuLink, '_blank')
        : history.push(menuGroup.menuLink)
    } else {
      setIsListFolded(isMenuFolded ? false : !isListFolded)

      // サイドメニューが閉じていた場合、開くとともにクリック対象までスクロールさせる
      if (isMenuFolded && menuGroupRef) {
        setTimeout(() => {
          if (menuGroupRef.current !== null) {
            menuGroupRef.current.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            })
          }
        }, Time.SIDE_MENU)
      }
    }
  }

  const title = Array.isArray(menuGroup.menuTitle)
    ? menuGroup.menuTitle
        .reduce((sum, str) => sum.concat([str, <br key={str} />]), [])
        .slice(0, -1)
    : menuGroup.menuTitle
  const isWordWrap = !!menuGroup.isWordWrap
  return (
    <>
      <Wrapper
        ref={menuGroupRef}
        isClickable={isClickable}
        isHighlighted={isHighlighted}
        onClick={onClick}
      >
        <IconWrapper>
          <menuGroup.menuIcon />
        </IconWrapper>
        <TitleWrapper isMenuFolded={isMenuFolded}>
          <TitleArea isWordWrap={isWordWrap}>{title}</TitleArea>
          {!menuGroup.menuLink && (
            <ArrowArea isMenuFolded={isMenuListFolded}>
              <DownArrowIcon />
            </ArrowArea>
          )}
        </TitleWrapper>
        {menuGroup.rightIcon && (
          <IconWrapper>
            <menuGroup.rightIcon />
          </IconWrapper>
        )}
      </Wrapper>
      {menuGroup.items && (
        <SideMenuList
          isListFolded={isMenuListFolded}
          isMenuFolded={isMenuFolded}
          menuHeight={menuHeight}
        >
          <div ref={listHeightRef}>
            {menuGroup.items.map(item => (
              <SideMenuItem key={item.link} item={item} />
            ))}
          </div>
        </SideMenuList>
      )}
    </>
  )
}

type WrapperProps = {
  isHighlighted?: boolean
  isClickable?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: ${Color.WHITE};
  font-size: ${Size.FONT.LARGE}px;
  cursor: default;
  transition: background-color ${Time.SIDE_MENU}ms;
  &:hover {
    background-color: ${Color.VERY_DARK_GRAYISH_BULE_6};
  }
  ${props =>
    props.isHighlighted &&
    `
    background-color: ${Color.VERY_DARK_GRAYISH_BLUE_7};
    &:hover {
      background-color: ${Color.VERY_DARK_GRAYISH_BLUE_7};
    }
  `}
  ${props =>
    props.isClickable &&
    `
    cursor: pointer;
    &:hover {
      background-color: ${Color.VERY_DARK_GRAYISH_BULE_6};
    }
  `}
`

const IconWrapper = styled.div`
  width: 48px;
  padding: 12px;
  line-height: 10px;
`

type TitleWrapperProps = {
  isMenuFolded: boolean
}

const TitleWrapper = styled.div<TitleWrapperProps>`
  display: table;
  align-items: center;
  width: calc(100% - 42px);
  max-width: calc(100% - 42px);
  overflow: hidden;
  opacity: 100%;
  margin-left: -6px;
  transition: all ease ${Time.SIDE_MENU}ms;
  ${props =>
    props.isMenuFolded &&
    css`
      max-width: 0;
      opacity: 0;
      div {
        line-height: 0;
      }
    `}
`
type TitleWordWrap = {
  isWordWrap: boolean
}
const TitleArea = styled.div<TitleWordWrap>`
  display: table-cell;
  width: auto;
  vertical-align: middle;
  padding: 12px 0;
  white-space: ${props => (props.isWordWrap ? `break-spaces` : `nowrap`)};
`
type ArrowAreaProps = {
  isMenuFolded: boolean
}

const ArrowArea = styled.div<ArrowAreaProps>`
  display: table-cell;
  width: 33px;
  vertical-align: middle;
  padding-left: 6px;
  svg {
    transition: all ease ${Time.SIDE_MENU}ms;
    transform: rotate(-180deg);
  }
  ${props =>
    props.isMenuFolded &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
`

type SideMenuListProps = {
  menuHeight: number | null
  isMenuFolded: boolean
  isListFolded: boolean
}

const SideMenuList = styled.div<SideMenuListProps>`
  max-height: ${props => props.menuHeight}px;
  max-width: 100%;
  overflow: hidden;
  transition: all ease ${Time.SIDE_MENU}ms;
  ${props =>
    props.isMenuFolded &&
    css`
      max-width: 0;
    `}
  ${props =>
    props.isListFolded &&
    css`
      max-height: 0;
    `}
`
