import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setPrinterByItemSeatSetsEditing } from '~/src/redux/modules/PrintTarget/PrintTargetByItem'
import { changeSelectedItemValues } from '~/src/redux/modules/PrintTarget/PrinterSelectBox'
import { PrinterSelectBoxValues } from '~/src/redux/modules/PrintTarget/types'

export const useSelectChange = () => {
  const dispatch = useDispatch()
  return useCallback(
    (values: PrinterSelectBoxValues[]) => {
      dispatch(setPrinterByItemSeatSetsEditing(true))
      dispatch(changeSelectedItemValues(values))
    },
    [dispatch]
  )
}
