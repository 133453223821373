import React from 'react'
import styled from 'styled-components'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import Hint from '~/src/components/molecules/Hint'
import { SelectItem } from '../CourseSetting/SelectItem/SelectItem'
import {
  Attention,
  StepTitleArea,
  StepTitle,
  DescriptionArea,
  Description
} from './Common/Parts'

export const Step2: React.VFC = () => (
  <Wrapper>
    <StepTitleArea>
      <StepBadge stepNum={2} />
      <StepTitle>コース内容の設定</StepTitle>
    </StepTitleArea>
    <DescriptionArea>
      <Description>
        コースの内容を設定します。商品一覧より選び、提供順に並べてください。
      </Description>
      <Attention>
        ※バリエーションありの商品を選択すると、注文時にお客様が自由に選べる商品として設定されます。
        <Hint small position="top-left" balloonWidth={290}>
          {
            '例えば、「肉料理」「魚料理」のバリエーションがある「メイン」という商品を設定した場合、コースの中の「メイン」を「肉料理」「魚料理」どちらにするかを注文時に選んで入力することができます。'
          }
        </Hint>
      </Attention>
    </DescriptionArea>
    <SelectItem />
  </Wrapper>
)

const Wrapper = styled.div`
  padding-top: 48px;
`
