import React from 'react'
import styled from 'styled-components'

type Props = {
  children: string | React.ReactElement[]
  needsToStopPropagation?: boolean
  clickHandler: () => void
}

export const FunctionLink: React.VFC<Props> = props => {
  const clickHandler = (e: React.MouseEvent) => {
    if (props.needsToStopPropagation) {
      e.stopPropagation()
    }
    e.preventDefault()
    props.clickHandler()
  }
  return <ReleaseLink {...props} onClick={clickHandler} />
}

const ReleaseLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  padding-right: 6px;
`
