import React from 'react'

import CommonFooter from '~/src/components/organisms/Footer'

type FooterProps = {
  isEditing: boolean
  onSubmit: () => void
}

export const Footer: React.VFC<FooterProps> = ({ isEditing, onSubmit }) => {
  return (
    <CommonFooter
      disableSubmitButton={!isEditing}
      noUseOptionButton={true}
      onSubmitButtonClick={onSubmit}
    />
  )
}
