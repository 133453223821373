import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import Modal from './Modal'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'

// FIXME: BatchEditFormModelを作って判定ロジックをモデルに移すこと
const applyButtonDisabledSelector = (state: AppState) => {
  const batchEditFormState =
    state.targetServingTimeSetting.targetServingTime.batchEditForm
  if (batchEditFormState == null) {
    return false
  }
  switch (batchEditFormState.which) {
    case 'noselect':
      return true
    case 'all': {
      const { modeId, noticeMinutes, alertMinutes } = batchEditFormState.all
      const noticeMinutesError = TargetServingTime.validateTime(
        modeId,
        noticeMinutes
      )
      const alertMinutesError = TargetServingTime.validateAlertTime(
        modeId,
        noticeMinutes,
        alertMinutes
      )
      return noticeMinutesError !== undefined || alertMinutesError !== undefined
    }
    case 'categories': {
      const errors = Object.values(batchEditFormState.categories)
        .map(category => {
          // @ts-ignore
          const { modeId, noticeMinutes, alertMinutes } = category
          const noticeMinutesError = TargetServingTime.validateTime(
            modeId,
            noticeMinutes
          )
          const alertMinutesError = TargetServingTime.validateAlertTime(
            modeId,
            noticeMinutes,
            alertMinutes
          )
          return [noticeMinutesError, alertMinutesError]
        })
        .flat()
      return errors.some(error => error !== undefined)
    }
    default:
      return false
  }
}

const Container = () => {
  const isOpen = useSelector(
    (state: AppState) =>
      state.targetServingTimeSetting.targetServingTime.batchEditForm !==
      undefined
  )
  const batchEditSelected =
    useSelector(
      (state: AppState) =>
        state.targetServingTimeSetting.targetServingTime.batchEditForm?.which
    ) || 'noselect'
  const applyButtonDisabled = useSelector(applyButtonDisabledSelector)
  const interactor = useInteractors().targetServingTimeInteractor
  return (
    <Modal
      isOpen={isOpen}
      interactor={interactor}
      batchEditSelected={batchEditSelected}
      applyButtonDisabled={applyButtonDisabled}
    />
  )
}

export default Container
