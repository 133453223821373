import {
  ENVIRONMENT_TYPE,
  ENVIRONMENT,
  LOCAL_REQUEST_TARGET
} from '~/src/const/environment'

const domain = document.domain || ''
const regexp = /[a-z0-9]*/g
const result = domain.match(regexp)
const SERVER_ENV = result[2] // dev2やdev10など
const CLIENT_ID = 'OES'

const getHandyUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return `https://${LOCAL_REQUEST_TARGET}.order.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://${SERVER_ENV}.order.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://order.airregi.jp'
    default:
      return ''
  }
}

const getSpoUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return 'https://cl.stg.spo.order.arg.x.recruit.co.jp'
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://cl.${SERVER_ENV}.spo.order.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://cl.spo.order.airregi.jp'
    default:
      return ''
  }
}

const getPlatformUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL: {
      const env = changeStagingToDev9(LOCAL_REQUEST_TARGET)
      return `https://${env}-shr-plf.arg.x.recruit.co.jp`
    }
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING: {
      const env = changeStagingToDev9(SERVER_ENV)
      return `https://${env}-shr-plf.arg.x.recruit.co.jp`
    }
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://connect.airregi.jp'
    default:
      return ''
  }
}

// 開発環境はdev4を固定で使う（他の環境にないことがあるため）
const getAirMarketUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return 'https://dev4-shr-mkt.arg.x.recruit.co.jp'
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return 'https://dev4-shr-mkt.arg.x.recruit.co.jp'
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://market.airregi.jp'
    default:
      return ''
  }
}

const getRbUrl = () => {
  const env = changeStagingToDev9(SERVER_ENV)
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return `https://${env}-rbd-cl.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://${env}-rbd-cl.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://restaurant-board.com'
    default:
      return ''
  }
}

const getRegiUrl = () => {
  const env = changeStagingToDev9(SERVER_ENV)
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return `https://${env}-shr-airregi.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://${env}-shr-airregi.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://airregi.jp'
    default:
      return ''
  }
}

const getTakeOutUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
      return 'https://cl.stg.spo.order.arg.x.recruit.co.jp'
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://cl.${SERVER_ENV}.spo.order.arg.x.recruit.co.jp`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://cl.spo.order.airregi.jp'
    default:
      return ''
  }
}

/**
 * 開発環境はdev6を固定で使う -> すいません。dev5とお伝えしてましたが、誤りでdev6環境が正しいです
 * https://rls-nb.slack.com/archives/C04SP453W94/p1725436932022589?thread_ts=1725434025.862899&cid=C04SP453W94
 */
const getMateUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      // TODO: 効果レポート案件後に本番維持環境に向ける https://rls-nb.slack.com/archives/C04SP453W94/p1725586351790349?thread_ts=1725506588.953999&cid=C04SP453W94
      return 'https://dev5.mcl.dev.airmate.jp'
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://airmate.jp'
    default:
      return ''
  }
}
/**
 * RSVアカウントを各面で発行するのが大変なため、開発環境ではdev2を固定で指定している
 * RSV上ではdev2だが、PLF上ではdev9扱い
 * https://rls-nb.slack.com/archives/C04SP4454K0/p1729500091284169?thread_ts=1729488347.208139&cid=C04SP4454K0
 */
const getReserveUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return `https://dev2-shr-rsv-web-cs.arg.x.recruit.co.jp/airord/calendar`
    case ENVIRONMENT_TYPE.PRODUCTION:
      return 'https://airrsv.net/airord/calendar?schdlId=s0000AA978'
    default:
      return ''
  }
}

const changeStagingToDev9 = target => {
  return target === 'stg' ? 'dev9' : target
}

const HANDY_URL = getHandyUrl()
const SPO_URL = getSpoUrl()
const PLATFORM_URL = getPlatformUrl()
const AIR_MARKET_URL = getAirMarketUrl()
const REGI_URL = getRegiUrl()
const RB_URL = getRbUrl()
const ORDER_FAQ = 'https://faq.order.airregi.jp/hc/ja'
const TAKEOUT_URL = getTakeOutUrl()
const MATE_URL = getMateUrl()
const AIR_RESERVE_URL = getReserveUrl()

export default {
  HANDY_URL,
  ORDER_FAQ,
  PLATFORM_URL,
  AIR_MARKET_URL,
  REGI_URL,
  RB_URL,
  SPO_URL,
  TAKEOUT_URL,
  MATE_URL,
  AIR_RESERVE_URL,

  // GETパラメータに"://"が含まれているとAWS WAFで検知されるため、"https://"を消す
  HANDY_LOGIN: redirectUri =>
    HANDY_URL +
    `/auth/login?redirectUri=${encodeURIComponent(
      redirectUri.replace(/^https:\/\//, '')
    )}`,
  HANDY_LOGOUT: HANDY_URL + '/auth/logout',

  SPO_CATEGORY: SPO_URL + '/category',
  SPO_AUTO_STOP_ORDER: SPO_URL + '/order_limit',
  TAKEOUT_SITE: TAKEOUT_URL + '/takeout_site',

  HODAI_FAQ: ORDER_FAQ + '/articles/360008346993',
  COURSE_FAQ: ORDER_FAQ + '/articles/360008246994',
  TOPPING_FAQ: ORDER_FAQ + '/articles/900005784583',
  KITCHEN_POSITION_FAQ: ORDER_FAQ + '/articles/900002974043',
  KITCHEN_PRINTER_FAQ: ORDER_FAQ + '/articles/360007602833',
  TARGET_SERVING_TIME_FAQ_FOR_PL: ORDER_FAQ + '/articles/900004258723',
  TARGET_SERVING_TIME_FAQ_FOR_KP: ORDER_FAQ + '/articles/360008469553',
  DISCOUNT_SURCHARGE_FAQ: ORDER_FAQ + '/articles/6855957068569',

  PLF_MY_ACCOUNT: redirectUri =>
    PLATFORM_URL +
    `/view/account/profile?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,
  PLF_STORE_INFORMATION: redirectUri =>
    PLATFORM_URL +
    `/view/store/information?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,
  PLF_SERVICE_LIST:
    PLATFORM_URL + `/view/mypage/serviceList?client_id=${CLIENT_ID}`,
  PLF_CONTRACT_INFO: redirectUri =>
    PLATFORM_URL +
    `/view/charge/contractInfo?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,
  PLF_STORE_LIST: redirectUri =>
    PLATFORM_URL +
    `/view/store/storeList?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,
  PLF_ACCOUNTS: redirectUri =>
    PLATFORM_URL +
    `/view/group/accounts?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,
  PLF_STAFF: redirectUri =>
    PLATFORM_URL +
    `/view/staff?client_id=${CLIENT_ID}&isPoPuP=1&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`,

  REGI_CATEGORY: REGI_URL + '/CLP/view/settingCategory',
  REGI_ITEM: REGI_URL + '/CLP/view/settingMenu',
  REGI_BULK_ITEM: REGI_URL + '/CLP/view/settingMenu/#/upload',
  REGI_REFERENCE_NUMBER: REGI_URL + '/CLP/view/settingKitchenMonitor',

  RB_TABLE: RB_URL + '/CLP/view/settingSeat',

  // 約款ページが本番環境しか存在しないため、domainが環境ごとに変わらない
  ORDER_KIYAKU: 'https://cdn.p.recruit.co.jp/terms/ord-t-1001/index.html',
  REGI_COMMON_TERMS: 'https://connect.airregi.jp/doc/common_terms.html'
}
