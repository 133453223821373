import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import VariationList from './VariationList'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import { AppState } from '~/src/redux/reducer'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => {
    const categoryIds: (string | undefined)[] = state.categories.map(
      c => c.categoryId
    )
    categoryIds.push(undefined) // カテゴリー未設定を対象に追加する
    return {
      items: categoryIds
        .map((categoryId?: string) =>
          categoryId === undefined
            ? state.items.filter(
                item =>
                  !state.categories.some(c => c.categoryId === item.categoryId)
              )
            : state.items.filter(item => item.categoryId === categoryId)
        )
        .map((items: CSVItem[]) =>
          items.filter((item: CSVItem) => item.hasVariationGroup())
        )
        .reduce((sum: CSVItem[], val: CSVItem[]) => sum.concat(val), [])
    }
  }
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}

const VariationListContainer = () => {
  const { items } = useVariationGroup()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedItemIds = useMemo(() => items, [...items])
  return <VariationList items={memoizedItemIds} />
}

export default VariationListContainer
