import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { TargetServingTimeState } from '~/src/redux/modules/TargetServingTime/types'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import { SimpleItem } from '~/src/redux/models/Item/Item'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import ItemRow from './ItemRow'
import { isKmPlanSelector } from '~/src/redux/selectors/storeSelectors'

type Props = {
  item: SimpleItem
}

const targetServingTimeState = (state: AppState) =>
  state.targetServingTimeSetting.targetServingTime

const targetServingTimeSelector = createSelector(
  targetServingTimeState,
  (_: AppState, props: Props) => props,
  (targetServingTimeState: TargetServingTimeState, props: Props) => {
    const { item } = props
    const targetServingTime: TargetServingTime =
      targetServingTimeState.targetServingTimeMap.get(item.itemId)!
    const estimatedTargetServingTime: EstimatedTargetServingTime =
      targetServingTimeState.estimatedTargetServingTimeMap.get(item.itemId) ||
      EstimatedTargetServingTime.EMPTY

    return {
      itemId: item.itemId,
      itemName: item.itemName,
      targetServingTime,
      estimatedTargetServingTime,
      isAutomaticSettingAvailable: useSelector(isKmPlanSelector)
    }
  }
)

const useTargetServingTime = (props: Props) => {
  return {
    ...useSelector((state: AppState) => targetServingTimeSelector(state, props))
  }
}

const useInteractor = () => ({
  interactor: useInteractors().targetServingTimeInteractor
})

const ItemRowContainer = (props: Props) => {
  return <ItemRow {...useInteractor()} {...useTargetServingTime(props)} />
}

export default ItemRowContainer
