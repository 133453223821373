import React from 'react'

import Footer from '~/src/components/organisms/Footer'
import Const from '~/src/const/'
const { Page } = Const

export const SeatSetListFooter = ({
  isEditing,
  onSubmit
}: {
  isEditing: boolean
  onSubmit: () => void
}) => {
  return (
    <Footer
      disableSubmitButton={!isEditing}
      optionButtonLink={Page.SEAT_SET.CREATE}
      optionButtonText="注文エリアを追加する"
      onSubmitButtonClick={onSubmit}
    />
  )
}
