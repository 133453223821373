import { Record } from 'immutable'

const PrinterRecord = Record({
  createdAt: 0,
  isDefault: false,
  isRingable: false,
  macAddress: '',
  name: '',
  paperWidthType: '',
  printerId: '',
  printerModelId: 0,
  version: 0
})

export default class Printer extends PrinterRecord {}
