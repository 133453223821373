import { Record, List, Map } from 'immutable'
import CSVSku from './CSVSku'
import CONSUMPTION_TAX_TYPE, {
  ConsumptionTaxType
} from '~/src/const/consumptionTaxType'
import VariationRetouch from './VariationRetouch'
import VariationGroup from './VariationGroup'
import Variation from './Variation'

interface CSVItemProps {
  itemId: string
  itemName: string
  categoryId?: string
  price?: number // SKU持ち商品の場合はundefined
  taxType: '内税' | '外税' | '非課税'
  consumptionTaxType?: ConsumptionTaxType // SKU持ち商品の場合はundefined
  isDisplayed: boolean
  skus: List<CSVSku>
  displayOrder: number
  rowData: string[]
  variationGroupId?: string
  variationRetouches: Map<string, VariationRetouch>
}

const CSVItemRecord = Record<CSVItemProps>({
  itemId: '',
  itemName: '',
  categoryId: undefined,
  price: undefined,
  taxType: '内税',
  consumptionTaxType: undefined,
  isDisplayed: true,
  skus: List(),
  displayOrder: 0,
  rowData: [],
  variationGroupId: undefined,
  variationRetouches: Map<string, VariationRetouch>()
})

class CSVItem extends CSVItemRecord implements CSVItemProps {
  hasSKU(): boolean {
    return this.skus.size > 0
  }

  shouldRetouch(variation: Variation): boolean {
    return (
      variation.inherit &&
      (this.hasSKU() ||
        this.consumptionTaxType?.ID === CONSUMPTION_TAX_TYPE.CHOICE.ID)
    )
  }

  setVariationGroupId(id?: string) {
    return this.set('variationGroupId', id)
  }

  disconnectVariationGroup() {
    return this.set('variationGroupId', undefined).set(
      'variationRetouches',
      Map<string, VariationRetouch>()
    )
  }

  hasVariationGroup(): boolean {
    return this.variationGroupId !== undefined
  }

  updateRetouch(retouch: VariationRetouch) {
    return this.set(
      'variationRetouches',
      this.variationRetouches.set(retouch.variationId, retouch)
    )
  }

  isValidVariations(variationGroups: VariationGroup[]): boolean {
    if (!this.variationGroupId) {
      return true
    }
    const variationGroup = variationGroups.find(
      vg => vg.variationGroupId === this.variationGroupId
    )
    return !!variationGroup?.variations.every(variation =>
      this.isValidVariation(variation)
    )
  }

  isValidVariation(variation: Variation): boolean {
    const retouch = this.variationRetouches.get(variation.variationId)
    if (retouch) {
      // 修正がある場合は、商品の既存設定によらず修正状態を評価する
      return retouch.isValid()
    } else {
      // 商品設定を継承する設定にも関わらずSKU商品の場合は継承すべき値が分からないため不正な状態
      // 修正を入力しない限りは不正な状態のまま
      return !this.shouldRetouch(variation)
    }
  }

  getConsumptionTaxType(variation: Variation) {
    const retouch = this.variationRetouches.get(variation.variationId)
    if (retouch) {
      return retouch.consumptionTaxType
    }
    if (!variation.inherit) {
      return variation.consumptionTaxType
    }
    return this.shouldRetouch(variation) ? undefined : this.consumptionTaxType
  }

  validateConsumptionTaxType(variation: Variation) {
    const retouch = this.variationRetouches.get(variation.variationId)
    return retouch
      ? retouch.validatesetConsumptionTaxType()
      : this.shouldRetouch(variation) && '選択必須'
  }

  getPrice(variation: Variation) {
    const retouch = this.variationRetouches.get(variation.variationId)
    if (retouch) {
      return retouch.price
    }
    if (!variation.inherit) {
      return variation.price?.toString()
    }
    return this.shouldRetouch(variation) ? '' : this.price?.toString()
  }

  validatePrice(variation: Variation) {
    const retouch = this.variationRetouches.get(variation.variationId)
    return retouch
      ? retouch.validatePrice()
      : this.shouldRetouch(variation)
      ? '入力必須'
      : undefined
  }

  compareTo(target: CSVItem): number {
    return this.displayOrder - target.displayOrder
  }

  getNameForDisplay(): string {
    return this.itemName + (this.isDisplayed ? '' : ' (非表示)')
  }

  getPriceForDisplay(): string {
    return '¥' + (this.price?.toLocaleString() || ' -')
  }

  applyVariationGroup(variationGroups: VariationGroup[]): CSVItem {
    const variationGroup = variationGroups.find(
      vg => vg.variationGroupId === this.variationGroupId
    )
    if (!variationGroup) {
      return this
    }
    const skus = variationGroup.variations.map(variation => {
      const retouch = this.variationRetouches.find(
        retouch => retouch.variationId === variation.variationId
      )
      return new CSVSku({
        skuId: '',
        skuName1: variation.skuName1 || '',
        skuName2: variation.skuName2 || '',
        price: retouch
          ? Number(retouch.price)
          : variation.inherit
          ? this.price
          : variation.price,
        taxType: this.taxType,
        consumptionTaxType: retouch
          ? retouch.consumptionTaxType!
          : variation.inherit
          ? this.consumptionTaxType!
          : variation.consumptionTaxType!,
        isDisplayed: this.isDisplayed,
        rowData: this.rowData
      })
    })
    return this.set('skus', skus)
  }
}

export default CSVItem
