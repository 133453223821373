import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  fetchResources,
  replacePrinterByItemSeatSets,
  clearPrinterByItemSeatSetsState
} from '~/src/redux/modules/PrintTarget/PrintTargetByItem'
import { clearPrinterSelectBoxState } from '~/src/redux/modules/PrintTarget/PrinterSelectBox'
import { clearUiState, openValidationErrorModal } from '~/src/redux/modules/UI'
import { setShouldForward } from '~/src/redux/modules/Forward'

export const useInitializeState = (categoryId: string) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearPrinterSelectBoxState())
    dispatch(clearPrinterByItemSeatSetsState())
    dispatch(fetchResources(categoryId))
  }, [dispatch, categoryId])
}
export const useSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(replacePrinterByItemSeatSets())
    dispatch(clearUiState())
  }, [dispatch])
}
export const useValidationError = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(openValidationErrorModal())
  }, [dispatch])
}
export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearPrinterSelectBoxState())
    dispatch(clearPrinterByItemSeatSetsState())
  }, [dispatch])
}
export const useCloseNotFoundErrorModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setShouldForward(true))
  }, [dispatch])
}
