import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from '@air-kit/air-kit'
import ModalFooter from './ModalFooter'
import ModalList from './ModalList'
import CSVItem from '~/src/redux/models/CSVItem/CSVItem'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import Const from '~/src/const'
const { Size } = Const

type Props = {
  interactor: VariationGroupInteractor
  isShowing: boolean
  item: CSVItem
}

const VariationGroupModal: React.FC<Props> = props => {
  const onCloseCb = useCallback(() => {
    props.interactor.closeVariationGroupModal()
  }, [props.interactor])
  if (!props.isShowing) {
    return null
  }
  const footer = {
    right: <ModalFooter />
  }
  return (
    <Adjuster>
      <Modal
        isScrollable
        title={`${props.item.itemName.slice(0, 10)}のバリエーション確認`}
        footer={footer}
        onClose={onCloseCb}
      >
        <ModalList />
      </Modal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`

export default React.memo(VariationGroupModal)
