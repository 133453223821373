import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { changeSelectedDeivceCount } from '~/src/redux/modules/DeviceCount/DeviceCount'

export const useSelectChanged = () => {
  const dispatch = useDispatch()
  return useCallback(
    (value: string) => {
      dispatch(changeSelectedDeivceCount(value))
    },
    [dispatch]
  )
}
