import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { postIfValid } from '~/src/redux/modules/KitchenPositionTarget/KitchenPositionTarget'

export const useHandleSubmitButtonClick = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(postIfValid())
  }, [dispatch])
}
