import { Map } from 'immutable'
import { createAction } from 'typesafe-actions'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import EstimatedTargetServingTime from '~/src/redux/models/TargetServingTime/EstimatedTargetServingTime'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import {
  FETCH_INITIAL_DATA_SUCCEEDED,
  CHANGE_CATEGORY,
  CHANGE_MODE_TO_AUTO,
  CHANGE_MODE_TO_MANUAL,
  CHANGE_MODE_TO_NON,
  CHANGE_NOTICE_MINUTES,
  CHANGE_ALERT_MINUTES,
  CLEAR_STATE,
  POST_TARGET_SERVING_TIMES_SUCCEEDED,
  CHANGE_ALL_ITEM_NOTICE_MINUTES,
  CHANGE_ALL_ITEM_ALERT_MINUTES,
  ACTIVATE_ALL_ITEM_BATCH_EDIT,
  DEACTIVATE_ALL_ITEM_BATCH_EDIT,
  CHANGE_CATEGORY_NOTICE_MINUTES,
  CHANGE_CATEGORY_ALERT_MINUTES,
  ACTIVATE_CATEGORY_BATCH_EDIT,
  DEACTIVATE_CATEGORY_BATCH_EDIT,
  CHANGE_ALL_ITEM_MODE,
  CHANGE_CATEGORY_MODE,
  OPEN_BATCH_EDIT_FORM_MODAL,
  CLOSE_BATCH_EDIT_FORM_MODAL,
  APPLY_ALL_ITEM_BATCH_EDIT,
  APPLY_CATEGORIES_BATCH_EDIT
} from './types'

export default {
  clearState: createAction(CLEAR_STATE)(),
  fetchInitialDateSucceeded: createAction(
    FETCH_INITIAL_DATA_SUCCEEDED,
    (
      categories: SimpleCategory[],
      targetServingTimeMap: Map<string, TargetServingTime>,
      estimatedTargetServingTimeMap: Map<string, EstimatedTargetServingTime>,
      lastCachedAt: string
    ) => ({
      categories,
      targetServingTimeMap,
      estimatedTargetServingTimeMap,
      lastCachedAt
    })
  )(),
  changeCategory: createAction(CHANGE_CATEGORY, (categoryId: string) => ({
    categoryId
  }))(),
  changeModeToAuto: createAction(CHANGE_MODE_TO_AUTO, (itemId: string) => ({
    itemId
  }))(),
  changeModeToManual: createAction(CHANGE_MODE_TO_MANUAL, (itemId: string) => ({
    itemId
  }))(),
  changeModeToNon: createAction(CHANGE_MODE_TO_NON, (itemId: string) => ({
    itemId
  }))(),
  changeNoticeMinutes: createAction(
    CHANGE_NOTICE_MINUTES,
    (itemId: string, noticeMinutes: any) => ({ itemId, noticeMinutes })
  )(),
  changeAlertMinutes: createAction(
    CHANGE_ALERT_MINUTES,
    (itemId: string, alertMinutes: any) => ({ itemId, alertMinutes })
  )(),
  postTargetServingTimesSucceeded: createAction(
    POST_TARGET_SERVING_TIMES_SUCCEEDED
  )(),
  changeAllItemNoticeMinutes: createAction(
    CHANGE_ALL_ITEM_NOTICE_MINUTES,
    (noticeMinutes: string) => ({ noticeMinutes })
  )(),
  changeAllItemAlertMinutes: createAction(
    CHANGE_ALL_ITEM_ALERT_MINUTES,
    (alertMinutes: string) => ({ alertMinutes })
  )(),
  activateAllItemBatchEdit: createAction(
    ACTIVATE_ALL_ITEM_BATCH_EDIT,
    (initialServingTimeModeId: string) => ({ initialServingTimeModeId })
  )(),
  deactivateAllItemBatchEdit: createAction(
    DEACTIVATE_ALL_ITEM_BATCH_EDIT,
    (initialServingTimeModeId: string) => ({ initialServingTimeModeId })
  )(),
  changeAllItemMode: createAction(CHANGE_ALL_ITEM_MODE, (modeId: string) => ({
    modeId
  }))(),
  changeCategoryNoticeMinutes: createAction(
    CHANGE_CATEGORY_NOTICE_MINUTES,
    (categoryId: string, noticeMinutes: string) => ({
      categoryId,
      noticeMinutes
    })
  )(),
  changeCategoryAlertMinutes: createAction(
    CHANGE_CATEGORY_ALERT_MINUTES,
    (categoryId: string, alertMinutes: string) => ({ categoryId, alertMinutes })
  )(),
  activateCategoryBatchEdit: createAction(
    ACTIVATE_CATEGORY_BATCH_EDIT,
    (categoryId: string, initialServingTimeModeId: string) => ({
      categoryId,
      initialServingTimeModeId
    })
  )(),
  deactivateCategoryBatchEdit: createAction(
    DEACTIVATE_CATEGORY_BATCH_EDIT,
    (categoryId: string, initialServingTimeModeId: string) => ({
      categoryId,
      initialServingTimeModeId
    })
  )(),
  changeCategoryMode: createAction(
    CHANGE_CATEGORY_MODE,
    (categoryId: string, modeId: string) => ({ categoryId, modeId })
  )(),
  openBatchEditFormModal: createAction(
    OPEN_BATCH_EDIT_FORM_MODAL,
    (initialServingTimeModeId: string) => ({ initialServingTimeModeId })
  )(),
  closeBatchEditFormModal: createAction(CLOSE_BATCH_EDIT_FORM_MODAL)(),
  applyAllItemBatchEdit: createAction(APPLY_ALL_ITEM_BATCH_EDIT)(),
  applyCategoriesBatchEdit: createAction(APPLY_CATEGORIES_BATCH_EDIT)()
}
