import React from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import EmptyState from '~/src/components/atoms/EmptyState'
import { Row } from './Row/Row'
import {
  HodaiNameColumn,
  CategoryColumn,
  LastOrderColumn,
  PriceColumn,
  ItemCountColumn,
  TrashColumn,
  SpaceColumn
} from './Column'
import { selectHodais } from '../../HodaiTop/selectors'

import Const from '~/src/const/'

const { Color } = Const

type Props = {}
export const InnerList: React.VFC<Props> = props => {
  const hodais = useSelector(selectHodais)
  return (
    <div>
      <TableHeader>
        <HodaiNameColumn>放題プラン名</HodaiNameColumn>
        <CategoryColumn>カテゴリー</CategoryColumn>
        <LastOrderColumn>L.O.時間</LastOrderColumn>
        <PriceColumn>価格</PriceColumn>
        <ItemCountColumn>商品数</ItemCountColumn>
        <TrashColumn>解除</TrashColumn>
        <SpaceColumn />
      </TableHeader>
      {hodais.map(hodai => (
        <Row key={`key-hodai-${hodai.hodaiId}`} hodai={hodai} />
      ))}
      {hodais.length === 0 && (
        <EmptyStateWrapper>
          <EmptyState>
            放題プランが登録されていません。
            <br />
            左下のボタンから登録できます。
          </EmptyState>
        </EmptyStateWrapper>
      )}
    </div>
  )
}

const TableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  position: relative;
  padding: 7px 0;
`

const EmptyStateWrapper = styled.div`
  padding-top: 28px;
`
