import React from 'react'
import Variation from './Variation'
import useInteractors from '~/src/hooks/useInteractors'
import { VariationEditor } from '~/src/redux/models/CSVItem/Variation'

const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

type Props = {
  index: number
  disabled: boolean
  variation?: VariationEditor
}

const VariationContainer = (props: Props) => {
  return <Variation {...props} {...useInteractor()} />
}

export default VariationContainer
