// eslint-disable-next-line camelcase
const YYYY_MM_DD_HH_mm_ss = 'YYYY/MM/DD HH:mm:ss'
const YYYY_MM_DD = 'YYYY/MM/DD'
const YYYY_MM_JP = 'YYYY年M月分'

export const format = {
  YYYY_MM_DD_HH_mm_ss,
  YYYY_MM_DD,
  YYYY_MM_JP
}
