import React from 'react'
import ForwardErrorModal from '~/src/components/organisms/Modal/ErrorModal/Forward'
import Const from '~/src/const'

const { Page } = Const

const GrantErrorModal = () => {
  return (
    <ForwardErrorModal
      to={Page.HOME}
      title="編集権限がありません"
      message="管理者権限があるアカウントでログインしてください。"
    />
  )
}

export default GrantErrorModal
