import React from 'react'

import { SeatSetColumn } from '~/src/components/pages/PrintTarget/Column'
import Multiselect from '~/src/components/atoms/Multiselect'
import { selectPrinterSelectBoxesState } from './selectors'

import Const from '~/src/const/'
import { useSelector } from 'react-redux'
import { useSelectChange } from './hooks'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'
import { PrinterByItemSeatSet, PrintTargetItem, Printer } from '../../types'
import { PrinterSelectBoxValues } from '~/src/redux/modules/PrintTarget/types'
const { UndefinedId } = Const

type Props = {
  seatSets: SeatSet[]
  printers: Printer[]
  item: PrintTargetItem
  printerByItemSeatSets: PrinterByItemSeatSet[]
}

export const PrinterSelectBoxes: React.VFC<Props> = props => {
  const { seatSets, printers, item, printerByItemSeatSets } = props
  const { isInitialized } = useSelector(selectPrinterSelectBoxesState)
  const selectChange = useSelectChange()

  const printerOptions = printers.map(printer => ({
    id: printer.printerId,
    label: printer.name,
    excludeOthers: printer.printerId === UndefinedId.PRINTER_ID
  }))

  const handleSelectChange = (
    values: PrinterSelectBoxValues[],
    itemId: string,
    seatSetId: string
  ): void => {
    // ここに来る時点では、valuesは配列になっている前提
    const sentValues = []
    values.forEach(value =>
      sentValues.push({
        itemId: itemId,
        seatSetId: seatSetId,
        printerId: value
      })
    )
    // see: https://ghe.misosiru.io/air-oes/airoes-frontend/pull/352
    if (!sentValues.length) {
      sentValues.push({
        itemId: itemId,
        seatSetId: seatSetId,
        printerId: ''
      })
    }
    selectChange(sentValues)
  }

  return (
    <>
      {seatSets.map(seatSet => (
        <SeatSetColumn key={`${item.itemId}-${seatSet.seatSetId}`}>
          {item.isItemSeatSetSetting && isInitialized && (
            <Multiselect
              id={`${item.itemId}-${seatSet.seatSetId}`}
              name={`${item.itemId}-${seatSet.seatSetId}`}
              placeholder="選択してください"
              options={printerOptions}
              values={filterPrinterIds(printerByItemSeatSets, item, seatSet)}
              handleSelectChange={values =>
                handleSelectChange(values, item.itemId, seatSet.seatSetId)
              }
            />
          )}
        </SeatSetColumn>
      ))}
    </>
  )
}

/**
 * 引数のカテゴリー・席群で選択されているカテゴリー席群別印刷先プリンターID(配列)を返却します。
 */
const filterPrinterIds = (
  printerByItemSeatSets: PrinterByItemSeatSet[],
  item: PrintTargetItem,
  seatSet: SeatSet
) => {
  return printerByItemSeatSets
    .filter(
      printerByItemSeatSet =>
        printerByItemSeatSet.itemId === item.itemId &&
        printerByItemSeatSet.seatSetId === seatSet.seatSetId
    )
    .map(printerByItemSeatSet => printerByItemSeatSet.printerId)
}
