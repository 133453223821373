import { Record } from 'immutable'

interface SkuProps {
  cost?: number
  deletedAt?: number
  displayOrder: number
  itemId: string
  price: number
  skuAxis1Name: string
  skuAxis2Name?: string
  skuId: string
}

const SkuRecord = Record<SkuProps>({
  cost: undefined,
  deletedAt: undefined,
  displayOrder: 0,
  itemId: '',
  price: 0,
  skuAxis1Name: '',
  skuAxis2Name: undefined,
  skuId: ''
})

export default class Sku extends SkuRecord implements SkuProps {}
