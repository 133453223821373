import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox } from '@air-kit/air-kit'
import {
  TableRow,
  ItemNameColumn,
  SettingMethodSelectColumn,
  NoticeSecondColumn,
  AlertSecondColumn,
  CheckBoxColumn
} from '../ItemList/Common/TableParts'
import SelectBox from '~/src/components/atoms/SelectBox'
import { ValidatableInput } from '~/src/components/molecules/ValidatableInput'
import Const from '~/src/const'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import TargetServingTime from '~/src/redux/models/TargetServingTime/TargetServingTime'
import ServingTimeModes, {
  servingTimeModeOptions
} from '~/src/const/serving-time-mode'

const { Color } = Const

type Props = {
  isDisabled: boolean
  isChecked: boolean
  modeId: string
  noticeMinutes: string
  alertMinutes: string
  interactor: TargetServingTimeInteractor
  isAutomaticSettingAvailable: boolean
}

const AllItemBatchEditForm: React.FC<Props> = ({
  isDisabled,
  isChecked,
  modeId,
  noticeMinutes,
  alertMinutes,
  interactor,
  isAutomaticSettingAvailable
}) => {
  const handleModeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      interactor.changeAllItemMode(e.target.value),
    [interactor]
  )
  const handleNoticeMinutesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      interactor.changeAllItemNoticeMinutes(e.target.value),
    [interactor]
  )
  const handleAlertMinutesChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      interactor.changeAllItemAlertMinutes(e.target.value),
    [interactor]
  )
  const handleCheckboxClick = useCallback(
    () =>
      isChecked
        ? interactor.deactivateAllItemBatchEdit(
            isAutomaticSettingAvailable
              ? ServingTimeModes.AUTO.ID
              : ServingTimeModes.MANUAL.ID
          )
        : interactor.activateAllItemBatchEdit(
            isAutomaticSettingAvailable
              ? ServingTimeModes.AUTO.ID
              : ServingTimeModes.MANUAL.ID
          ),
    [isChecked, interactor, isAutomaticSettingAvailable]
  )

  const options = servingTimeModeOptions(isAutomaticSettingAvailable)

  return (
    <TableRow>
      <CheckBoxColumn>
        <Checkbox
          isChecked={isChecked}
          isDisabled={isDisabled}
          onClick={handleCheckboxClick}
        />
      </CheckBoxColumn>
      <ItemNameColumn>すべての商品</ItemNameColumn>
      <SettingMethodSelectColumn>
        <SelectBox
          name="setting-method-select"
          options={options}
          selectedId={modeId}
          handleSelectChange={handleModeChange}
          disabled={!isChecked || isDisabled}
        />
      </SettingMethodSelectColumn>
      {modeId === ServingTimeModes.MANUAL.ID && (
        <>
          <NoticeSecondColumn>
            <Input>
              <ValidatableInput
                id="notice-all"
                type="text"
                maxLength={3}
                value={noticeMinutes}
                align="right"
                onChange={handleNoticeMinutesChange}
                error={TargetServingTime.validateTime(modeId, noticeMinutes)}
                disabled={!isChecked || isDisabled}
              />
              <Text>分</Text>
            </Input>
          </NoticeSecondColumn>
          <AlertSecondColumn>
            <Input>
              <ValidatableInput
                id="alert-all"
                type="text"
                maxLength={3}
                value={alertMinutes}
                align="right"
                onChange={handleAlertMinutesChange}
                error={TargetServingTime.validateAlertTime(
                  modeId,
                  noticeMinutes,
                  alertMinutes
                )}
                disabled={!isChecked || isDisabled}
              />
              <Text>分</Text>
            </Input>
          </AlertSecondColumn>
        </>
      )}
      {modeId === ServingTimeModes.AUTO.ID && (
        <>
          <NoticeSecondColumn>
            <Text disabled={!isChecked}>商品別に設定</Text>
          </NoticeSecondColumn>
          <AlertSecondColumn>
            <Text disabled={!isChecked}>商品別に設定</Text>
          </AlertSecondColumn>
        </>
      )}
      {modeId === ServingTimeModes.NON.ID && (
        <>
          <NoticeSecondColumn>
            <Text>-分-秒</Text>
          </NoticeSecondColumn>
          <AlertSecondColumn>
            <Text>-分-秒</Text>
          </AlertSecondColumn>
        </>
      )}
    </TableRow>
  )
}

const Input = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Text = styled.div<{ disabled?: boolean }>`
  flex: 0 0 20px;
  padding-left: 4px;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  color: ${props =>
    props.disabled ? Color.LIGHT_GRAY_3 : Color.VERY_DARK_GRAY};
`

export default React.memo(AllItemBatchEditForm)
