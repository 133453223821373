import { ActionType, getType } from 'typesafe-actions'
import Const from '~/src/const'
import { actions } from './actions'
import { KmSettingState } from './types'

const { OrderTicketSortType } = Const

const initialState: KmSettingState = {
  isCookingNoticeEnabled: false,
  orderTicketSortTypeId: OrderTicketSortType.BY_NOTICE_SECOND.ID
}

export const kmSettings = (
  state: KmSettingState = initialState,
  action: ActionType<typeof actions>
): KmSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      const setting = action.payload.kmSettingApiResponse.setting
      return {
        ...state,
        isCookingNoticeEnabled: setting.isCookingNoticeEnabled,
        orderTicketSortTypeId: setting.orderTicketSortType
      }
    }
    case getType(actions.toggleCookingNoticeEnabled): {
      return {
        ...state,
        isCookingNoticeEnabled: !state.isCookingNoticeEnabled
      }
    }
    case getType(actions.selectOrderTicketSortType): {
      return {
        ...state,
        orderTicketSortTypeId: action.payload.selectedOrderTicketSortTypeId
      }
    }
    default:
      return state
  }
}
