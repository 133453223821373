/**
 * undefinedとなりうる値の擬似値定義クラス
 * inputのvalueに設定する値が、undefinedの場合に上手く動かないことがあるため
 * 擬似値を定義し使い回すため値を定義するためのコンポーネント
 **/

export default {
  SEAT_SET_ID: 'UNDEFINED_SEAT_SET_ID',
  PRINTER_ID: 'UNPRINT_PRINTER_ID',
  SELECTBOX_ID: 'UNDEFINED_SELECTBOX_ID',
  HODAI_ID: 'UNDEFINED_HODAI_ID',
  ITEM_ID: 'UNDEFINED_ITEM_ID',
  ITEM_NAME: 'UNDEFINED_ITEM_NAME'
}
