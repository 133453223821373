import { ENVIRONMENT_TYPE, ENVIRONMENT } from '~/src/const/environment'
import { Koruli } from '~/src/const/koruli'

export const getKoruliUrl = () => {
  switch (ENVIRONMENT) {
    case ENVIRONMENT_TYPE.LOCAL:
    case ENVIRONMENT_TYPE.DEVELOPMENT:
    case ENVIRONMENT_TYPE.STAGING:
      return (
        Koruli.URL.DEVELOPMENT +
        '?f=' +
        Koruli.AD_DISTRIBUTION_FRAME +
        '&m=' +
        Koruli.NUMBER_OF_ADS +
        '&ex=' +
        APP.storeId
      )

    case ENVIRONMENT_TYPE.PRODUCTION:
      return (
        Koruli.URL.PRODUCTION +
        '?f=' +
        Koruli.AD_DISTRIBUTION_FRAME +
        '&m=' +
        Koruli.NUMBER_OF_ADS +
        '&ex=' +
        APP.storeId
      )

    default:
      return ''
  }
}
