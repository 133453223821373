import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
const { Color, Size } = Const

const Discription: React.FC = () => {
  return (
    <Wrapper>
      <Text>
        ※Airレジの商品設定でバリエーションが設定されている商品に、一括登録するとバリエーションは上書きされます。
      </Text>
      <Text>
        ※レストランボードのコース設定から登録した商品にバリエーションを登録することはできません。
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
  padding-bottom: 16px;
`

const Text = styled.p`
  line-height: 20px;
  margin: 0;
  padding-bottom: 8px;

  :last-child {
    padding-bottom: 0;
  }
`

export default Discription
