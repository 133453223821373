import * as React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import SpoUpSellButtonSvg from '~/assets/images/SpoUpSell/spo_up_sell_banner.svg'
import { useHistory } from 'react-router'
import { datadogLogs } from '@datadog/browser-logs'
import { Datadog } from '~/src/const/datadog'

const { Page } = Const

export const SpoUpSellButton: React.VFC = () => {
  const history = useHistory()
  const onClick = () => {
    datadogLogs.logger.info(
      Datadog.BROWSER_LOG_MESSAGE.SPO_UP_SELL_FROM_BANNER.MESSAGE,
      {
        event: Datadog.BROWSER_LOG_MESSAGE.SPO_UP_SELL_FROM_BANNER.EVENT
      }
    )
    history.push(Page.SPO_UP_SELL)
  }

  return (
    <StyleButton onClick={onClick}>
      <SpoUpSellButtonSvg />
    </StyleButton>
  )
}

const StyleButton = styled.button`
  padding: 0px;
  display: flex;
  margin: auto;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  background: none;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.7;
  }
`
