import { createAction } from 'typesafe-actions'
import { ApiResponse } from '~/src/api/types'
import { HandySettingApiResponse } from '~/src/api/handy/settings/types'
import { CommonSettingApiResponse } from '~/src/api/common/settings/types'
import {
  FETCH_HANDY_INITIAL_DATA_SUCCEEDED,
  FETCH_COMMON_INITIAL_DATA_SUCCEEDED,
  FETCH_INITIAL_DATA_FINISHED,
  CHANGE_IS_SALES_DISPLAYED,
  CHANGE_TAX_DISPLAY_TYPE,
  CHANGE_SHOULD_AUTO_APPLY_SETTINGS,
  UPDATE_HANDY_SETTING_SUCCESS,
  UPDATE_COMMON_SETTING_SUCCESS,
  CLEAR_STATE
} from './types'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  fetchHandyInitialDataSucceeded: createAction(
    FETCH_HANDY_INITIAL_DATA_SUCCEEDED,
    (setting: ApiResponse<HandySettingApiResponse>) => ({ setting })
  )(),
  fetchCommonInitialDataSucceeded: createAction(
    FETCH_COMMON_INITIAL_DATA_SUCCEEDED,
    (setting: ApiResponse<CommonSettingApiResponse>) => ({ setting })
  )(),
  fetchInitialDataFinished: createAction(FETCH_INITIAL_DATA_FINISHED)(),
  changeIsSalesDisplayed: createAction(
    CHANGE_IS_SALES_DISPLAYED,
    (isSalesDisplayed: boolean) => ({ isSalesDisplayed })
  )(),
  changeTaxDisplayType: createAction(
    CHANGE_TAX_DISPLAY_TYPE,
    (taxDisplayTypeId: string) => ({ taxDisplayTypeId })
  )(),
  changeShouldAutoApplySettings: createAction(
    CHANGE_SHOULD_AUTO_APPLY_SETTINGS,
    (shouldAutoApplySettings: boolean) => ({ shouldAutoApplySettings })
  )(),
  updateHandySettingSuccess: createAction(
    UPDATE_HANDY_SETTING_SUCCESS,
    (setting: ApiResponse<HandySettingApiResponse>) => ({ setting })
  )(),
  updateCommonSettingSuccess: createAction(
    UPDATE_COMMON_SETTING_SUCCESS,
    (setting: ApiResponse<CommonSettingApiResponse>) => ({ setting })
  )()
}
