import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { StepBadge } from '~/src/components/atoms/StepBadge'
import { SelectItemArea } from '~/src/components/organisms/SelectItemArea/SelectItemArea'
import { actions } from './actions'
import {
  Attention,
  StepTitleArea,
  StepTitle,
  DescriptionArea,
  Description
} from './Common/Parts'
import { selectSelectedHodai } from './selectors'

type Props = {
  hasWarning: boolean
}

export const Step3: React.VFC<Props> = props => {
  const selectedHodai = useSelector(selectSelectedHodai)
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(actions.openHodaiSelect())
  }, [dispatch])
  return (
    <Wrapper>
      <StepTitleArea>
        <StepBadge stepNum={3} />
        <StepTitle>放題プランの設定</StepTitle>
      </StepTitleArea>
      <DescriptionArea>
        <Description>放題プラン付きコースの場合に設定します。</Description>
        <Attention>※放題プランの価格はコース価格に加算されません。</Attention>
      </DescriptionArea>
      <SelectItemArea
        onClick={handleClick}
        selectedItemName={selectedHodai && selectedHodai.itemName}
        shouldShowButton={true} // 常に表示
        hasWarning={props.hasWarning}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 48px;
  padding-bottom: 48px;
`
