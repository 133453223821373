import React from 'react'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { selectError } from './selectors'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { URL, Page } = Const

export const Messages: React.VFC = () => {
  const {
    hasDeletedCourse,
    hasDeletedCourseDetail,
    hasEmptyCategoryItem,
    hasEmptyCourse,
    hasHodaiError,
    hasSkuCourse,
    hasDeletedCategory
  } = useSelector(selectError)

  const warningMessageList = []
  const cautionMessageList = []

  if (hasDeletedCourse) {
    warningMessageList.push(
      '削除済みのコースがあります。削除済みのコースは編集できません。解除してください。'
    )
  }

  if (hasDeletedCourseDetail) {
    warningMessageList.push(
      'コース内容に削除された商品が含まれています。コース内容を確認してください。'
    )
  }

  if (hasEmptyCategoryItem) {
    warningMessageList.push(
      'コース内容にカテゴリーが未設定の商品が含まれています。コース内容を確認してください。'
    )
  }

  if (hasHodaiError) {
    warningMessageList.push(
      <>
        コース内容に設定エラーがある放題プランが含まれています。
        <StyledLink to={Page.HODAI.LIST}>放題プランページ</StyledLink>
        で内容を確認してください。または選択する放題プランを変更してください。
      </>
    )
  }

  if (hasSkuCourse) {
    warningMessageList.push(
      <>
        バリエーションが設定されているコースがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  if (hasDeletedCategory) {
    warningMessageList.push(
      <>
        カテゴリーが未設定のコースがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  if (hasEmptyCourse) {
    cautionMessageList.push('コース内容が未設定のコースがあります。')
  }

  return (
    ((warningMessageList.length !== 0 || cautionMessageList.length !== 0) && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
        <Message type="caution" messageList={cautionMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const StyledLink = styled(Link)`
  vertical-align: middle;
`

const Wrapper = styled.div`
  padding-bottom: 16px;
`
