const trackPageChange = path => {
  const pageType = path === '/' ? 'errorPage' : ''
  window.sc_data = {
    events: '',
    prop11: APP.storeId
  }
  window.sc_page_data = {
    event: '',
    // パスをサイカタ用に整える(ex. '/foo/:id/bar_baz' => 'foo:id:bar_baz')
    pageName: path.replace(/:/g, '').slice(1).replace(/\//g, ':'),
    channel: 'handy',
    pageType: pageType
  }
  // _satelliteのロードが遅延した場合に undefined になるので、白画面にならないように対策
  window._satellite && window._satellite.track('page_change')
}

const trackClickLink = (linkname, lid) => {
  window.sc_data = {
    events: '',
    prop11: APP.storeId
  }
  window.sc_click_data = {
    events: '',
    linkname: linkname,
    lid: lid
  }
  // _satelliteのロードが遅延した場合に undefined になるので、白画面にならないように対策
  window._satellite && window._satellite.track('click_link')
}

export default {
  trackPageChange,
  trackClickLink
}
