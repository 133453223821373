import React from 'react'
import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

import Const from '~/src/const'

const { Size } = Const

type Props = {
  children?: React.ReactNode
}

type TrashBoxProps = {
  onClick?: () => void
  className?: string
  children: React.ReactNode
}

export const ItemNameColumn: React.VFC<Props> = props => {
  return <ItemNameColumnStyle>{props.children}</ItemNameColumnStyle>
}

export const TrashBoxColumn: React.VFC<TrashBoxProps> = props => {
  const { onClick, className } = props
  return (
    <TrashBoxColumnStyle onClick={onClick} className={className}>
      {props.children}
    </TrashBoxColumnStyle>
  )
}

export const NoHandleColumn: React.VFC<Props> = props => {
  return <NoHandleColumnStyle>{props.children}</NoHandleColumnStyle>
}

export const HandleColumn: React.VFC<Props> = props => {
  return <HandleColumnStyle>{props.children}</HandleColumnStyle>
}

const ItemNameColumnStyle = styled(TableColumn)`
  text-align: left;
  font-size: ${Size.FONT.LARGE}px;
  padding: 0 6px;
  word-wrap: break-word;
`

const TrashBoxColumnStyle = styled(TableColumn)`
  width: 50px;
  font-weight: normal;
  font-size: ${Size.FONT.LARGE}px;
  padding: 0;
`

const NoHandleColumnStyle = styled(TableColumn)`
  width: 22px;
  padding: 8px 12px;
`

const HandleColumnStyle = styled(TableColumn)`
  cursor: move;
  width: 32px;
  padding: 0;
`
