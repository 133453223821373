import { Record } from 'immutable'
import CONSUMPTION_TAX_TYPE, {
  ConsumptionTaxType
} from '~/src/const/consumptionTaxType'

interface CSVSkuProps {
  skuId: string
  skuName1: string
  skuName2?: string
  price?: number
  taxType: '内税' | '外税' | '非課税'
  consumptionTaxType: ConsumptionTaxType
  isDisplayed: boolean
  rowData: string[]
}

const CSVSkuRecord = Record<CSVSkuProps>({
  skuId: '',
  skuName1: '',
  skuName2: undefined,
  price: undefined,
  taxType: '内税',
  consumptionTaxType: CONSUMPTION_TAX_TYPE.STANDARD,
  isDisplayed: true,
  rowData: []
})

class CSVSku extends CSVSkuRecord implements CSVSkuProps {
  constructor(sku: CSVSkuProps) {
    super(sku)
    return this
  }
}

export default CSVSku
