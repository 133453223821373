import React from 'react'

import PageTemplate from '~/src/components/templates/PageTemplate'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Const from '~/src/const'
const { Page, Weight } = Const

export default () => {
  return (
    <PageTemplate breadcrumbs={[{ title: '' }]}>
      <H2>指定されたURLは存在しません。</H2>
      <Main>URLをお確かめの上、正しいURLでアクセスして下さい。</Main>
      <br />
      <LinkWrapper>
        <StyledLink to={Page.HOME}>TOPへ戻る</StyledLink>
      </LinkWrapper>
    </PageTemplate>
  )
}

const LinkWrapper = styled.div`
  text-align: center;
`

const StyledLink = styled(Link)``

const Main = styled.main`
  text-align: center;
`

const H2 = styled.h2`
  font-size: 20px;
  font-weight: ${Weight.BOLD};
  text-align: center;
  line-height: 60px;
`
