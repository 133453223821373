import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  onClick: () => void
  children: React.ReactNode
}

export const FunctionLink: React.VFC<Props> = props => {
  const onLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    props.onClick()
  }
  return (
    <ReleaseLink
      className={props.className}
      onClick={(e: React.MouseEvent<HTMLElement>) => onLinkClick(e)}
    >
      {props.children}
    </ReleaseLink>
  )
}

const ReleaseLink = styled.a`
  cursor: pointer;
  font-weight: normal;
  padding-right: 6px;
`
