// 税表示タイプ

export default {
  INCLUDED: {
    ID: '0',
    TEXT: '税込'
  },
  EXCLUDED: {
    ID: '1',
    TEXT: '税抜'
  }
}
