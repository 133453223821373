import React, { useEffect } from 'react'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

interface Props {
  interactor: VariationGroupInteractor
}

const VariationGroupState: React.FC<Props> = props => {
  useEffect(() => {
    return () => {
      props.interactor.clearState()
    }
  }, [props.interactor])
  return null
}

export default VariationGroupState
