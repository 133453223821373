import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearDeleteTargetDevice,
  postDeleteDevice
} from '~/src/redux/modules/RegisteredDevice/RegisteredDevice'

export const useClearDeleteTargetDevice = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearDeleteTargetDevice())
  }, [dispatch])
}

export const useDeleteDevice = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(postDeleteDevice())
  }, [dispatch])
}
