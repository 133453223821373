import React, { useEffect } from 'react'
import styled from 'styled-components'
import { AppState } from '~/src/redux/reducer'
import CategorySelectArea from '../CategorySelectArea'
import PageTemplate from '~/src/components/templates/PageTemplate'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import ItemList from '../ItemList'
import Footer from '../Footer'
import Modal from '../Modal'
import { DisplayOptionSetting } from '../DisplayOptionSetting'
import { useSelector } from 'react-redux'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'
import { useHandleInitialize, useHandleDeinit } from './hooks'
import useInteractors from '~/src/hooks/useInteractors'
import { isKpPlanSelector } from '~/src/redux/selectors/storeSelectors'

const { URL, Color } = Const

export const TargetServingTime: React.VFC = () => {
  const isEdited = useSelector(
    (state: AppState) =>
      state.targetServingTimeSetting.targetServingTime.isEdited
  )

  const isSplashShowing = useSelector(
    (state: AppState) =>
      !(state.api.kmSetting.fetched || state.api.kmSetting.failed)
  )
  const isKpPlan = useSelector(isKpPlanSelector)
  const faqUrl = isKpPlan
    ? URL.TARGET_SERVING_TIME_FAQ_FOR_KP
    : URL.TARGET_SERVING_TIME_FAQ_FOR_PL
  const faqLinkText = isKpPlan
    ? '目標提供時間の設定（プリンタープラン向け）'
    : '目標提供時間の設定（ペーパーレスプラン向け）'

  const handleInitialize = useHandleInitialize(
    useInteractors().targetServingTimeInteractor
  )
  const handleDeinit = useHandleDeinit()
  useEffect(() => {
    handleInitialize()
    return handleDeinit
  }, [handleInitialize, handleDeinit])

  const breadcrumbs = [
    {
      title: '目標提供時間'
    }
  ]
  const description = isKpPlan
    ? [
        '提供遅れをお知らせする時間を設定できます。注意時間超過で黄色、警告時間超過で赤色の目印を表示し、提供遅れに気づきやすくすることができます。'
      ]
    : [
        '提供遅れをお知らせする時間を設定できます。ハンディでは注意時間超過で黄色、警告時間超過で赤色の目印を表示し、キッチンモニターでは注意時間超過で黄色の着手アラートを表示することができます。'
      ]

  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      footer={<Footer />}
      modal={
        <>
          <Modal />
          <DismissPrompt enabled={isEdited} />
        </>
      }
      displayKM
      displayKMOnly
      displayKP
      isSplashShowing={isSplashShowing}
    >
      <TargetServingTimeFAQ>
        ※目標提供時間について、詳しくは
        <InlineLink href={faqUrl} target="_blank">
          {faqLinkText}
          <LinkIcon color={Color.STRONG_BLUE} />
        </InlineLink>
        をご覧ください。
      </TargetServingTimeFAQ>
      <DisplayOptionSetting />
      <CategorySelectArea />
      <ItemList />
    </PageTemplate>
  )
}

const TargetServingTimeFAQ = styled.div`
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 20px;
`

const LinkIcon = styled(ExternalLinkIcon)`
  width: 18px;
  padding: 0 3px;
`
