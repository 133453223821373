import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import Icon from '~/src/components/atoms/Icon'
import Radio from '~/src/components/atoms/Radio'
import Toggle from '~/src/components/atoms/Toggle'
import {
  RadioButtonColumn,
  DeleteColumn,
  KitchenPositionNameColumn,
  SeatSetUsableColumn
} from '../Column'
import {
  useHandleChangeRadioButton,
  useHandleChangeInput,
  useHandleChangeSeatSetToggle,
  useHandleDeleteKitchenPosition,
  useHandleFocus
} from './hooks'
import { selectKitchenPositionSettingListRow } from './selectors'

import { KitchenPositionNameInput } from '../Common'
import { useSelector } from 'react-redux'
import KitchenPosition from '~/src/redux/models/KitchenPositionSetting/KitchenPosition'

export const Row = ({
  kitchenPosition,
  index,
  isLast
}: {
  kitchenPosition: KitchenPosition
  index: number
  isLast: boolean
}) => {
  const { isSeatSetUsable, isDefault, kitchenPositionName, validateName } =
    useSelector(selectKitchenPositionSettingListRow(kitchenPosition))

  const handleChangeRadioButton = useHandleChangeRadioButton()
  const handleChangeInput = useHandleChangeInput(index)
  const handleChangeSeatSetToggle = useHandleChangeSeatSetToggle(
    index,
    !isSeatSetUsable
  )
  const handleDeleteKitchenPosition = useHandleDeleteKitchenPosition(
    index,
    isLast
  )
  const handleFocus = useHandleFocus(isLast)

  return (
    <TableRow>
      <RadioButtonColumn>
        {!isLast && (
          <RadioWrapper>
            <Radio
              name="kitchen-position-radio"
              value={index.toString()}
              checked={isDefault}
              onChange={handleChangeRadioButton}
            />
          </RadioWrapper>
        )}
      </RadioButtonColumn>
      <KitchenPositionNameColumn>
        <KitchenPositionNameInput
          type="text"
          name="kitchen-position-name"
          value={kitchenPositionName}
          align="left"
          placeholder="焼き場、ドリンカー など"
          onChange={handleChangeInput}
          onFocus={handleFocus}
          error={validateName}
        />
      </KitchenPositionNameColumn>
      <SeatSetUsableColumn>
        {!isLast && (
          <Toggle
            onToggle={handleChangeSeatSetToggle}
            checked={isSeatSetUsable}
            disabled={false}
            name={`toggle_${index}`}
          />
        )}
      </SeatSetUsableColumn>
      <DeleteColumn pointer={!isLast} onClick={handleDeleteKitchenPosition}>
        {!isLast && <DeleteIcon />}
      </DeleteColumn>
    </TableRow>
  )
}

const RadioWrapper = styled.label`
  display: block;
  cursor: pointer;
`

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``
