import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'

const { Color, Size } = Const

const EmptyState: React.FC = props => {
  return <Wrapper>{props.children}</Wrapper>
}

const Wrapper = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.BASE}px;
  line-height: 24px;
  text-align: center;
  padding: 36px;
`

export default EmptyState
