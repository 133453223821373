import { AppState } from '~/src/redux/reducer'
import { checkHodai, HodaiError } from '~/src/util/ErrorChecker'

export const selectError = (itemId?: string) => (state: AppState) => {
  if (!itemId) {
    return {
      isDeletedHodaiPlanItemError: false,
      isEmptyHodai: false,
      hasSku: false,
      hasDeletedHodaiPlanCategoryError: false
    }
  }
  const hodaiSettingError: HodaiError = checkHodai(state.api.items.response)
  return {
    isDeletedHodaiPlanItemError:
      hodaiSettingError.itemIdsHaveDeletedHodaiPlanItemError.some(
        e => e === itemId
      ),
    isEmptyHodai: !state.hodaiSetting.categories.some(category =>
      category.items.some(item => item.isSelected)
    ),
    hasSku: hodaiSettingError.itemIdsHaveVariationHodaiPlanError.some(
      e => e === itemId
    ),
    hasDeletedHodaiPlanCategoryError:
      hodaiSettingError.itemIdsHaveDeletedHodaiPlanCategoryError.some(
        e => e === itemId
      )
  }
}
