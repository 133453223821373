import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from '../actions'

export const useSelectCategoryItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (categoryId, itemId) => {
      dispatch(actions.selectCategoryItem({ categoryId, itemId }))
    },
    [dispatch]
  )
}

export const useSelectCategory = () => {
  const dispatch = useDispatch()
  return useCallback(
    categoryId => {
      dispatch(actions.selectCategory({ categoryId }))
    },
    [dispatch]
  )
}

export const useReleaseItem = () => {
  const dispatch = useDispatch()
  return useCallback(
    (categoryId: string, itemId: string) => {
      dispatch(actions.releaseCategoryItem({ categoryId, itemId }))
    },
    [dispatch]
  )
}

export const useReleaseCategory = () => {
  const dispatch = useDispatch()
  return useCallback(
    (categoryId: string) => {
      dispatch(actions.releaseCategory({ categoryId }))
    },
    [dispatch]
  )
}

export const useReleaseAll = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.releaseAll())
  }, [dispatch])
}
