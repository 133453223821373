import React from 'react'
import styled from 'styled-components'
import { SortableHandle } from 'react-sortable-hoc'
import { Link } from 'react-router-dom'

import Const from '~/src/const'

import Icon from '~/src/components/atoms/Icon'
import {
  TableRow,
  HandleColumn,
  SeatSetNameColumn,
  ArrowColumn
} from './TableParts'
import { SeatSet } from '~/src/redux/modules/SeatSet/types'
const { Page, Color } = Const

const DragHandle = SortableHandle(() => (
  <StyledHandleColumn>
    <SortIcon />
  </StyledHandleColumn>
))

export const Row = ({
  seatSet,
  index
}: {
  seatSet: SeatSet
  index: number
}) => {
  return (
    <StyledTableRow key={index}>
      <DragHandle />
      <StyledLink to={Page.SEAT_SET.UPDATE(seatSet.seatSetId)}>
        <SeatSetNameColumn>{seatSet.name}</SeatSetNameColumn>
        <ArrowColumn>
          <ArrowIcon />
        </ArrowColumn>
      </StyledLink>
    </StyledTableRow>
  )
}

const StyledLink = styled(Link)`
  display: table-cell;
  color: ${Color.VERY_DARK_GRAY};
  :hover {
    text-decoration: none;
  }
`

const StyledTableRow = styled(TableRow)``

const StyledHandleColumn = styled(HandleColumn)`
  cursor: move;
`

const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``
const ArrowIcon = styled(Icon).attrs({ name: 'next' })``
