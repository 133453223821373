import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { KmSettingApiResponse } from '~/src/api/km/settings/types'
import { actions } from './actions'
import { actions as parentActions } from '../actions'

export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.clearState())
  }, [dispatch])
}

export const useInitialize = (kmSettingApiResponse: KmSettingApiResponse) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.initialize(kmSettingApiResponse))
  }, [kmSettingApiResponse, dispatch])
}

export const useHandleToggleCookingNoticeEnabled = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(actions.toggleCookingNoticeEnabled())
    dispatch(parentActions.edited())
  }, [dispatch])
}

export const useHandleSelectOrderTicketSortType = () => {
  const dispatch = useDispatch()
  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(actions.selectOrderTicketSortType(event.target.value))
      dispatch(parentActions.edited())
    },
    [dispatch]
  )
}
