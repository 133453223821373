import React from 'react'
import styled from 'styled-components'

import Hint from '~/src/components/molecules/Hint'
import TableRow from '~/src/components/atoms/TableRow'
import Required from '~/src/components/atoms/Required'

import {
  CategoryNameColumn,
  KitchenPositionSelectColumn,
  ByItemLinkColumn,
  AnalysisTagSelectColumn
} from './Column'

import Const from '~/src/const/'

const { Color } = Const
const BALLOON_WIDTH = 290

type Props = {
  isDisplayedCategoryShowing: boolean
}

export const HeaderRow: React.VFC<Props> = props => {
  return (
    <StyledCategoryColumn>
      <CategoryNameColumn>カテゴリー</CategoryNameColumn>
      <KitchenPositionSelectColumn>
        振り分け先の調理場
      </KitchenPositionSelectColumn>
      <AnalysisTagSelectColumn>
        分析タグ
        {props.isDisplayedCategoryShowing && <Required name="required" />}
        <Hint position="bottom-left" balloonWidth={BALLOON_WIDTH}>
          フードやドリンクなどの種別ごとに商品を分別することができます。
          <br />
          設定すると、フードとドリンクが初めて注文される場合は「新」が表示されます。
        </Hint>
      </AnalysisTagSelectColumn>
      <ByItemLinkColumn />
    </StyledCategoryColumn>
  )
}

const StyledCategoryColumn = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
