import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import DismissPrompt from '~/src/components/organisms/DismissPrompt'
import ErrorModal from '~/src/components/organisms/Modal/ErrorModal'

const { Page } = Const

const ModalContainer: React.VFC = () => {
  const isEdited = useSelector((state: AppState) => state.toppingGroup.isEdited)
  const isToppingGroupNotFound = useSelector(
    (state: AppState) => state.toppingGroup.isToppingGroupNotFound
  )

  const history = useHistory()
  const handleCloseNotFoundModal = useCallback(() => {
    history.push(Page.TOPPING.LIST)
  }, [history])
  const props: InnerProps = {
    isEdited,
    isToppingGroupNotFound,
    onCloseNotFoundModal: handleCloseNotFoundModal
  }

  return <Modal {...props} />
}
export { ModalContainer as Modal }

type InnerProps = {
  isEdited: boolean
  isToppingGroupNotFound: boolean
  onCloseNotFoundModal: () => void
}
const Modal: React.VFC<InnerProps> = React.memo(props => {
  const { isEdited, isToppingGroupNotFound, onCloseNotFoundModal } = props
  return (
    <>
      <DismissPrompt enabled={isEdited} />
      <ErrorModal
        isError={isToppingGroupNotFound}
        error={{
          title: '対象のデータが存在しません',
          message: 'トッピング画面に戻ります。'
        }}
        onClose={onCloseNotFoundModal}
      />
    </>
  )
})
