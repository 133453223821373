import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearMemoState,
  deleteMemo,
  fetchMemo,
  postDeleteMemo,
  postMergeMemo,
  setIsDeleteConfirmModalOpen
} from '~/src/redux/modules/OrderItemMemoTemplate'
import { clearUiState, openValidationErrorModal } from '~/src/redux/modules/UI'

export const useInitializeState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchMemo())
  }, [dispatch])
}
export const useOnSubmit = () => {
  const dispatch = useDispatch()
  return useCallback(
    (hasValidationError: boolean) => {
      if (hasValidationError) {
        dispatch(openValidationErrorModal())
        return
      }
      dispatch(postMergeMemo())
    },
    [dispatch]
  )
}
export const useClearState = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearUiState())
    dispatch(clearMemoState())
  }, [dispatch])
}
export const useOnDeleteConfirmModalDelete = (
  orderItemMemoTemplateId?: string | null,
  deletingMemoIndex?: number
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setIsDeleteConfirmModalOpen(false))

    if (
      orderItemMemoTemplateId === undefined ||
      deletingMemoIndex === undefined
    ) {
      return
    }
    // IDがないもの(サーバにないもの)は通信なしで消す
    if (orderItemMemoTemplateId === null) {
      dispatch(deleteMemo(deletingMemoIndex))
      return
    }
    dispatch(postDeleteMemo(deletingMemoIndex))
  }, [orderItemMemoTemplateId, deletingMemoIndex, dispatch])
}
export const useOnDeleteConfirmModalCancel = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setIsDeleteConfirmModalOpen(false))
  }, [dispatch])
}
