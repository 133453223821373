import React from 'react'
import { useHistory } from 'react-router'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Discription from './Discription'
import ItemList from './VariationList'
import Footer from './Footer'
import DismissPrompt from '../Common/DismissPrompt'
import Const from '~/src/const'
const { Page } = Const

type Props = {
  hasFile: boolean
}

const Confirm: React.FC<Props> = props => {
  const history = useHistory()
  if (!props.hasFile) {
    history.replace(Page.VARIATION_GROUP.GETTING_STARTED)
    return null
  }
  const breadcrumbs = [{ title: 'バリエーション登録内容の最終確認' }]
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      modal={<DismissPrompt />}
      footer={<Footer />}
      displayKM
    >
      <Discription />
      <ItemList />
    </PageTemplate>
  )
}

export default React.memo(Confirm)
