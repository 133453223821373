import moment from 'moment'
import { format } from '~/src/const/formatter'
import { AppState } from '~/src/redux/reducer'

export const selectInnerTable = (state: AppState) => {
  const planPriceType = state.auth.store?.planPriceType
  const summary = state.deviceCount.summary
  const billingTargetCount = summary.billingTargetCount

  const flatRateCount = planPriceType?.FLAT_RATE_COUNT || '-'
  const deviceCountAppended = planPriceType?.FLAT_RATE_COUNT
    ? Math.max(billingTargetCount - planPriceType?.FLAT_RATE_COUNT, 0)
    : '-'

  return {
    currentCount: summary.currentCount,
    lastUpdatedAt: moment(summary.lastUpdatedAt).format(
      format.YYYY_MM_DD_HH_mm_ss
    ),
    requestedAt: moment(summary.requestedAt).format(format.YYYY_MM_JP),
    billingTargetDate: moment(summary.billingTargetDate).format(
      format.YYYY_MM_DD
    ),
    billingTargetCount,
    flatRateCount,
    deviceCountAppended
  }
}
