import { AppState } from '~/src/redux/reducer'
import { checkCourse, CourseError } from '~/src/util/ErrorChecker'

export const selectError = (state: AppState) => {
  const courseErrors: CourseError = checkCourse(state.api.items.response)
  return {
    hasDeletedCourse: courseErrors.itemIdsHaveDeletedCourse.length !== 0,
    hasDeletedCourseDetail:
      courseErrors.itemIdsHaveDeletedCourseDetail.length !== 0,
    hasEmptyCategoryItem:
      courseErrors.itemIdsHaveEmptyCategoryItem.length !== 0,
    hasEmptyCourse: courseErrors.itemIdsHaveEmptyCourse.length !== 0,
    hasHodaiError: courseErrors.itemIdsHaveHodaiError.length !== 0,
    hasSkuCourse: courseErrors.itemIdsHaveSkuCourse.length !== 0,
    hasDeletedCategory: courseErrors.itemIdsHaveEmptyCategory.length !== 0
  }
}
