import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setShouldForward } from '~/src/redux/modules/Forward'

export const useSetShouldForward = () => {
  const dispatch = useDispatch()
  return useCallback(
    (shouldForward: boolean) => {
      dispatch(setShouldForward(shouldForward))
    },
    [dispatch]
  )
}
