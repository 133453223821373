import React from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
const { Color } = Const

export default ({ list }) => (
  <PageDescription>
    {list.map((item, i) => (
      <PageDescriptionItem key={`notes-${i}`}>{item}</PageDescriptionItem>
    ))}
  </PageDescription>
)

const PageDescription = styled.ul`
  margin: 0 0 6px 0;
  padding-left: 0;
  color: ${Color.DARK_GRAY_3};
  li {
    content: '';
    padding-bottom: 5px;
  }
  li:last-child {
    content: '';
    padding-bottom: 0;
  }
`

const PageDescriptionItem = styled.li`
  list-style: none;
`
