export const ENVIRONMENT_TYPE = {
  LOCAL: 'local',
  TEST: 'test',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}

// NODE_ENV は production | development なので、その他の環境を表すために APP_ENVを使用する
// 既存の処理を壊さないために APP_ENV が指定されていない場合は NODE_ENV を使用する
export const ENVIRONMENT = process.env.APP_ENV || process.env.NODE_ENV

export const LOCAL_REQUEST_TARGET = process.env.LOCAL_REQUEST_TARGET || 'dev10'
