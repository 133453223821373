import React from 'react'
import styled from 'styled-components'
import { Validation } from '~/src/components/atoms/Validation'
import SelectBox from '~/src/components/atoms/SelectBox'

export default props => {
  const {
    name,
    options,
    placeholder,
    handleSelectChange,
    selectedId,
    error,
    onFocus,
    disabled,
    errorAlign
  } = props

  const align = errorAlign || 'top'

  return (
    <Wrapper>
      <SelectBox
        name={name}
        options={options}
        placeholder={placeholder}
        handleSelectChange={handleSelectChange}
        selectedId={selectedId}
        onFocus={onFocus}
        invalid={!!error}
        disabled={disabled}
      />
      {error && <Validation align={align}>{error}</Validation>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
