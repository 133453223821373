import React from 'react'
import { Map, Set } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import { SelectedToppingError } from '~/src/redux/modules/Topping/ToppingGroup/types'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { useSelector } from 'react-redux'

const { URL } = Const

const ErrorMessagesContainer: React.VFC = React.memo(() => {
  const errors = useSelector(
    (state: AppState) => state.itemByToppingGroup.selectedItemErrors
  )

  const warningMessages: (string | React.ReactElement)[] = []
  if (containsError(errors, 'TAX_FREE')) {
    warningMessages.push(
      <>
        税設定が「非課税」の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }
  if (containsError(errors, 'NO_CATEGORY')) {
    warningMessages.push(
      <>
        カテゴリーが未設定の商品が含まれています。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  return <ErrorMessages warningMessages={warningMessages} />
})
export { ErrorMessagesContainer as ErrorMessages }

const containsError = (
  errors: Map<string, Set<SelectedToppingError>>,
  target: SelectedToppingError
) => {
  return errors.some(err => !!err.get(target))
}

type InnerProps = {
  warningMessages: (string | React.ReactElement)[]
}
const ErrorMessages: React.VFC<InnerProps> = React.memo(
  ({ warningMessages }) => {
    if (warningMessages.length < 1) {
      return <></>
    }
    return <Message type="warning" messageList={warningMessages} />
  }
)
