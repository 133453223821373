import { Record, List } from 'immutable'
import Variation, { VariationEditor } from './Variation'
import UuidGenerator from '~/src/util/UuidGenerator'

interface VariationGroupProps {
  variationGroupId: string
  variations: List<Variation>
}

const VariationGroupRecord = Record<VariationGroupProps>({
  variationGroupId: '',
  variations: List()
})

class VariationGroup
  extends VariationGroupRecord
  implements VariationGroupProps {}

interface VariationGroupEditorProps {
  variationGroupId: string
  variations: List<VariationEditor | undefined>
}

const VariationGroupEditorRecord = Record<VariationGroupEditorProps>({
  variationGroupId: '',
  variations: List()
})

export class VariationGroupEditor
  extends VariationGroupEditorRecord
  implements VariationGroupEditorProps
{
  constructor() {
    super()
    return this.set('variationGroupId', UuidGenerator())
  }

  isValid(): boolean {
    return (
      this.variations.size > 0 &&
      this.variations.every(variation => !!variation?.isValid())
    )
  }

  updateVariation(newVariation: VariationEditor) {
    return this.set(
      'variations',
      this.variations.map(variation =>
        variation?.variationId === newVariation.variationId
          ? newVariation
          : variation
      )
    )
  }

  sortVariation(from: number, to: number) {
    const target = this.variations.get(from)
    return this.set(
      'variations',
      this.variations.delete(from).insert(to, target)
    )
  }

  pushNewVariation() {
    return this.set('variations', this.variations.push(new VariationEditor()))
  }

  deleteVariation(variationId: string) {
    return this.set(
      'variations',
      this.variations.filter(
        variation => variation?.variationId !== variationId
      )
    )
  }

  toVariationGroup() {
    return new VariationGroup({
      variationGroupId: this.variationGroupId,
      variations: this.variations
        .filter(variation => !!variation)
        // @ts-ignore : 上記でundefinedを弾いているのでTS警告を無視する
        .map((variation: VariationEditor) => variation.toVariation())
    })
  }
}

export default VariationGroup
