import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import HeaderRow from './Header'
import AllItemBatchEditForm from './AllItemBatchEditForm'
import useInteractors from '~/src/hooks/useInteractors'
import { AppState } from '~/src/redux/reducer'
import { BatchEditForm } from '~/src/redux/modules/TargetServingTime/types'
import { isKmPlanSelector } from '~/src/redux/selectors/storeSelectors'

const batchEditFormSelector = (state: AppState) =>
  state.targetServingTimeSetting.targetServingTime.batchEditForm

const allItemBatchEditFormSelector = createSelector(
  batchEditFormSelector,
  (state?: BatchEditForm) => state?.all
)

const Container = () => {
  const batchEditForm = useSelector(batchEditFormSelector)
  const which = batchEditForm?.which
  const allItemBatchEditForm = useSelector(allItemBatchEditFormSelector)
  const { isChecked, modeId, noticeMinutes, alertMinutes } =
    allItemBatchEditForm || {}
  const interactor = useInteractors().targetServingTimeInteractor
  const isAutomaticSettingAvailable = useSelector(isKmPlanSelector)
  return (
    <>
      <HeaderRow />
      <AllItemBatchEditForm
        isDisabled={which === 'categories'}
        modeId={modeId || ''}
        isChecked={isChecked || false}
        noticeMinutes={noticeMinutes || '0'}
        alertMinutes={alertMinutes || '0'}
        interactor={interactor}
        isAutomaticSettingAvailable={isAutomaticSettingAvailable}
      />
    </>
  )
}

export default Container
