import { ActionType, getType } from 'typesafe-actions'
import { arrayMove } from '~/src/util/ArrayUtils'
import { actions } from './actions'
import { CourseSettingState } from './types'

const initialState = {
  selectedCourse: undefined,
  selectedItemList: [],
  selectedHodai: undefined,
  isSelectingCourse: false,
  isSelectingHodai: false,
  isEditing: false
}

export const courseSetting = (
  state: CourseSettingState = initialState,
  action: ActionType<typeof actions>
): CourseSettingState => {
  switch (action.type) {
    case getType(actions.clearState): {
      return initialState
    }
    case getType(actions.initialize): {
      return {
        ...state,
        selectedItemList: action.payload.items,
        selectedHodai: action.payload.selectedHodai
      }
    }
    case getType(actions.openCourseSelect): {
      return {
        ...state,
        isSelectingCourse: true
      }
    }
    case getType(actions.closeCourseSelect): {
      return {
        ...state,
        isSelectingCourse: false
      }
    }
    case getType(actions.submitCourseSelect): {
      return {
        ...state,
        selectedCourse: {
          ...action.payload.item,
          isDeleted: false // 編集画面から削除済み商品が選択されることはないためfalse固定
        },
        isSelectingCourse: false,
        isEditing: true
      }
    }
    case getType(actions.selectItem): {
      return {
        ...state,
        selectedItemList: [...state.selectedItemList, action.payload.item],
        isEditing: true
      }
    }
    case getType(actions.releaseItem): {
      return {
        ...state,
        selectedItemList: state.selectedItemList.filter(
          (_, index) => index !== action.payload.index
        ),
        isEditing: true
      }
    }
    case getType(actions.releaseAll): {
      return {
        ...state,
        selectedItemList: [],
        isEditing: true
      }
    }
    case getType(actions.sortItem): {
      return {
        ...state,
        selectedItemList: arrayMove(
          state.selectedItemList,
          action.payload.oldIndex,
          action.payload.newIndex
        ),
        isEditing: true
      }
    }
    case getType(actions.openHodaiSelect): {
      return {
        ...state,
        isSelectingHodai: true
      }
    }
    case getType(actions.closeHodaiSelect): {
      return {
        ...state,
        isSelectingHodai: false
      }
    }
    case getType(actions.submitHodaiSelect): {
      return {
        ...state,
        selectedHodai: {
          itemId: action.payload.item.itemId,
          hodaiId: action.payload.item.hodaiId,
          itemName: action.payload.item.itemName
        },
        isSelectingHodai: false,
        isEditing: true
      }
    }
    default:
      return state
  }
}
