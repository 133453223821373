import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { checkBatchSelectCategory } from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useCheckCategory = (categoryId: string, isChecked: boolean) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(checkBatchSelectCategory(categoryId, isChecked))
  }, [categoryId, dispatch, isChecked])
}
