import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  deleteKitchenPosition,
  setIsDeleteConfirmModalOpen
} from '~/src/redux/modules/KitchenPositionSetting/KitchenPositionManagement'

export const useHandleOpenDeleteConfirmModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setIsDeleteConfirmModalOpen(false))
    dispatch(deleteKitchenPosition())
  }, [dispatch])
}

export const useHandleCloseDeleteConfirmModal = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setIsDeleteConfirmModalOpen(false))
  }, [dispatch])
}
