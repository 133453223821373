import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'
import Const from '~/src/const/'

const { Size } = Const

const CourseCommonColumn = styled(TableColumn)`
  text-align: left;
`

export const CourseDetailNameColumn = styled(CourseCommonColumn)`
  width: 30%;
  word-wrap: break-word;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    width: 100px;
  }
`

export const CategoryNameColumn = styled(CourseCommonColumn)`
  width: 26%;
  word-wrap: break-word;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.STANDARD_TABLET_MIN}px) {
    display: none;
  }
`

export const PriceColumn = styled(CourseCommonColumn)`
  width: 160px;
  white-space: pre-wrap;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 80px;
  }
  white-space: pre-wrap;
`

export const ItemCountColumn = styled(CourseCommonColumn)`
  width: 110px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 80px;
  }
`

export const FreeFlowingColumn = styled(CourseCommonColumn)`
  width: 177px;
  text-align: right;
  @media (max-width: ${Size.MEDIA.SCREEN_SIZE.PC_LG_MIN}px) {
    width: 120px;
  }
`

export const IconColumn = styled(CourseCommonColumn)`
  height: 36px;
  text-align: center;
  width: 70px;
`

export const SpaceColumn = styled(TableColumn)`
  width: 39px;
  height: 36px;
  padding: 0 10px 0 0;
  text-align: center;
`
