import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

import Const from '~/src/const'

const { Color, Size, KeyEvent } = Const

type Props = {
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
  invalid?: boolean
  disabled?: boolean
  align?: string
  pattern?: string
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined
  id?: string
  name?: string
  type: string
  value?: string
  max?: number
  min?: number
  index?: number
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  maxLength?: number
  placeholder?: string
  error?: string
  width?: number
}
export const Input: React.VFC<Props> = props => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (
      !e.nativeEvent.isComposing &&
      e.code === KeyEvent.NAME.ENTER &&
      inputRef.current
    ) {
      inputRef.current.blur()
    }
    props.onKeyDown && props.onKeyDown(e)
  }
  return <StyledInput ref={inputRef} {...props} onKeyDown={handleKeyDown} />
}

const StyledInput = styled.input<{
  invalid?: boolean
  disabled?: boolean
  align?: string
  width?: number
  ref?: any
}>`
  color: ${Color.VERY_DARK_GRAY};
  padding: 0 12px;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: 42px;
  background-color: ${Color.WHITE};
  /* stylelint-disable-next-line declaration-colon-newline-after */
  border: ${props => (props.invalid ? Color.SOFT_RED : Color.LIGHT_GRAY_4)}
    solid 1px;
  border-radius: ${Size.BORDER.RADIUS.DEFAULT}px;
  font-size: ${Size.FONT.BASE}px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &::-webkit-input-placeholder {
    color: ${Color.LIGHT_GRAY_4};
    padding-top: 0.2em;
  }
  &::-moz-placeholder {
    color: ${Color.LIGHT_GRAY_4};
  }
  &:-ms-input-placeholder {
    color: ${Color.LIGHT_GRAY_4};
  }
  &:focus {
    outline: 0;
    /* stylelint-disable-next-line declaration-colon-newline-after */
    border: ${props => (props.invalid ? Color.SOFT_RED : Color.VIVID_BLUE)}
      solid 1px;
  }
  ${props =>
    props.disabled &&
    css`
      background-color: ${Color.LIGHT_GRAY_3};
      opacity: 0.3;
    `}
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`
