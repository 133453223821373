import styled, { css } from 'styled-components'

import Const from '~/src/const'

const { Color } = Const

/* TODO: disabled時の灰色は適当に選んだものなので、相談後に改める */
export default styled.div`
  ${props =>
    props.disabled &&
    css`
      color: ${Color.LIGHT_GRAY_2};
    `}
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border: none;
  display: table-cell;
  line-height: 1.2;
  padding: 0 16px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word;
  word-break: break-all;
`
