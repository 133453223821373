import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

const baseColumn = styled(TableColumn)`
  text-align: left;
`

export const ItemNameColumn = styled(baseColumn)`
  width: auto;
`
export const ItemSettingColumn = styled(baseColumn)`
  width: 150px;
`
export const KitchenPositionSelectColumn = styled(baseColumn)`
  width: 200px;
  padding: 0;
`
export const AnalysisTagSelectColumn = styled(baseColumn)`
  width: 180px;
  padding: 0 0 0 16px;
`
