import React, { ReactNode } from 'react'
import styled from 'styled-components'
import Const from '~/src/const'
import Modal from '~/src/components/organisms/Modal'
import Heading from '~/src/components/atoms/Heading'
import { selectIsOpen } from './selectors'
import { useHandleOKButtonClick } from './hooks'
import { useSelector } from 'react-redux'

const { Color, Size } = Const
const { H2 } = Heading

type ValidationErrorModalProps = {
  children: ReactNode
}

export const ValidationErrorModal: React.VFC<
  ValidationErrorModalProps
> = props => {
  const isOpen = useSelector(selectIsOpen)
  const handleOKButtonClick = useHandleOKButtonClick()

  return (
    <Modal isOpen={isOpen} onOK={handleOKButtonClick} noUseCancelButton={true}>
      <StyledHeading>入力エラー</StyledHeading>
      {props.children && <StyledContent>{props.children}</StyledContent>}
    </Modal>
  )
}

const StyledHeading = styled(H2)`
  color: ${Color.VERY_DARK_GRAY_3};
  margin: 0 0 24px;
  word-wrap: break-word;
  max-width: 320px;
`

const StyledContent = styled.div`
  text-align: center;
  color: ${Color.VERY_DARK_GRAY_3};
  margin-bottom: 8px;
  font-size: ${Size.FONT.BASE}px;
  line-height: 20px;
  word-wrap: break-word;
  max-width: 320px;
`
