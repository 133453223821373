import React from 'react'
import styled from 'styled-components'

import Const from '~/src/const'
const { Color } = Const

const TOGGLE_HEIGHT = 32
const TOGGLE_WIDTH = 52

const DIAMETER = 28

const ToggleButton = ({ onToggle, name, disabled, checked }) => (
  <Wrapper>
    <Checkbox
      type="checkbox"
      name={name}
      disabled={disabled}
      onChange={e => onToggle(e)}
      checked={checked}
    />
    <Bar>
      <Handle />
    </Bar>
  </Wrapper>
)

const Wrapper = styled.label`
  display: inline-block;
  position: relative;
  height: ${TOGGLE_HEIGHT}px;
  width: ${TOGGLE_WIDTH}px;
`

const Checkbox = styled.input`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + span {
    background-color: ${Color.VIVID_BLUE};
    border-color: ${Color.VIVID_BLUE};

    > span {
      left: 20px;
    }
  }
`

const Bar = styled.span`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  background-color: ${Color.LIGHT_GRAY_5};
  border-radius: 16px;
  top: 0;
  left: 0;
  height: ${TOGGLE_HEIGHT}px;
  width: ${TOGGLE_WIDTH}px;
  transition: all 0.2s;
`

const Handle = styled.span`
  position: absolute;
  background-color: ${Color.WHITE};
  border-radius: 28px;
  left: 0;
  width: ${DIAMETER}px;
  height: ${DIAMETER}px;
  margin: 2px;
  transition: all 0.2s;
`

export default ToggleButton
