import React, { useCallback } from 'react'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import FooterArea from '~/src/components/atoms/Footer/Footer'
import FooterLeftArea from '~/src/components/atoms/Footer/FooterLeftArea'
import FooterRightArea from '~/src/components/atoms/Footer/FooterRightArea'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import Const from '~/src/const'
import { useHistory } from 'react-router'
const { Page, Size, Weight } = Const

type Props = {
  interactor: VariationGroupInteractor
  selectedItemCount: number
  isEdited: boolean
}

const Footer: React.FC<Props> = props => {
  const history = useHistory()
  const onUploadNewCsvCb = useCallback(() => {
    history.push(Page.VARIATION_GROUP.GETTING_STARTED)
  }, [history])
  const onFinishCb = useCallback(() => {
    history.push(Page.VARIATION_GROUP.CONFIRM)
  }, [history])
  const onModalOpen = useCallback(() => {
    props.interactor.openVariationGroupEditor()
  }, [props.interactor])
  return (
    <StyledFooter>
      <LeftArea>
        <StyledButton
          type="button"
          normal
          disabled={!!props.selectedItemCount}
          onClick={onUploadNewCsvCb}
        >
          CSVをアップロードし直す
        </StyledButton>
      </LeftArea>
      <RightArea>
        {props.selectedItemCount ? (
          <ItemCount>{`計${props.selectedItemCount}点`}</ItemCount>
        ) : (
          ''
        )}
        <StyledButton
          type="button"
          normal
          disabled={!props.selectedItemCount}
          onClick={onModalOpen}
        >
          バリエーションを一括登録する
        </StyledButton>
        <StyledButton
          type="button"
          primary
          disabled={!props.isEdited || !!props.selectedItemCount}
          onClick={onFinishCb}
        >
          登録内容を確認する
        </StyledButton>
      </RightArea>
    </StyledFooter>
  )
}

const ItemCount = styled.span`
  font-weight: ${Weight.BOLD};
  font-size: ${Size.FONT.BASE}px;
`

const StyledButton = styled(Button)`
  width: auto;
`

// 横幅が狭い時にLeft, Rightが重なった時に8pxを間に入れるためのCSS
const StyledFooter = styled(FooterArea)`
  padding-bottom: 0;
`

const LeftArea = styled(FooterLeftArea)`
  padding-bottom: 8px;
`

const RightArea = styled(FooterRightArea)`
  padding-bottom: 8px;
  button,
  span {
    margin-left: 16px;
  }
`

export default Footer
