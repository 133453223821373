import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Downloaded from './Downloaded'
import { VariationGroupState } from '~/src/redux/modules/VariationGroup/types'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'

const variationGroupSelector = createSelector(
  (state: AppState) => state.variationGroup,
  (state: VariationGroupState) => ({
    filename: state.filename!,
    header: state.header!,
    items: state.items,
    variationGroups: state.variationGroups,
    hasFile: !!state.file
  })
)

const useVariationGroup = () => {
  return useSelector(variationGroupSelector)
}
const useInteractor = () => ({
  interactor: useInteractors().variationGroupInteractor
})

const DownloadedContainer = () => {
  return <Downloaded {...useVariationGroup()} {...useInteractor()} />
}

export default DownloadedContainer
