import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '~/src/redux/reducer'
import styled from 'styled-components'
import { CategoryList } from '~/src/components/pages/Topping/ItemByToppingGroup/CategoryList/CategoryList'

import Const from '~/src/const'
import { Set } from 'immutable'
const { Weight } = Const

export const ListArea: React.VFC = () => {
  const selectedItemIds: Set<string> = useSelector(
    (state: AppState) => state.itemByToppingGroup.selectedItemIds
  )

  return (
    <Wrapper>
      <HeaderArea>
        <div>対象商品</div>
        <div>計{selectedItemIds.size}点</div>
      </HeaderArea>
      <CategoryList />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 70px);
`

const HeaderArea = styled.div`
  justify-content: space-between;
  padding: 16px 0;
  display: flex;
  font-weight: ${Weight.BOLD};
  font-size: 14px;
`
