import React, { useEffect } from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Content from './Content'
import Footer from './Footer'

import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'
import DismissPrompt from '../Common/DismissPrompt'

type Props = {
  interactor: VariationGroupInteractor
}

const GettingStarted: React.FC<Props> = props => {
  const { interactor } = props

  // 後続の編集画面でカテゴリーマスタが必要なため予め取得して保持しておく
  useEffect(() => {
    interactor.clearState() // ページ跨ぎで同じステートを使ってるからページ離脱時にはclearStateしないのでここでする
    interactor.fetchCategories()
  }, [interactor])

  const description = [
    '複数の商品に、バリエーションを一括で登録することができます。（テスト提供中）'
  ]
  const breadcrumbs = [{ title: 'バリエーション' }]
  return (
    <PageTemplate
      breadcrumbs={breadcrumbs}
      description={description}
      modal={<DismissPrompt />}
      footer={<Footer />}
      displayKM
    >
      <Content />
    </PageTemplate>
  )
}

export default GettingStarted
