import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearEditTargetDevice,
  postEditDeviceName,
  changeDeviceName
} from '~/src/redux/modules/RegisteredDevice/RegisteredDevice'

export const useClearEditTargetDevice = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(clearEditTargetDevice())
  }, [dispatch])
}

export const useEditDeviceName = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(postEditDeviceName())
  }, [dispatch])
}

export const useChangeDeviceName = () => {
  const dispatch = useDispatch()
  return useCallback(
    (deviceName: string) => {
      dispatch(changeDeviceName(deviceName))
    },
    [dispatch]
  )
}
