import { AppState } from '~/src/redux/reducer'
import { checkCourse, CourseError } from '~/src/util/ErrorChecker'
import { ITEM_COUNT_LIMIT } from './Messages'

type ErrorsForCourseSetting = {
  isDeletedCourse: boolean
  hasDeletedCourseDetail: boolean
  hasHodaiError: boolean
  hasEmptyCategoryItem: boolean
  hasSku: boolean
  hasDeletedCategory: boolean
  emptyCourseDetails: boolean
  exceededItemCount: number
}
export const selectError =
  (itemId?: string) =>
  (state: AppState): ErrorsForCourseSetting => {
    const selectedItemCount = selectSelectedItems(state).length
    const exceededItemCount =
      selectedItemCount - ITEM_COUNT_LIMIT > 0
        ? selectedItemCount - ITEM_COUNT_LIMIT
        : 0
    // 新規登録の場合は上限エラー以外全てfalseで返す
    if (!itemId) {
      return {
        isDeletedCourse: false,
        hasDeletedCourseDetail: false,
        hasHodaiError: false,
        hasEmptyCategoryItem: false,
        hasSku: false,
        hasDeletedCategory: false,
        emptyCourseDetails: false,
        exceededItemCount: exceededItemCount
      }
    }
    const courseErrors: CourseError = checkCourse(state.api.items.response)

    // 現在画面に表示されているコース内容の商品IDを取得する
    // APIのレスポンス内容だけでエラー判定するとエラーが解消されないため
    const currentCourseDetailsItemIds = new Set(
      state.courseSetting.selectedItemList.map(element => element.itemId)
    )
    const currentHodaiItemId = state.courseSetting.selectedHodai?.itemId
    return {
      isDeletedCourse: courseErrors.itemIdsHaveDeletedCourse.some(
        e => e === itemId
      ),
      hasDeletedCourseDetail: courseErrors.itemIdsHaveDeletedCourseDetail.some(
        e => currentCourseDetailsItemIds?.has(e)
      ),
      hasHodaiError: courseErrors.itemIdsHaveHodaiError.some(
        e => e === currentHodaiItemId
      ),
      hasEmptyCategoryItem: courseErrors.itemIdsHaveEmptyCategoryItem.some(e =>
        currentCourseDetailsItemIds?.has(e)
      ),
      hasSku: courseErrors.itemIdsHaveSkuCourse.some(e => e === itemId),
      hasDeletedCategory: courseErrors.itemIdsHaveEmptyCategory.some(
        e => e === itemId
      ),
      emptyCourseDetails: state.courseSetting.selectedItemList.length === 0,
      exceededItemCount: exceededItemCount
    }
  }

export const selectSelectedItems = (state: AppState) =>
  state.courseSetting.selectedItemList
