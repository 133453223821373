import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearDeviceCountState,
  closeErrorModal,
  fetchResources,
  updateDeviceError,
  updateDeviceReady
} from '~/src/redux/modules/DeviceCount/DeviceCount'
import Const from '~/src/const'
import { setShouldForward } from '~/src/redux/modules/Forward'
import { useHistory } from 'react-router'

const { Page } = Const

export const useHandleInitialize = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(fetchResources())
  }, [dispatch])
}

export const useClearState = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return useCallback(() => {
    const regExp = new RegExp(Page.DEVICE_COUNT.CONFIRM)
    if (!regExp.test(history.location.pathname)) {
      dispatch(clearDeviceCountState())
    }
  }, [dispatch, history.location.pathname])
}

export const useErrorModalClosed = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(closeErrorModal())
  }, [dispatch])
}

export const useSubmit = (
  selectedDeviceCount: number,
  registeredDevicesCount: number,
  isFreePlan?: boolean
) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!isFreePlan && selectedDeviceCount < registeredDevicesCount) {
      dispatch(updateDeviceError())
    } else {
      dispatch(setShouldForward(true))
      dispatch(updateDeviceReady())
    }
  }, [dispatch, isFreePlan, registeredDevicesCount, selectedDeviceCount])
}
