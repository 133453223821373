import { datadogLogs } from '@datadog/browser-logs'
import React from 'react'
import PageTemplate from '~/src/components/templates/PageTemplate'
import Const from '~/src/const'
import MateImage from '~/assets/images/BusinessSupport/AirMATE.png'
import styled from 'styled-components'
import Button from '~/src/components/atoms/Button'
import { ExternalLinkIcon } from '~/src/components/atoms/Icon'
import { selectStoreNo } from './selector'
import { useSelector } from 'react-redux'
import { Datadog } from '~/src/const/datadog'

const { URL, Color } = Const

/**
 * 経営サポート画面
 */
export const BusinessSupport: React.FC = () => {
  const storeNo = useSelector(selectStoreNo)
  /**
   * Mate画面へ遷移する処理
   */
  const transitionToMate = () => {
    datadogLogs.logger.info(Datadog.BROWSER_LOG_MESSAGE.MATE.MESSAGE, {
      event: Datadog.BROWSER_LOG_MESSAGE.MATE.EVENT
    })
    window.open(
      URL.MATE_URL + `/store?ac=${storeNo}&lid=amt_ord_bo_btn`,
      '_blank'
    )
  }

  const breadcrumbs = [
    {
      title: '経営サポート'
    }
  ]
  const description = [
    'お店の状況がひとめでわかる経営サポートサービス Airメイト（無料）を利用できます。',
    'AirレジやAirレジ オーダーから連携される情報を自動で集計し、誰でもカンタンに分析を開始できます。'
  ]

  return (
    <PageTemplate breadcrumbs={breadcrumbs} description={description}>
      <PageContainer>
        <ImageContainer>
          <ImageStyle src={MateImage} alt="Airメイトのご案内" />
        </ImageContainer>
        <ButtonArea>
          <StyledButton type="button" primary onClick={transitionToMate}>
            Airメイト（無料）を開く <MateExternalLinkIcon />
          </StyledButton>
        </ButtonArea>
      </PageContainer>
    </PageTemplate>
  )
}

const PageContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

const ImageContainer = styled.div``

const ImageStyle = styled.img`
  width: 768px;
  height: 306px;
`

const ButtonArea = styled.div`
  display: inline-block;
`

const MateExternalLinkIcon = styled(ExternalLinkIcon)`
  width: 14px;
  height: 14px;
`

const StyledButton = styled(Button)`
  &:hover ${MateExternalLinkIcon} {
    fill: ${Color.VERY_SOFT_BLUE};
  }
  display: flex;
  gap: 4px;
`
