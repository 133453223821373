// 定数モジュール

import AnalysisTag, { AnalysisTagOptions } from './analysis-tag'
import API from './api'
import Color from './color'
import ContractType from './contract-type'
import DisplayText from './display-text'
import Error from './error'
import FeatureToggleKey from './featureToggleKey'
import Page from './page'
import PlfGrant from './plf-grant'
import ReleaseLifeCycle from './releaseLifeCycle'
import ReturnCode from './return-code'
import Size from './size'
import TaxType from './tax-type'
import TaxDisplayType from './tax-display-type'
import Time from './time'
import UndefinedId from './undefined-id'
import URL from './url'
import Weight from './weight'
import ZIndex from './z-index'
import KeyEvent from './key-event'
import ItemType from './item-type'
import Toast from './toast'
import { ServiceType } from './service-type'
import * as DeviceCount from './device-count'
import PlanPriceTypes from './plan-price-type'
import OrderTicketSortType from './order-tichekt-sort-type'
import { ConsumptionTaxTypeAtOrder } from './consumptionTaxTypeAtOrder'
import ShouldAutoApplySettings from './should-auto-apply-setting'
import SalesDisplays from './sales-display'
import { Datadog } from './datadog'

export default {
  AnalysisTag,
  AnalysisTagOptions,
  API,
  Color,
  ContractType,
  DisplayText,
  Error,
  FeatureToggleKey,
  ItemType,
  KeyEvent,
  Page,
  PlfGrant,
  ReleaseLifeCycle,
  ReturnCode,
  Size,
  TaxType,
  TaxDisplayType,
  Time,
  UndefinedId,
  URL,
  Weight,
  ZIndex,
  Toast,
  ServiceType,
  DeviceCount,
  PlanPriceTypes,
  OrderTicketSortType,
  ConsumptionTaxTypeAtOrder,
  ShouldAutoApplySettings,
  SalesDisplays,
  Datadog
}
