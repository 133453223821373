import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { replaceBatchSelectPrinters } from '~/src/redux/modules/PrintTarget/PrintTargetByCategory'

export const useReplacePrinter = () => {
  const dispatch = useDispatch()
  return useCallback(
    (printerIds: string[]) => {
      dispatch(replaceBatchSelectPrinters(printerIds))
    },
    [dispatch]
  )
}
