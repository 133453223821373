import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Icon, { DownArrowIcon } from '~/src/components/atoms/Icon'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn, FunctionColumn } from '../Column/Column'
import { FunctionLink } from '~/src/components/atoms/FunctionLink'
import Const from '~/src/const'
import { useReleaseItem, useReleaseCategory } from '../../hooks'
import { Category } from '../../../types'
const { Time } = Const

type Props = {
  category: Category
}

export const Row: React.VFC<Props> = props => {
  const { category } = props
  const handleReleaseCategory = useReleaseCategory()
  const handleReleaseItem = useReleaseItem()

  const onClickReleaseCategory = (categoryId: string) => () =>
    handleReleaseCategory(categoryId)

  const onClickReleaseItem = (categoryId: string, itemId: string) => () =>
    handleReleaseItem(categoryId, itemId)

  const [isFolded, setIsFolded] = useState(false)
  const onTitleClicked = () => {
    setIsFolded(!isFolded)
  }

  return (
    <>
      <AccordionTitle onClick={onTitleClicked}>
        <StyledTableRow>
          <ContentColumn>
            {category.name}
            {!category.isDisplayed && ' (非表示)'}
          </ContentColumn>
          <FunctionColumn>
            {!isFolded && (
              <FunctionLink
                onClick={onClickReleaseCategory(category.categoryId)}
              >
                すべて解除
              </FunctionLink>
            )}
            <ArrowWrapper isFolded={isFolded}>
              <DownArrowIcon />
            </ArrowWrapper>
          </FunctionColumn>
        </StyledTableRow>
      </AccordionTitle>
      <AccordionList isFolded={isFolded} height={null}>
        <div>
          {category.items.map(item => (
            <StyledTableRowItem
              key={`key-${category.categoryId}-${item.itemId}`}
            >
              <ContentColumn>
                {item.itemName}
                {!item.isDisplayed && ' (非表示)'}
              </ContentColumn>
              <FunctionColumn>
                <TrashBoxArea
                  onClick={onClickReleaseItem(category.categoryId, item.itemId)}
                >
                  <TrashBoxIcon />
                </TrashBoxArea>
              </FunctionColumn>
            </StyledTableRowItem>
          ))}
        </div>
      </AccordionList>
    </>
  )
}

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
`

const AccordionList = styled.div<{
  height: number | null
  isFolded: boolean
}>`
  max-height: ${props => props.height}px;
  max-width: 100%;
  overflow: hidden;
  transition: all ease ${Time.ACCORDION}ms;
  ${props =>
    props.isFolded &&
    css`
      max-height: 0;
    `}
`

const ArrowWrapper = styled.span<{
  isFolded: boolean
}>`
  svg {
    transition: all ease ${Time.ACCORDION}ms;
    transform: rotate(-180deg);
  }
  ${props =>
    props.isFolded &&
    css`
      svg {
        transform: rotate(0deg);
      }
    `}
`

const StyledTableRow = styled(TableRow)`
  height: 50px;
  font-weight: bold;
`

const StyledTableRowItem = styled(StyledTableRow)`
  padding-left: 30px;
  font-weight: normal;
`
const TrashBoxArea = styled.span`
  margin: 0 5px;
`

const TrashBoxIcon = styled(Icon).attrs({ name: 'trash_box' })``
