import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Const from '~/src/const/'
import { CategoryList } from './CategoryList/CategoryList'
import { StepBadge } from '~/src/components/atoms/StepBadge'

const { Color, Page } = Const

export const CategorySelect: React.VFC = () => {
  return (
    <div>
      <StepTitle>
        <StepBadge stepNum={2} />
        <StepTitleText>カテゴリー選択</StepTitleText>
      </StepTitle>
      <StyledHr />
      <Description>
        選択したプリンターから印刷したいカテゴリーを選んでください。
        <SubDescription>
          ※チェックを入れたカテゴリーは、設定が上書きされますが、商品別設定は上書きされません。
          <br />
          ※商品ごとにプリンターを出し分けたい場合は、
          <Link to={Page.PRINTER_TARGET.CATEGORIES}>印刷振り分け設定</Link>
          で行ってください。
        </SubDescription>
      </Description>
      <CategoryList />
    </div>
  )
}

const Description = styled.div`
  padding-top: 16px;
  padding-bottom: 24px;
`

const SubDescription = styled.div`
  font-size: 14px;
  padding-top: 4px;
  color: ${Color.DARK_GRAY_3};
`

const StepTitle = styled.div`
  display: flex;
  padding: 12px 0;
`

const StepTitleText = styled.div`
  padding-left: 8px;
  padding-top: 7px;
  font-size: 18px;
  font-weight: 600;
`
const StyledHr = styled.hr`
  margin: 0;
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: none;
`
