import React, { useState } from 'react'
import styled from 'styled-components'
import { SideMenuGroup } from './SideMenuGroup/SideMenuGroup'
import { Footer } from './Footer/Footer'
import { LeftDoubleArrowIcon } from '~/src/components/atoms/SideMenuIcon'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import { selectMenuList } from './selectors'

const { Color, Size, Time } = Const

export const SideMenu: React.VFC = () => {
  const { menuList } = useSelector(selectMenuList)
  const [isMenuFolded, setIsMenuFolded] = useState(false)
  const onToggle = () => {
    setIsMenuFolded(!isMenuFolded)
  }
  const onSideMenuClick = isMenuFolded
    ? () => setIsMenuFolded(!isMenuFolded)
    : () => {}
  return (
    <Root isMenuFolded={isMenuFolded}>
      <SideMenuArea onClick={onSideMenuClick}>
        {menuList.map(menuGroup => (
          <SideMenuGroup
            key={menuGroup.menuTitle}
            isMenuFolded={isMenuFolded}
            menuGroup={menuGroup}
            onClick={menuGroup.onClick}
          />
        ))}
        {!isMenuFolded && <Footer />}
      </SideMenuArea>
      <SideMenuToggle onClick={onToggle}>
        <SideMenuToggleWrapper isMenuFolded={isMenuFolded}>
          <LeftDoubleArrowIcon />
        </SideMenuToggleWrapper>
      </SideMenuToggle>
    </Root>
  )
}

type RootProps = {
  isMenuFolded: boolean
}

const Root = styled.div<RootProps>`
  flex: 0 0 auto;
  position: relative;
  width: ${Size.SIDE_MENU_WIDTH}px;
  min-width: ${Size.SIDE_MENU_WIDTH}px;
  background-color: ${Color.VERY_DARK_GRAYISH_BLUE_1};
  transition: all ease ${Time.SIDE_MENU}ms;
  ${props =>
    props.isMenuFolded &&
    `
    width: ${Size.SIDE_MENU_MIN_WIDTH}px;
    min-width: ${Size.SIDE_MENU_MIN_WIDTH}px;
    `}
`

const SideMenuArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 48px;
  width: 100%;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const SideMenuToggle = styled.div`
  width: 100%;
  height: 48px;
  position: absolute;
  bottom: 0;
  background-color: ${Color.VERY_DARK_GRAYISH_BLUE_3};
  text-align: right;
  transition: background-color ${Time.SIDE_MENU}ms;
  &:hover {
    cursor: pointer;
    background-color: ${Color.DARK_GRAYISH_BLUE_1};
  }
`

type SideMenuToggleWrapperProps = {
  isMenuFolded: boolean
}

const SideMenuToggleWrapper = styled.span<SideMenuToggleWrapperProps>`
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 14px;
  svg {
    transition: all ease ${Time.SIDE_MENU}ms;
  }
  ${props =>
    props.isMenuFolded &&
    `
      svg {
        transform: rotate(180deg);
      }
      `}
`
