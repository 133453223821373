import KitchenPositionByCategory from '~/src/redux/models/KitchenPositionSetting/KitchenPositionByCategory'
import { AppState } from '~/src/redux/reducer'

export const selectTargetParams =
  (kitchenPositionByCategory: KitchenPositionByCategory) =>
  (state: AppState) => {
    const { kitchenPositionByItems, focusTargetCategoryId } =
      state.kitchenPositionTarget
    const hasItemSetting = kitchenPositionByItems.hasItemIds(
      kitchenPositionByCategory.itemIds
    )
    return {
      hasItemSetting,
      focusTargetCategoryId
    }
  }
