import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

export const CheckboxColumn = styled(TableColumn)`
  width: 48px;
  text-align: center;
`

export const CategoryColumn = styled(TableColumn)`
  width: 250px;
  max-width: 250px;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
`

export const PrintersColumn = styled(TableColumn)`
  max-width: 400px;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
`
