import styled from 'styled-components'
import Arrow from '~/assets/images/SideMenuIcon/Arrow.svg'
import DownArrow from '~/assets/images/SideMenuIcon/DownArrow.svg'
import LeftDoubleArrow from '~/assets/images/SideMenuIcon/LeftDoubleArrow.svg'
import Handy from '~/assets/images/SideMenuIcon/Handy.svg'
import HowTo from '~/assets/images/SideMenuIcon/HowTo.svg'
import ItemTag from '~/assets/images/SideMenuIcon/ItemTag.svg'
import SelfOrder from '~/assets/images/SideMenuIcon/SelfOrder.svg'
import Kitchen from '~/assets/images/SideMenuIcon/Kitchen.svg'
import Printer from '~/assets/images/SideMenuIcon/Printer.svg'
import Staff from '~/assets/images/SideMenuIcon/Staff.svg'
import Table from '~/assets/images/SideMenuIcon/Table.svg'
import Gear from '~/assets/images/SideMenuIcon/Gear.svg'
import Memo from '~/assets/images/SideMenuIcon/Memo.svg'
import Dishup from '~/assets/images/SideMenuIcon/Dishup.svg'
import OnlinePayment from '~/assets/images/SideMenuIcon/OnlinePayment.svg'
import TakeOut from '~/assets/images/SideMenuIcon/TakeOut.svg'
import BusinessSupport from '~/assets/images/SideMenuIcon/BusinessSupport.svg'

import Const from '~/src/const'
const { Color } = Const

const IconStyler = (icon: any) => styled(icon)`
  width: 24px;
  height: 24px;
  fill: ${Color.WHITE};
`

export const ArrowIcon = styled(Arrow)`
  width: 10px;
  height: 18px;
  fill: ${Color.WHITE};
`
export const DownArrowIcon = styled(DownArrow)`
  width: 10px;
  height: 10px;
  fill: ${Color.WHITE};
`
export const LeftDoubleArrowIcon = styled(LeftDoubleArrow)`
  width: 20px;
  height: 20px;
  fill: ${Color.WHITE};
`
export const HandyIcon = IconStyler(Handy)
export const HowToIcon = IconStyler(HowTo)
export const ItemTagIcon = IconStyler(ItemTag)
export const SelfOrderIcon = IconStyler(SelfOrder)
export const KitchenIcon = IconStyler(Kitchen)
export const PrinterIcon = IconStyler(Printer)
export const StaffIcon = IconStyler(Staff)
export const TableIcon = IconStyler(Table)
export const VariousSettingsIcon = IconStyler(Gear)
export const MemoIcon = IconStyler(Memo)
export const DishupIcon = IconStyler(Dishup)
export const OnlinePaymentIcon = IconStyler(OnlinePayment)
export const TakeOutIcon = IconStyler(TakeOut)
export const BusinessSupportIcon = IconStyler(BusinessSupport)
