import React, { useCallback } from 'react'
import Button from '~/src/components/atoms/Button'
import styled from 'styled-components'
import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

type FooterProps = {
  interactor: VariationGroupInteractor
}

const Footer: React.FC<FooterProps> = props => {
  const onCloseCb = useCallback(() => {
    props.interactor.closeVariationGroupModal()
  }, [props.interactor])
  return (
    <div>
      <StyledButton normal type="button" onClick={onCloseCb}>
        閉じる
      </StyledButton>
    </div>
  )
}

const StyledButton = styled(Button)`
  width: auto;
`

export default React.memo(Footer)
