import { AppState } from '~/src/redux/reducer'
import { Category } from './AccordionList/Row'

export const selectSourseCategories = (state: AppState): Category[] => {
  const courseSettingState = state.courseSetting
  const itemsState = state.api.items.response

  const itemMap = itemsState.items?.reduce((previous: any, current) => {
    previous[current.itemId] = current
    return previous
  }, {})

  // Step1で選択されている、またはコース親・放題プランに設定されている商品はグレーアウト
  const selectedCourseItemId = courseSettingState.selectedCourse?.itemId
  const coursesItemIds = new Set(
    itemsState.courses?.map(course => course.itemId)
  )
  const hodaisItemIds = new Set(itemsState.hodais?.map(hodai => hodai.itemId))

  return (
    itemsState.categories?.map(category => {
      const items =
        itemsState.itemCategoryJunctions
          ?.filter(e => e.categoryId === category.categoryId)
          .map(e => {
            const item = itemMap[e.itemId]
            const disabled =
              selectedCourseItemId === item.itemId ||
              coursesItemIds.has(item.itemId) ||
              hodaisItemIds.has(item.itemId)
            return {
              itemId: item.itemId,
              itemName: item.itemName,
              isSKU: item.skus?.length > 0 || false,
              disabled,
              isDisplayed: item.isDisplayed,
              isCombinedCategory: true // categoryと紐づいてるためtrue
            }
          }) || []
      return {
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        isDisplayed: category.isDisplayed,
        items
      }
    }) || []
  )
}

export const selectSelectedItems = (state: AppState) =>
  state.courseSetting.selectedItemList
