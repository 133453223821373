import React from 'react'
import styled from 'styled-components'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import 'react-virtualized/styles.css'
import Const from '~/src/const'
import { CategoryHeader } from './CategoryHeader/CategoryHeader'
import { CategoryColumn } from './CategoryColumn/CategoryColumn'
import { SeatSetHeader } from './SeatSetHeader/SeatSetHeader'
import { SeatSetColumn } from './SeatSetColumn/SeatSetColumn'
import { selectPrintTargetCategoryTable } from './selectors'
import { useSelector } from 'react-redux'
const { Size } = Const

const BOTTOM_SPACE = 200
const HEADER_ROW_HEIGHT = 50
const CATEGORY_ROW_HEIGHT = 70
const HEADER_COLUMN_WIDTH = 230
const MIN_SEAT_SET_COLUMN_WIDTH = 315

export const Table = () => {
  const { categories, seatSets } = useSelector(selectPrintTargetCategoryTable)

  const getRowHeight = (index: number) => {
    switch (index) {
      case 0:
        // ヘッダー行
        return HEADER_ROW_HEIGHT
      case categories.size + 1:
        // ドロップダウン用にあける最終行下の空行
        return BOTTOM_SPACE
      default: {
        // 通常行
        // カテゴリー名が14文字で改行されることへの対策
        const categoryName = categories.get(index - 1)?.categoryName || ''
        const additionalRowHeight =
          Size.FONT.BASE * (Math.ceil(categoryName.length / 14) - 1)
        return CATEGORY_ROW_HEIGHT + additionalRowHeight
      }
    }
  }

  const getColumnWidth = (index: number, width: number) => {
    const seatSetNum = seatSets.length
    switch (index) {
      case 0:
        // ヘッダー列
        return HEADER_COLUMN_WIDTH
      default: {
        // 通常列
        // カテゴリー名が14文字で改行されることへの対策
        const remainingWidth = width - HEADER_COLUMN_WIDTH
        const seatSetColumnWidth = Math.floor(remainingWidth / seatSetNum)
        return seatSetColumnWidth > MIN_SEAT_SET_COLUMN_WIDTH
          ? seatSetColumnWidth
          : MIN_SEAT_SET_COLUMN_WIDTH
      }
    }
  }

  const handleCellRendering = ({
    columnIndex,
    rowIndex,
    style,
    width
  }: {
    columnIndex: number
    rowIndex: number
    style: React.CSSProperties
    width: number
  }) => {
    const seatSet = seatSets[columnIndex - 1]
    const category = categories.get(rowIndex - 1)
    const key = `cell-${rowIndex}-${columnIndex}`

    // 以下、react-virtualizedの横幅指定がうまくいっていないので上書く
    const cellWidth = getColumnWidth(columnIndex, width)
    const newStyle = {
      ...style,
      width: cellWidth,
      left: columnIndex === 0 ? 0 : cellWidth * (columnIndex - 1)
    }

    if (rowIndex > categories.size) {
      return <BlankRow key={key} style={newStyle} />
    }
    return (
      <div key={key} style={newStyle}>
        {rowIndex === 0 ? (
          columnIndex === 0 ? (
            <CategoryHeader />
          ) : (
            <SeatSetHeader
              seatSet={seatSet}
              width={getColumnWidth(columnIndex, width)}
            />
          )
        ) : columnIndex === 0 ? (
          <CategoryColumn
            height={getRowHeight(rowIndex)}
            category={category!}
          />
        ) : (
          <SeatSetColumn
            height={getRowHeight(rowIndex)}
            width={getColumnWidth(columnIndex, width)}
            category={category!}
            seatSet={seatSet!}
          />
        )}
      </div>
    )
  }

  return (
    <Wrapper>
      <AutoSizer>
        {({ height, width }) => (
          <Styler>
            <MultiGrid
              width={width}
              height={height}
              rowHeight={({ index }) => getRowHeight(index)}
              columnWidth={({ index }) => getColumnWidth(index, width)}
              rowCount={categories.size + 2}
              columnCount={seatSets.length + 1}
              classNameTopRightGrid="ReactVirtualized__Grid__Top_Right"
              classNameBottomLeftGrid="ReactVirtualized__Grid__Bottom_Left"
              enableFixedColumnScroll
              enableFixedRowScroll
              fixedColumnCount={1}
              fixedRowCount={1}
              overscanColumnCount={1}
              cellRenderer={props =>
                handleCellRendering({
                  ...props,
                  width
                })
              }
              containerStyle={{
                width: getColumnWidth(1, width) * seatSets.length,
                maxWidth: getColumnWidth(1, width) * seatSets.length
              }}
            />
          </Styler>
        )}
      </AutoSizer>
    </Wrapper>
  )
}

const BlankRow = styled.div`
  display: table-cell;
  height: ${BOTTOM_SPACE}px;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Styler = styled.div`
  .ReactVirtualized__Grid__Top_Right,
  .ReactVirtualized__Grid__Bottom_Left {
    -ms-overflow-style: none;
  }
  .ReactVirtualized__Grid__Top_Right::-webkit-scrollbar,
  .ReactVirtualized__Grid__Bottom_Left::-webkit-scrollbar {
    display: none;
  }
`
