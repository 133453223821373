type SalesDisplay = {
  ID: string
  LABEL: string
  VALUE: boolean
}

const SalesDisplays: {
  [key: string]: SalesDisplay
} = {
  SHOW: {
    ID: '1',
    LABEL: '表示',
    VALUE: true
  },
  HIDE: {
    ID: '2',
    LABEL: '非表示',
    VALUE: false
  }
}

export default SalesDisplays
