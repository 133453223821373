import React from 'react'
import SelectBox from '~/src/components/atoms/SelectBox'
import styled from 'styled-components'
import Const from '~/src/const'
import { useHandleSelectChange } from './hooks'
import { useSelector } from 'react-redux'
import { selectSelectableOptions } from './selectors'

const { Size } = Const

type Props = {
  categoryId: string
  selectedKitchenPositionId: string
  isAutoCompleted: boolean
}
export const KitchenPositionSelect: React.VFC<Props> = props => {
  const selectableOptions = useSelector(selectSelectableOptions)
  const handleSelectChange = useHandleSelectChange(props.categoryId)
  return (
    <div>
      <SelectBox
        name="kitchen-position-select"
        options={selectableOptions}
        selectedId={props.selectedKitchenPositionId}
        handleSelectChange={handleSelectChange}
      />
      {props.isAutoCompleted && (
        <AutoCompletedMessageArea>
          ※デフォルトの調理場が自動選択されています。
        </AutoCompletedMessageArea>
      )}
    </div>
  )
}

const AutoCompletedMessageArea = styled.div`
  padding-top: 5px;
  font-size: ${Size.FONT.SMALL}px;
  word-wrap: break-word;
  white-space: normal;
`
