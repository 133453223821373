import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { viewSeatSetSort } from '~/src/redux/modules/SeatSet/SeatSet'

export const useSort = () => {
  const dispatch = useDispatch()
  return useCallback(
    (params: { oldIndex: number; newIndex: number }) => {
      dispatch(viewSeatSetSort(params))
    },
    [dispatch]
  )
}
