import { createAction } from 'typesafe-actions'

import { ApiResponse } from '~/src/api/types'
import { ItemsApiResponse } from '~/src/api/handy/items/types'

import * as types from './types'

export const actions = {
  clearState: createAction(types.CLEAR_STATE)(),
  fetchInitialDataSucceeded: createAction(
    types.FETCH_INITIAL_DATA_SUCCEEDED,
    (items: ApiResponse<ItemsApiResponse>, toppingGroupId?: string) => ({
      items,
      toppingGroupId
    })
  )(),
  editToppingGroupName: createAction(
    types.EDIT_TOPPING_GROUP_NAME,
    (toppingGroupName: string) => ({ toppingGroupName })
  )(),
  setToppingGroupNameErrorStatus: createAction(
    types.SET_TOPPING_GROUP_NAME_ERROR_STATUS,
    (toBe: boolean) => ({ toBe })
  )(),
  addItemToToppingGroup: createAction(
    types.ADD_ITEM_TO_TOPPING_GROUP,
    (itemId: string) => ({ itemId })
  )(),
  addCategoryToToppingGroup: createAction(
    types.ADD_CATEGORY_TO_TOPPING_GROUP,
    (categoryId: string) => ({ categoryId })
  )(),
  deleteAllFromToppingGroup: createAction(
    types.DELETE_ALL_FROM_TOPPING_GROUP
  )(),
  deleteItemFromToppingGroup: createAction(
    types.DELETE_ITEM_FROM_TOPPING_GROUP,
    (itemId: string) => ({ itemId })
  )(),
  didReOrderSelectedToppingItemIds: createAction(
    types.DID_REORDER_TOPPING_ITEMS_IDS,
    (oldIndex: number, newIndex: number) => ({ oldIndex, newIndex })
  )(),
  saveToppingGroupSucceeded: createAction(types.SAVE_TOPPING_GROUP_SUCCEEDED)()
}
