import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Set } from 'immutable'

import Const from '~/src/const'
import { AppState } from '~/src/redux/reducer'
import useInteractors from '~/src/hooks/useInteractors'
import { ItemError } from '~/src/redux/modules/Topping/ToppingGroup/types'
import { SimpleItemForTopping } from '~/src/redux/models/Item/Item'
import TableRow from '~/src/components/atoms/TableRow'
import { ContentColumn } from '../Common/Column'

const { Color } = Const

type Props = {
  itemId: string
}

const ItemRowContainer: React.VFC<Props> = React.memo(({ itemId }) => {
  const item = useSelector((state: AppState) =>
    state.toppingGroup.items.get(itemId)
  )!
  const errors = useSelector((state: AppState) =>
    state.toppingGroup.itemErrors.get(itemId)
  )

  const displayName = generateDisplayName(item, errors)
  const isDisabled = errors ? !errors.isEmpty() : false
  const interactor = useInteractors().toppingGroupInteractor
  const handleClick = useCallback(() => {
    if (!isDisabled) {
      interactor.addItemToToppingGroup(item.itemId)
    }
  }, [isDisabled, interactor, item.itemId])

  const props: InnerProps = {
    displayName,
    isDisabled,
    onClick: handleClick
  }
  return <ItemRow {...props} />
})
export { ItemRowContainer as ItemRow }

const generateDisplayName = (
  item: SimpleItemForTopping,
  errors?: Set<ItemError>
) => {
  let info = ''
  if (errors?.contains('HAS_SKU')) {
    info += '(バリエーションあり)'
  }
  if (errors?.contains('TAX_FREE')) {
    info += '(税設定:非課税)'
  }
  if (!item.isDisplayed) {
    info += '(非表示)'
  }
  const nameAndPrice = errors?.contains('HAS_SKU')
    ? item.itemName
    : // @ts-ignore
      `${item.itemName}  ¥${item.price.toCommaSeparatedString()}`
  return info ? `${nameAndPrice} ${info}` : nameAndPrice
}

type InnerProps = {
  displayName: string
  isDisabled: boolean
  onClick: () => void
}
const ItemRow: React.VFC<InnerProps> = props => {
  const { displayName, isDisabled, onClick } = props

  return (
    <StyledTableRowItem onClick={onClick} disable={isDisabled}>
      <ContentColumn>{displayName}</ContentColumn>
    </StyledTableRowItem>
  )
}

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  height: 50px;
  font-weight: bold;
`

const StyledTableRowItem = styled(StyledTableRow)<{ disable: boolean }>`
  padding-left: 30px;
  font-weight: normal;
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  background: ${props =>
    props.disable ? Color.VERY_LIGHT_GRAY_9 : Color.WHITE};
  /* stylelint-disable-next-line declaration-colon-newline-after,value-list-max-empty-lines */
  cursor: ${props => (props.disable ? 'auto' : 'pointer')};
`
