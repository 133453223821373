import React, { useEffect } from 'react'
import Session from '~/src/util/Session'
import { Dialog } from '@air-kit/air-kit'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useLoginError } from './hooks'
import { selectLoginError } from './selectors'

const LoginError = () => {
  const { title, message, landingPageUrl } = useSelector(selectLoginError)
  const handleLoginError = useLoginError()
  const location = useLocation()
  useEffect(() => {
    const url = location.search
    handleLoginError(url)
  }, [handleLoginError, location.search])

  const actions = [
    {
      text: 'ログアウト',
      onClick: () => {
        Session.logout()
      },
      primary: true
    }
  ]
  if (landingPageUrl) {
    actions.push({
      text: 'Webサイトに移動する',
      onClick: () => {
        const url = landingPageUrl + '?lid=handy_bo_auth_noconstract'
        window.open(url, '_blank')
      },
      primary: true
    })
  }

  return (
    <Dialog title={title || undefined} actions={actions}>
      {message}
    </Dialog>
  )
}

export default LoginError
