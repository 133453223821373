import React from 'react'
import styled from 'styled-components'

import Hint from '~/src/components/molecules/Hint'
import TableRow from '~/src/components/atoms/TableRow'
import Required from '~/src/components/atoms/Required'

import {
  RadioButtonColumn,
  KitchenPositionColumn,
  DeleteColumn,
  SeatSetUsableColumn
} from '../Column'

import Const from '~/src/const/'

const { Color } = Const
const DEFAULT_KITCHEN_POSITION_BALLOON_WIDTH = 270
const SEAT_SET_BALLOON_WIDTH = 234

export const HeaderRow = () => {
  return (
    <Row>
      <RadioButtonColumn>
        デフォルト
        <Hint
          balloonWidth={DEFAULT_KITCHEN_POSITION_BALLOON_WIDTH}
          position="bottom-right"
        >
          新規で登録したカテゴリーの商品は、デフォルトの調理場に表示されます。カテゴリーごとに表示する調理場を分けたい場合は、「調理場振り分け」から設定できます。
        </Hint>
      </RadioButtonColumn>
      <KitchenPositionColumn>
        調理場名
        <Required name="required" />
      </KitchenPositionColumn>
      <SeatSetUsableColumn>
        注文エリア利用
        <Hint balloonWidth={SEAT_SET_BALLOON_WIDTH} position="bottom-left">
          注文エリアを登録している場合、調理場への注文を、さらに注文エリアごとに振り分けできます。
        </Hint>
      </SeatSetUsableColumn>
      <DeleteColumn pointer={false} />
    </Row>
  )
}

const Row = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
`
