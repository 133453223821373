import { Record, List } from 'immutable'
import {
  CategoryApiResponse,
  ItemApiResponse,
  ItemCategoryJunctionApiResponse,
  ToppingApiResponse
} from '~/src/api/handy/items/types'
import { Topping } from './Topping'

interface ToppingsProps {
  toppings: ToppingApiResponse[]
  toppingModels: List<Topping>
}

const ToppingsRecord = Record<ToppingsProps>({
  toppings: [],
  toppingModels: List()
})

export class Toppings extends ToppingsRecord implements ToppingsProps {
  constructor(
    toppings: ToppingApiResponse[] = [],
    items: ItemApiResponse[] = [],
    categories: CategoryApiResponse[] = [],
    itemCategoryJunctions: ItemCategoryJunctionApiResponse[] = []
  ) {
    super()
    const list = List(
      toppings.sort((a, b) => a.displayOrder - b.displayOrder)
    ).map(
      topping => new Topping(topping, items, categories, itemCategoryJunctions)
    )
    return this.set('toppingModels', list)
  }
}
