import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Const from '~/src/const'
import * as Selectors from './selectors'
import { useSelector } from 'react-redux'
import { useInitializeReduxState } from './hooks'
import { isHandyKmNotKpKmOnlySelector } from '~/src/redux/selectors/storeSelectors'

const { Page, Size, Color } = Const

export const DisplayOptionSetting: React.VFC = () => {
  const {
    selectDisplayFirstDrinkDelaySetting,
    selectDisplayFirstFoodDelaySetting,
    selectDisplayAlertSecondsEnabled,
    selectDisplayCookingNoticeEnabled,
    selectDisplayOrderTicketSortType,
    selectIsDisplay
  } = Selectors

  const displayFirstDrinkDelaySetting = useSelector(
    selectDisplayFirstDrinkDelaySetting
  )
  const displayFirstFoodDelaySetting = useSelector(
    selectDisplayFirstFoodDelaySetting
  )
  const displayAlertSecondsEnabled = useSelector(
    selectDisplayAlertSecondsEnabled
  )
  const displayCookingNoticeEnabled = useSelector(
    selectDisplayCookingNoticeEnabled
  )
  const displayOrderTicketSortType = useSelector(
    selectDisplayOrderTicketSortType
  )
  const isDisplay = useSelector(selectIsDisplay)
  const isDisplayHandySetting = useSelector(isHandyKmNotKpKmOnlySelector)
  const initializeReduxState = useInitializeReduxState()
  useEffect(initializeReduxState, [initializeReduxState])

  if (!isDisplay) {
    return <></>
  }

  return (
    <>
      <SettingTitle>オプション設定</SettingTitle>
      <Wrapper>
        <Setting>
          <OptionSetting>
            {isDisplayHandySetting && (
              <HandySettingWrapper>
                <Header>ハンディ</Header>
                <HandyContents>
                  <TopTitle>お待たせ判定</TopTitle>
                  <HandyContentWrapper>
                    {!displayFirstDrinkDelaySetting &&
                      !displayFirstFoodDelaySetting &&
                      !displayAlertSecondsEnabled && (
                        <Content>設定なし</Content>
                      )}
                    {displayFirstDrinkDelaySetting && (
                      <Content>{displayFirstDrinkDelaySetting}</Content>
                    )}
                    {displayFirstFoodDelaySetting && (
                      <Content>{displayFirstFoodDelaySetting}</Content>
                    )}
                    {displayAlertSecondsEnabled && (
                      <Content>{displayAlertSecondsEnabled}</Content>
                    )}
                  </HandyContentWrapper>
                </HandyContents>
              </HandySettingWrapper>
            )}
            <KmSettingWrapper>
              <Header>キッチンモニター</Header>
              <KmContents>
                <KmContentWrapper>
                  <TopTitle>着手アラート表示</TopTitle>
                  <Content>{displayCookingNoticeEnabled}</Content>
                </KmContentWrapper>
                <KmContentWrapper>
                  <Title>注文内の商品並び順</Title>
                  <Content>{displayOrderTicketSortType}</Content>
                </KmContentWrapper>
              </KmContents>
            </KmSettingWrapper>
            <LinkWrapper>
              <Link to={Page.TARGET_SERVING_TIME.OPTION_SETTING}>編集する</Link>
            </LinkWrapper>
          </OptionSetting>
        </Setting>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding-bottom: 48px;
`

const SettingTitle = styled.div`
  font-size: ${Size.FONT.XLARGE}px;
  font-weight: bold;
  padding-bottom: 12px;
`

const Setting = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_6};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_6};
`

const OptionSetting = styled.div`
  display: flex;
`
const Header = styled.div`
  color: ${Color.DARK_GRAY_4};
  font-size: ${Size.FONT.BASE}px;
  line-height: 21px;
`

const HandySettingWrapper = styled.div`
  padding: 0 0 20px 16px;
  display: inline-block;
`

const HandyContents = styled.div`
  display: flex;
  align-items: baseline;
  line-height: 26px;
`

const HandyContentWrapper = styled.div`
  display: flex;
  align-items: baseline;
  flex-flow: column wrap;
`

const KmSettingWrapper = styled.div`
  padding: 0 0 20px 48px;
  display: inline-block;
`

const KmContents = styled.div`
  display: inline-block;
  align-items: baseline;
  line-height: 26px;
`

const KmContentWrapper = styled.div`
  display: flex;
  align-items: baseline;
`

const Content = styled.div`
  display: flex;
  min-width: 74px;
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
  font-weight: bold;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding-left: 48px;
`

const TopTitle = styled.div`
  display: flex;
  /** 2つ目(flex-basis)を省略するとIE11とchromeで挙動が変わってしまうため、autoを指定する */
  flex: 1 auto;
  padding: 12px 20px 0 0;
  color: ${Color.VERY_DARK_GRAY};
  font-size: ${Size.FONT.BASE}px;
`

const Title = styled(TopTitle)`
  display: flex;
  /** 2つ目(flex-basis)を省略するとIE11とchromeで挙動が変わってしまうため、autoを指定する */
  flex: 1 auto;
  padding-top: 0;
`
