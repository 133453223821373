import React from 'react'
import styled from 'styled-components'

import TableRow from '~/src/components/atoms/TableRow'
import Icon from '~/src/components/atoms/Icon'
import {
  RowNumberColumn,
  DeviceNameColumn,
  DeviceNameEditColumn,
  DeviceIdColumn,
  DeviceModelColumn,
  AppVersionColumn,
  DeleteColumn
} from './Column'
import RegisteredDevice from '~/src/redux/models/RegisteredDevice/RegisteredDevice'
import { useSetDeleteTargetDevice, useSetEditTargetDevice } from './hooks'

type RowProps = {
  index: number
  registeredDevice: RegisteredDevice
}

export const Row = (props: RowProps) => {
  const { index, registeredDevice } = props
  const setEditTargetDevice = useSetEditTargetDevice(registeredDevice)
  const setDeleteTargetDevice = useSetDeleteTargetDevice(registeredDevice)

  return (
    <TableRow>
      <RowNumberColumn>{index}</RowNumberColumn>
      <DeviceIdColumn>{registeredDevice.deviceId}</DeviceIdColumn>
      <DeviceNameColumn>{registeredDevice.deviceName}</DeviceNameColumn>
      <EditIconColumn onClick={setEditTargetDevice}>
        <EditIcon />
      </EditIconColumn>
      <DeviceModelColumn>
        {`${registeredDevice.deviceModel}`}
        <span>{` (iOS ${registeredDevice.osVersion})`}</span>
      </DeviceModelColumn>
      <AppVersionColumn>{registeredDevice.appVersion}</AppVersionColumn>
      <DeleteIconColumn onClick={setDeleteTargetDevice}>
        <DeleteIcon />
      </DeleteIconColumn>
    </TableRow>
  )
}

const EditIcon = styled(Icon).attrs({ name: 'pencil' })``

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``

const EditIconColumn = styled(DeviceNameEditColumn)`
  cursor: pointer;
`

const DeleteIconColumn = styled(DeleteColumn)`
  cursor: pointer;
`
