import React from 'react'
import styled from 'styled-components'
import Required from '~/src/components/atoms/Required'
import Const from '~/src/const/'
import {
  TitleColumn,
  SeatSetColumn,
  ToggleColumn
} from '~/src/components/pages/PrintTarget/Column'
import TableRow from '~/src/components/atoms/TableRow'

const { Color } = Const

type Props = {
  seatSets: any[] // TODO: use SeatSet type
}

export const PrintTargetByItemHeader = ({ seatSets }: Props) => {
  return (
    <EditableHeader>
      <ToggleColumn />
      <TitleColumn>商品</TitleColumn>
      {seatSets.map(seatSet => (
        <SeatSetColumn key={seatSet.seatSetId}>
          {seatSet.name} <Required name="required" />
        </SeatSetColumn>
      ))}
    </EditableHeader>
  )
}

const EditableHeader = styled(TableRow)`
  background-color: ${Color.VERY_LIGHT_GRAY_9};
  border-top: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  border-bottom: 1px solid ${Color.VERY_LIGHT_GRAY_2};
  font-weight: bold;
  font-size: 14px;
  top: 0;
  margin-top: 0;

  position: relative;
`
