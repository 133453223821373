import React from 'react'
import HeaderRow from './Header'
import CategoryRow from './CategoryRow'
import { useSelector } from 'react-redux'
import useInteractors from '~/src/hooks/useInteractors'
import { AppState } from '~/src/redux/reducer'
import { SimpleCategory } from '~/src/redux/models/Category/Category'
import {
  TargetServingTimeState,
  BatchEditForm
} from '~/src/redux/modules/TargetServingTime/types'
import { createSelector } from 'reselect'
import { isKmPlanSelector } from '~/src/redux/selectors/storeSelectors'

const targetServingTimeState = (state: AppState) =>
  state.targetServingTimeSetting.targetServingTime

const categoriesSelector = createSelector(
  targetServingTimeState,
  (state: TargetServingTimeState) => state.categories
)
const batchEditFormSelector = createSelector(
  targetServingTimeState,
  (state: TargetServingTimeState) => state.batchEditForm! // Modalが開かれるときにbatchEditFormは初期化され必ず存在するのでNonull Assertionを使う
)
const categoriesEditFormSelector = createSelector(
  batchEditFormSelector,
  (state: BatchEditForm) => state.categories
)

const Container = () => {
  // TODO: モーダルを開いたタイミングでstateに入っているcategories分の初期状態を作ってstateに保存する
  const batchEditForm = useSelector(batchEditFormSelector)
  const categories = useSelector(categoriesSelector)
  const categoriesEditForm = useSelector(categoriesEditFormSelector)
  const interactor = useInteractors().targetServingTimeInteractor
  const isAutomaticSettingAvailable = useSelector(isKmPlanSelector)
  return (
    <>
      <HeaderRow />
      {categories.map((category: SimpleCategory) => {
        const form = categoriesEditForm[category.categoryId]
        return (
          <CategoryRow
            key={category.categoryId}
            isDisabled={batchEditForm.which === 'all'}
            category={category}
            isChecked={form.isChecked}
            modeId={form.modeId}
            noticeMinutes={form.noticeMinutes}
            alertMinutes={form.alertMinutes}
            interactor={interactor}
            isAutomaticSettingAvailable={isAutomaticSettingAvailable}
          />
        )
      })}
    </>
  )
}

export default Container
