import { useCallback } from 'react'
import { showOnboarding } from '~/src/util/Onboarding/OnboardingClient'

export const useOnClick = (isLoading: boolean) => {
  return useCallback(() => {
    // Onboardingの資材の読み込みが終わっていない時は何もしない
    if (isLoading) {
      return
    }
    showOnboarding()
  }, [isLoading])
}
