import React from 'react'
import Analytics from '~/src/util/Analytics'

// https://one-recruit.atlassian.net/wiki/spaces/AIROPT/pages/25600945/AA
const addSiteCatalyst = (e: React.MouseEvent<HTMLAnchorElement>) => {
  const targetHref = e.currentTarget.href.split('?')[0].split('/')
  const target = targetHref[targetHref.length - 1].replace('#', '')
  const source = document.location.hash.replace('#', '')
  const linkname = source + ':' + target
  const lid = 'handy_bo_' + source + '_' + target
  Analytics.trackClickLink(linkname, lid)
}

type Props = {
  href?: string
  target?: string
  children: React.ReactNode
  onClick?: () => void
}

export const InlineLink: React.VFC<Props> = props => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    addSiteCatalyst(e)
    if (props.onClick) props.onClick()
  }
  return (
    <a {...props} onClick={e => handleClick(e)}>
      {props.children}
    </a>
  )
}
