const INCLUDE = 0
const EXCLUDE = 1
const NON = 2

const taxType = {}

taxType[INCLUDE] = '内税'
taxType[EXCLUDE] = '外税'
taxType[NON] = '非課税'

export default taxType
