import styled from 'styled-components'
import TableColumn from '~/src/components/atoms/TableColumn'

const baseColumn = styled(TableColumn)`
  text-align: left;
`

export const SelectColumn = styled(baseColumn)`
  width: 40px;
  padding: 0 0 0 8px;
`
export const HodaiNameColumn = styled(baseColumn)`
  padding: 0 16px 0 0;
`

export const HodaiDetailNumColumn = styled(baseColumn)`
  width: 80px;
  text-align: right;
  padding: 0 24px 0 0;
`
