import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  updateMemoText,
  pushNewMemo,
  setDeletingMemoIndex,
  setIsDeleteConfirmModalOpen
} from '~/src/redux/modules/OrderItemMemoTemplate'

export const useInputChange = () => {
  const dispatch = useDispatch()
  return useCallback(
    (index: number, text: string) => {
      dispatch(updateMemoText(index, text))
    },
    [dispatch]
  )
}

export const useFocus = () => {
  const dispatch = useDispatch()
  return useCallback(
    (isLast: boolean) => {
      if (isLast) {
        dispatch(pushNewMemo())
      }
    },
    [dispatch]
  )
}

export const useDeleteButtonClick = () => {
  const dispatch = useDispatch()
  return useCallback(
    (index: number) => {
      dispatch(setDeletingMemoIndex(index))
      dispatch(setIsDeleteConfirmModalOpen(true))
    },
    [dispatch]
  )
}
