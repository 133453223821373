import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import Button from '~/src/components/atoms/Button'
import FooterArea from '~/src/components/atoms/Footer/Footer'
import FooterRightArea from '~/src/components/atoms/Footer/FooterRightArea'
import FooterLeftArea from '~/src/components/atoms/Footer/FooterLeftArea'

import Const from '~/src/const'
const { Page, URL } = Const

const Footer: FC = () => {
  const history = useHistory()
  const onUploadNewCsvClick = useCallback(() => {
    history.push(Page.VARIATION_GROUP.GETTING_STARTED)
  }, [history])

  const onOpenAirREGIClick = useCallback(() => {
    window.open(URL.REGI_BULK_ITEM, '_blank')
  }, [])

  const onBackClick = useCallback(() => {
    history.push(Page.VARIATION_GROUP.ITEMS)
  }, [history])

  return (
    <StyledFooter>
      <LeftArea>
        <StyledButton type="button" normal onClick={onUploadNewCsvClick}>
          新しいCSVをアップロードする
        </StyledButton>
      </LeftArea>
      <RightArea>
        <StyledButton type="button" normal onClick={onBackClick}>
          登録画面に戻り修正する
        </StyledButton>
        <StyledButton type="button" primary onClick={onOpenAirREGIClick}>
          Airレジを開く
        </StyledButton>
      </RightArea>
    </StyledFooter>
  )
}

const StyledButton = styled(Button)`
  width: auto;
`
// 横幅が狭い時にLeft, Rightが重なった時に8pxを間に入れるためのCSS
const StyledFooter = styled(FooterArea)`
  padding-bottom: 0;
`

const LeftArea = styled(FooterLeftArea)`
  padding-bottom: 8px;
`

const RightArea = styled(FooterRightArea)`
  padding-bottom: 8px;
  button,
  span {
    margin-left: 16px;
  }
`
export default Footer
