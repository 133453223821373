import { AppState } from '~/src/redux/reducer'
import { checkHodai, HodaiError } from '~/src/util/ErrorChecker'

export const selectError = (state: AppState) => {
  const hodaiTopError: HodaiError = checkHodai(state.api.items.response)
  return {
    hasDeletedHodaiPlanItemError:
      !!hodaiTopError.itemIdsHaveDeletedHodaiPlanItemError.length,
    hasDeletedHodaiPlanCategoryError:
      !!hodaiTopError.itemIdsHaveDeletedHodaiPlanCategoryError.length,
    hasVariationHodaiPlanError:
      !!hodaiTopError.itemIdsHaveVariationHodaiPlanError.length,
    hasItemNotSetHodaiPlanError:
      !!hodaiTopError.itemIdsHaveItemNotHodaiPlanError.length
  }
}
