import { createAction } from 'typesafe-actions'

const CLEAR_STATE = 'oes/Course/CourseSetting/CLEAR_STATE'
const INITIALIZE = 'oes/Course/CourseSetting/INITIALIZE'
const OPEN_COURSE_SELECT = 'oes/Course/CourseSetting/OPEN_COURSE_SELECT'
const CLOSE_COURSE_SELECT = 'oes/Course/CourseSetting/CLOSE_COURSE_SELECT'
const SUBMIT_COURSE_SELECT = 'oes/Course/CourseSetting/SUBMIT_COURSE_SELECT'
const SELECT_ITEM = 'oes/Course/CourseSetting/SELECT_ITEM'
const RELEASE_ITEM = 'oes/Course/CourseSetting/RELEASE_ITEM'
const RELEASE_ALL = 'oes/Course/CourseSetting/RELEASE_ALL'
const SORT_ITEM = 'oes/Course/CourseSetting/SORT_ITEM'
const OPEN_HODAI_SELECT = 'oes/Course/Course/OPEN_HODAI_SELECT'
const SUBMIT_HODAI_SELECT = 'oes/Course/Course/SUBMIT_HODAI_SELECT'
const CLOSE_HODAI_SELECT = 'oes/Course/Course/CLOSE_HODAI_SELECT'

export const actions = {
  clearState: createAction(CLEAR_STATE)(),
  initialize: createAction(INITIALIZE, ({ items, selectedHodai }) => ({
    items,
    selectedHodai
  }))(),
  openCourseSelect: createAction(OPEN_COURSE_SELECT)(),
  closeCourseSelect: createAction(CLOSE_COURSE_SELECT)(),
  submitCourseSelect: createAction(
    SUBMIT_COURSE_SELECT,
    ({ itemId, name }) => ({
      item: {
        itemId,
        name
      }
    })
  )(),
  selectItem: createAction(SELECT_ITEM, ({ item }) => ({
    item
  }))(),
  releaseItem: createAction(RELEASE_ITEM, index => ({
    index
  }))(),
  releaseAll: createAction(RELEASE_ALL)(),
  sortItem: createAction(SORT_ITEM, ({ oldIndex, newIndex }) => ({
    oldIndex,
    newIndex
  }))(),
  openHodaiSelect: createAction(OPEN_HODAI_SELECT)(),
  submitHodaiSelect: createAction(
    SUBMIT_HODAI_SELECT,
    ({ itemId, hodaiId, itemName }) => ({
      item: {
        itemId,
        hodaiId,
        itemName
      }
    })
  )(),
  closeHodaiSelect: createAction(CLOSE_HODAI_SELECT)()
}

export const types = {
  CLEAR_STATE,
  INITIALIZE,
  OPEN_COURSE_SELECT,
  CLOSE_COURSE_SELECT,
  SUBMIT_COURSE_SELECT,
  SELECT_ITEM,
  RELEASE_ITEM,
  RELEASE_ALL,
  SORT_ITEM,
  OPEN_HODAI_SELECT,
  SUBMIT_HODAI_SELECT,
  CLOSE_HODAI_SELECT
}
