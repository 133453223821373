import React from 'react'
import Message from '~/src/components/atoms/Message'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import { selectError } from './selectors'
import Const from '~/src/const'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
const { URL } = Const

export const Messages: React.VFC = () => {
  const {
    hasVariationHodaiPlanError,
    hasDeletedHodaiPlanCategoryError,
    hasDeletedHodaiPlanItemError,
    hasItemNotSetHodaiPlanError
  } = useSelector(selectError)

  const warningMessageList = []
  const cautionMessageList = []
  if (hasDeletedHodaiPlanItemError) {
    warningMessageList.push(
      '削除済みの放題プランがあります。削除済みの放題プランは編集できません。解除してください。'
    )
  }

  if (hasVariationHodaiPlanError) {
    warningMessageList.push(
      <>
        バリエーションが設定されている放題プランがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  if (hasDeletedHodaiPlanCategoryError) {
    warningMessageList.push(
      <>
        カテゴリーが未設定の放題プランがあります。
        <InlineLink href={URL.REGI_ITEM} target="_blank">
          Airレジの商品設定ページ
        </InlineLink>
        で設定を変更してください。
      </>
    )
  }

  if (hasItemNotSetHodaiPlanError) {
    cautionMessageList.push('商品が未設定の放題プランがあります。')
  }

  return (
    ((warningMessageList.length !== 0 || cautionMessageList.length !== 0) && (
      <Wrapper>
        <Message type="warning" messageList={warningMessageList} />
        <Message type="caution" messageList={cautionMessageList} />
      </Wrapper>
    )) || <></>
  )
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`
