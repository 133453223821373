import { createSelector } from 'reselect'
import { AppState } from '~/src/redux/reducer'

export const selectListState = createSelector(
  (state: AppState) => state.registeredDevice,
  ({ registeredDevices, deviceCount }) => {
    const registeredDeviceCount = registeredDevices.length
    const maxDeviceCount = deviceCount.currentCount
    const unRegisteredCount = maxDeviceCount - registeredDeviceCount
    const unRegisteredRowList = [
      ...new Array(unRegisteredCount > 0 ? unRegisteredCount : 0)
    ].map((v, i) => i + registeredDeviceCount)
    return {
      registeredDevices,
      unRegisteredRowList
    }
  }
)
