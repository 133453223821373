import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  setEditTargetDevice,
  setDeleteTargetDevice
} from '~/src/redux/modules/RegisteredDevice/RegisteredDevice'
import RegisteredDevice from '~/src/redux/models/RegisteredDevice/RegisteredDevice'

export const useSetEditTargetDevice = (device: RegisteredDevice) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setEditTargetDevice(device))
  }, [dispatch, device])
}

export const useSetDeleteTargetDevice = (device: RegisteredDevice) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(setDeleteTargetDevice(device))
  }, [dispatch, device])
}
