import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectKmSettingApiResponse } from './selectors'
import { useClearState, useInitialize } from './interactors'

export const useInitializeReduxState = () => {
  const kmSetting = useSelector(selectKmSettingApiResponse)
  const clearState = useClearState()
  const initialize = useInitialize(kmSetting)
  return useCallback(() => {
    initialize()
    return clearState
  }, [clearState, initialize])
}
