import React, { useCallback } from 'react'
import { Modal as AirKitModal } from '@air-kit/air-kit'
import Button from '~/src/components/atoms/Button'
import styled from 'styled-components'
import Const from '~/src/const'
import AllItemBatchEdit from '../AllItemBatchEdit'
import BatchEditByCategories from '../BatchEditByCategories'
import TargetServingTimeInteractor from '~/src/interactors/TargetServingTime/TargetServingTimeInteractor'
import { BatchEditSelected } from '~/src/redux/modules/TargetServingTime/types'
const { Size, Color } = Const

type Props = {
  isOpen: boolean
  interactor: TargetServingTimeInteractor
  batchEditSelected: BatchEditSelected
  applyButtonDisabled: boolean
}

const Modal: React.FC<Props> = ({
  isOpen,
  interactor,
  batchEditSelected,
  applyButtonDisabled
}: Props) => {
  const handleCancelButtonClick = useCallback(
    () => interactor.closeBatchEditFormModal(),
    [interactor]
  )
  const handleApplyButtonClick = useCallback(
    () => interactor.applyBatchEdit(batchEditSelected),
    [batchEditSelected, interactor]
  )
  if (!isOpen) {
    return null
  }
  const footer = {
    right: (
      <div>
        <StyledButton
          normal
          name="cancel-button"
          onClick={handleCancelButtonClick}
        >
          キャンセル
        </StyledButton>
        <StyledButton
          primary
          onClick={handleApplyButtonClick}
          disabled={applyButtonDisabled}
        >
          反映する
        </StyledButton>
      </div>
    )
  }
  return (
    <Adjuster>
      <AirKitModal
        title="一括編集"
        footer={footer}
        onClose={handleCancelButtonClick}
      >
        <Addition>
          全商品まとめて、もしくはカテゴリーごとにまとめて編集することができます。
        </Addition>
        <Content>
          <Title>全商品一括編集</Title>
          <AllItemBatchEdit />
        </Content>
        <Content>
          <Title>カテゴリー別一括編集</Title>
          <BatchEditByCategories />
        </Content>
      </AirKitModal>
    </Adjuster>
  )
}

const Adjuster = styled.div`
  div[class^='feedback__Wrapper'] {
    top: ${Size.COMMON_HEADER_HEIGHT + Size.HEADER_HEIGHT}px;
  }
  div[class^='modal__ModalWrapper'] {
    max-width: 800px;
    width: calc(100vw - 100px);
  }
`
const StyledButton = styled(Button)`
  width: auto;
`

const Content = styled.div`
  padding-bottom: 22px;
`

const Title = styled.div`
  font-weight: bold;
  padding: 0 0 12px;
  border-bottom: solid 1px ${Color.LIGHT_GRAY_4};
  margin-bottom: 20px;
`

const Addition = styled.div`
  padding-bottom: 24px;
  font-size: 16px;
  line-height: 22px;
`

export default Modal
