import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSetShouldForward } from './hooks'

type ForwardProps = {
  when: boolean
  forwardPage: string
  after?: number | null
}

export default (props: ForwardProps) => {
  const { when, forwardPage, after } = props

  const history = useHistory()
  const handleSetShouldForward = useSetShouldForward()
  if (when) {
    setTimeout(() => history.push(forwardPage), after || 0)
    handleSetShouldForward(false)
  }
  return <></>
}
