import React from 'react'
import TableRow from '~/src/components/atoms/TableRow'
import {
  ItemNameColumn,
  KitchenPositionSelectColumn,
  AnalysisTagSelectColumn,
  ItemSettingColumn
} from './Column'
import SelectBox from '~/src/components/atoms/SelectBox'
import Toggle from '~/src/components/atoms/Toggle'
import AnalysisTagSelect from '../../../../Common/AnalysisTagSelect/AnalysisTagSelect'
import { useSelector } from 'react-redux'
import { selectRow } from './selectors'
import { useToggle, useSelect } from './hooks'
import Item from '~/src/redux/models/Item/Item'

type Props = {
  item: Item
}

export const Row: React.VFC<Props> = ({ item }) => {
  const {
    hasItemSetting,
    selectableOptions,
    selectedKitchenPositionId,
    selectedAnalysisTag
  } = useSelector(selectRow(item.itemId))

  const handleToggle = useToggle(
    item.itemId,
    hasItemSetting,
    selectedKitchenPositionId,
    selectedAnalysisTag
  )
  const handleSelect = useSelect(item.itemId)

  return (
    <TableRow>
      <ItemNameColumn>{item.itemName}</ItemNameColumn>
      <ItemSettingColumn>
        <Toggle
          name="toggle"
          onToggle={handleToggle}
          checked={hasItemSetting}
          disabled={false}
        />
      </ItemSettingColumn>
      <KitchenPositionSelectColumn>
        <SelectBox
          name="kitchenPositionSelect"
          disabled={!hasItemSetting}
          options={selectableOptions}
          selectedId={selectedKitchenPositionId}
          handleSelectChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleSelect(e.target.value, selectedAnalysisTag)
          }
        />
      </KitchenPositionSelectColumn>
      <AnalysisTagSelectColumn>
        <AnalysisTagSelect
          name="itemByAnalysisTagSelect"
          disabled={!hasItemSetting}
          selectedId={selectedAnalysisTag}
          handleSelectChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            handleSelect(selectedKitchenPositionId, e.target.value)
          }
        />
      </AnalysisTagSelectColumn>
    </TableRow>
  )
}
