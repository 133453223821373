import React from 'react'
import styled from 'styled-components'
import TableRow from '~/src/components/atoms/TableRow'
import { SortableHandle } from 'react-sortable-hoc'
import {
  HandleColumn,
  TrashBoxColumn,
  ItemNameColumn
} from '../../Column/Column'
import { Item } from '../../../types'
import Icon, { ExclamationIcon } from '~/src/components/atoms/Icon'
import Const from '~/src/const'

const { Color } = Const

type Props = {
  item: Item
  onClickTrashbox: () => void
}

export const Row: React.VFC<Props> = props => {
  const { itemName, isDeleted, isSKU, isDisplayed, isCombinedCategory } =
    props.item

  return (
    <MainContents isDeleted={isDeleted}>
      <DraggableColumn />
      {isDeleted && (
        <ItemNameColumn>
          <ExclamationIcon color="red" />
          [削除済み]
        </ItemNameColumn>
      )}
      {!isDeleted && (
        <ItemNameColumn>
          {(isDeleted || !isCombinedCategory) && (
            <ExclamationIcon color="red" />
          )}
          {!isCombinedCategory && '['}
          {!isDeleted && itemName}
          {!isDeleted && isSKU && '(バリエーションあり)'}
          {!isCombinedCategory && '(カテゴリー未設定)]'}
          {!isDeleted && !isDisplayed && '(非表示)'}
          {isDeleted && '[削除済み]'}
        </ItemNameColumn>
      )}
      <TrashBoxColumn
        onClick={() => {
          props.onClickTrashbox()
        }}
      >
        <DeleteIcon />
      </TrashBoxColumn>
    </MainContents>
  )
}

const MainContents = styled(TableRow)<{
  isDeleted: boolean
}>`
  background-color: ${props =>
    props.isDeleted ? Color.VERY_LIGHT_GRAY_9 : Color.WHITE};
`

const DeleteIcon = styled(Icon).attrs({ name: 'trash_box' })``

const DraggableColumn = SortableHandle(() => {
  return (
    <HandleColumn>
      <SortIcon />
    </HandleColumn>
  )
})

const SortIcon = styled(Icon).attrs({ name: 'sort_handle' })``
