import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { HodaiApiRequest } from '~/src/api/handy/hodais/types'
import { ItemsApiDispatch } from '~/src/api/handy/items/types'
import { actions as itemsApiActions } from '~/src/api/handy/items/actions'
import { useHistory } from 'react-router-dom'
import Const from '~/src/const'
import {
  clearLoading,
  setLoading,
  showAndBlurToast
} from '~/src/redux/modules/UI'
import ApiClient, { ApiClientInterface } from '~/src/util/APIClient'
import { handleError } from '~/src/redux/modules/ApiError'
import { HodaiSettingState, Item } from './types'
import { actions } from './actions'

const { Page, API, Toast } = Const

export const useFetch = () => {
  const dispatch: ItemsApiDispatch = useDispatch()
  return useCallback(() => {
    dispatch(itemsApiActions.fetchItems())
  }, [dispatch])
}

export const useHandlePostCreateHodai = (
  state: HodaiSettingState,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const history = useHistory()
  return useCallback(async () => {
    dispatch(setLoading())
    return apiClient
      .post(API.POST_CREATE_HODAI, convertStateToRequest(state))
      .then(() => {
        dispatch(showAndBlurToast(Toast.CREATED_MESSAGE))
        dispatch(actions.clearState())
        history.push(Page.HODAI.LIST)
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [dispatch, apiClient, state, history])
}

export const useHandlePostUpdateHodai = (
  state: HodaiSettingState,
  hodaiId: string,
  apiClient: ApiClientInterface = ApiClient
) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch()
  const history = useHistory()
  return useCallback(async () => {
    dispatch(setLoading())
    return apiClient
      .post(API.POST_UPDATE_HODAI, convertStateToRequest(state, hodaiId))
      .then(() => {
        dispatch(showAndBlurToast(Toast.SAVED_MESSAGE))
        dispatch(actions.clearState())
        history.push(Page.HODAI.LIST)
      })
      .catch(error => dispatch(handleError(error)))
      .finally(() => dispatch(clearLoading()))
  }, [dispatch, apiClient, state, hodaiId, history])
}

const convertStateToRequest = (
  state: HodaiSettingState,
  hodaiId?: string
): HodaiApiRequest => {
  return {
    hodaiId: hodaiId,
    itemId: state.selectedHodai?.itemId,
    lastOrderMinutes:
      state.lastOrderSetting.lastOrderSetMode === 'manual'
        ? undefined
        : state.lastOrderSetting.lastOrderMinutes,
    hodaiDetails: state.categories
      .flatMap(category => category.items.filter(item => item.isSelected))
      .map((item: Item) => ({
        hodaiId: hodaiId,
        itemId: item.itemId
      }))
  }
}
