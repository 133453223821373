import { ActionType, getType } from 'typesafe-actions'
import { AuthState } from './types'
import actions from './actions'
import Const from '~/src/const'
import { setDatadogUser } from '~/src/util/Datadog'
const { PlfGrant } = Const

const initialState: AuthState = {
  store: undefined,
  token: undefined
}

export default (
  state: AuthState = initialState,
  action: ActionType<typeof actions>
): AuthState => {
  switch (action.type) {
    case getType(actions.getStoreSucceeded):
      setDatadogUser(
        action.payload.store.storeId,
        action.payload.store.serviceTypes
      )
      return {
        ...state,
        store: action.payload.store,
        token: {
          ...action.payload.token,
          isManager: action.payload.token.plfGrant === PlfGrant.MANAGER
        }
      }
    default:
      return state
  }
}
