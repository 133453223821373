import React, { useCallback } from 'react'
import Const from '~/src/const'
import styled from 'styled-components'
import Heading from '~/src/components/atoms/Heading'
import { InlineLink } from '~/src/components/atoms/InlineLink'
import FileUploader, {
  RejectFile
} from '~/src/components/organisms/FileUploader/FileUploader'
// @ts-ignore
import RegiItemUploadScreen from '~/assets/images/regi_item_upload_screen.png'

import VariationGroupInteractor from '~/src/interactors/VariationGroup/VariationGroupInteractor'

import Message from '~/src/components/atoms/Message'

const { Size, URL, Color } = Const
const { H3 } = Heading

type Props = {
  interactor: VariationGroupInteractor
  file?: File
  fileErrorMessage?: string
}

const Content: React.FC<Props> = props => {
  const { interactor, file, fileErrorMessage } = props

  const onFileAcceptedCb = useCallback(
    (file: File) => {
      interactor.loadFile(file)
    },
    [interactor]
  )

  const onFileRejectedCb = useCallback(
    (error: RejectFile) => {
      interactor.rejectFile(error.cause)
    },
    [interactor]
  )

  const onValidateCb = useCallback(
    (file: File): Promise<void> => {
      return interactor.validateFile(file)
    },
    [interactor]
  )

  return (
    <Wrapper>
      {fileErrorMessage && (
        <Message
          type="warning"
          messageList={[fileErrorMessage]}
          // TODO 消したいけど、どこかでname使ってたら嫌なので消せない
          // @ts-ignore
          name="warning-message"
        />
      )}
      <Section>
        <InnerSection>
          <StyledH3>必要な準備</StyledH3>
          <DescriptionElementContainer>
            <DescriptionElement>
              この設定はパソコンのご利用を推奨します。
            </DescriptionElement>
            <DescriptionElement>
              <InlineLink href={URL.REGI_BULK_ITEM} target="_blank">
                Airレジのファイルで一括編集ページ
              </InlineLink>
              を開き、商品一括編集用ファイル(CSV)をダウンロードします。
            </DescriptionElement>
            <DescriptionElement>
              ダウンロード後、設定が完了するまで、Airレジの商品設定は絶対に編集しないでください。
            </DescriptionElement>
          </DescriptionElementContainer>
        </InnerSection>
        <InnerSection>
          <ImageContainer>
            <ImageStyle
              width="266px"
              height="200px"
              src={RegiItemUploadScreen}
            />
          </ImageContainer>
        </InnerSection>
      </Section>
      <Section>
        <InnerSection>
          <StyledH3>商品一括編集用ファイル(CSV)のアップロード</StyledH3>
          <DescriptionElement>
            事前に準備した商品一括編集用ファイル(CSV)をこのページにアップロードします。「設定を始める」をクリックして設定画面に進みます。
          </DescriptionElement>
          <FileUploader
            fileType="text/csv"
            filenameExample="Airレジの商品一括編集用ファイル(CSV)"
            selectedFile={file}
            validator={onValidateCb}
            onFileAccepted={onFileAcceptedCb}
            onFileRejected={onFileRejectedCb}
          />
        </InnerSection>
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
  /* 画像との間にのみpaddingを効かせるため */
  > div {
    padding-right: 16px;
  }
  > :last-child {
    padding-right: 0;
  }
`

const InnerSection = styled.div`
  flex: 1 1 auto;
`

const DescriptionElement = styled.div`
  font-size: ${Size.FONT.LARGE}px;
  line-height: 22px;
  color: ${Color.DARK_GRAY};
  padding-bottom: 8px;
`

const DescriptionElementContainer = styled.div`
  div:last-child {
    padding-bottom: 0;
  }
`

const StyledH3 = styled(H3)`
  font-size: ${Size.FONT.HEADER.H3}px;
  padding-bottom: 16px;
`

const ImageContainer = styled.div`
  text-align: right;
`

const ImageStyle = styled.img`
  border: 1px solid ${Color.LIGHT_GRAY_4};
`

export default Content
