import React from 'react'
import { useSelector } from 'react-redux'
import Message from '~/src/components/atoms/Message'
import { selectMessageAreaState } from './selectors'

export const MessageArea = () => {
  const { hasError, exceededErrorMessage, currentCount } = useSelector(
    selectMessageAreaState
  )

  if (!hasError) {
    return <></>
  }

  const errorMessage = (
    <>
      現在、{exceededErrorMessage}が、有料利用時に使用できなくなる状態です。{' '}
      <br />
      利用台数の数まで端末を登録解除するか、利用台数の引き上げを行ってください。
      <br />
      現在の利用台数 : {currentCount} 台
    </>
  )

  return <Message type="warning" messageList={[errorMessage]} />
}
